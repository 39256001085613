import { getListTour } from "../repository/tour.repository";

export const getListUseCase = async () => {
  try {
    const response = await getListTour();
    return response.data.response;
  } catch (error) {
    console.log(error);
  }
};
