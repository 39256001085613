import { getCountryAndCityList } from "../../../data/repository/city.repo";

export const GetCountryAndCityListUsecase = async () => {
  try {
    const resp = await getCountryAndCityList();
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
