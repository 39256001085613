import React, { useEffect, useState } from "react";
import { getListUseCase } from "./api/tour/usecases/getListUseCase";

const ViajesLista = () => {
  const [description, setDescription] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getListUseCase();
      console.log(response);
      setDescription(response);
    };
    fetchData();
  }, []);

  return (
    <>
      {description.length > 0 && (
        <>
          {description.map((elem, index) => (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: elem.description }}
            />
          ))}
        </>
      )}
    </>
  );
};

export default ViajesLista;
