import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CssBaseline,
  Grid,
  Stack,
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

import { tokens } from "../../../../theme";
import { getGenres } from "../../../../api/resources/genres/genres";
import {
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../../helpers/token/refreshToken";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SnackBar from "../../../../components/snackbar/SnackBar";
import {
  createDriverAndVehicle,
  deleteDriverAndVehicle,
  getDriverById,
  updateDriver,
} from "../../../../api/users/driver/driver";
import { getVehicles } from "../../../../api/users/driver/vehicle";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

const Driver = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const { id } = useParams();
  const { t } = useTranslation();
  const [isUpdate, setIsUpdate] = useState(true);
  const [genre, setGenre] = useState([]);
  const [driver, setDriver] = useState("");

  const [fixedPay, setFixedPay] = useState(false);
  const [bookingStatus, setBookingStatus] = useState(false);

  const [vehicles, setVehicles] = useState([]);
  const [driverVehicle, setDriverVehicle] = useState([]);
  const [valueUpdate, setValueUpdate] = useState([]);
  const [valueDelete, setValueDelete] = useState([]);

  const [license, setLicense] = useState("");
  const location = useLocation();
  const driverName = location.state.fullname;

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    lastname: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    phone: yup.string().required("required"),
    address: yup.string().required("required"),
    genres_id: yup.number().required("required"),
  });

  useEffect(() => {
    const genres = async () => {
      try {
        const response = await getGenres();
        const data = response.data.genres.filter((genre) => genre.id);
        setGenre(data);
      } catch (error) {
        console.log(error);
      }
    };
    genres();
  }, []);

  useEffect(() => {
    const driver = async () => {
      try {
        const token = getTokenRefresh();
        const response = await getDriverById(token, id);
        console.log(response?.data);
        setDriver(response?.data);
        console.log(response?.data.commission_monthly);
        setDriverVehicle(response.data.vehicles);
        setFixedPay(Boolean(response?.data.commission_monthly));
        setBookingStatus(Boolean(response?.data.bookings));
      } catch (error) {
        console.log(error);
      }
    };
    driver();
  }, []);

  useEffect(() => {
    const verify = verifyExpireTokenRefresh();
    if (verify) navigate("/");
    const vehicles = async () => {
      try {
        const token = getTokenRefresh();
        if (driver) {
          const response = await getVehicles(token);
          setVehicles([]);
          response?.data?.map((vehicle) => {
            let flag = true;
            driver.vehicles.map((vehicleDriver, index) => {
              if (vehicleDriver.id === vehicle.id) flag = false;

              if (index + 1 === driver.vehicles.length && flag) {
                setVehicles((oldData) => [...oldData, vehicle]);
              }
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    vehicles();
  }, [driver]);

  const handleFormSubmit = async (values) => {
    console.log(values);
    //if (expireToken) navigate("/");
    try {
      const token = getTokenRefresh();

      const license = {
        expiration: values.license_expiration,
        number: values.license_number,
      };

      const contract = {
        number: values.contract_number,
        start: values.contract_start,
        end: values.contract_end,
      };

      const commission = {
        percentageValue: values.commission_percentage,
        fixedValue: values.commission_fixed,
        monthly: fixedPay,
      };
      console.log("bookings", bookingStatus);
      const driver = {
        id: values.id,
        name: values.name.toUpperCase(),
        lastname: values.lastname.toUpperCase(),
        email: values.email,
        address: values.address.toUpperCase(),
        date_of_birth: values.date_of_birth,
        dni: values.dni,
        notes: values.notes ? values.notes.toUpperCase() : "",
        phone: values.phone,
        phone_reference: values.phone_reference,
        genres_id: values.genres_id,
        bookings: bookingStatus,
      };

      await updateDriver(token, contract, license, commission, driver);
      snackBarDialog("DATOS ACTUALIZADOS");
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR AL ACTUALIZAR DATOS", "error");
    }
  };

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const updateDriverVehicle = async (driver_id) => {
    console.log(valueUpdate);
    console.log(driver_id);
    try {
      const token = "";
      await createDriverAndVehicle(token, valueUpdate, driver_id);
      snackBarDialog("VEHICULO ASIGNADO AL CONDUCTOR");
      setValueUpdate([]);
    } catch (error) {
      snackBarDialog("NO SE PUDO AGREGAR VEHICULO", "error");
    }
  };

  const deleteDriverVehicle = async (driver_id) => {
    console.log(valueDelete);
    console.log(driver_id);
    try {
      const token = "";
      await deleteDriverAndVehicle(token, valueDelete, driver_id);
      snackBarDialog("VEHICULO DESVINCULADO DEL CONDUCTOR");
      setValueDelete([]);
    } catch (error) {
      snackBarDialog("ERROR, NO SE LOGRO DESVINCULAR EL VEHICULO", "error");
    }
  };

  const handleChangeFixed = (event) => {
    var fixed = JSON.parse(event.target.value);
    setFixedPay(fixed);
  };
  const handleChangeBooking = (event) => {
    var booking = JSON.parse(event.target.value);
    setBookingStatus(booking);
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={driverName} subtitle="CONDUCTOR" />

              <Button
                color="secondary"
                onClick={(e) => {
                  setIsUpdate(!isUpdate);
                }}
              >
                <CreateOutlinedIcon />
                EDITAR CONDUCTOR
              </Button>
            </Box>
            {driver ? (
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={driver}
                validationSchema={checkoutSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      backgroundColor={colors.primary[400]}
                      borderRadius={3}
                      p={1}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.name}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            type="text"
                            label={t("name")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="name"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            error={!!touched.name && !!errors.name}
                            helperText={touched.name && errors.name}
                            color="success"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.lastname}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="text"
                            label={t("lastname")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="lastname"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            error={!!touched.lastname && !!errors.lastname}
                            helperText={touched.lastname && errors.lastname}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.dni}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="number"
                            label={t("DNI")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="dni"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.email}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="email"
                            label={t("email")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <TextField
                            value={values.phone}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="tel"
                            label={t("phone")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="phone"
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            value={values.phone_reference}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="tel"
                            label={t("Telefono Referencial")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="phone_reference"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.address}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="text"
                            label={t("address")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="address"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            error={!!touched.address && !!errors.address}
                            helperText={touched.address && errors.address}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2}>
                              <MobileDatePicker
                                value={values.date_of_birth}
                                label={t("Fecha de Nacimiento")}
                                inputFormat="DD/MM/YYYY"
                                name="date_of_birth"
                                onChange={(newValue) => {
                                  console.log(newValue);
                                  values.date_of_birth = newValue;
                                  setLicense(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                disabled={isUpdate}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth disabled={isUpdate}>
                            <InputLabel>{t("genre")}</InputLabel>
                            <Select
                              value={values.genres_id}
                              id="genre"
                              name="genres_id"
                              label={t("genre")}
                              onChange={handleChange}
                            >
                              {genre.map((genre, index) => (
                                <MenuItem key={index} value={genre.id}>
                                  {t(genre.name)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={6} sm={3}>
                          <TextField
                            value={values.commission_percentage}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="number"
                            label={t("PORCENTAJE")}
                            onChange={handleChange}
                            name="commission_percentage"
                            disabled={isUpdate}
                          />
                        </Grid>

                        <Grid item xs={6} sm={3}>
                          <TextField
                            value={values.commission_fixed}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="number"
                            label={"Fijo (diario)"}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="commission_fixed"
                            disabled={isUpdate}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3} textAlign="center">
                          <FormControl disabled={isUpdate}>
                            <FormLabel id="row-radio-buttons">
                              COBRAR POR:
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="row-radio-buttons"
                              value={fixedPay}
                              onChange={handleChangeFixed}
                            >
                              <FormControlLabel
                                value={false}
                                control={<Radio color="success" />}
                                label="DIA"
                              />
                              <FormControlLabel
                                value={true}
                                control={<Radio color="success" />}
                                label="MES"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3} textAlign="center">
                          <FormControl disabled={isUpdate}>
                            <FormLabel id="row-radio-buttons">
                              RESERVAS:
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="row-radio-buttons"
                              value={bookingStatus}
                              onChange={handleChangeBooking}
                            >
                              <FormControlLabel
                                value={false}
                                control={<Radio color="success" />}
                                label="NO"
                              />
                              <FormControlLabel
                                value={true}
                                control={<Radio color="success" />}
                                label="SI"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <TextField
                            value={values.license_number}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="text"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            label={t("Licencia")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="license_number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2}>
                              <MobileDatePicker
                                value={values.license_expiration}
                                label={t("Vencimiento")}
                                inputFormat="DD/MM/YYYY"
                                name="license_expiration"
                                onChange={(newValue) => {
                                  console.log(newValue);
                                  values.license_expiration = newValue;
                                  setLicense(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                disabled={isUpdate}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            value={values.contract_number}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="text"
                            label={t("Contrato")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="contract_number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2}>
                              <MobileDatePicker
                                value={values.contract_start}
                                label={t("start")}
                                inputFormat="DD/MM/YYYY"
                                name="contract_start"
                                onChange={(newValue) => {
                                  console.log(newValue);
                                  values.contract_start = newValue;
                                  setLicense(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                disabled={isUpdate}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2}>
                              <MobileDatePicker
                                value={values.contract_end}
                                label={t("end")}
                                inputFormat="DD/MM/YYYY"
                                name="contract_end"
                                onChange={(newValue) => {
                                  console.log(newValue);
                                  values.contract_end = newValue;
                                  setLicense(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                disabled={isUpdate}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <TextField
                            value={values.notes}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="text"
                            label={t("Notas")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            name="notes"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} textAlign={"end"}>
                          <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            disabled={isUpdate}
                          >
                            {t("ACTUALIZAR CONDUCTOR")}
                          </Button>
                        </Grid>

                        <Grid item xs={10} sm={10}>
                          <Autocomplete
                            multiple
                            id="id-vehicles-update"
                            value={valueUpdate}
                            onChange={(e, newValue) => {
                              setValueUpdate([...newValue]);
                            }}
                            options={vehicles}
                            getOptionLabel={(option) =>
                              `${option.id}: ${option.brand} ${option.plate}`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("Vehiculos Disponibles")}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={2} sm={2}>
                          <Button
                            fullWidth
                            color="secondary"
                            variant="contained"
                            sx={{ height: "100%" }}
                            onClick={(e) => {
                              e.preventDefault();
                              updateDriverVehicle(values.id);
                            }}
                          >
                            AGREGAR
                          </Button>
                        </Grid>

                        <Grid item xs={10} sm={10}>
                          <Autocomplete
                            multiple
                            id="id-vehicles-delete"
                            value={valueDelete}
                            onChange={(e, newValue) => {
                              setValueDelete([...newValue]);
                            }}
                            options={driverVehicle}
                            getOptionLabel={(option) =>
                              `${option.id}: ${option.brand} ${option.plate}`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("Vehiculos del Conductor")}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={2} sm={2}>
                          <Button
                            fullWidth
                            color="secondary"
                            variant="contained"
                            sx={{ height: "100%" }}
                            onClick={(e) => {
                              e.preventDefault();
                              deleteDriverVehicle(values.id);
                            }}
                          >
                            ELIMINAR
                          </Button>
                        </Grid>
                      </Grid>
                      <SnackBar
                        stateSnk={stateSnk}
                        vertical={vertical}
                        horizontal={horizontal}
                        duration={5000}
                        handleClose={handleSnkClose}
                        severity={severity}
                        messageSnk={messageSnk}
                      />
                    </Box>
                  </form>
                )}
              </Formik>
            ) : (
              <>Cargando datos ...</>
            )}
          </Box>
        </main>
      </div>
    </>
  );
};

export default Driver;
