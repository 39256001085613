import { createReservationAdvance } from "../../../data/repository/advance.repo";

export const CreateAdvanceUseCase = async (advance) => {
  try {
    const resp = await createReservationAdvance(advance);

    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
