import { useLocation, useParams } from "react-router-dom";
import { Box, CssBaseline, Grid, useTheme } from "@mui/material";
import Header from "../../../../components/header/Header";
import Topbar from "../../../../components/topbar/TopBar";
import { tokens } from "../../../../theme";
import { useEffect, useState } from "react";
import { DataGrid, esES } from "@mui/x-data-grid";
import {
  getEnterprise,
  searchBookingPerEnterprise,
} from "../../../../api/enterprise/enterprise";
import ReportHistory from "./ReportHistory";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

const EnterpriseHistory = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const [booking, setBooking] = useState(null);
  const [dataSelected, setDataSelected] = useState([]);
  const { id } = useParams();
  const location = useLocation();
  const userData = location.state.userData;
  const enterpriseName = location.state.enterpriseName;

  useEffect(() => {
    const enterpriseCredit = async () => {
      try {
        const response = await searchBookingPerEnterprise(id, "null", "null");
        setBooking([]);
        Object.values(response.data).map((book) => {
          setBooking((oldData) => [...oldData, book]);
        });
      } catch (error) {
        console.log(error);
      }
    };
    enterpriseCredit();
  }, []);

  const [enterpriseData, setEnterpriseData] = useState("");
  useEffect(() => {
    const getEnterpriseData = async () => {
      try {
        const response = await getEnterprise("token", id);
        console.log(response.data);
        setEnterpriseData(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    getEnterpriseData();
  }, []);

  const columns = [
    { headerName: "PEDIDO", field: "order_number", width: 50 },
    {
      headerName: "FECHA",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "HORA",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "TRABAJADOR",
      field: "fullname_employee",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "ORIGEN",
      field: "origin_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "DESTINO",
      field: "destination_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "CONDUCTOR",
      field: "fullname_driver",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "PLATE",
      field: "plate",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "PEAJE",
      field: "toll",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "PRECIO",
      field: "price_total",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
  ];

  const [reportData, setReportData] = useState([]);
  const [totalData, setTotalData] = useState(null);

  useEffect(() => {
    try {
      if (dataSelected.length > 0) {
        setReportData([]);
        let sumTotal = 0;
        let sumToll = 0;
        Object.values(dataSelected).map((book) => {
          sumToll += Number(book.toll);
          sumTotal += Number(book.price_total);
          setReportData((oldData) => [...oldData, book]);
        });
        setTotalData({ sumTotal, sumToll, igv: userData });
      } else {
        setReportData([]);
        setTotalData({ sumTotal: 0, sumToll: 0, igv: userData });
      }
    } catch (error) {
      console.log(error);
    }
  }, [dataSelected]);

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header
                title={enterpriseName}
                subtitle="HISTORIAL"
              />
              <Box>
                {totalData && reportData && (
                  <ReportHistory
                    userData={reportData}
                    invoice={totalData}
                    enterpriseData={enterpriseData}
                  />
                )}
              </Box>
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <Grid item xs={12} sm={12} md={12}>
                  {booking && (
                    <Box
                      m="0px 0 0 0"
                      height="75vh"
                      sx={{
                        "& .MuiDataGrid-root": {
                          border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          backgroundColor: colors.blueAccent[700],
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                      }}
                    >
                      <DataGrid
                        rows={booking}
                        columns={columns}
                        localeText={
                          esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        checkboxSelection={true}
                        onSelectionModelChange={(ids) => {
                          setDataSelected([]);
                          ids.map((id) => {
                            const select = booking.find((row) => row.id === id);
                            setDataSelected((oldData) => [...oldData, select]);
                          });
                        }}
                      />
                    </Box>
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default EnterpriseHistory;
