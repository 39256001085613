import React from "react";
import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import cambayRegular from "../../../../font/CambayRegular.ttf";
import logo from "../../../../assets/contratologo.jpg";
import firma from "../../../../assets/contratofirma.png";

Font.register({
  family: "Cambria",
  src: cambayRegular,
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignContent: "center",
  },
  enterpriseContainer: {
    width: "50%",
    marginTop: 5,
  },
  text: {
    color: "#000000",
    fontSize: 11,
    fontFamily: "Cambria",
  },
  logo: {
    width: 130,
    height: 60,
    marginRight: "auto",
  },
});

const Signature = () => {
  return (
    <View style={styles.container}>
      <View style={styles.enterpriseContainer}>
        <Text style={styles.text}>LA EMPRESA</Text>
        <Image style={styles.logo} src={firma} />
      </View>
      <View style={styles.enterpriseContainer}>
        <Text style={styles.text}>EL PROPIETARIO AFILIADO</Text>
      </View>
    </View>
  );
};

export default Signature;
