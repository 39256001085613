import React, { useState } from "react";
import { t } from "i18next";
import {
  Box,
  Button,
  TextField,
  useTheme,
  Stack,
  FormControlLabel,
  Checkbox,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Formik } from "formik";
import * as yup from "yup";

import { tokens } from "../../../theme";
import { getTokenRefresh } from "../../../helpers/token/refreshToken";
import SnackBar from "../../../components/snackbar/SnackBar";
import { updateVehicle } from "../../../api/users/driver/vehicle";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
} from "@react-pdf/renderer";
import Header from "../pdf/elements/Header";
import Title from "../pdf/elements/Title";
import Body from "../pdf/elements/Body";
import Signature from "../pdf/elements/Signature";
import Footer from "../pdf/elements/Footer";
import Constatacion from "../constatacion/Constatacion";
import Renovacion from "../renovacion/Renovacion";
import Habilitacion from "../habilitacion/Habilitacion";
import Vencidos from "../vencidos/Vencidos";
import Mejora from "../mejora/Mejora";
import Desafiliacion from "../desafiliacion/Desafiliacion";
import Constancia from "../constancia/Constancia";

const VehicleForm = ({ vehicle, operation, isUpdate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dateEnd, setDateEnd] = useState(null);
  const [soatEnd, setSoatEnd] = useState(null);
  const [setare, setSetare] = useState(false);

  const [checkSetare, setCheckSetare] = useState(vehicle.setare_permission);

  const handleSetare = (e) => setSetare(e.target.checked);

  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const handleFormSubmit = async (values) => {
    try {
      const token = getTokenRefresh();
      if (token) {
        const vehicle = {
          id: values.id,
          brand: values.brand.toUpperCase(),
          model: values.model.toUpperCase(),
          bodywork: values.bodywork.toUpperCase(),
          plate: values.plate.toUpperCase(),
          year: values.year,
          color: values.color.toUpperCase(),
          owner: values.owner ? values.owner.toUpperCase() : "",
          cylinder: values.cylinder,
          weight: values.weight,
          notes: values.notes ? values.notes.toUpperCase() : "",
          premium: premium,
          ownerDni: values.owner_dni,
          ownerAddress: values.owner_address,
          engine: values.engine,
          series: values.series,
        };
        const technical_review = {
          description: values.tr_description.toUpperCase(),
          start: "",
          end: values.tr_end,
        };

        const soat = {
          description: values.soat_description.toUpperCase(),
          start: "",
          end: values.soat_end,
        };

        if (!values.setare_permission) {
          values.setare_start = "";
          values.setare_end = "";
        }
        const Setare = {
          permission: values.setare_permission,
          start: values.setare_start,
          end: values.setare_end,
        };
        await updateVehicle(token, vehicle, technical_review, soat, Setare);
        setMessageSnk("VEHICULO ACTUALIZADO");
        setSeverity("success");
        setStateSnk(true);
      }
    } catch (error) {
      console.log(error);
      setMessageSnk("ERROR AL ACTUALIZAR EL VEHICULO");
      setSeverity("error");
      setStateSnk(true);
    }
  };

  const checkoutSchema = yup.object().shape({
    brand: yup.string().required("required"),
    model: yup.string().required("required"),
    bodywork: yup.string().required("required"),
    plate: yup.string().required("required"),
    year: yup.string().required("required"),
    color: yup.string().required("required"),
    tr_description: yup.string().required("required"),
    soat_description: yup.string().required("required"),
  });

  const initialValues = operation
    ? vehicle
    : {
        brand: "",
        model: "",
        bodywork: "",
        plate: "",
        year: "",
        color: "",
        tr_description: "",
        tr_start: "",
        tr_end: "",
        soat_description: "",
        soat_start: "",
        soat_end: "",
        setare_permission: false,
        setare_start: "",
        setare_end: "",
        owner_dni: "",
        owner_address: "",
        engine: "",
        series: "",
      };

  const [premium, setPremium] = useState(vehicle.premium);
  const handleChangeBooking = (e) => {
    setPremium(e.target.value);
  };

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box backgroundColor={colors.primary[400]} borderRadius={3} p={2}>
            <Grid container spacing={1.5}>
              <Grid item xs={12} sm={4}>
                <TextField
                  value={values.brand}
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={t("brand")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="brand"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.brand && !!errors.brand}
                  helperText={touched.brand && errors.brand}
                  color="success"
                  disabled={isUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  value={values.model}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("model")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="model"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.model && !!errors.model}
                  helperText={touched.model && errors.model}
                  disabled={isUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>CARROCERIA</InputLabel>
                  <Select
                    id="select"
                    value={values.bodywork}
                    label="CARROCERIA"
                    onChange={handleChange}
                    name="bodywork"
                    disabled={isUpdate}
                  >
                    <MenuItem value={"SEDAN"}>SEDAN</MenuItem>
                    <MenuItem value={"SUV"}>SUV</MenuItem>
                    <MenuItem value={"MINIVAN"}>MINIVAN</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  value={values.plate}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("plate")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="plate"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.plate && !!errors.plate}
                  helperText={touched.plate && errors.plate}
                  disabled={isUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  value={values.year}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="number"
                  label={t("year")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="year"
                  error={!!touched.year && !!errors.year}
                  helperText={touched.year && errors.year}
                  disabled={isUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  value={values.color}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("color")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="color"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.color && !!errors.color}
                  helperText={touched.color && errors.color}
                  disabled={isUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  value={values.tr_description}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("Revision Tecnica")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="tr_description"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.tr_description && !!errors.tr_description}
                  helperText={touched.tr_description && errors.tr_description}
                  disabled={isUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <MobileDatePicker
                      value={values.tr_end}
                      label={t("end")}
                      inputFormat="DD/MM/YYYY"
                      name="tr_end"
                      onChange={(newValue) => {
                        values.tr_end = newValue;
                        setDateEnd(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      disabled={isUpdate}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  value={values.soat_description}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("soat")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="soat_description"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={
                    !!touched.soat_description && !!errors.soat_description
                  }
                  helperText={
                    touched.soat_description && errors.soat_description
                  }
                  disabled={isUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <MobileDatePicker
                      value={values.soat_end}
                      label={t("end")}
                      inputFormat="DD/MM/YYYY"
                      name="dateEnd"
                      onChange={(newValue) => {
                        values.soat_end = newValue;
                        setSoatEnd(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      disabled={isUpdate}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  value={values.owner}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label="Propietario"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="owner"
                  disabled={isUpdate}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.descriptionTR && !!errors.descriptionTR}
                  helperText={touched.descriptionTR && errors.descriptionTR}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  value={values.owner_dni}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label="Dni del Propietario"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="owner_dni"
                  disabled={isUpdate}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.descriptionTR && !!errors.descriptionTR}
                  helperText={touched.descriptionTR && errors.descriptionTR}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  value={values.owner_address}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label="Direccion del Propietario"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="owner_address"
                  disabled={isUpdate}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.descriptionTR && !!errors.descriptionTR}
                  helperText={touched.descriptionTR && errors.descriptionTR}
                />
              </Grid>

              <Grid item xs={12} sm={2} md={2}>
                <TextField
                  value={values.cylinder}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label="Cilindrada"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="cylinder"
                  disabled={isUpdate}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.descriptionTR && !!errors.descriptionTR}
                  helperText={touched.descriptionTR && errors.descriptionTR}
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <TextField
                  value={values.weight}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label="Peso"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="weight"
                  disabled={isUpdate}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.descriptionTR && !!errors.descriptionTR}
                  helperText={touched.descriptionTR && errors.descriptionTR}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  value={values.engine}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label="MOTOR"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="engine"
                  disabled={isUpdate}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.descriptionTR && !!errors.descriptionTR}
                  helperText={touched.descriptionTR && errors.descriptionTR}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  value={values.series}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label="SERIE"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="series"
                  disabled={isUpdate}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.descriptionTR && !!errors.descriptionTR}
                  helperText={touched.descriptionTR && errors.descriptionTR}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2} textAlign="center">
                <FormControl>
                  <FormLabel id="row-radio-buttons">PREMIUM:</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="row-radio-buttons"
                    value={premium}
                    onChange={handleChangeBooking}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio color="success" />}
                      label="NO"
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio color="success" />}
                      label="SI"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                {/* <FormControlLabel
                  value={values.setare_permission}
                  label={t("setare")}
                  disabled={isUpdate}
                  control={
                    <Checkbox
                      color="success"
                      checked={checkSetare}
                      onChange={(e) => {
                        console.log(e.target.checked);
                        setCheckSetare(e.target.checked);
                        values.setare_permission = e.target.checked;
                        //handleSetare
                      }}
                      name="setare_permission"
                    />
                  }
                /> */}
              </Grid>

              {values.setare_permission ? (
                <>
                  <Grid item xs={12} sm={4} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={0}>
                        <MobileDatePicker
                          value={values.setare_start}
                          label={t("start")}
                          inputFormat="DD/MM/YYYY"
                          name="dateStart"
                          onChange={(newValue) => {
                            values.setare_start = newValue;
                            setSoatEnd(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          disabled={isUpdate}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={0}>
                        <MobileDatePicker
                          value={values.setare_end}
                          label={t("end")}
                          inputFormat="DD/MM/YYYY"
                          name="dateEnd"
                          onChange={(newValue) => {
                            values.setare_end = newValue;
                            setSoatEnd(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          disabled={isUpdate}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                </>
              ) : (
                <div> </div>
              )}

              <Grid item xs={12} sm={12}>
                <TextField
                  value={values.notes}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("Notas")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="notes"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.notes && !!errors.notes}
                  helperText={touched.notes && errors.notes}
                  disabled={isUpdate}
                />
              </Grid>

              <Grid item xs={6} sm={4} md={2}>
                <PDFDownloadLink
                  document={
                    <Document>
                      <Page size="A4" style={styles.page}>
                        <Header />
                        <Title />
                        <Body data={values} />
                        <Signature />
                        <Footer />
                      </Page>
                    </Document>
                  }
                  fileName={"contrato-arrendamiento.pdf"}
                >
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    sx={{ height: 45 }}
                  >
                    CONTRATO
                  </Button>
                </PDFDownloadLink>
              </Grid>

              <Grid item xs={6} sm={4} md={2}>
                <Constatacion plate={values.plate} />
              </Grid>

              {values.driverName && (
                <Grid item xs={6} sm={4} md={2}>
                  <Habilitacion data={values} />
                </Grid>
              )}

              {values.driverName && (
                <Grid item xs={6} sm={4} md={2}>
                  <Renovacion data={values} />
                </Grid>
              )}

              {values.driverName && (
                <Grid item xs={6} sm={4} md={2}>
                  <Vencidos data={values} />
                </Grid>
              )}
              {values.driverName && (
                <Grid item xs={6} sm={4} md={2}>
                  <Mejora data={values} />
                </Grid>
              )}
              {values.driverName && (
                <Grid item xs={6} sm={4} md={2}>
                  <Desafiliacion data={values} />
                </Grid>
              )}
              {values.driverName && (
                <Grid item xs={6} sm={4} md={2}>
                  <Constancia data={values} />
                </Grid>
              )}

              <Grid item xs={6} md={4}>
                <Button
                  fullWidth
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ height: 45 }}
                  disabled={isUpdate}
                >
                  ACTUALIZAR
                </Button>
              </Grid>
            </Grid>
          </Box>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={4000}
            handleClose={handleSnkClose}
            severity={severity}
            messageSnk={messageSnk}
          />
        </form>
      )}
    </Formik>
  );
};

export default VehicleForm;
