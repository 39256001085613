import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#000000";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000000",
    backgroundColor: "#4B44BE",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    color: "white",
    fontFamily: "Helvetica-Bold",
    flexGrow: 1,
    fontSize: 10,
  },
  index: {
    width: "5%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 3,
  },
  fullname: {
    width: "50%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
  },
  dni: {
    width: "18%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
  },
  country: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
  },
  age: {
    width: "7%",
    borderRightColor: borderColor,
    // borderRightWidth: 1,
    marginTop: 4,
  },
});

const TableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.index}>No</Text>
    <Text style={styles.fullname}>NOMBRES Y APELLIDOS</Text>
    <Text style={styles.dni}>DNI/PASSPORT</Text>
    <Text style={styles.country}>NACIONALIDAD</Text>
    <Text style={styles.age}>EDAD</Text>
  </View>
);

export default TableHeader;
