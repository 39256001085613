import { baseBackend } from "../base/baseUrl";
import { URL_ADDRESS } from "../../helpers/endpoint";

export const getEnterpriseAddress = async (token, enterprise_id) => {
  return await baseBackend.get(`${URL_ADDRESS}/enterprise/${enterprise_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getEnterpriseEmployeeAddress = async (
  token,
  employee_id,
  enterprise_id
) => {
  return await baseBackend.post(
    `${URL_ADDRESS}/employee`,
    JSON.stringify({ employee_id, enterprise_id }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const createAddress = async (token, address) => {
  return await baseBackend.post(URL_ADDRESS, JSON.stringify({ address }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const putAddress = async (token, address) => {
  return await baseBackend.put(URL_ADDRESS, JSON.stringify({ address }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteAddress = async (token, address_id) => {
  return await baseBackend.delete(`${URL_ADDRESS}/${address_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
