import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

import "dayjs/locale/es";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { CreateScheduledUsecase } from "../../../domain/usecases/schedule/CreateScheduledUseCase";
import { GetTourCompleteListUseCase } from "../../../domain/usecases/tour/GetTourCompleteListUseCase";
import { GetCountryAndCityListUsecase } from "../../../domain/usecases/city/GetCountryAndCityListUseCase";

const ScheduledCreateScreen = () => {
  const [lang, setLang] = useState(0);
  const [tourType, setTourType] = useState(0);

  const [tourSelect, setTourSelect] = useState({
    code: "",
    title: "",
    language_abbreviation: "",
  });

  const [tourListApi, setTourListApi] = useState([]);
  const [tourComplete, setTourComplete] = useState([]);
  const [countryComplete, setCountryComplete] = useState([]);
  const [countrySelect, setCountrySelect] = useState({ id: "", name: "" });
  const [cityComplete, setCityComplete] = useState([]);
  const [citySelect, setCitySelect] = useState({ id: "", name: "" });

  const [capacity, setCapacity] = useState(1);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [pricePen, setPricePen] = useState(0);
  const [priceUsd, setPriceUsd] = useState(0);
  const [note, setNote] = useState("");
  const [noteAdmin, setNoteAdmin] = useState("");
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [guide, setGuide] = useState("SI");

  useEffect(() => {
    getTourComplete();
  }, []);

  const getTourComplete = async () => {
    Promise.all([
      GetTourCompleteListUseCase(),
      GetCountryAndCityListUsecase(),
    ]).then(([tourResp, countryList]) => {
      if (tourResp.message === "success") {
        setTourListApi(tourResp.tour);
      } else setTourListApi([]);
      if (countryList.message === "success") {
        setCountryComplete(countryList.country);
      } else setCountryComplete([]);
    });
  };

  const handleTourSelect = (tour) => {
    setTourSelect(tour);
    setOrigin(tour.def_origin);
    setDestination(tour.def_destination);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const validationData = () => {
    if (tourSelect.code === "")
      return snackBarDialog("SELECCIONE EL TOUR", "error");
    if (Number(capacity) <= 0)
      return snackBarDialog("INGRESE NUM PERSONAS", "error");
    if (date == null) return snackBarDialog("SELECCIONE LA FECHA", "error");
    if (time == null) return snackBarDialog("SELECCIONE LA HORA", "error");
    if (pricePen <= 0) return snackBarDialog("INGRESE PRECIO (PEN)", "error");
    if (priceUsd <= 0) return snackBarDialog("INGRESE PRECIO (USD)", "error");

    return true;
  };

  const timePickerToTime = (time) => {
    const { $H, $m } = time;
    const hour =
      $H > 12
        ? $H % 12 < 10
          ? "0" + ($H % 12)
          : $H % 12
        : $H > 9
        ? $H
        : "0" + $H;
    const minute = $m < 10 ? `0${$m}` : $m;
    return `${hour}:${minute} ${$H > 11 ? "PM" : "AM"}`;
  };

  const datePickerToDate = (date) => {
    const { $D, $M, $y } = date;
    const day = $D > 9 ? $D : `0${$D}`;
    const month = $M + 1 > 9 ? $M + 1 : `0${$M + 1}`;
    return `${day}/${month}/${$y}`;
  };

  const handleReservationCreate = async () => {
    try {
      if (validationData()) {
        setIsLoading(true);

        const reservation = {
          tourId: tourSelect.id,
          guide: guide, //string [si no]
          origin,
          destination,
          capacity,
          date: datePickerToDate(date),
          time: timePickerToTime(time),
          pricePen: pricePen,
          priceUsd: priceUsd,
          note: note,
          noteAdmin: noteAdmin,
        };

        console.log(reservation);
        const resp = await CreateScheduledUsecase(reservation);
        if (resp.message === "success") {
          snackBarDialog("RESERVA CREADA");
        } else {
          snackBarDialog("ERROR AL CREAR LA RESERVA", "error");
        }

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="CREAR TOUR PROGRAMADO" subtitle="" />
            </Box>
            <Box>
              {/* crear un reservacion */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} md={2}>
                  <Stack spacing={2}>
                    <Autocomplete
                      id="country"
                      freeSolo
                      value={countrySelect}
                      onChange={(e, newValue) => {
                        if (newValue != null) {
                          setCountrySelect(newValue);
                          setCityComplete(newValue.cityList);
                        }
                      }}
                      onInputChange={(e, newInputValue) => {
                        if (newInputValue == "") {
                          setCityComplete([]);
                        }
                      }}
                      options={countryComplete}
                      getOptionLabel={(option) => `${option.name}`}
                      renderInput={(params) => (
                        <TextField {...params} label="PAIS" />
                      )}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={3} md={2}>
                  <Stack spacing={2}>
                    <Autocomplete
                      id="city"
                      freeSolo
                      value={citySelect}
                      onChange={(e, newValue) => {
                        if (newValue != null) {
                          setCitySelect(newValue);
                          setTourComplete([]);
                          console.log(citySelect.id);
                          console.log(countrySelect.id);
                          tourListApi.map((elem, idx) => {
                            if (
                              elem.country_id == countrySelect.id &&
                              elem.city_id == newValue.id
                            ) {
                              setTourComplete((oldTour) => [...oldTour, elem]);
                            }
                          });
                        }
                      }}
                      onInputChange={(e, newInputValue) => {
                        if (newInputValue == "") setTourComplete([]);
                      }}
                      options={cityComplete}
                      getOptionLabel={(option) => `${option.name}`}
                      renderInput={(params) => (
                        <TextField {...params} label="CIUDAD" />
                      )}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={8}>
                  {tourComplete.length > 0 && (
                    <Autocomplete
                      id="tour"
                      freeSolo
                      value={tourSelect}
                      onChange={(e, newValue) => {
                        if (newValue != null) handleTourSelect(newValue);
                      }}
                      // onInputChange={(e, newInputValue) => {
                      //   if (newInputValue == "") setFindTour([]);
                      // }}
                      options={tourComplete}
                      getOptionLabel={(option) =>
                        `${option.code} ${option.title} ${option.language_abbreviation}`
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="SELECCIONE TOUR" />
                      )}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={2} md={1}>
                  <TextField
                    value={capacity}
                    fullWidth
                    variant="outlined"
                    type="number"
                    onChange={(e) => setCapacity(e.target.value)}
                    label="Capacidad"
                    InputLabelProps={{ style: { color: "white" } }}
                    name="capacity"
                  />
                </Grid>

                <Grid item xs={12} sm={2} md={1}>
                  <FormControl fullWidth>
                    <InputLabel style={{ color: "white" }}>GUIA</InputLabel>
                    <Select
                      value={guide}
                      onChange={(e) => setGuide(e.target.value)}
                      label="GUIA"
                      name="guide"
                    >
                      <MenuItem value={"SI"}>SI</MenuItem>
                      <MenuItem value={"NO"}>NO</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"es"}
                  >
                    <MobileDatePicker
                      value={date}
                      label="FECHA"
                      inputFormat="DD/MM/YYYY"
                      onChange={(newValue) => {
                        console.log(newValue);
                        setDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      label="HORA"
                      value={time}
                      name="time"
                      onChange={(newValue) => {
                        console.log(newValue);
                        setTime(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={4} md={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        value={priceUsd}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setPriceUsd(e.target.value)}
                        label={"P. USD"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="total"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={pricePen}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setPricePen(e.target.value)}
                        label={"P. PEN"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="total"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={origin}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setOrigin(e.target.value)}
                        label={"ORIGEN"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="origin"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={destination}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setDestination(e.target.value)}
                        label={"DESTINO"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="destination"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    value={note}
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(e) => setNote(e.target.value)}
                    label={"NOTAS"}
                    InputLabelProps={{ style: { color: "white" } }}
                    name="note"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={noteAdmin}
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(e) => setNoteAdmin(e.target.value)}
                    label={"NOTAS ADMIN"}
                    InputLabelProps={{ style: { color: "white" } }}
                    name="noteAdmin"
                  />
                </Grid>
                <Grid item xs={0} sm={8}></Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="success"
                    onClick={handleReservationCreate}
                    disabled={isLoading}
                  >
                    GUARDAR
                  </Button>
                </Grid>
              </Grid>

              <SnackBar
                stateSnk={stateSnk}
                vertical={vertical}
                horizontal={horizontal}
                duration={4000}
                handleClose={handleSnkClose}
                severity={severity}
                messageSnk={messageSnk}
              />
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default ScheduledCreateScreen;
