import { readCountryList } from "../../../data/repository/country.repo";

export const ReadCountryListUseCase = async () => {
  try {
    const response = await readCountryList();
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
