import { URL_EMPLOYEE } from "../../../helpers/endpoint";
import { baseBackend } from "../../base/baseUrl";

export const getEmployeesByName = async (token, name) => {
  return await baseBackend.get(`${URL_EMPLOYEE}/${name}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getRates = async (enterpriseId) => {
  return await baseBackend.get(`/api/v1/enterprise-rates/${enterpriseId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const createRates = async (rate) => {
  return await baseBackend.post(
    `/api/v1/enterprise-rate`,
    JSON.stringify({ rate }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const createRateList = async (id, rate) => {
  return await baseBackend.post(
    `/api/v1/enterprise-rate/${id}/list`,
    JSON.stringify({ rate }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const enabledRate = async (enabled, id) => {
  return await baseBackend.put(
    `/api/v1/enterprise-rate/${id}`,
    JSON.stringify({ enabled }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateRate = async (rate) => {
  return await baseBackend.put(
    `/api/v1/enterprise-rate`,
    JSON.stringify({ rate }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getRoutes = async (enterpriseId) => {
  return await baseBackend.get(`/api/v1/enterprise-route/${enterpriseId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getRoutesAddress = async (enterpriseId) => {
  return await baseBackend.get(
    `/api/v1/enterprise-routes-address/${enterpriseId}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const getRatesAndRoutesAddress = async (enterpriseId) => {
  return await baseBackend.get(
    `/api/v1/enterprise-rates-routes/${enterpriseId}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const getRoutesGlobal = async () => {
  return await baseBackend.get(`/api/v1/enterprise-routes-global`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getCityList = async () => {
  return await baseBackend.get(`/api/v1/rate-city`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const createRateAddress = async (address) => {
  return await baseBackend.post(
    "/api/v1/rate-address",
    JSON.stringify({ address }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getRateAddress = async () => {
  return await baseBackend.get("/api/v1/rate-address", {
    headers: { "Content-Type": "application/json" },
  });
};

export const createRoute = async (routes) => {
  return await baseBackend.post(
    "/api/v1/enterprise-route",
    JSON.stringify({ routes }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const createRouteAddress = async (address) => {
  return await baseBackend.post(
    "/api/v1/enterprise-route-address",
    JSON.stringify({ address }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const updateRouteAddress = async (route) => {
  return await baseBackend.put(
    `/api/v1/enterprise-route-address`,
    JSON.stringify({ route }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const deleteRouteAddress = async (route) => {
  return await baseBackend.put(
    `/api/v1/enterprise-route-address/delete`,
    JSON.stringify({ route }),
    { headers: { "Content-Type": "application/json" } }
  );
};
