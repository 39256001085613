import { URL_BOOKING_BUSINESS } from "../../helpers/endpoint";
import { baseBackend } from "../base/baseUrl";

export const getBookingBusiness = async (token) => {
  return await baseBackend.get(URL_BOOKING_BUSINESS, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBookingBusinessByID = async (token, id) => {
  return await baseBackend.get(`${URL_BOOKING_BUSINESS}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createBookingBusiness = async (token, booking) => {
  return await baseBackend.post(
    URL_BOOKING_BUSINESS,
    JSON.stringify({ booking }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateBookingBusiness = async (token, booking) => {
  return await baseBackend.put(
    URL_BOOKING_BUSINESS,
    JSON.stringify({ booking }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateBookingBusinessCredit = async (token, booking) => {
  return await baseBackend.put(
    `${URL_BOOKING_BUSINESS}-credit`,
    JSON.stringify({ booking }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const putBookingBusiness = async (
  token,
  discount,
  payment_type,
  order
) => {
  return await baseBackend.put(
    `${URL_BOOKING_BUSINESS}/status`,
    JSON.stringify({
      discount,
      payment_type,
      order,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateBookingInvoice = async (token, keys) => {
  return await baseBackend.put(
    `${URL_BOOKING_BUSINESS}/invoice`,
    JSON.stringify({ keys }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

//aun no funciona
export const updateStatusBookingBusiness = async (token, booking) => {
  return await baseBackend.put(
    `${URL_BOOKING_BUSINESS}/statusPayment`,
    JSON.stringify({ booking }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
//aun no funciona
export const updateStatusBookingBusinessAdmin = async (
  token,
  state_payment,
  id
) => {
  return await baseBackend.put(
    `${URL_BOOKING_BUSINESS}/status_Payment`,
    JSON.stringify({ state_payment, id }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateBusinessTravelStatus = async (id, travelStatus) => {
  return await baseBackend.put(
    `${URL_BOOKING_BUSINESS}-travel`,
    JSON.stringify({ id, travelStatus }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};