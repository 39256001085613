import {
  Autocomplete,
  Box,
  Button,
  CssBaseline,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../../../../components/header/Header";
import Topbar from "../../../../../components/topbar/TopBar";

//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../components/sidebar/Sidebar";

import { Formik } from "formik";
import { tokens } from "../../../../../theme";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { DataGrid, esES } from "@mui/x-data-grid";
import {
  createRouteAddress,
  getRoutesGlobal,
} from "../../../../../api/users/enterprise/enterpriseApi";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import UpdateModal from "../../employee/rates/UpdateModal";
import DeleteModal from "../../employee/rates/DeleteModal";

const RateList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [routeGlobal, setRouteGlobal] = useState([]);
  const [addresses, setAddressess] = useState(null);
  const [addressOrigin, setAddressOrigin] = useState(null);
  const [addressDestination, setAddressDestination] = useState(null);

  const [cities, setCities] = useState(null);
  const [city, setCity] = useState(null);

  const addressInitial = {
    sedanDriver: 0,
    sedanTotal: 0,
    sedanTax: 0,
    suvDriver: 0,
    suvTotal: 0,
    suvTax: 0,
    miniDriver: 0,
    miniTotal: 0,
    miniTax: 0,
    mini15Driver: 0,
    mini15Total: 0,
    mini15Tax: 0,
  };

  const addressSchema = yup.object().shape({
    sedanDriver: yup.number().required("required"),
    sedanTotal: yup.number().required("required"),
    sedanTax: yup.number().required("required"),
    suvDriver: yup.number().required("required"),
    suvTotal: yup.number().required("required"),
    suvTax: yup.number().required("required"),
    miniDriver: yup.number().required("required"),
    miniTotal: yup.number().required("required"),
    miniTax: yup.number().required("required"),
    mini15Driver: yup.number().required("required"),
    mini15Total: yup.number().required("required"),
    mini15Tax: yup.number().required("required"),
  });

  useEffect(() => {
    fetchRoutesGlobal();
  }, []);

  const fetchRoutesGlobal = async () => {
    try {
      const response = await getRoutesGlobal();
      const data = response.data;
      console.log(data);
      setAddressess(data.addressGlobal);
      setCities(data.cities);
      setRouteGlobal(data.routeGlobal);
    } catch (error) {
      console.log(error);
    }
  };

  const validationData = () => {
    if (!city) return snackBarDialog("SELECCIONE LA CIUDAD", "error");

    if (!addressOrigin) return snackBarDialog("SELECCIONE ORIGEN", "error");

    if (!addressDestination)
      return snackBarDialog("SELECCIONE DESTINO", "error");

    return true;
  };

  const handleFormSubmit = async (values) => {
    try {
      if (validationData()) {
        setIsLoading(true);

        const routes = {
          global: true,
          enterprise_id: null,
          address_origin_id: addressOrigin.id,
          address_destination_id: addressDestination.id,
          city_id: city.id,
          total: values.sedanTotal,
          driver: values.sedanDriver,
          tax: values.sedanTax,
          suvTotal: values.suvTotal,
          suvDriver: values.suvDriver,
          suvTax: values.suvTax,
          miniTotal: values.miniTotal,
          miniDriver: values.miniDriver,
          miniTax: values.miniTax,
          mini15Total: values.mini15Total,
          mini15Driver: values.mini15Driver,
          mini15Tax: values.mini15Tax,
        };

        await createRouteAddress(routes);
        values.sedanTotal = 0;
        values.sedanDriver = 0;
        values.sedanTax = 0;
        values.suvTotal = 0;
        values.suvDriver = 0;
        values.suvTax = 0;
        values.miniTotal = 0;
        values.miniDriver = 0;
        values.miniTax = 0;
        values.mini15Total = 0;
        values.mini15Driver = 0;
        values.mini15Tax = 0;
        setAddressOrigin(null);
        setAddressDestination(null);
        setCity(null);
        setIsLoading(false);
        snackBarDialog("RUTA GLOBAL REGISTRADA");
      }
    } catch (error) {
      setIsLoading(false);
      snackBarDialog("ERROR: NO SE LOGRO REGISTRAR LA RUTA", "error");
    }
  };

  const columns = [
    {
      headerName: "CIUDAD",
      type: "string",
      field: "cityName",
      align: "left",
      width: 100,
    },
    {
      headerName: "ORIGEN",
      field: "originName",
      headerAlign: "left",
      align: "left",
      width: 300,
    },
    {
      headerName: "DESTINO",
      field: "destinationName",
      headerAlign: "left",
      align: "left",
      width: 300,
    },
    {
      headerName: "SEDAN",
      field: "driver",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Impuesto",
      field: "tax",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Total",
      field: "total",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "SUV",
      field: "suv_driver",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Impuesto",
      field: "suv_tax",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Total",
      field: "suv_total",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "MINIVAN",
      field: "mini_driver",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Impuesto",
      field: "mini_tax",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Total",
      field: "mini_total",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "MINIVAN_15",
      field: "mini15_driver",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Impuesto",
      field: "mini15_tax",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Total",
      field: "mini15_total",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "",
      field: "actions",
      width: 120,
      align: "center",
      renderCell: ({ row: { id } }) => {
        return (
          <Stack direction="row" spacing={1}>
            <UpdateModal
              idRoute={id}
              address={addresses}
              cities={cities}
              routes={routeGlobal}
            />
            <DeleteModal idRoute={id} />
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="RUTAS GLOBALES" subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <Formik
                  onSubmit={handleFormSubmit}
                  initialValues={addressInitial}
                  validationSchema={addressSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box backgroundColor={colors.primary[400]} p={2}>
                        <Grid
                          container
                          spacing={1}
                          sx={{ alignItems: "center" }}
                        >
                          <Grid item xs={12} sm={6} md={5}>
                            {addresses && (
                              <Autocomplete
                                value={addressOrigin}
                                disablePortal
                                id="select-origin"
                                onChange={(e, newValue) =>
                                  setAddressOrigin(newValue)
                                }
                                options={addresses}
                                getOptionLabel={(option) =>
                                  `${option.id} ${option.name}`
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t("Direccion A")}
                                  />
                                )}
                              />
                            )}
                          </Grid>

                          <Grid item xs={12} sm={6} md={5}>
                            {addresses && (
                              <Autocomplete
                                value={addressDestination}
                                disablePortal
                                id="select-origin"
                                onChange={(e, newValue) => {
                                  setAddressDestination(newValue);
                                }}
                                options={addresses}
                                getOptionLabel={(option) =>
                                  `${option.id} ${option.name}`
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t("Direccion B")}
                                  />
                                )}
                              />
                            )}
                          </Grid>

                          <Grid item xs={12} sm={6} md={2}>
                            {cities && (
                              <Autocomplete
                                value={city}
                                disablePortal
                                id="select-origin"
                                onChange={(e, newValue) => {
                                  setCity(newValue);
                                }}
                                options={cities}
                                getOptionLabel={(option) => `${option.name}`}
                                renderInput={(params) => (
                                  <TextField {...params} label={t("CIUDAD")} />
                                )}
                              />
                            )}
                          </Grid>

                          {/* SEDAN */}
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography textAlign={"center"}>SEDAN</Typography>
                            <Grid container spacing={1}>
                              <Grid item xs={4} sm={4} md={4}>
                                <TextField
                                  value={values.sedanDriver}
                                  name="sedanDriver"
                                  fullWidth
                                  type="number"
                                  label="Conductor"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    const value = e.target.value;
                                    const tax =
                                      (values.sedanTotal || 0) - (value || 0);
                                    setFieldValue("sedanTax", tax);
                                  }}
                                  error={
                                    !!touched.sedanDriver &&
                                    !!errors.sedanDriver
                                  }
                                  helperText={
                                    touched.sedanDriver && errors.sedanDriver
                                  }
                                />
                              </Grid>

                              <Grid item xs={4} sm={4} md={4}>
                                <TextField
                                  value={values.sedanTax}
                                  name="sedanTax"
                                  fullWidth
                                  type="number"
                                  label="Inpuesto"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  disabled
                                />
                              </Grid>

                              <Grid item xs={4} sm={4} md={4}>
                                <TextField
                                  value={values.sedanTotal}
                                  name="sedanTotal"
                                  fullWidth
                                  type="number"
                                  label="TOTAL"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    const value = e.target.value;
                                    const tax =
                                      (value || 0) - (values.sedanDriver || 0);
                                    setFieldValue("sedanTax", tax);
                                  }}
                                  error={
                                    !!touched.sedanTotal && !!errors.sedanTotal
                                  }
                                  helperText={
                                    touched.sedanTotal && errors.sedanTotal
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* SUV */}
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography textAlign={"center"}>SUV</Typography>
                            <Grid container spacing={1}>
                              <Grid item xs={4} sm={4} md={4}>
                                <TextField
                                  value={values.suvDriver}
                                  name="suvDriver"
                                  fullWidth
                                  type="number"
                                  label="Conductor"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    const value = e.target.value;
                                    const tax =
                                      (values.suvTotal || 0) - (value || 0);
                                    setFieldValue("suvTax", tax);
                                  }}
                                  error={
                                    !!touched.suvDriver && !!errors.suvDriver
                                  }
                                  helperText={
                                    touched.suvDriver && errors.suvDriver
                                  }
                                />
                              </Grid>

                              <Grid item xs={4} sm={4} md={4}>
                                <TextField
                                  value={values.suvTax}
                                  name="suvTax"
                                  fullWidth
                                  type="number"
                                  label="Impuesto"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  disabled
                                />
                              </Grid>

                              <Grid item xs={4} sm={4} md={4}>
                                <TextField
                                  value={values.suvTotal}
                                  name="suvTotal"
                                  fullWidth
                                  type="number"
                                  label="Total"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    const value = e.target.value;
                                    const tax =
                                      (value || 0) - (values.suvDriver || 0);
                                    setFieldValue("suvTax", tax);
                                  }}
                                  error={
                                    !!touched.suvTotal && !!errors.suvTotal
                                  }
                                  helperText={
                                    touched.suvTotal && errors.suvTotal
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* MINIVAN */}
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography textAlign={"center"}>
                              MINIVAN
                            </Typography>
                            <Grid container spacing={1}>
                              <Grid item xs={4} sm={4} md={4}>
                                <TextField
                                  value={values.miniDriver}
                                  name="miniDriver"
                                  fullWidth
                                  type="number"
                                  label="Conductor"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    const value = e.target.value;
                                    const tax =
                                      (values.miniTotal || 0) - (value || 0);
                                    setFieldValue("miniTax", tax);
                                  }}
                                  error={
                                    !!touched.miniDriver && !!errors.miniDriver
                                  }
                                  helperText={
                                    touched.miniDriver && errors.miniDriver
                                  }
                                />
                              </Grid>

                              <Grid item xs={4} sm={4} md={4}>
                                <TextField
                                  value={values.miniTax}
                                  name="miniTax"
                                  fullWidth
                                  type="number"
                                  label="Impuesto"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  disabled
                                />
                              </Grid>

                              <Grid item xs={4} sm={4} md={4}>
                                <TextField
                                  value={values.miniTotal}
                                  name="miniTotal"
                                  fullWidth
                                  type="number"
                                  label="Total"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    const value = e.target.value;
                                    const tax =
                                      (value || 0) - (values.miniDriver || 0);
                                    setFieldValue("miniTax", tax);
                                  }}
                                  error={
                                    !!touched.miniTotal && !!errors.miniTotal
                                  }
                                  helperText={
                                    touched.miniTotal && errors.miniTotal
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* MINIVAN_15 */}
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography textAlign={"center"}>
                              MINIVAN_15
                            </Typography>
                            <Grid container spacing={1}>
                              <Grid item xs={4} sm={4} md={4}>
                                <TextField
                                  value={values.mini15Driver}
                                  name="mini15Driver"
                                  fullWidth
                                  type="number"
                                  label="Conductor"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    const value = e.target.value;
                                    const tax =
                                      (values.mini15Total || 0) - (value || 0);
                                    setFieldValue("mini15Tax", tax);
                                  }}
                                  error={
                                    !!touched.mini15Driver &&
                                    !!errors.mini15Driver
                                  }
                                  helperText={
                                    touched.mini15Driver && errors.mini15Driver
                                  }
                                />
                              </Grid>

                              <Grid item xs={4} sm={4} md={4}>
                                <TextField
                                  value={values.mini15Tax}
                                  name="mini15Tax"
                                  fullWidth
                                  type="number"
                                  label="Impuesto"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  disabled
                                />
                              </Grid>

                              <Grid item xs={4} sm={4} md={4}>
                                <TextField
                                  value={values.mini15Total}
                                  name="mini15Total"
                                  fullWidth
                                  type="number"
                                  label="Total"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    const value = e.target.value;
                                    const tax =
                                      (value || 0) - (values.mini15Driver || 0);
                                    setFieldValue("mini15Tax", tax);
                                  }}
                                  error={
                                    !!touched.mini15Total &&
                                    !!errors.mini15Total
                                  }
                                  helperText={
                                    touched.mini15Total && errors.mini15Total
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={3} md={3} margin={"auto"}>
                            <Button
                              type="submit"
                              color="secondary"
                              variant="contained"
                              fullWidth
                              sx={{ height: 45 }}
                              disabled={isLoading}
                            >
                              AGREGAR
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                      <SnackBar
                        stateSnk={stateSnk}
                        vertical={vertical}
                        horizontal={horizontal}
                        duration={5000}
                        handleClose={handleSnkClose}
                        severity={severity}
                        messageSnk={messageSnk}
                      />
                    </form>
                  )}
                </Formik>

                <Box
                  m="10px 0 0 0"
                  height="75vh"
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .name-column--cell": {
                      color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: colors.blueAccent[700],
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                      borderTop: "none",
                      backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                  }}
                >
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={routeGlobal}
                    columns={columns}
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default RateList;
