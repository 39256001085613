import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { db } from "../../../../firebase";
import { ref, onValue, update } from "firebase/database";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
  Grid,
  Stack,
  OutlinedInput,
} from "@mui/material";
import { tokens } from "../../../../theme";
import SnackBar from "../../../../components/snackbar/SnackBar";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { formatterTimePicker } from "../../../../helpers/date/formatterTimePicker";
import { dateFormat, timeFormat } from "../../../../helpers/date/date";
import { UpdateDataBackup } from "../backup/usecases/UpdateDataBackup";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { suggestionBackgroundColor } from "../booking/style/BookingStyle";
import { Formik } from "formik";

const FormQuoteHour = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [booking, setBooking] = useState(null);

  const { t } = useTranslation();

  const [note, setNote] = useState("");
  const [noteAdmin, setNoteAdmin] = useState("");
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [advance, setAdvance] = useState(0);
  const [currency, setCurrency] = useState(1);
  const [persons, setPersons] = useState(0);

  const [dateformat, setDateFormat] = useState("");
  const [timeFormatter, setTimeFormat] = useState("");

  useEffect(() => {
    const starCountRef = ref(db, `bookingNow/${uuid}`);
    onValue(starCountRef, (snapshot) => {
      let data = snapshot.val();

      if (data === null) return console.log("No Booking");
      setOrigin(data.origin.name);
      setDestination(data.destination.name);
      setORef(data.origin.name);
      setDRef(data.destination.name);
      setNote(data.note);
      setTotal(data.total);
      setAdvance(data.advance ? data.advance : 0);
      setDiscount(data.discount ? data.discount : 0);
      setCurrency(data.currency);
      setBooking(data);
      setPersons(data.persons);
      setDateFormat(dayjs(data.date, "DD/MM/YYYY").toDate());
      setTimeFormat(formatterTimePicker(data.date, data.time));
    });
  }, []);

  const handleClickSave = async (value) => {
    try {
      setIsLoading(true);
      value.cotizado = false;
      value.currency = currency;
      value.statusPayment = "ASIGNADO";
      value.date = value.date.$d ? dateFormat(value.date.$d) : value.date;
      value.time = value.time.$d ? timeFormat(value.time.$d) : value.time;
      value.discount = discount;
      value.advance = advance;
      value.total = total;
      value.persons = persons;
      value.note = note;
      value.noteAdmin = noteAdmin;

      if (value.origin.name != oRef) {
        value.origin = {
          name: origin,
          lat: originCoords.lat,
          lng: originCoords.lng,
        };
      }
      if (value.destination.name != dRef) {
        value.destination = {
          name: origin,
          lat: destCoords.lat,
          lng: destCoords.lng,
        };
      }

      const openpay = {
        createBy: "A",
        status: "pending",
        btnPay: true,
        currency,
        transaction_id: "",
        order_id: "",
        operation_date: "",
        url: "",
      };

      if (advance > 0) value.openpay = openpay;

      const backup = {
        id: value.counterBooking,
        date: value.date.$d ? dateFormat(value.date.$d) : value.date,
        time: value.time.$d ? timeFormat(value.time.$d) : value.time,
        currency: currency,
        advance: Number(advance),
        discount: Number(discount),
        total: Number(total),
        persons: Number(persons),
        note: note,
        noteAdmin: noteAdmin,
      };

      await update(ref(db, `bookingNow/${uuid}`), value);
      await UpdateDataBackup(backup);

      setIsLoading(false);
      snackBarDialog("COTIZACION GUARDADA");
      setTimeout(() => {
        navigate("/admin/list-book-quote");
      }, 5000);
    } catch (error) {
      setIsLoading(false);
      snackBarDialog("ERROR DE COTIZACION GUARDADA", "error");
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };
  const handleCloseSnk = () => setStateSnk(false);

  //editar origen
  const [oRef, setORef] = useState("");
  const [dRef, setDRef] = useState("");

  const [origin, setOrigin] = useState("");
  const [originCoords, setOriginCoords] = useState({ lat: null, lng: null });
  const [destination, setDestination] = useState("");
  const [destCoords, setDestCoords] = useState({ lat: null, lng: null });
  const handleOriSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setOrigin(value);
    setOriginCoords(ll);
  };
  const handleDestSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setDestination(value);
    setDestCoords(ll);
  };

  const BoxSuggestion = ({
    loading,
    suggestions,
    getSuggestionItemProps,
    state,
  }) => {
    return (
      <Box
        key={suggestions.description}
        className={`autocomplete-dropdown-container ${
          state ? "suggestions" : "suggestionsDestination"
        }`}
      >
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion) => {
          const className = suggestion.active
            ? "suggestion-item--active"
            : "suggestion-item";
          const style = suggestion.active
            ? suggestionBackgroundColor(colors.primary[500])
            : suggestionBackgroundColor(colors.primary[300]);
          return (
            <div
              key={suggestion.description}
              {...getSuggestionItemProps(suggestion, {
                className,
                style,
              })}
            >
              <span>{suggestion.description}</span>
            </div>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      {booking && (
        <Box backgroundColor={colors.primary[400]} p={2} m={1} borderRadius={3}>
          <Formik>
            {({ handleChange }) => (
              <form>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <PlacesAutocomplete
                      value={origin}
                      onChange={setOrigin}
                      onSelect={handleOriSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div key={suggestions.description}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Origen</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              onChange={handleChange("weight")}
                              aria-describedby="outlined-weight-helper-text"
                              label="Origen"
                              {...getInputProps({
                                placeholder: t("origin"),
                                className: "location-search-input",
                              })}
                            />
                          </FormControl>
                          <BoxSuggestion
                            loading={loading}
                            suggestions={suggestions}
                            getSuggestionItemProps={getSuggestionItemProps}
                            state={true}
                          />
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PlacesAutocomplete
                      value={destination}
                      onChange={setDestination}
                      onSelect={handleDestSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div key={suggestions.description}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Destino</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              onChange={handleChange("weight")}
                              aria-describedby="outlined-weight-helper-text"
                              label="Destino"
                              {...getInputProps({
                                placeholder: t("destination"),
                                className: "location-search-input",
                              })}
                            />
                          </FormControl>
                          <BoxSuggestion
                            loading={loading}
                            suggestions={suggestions}
                            getSuggestionItemProps={getSuggestionItemProps}
                            state={false}
                          />
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>

          <Grid container spacing={1} marginTop={1}>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                value={booking.origin.name}
                fullWidth
                variant="outlined"
                color="success"
                type="text"
                label={t("origin")}
                name="origin"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={booking.destination.name}
                fullWidth
                variant="outlined"
                color="success"
                type="text"
                label={t("destination")}
                name="destination"
              />
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <TextField
                value={booking.clientName}
                fullWidth
                disabled
                variant="outlined"
                color="success"
                type="text"
                label={t("client")}
                name="client"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <MobileDatePicker
                        label={t("date")}
                        inputFormat="DD/MM/YYYY"
                        name="date"
                        value={dateformat}
                        onChange={(newValue) => {
                          setDateFormat(newValue);
                          booking.date = newValue;
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <MobileTimePicker
                        label={t("time")}
                        value={timeFormatter}
                        name="time"
                        onChange={(newValue) => {
                          setTimeFormat(newValue);
                          booking.time = newValue;
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <TextField
                    value={persons}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="number"
                    label={t("persons")}
                    name="persons"
                    onChange={(e) => setPersons(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>{t("Model")}</InputLabel>
                    <Select
                      value={booking.vehicle}
                      id="vehicle"
                      name="vehicle"
                      label={t("vehicle")}
                      onChange={(e) => {
                        booking.vehicle = e.target.value;
                      }}
                    >
                      <MenuItem value={1}>{t("Sedan")}</MenuItem>
                      <MenuItem value={2}>{t("SUV")}</MenuItem>
                      <MenuItem value={3}>{t("Minivan")}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} sm={2}>
              <FormControl fullWidth>
                <InputLabel>{t("currency")}</InputLabel>
                <Select
                  value={currency}
                  id="currency"
                  name="currency"
                  label={t("currency")}
                  onChange={(e) => {
                    setCurrency(e.target.value);
                  }}
                >
                  <MenuItem value={1}>{t("PEN")}</MenuItem>
                  <MenuItem value={2}>{t("USD")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={2}>
              <TextField
                value={advance}
                name="advance"
                fullWidth
                variant="outlined"
                color="success"
                type="number"
                label="Adelanto"
                onChange={(e) => {
                  setAdvance(e.target.value);
                }}
                inputProps={{ style: { fontSize: 14 } }}
              />
            </Grid>

            <Grid item xs={6} sm={2}>
              <TextField
                value={discount}
                name="discount"
                fullWidth
                variant="outlined"
                color="success"
                type="number"
                label="Descuento"
                onChange={(e) => {
                  setDiscount(e.target.value);
                }}
                inputProps={{ style: { fontSize: 14 } }}
              />
            </Grid>

            <Grid item xs={6} sm={2}>
              <TextField
                value={total}
                fullWidth
                variant="outlined"
                color="success"
                type="number"
                label={t("total")}
                onChange={(e) => {
                  setTotal(e.target.value);
                }}
                name="total"
                inputProps={{ style: { fontSize: 14 } }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                value={note}
                fullWidth
                name="note"
                variant="outlined"
                color="success"
                type="text"
                label={t("notes")}
                onChange={(e) => {
                  console.log(e.target.value);
                  setNote(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                value={noteAdmin}
                fullWidth
                name="note"
                variant="outlined"
                color="success"
                type="text"
                label="Notas solo para Admin"
                onChange={(e) => setNoteAdmin(e.target.value)}
              />
            </Grid>

            <Grid item xs={6} sm={12} textAlign={"end"}>
              <Button
                color="secondary"
                variant="contained"
                onClick={(e) => {
                  handleClickSave(booking);
                }}
                disabled={isLoading}
              >
                {t("saveChanges")}
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={4000}
            handleClose={handleCloseSnk}
            severity={severity}
            messageSnk={messageSnk}
          />
        </Box>
      )}
    </>
  );
};

export default FormQuoteHour;
