import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, CssBaseline, Menu, MenuItem, Fade } from "@mui/material";

import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { getBookingNowClientById } from "../../../../api/booking/bookingNow";
import DataTable from "../../../../components/datatable/DataTable";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

const ClientHistory = () => {
  const { id } = useParams();
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));

  const [booking, setBooking] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    const getHistoryBookingClient = async () => {
      try {
        const token = "jjj";
        const response = await getBookingNowClientById(token, id);
        setBooking([]);
        Object.values(response?.data).map((booking) => {
          setBooking((oldArray) => [...oldArray, booking]);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getHistoryBookingClient();
  }, []);

  const columns = [
    {
      headerName: "Pedido",
      field: "order_number",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "Fecha",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Hora",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Nombre",
      field: "clientName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 180,
    },
    {
      headerName: "Origen",
      field: "origin",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "Destino",
      field: "destination",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "Pack",
      field: "persons",
      headerAlign: "left",
      align: "center",
      width: 20,
    },
    {
      headerName: "Notas",
      field: "notes",
      width: 180,
    },
    {
      headerName: "Tipo",
      field: "booking_type",
      width: 180,
    },
    {
      headerName: "Creado",
      field: "created_by",
      width: 80,
    },
    {
      headerName: "",
      field: "actions",
      width: 90,
      renderCell: ({ row: { id } }) => {
        return (
          <div>
            <Button
              color="secondary"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => {
                console.log(id);
                setAnchorEl(event.currentTarget);
              }}
            >
              OPCIONES
            </Button>
            <Menu
              id="menu-options"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={handleClose}>VER DETALLES</MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="HISTORIAL DE PEDIDOS" subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <DataTable data={booking} columns={columns} />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};

export default ClientHistory;
