import React from "react";
import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import courgette from "../../../../font/CourgetteRegular.ttf";
import logo from "../../../../assets/contratologo.jpg";

Font.register({
  family: "Courgette",
  src: courgette,
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignContent: "center",
  },

  textContainer: {
    width: "80%",
    height: 55,
    justifyContent: "flex-end",
  },
  text: {
    color: "#000000",
    fontSize: 18,
    fontFamily: "Courgette",
  },
  logo: {
    width: 130,
    height: 50,
    marginRight: "auto",
  },
});

const Header = () => {
  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={styles.text}>
          Servicios Generales Trans Mundo E.I.R.L.
        </Text>
      </View>
      <Image style={styles.logo} src={logo} />
    </View>
  );
};

export default Header;
