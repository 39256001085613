import { Box, CssBaseline } from "@mui/material";
import { useParams } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Topbar from "../../../../components/topbar/TopBar";

//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";
import TourForm from "./views/TourForm";
import TourTable from "./views/TourTable";

const Tour = () => {
  const { id } = useParams();
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="PANEL DE TOUR" subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <TourForm />
                <TourTable />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default Tour;
