import { Box, CssBaseline } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../../../../components/header/Header";
import Topbar from "../../../../../components/topbar/TopBar";
import { getTokenRefresh } from "../../../../../helpers/token/refreshToken";
import RoleForm from "./RoleForm";
import RoleList from "./RoleList";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../components/sidebar/Sidebar";

const RoleAdd = () => {
  const token = getTokenRefresh();
  const { id } = useParams();
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const location = useLocation();
  const enterpriseName = location.state.enterpriseName;

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={enterpriseName} subtitle="AGREGAR ROL" />
              <Box></Box>
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <RoleForm token={token} enterprise_id={id} />
                <RoleList token={token} enterprise_id={id} />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default RoleAdd;
