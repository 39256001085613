import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import { Formik } from "formik";
import * as yup from "yup";

import SnackBar from "../../../../../components/snackbar/SnackBar";
import TourDataTable from "./table/TourDataTable";
import { tokens } from "../../../../../theme";
import { tourSchema } from "./schema/TourSchema";
import { modalStyle } from "./style/TourStyle";
import { getListTourUseCase } from "../usecases/tour/GetListTourUseCase";
import { deleteTourUseCase } from "../usecases/tour/DeleteTourUseCase";
import { updateTourUseCase } from "../usecases/tour/updateTourUseCase";

const TourTable = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const checkoutSchema = yup.object().shape(tourSchema(yup));
  const [tours, setTours] = useState([]);
  const [updateTour, setUpdateTour] = useState({});

  useEffect(() => {
    const getTours = async () => {
      try {
        const response = await getListTourUseCase();
        console.log(response.data);
        setTours([]);
        setTours(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getTours();
  }, []);

  const columns = [
    {
      headerName: "PAIS",
      type: "string",
      field: "name",
      align: "left",
      width: 80,
    },
    {
      headerName: "DESCRIPCION",
      field: "description",
      headerAlign: "left",
      align: "left",
      width: 300,
    },
    {
      headerName: "URL",
      field: "url",
      headerAlign: "left",
      align: "left",
      width: 250,
    },
    {
      headerName: "PRECIO",
      field: "price",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 120,
      align: "center",
      renderCell: ({ row: { tour_id } }) => {
        return (
          <Stack direction="row" spacing={1}>
            {/* UPDATE */}
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                setUID(tour_id);
                setUpdateOpen(true);
                tours.map((el) => {
                  if (el.tour_id === tour_id) {
                    console.log(el);
                    setUpdateTour(el);
                  }
                });
              }}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
            {/* DELETE */}
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(tour_id);
                setOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);
  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [UID, setUID] = useState("");
  const handleClose = () => setOpen(false);
  const handleUpdateClose = () => setUpdateOpen(false);

  const updateFormSubmit = async (values) => {
    try {
      console.log(values);
      setIsLoading(true);
      const tour = {
        id: values.tour_id,
        code: values.code,
        country_id: values.country_id,
        description: values.description.toUpperCase(),
        url: values.url,
        price: values.price,
      };
      const response = await updateTourUseCase(tour);
      console.log(response);
      snackBarDialog("TOUR ACTUALIZADO");
      setIsLoading(false);
      setUpdateOpen(false);
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR DE ACTUALIZACION", "error");
      setIsLoading(false);
      setUpdateOpen(false);
    }
  };

  const handleAddressDelete = async () => {
    try {
      setIsLoading(true);
      const response = await deleteTourUseCase(UID);
      snackBarDialog("TARIFA ELIMINADA");
      setIsLoading(false);
      handleClose();
    } catch (error) {
      snackBarDialog("ERROR AL ELIMINAR", "error");
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <main className="content">
        <Box>
          <Box display="grid" gridAutoRows="120px">
            <Box gridColumn="span 12" gridRow="span 2">
              <TourDataTable data={tours} columns={columns} />
            </Box>
          </Box>
        </Box>

        <Modal open={updateOpen} onClose={handleUpdateClose}>
          <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
            <Typography
              variant="h6"
              component="h2"
              padding={2}
              textAlign={"center"}
            >
              ACTUALIZAR
            </Typography>
            <Formik
              onSubmit={updateFormSubmit}
              initialValues={updateTour}
              // validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box backgroundColor={colors.primary[400]}>
                    <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          value={values.name}
                          fullWidth
                          type="text"
                          label={t("country").toUpperCase()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 50,
                            style: { textTransform: "uppercase" },
                          }}
                          name="name"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          value={values.description}
                          fullWidth
                          type="text"
                          label={t("description").toUpperCase()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          name="description"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          value={values.url}
                          fullWidth
                          type="text"
                          label={t("URL").toUpperCase()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="url"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          value={values.price}
                          fullWidth
                          type="number"
                          label={"PRECIO"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="price"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} textAlign={"center"}>
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          sx={{ height: 45 }}
                          disabled={isLoading}
                        >
                          {t("actualizar")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Modal>
        <Modal open={open} onClose={handleClose}>
          <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
            <Typography variant="h6" component="h2">
              ELIMINAR DIRECCION?
            </Typography>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6} md={6} textAlign="center">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleAddressDelete}
                  disabled={isLoading}
                >
                  ELIMINAR
                </Button>
              </Grid>
              <Grid item xs={6} md={6} textAlign="center">
                <Button
                  color="error"
                  variant="contained"
                  onClick={handleClose}
                  disabled={isLoading}
                >
                  CANCELAR
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <SnackBar
          stateSnk={stateSnk}
          vertical={vertical}
          horizontal={horizontal}
          duration={5000}
          handleClose={handleSnkClose}
          severity={severity}
          messageSnk={messageSnk}
        />
      </main>
    </>
  );
};

export default TourTable;
