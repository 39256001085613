import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#3498DB";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000000",
    backgroundColor: "#3498DB",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    color: "white",
    fontFamily: "Helvetica-Bold",
    flexGrow: 1,
    fontSize: 10,
  },
  index: {
    width: "7%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 3,
    fontSize: 10,
  },
  fulldate: {
    width: "7%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
    fontSize: 10,
  },
  fulltime: {
    width: "7%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
    fontSize: 10,
  },
  origin: {
    width: "32%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
    fontSize: 10,
  },
  destination: {
    width: "32%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
    fontSize: 10,
  },
  toll: {
    width: "6%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
    fontSize: 9,
  },
  price: {
    width: "9%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
    fontSize: 9,
  },
});

const TableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.index}>No</Text>
    <Text style={styles.fulldate}>FECHA</Text>
    <Text style={styles.fulltime}>HORA</Text>
    <Text style={styles.origin}>ORIGEN</Text>
    <Text style={styles.destination}>DESTINO</Text>
    <Text style={styles.toll}>P.</Text>
    <Text style={styles.price}>PRECIO</Text>
  </View>
);

export default TableHeader;
