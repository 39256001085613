import { createPictureTour } from "../../../data/repository/picture.repo";

export const CreatePictureTourUseCase = async (relation) => {
  try {
    const resp = await createPictureTour(relation);
    return resp.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
