import { baseBackend } from "../base/baseUrl";
const URL_BACKUP = "/api/v1/backup-order";

export const createBackup = async (booking) => {
  return await baseBackend.post(
    `${URL_BACKUP}/create`,
    JSON.stringify({ booking }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const getBackup = async () => {
  return baseBackend.get(`${URL_BACKUP}/list`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const updateBackupData = async (backup) => {
  return baseBackend.put(`${URL_BACKUP}/data`, JSON.stringify({ backup }), {
    headers: { "Content-Type": "application/json" },
  });
};

export const updateBackupPay = async (backup) => {
  return baseBackend.put(`${URL_BACKUP}/pay`, JSON.stringify({ backup }), {
    headers: { "Content-Type": "application/json" },
  });
};

export const updateBackupQuote = async (backup) => {
  return baseBackend.put(`${URL_BACKUP}/quote`, JSON.stringify({ backup }), {
    headers: { "Content-Type": "application/json" },
  });
};

export const getBackupOld = async () => {
  return baseBackend.get(`/api/v1/backup`, {
    headers: { "Content-Type": "application/json" },
  });
};
