import { updateClientApi } from "../../../../../api/users/client/client";

export const UpdateStatusClientUseCase = async (clientId, status) => {
  try {
    const resp = await updateClientApi(clientId, status);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
