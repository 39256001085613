import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";
import Sidebar from "../../../../components/sidebar/Sidebar";
import {
  Box,
  Button,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { useTheme as themeStyles } from "@mui/material/styles";
import { CreateTourUseCase } from "./api/tour/usecases/createTourUseCase";

const ViajesArequipa = () => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [pricePen, setPricePen] = useState(0);
  const [priceUsd, setPriceUsd] = useState(0);
  const [city, setCity] = useState(0);
  const [photos, setPhotos] = useState([]);
  //conexcion a db,
  //guardar este tour

  const handleCreatedTour = async () => {
    try {
      if (title == "") return console.log("no title");
      if (subtitle == "") return console.log("no subtitle");
      if (pricePen == 0) return console.log("no PEN");
      if (priceUsd == 0) return console.log("no USD");
      if (city == 0) return console.log("no city");

      const tour = {
        uuid: "abcd-defg-hijk-lmno",
        title,
        subtitle,
        description: content,
        cityId: city,
        price: [
          { amount: pricePen, currencyId: 1 },
          { amount: priceUsd, currencyId: 2 },
        ],
      };
      const response = await CreateTourUseCase(tour);
      console.log(response);
    } catch (error) {
      console.log("view", error);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={"CREAR PAGINA DE TOUR"} subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={title}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setTitle(e.target.value)}
                      label={"TITULO"}
                      name="title"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={subtitle}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setSubtitle(e.target.value)}
                      label={"SUB TITULO"}
                      name="subtitle"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel>CIUDAD</InputLabel>
                      <Select
                        value={city}
                        id="city"
                        onChange={(e) => setCity(e.target.value)}
                        label={"CIUDAD"}
                        name="city"
                      >
                        <MenuItem value={1}>AREQUIPA</MenuItem>
                        <MenuItem value={2}>PUNO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <TextField
                      value={pricePen}
                      fullWidth
                      variant="outlined"
                      type="number"
                      onChange={(e) => setPricePen(e.target.value)}
                      label={"PRECIO PEN"}
                      name="pricePen"
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <TextField
                      value={priceUsd}
                      fullWidth
                      variant="outlined"
                      type="number"
                      onChange={(e) => setPriceUsd(e.target.value)}
                      label={"PRECIO USD"}
                      name="priceUsd"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box
                      style={{
                        background: "white",
                        color: "black",
                      }}
                    >
                      <JoditEditor
                        ref={editor}
                        value={content}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) => setContent(newContent)}
                        // onChange={(newContent) => {
                        //   setContent(newContent);
                        // }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box display="flex" justifyContent="end" mt="10px">
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleCreatedTour}
                      >
                        GUARDAR
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="120px"
              gap="10px"
              marginTop={2}
            ></Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default ViajesArequipa;
// const ViajesArequipa = () => {
//   const [value, setValue] = useState("");
//   const myChain = `<h4>DIA 1 : AREQUIPA / CHIVAY</h4>
//   <p>
//       <span style="color: rgb(255, 94, 19);">08:00 H.&nbsp;</span>La movilidad pasará por los diferentes hoteles para iniciar nuestro viaje a uno de los cañones más profundos del mundo. Nuestro recorrido será tomando la carretera de Yura, bordeando el nevado Chachani hacia pampa Cañahuas (Reserva
//       Nacional de Aguada Blanca y Salinas) hábitat de los bellos camélidos sudamericanos: las vicuñas, llamas, alpacas. En los bofedales de tocra podremos apreciar variedad de aves migratorias, si tenemos suerte podremos ver a los flamencos. Pasaremos por
//       el cráter de un volcán extinto (Chucura). Nos detendremos en el mirador de los volcanes, que es también la zona más alta del camino (4,910 m.s.n.m.) desde donde apreciaremos gran parte de la cordillera occidental.</p>`;
//   const printValue = () => {
//     console.log(value);
//   };

//   useEffect(() => {
//     function handleMouseUp(e) {
//       const selectedText = window.getSelection().toString().trim();
//       if (selectedText !== "") {
//         console.log(selectedText);
//       }
//     }

//     document.addEventListener("mouseup", handleMouseUp);

//     return () => {
//       document.removeEventListener("mouseup", handleMouseUp);
//     };
//   }, []);

//   return (
//     <>
//       <ReactQuill theme="snow" value={value} onChange={setValue} />
//       <button onClick={printValue}>Aceptar</button>
//       <div dangerouslySetInnerHTML={{ __html: myChain }} />
//     </>
//   );
// };
