import { isExpired, decodeToken } from "react-jwt";

export const saveToken = (name, token) => {
  window.localStorage.setItem(name, JSON.stringify(token));
};

export const getTokenRefresh = () => {
  const tokenJson = window.localStorage.getItem("atknre");

  if (!tokenJson) return null;

  const token = JSON.parse(tokenJson);
  return token;
};

export const getToken = (name) => {
  const tokenJson = window.localStorage.getItem(name);

  if (!tokenJson) return null;

  const token = JSON.parse(tokenJson);
  return token;
};

export const deleteTokenRefresh = () => {
  window.localStorage.removeItem("atknre");
};

export const verifyExpireTokenRefresh = () => {
  const token = getTokenRefresh();

  if (!token) return true;

  const isTokenExpired = isExpired(token);
  return isTokenExpired;
};

export const decodeAdminToken = (token) => {
  const { name, lastname, email } = decodeToken(token);
  return { name, lastname, email };
};
