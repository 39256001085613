import {
  Box,
  CssBaseline,
  Grid,
  Autocomplete,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { GetTourListUseCase } from "../../../domain/usecases/tour/GetTourListUseCase";
import CardTour from "../components/CardTour";

const TourListScreen = () => {
  const [description, setDescription] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetTourListUseCase();
      console.log(response);
      if (response.message === "success") {
        setDescription(response);
      } else {
        setDescription([]);
      }
    };
    fetchData();
  }, []);

  const [search, setSearch] = useState("");
  const [findTour, setFindTour] = useState([]);

  const searchTour = (text) => {
    const cadSplit = text.split(" ");
    console.log(cadSplit);
    setFindTour([]);
    const filter = description.tour.filter(
      (tour) =>
        tour.country_name == cadSplit[1] && tour.city_name == cadSplit[2]
    );
    setFindTour(filter);
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box display="flex" justifyContent="space-between">
              <Header title={"LISTA DE TOUR"} subtitle="" />
              <Stack spacing={2} sx={{ width: 300 }}>
                {description?.tour != undefined && (
                  <Autocomplete
                    id="search"
                    freeSolo
                    value={search}
                    onChange={(e, newValue) => {
                      if (newValue != null) searchTour(newValue);
                    }}
                    onInputChange={(e, newInputValue) => {
                      if (newInputValue == "") setFindTour([]);
                    }}
                    options={description?.tour?.map(
                      (option) =>
                        `${option.id} ${option.country_name} ${option.city_name}`
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Buscar" />
                    )}
                  />
                )}
              </Stack>
            </Box>
            <Box gap="10px">
              <Box>
                <Grid container spacing={1}>
                  {findTour.length > 0 ? (
                    findTour.map((tour, index) => (
                      <Grid key={index} item xs={12} sm={3} md={2}>
                        <CardTour tour={tour} />
                      </Grid>
                    ))
                  ) : (
                    <>
                      {description && description.tour.length > 0 ? (
                        description.tour.map((tour, index) => (
                          <Grid key={index} item xs={12} sm={4} md={3}>
                            <CardTour tour={tour} />
                          </Grid>
                        ))
                      ) : (
                        <>
                          <h2>cargando ...</h2>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default TourListScreen;
