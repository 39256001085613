export const rechargeSchema = (yup) => {
  return {
    amount: yup.number().required("required"),
  };
};

export const rechargeInitial = {
  amount: 0,
  operations_id: 3,
};
