import { readEnterpriseSede } from "../respository/sede.repository";

export const ReadEnterpriseSede = async (enterpriseId) => {
  try {
    const response = await readEnterpriseSede(enterpriseId);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
