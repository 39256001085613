import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { db } from "../../../../firebase";
import { ref, onValue, update } from "firebase/database";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
  Grid,
} from "@mui/material";
import { tokens } from "../../../../theme";
import SnackBar from "../../../../components/snackbar/SnackBar";

const FormQuote = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { client } = useParams();
  const { uuid } = useParams();

  const [booking, setBooking] = useState(null);

  const { t } = useTranslation();

  const [note, setNote] = useState("");
  const [total, setTotal] = useState([]);
  const [currency, setCurrency] = useState(1);

  useEffect(() => {
    const starCountRef = ref(db, `BookingLater/Clients/${client}/book/${uuid}`);
    onValue(starCountRef, (snapshot) => {
      let data = snapshot.val();

      if (data === null) return console.log("No Booking");

      setNote(data.note);
      setTotal(data.total);
      setCurrency(data.currency);
      setBooking(data);
    });
  }, []);

  const handleClickSave = async (value) => {
    try {
      setIsLoading(true);
      value.note = note;
      value.total = total;
      value.cotizado = false;
      value.currency = currency;
      value.statusPayment = "ASIGNADO";
      await update(
        ref(db, `BookingLater/Clients/${client}/book/${value.uuid}`),
        value
      );
      setIsLoading(false);
      snackBarDialog("COTIZACION GUARDADA");
    } catch (error) {
      setIsLoading(false);
      snackBarDialog("ERROR DE COTIZACION GUARDADA", "error");
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };
  const handleCloseSnk = () => setStateSnk(false);

  return (
    <>
      {booking && (
        <Box backgroundColor={colors.primary[400]} p={2} m={1} borderRadius={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={booking.origin.name}
                fullWidth
                variant="outlined"
                color="success"
                type="text"
                label={t("client")}
                name="client"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={booking.destination.name}
                fullWidth
                variant="outlined"
                color="success"
                type="text"
                label={t("destination")}
                name="destination"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={booking.clientName}
                fullWidth
                disabled
                variant="outlined"
                color="success"
                type="text"
                label={t("client")}
                name="client"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} md={3}>
                  <TextField
                    value={booking.date}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="text"
                    label={t("date")}
                    name="date"
                  />
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <TextField
                    value={booking.time}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="text"
                    label={t("time")}
                    name="time"
                  />
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <TextField
                    value={booking.persons}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="text"
                    label={t("persons")}
                    name="persons"
                  />
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>{t("Model")}</InputLabel>
                    <Select
                      value={booking.vehicle}
                      id="vehicle"
                      name="vehicle"
                      label={t("vehicle")}
                      onChange={(e) => {
                        console.log(e.target.value);
                        booking.vehicle = e.target.value;
                      }}
                    >
                      <MenuItem value={1}>{t("Sedan")}</MenuItem>
                      <MenuItem value={2}>{t("SUV")}</MenuItem>
                      <MenuItem value={3}>{t("Minivan")}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                value={note}
                fullWidth
                name="note"
                variant="outlined"
                color="success"
                type="text"
                label={t("notes")}
                onChange={(e) => {
                  console.log(e.target.value);
                  setNote(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <FormControl fullWidth>
                <InputLabel>{t("currency")}</InputLabel>
                <Select
                  value={currency}
                  id="currency"
                  name="currency"
                  label={t("currency")}
                  onChange={(e) => {
                    setCurrency(e.target.value);
                  }}
                >
                  <MenuItem value={1}>{t("PEN")}</MenuItem>
                  <MenuItem value={2}>{t("USD")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                value={total}
                fullWidth
                variant="outlined"
                color="success"
                type="number"
                label={t("total")}
                onChange={(e) => {
                  setTotal(e.target.value);
                }}
                name="total"
                inputProps={{ style: { fontSize: 14 } }}
              />
            </Grid>
            <Grid item xs={6} sm={12} textAlign={"end"}>
              <Button
                color="secondary"
                variant="contained"
                onClick={(e) => {
                  handleClickSave(booking);
                }}
                disabled={isLoading}
              >
                {t("saveChanges")}
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={4000}
            handleClose={handleCloseSnk}
            severity={severity}
            messageSnk={messageSnk}
          />
        </Box>
      )}
    </>
  );
};

export default FormQuote;
