import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import StatBox from "../../../../components/StatBox";

import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import { getTripsAndEarning } from "../../../../api/generate/reportTravel";

const GridReports = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [counterDay, setCounterDay] = useState();

  useEffect(() => {
    const getDay = async () => {
      try {
        const response = await getTripsAndEarning();
        setCounterDay(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getDay();
  }, []);

  return (
    <>
      {counterDay ? (
        <Grid container spacing={1} mt={1}>
          <Grid item xs={12}>
            <Box textAlign={"center"}>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                CLIENTES
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="Hoy"
                booking={counterDay.bookingDay[0].total_booking}
                tax={counterDay.bookingDay[0].total_tax_day}
                toll={counterDay.bookingDay[0].total_toll_day}
                commission={counterDay.bookingDay[0].total_commission_day}
                driver={counterDay.bookingDay[0].total_driver_day}
                total={counterDay.bookingDay[0].total_price_day}
              />
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="Semana"
                booking={counterDay.bookingWeek[0].total_booking}
                tax={counterDay.bookingWeek[0].total_tax_week}
                toll={counterDay.bookingWeek[0].total_toll_week}
                commission={counterDay.bookingWeek[0].total_commission_week}
                driver={counterDay.bookingWeek[0].total_driver_week}
                total={counterDay.bookingWeek[0].total_price_week}
              />
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="Este Mes"
                booking={counterDay.bookingMonth[0].total_booking}
                tax={counterDay.bookingMonth[0].total_tax_month}
                toll={counterDay.bookingMonth[0].total_toll_month}
                commission={counterDay.bookingMonth[0].total_commission_month}
                driver={counterDay.bookingMonth[0].total_driver_month}
                total={counterDay.bookingMonth[0].total_price_month}
              />
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="Este Año"
                booking={counterDay.bookingYear[0].total_booking}
                tax={counterDay.bookingYear[0].total_tax_year}
                toll={counterDay.bookingYear[0].total_toll_year}
                commission={counterDay.bookingYear[0].total_commission_year}
                driver={counterDay.bookingYear[0].total_driver_year}
                total={counterDay.bookingYear[0].total_price_year}
              />
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox title="loading..." />
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox title="loading..." />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box textAlign={"center"}>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                EMPRESAS
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={2} md={2}>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="Hoy"
                booking={counterDay.businessDay[0].total_booking}
                tax={counterDay.businessDay[0].total_tax_day}
                toll={counterDay.businessDay[0].total_toll_day}
                commission={counterDay.businessDay[0].total_commission_day}
                driver={counterDay.businessDay[0].total_driver_day}
                total={counterDay.businessDay[0].total_price_day}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={2} md={2}>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="Semana"
                booking={counterDay.businessWeek[0].total_booking}
                tax={counterDay.businessWeek[0].total_tax_week}
                toll={counterDay.businessWeek[0].total_toll_week}
                commission={counterDay.businessWeek[0].total_commission_week}
                driver={counterDay.businessWeek[0].total_driver_week}
                total={counterDay.businessWeek[0].total_price_week}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={2} md={2}>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="Mes"
                booking={counterDay.businessMonth[0].total_booking}
                tax={counterDay.businessMonth[0].total_tax_month}
                toll={counterDay.businessMonth[0].total_toll_month}
                commission={counterDay.businessMonth[0].total_commission_month}
                driver={counterDay.businessMonth[0].total_driver_month}
                total={counterDay.businessMonth[0].total_price_month}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="Año"
                booking={counterDay.businessYear[0].total_booking}
                tax={counterDay.businessYear[0].total_tax_year}
                toll={counterDay.businessYear[0].total_toll_year}
                commission={counterDay.businessYear[0].total_commission_year}
                driver={counterDay.businessYear[0].total_driver_year}
                total={counterDay.businessYear[0].total_price_year}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={2} md={2}>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox title="loading..." />
            </Box>
          </Grid>

          <Grid item xs={12} sm={2} md={2}>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="loading..."
                icon={
                  <TimeToLeaveIcon
                    sx={{
                      color: colors.greenAccent[600],
                      fontSize: "26px",
                    }}
                  />
                }
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <div> loading ...</div>
      )}
    </>
  );
};

export default GridReports;
