import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import logo from "../../../../../../assets/logo.png";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    marginTop: 20,
    alignContent:"center"
  },
  reportTitle: {
    width: "50%",
    color: "#4B44BE",
    fontSize: 20,
    textTransform: "uppercase",
  },
  logo: {
    width: 65,
    height: 65,
    marginLeft: 200,
    marginRight: "auto",
  },
});

const Title = ({ title }) => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>{title}</Text>
    <Image style={styles.logo} src={logo} />
  </View>
);

export default Title;