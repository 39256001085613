import React from "react";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { Button } from "@mui/material";
import Header from "./elements/Header";
import Footer from "./elements/Footer";
import Title from "./elements/Title";
import Body from "./elements/Body";
import Signature from "./elements/Signature";

const Desafiliacion = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    footer: {
      position: "absolute",
      bottom: 20, // Ajusta según sea necesario
      left: 0,
      right: 0,
      textAlign: "center",
      fontSize: 10,
      color: "grey",
    },
  });

  return (
    <PDFDownloadLink
      document={
        <Document>
          <Page size="A4" style={styles.page}>
            <Header />
            <Title />
            <Body data={data}/>
            <Signature />
            <Footer />
          </Page>
        </Document>
      }
      fileName={"constancia-desafiliacion.pdf"}
    >
      <Button
        fullWidth
        color="secondary"
        variant="contained"
        sx={{ height: 45 }}
      >
        DESAFILIACION
      </Button>
    </PDFDownloadLink>
  );
};

export default Desafiliacion;
