import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import cambria from "../../../../font/Cambria.ttf";
import { monthList } from "../../MonthList";

Font.register({
  family: "Cambria",
  src: cambria,
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  container: {
    width: "100%",
    marginTop: 30,
  },
  text: {
    color: "#000000",
    fontSize: 15,
    fontFamily: "Cambria",
    textAlign: "justify",
  },
  dateText: {
    marginTop: 50,
    color: "#000000",
    fontSize: 12,
    fontFamily: "Cambria",
    textAlign: "right",
  },
});

const Body = ({ data }) => {
  const paragraph1 = `  Por medio de la presente SERVICIOS GENERALES TRANS MUNDO E.I.R.L con RUC: 20455135134; certifica que el Sr. ${data.driverLastname} ${data.driverName}, identificado con DNI N° ${data.driverDni} trabaja como conductor profesional y afiliado en nuestra empresa, desde 08 de enero del 2018, ha demostrado puntualidad, honestidad y responsabilidad como conductor profesional. Se expide la presente constancia a solicitud del interesado para los fines que estime conveniente.`;

  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth();
  const year = currentDate.getFullYear();
  const date = `Arequipa a los ${day} días del mes de ${monthList[
    month
  ].toLowerCase()} del año ${year}`;
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{paragraph1}</Text>
      <Text style={styles.dateText}>{date}</Text>
    </View>
  );
};

export default Body;
