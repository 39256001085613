import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CssBaseline,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";

import { tokens } from "../../../../theme";
import { getGenres } from "../../../../api/resources/genres/genres";
import {
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../../helpers/token/refreshToken";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { getClientId, updateClient } from "../../../../api/users/client/client";
import SnackBar from "../../../../components/snackbar/SnackBar";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

const Client = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const { id } = useParams();
  const { t } = useTranslation();

  const [client, setClient] = useState("");
  const [genre, setGenre] = useState([]);
  const [isUpdate, setIsUpdate] = useState(true);

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    lastname: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    phone: yup.string().required("required"),
    address: yup.string().required("required"),
    genres_id: yup.number().required("required"),
  });

  useEffect(() => {
    const genres = async () => {
      try {
        const response = await getGenres();
        const data = response.data.genres.filter((genre) => genre.id);
        setGenre(data);
      } catch (error) {
        console.log(error);
      }
    };

    genres();
  }, []);

  useEffect(() => {
    const getClient = async () => {
      try {
        const token = getTokenRefresh();
        const response = await getClientId(token, id);
        const data = response.data;
        console.log(response.data);
        setClient(data[0]);
        setChecked(Boolean(data[0].frequent));
      } catch (error) {
        console.log(error);
      }
    };

    getClient();
  }, []);

  const handleFormSubmit = async (values) => {
    const expireToken = verifyExpireTokenRefresh();
    if (expireToken) navigate("/");

    try {
      const token = getTokenRefresh();
      const client = {
        id: values.id,
        name: values.name.toUpperCase(),
        lastname: values.lastname.toUpperCase(),
        phone: values.phone,
        address: values.address.toUpperCase(),
        genres_id: values.genres_id,
        frequent: checked,
      };
      await updateClient(token, client);
      snackBarDialog("DATOS ACTUALIZADOS");
    } catch (error) {
      snackBarDialog("ERROR NO SE ACTUALIZO LOS DATOS", "error");
      console.log(error);
    }
  };

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [checked, setChecked] = useState(false);

  const handleChange1 = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="Cliente" subtitle="" />
              <Grid>
                <Button
                  color="secondary"
                  disabled={client ? false : true}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/admin/client-history/${client.id}`);
                  }}
                >
                  <WorkHistoryOutlinedIcon />
                  Historial
                </Button>
                <Button
                  color="secondary"
                  onClick={(e) => {
                    setIsUpdate(!isUpdate);
                  }}
                >
                  <CreateOutlinedIcon />
                  EDITAR CLIENTE
                </Button>
              </Grid>
            </Box>

            {client ? (
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={client}
                validationSchema={checkoutSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      backgroundColor={colors.primary[400]}
                      borderRadius={3}
                      p={2}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.name}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            type="text"
                            label={t("name")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="name"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            error={!!touched.name && !!errors.name}
                            helperText={touched.name && errors.name}
                            color="success"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.lastname}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="text"
                            label={t("lastname")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="lastname"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            error={!!touched.lastname && !!errors.lastname}
                            helperText={touched.lastname && errors.lastname}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.email}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="text"
                            label={t("email")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.phone}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="tel"
                            label={t("phone")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="phone"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.address}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="text"
                            label={t("address")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="address"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            error={!!touched.address && !!errors.address}
                            helperText={touched.address && errors.address}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth disabled={isUpdate}>
                            <InputLabel>{t("genre")}</InputLabel>
                            <Select
                              id="genre"
                              value={values.genres_id}
                              name="genres_id"
                              label={t("genre")}
                              onChange={handleChange}
                            >
                              {genre.map((genre, index) => (
                                <MenuItem key={index} value={genre.id}>
                                  {t(genre.name)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <FormControlLabel
                            disabled={isUpdate}
                            label="FRECUENTE"
                            labelPlacement="top"
                            control={
                              <Checkbox
                                color="success"
                                checked={checked}
                                onChange={handleChange1}
                              />
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} textAlign={"end"}>
                          <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            disabled={isUpdate}
                          >
                            {t("ACTUALIZAR")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <SnackBar
                      stateSnk={stateSnk}
                      vertical={vertical}
                      horizontal={horizontal}
                      duration={5000}
                      handleClose={handleSnkClose}
                      severity={severity}
                      messageSnk={messageSnk}
                    />
                  </form>
                )}
              </Formik>
            ) : (
              <div>loading ... </div>
            )}
          </Box>
        </main>
      </div>
    </>
  );
};

export default Client;
