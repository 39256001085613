export const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  border: "1px solid #000",
  borderRadius: 3,
  boxShadow: 20,
  px: 2,
  pb: 2,
};