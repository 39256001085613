import { baseBackend } from "../../../api/base/baseUrl";
const URL_COUNTRY = "api/v1/module/country";

export const createCountry = async (country) => {
  return await baseBackend.post(`${URL_COUNTRY}`, country, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const readCountryList = async () => {
  return await baseBackend.get(`${URL_COUNTRY}/list`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getCountryAndCity = async () => {
  return await baseBackend.get(`${URL_COUNTRY}/city/list`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getCountryById = async (id) => {
  return await baseBackend.get(`${URL_COUNTRY}/id/${id}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const updateCountry = async (country) => {
  return await baseBackend.put(`${URL_COUNTRY}/update`, country, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
