import { getListAdvance } from "../../../data/repository/advance.repo";

export const GetAdvanceListUseCase = async (id) => {
  try {
    const resp = await getListAdvance(id);

    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
