import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import cambria from "../../../../font/Cambria.ttf";
import { monthList } from "../../MonthList";

Font.register({
  family: "Cambria",
  src: cambria,
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  container: {
    width: "100%",
    marginTop: 10,
  },

  text: {
    color: "#000000",
    fontSize: 13,
    fontFamily: "Cambria",
  },

  containerSubtitle: {
    marginTop: 15,
    marginBottom: 15,
    width: "100%",
    alignItems: "center",
    alignContent: "center",
    marginHorizontal: "auto",
  },
  subtitle: {
    textAlign: "center",
    color: "#000000",
    fontSize: 15,
    fontFamily: "Cambria",
  },

  dateText: {
    marginTop: 30,
    color: "#000000",
    fontSize: 12,
    fontFamily: "Cambria",
    textAlign: "right",
  },
});

const Body = ({ data }) => {
  const paragraph1 = `El que suscribe en calidad de gerente de la Empresa SERVICIOS GENERALES TRANS MUNDO E.I.R.L con N° de RUC 20455135134 Sr. JULIO LAURA CHAMBILLA con DNI 41697491 y con domicilio legal en Calle Abraham Valdelomar 126, Distrito A.S.A.`;

  const paragraph2 = `Hace constar que el Sr. ${data.driverLastname} ${data.driverName} con DNI ${data.driverDni} y con ${data.driverAddress}. es PROPIETARIO de la móvil de placa ${data.plate} el cual YA NO ES AFILIADO de nuestra empresa.`;

  const paragraph3 = `Se le pide la presente para los fines que crea conveniente.`;

  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth();
  const year = currentDate.getFullYear();
  const date = `Arequipa a los ${day} días del mes de ${monthList[
    month
  ].toLowerCase()} del año ${year}`;
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{paragraph1}</Text>

      <View style={styles.containerSubtitle}>
        <Text style={styles.subtitle}>HACE CONSTAR:</Text>
      </View>

      <Text style={styles.text}>{paragraph2}</Text>
      <Text style={styles.text}>{paragraph3}</Text>
      <Text style={styles.dateText}>{date}</Text>
    </View>
  );
};

export default Body;
