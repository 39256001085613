import { baseBackend } from "../../../api/base/baseUrl";
const URL_TOUR = "/api/v1/module/tour";

export const createTour = async (tour) => {
  return await baseBackend.post(URL_TOUR, tour, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const createTourEmpty = async () => {
  return await baseBackend.post(`${URL_TOUR}-empty`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getTourList = async () => {
  return await baseBackend.get(`${URL_TOUR}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getTourById = async (tourId) => {
  return await baseBackend.get(`${URL_TOUR}/read/${tourId}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getTourUrl = async () => {
  return baseBackend.get(`${URL_TOUR}/url/list`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getSingleTour = async (search) => {
  const queryString = new URLSearchParams(search).toString();
  return await baseBackend.get(`${URL_TOUR}/search?${queryString}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const updateTour = async (tour) => {
  return await baseBackend.put(`${URL_TOUR}`, tour, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const updateTourCron = async (tour) => {
  return await baseBackend.put(`${URL_TOUR}-cron`, JSON.stringify({ tour }), {
    headers: { "Content-Type": "application/json" },
  });
};

export const deleteTour = async (tourId) => {
  return await baseBackend.put(
    `${URL_TOUR}/delete`,
    JSON.stringify({ tourId }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

//ENABLE AND DISABLE TOUR
export const enableTour = async (tour) => {
  return await baseBackend.post(
    `${URL_TOUR}/open-change`,
    JSON.stringify({ tour }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getTourCompleteList = async () => {
  return await baseBackend.get(`${URL_TOUR}-horary`, {
    headers: { "Content-Type": "application/json" },
  });
};
