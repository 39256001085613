import { createBackup } from "../../../../../api/backup/backup";

export const CreateBackupUseCase = async (backup) => {
  try {
    const resp = await createBackup(backup);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
