import { createFooter } from "../../../data/repository/footer.repo";

export const CreateFooterUseCase = async (footer) => {
  try {
    const response = await createFooter(footer);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
