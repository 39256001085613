import React, { Fragment, useState } from "react";
import { Box, Button, CssBaseline, Grid, TextField } from "@mui/material";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { CreateFooterUseCase } from "../../../domain/usecases/footer/CreateFooterUseCase";

const FooterCreateScreen = () => {
  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [sectionUrl, setSectionUrl] = useState("");
  const [sectionList, setSectionList] = useState([
    { title: "", links: [{ name: "", url: "" }] },
  ]);

  const addSectionTitle = (value, section, index) => {
    sectionList[index] = { ...section, title: value };
    setSectionList([...sectionList]);
  };
  const addSectionName = (value, link, idxSection, idxLink) => {
    sectionList[idxSection].links[idxLink] = { ...link, name: value };
    setSectionList([...sectionList]);
  };
  const addSectionUrl = (value, link, idxSection, idxLink) => {
    sectionList[idxSection].links[idxLink] = { ...link, url: value };
    setSectionList([...sectionList]);
  };

  const addNewSection = () => {
    if (sectionList[sectionList.length - 1].title !== "") {
      setSectionList([
        ...sectionList,
        { title: "", links: [{ name: "", url: "" }] },
      ]);
      setSectionTitle("");
      setSectionName("");
      setSectionUrl("");
    } else {
      window.alert("INGRESE TODOS LOS CAMPOS PARA SEGUIR AGREGANDO");
    }
  };

  const addNewLink = (idx) => {
    const oldLinks = sectionList[idx].links;
    sectionList[idx].links = [...oldLinks, { name: "", url: "" }];
    setSectionList([...sectionList]);
    setSectionName("");
    setSectionUrl("");
  };

  const deleteNewLink = (idxSection, idxLink) => {
    const newSectionList = sectionList.map((section, idx) => {
      if (idx === idxSection) {
        return {
          ...section,
          links: section.links.filter((_, linkIndex) => linkIndex !== idxLink),
        };
      }
      return section;
    });

    setSectionList(newSectionList);
    setSectionName("");
    setSectionUrl("");
  };

  const registerFooter = async () => {
    const footer = {
      data: sectionList,
    };
    console.log(footer);
    const resp = await CreateFooterUseCase(footer);
    console.log(resp);
    if (resp.message === "success") alert("CREADO CORRECTAMENTE");
    else alert("ERROR");
    //buscar registrar en la base de datos
  };

  const labelColor = { color: "white" };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box margin="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={"CREAR FOOTER"} subtitle="" />
            </Box>

            <Box border={2} borderColor="white" padding={1} borderRadius={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={9}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={addNewSection}
                  >
                    AGREGAR SECCION
                  </Button>
                </Grid>
                {sectionList.map((item, idx) => {
                  return (
                    <Fragment key={idx}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          value={item.title ? item.title : sectionTitle}
                          fullWidth
                          variant="outlined"
                          type="text"
                          onChange={(e) => {
                            // setSectionTitle(e.target.value);
                            addSectionTitle(e.target.value, item, idx);
                          }}
                          label={"TITULO DE LA SECCION"}
                          InputLabelProps={{ style: labelColor }}
                          name="section"
                        />

                        {item.links.map((link, idxLink) => {
                          return (
                            <div
                              key={idxLink}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <TextField
                                value={link.name ? link.name : sectionName}
                                fullWidth
                                variant="outlined"
                                type="text"
                                sx={{ margin: "10px" }}
                                onChange={(e) => {
                                  addSectionName(
                                    e.target.value,
                                    link,
                                    idx,
                                    idxLink
                                  );
                                }}
                                label={"Nombre"}
                                InputLabelProps={{ style: labelColor }}
                                name="name"
                              />
                              <TextField
                                value={link.url ? link.url : sectionUrl}
                                fullWidth
                                variant="outlined"
                                type="text"
                                sx={{ margin: "10px" }}
                                onChange={(e) => {
                                  addSectionUrl(
                                    e.target.value,
                                    link,
                                    idx,
                                    idxLink
                                  );
                                }}
                                label={"URL"}
                                InputLabelProps={{ style: labelColor }}
                                name="url"
                              />
                              {idxLink === 0 ? (
                                <Button
                                  variant="contained"
                                  color="success"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                    margin: "10px",
                                  }}
                                  onClick={(e) => addNewLink(idx)}
                                >
                                  +
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="success"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                    margin: "10px",
                                  }}
                                  onClick={(e) => deleteNewLink(idx, idxLink)}
                                >
                                  -
                                </Button>
                              )}
                            </div>
                          );
                        })}
                      </Grid>
                    </Fragment>
                  );
                })}
                <Grid item xs={12} sm={9}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={registerFooter}
                  >
                    REGISTRAR
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default FooterCreateScreen;
