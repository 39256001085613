import { URL_COMPANY } from "../../../helpers/endpoint";
import { baseBackend } from "../../base/baseUrl";

export const getCompanies = async (token) => {
  return await baseBackend.get(URL_COMPANY, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
