import { deleteDriverApi } from "../../../../../api/users/driver/driver";

export const DeleteDriverUseCase = async (driverId, status) => {
  try {
    const resp = await deleteDriverApi(driverId, status);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
