export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  
  border: '1px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 1,
};