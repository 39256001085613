import { useNavigate } from "react-router-dom";
import { Box, IconButton, Stack, useTheme } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../../../theme";

import { tourTypeList } from "../../../core/resources/tourList";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useState } from "react";
import { UpdateBookingStateUseCase } from "../../../domain/usecases/booking/UpdateBookingStateUseCase";
import SnackBar from "../../../../components/snackbar/SnackBar";

const tableStyle = (colors) => {
  return {
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "none",
    },
    "& .name-column--cell": {
      color: colors.greenAccent[300],
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: colors.blueAccent[700],
      borderBottom: "none",
    },
    "& .MuiDataGrid-virtualScroller": {
      backgroundColor: colors.primary[400],
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: colors.blueAccent[700],
    },
    "& .MuiCheckbox-root": {
      color: `${colors.greenAccent[200]} !important`,
    },
  };
};

const ReserveCompleteTable = ({ data, handleSelectReservation }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dataSelected = (data) => {
    handleSelectReservation(data);
  };

  const columns = [
    {
      headerName: "Orden",
      field: "id",
      type: "number",
      headerAlign: "left",
      align: "left",
      width: 60,
      renderCell: ({ row: { ...params } }) => {
        const url = `https://api.whatsapp.com/send?text=*Cliente:* ${params.name} ,%0A *TEL:* ${params.phone} %0A *Origen:* ${params.origin}%0A *Destino:* ${params.destination} %0A *Nota:* ${params.notes}`;
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <a href={url} target="_blank" style={anchorStyle}>
              {params.id}
            </a>
            <>{params.tourCode}</>
          </div>
        );
      },
    },
    {
      headerName: "FECHA",
      headerAlign: "center",
      field: "date",
      width: 130,
      renderCell: ({ row: { reservation_service_id, date, time, horary } }) => {
        return (
          <div>
            <p>{date}</p>
            <p>{reservation_service_id == 1 ? horary : time}</p>
          </div>
        );
      },
    },
    {
      headerName: "TITULO",
      field: "title",
      type: "number",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      headerName: "TIPO",
      field: "tourType",
      type: "number",
      headerAlign: "left",
      align: "left",
      width: 90,
      renderCell: ({ row: { tourType } }) => {
        return <div>{tourTypeList[tourType]}</div>;
      },
    },
    {
      headerName: "ORIGEN",
      field: "origin",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 230,
      renderCell: ({ row: { origin, destination } }) => {
        return (
          <div>
            <p>{origin}</p>
            <p>{destination}</p>
          </div>
        );
      },
    },
    {
      headerName: "CLIENTE  ",
      field: "name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 180,
      renderCell: ({ row: { name, phone, origin, destination, notes } }) => {
        const url = `https://api.whatsapp.com/send?phone=${phone}=&text=*Cliente:* ${name} ,%0A *TEL:* ${phone} %0A *Origen:* ${origin}%0A *Destino:* ${destination} %0A *Nota:* ${notes}`;
        return (
          <div>
            <p>{name}</p>
            <a href={url} target="_blank" style={anchorStyle}>
              {phone}
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Nota",
      field: "notes",
      width: 200,
    },
    {
      headerName: "NOTA ADMIN",
      field: "notes_admin",
      width: 200,
    },
    {
      headerName: "TOTAL",
      field: "advance",
      type: "string",
      headeralign: "left",
      align: "left",
      width: 100,
      renderCell: ({ row: { currency, total, advanceList } }) => {
        const advance = advanceList.reduce(
          (acc, curr) => acc + Number(curr.amount),
          0
        );
        return (
          <div>
            <p>
              {currency} {total}
            </p>
            <p>
              {currency} {total - advance}
            </p>
          </div>
        );
      },
    },
    {
      headerName: "ADELANTO",
      field: "advanceList",
      type: "string",
      headeralign: "left",
      align: "left",
      width: 100,
      renderCell: ({ row: { advanceList } }) => {
        return (
          <div>
            {Object.values(advanceList).map((item, idx) => (
              <p key={idx}>
                {item.currency} {item.amount}
              </p>
            ))}
          </div>
        );
      },
    },
  ];

  const statusStyle = {
    borderRadius: "5px",
    padding: "2px",
    fontWeight: "bold",
  };
  const anchorStyle = {
    color: "white",
    fontSize: 12,
    listStyle: "none",
    textDecoration: "none",
  };

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const handleSnkClose = () => setStateSnk(false);
  const vertical = "top";
  const horizontal = "center";

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const updateReservationState = async (params, state) => {
    setIsLoading(true);
    const reservation = {
      ...params,
      state,
      riderData: {
        name: params.name,
        email: params.email,
        phone: params.phone,
      },
    };

    const resp = await UpdateBookingStateUseCase(reservation);
    if (resp.message === "success") {
      snackBarDialog("RESERVA ACTUALIZADA");
    } else {
      snackBarDialog("ERROR: NO SE LOGRO ACTUALIZAR", "error");
    }
    setIsLoading(false);
  };

  return (
    <Box>
      <Box m="10px 0 0 0" height="100vh" sx={tableStyle(colors)}>
        <DataGrid
          getRowId={(row) => row.id}
          rows={data}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            var list = [];
            ids.map((id) => {
              const select = data.find((row) => row.id === id);
              list.push(select);
            });
            dataSelected(list);
          }}
        />
        <SnackBar
          stateSnk={stateSnk}
          vertical={vertical}
          horizontal={horizontal}
          duration={4000}
          handleClose={handleSnkClose}
          severity={severity}
          messageSnk={messageSnk}
        />
      </Box>
    </Box>
  );
};

export default ReserveCompleteTable;
