import { baseTour } from "../../base/base";
import { URL_TOUR } from "../../base/endpoint";

export const createTour = async (tour) => {
  return await baseTour.post(URL_TOUR, JSON.stringify({ tour }), {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getListTour = async () => {
  return await baseTour.get(`${URL_TOUR}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// export const updateInvoice = async (token, invoice) => {
//   return await baseBackend.put(URL_INVOICE, JSON.stringify({ invoice }), {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   });
// };
