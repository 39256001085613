import { URL_REPORT_BOOKING, URL_INVOICE } from "../../helpers/endpoint";
import { baseBackend } from "../base/baseUrl";

export const searchBookingEnterprisePerRole = async (
  enterprise_id,
  employee_role,
  from,
  to
) => {
  return await baseBackend.get(
    `${URL_REPORT_BOOKING}/enterprise/${enterprise_id}/role/${employee_role}/from/${from}/to/${to}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

//booking business por empleado
export const searchBookingEnterprisePerEmployee = async (
  employee_id,
  from,
  to
) => {
  return await baseBackend.get(
    `${URL_REPORT_BOOKING}/employee/${employee_id}/from/${from}/to/${to}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

//booking business por empleado
export const searchBookingPerEnterprise = async (enterprise_id, from, to) => {
  return await baseBackend.get(
    `${URL_REPORT_BOOKING}/enterprise/${enterprise_id}/from/${from}/to/${to}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const createInvoice = async (token, invoice) => {
  return await baseBackend.post(URL_INVOICE, JSON.stringify({ invoice }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getInvoiceBusiness = async (token) => {
  return await baseBackend.get(URL_INVOICE, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const searchInvoiceBusiness = async (token, id) => {
  return await baseBackend.get(`${URL_INVOICE}/business/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateInvoice = async (token, invoice) => {
  return await baseBackend.put(URL_INVOICE, JSON.stringify({ invoice }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const payInvoice = async (token, invoice) => {
  return await baseBackend.put(
    `${URL_INVOICE}/pay`,
    JSON.stringify({ invoice }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getInvoicePending = async (token) => {
  return await baseBackend.get(`${URL_INVOICE}/pending`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
