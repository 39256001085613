import React from "react";
import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import cambria from "../../../../font/Cambria.ttf";
import firma from "../../../../assets/contratofirma.png";
import { monthList } from "../../MonthList";

Font.register({
  family: "Cambria",
  src: cambria,
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  container: {
    width: "90%",
    marginTop: 15,
    alignContent: "flex-end",
    marginHorizontal: "auto",
  },
  presentation: {
    width: "60%",
    marginLeft: "auto",
  },
  content: {
    marginTop: 20,
    width: "100%",
    marginLeft: "auto",
  },
  option: {
    width: "100%",
    flexDirection: "row",
  },
  optionText: {
    paddingTop: 4,
    width: "95%",
    fontSize: 9,
  },
  mainText: {
    color: "#000000",
    fontSize: 12,
    fontFamily: "Cambria",
    textAlign: "justify",
  },
  text: {
    color: "#000000",
    fontSize: 11,
    fontFamily: "Cambria",
    textAlign: "justify",
  },
  subTitle: {
    marginTop: 10,
    color: "#000000",
    fontSize: 13,
    fontFamily: "Cambria",
    textAlign: "justify",
  },
  date: {
    marginTop: 10,
    color: "#000000",
    fontSize: 11,
    fontFamily: "Cambria",
    textAlign: "justify",
  },
  signature: {
    width: "60%",
    marginTop: 10,
    color: "#000000",
    fontSize: 11,
    fontFamily: "Cambria",
    borderTop: 1,
    borderTopColor: "#000000",
    borderTopStyle: "dashed",
  },
  logo: {
    width: 150,
    height: 70,
    marginRight: "auto",
    marginTop: 20,
  },
});

const Body = ({ data }) => {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth();
  const year = currentDate.getFullYear();

  const maintitle = "SEÑOR ALCALDE DE LA MUNICIPALIDAD PROVINCIAL DE AREQUIPA";
  const paragraph1 = `Yo, JULIO LAURA CHAMBILLA, representante legal de la empresa SERVICIOS GENERALES TRANSMUNDO E.I.R.L (Taxi Mundo), de código E-265; ante Ud. respetuosamente me presento y digo:`;

  const paragraph2 = `Que, con la finalidad de MEJORAR MAQUIPA es que necesito SUSTITUIR EL VEHICULO SALIENTE de placa N° ${data.plate}, por el VEHICULO ENTRANTE de placa N° .................; en el siguiente procedimiento avalado en la O.M. 928-2015.`;

  const option1 = `Sustitución de Vehículo con autorizacion SETARE`;
  const option2 = `Sustitución de Vehículo con autorizacion TRANSITORIO`;

  const paragraph5 = `Arequipa, ${day} de ${monthList[month]} de ${year}`;

  const signature = `Firma del representante legal`;

  return (
    <View style={styles.container}>
      <Text style={styles.mainText}>{maintitle}</Text>

      <View style={styles.presentation}>
        <Text style={{ ...styles.text, marginTop: 5 }}>{paragraph1}</Text>
      </View>
      <View style={styles.content}>
        <Text style={styles.text}>{paragraph2}</Text>
      </View>
      <View style={styles.option}>
        <Text style={{ paddingTop: 4, width: "95%", fontSize: 10 }}>
          {option1}
        </Text>
        <View
          style={{ border: "black", borderWidth: 1, width: "5%", height: 15 }}
        ></View>
      </View>
      <View style={styles.option}>
        <Text style={{ paddingTop: 4, width: "95%", fontSize: 10 }}>
          {option2}
        </Text>
        <View
          style={{ border: "black", borderWidth: 1, width: "5%", height: 15 }}
        ></View>
      </View>

      <View style={{ marginTop: 10 }}>
        <Text style={{ fontFamily: "Helvetica-Bold" }}>Requisitos Comunes</Text>
      </View>
      <View style={{ ...styles.option, marginTop: 5 }}>
        <Text style={styles.optionText}>
          Autorizacion de SETARE vigente del vehiculo saliente.
        </Text>
        <View
          style={{ border: "black", borderWidth: 1, width: "5%", height: 15 }}
        ></View>
      </View>
      <View style={{ ...styles.option, marginTop: 2 }}>
        <Text style={styles.optionText}>
          Boletin informativo de SUNARP del vehiculo entrante con una antiguedad
          no mayor a 30 dias.
        </Text>
        <View
          style={{ border: "black", borderWidth: 1, width: "5%", height: 15 }}
        ></View>
      </View>
      <View style={{ ...styles.option, marginTop: 2 }}>
        <Text style={styles.optionText}>
          Copia simple de SOAT vigente del vehiculo entrante.
        </Text>
        <View
          style={{ border: "black", borderWidth: 1, width: "5%", height: 15 }}
        ></View>
      </View>

      <View style={{ ...styles.option, marginTop: 2 }}>
        <Text style={styles.optionText}>
          Copia simple del certificado de Inspeccion Tecnica Vehicular
          complementaria aprobada y vigente de la unidad entrante.
        </Text>
        <View
          style={{ border: "black", borderWidth: 1, width: "5%", height: 15 }}
        ></View>
      </View>
      <View style={{ ...styles.option, marginTop: 2 }}>
        <Text style={styles.optionText}>
          Recibo de pago por derechos. S/. 68.70.
        </Text>
        <View
          style={{ border: "black", borderWidth: 1, width: "5%", height: 15 }}
        ></View>
      </View>

      <View style={{ ...styles.option, marginTop: 2 }}>
        <Text style={styles.optionText}>
          Certificado de constatacion de caracteristicas (aprobado y vigente)
        </Text>
        <View
          style={{ border: "black", borderWidth: 1, width: "5%", height: 15 }}
        ></View>
      </View>
      <View style={{ ...styles.option, marginTop: 2 }}>
        <Text style={styles.optionText}>
          Copia simple del contrato de arrendamiento con firmas legalizadas
        </Text>
        <View
          style={{ border: "black", borderWidth: 1, width: "5%", height: 15 }}
        ></View>
      </View>

      <View style={{ marginTop: 10 }}>
        <Text style={{ fontFamily: "Helvetica-Bold" }}>
          En caso de mejoramiento de maquina:
        </Text>
        <Text style={{ ...styles.option, marginTop: 5 }}>
          Boletin informativo de Registro Publicos donde conste la titularidad
          del vehiculo saliente con vigencia de 1 dia.
        </Text>
      </View>

      <View style={{ marginTop: 10 }}>
        <Text style={{ fontFamily: "Helvetica-Bold" }}>
          En caso de siniestro y/o robados:
        </Text>
      </View>
      <View style={{ ...styles.option, marginTop: 5 }}>
        <Text style={styles.optionText}>
          Copia certificada del atestado policial que acredite la ocurrencia del
          siniestro o robo del vehiculo
        </Text>
        <View
          style={{ border: "black", borderWidth: 1, width: "5%", height: 15 }}
        ></View>
      </View>
      <View style={{ ...styles.option, marginTop: 5 }}>
        <Text style={styles.optionText}>
          Certificado del cierre o cancelacion de la partida registral del
          Registro Publicos (SUNARP)
        </Text>
        <View
          style={{ border: "black", borderWidth: 1, width: "5%", height: 15 }}
        ></View>
      </View>

      <View style={styles.presentation}>
        <Text style={{ ...styles.text, marginTop: 10 }}>POR LO EXPUESTO:</Text>
        <Text style={styles.text}>Pido a usted la atencion a mi solicitud</Text>
      </View>
      <View style={styles.presentation}>
        <Text style={styles.date}>{paragraph5}</Text>
      </View>
      <View style={styles.presentation}>
        <Image style={styles.logo} src={firma} />
        <Text style={styles.signature}>{signature}</Text>
      </View>
    </View>
  );
};

export default Body;
