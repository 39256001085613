import { URL_ADMIN } from "../../../helpers/endpoint";
import { baseBackend } from "../../base/baseUrl";

export const getAdmin = async (token) => {
  return await baseBackend.get(URL_ADMIN, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createAdmin = async (token, admin) => {
  return await baseBackend.post(URL_ADMIN, JSON.stringify({ admin }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updatePassword = async (token, admin_id, password) => {
  return await baseBackend.put(
    `${URL_ADMIN}/password`,
    JSON.stringify({ admin_id, password }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
