import { useState, useEffect, memo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { db } from "../../firebase";
import { ref, onValue, remove, update, get } from "firebase/database";
import {
  Box,
  Button,
  Grid,
  Modal,
  useTheme,
  Popper,
  Paper,
  Typography,
  Fade,
  Menu,
  MenuItem,
} from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WhereToVoteOutlinedIcon from "@mui/icons-material/WhereToVoteOutlined";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ContactlessIcon from "@mui/icons-material/Contactless";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { convertDate } from "../../helpers/date/convertDate";
import SnackBar from "../snackbar/SnackBar";
import { ModalStyle } from "./TableBookStyle";
import { sendNotificationTokens } from "../../api/fcm/notification";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
} from "@react-pdf/renderer";
import DocumentName from "./pdf/later/elements/DocumentName";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableBooking from "./pdf/later/elements/TableBooking";
import Header from "../header/Header";
import HeaderM from "../../views/users/admin/booking/history/pdf/later/elements/HeaderM";
import Contact from "./pdf/elements/Contact";
import { getEnterprise } from "../../api/enterprise/enterprise";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const TableBookBusinessLater = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [booking, setBooking] = useState([]);
  const [UID, setUID] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  useEffect(() => {
    const starCountRef = ref(db, "BookingBusiness");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setBooking([]);
      if (data !== null) {
        const compareByDate = (a, b) => {
          const dateB = convertDate(b.date, b.time);
          const dateA = convertDate(a.date, a.time);
          return dateA - dateB;
        };
        Object.values(data).map((booking) => {
          if (
            booking.booking_type &&
            booking.booking_type != 1 &&
            booking.cotizar
          ) {
            booking.fullorigin = booking.origin.name;
            booking.originLat = booking.origin.lat;
            booking.originLng = booking.origin.lng;

            booking.fulldestination = booking.destination.name;
            booking.destinationLat = booking.destination.lat;
            booking.destinationLng = booking.destination.lng;

            if (booking.booking_type == 3)
              booking.fullnote = `${booking.dateEnd}, ${booking.timeEnd}. ${booking.note}`;
            else booking.fullnote = booking.note;
            if (booking.booking_type == 4)
              booking.fullnote = `${booking.tour_description}. ${booking.note}`;
            else booking.fullnote = booking.note;

            setBooking((oldArray) =>
              [...oldArray, booking].sort(compareByDate)
            );
          }
        });
      }
    });
  }, []);

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };

  const [openPublishmentModal, setOpenPublishmentModal] = useState(false);
  const handleClosePublishmentModal = () => setOpenPublishmentModal(false);
  const [isLoading, setIsLoading] = useState(false);

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const handleClosePaymentModal = () => setOpenPaymentModal(false);

  //data menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const [uuidParam, setUuidParam] = useState("");
  const [clientParam, setClientParam] = useState("");
  const [publishmentParam, setPublishmentParam] = useState(false);

  const [openActiveBtnModal, setOpenActiveBtnModal] = useState(false);
  const handleCloseActiveBtnModal = () => setOpenActiveBtnModal(false);
  const [currencyType, setCurrencyType] = useState("");
  const [currencyParam, setCurrencyParam] = useState("");
  const [totalParam, setTotalParam] = useState("");
  const [clientID, setClientID] = useState("");
  const [counterBookingParam, setCounterBookingParam] = useState("");
  const [currencyPrice, setCurrencyPrice] = useState("");
  const currency = { 1: "PEN", 2: "USD" };

  const bookingTypeLabels = {
    1: "AHORA",
    2: "TRASLADO",
    3: "HORAS",
    4: "TOUR",
  };

  const [clientOrigin, setClientOrigin] = useState({
    latitude: 0,
    longitude: 0,
  });

  const columnsClient = [
    {
      headerName: "PEDIDO",
      field: "counterBooking",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 50,
      renderCell: ({ row: { ...props } }) => {
        const urlWhatsApp = `https://api.whatsapp.com/send?text=*PEDIDO:* ${
          props.counterBooking
        }%0A*Cliente:* ${props.clientName} ,%0A*Origen:* ${encodeURIComponent(
          props.fullorigin
        )}%0A*Destino:* ${encodeURIComponent(
          props.fulldestination
        )}%0A*Fecha:* ${props.date} - *Hora:* ${props.time}%0A*Pasajeros:* ${
          props.persons
        }%0A*Notas:* ${encodeURIComponent(props.fullnote)}%0A*TELEFONO:* ${
          props.clientPhone
        }`;
        return (
          <a
            href={urlWhatsApp}
            target="_blank"
            style={{ color: "white", textDecoration: "none" }}
          >
            {props.counterBooking}
          </a>
        );
      },
    },
    {
      headerName: "FECHA",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "HORA",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "CLIENTE",
      field: "clientName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 220,
      renderCell: ({ row: { clientName, clientPhone } }) => {
        return (
          <a
            href={`https://api.whatsapp.com/send?phone=${clientPhone}&text=*`}
            target="_blank"
            style={{ color: "white", textDecoration: "none" }}
          >
            {clientName}
          </a>
        );
      },
    },
    {
      headerName: "EMPRESA",
      field: "enterpriseName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      headerName: "Origen",
      field: "fullorigin",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: ({ row: { fullorigin, originLat, originLng } }) => {
        const url = `https://maps.google.com/?q=${originLat},${originLng}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 13,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {fullorigin}
          </a>
        );
      },
    },
    {
      headerName: "destino",
      field: "fulldestination",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: ({
        row: { fulldestination, destinationLat, destinationLng },
      }) => {
        const url = `https://maps.google.com/?q=${destinationLat},${destinationLng}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 13,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {fulldestination}
          </a>
        );
      },
    },
    {
      headerName: "Pack",
      field: "persons",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      headerName: "Notas",
      field: "fullnote",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Conductor",
      field: "booking_type",
      type: "string",
      width: 90,
      renderCell: ({ row: { booking_type } }) => {
        return (
          <div
            style={{
              fontSize: 12,
              width: "100%",
              color: "white",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {bookingTypeLabels[booking_type]}
          </div>
        );
      },
    },
    {
      headerName: "Estado",
      field: "statusPayment",
      width: 90,
      renderCell: ({ row: { statusPayment } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              width: "100%",
              color: "white",
              background: statusPayment === "PENDIENTE" ? "red" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {statusPayment}
          </div>
        );
      },
    },
    {
      headerName: "Creado",
      field: "createBooking",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "",
      field: "actions",
      width: 90,
      renderCell: ({
        row: {
          client,
          uuid,
          currency,
          total,
          publishment,
          counterBooking,
          originLat,
          originLng,
        },
      }) => {
        return (
          <>
            <Button
              color="secondary"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => {
                setUuidParam(uuid);
                setClientParam(client);
                setCurrencyParam(currency);
                setTotalParam(total);
                setPublishmentParam(publishment);
                setCounterBookingParam(counterBooking);
                setAnchorEl(event.currentTarget);
              }}
            >
              OPCIONES
            </Button>

            <Menu
              id="menu-options"
              MenuListProps={{ "aria-labelledby": "fade-button" }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem
                onClick={() =>
                  navigate(`/admin/add-booking-business-later/${uuidParam}`)
                }
              >
                <Edit sx={{ color: "#FF0404" }} />
                &nbsp;EDITAR
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setUID(uuidParam);
                  setOpenPaymentModal(true);
                  setAnchorEl(null);
                }}
              >
                <CheckCircleOutlineOutlinedIcon sx={{ color: "#FF0404" }} />
                &nbsp;CONFIRMAR PAGO
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setUID(uuidParam);
                  setClientID(clientParam);
                  setCurrencyType(currencyParam);
                  setCurrencyPrice(totalParam);
                  setOpenActiveBtnModal(true);
                  setAnchorEl(null);
                }}
              >
                <ContactlessIcon sx={{ color: "#FF0404" }} />
                &nbsp;PAGO CON TARJETA
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setUID(uuidParam);
                  setOpenPublishmentModal(true);
                  setClientOrigin({
                    latitude: originLat,
                    longitude: originLng,
                  });
                }}
              >
                <BookmarkAddOutlinedIcon
                  sx={{
                    color:
                      publishmentParam && publishmentParam === true
                        ? "#FFFFFF"
                        : "#FF0404",
                  }}
                />
                &nbsp;PUBLICAR RESERVA
              </MenuItem>

              {/* revisar si se ha apgado */}
              {/* {openpay &&
                openpay.status === "pending" &&
                openpay.transaction_id != "" && (
                  <MenuItem
                    color="success"
                    onClick={() => {
                      setUID(uuid);
                      setClientID(client);
                      checkPaymentClient(openpay, currency);
                    }}
                  >
                    <PriceCheckIcon color="success" />
                    VERIFICAR PAGO
                  </MenuItem>
                )} */}

              <MenuItem
                onClick={() => {
                  setUID(uuidParam);
                  setOpenDeleteModal(true);
                  setAnchorEl(null);
                }}
              >
                <DeleteIcon sx={{ color: "#FF0404" }} />
                &nbsp;ELIMINAR RESERVA
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setUID(uuidParam);
                  setAnchorEl(null);
                }}
              >
                <WhereToVoteOutlinedIcon sx={{ color: "#FF0404" }} />
                &nbsp;FINALIZAR VIAJE
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleCloseSnk = () => setStateSnk(false);

  const deleteQuote = async () => {
    try {
      await remove(ref(db, `BookingBusiness/${UID}`));
      handleCloseDeleteModal();
      snackBarDialog("RESERVA ELIMINADA");
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO SE PUEDE ELIMINAR LA RESERVA", "error");
    }
  };

  const [driverToken, setDriverToken] = useState([]);
  useEffect(() => {
    const dbRefToken = ref(db, "Token/Driver");
    get(dbRefToken)
      .then(async (snapshot) => {
        const data = snapshot.val();
        setDriverToken([]);
        if (data) {
          snapshot.forEach((childSnapshot) => {
            const key = childSnapshot.key;
            const value = childSnapshot.val();
            if (Boolean(value.isBooking) && Boolean(value.isPremium)) {
              const token = {
                token: value.token,
                key,
              };
              setDriverToken((oldTokens) => [...oldTokens, token]);
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error al obtener los datos:", error);
      });
  }, []);

  const checkDriversLatLng = async () => {
    try {
      const dbRefDriver = ref(db, "ActiveDrivers");
      const snapshot = await get(dbRefDriver);
      let listLatLng = [];

      if (snapshot.exists()) {
        snapshot.forEach((children) => {
          const key = children.key;
          driverToken.forEach((item) => {
            if (key === item.key) {
              const latLng = {
                latitude: children.val().latitude,
                longitude: children.val().longitude,
                token: item.token,
              };
              listLatLng.push(latLng);
            }
          });
        });
        return listLatLng;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const updatePublishmentQuote = async (operation) => {
    setIsLoading(true);
    try {
      const value = { publishment: operation };
      const dbRef = ref(db, `BookingBusiness/${UID}`);
      const snapshot = await get(dbRef);

      if (snapshot.exists()) {
        await update(dbRef, value);
        if (operation) {
          snackBarDialog("RESERVA PUBLICADA");
          const title = "NUEVA RESERVA PUBLICADA EMPRESA";
          const body = "Ingrese a Reservas para aceptar";
          // const listLatLng = await checkDriversLatLng();
          // let listToken = [];
          // const promises = listLatLng.map((latLng) => {
          //   const distance = haversineDistance(
          //     clientOrigin.latitude,
          //     clientOrigin.longitude,
          //     latLng.latitude,
          //     latLng.longitude
          //   );

          //   if (distance < 9) {
          //     listToken.push(latLng.token);
          //   }
          //   return listToken;
          // });
          const tokens = driverToken.map((item) => item.token);
          await sendNotificationTokens(tokens, title, body);
        } else {
          snackBarDialog("RESERVA ANULADA");
        }
      } else {
        snackBarDialog("ESTE PEDIDO YA NO EXISTE", "error");
      }

      setIsLoading(false);
      handleClosePublishmentModal();
    } catch (error) {
      setIsLoading(false);
      snackBarDialog("ERROR: NO SE PUEDE PUBLICAR ESTE PEDIDO", "error");
      handleClosePublishmentModal();
    }
  };

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.asin(Math.sqrt(a));

    // Radio de la Tierra en kilómetros (puedes ajustarlo según tus necesidades)
    const radiusOfEarth = 6371.0;

    return c * radiusOfEarth;
  };

  const toRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  const updateActiveBtnQuote = async (currency) => {
    try {
      const openpayData = {
        openpay: {
          status: "pending",
          btnPay: true,
          currency,
          transaction_id: "",
          order_id: "",
          url: "",
        },
      };
      await update(ref(db, `BookingBusiness/${UID}`), openpayData);
      handleCloseActiveBtnModal();
      snackBarDialog("BOTON ACTIVADO PARA PAGOS");
    } catch (error) {
      handleCloseActiveBtnModal();
      console.log(error);
      snackBarDialog("ERROR DE ACTIVACION DE BOTON DE PAGO ", "error");
    }
  };

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 35,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });
  const [dataSelected, setDataSelected] = useState([]);
  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    if (dataSelected.length > 0) {
      getEnterpriseData(dataSelected[0].enterpriseId);
    }
  }, [dataSelected]);

  const getEnterpriseData = async (enterpriseId) => {
    try {
      const response = await getEnterprise("token", enterpriseId);

      if (response.data.length > 0) {
        const enterpriseData = response.data[0];
        let total = 0;

        Object.values(dataSelected).map((book) => {
          total += Number(book.total);
        });

        let imp = 0;
        let totalPay = 0;
        let subTotal = 0;

        if (enterpriseData.igv === "MAS IGV") {
          imp = Math.round(Number(total) * 0.18 * 100) / 100;
          subTotal = Number(total);
          totalPay = Math.round((Number(total) + Number(imp)) * 100) / 100;
        } else if (enterpriseData.igv === "INAFECTO") {
          totalPay = Number(total);
        } else if (enterpriseData.igv === "AFECTO") {
          subTotal = Math.round((total / 1.18) * 100) / 100;
          imp = Math.round((total - subTotal) * 100) / 100;
          totalPay = Math.round(Number(total) * 100) / 100;
        }

        setTableData({});
        setTableData({
          id: "5df3180a09ea16dc4b95f910",
          invoice_no: "201906-28",
          company: "TAXIMUNDO",
          email: "info@taximundo.com",
          phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
          address: "Servicios Generales Trans Mundo E.I.R.L.",
          items: dataSelected,
          igv: imp,
          total: totalPay,
          peaje: 0,
          subTotal: subTotal,
          from: 0,
          to: 0,
        });
      } else {
        alert("ERROR AL BUSCAR DATOS DE LA EMPRESA");
      }
      // if(response)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="RESERVAS EMPRESAS" subtitle="" />
        <Box width="200px">
          {dataSelected.length > 0 && tableData ? (
            <PDFDownloadLink
              document={
                <Document>
                  <Page size="A4" style={styles.page}>
                    <HeaderM />
                    <Contact invoice={tableData} />
                    <DocumentName
                      title={"RESERVAS"}
                      from={"16/11/2023"}
                      to={"16/11/2023"}
                    />
                    <TableBooking invoice={tableData} />
                  </Page>
                </Document>
              }
              fileName={"detalles-reserva-taximundo.pdf"}
            >
              <Box width={"100%"}>
                <Button fullWidth variant="contained" color="success">
                  <PictureAsPdfIcon /> DESCARGAR
                </Button>
              </Box>
            </PDFDownloadLink>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box display="grid" gap="10px">
        <Box gridColumn="span 12" gridRow="span 2">
          <Box>
            <Box
              m="10px 0 0 0"
              height="100vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid
                checkboxSelection
                getRowId={(row) => row.uuid}
                rows={booking}
                columns={columnsClient}
                onSelectionModelChange={(ids) => {
                  setDataSelected([]);
                  ids.map((id) => {
                    const select = booking.find((row) => row.uuid === id);
                    setDataSelected((oldData) => [...oldData, select]);
                  });
                }}
              />
            </Box>

            {/* PUBLISHMENT BOOKING */}
            {/* MODAL PUBLISHMENT */}
            <Modal
              open={openPublishmentModal}
              onClose={handleClosePublishmentModal}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box
                sx={{
                  ...ModalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-title">PUBLICAR RESERVA</h2>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        updatePublishmentQuote(true);
                        //ENVIAR NOTIFICACION
                      }}
                      disabled={isLoading}
                    >
                      PUBLICAR
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        updatePublishmentQuote(false);
                      }}
                      disabled={isLoading}
                    >
                      ANULAR
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      onClick={handleClosePublishmentModal}
                      disabled={isLoading}
                    >
                      CERRAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>

            {/* ACTIVAR BTN DE PAGO CLIENTE */}
            <Modal
              open={openActiveBtnModal}
              onClose={handleCloseActiveBtnModal}
              aria-labelledby="modal-title"
            >
              <Box
                sx={{
                  ...ModalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-title">ACTIVAR BOTON PAGO</h2>
                    <h3 id="sub-title" style={{ margin: 0 }}>
                      {currency[currencyType]} {currencyPrice}
                    </h3>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={(e) =>
                        updateActiveBtnQuote(currency[currencyType])
                      }
                    >
                      ACTIVAR PAGO
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      onClick={handleCloseActiveBtnModal}
                    >
                      CERRAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>

            {/* DELETE BOOKING */}
            <Modal
              open={openDeleteModal}
              onClose={handleCloseDeleteModal}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box
                sx={{
                  ...ModalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-title">ELIMINAR RESERVA</h2>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={deleteQuote}
                    >
                      ACEPTAR
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      onClick={handleCloseDeleteModal}
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>

            {/* FINISH BOOKINGS */}

            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleCloseSnk}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TableBookBusinessLater;
