import { URL_VEHICLE } from "../../../helpers/endpoint";
import { baseBackend } from "../../base/baseUrl";

export const getVehicle = async (token, id) => {
  return await baseBackend.get(`${URL_VEHICLE}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getVehicles = async (token) => {
  return await baseBackend.get(URL_VEHICLE, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteVehicleApi = async (vehicle_id, status) => {
  return await baseBackend.delete(`${URL_VEHICLE}/delete/${vehicle_id}`, {
    data: { status },
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// axios.delete(url, { data: { hello: "world" }, headers: { "Authorization": "Bearer_token_here" } });

export const createVehicle = async (
  token,
  vehicle,
  technical_review,
  soat,
  setare
) => {
  return await baseBackend.post(
    URL_VEHICLE,
    JSON.stringify({
      vehicle,
      technical_review,
      soat,
      setare,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateVehicle = async (
  token,
  vehicle,
  technical_review,
  soat,
  setare
) => {
  return await baseBackend.put(
    URL_VEHICLE,
    JSON.stringify({
      vehicle,
      technical_review,
      soat,
      setare,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
