import { isExpired, decodeToken } from "react-jwt";

export const setMenuToken = (data) => {
  window.localStorage.setItem("menutkn", JSON.stringify(data));
};

export const getMenuToken = () => {
  const tknJson = window.localStorage.getItem("menutkn");
  const token = JSON.parse(tknJson);
  return token;
};
