import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../../theme";
import { GetListTourUseCase } from "../../../domain/usecases/question/GetListTourUseCase";
import { CreateBindQuestionUseCase } from "../../../domain/usecases/question/CreateBindQuestionUseCase";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { TableStyle } from "./styles/TableStyle";

const QuestionTable = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openBind, setOpenBind] = useState(false);
  const handleCloseBind = () => setOpenBind(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [itemSelect, setItemSelect] = useState(null);
  const openMenu = Boolean(anchorEl);
  const closeMenu = () => setAnchorEl(null);

  const [services, setServices] = useState([]);
  const [listService, setListService] = useState([
    { id: 1, name: "TOUR" },
    { id: 2, name: "TRASLADO" },
    { id: 3, name: "PAQUETE" },
  ]);

  const [dataInf, setDataInf] = useState([]);

  const dataTransform = (info) => {
    info.map((item) => {
      let typeStr = "";
      let tourStr = "";
      item.bindType.map((type) => {
        if (type.typeId != null) typeStr = typeStr + type.typeName + " ,";
      });
      item.bindTour.map((tour) => {
        if (tour.tourId != null) tourStr = tourStr + tour.tourCode + " ,";
      });
      item.typeStr = typeStr;
      item.tourStr = tourStr;
    });
    setDataInf(data);
    return data;
  };

  useEffect(() => {
    if (data.length > 0) dataTransform(data);
    // console.log(dataTransform());
  }, [data]);

  const [tours, setTours] = useState([]);
  const [listTours, setListTours] = useState([]);

  const handleOptionClick = (event, props) => {
    setItemSelect(props);
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    readListOfTour();
  }, []);

  const readListOfTour = async () => {
    const listTour = await GetListTourUseCase();
    if (listTour.message === "success") {
      setListTours(listTour.data);
    } else {
      setListTours([]);
    }
  };

  const columns = [
    {
      headerName: "TITULO",
      field: "title",
      width: 250,
    },
    {
      headerName: "CONTENIDO",
      field: "content",
      width: 400,
      renderCell: ({ row: { content } }) => (
        <div id="content-input" dangerouslySetInnerHTML={{ __html: content }} />
      ),
    },
    {
      headerName: "TIPO",
      field: "typeStr",
      width: 100,
      // renderCell: ({ row: { bindType } }) =>
      //   bindType.map((bind, idx) => <div key={idx}>{bind.typeName},</div>),
    },
    {
      headerName: "TOUR",
      field: "tourStr",
      width: 200,
      // renderCell: ({ row: { bindTour } }) =>
      //   bindTour.map((bind, idx) => <div key={idx}>{bind.tourCode},</div>),
    },
    {
      headerName: "OPCIONES",
      field: "actions",
      headerAlign: "center",
      width: 150,
      align: "center",
      renderCell: ({ row: { ...props } }) => {
        return (
          <>
            <Button
              id="btn-menu"
              variant="contained"
              color="success"
              onClick={(e) => handleOptionClick(e, props)}
            >
              Opciones
            </Button>

            <Menu
              id="shopping-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={closeMenu}
              MenuListProps={{ "aria-labelledby": "basic-button" }}
            >
              <MenuItem onClick={closeMenu}>
                <Button
                  // startIcon={<SaveIcon sx={{ color: "green" }} />}
                  sx={{ color: "white" }}
                  onClick={() => setOpenBind(true)}
                >
                  ASIGNAR
                </Button>
              </MenuItem>
              <MenuItem onClick={closeMenu}>
                <Button
                  // startIcon={<SaveIcon sx={{ color: "green" }} />}
                  sx={{ color: "white" }}
                >
                  ELIMINAR
                </Button>
              </MenuItem>
              <MenuItem onClick={closeMenu}>
                <Button
                  // startIcon={<SaveIcon sx={{ color: "green" }} />}
                  sx={{ color: "white" }}
                >
                  EDITAR
                </Button>
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: 7,
    boxShadow: 24,
    p: 2,
  };

  const createBindQuestion = async () => {
    if (services.length > 0 || tours.length > 0) {
      const resp = await CreateBindQuestionUseCase(
        itemSelect.id,
        services,
        tours
      );
      if (resp.message === "success") {
        snackBarDialog("CREADO CORRECTAMENTE");
        handleCloseBind();
      } else {
        snackBarDialog("ERROR: No se logro asignar", "error");
        handleCloseBind();
      }
    } else {
      snackBarDialog("SELECCIONE MINIMO 1", "error");
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);
  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
    setIsLoading(false);
  };

  return (
    <Box>
      <Box m="10px 0 0 0" height="100vh" sx={TableStyle(colors)}>
        <DataGrid
          getRowId={(row) => row.id}
          rows={dataInf.length > 0 ? dataInf : []}
          columns={columns}
          // localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          checkboxSelection={false}
        />
      </Box>

      {/* ASIGNAR LA RESERVA */}
      <Modal open={openBind} onClose={handleCloseBind}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
            fontWeight={"bold"}
          >
            VINCULAR PREGUNTAS FRECUENTES
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                id="autocomplete-services"
                value={services}
                onChange={(e, newValue) => setServices([...newValue])}
                options={listService}
                getOptionLabel={(option) => `${option.id}: ${option.name}`}
                renderInput={(params) => (
                  <TextField {...params} label="SERVICIOS" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                id="autocomplete-tous"
                value={tours}
                onChange={(e, newValue) => setTours([...newValue])}
                options={listTours}
                getOptionLabel={(option) => `${option.id}: ${option.title}`}
                renderInput={(params) => (
                  <TextField {...params} label="TOURS" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={handleCloseBind}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={createBindQuestion}
                disabled={isLoading}
              >
                guardar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* message */}
      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={4000}
        handleClose={handleSnkClose}
        severity={severity}
        messageSnk={messageSnk}
      />
    </Box>
  );
};

export default QuestionTable;
