import { URL_DRIVER } from "../../../helpers/endpoint";
import { baseBackend } from "../../base/baseUrl";

export const getDrivers = async (token) => {
  return await baseBackend.get(URL_DRIVER, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBirthdayDrivers = async (token) => {
  return await baseBackend.get(`${URL_DRIVER}-birthday`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDriverById = async (token, id) => {
  return await baseBackend.get(`${URL_DRIVER}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createdDriver = async (
  token,
  contract,
  license,
  driver,
  commissions,
  vehicles
) => {
  return await baseBackend.post(
    URL_DRIVER,
    JSON.stringify({
      contract,
      license,
      driver,
      commissions,
      vehicles,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateDriver = async (
  token,
  contract,
  license,
  commission,
  driver
) => {
  return await baseBackend.put(
    URL_DRIVER,
    JSON.stringify({
      contract,
      license,
      commission,
      driver,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateApproved = async (token, driver) => {
  return await baseBackend.put(
    `${URL_DRIVER}/approved`,
    JSON.stringify({ driver }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteDriverApi = async (driver_id, status) => {
  return await baseBackend.delete(`${URL_DRIVER}/${driver_id}`, {
    data: { status },
    headers: { "Content-Type": "application/json" },
  });
};

export const createDriverPayment = async (token, driver_id, payment, notes) => {
  return await baseBackend.post(
    `${URL_DRIVER}-payment`,
    JSON.stringify({ driver_id, payment, notes }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getDriverAndVehicle = async (token) => {
  return await baseBackend.get(`${URL_DRIVER}-vehicle`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createDriverAndVehicle = async (token, vehicles, driver_id) => {
  return await baseBackend.post(
    `${URL_DRIVER}-vehicle`,
    JSON.stringify({ vehicles, driver_id }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteDriverAndVehicle = async (token, vehicles, driver_id) => {
  return await baseBackend.delete(`${URL_DRIVER}-vehicle`, {
    data: { vehicles, driver_id },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const readDriverList = async () => {
  return await baseBackend.get("/api/v1/driver-list", {
    headers: { "Content-Type": "application/json" },
  });
};
