import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import SnackBar from "../../../../../components/snackbar/SnackBar";
import { tokens } from "../../../../../theme";
import { updateRouteAddress } from "../../../../../api/users/enterprise/enterpriseApi";

const UpdateModal = ({ idRoute, address, cities, routes }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [route, setRoute] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [addressOrigin, setAddressOrigin] = useState(null);
  const [addressDestination, setAddressDestination] = useState(null);
  const [city, setCity] = useState(null);

  const [driver, setDriver] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [suvDriver, setSuvDriver] = useState(0);
  const [suvTax, setSuvTax] = useState(0);
  const [suvTotal, setSuvTotal] = useState(0);
  const [miniDriver, setMiniDriver] = useState(0);
  const [miniTax, setMiniTax] = useState(0);
  const [miniTotal, setMiniTotal] = useState(0);
  const [mini15Driver, setMini15Driver] = useState(0);
  const [mini15Tax, setMini15Tax] = useState(0);
  const [mini15Total, setMini15Total] = useState(0);

  const routeSelect = () => {
    routes.map((el) => {
      if (el.id === idRoute) {
        setAddressOrigin({
          id: el.originId,
          name: el.originName,
        });
        setAddressDestination({
          id: el.destinationId,
          name: el.destinationName,
        });
        setCity({
          id: el.cityId,
          name: el.cityName,
        });
        setDriver(el.driver);
        setTax(el.tax);
        setTotal(el.total);
        setSuvDriver(el.suv_driver);
        setSuvTax(el.suv_tax);
        setSuvTotal(el.suv_total);
        setMiniDriver(el.mini_driver);
        setMiniTax(el.mini_driver);
        setMiniTotal(el.mini_total);
        setMini15Driver(el.mini15_driver);
        setMini15Tax(el.mini15_tax);
        setMini15Total(el.mini15_total);
        setRoute(el);
      }
    });
    handleOpen();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: colors.primary[400],
    border: "1px solid #000",
    borderRadius: 3,
    height: "100vh",
    p: 2,
    overflowY: "auto",
  };

  const handleUpdateAddress = async () => {
    try {
      setIsLoading(true);
      const update = {
        id: route.id,
        originId: addressOrigin.id,
        destinationId: addressDestination.id,
        cityId: city.id,
        driver: driver,
        tax: tax,
        total: total,
        suvDriver,
        suvTax,
        suvTotal,
        miniDriver,
        miniTax,
        miniTotal,
        mini15Driver,
        mini15Tax,
        mini15Total,
      };
      await updateRouteAddress(update);

      snackBarDialog("ACTUALIZACION EXITOSA");
      setIsLoading(false);
      handleClose();
    } catch (error) {
      snackBarDialog("ERROR: NO SE LOGRO ACTUALIZAR LA RUTA", "error");
      setIsLoading(false);
    }
  };

  return (
    <>
      <IconButton
        color="success"
        aria-label="add"
        component="label"
        sx={{ background: "#ffff" }}
        onClick={routeSelect}
      >
        <EditIcon />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography
            textAlign={"center"}
            sx={{ fontSize: 16, fontWeight: "bold" }}
          >
            ACTUALIZAR ESTA RUTA?
          </Typography>

          <Box>
            {route != null && (
              <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
                <Grid item xs={12} sm={12} md={12}>
                  {address && (
                    <Autocomplete
                      disablePortal
                      value={addressOrigin}
                      id="select-origin"
                      onChange={(e, newValue) => setAddressOrigin(newValue)}
                      options={address}
                      getOptionLabel={(option) => `${option.id} ${option.name}`}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField {...params} label={"Direccion A"} />
                      )}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  {address && (
                    <Autocomplete
                      disablePortal
                      value={addressDestination}
                      id="select-destination"
                      onChange={(e, newValue) => {
                        setAddressDestination(newValue);
                      }}
                      options={address}
                      getOptionLabel={(option) => `${option.id} ${option.name}`}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField {...params} label={"Direccion B"} />
                      )}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  {cities && (
                    <Autocomplete
                      value={city}
                      disablePortal
                      id="select-origin"
                      onChange={(e, newValue) => {
                        setCity(newValue);
                      }}
                      options={cities}
                      getOptionLabel={(option) => `${option.name}`}
                      renderInput={(params) => (
                        <TextField {...params} label={"CIUDAD"} />
                      )}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Typography textAlign={"center"}>SEDAN</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        value={driver}
                        name="driver"
                        fullWidth
                        type="number"
                        label="Conductor"
                        onChange={(e) => {
                          const value = e.target.value;
                          setTax((total || 0) - (value || 0));
                          setDriver(value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        value={tax}
                        name="tax"
                        fullWidth
                        type="number"
                        label="Impuesto"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        value={total}
                        name="total"
                        fullWidth
                        type="number"
                        label="Total"
                        onChange={(e) => {
                          const value = e.target.value;
                          setTax((value || 0) - (driver || 0));
                          setTotal(value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Typography textAlign={"center"}>SUV</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        value={suvDriver}
                        name="suvDriver"
                        fullWidth
                        type="number"
                        label="Conductor"
                        onChange={(e) => {
                          const value = e.target.value;
                          setSuvTax((suvTotal || 0) - (value || 0));
                          setSuvDriver(value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        value={suvTax}
                        name="suvTax"
                        fullWidth
                        type="number"
                        label="Impuesto"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        value={suvTotal}
                        name="suvTotal"
                        fullWidth
                        type="number"
                        label="Total"
                        onChange={(e) => {
                          const value = e.target.value;
                          setSuvTax((value || 0) - (suvDriver || 0));
                          setSuvTotal(value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Typography textAlign={"center"}>MINI</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        value={miniDriver}
                        name="miniDriver"
                        fullWidth
                        type="number"
                        label="Conductor"
                        onChange={(e) => {
                          const value = e.target.value;
                          setMiniTax((miniTotal || 0) - (value || 0));
                          setMiniDriver(value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        value={miniTax}
                        name="miniTax"
                        fullWidth
                        type="number"
                        label="Impuesto"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        value={miniTotal}
                        name="miniTotal"
                        fullWidth
                        type="number"
                        label="Total"
                        onChange={(e) => {
                          const value = e.target.value;
                          setMiniTax((value || 0) - (miniDriver || 0));
                          setMiniTotal(value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Typography textAlign={"center"}>MINI_15</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        value={mini15Driver}
                        name="mini15driver"
                        fullWidth
                        type="number"
                        label="Conductor"
                        onChange={(e) => {
                          const value = e.target.value;
                          setMini15Tax((mini15Total || 0) - (value || 0));
                          setMini15Driver(value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        value={mini15Tax}
                        name="mini15Tax"
                        fullWidth
                        type="number"
                        label="Impuesto"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        value={mini15Total}
                        name="mini15Total"
                        fullWidth
                        type="number"
                        label="Total"
                        onChange={(e) => {
                          const value = e.target.value;
                          setMini15Tax((value || 0) - (mini15Driver || 0));
                          setMini15Total(value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    color="secondary"
                    variant="contained"
                    fullWidth
                    sx={{ height: 45 }}
                    disabled={isLoading}
                    onClick={handleUpdateAddress}
                  >
                    ACTUALIZAR
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Modal>
      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={5000}
        handleClose={handleSnkClose}
        severity={severity}
        messageSnk={messageSnk}
      />
    </>
  );
};

export default UpdateModal;
