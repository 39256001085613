import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import courgette from "../../../../font/CourgetteRegular.ttf";

Font.register({
  family: "Courgette",
  src: courgette,
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 20, // Ajusta según sea necesario
    left: 0,
    right: 0,
    flexDirection: "row",
    alignContent: "center",
    marginTop: 5,
  },

  divContainer: {
    width: "80%",
    alignContent: "center",
    margin: "auto",
    textAlign: "center",
  },

  text: {
    color: "#000000",
    fontSize: 10,
    fontFamily: "Courgette",
  },
});

const Footer = () => {
  const text1 = "Este documento no tiene valor tributario";
  const text2 =
    "Si desea boleta o factura puede solicitar por correo o WhatsApp";

  return (
    <View style={styles.container}>
      <View style={styles.divContainer}>
        <Text style={styles.text}>{text1}</Text>
        <Text style={styles.text}>{text2}</Text>
      </View>
    </View>
  );
};

export default Footer;
