import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { deleteRouteAddress } from "../../../../../api/users/enterprise/enterpriseApi";
import SnackBar from "../../../../../components/snackbar/SnackBar";

const DeleteModal = ({ idRoute }) => {
  const [uuid, setUid] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    color: "black",
    border: "1px solid #000",
    borderRadius: 3,
    p: 2,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleDeleteRoute = async () => {
    try {
      setIsLoading(true);
      const route = { id: idRoute };
      const resp = await deleteRouteAddress(route);
      console.log(resp);
      snackBarDialog("SE HA ELIMINADO LA RUTA");
      handleClose();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO SE LOGRO ELIMINAR LA RUTA", "error");
      handleClose();
      setIsLoading(false);
    }
  };

  return (
    <>
      <IconButton
        aria-label="delete"
        component="label"
        sx={{ background: "#AA151B" }}
        onClick={() => {
          setUid(idRoute);
          handleOpen();
        }}
      >
        <DeleteIcon />
      </IconButton>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography
            textAlign={"center"}
            sx={{ fontSize: 16, fontWeight: "bold" }}
          >
            ¿ELIMINAR ESTA RUTA?
          </Typography>

          <Box display={"flex"} gap={2}>
            <Button
              variant="contained"
              sx={{ backgroundColor: "green", color: "white" }}
              fullWidth
              onClick={handleDeleteRoute}
              disabled={isLoading}
            >
              ACEPTAR
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "red", color: "white" }}
              fullWidth
              onClick={handleClose}
              disable={isLoading}
            >
              CANCELAR
            </Button>
          </Box>
        </Box>
      </Modal>

      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={5000}
        handleClose={handleSnkClose}
        severity={severity}
        messageSnk={messageSnk}
      />
    </>
  );
};

export default DeleteModal;
