import { getCountryAndCity } from "../../../data/repository/country.repo";

export const GetCountryAndCityUseCase = async () => {
  try {
    const response = await getCountryAndCity();
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
