import { getCountryById } from "../../../data/repository/country.repo";

export const ReadCountryByIdUseCase = async (id) => {
  try {
    const resp = await getCountryById(id);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
