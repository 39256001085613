import React from "react";
import { Box, CssBaseline } from "@mui/material";

//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../../../../components/sidebar/Sidebar";
import Topbar from "../../../../../../../../components/topbar/TopBar";
import Header from "../../../../../../../../components/header/Header";
import QuoteTourTable from "./QuoteTourTable";

const QuoteTour = () => {
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  return (
    <div>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="Cotizacion Tour Clientes" subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <QuoteTourTable />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};

export default QuoteTour;
