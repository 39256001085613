import { readEnterpriseCostCenter } from "../respository/costcenter.respository";

export const ReadCostCenter = async (enterpriseId) => {
  try {
    const response = await readEnterpriseCostCenter(enterpriseId);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
