import { createCountry } from "../../../data/repository/country.repo";

export const CreateCountryUseCase = async (country) => {
  try {
    const response = await createCountry(country);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
