import React from "react";
import { Box, CssBaseline } from "@mui/material";
import Topbar from "../../../../components/topbar/TopBar";
import TableBookNow from "../../../../components/datatable/TableBookNow";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

const ListBookNow = () => {
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  return (
    <div>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <TableBookNow type_booking={1} />
          </Box>
        </main>
      </div>
    </div>
  );
};

export default ListBookNow;
