import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#424949";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    height: 14,
    fontStyle: "bold",
  },
  index: {
    width: "7%",
    height: "100%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: 7,
  },
  plate: {
    width: "7%",
    height: "100%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  fullname: {
    width: "46%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  year: {
    width: "6%",
    height: "100%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  setare: {
    width: "12%",
    height: "100%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  weight: {
    width: "11%",
    height: "100%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 4,
    fontSize: 8,
  },
  cylinder: {
    width: "11%",
    height: "100%",
    textAlign: "center",
    paddingRight: 6,
    fontSize: 8,
  },
});

const TableRow = ({ items }) => {
  const rows = items.map((item, index) => (
    <View style={styles.row} key={item.id}>
      <Text style={styles.index}>{index + 1}</Text>
      <Text style={styles.plate}>{item.plate}</Text>
      <Text style={styles.fullname}>{item.owner}</Text>
      <Text style={styles.year}>{item.year}</Text>
      <Text style={styles.setare}>
        {item.setare_permission ? item.setare_end : ""}
      </Text>
      <Text style={styles.weight}>{item.weight}</Text>
      <Text style={styles.cylinder}>{item.cylinder}</Text>
      {/* <Text style={styles.rate}>{item.rate}</Text>
      <Text style={styles.amount}>{(item.qty * item.rate).toFixed(2)}</Text> */}
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default TableRow;
