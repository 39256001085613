import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Button, CssBaseline, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { ReadCountryListUseCase } from "../../../domain/usecases/country/ReadCountryListUseCase";

// const COUNTRY_PATH = "https://backend.taximundo.com/country"
const COUNTRY_PATH = "http://localhost:4000/country/";

const CountryListScreen = () => {
  const [countryList, setCountryList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const readCountry = async () => {
      try {
        const response = await ReadCountryListUseCase();
        console.log(response);
        setCountryList(response.countryList);
      } catch (error) {
        console.log(error);
      }
    };
    readCountry();
  }, []);

  const redirectTo = (id) => (event) => {
    navigate(`/admin/pais/actualizar/${id}`);
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={"LISTA DE PAISES"} subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <Grid container spacing={1}>
                  {countryList && countryList.length > 0 ? (
                    countryList.map((tour, idx) => (
                      <Grid key={idx} item xs={12} sm={4} md={3}>
                        <Card sx={{ width: "100%" }}>
                          <CardMedia
                            sx={{ height: 140 }}
                            image={`${COUNTRY_PATH}/${tour.image}`}
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h4"
                              component="div"
                            >
                              {tour.name}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              size="small"
                              variant="contained"
                              sx={{ backgroundColor: "green", color: "white" }}
                              onClick={redirectTo(tour.id)}
                            >
                              ACTUALIZAR
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <div>
                      <h2>LISTA DE TOURS VACIO</h2>
                    </div>
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default CountryListScreen;
