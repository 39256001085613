import { useEffect, useState } from "react";
import Header from "../../../../components/header/Header";
import Topbar from "../../../../components/topbar/TopBar";
import {
  Box,
  Button,
  CssBaseline,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  useTheme,
} from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockResetIcon from "@mui/icons-material/LockReset";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../../../theme";
import { getAdmin, updatePassword } from "../../../../api/users/admin/admin";
import { useNavigate } from "react-router-dom";
import SnackBar from "../../../../components/snackbar/SnackBar";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

export const ListAdmin = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    const getListAdmin = async () => {
      try {
        const response = await getAdmin("token");
        console.log(response.data);
        setData([]);
        response.data.map((admin, index) => {
          admin.index = index + 1;
          setData((oldaData) => [...oldaData, admin]);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getListAdmin();
  }, []);

  const columns = [
    { field: "index", headerName: "ID", width: 50 },
    {
      headerName: "EMPRESA",
      field: "company_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 130,
    },
    {
      headerName: "NOMBRE",
      field: "name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 130,
    },
    {
      headerName: "APELLIDOS",
      field: "lastname",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "EMAIL",
      field: "email",
      width: 200,
    },
    {
      headerName: "ESTADO",
      field: "status",
      width: 90,
      align: "center",
    },
    {
      headerName: "PERMISOS",
      headerAlign: "center",
      field: "actions",
      width: 100,
      align: "center",
      renderCell: ({ row: { id } }) => {
        return (
          <IconButton
            onClick={() => {
              navigate(`/admin/permissions/${id}`);
            }}
          >
            <AdminPanelSettingsIcon />
          </IconButton>
        );
      },
    },
    {
      headerName: "OPCIONES",
      headerAlign: "center",
      field: "options",
      width: 120,
      align: "center",
      renderCell: ({ row: { id } }) => {
        return (
          <>
            <IconButton
              onClick={() => {
                //editar
                navigate(`/admin/permissions/${id}`);
              }}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                handleModalRePassOpen(id);
              }}
            >
              <LockResetIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowRePassword = () => setShowRePassword((show) => !show);
  const handleMouseDownPassword = (e) => e.preventDefault();

  const [openModalRePass, setOpenModalRePass] = useState(false);
  const [password, setPassword] = useState("");
  const [UID, setUID] = useState("");
  const [rePassword, setRePassword] = useState("");
  const handleModalRePassOpen = (uid) => {
    setUID(uid);
    setOpenModalRePass(true);
  };
  const handleModalRePassClose = () => setOpenModalRePass(false);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    p: 1,
  };

  const updatePasswordAdmin = async () => {
    if (password === rePassword) {
      try {
        if (password.length >= 6) {
          setIsLoading(true);
          const response = await updatePassword("token", UID, password);
          snackBarDialog("CONTRASEÑA ACTUALIZADA");
          setIsLoading(false);
          handleModalRePassClose();
        } else {
          setIsLoading(false);
          snackBarDialog("CONTRASEÑAS MINIMO DE 6 CARACTERES", "error");
        }
      } catch (error) {
        snackBarDialog("ERROR, NO SE LOGRO CAMBIAR LA CONTRASEÑA", "error");
        setIsLoading(false);
        handleModalRePassClose();
        console.log(error);
      }
    } else {
      snackBarDialog("LAS CONTRASEÑAS DEBEN SER IGUALES", "error");
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box>
            <Box marginLeft={1} marginRight={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={8} md={8}>
                  <Header title="ADMINISTRADORES" subtitle="" />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={(e) => {
                      navigate("/admin/list-company");
                    }}
                  >
                    AGREGAR EMPRESA
                  </Button>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={(e) => {
                      navigate("/admin/add-admin");
                    }}
                  >
                    AGREGAR ADMIN
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <Box
              m="10px 0 0 0"
              height="100vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid
                getRowId={(row) => row.id}
                rows={data}
                columns={columns}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
            <Modal
              open={openModalRePass}
              onClose={handleModalRePassClose}
              aria-labelledby="modal-debt-title"
            >
              <Box
                sx={{
                  ...modalStyle,
                  minWidth: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-debt-title">CAMBIAR CONTRASEÑA</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="password">
                        NUEVA CONTRASEÑA
                      </InputLabel>
                      <OutlinedInput
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="NUEVA CONTRASEÑA"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="re-password">
                        CONFIRMAR CONTRASEÑA
                      </InputLabel>
                      <OutlinedInput
                        id="re-password"
                        value={rePassword}
                        onChange={(e) => setRePassword(e.target.value)}
                        type={showRePassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowRePassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showRePassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="CONFIRMAR CONTRASEÑA"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        updatePasswordAdmin();
                      }}
                      disabled={isLoading}
                    >
                      CAMBIAR CONTRASEÑA
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleSnkClose}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </main>
      </div>
    </>
  );
};
