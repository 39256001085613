import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CssBaseline,
  Grid,
} from "@mui/material";

import { tokens } from "../../../../theme";
import {
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../../helpers/token/refreshToken";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { getCompanies } from "../../../../api/users/admin/company";
import { createAdmin } from "../../../../api/users/admin/admin";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

const AddAdmin = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const { id } = useParams();
  const { t } = useTranslation();

  const [client, setClient] = useState("");
  const [isUpdate, setIsUpdate] = useState(true);
  const [company, setCompany] = useState([]);

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    lastname: yup.string().required("required"),
    email: yup.string().email("Emaiil no valido").required("required"),
    password: yup.string().required("required"),
    company_id: yup.number().required("required"),
  });

  useEffect(() => {
    const getListCompanies = async () => {
      try {
        const response = await getCompanies("token");
        console.log(response.data);
        setCompany(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getListCompanies();
  }, []);

  const handleFormSubmit = async (values) => {
    const expireToken = verifyExpireTokenRefresh();
    if (expireToken) navigate("/");
    try {
      setIsLoading(true);
      const token = getTokenRefresh();
      const admin = {
        name: values.name.toUpperCase(),
        lastname: values.lastname.toUpperCase(),
        email: values.email,
        password: values.password,
        company_id: values.company_id,
      };
      await createAdmin(token, admin);
      snackBarDialog("ADMIN CREADO");
      setIsLoading(false);
    } catch (error) {
      snackBarDialog("ERROR NO SE CREO EL ADMIN", "error");
      setIsLoading(false);
      console.log(error);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const dataAdmin = {
    name: "",
    lastname: "",
    email: "",
    password: "",
    company_id: "",
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="Agregar Admin" subtitle="" />
              <Grid></Grid>
            </Box>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={dataAdmin}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    backgroundColor={colors.primary[400]}
                    borderRadius={3}
                    p={2}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.name}
                          fullWidth
                          variant="outlined"
                          type="text"
                          label={t("name")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="name"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={!!touched.name && !!errors.name}
                          helperText={touched.name && errors.name}
                          color="success"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.lastname}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="text"
                          label={t("lastname")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="lastname"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={!!touched.lastname && !!errors.lastname}
                          helperText={touched.lastname && errors.lastname}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.email}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="email"
                          label={t("email")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="email"
                          error={!!touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.password}
                          name="password"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          color="success"
                          type="password"
                          label={t("Password")}
                          error={!!touched.password && !!errors.password}
                          helperText={touched.password && errors.password}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel>{t("EMPRESA")}</InputLabel>
                          <Select
                            id="genre"
                            value={values.company_id}
                            name="company_id"
                            label={t("EMPRESA")}
                            onChange={handleChange}
                          >
                            {company.map((company, index) => (
                              <MenuItem key={index} value={company.id}>
                                {company.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} textAlign={"end"}>
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          disabled={isLoading}
                        >
                          CREAR CUENTA
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <SnackBar
                    stateSnk={stateSnk}
                    vertical={vertical}
                    horizontal={horizontal}
                    duration={4000}
                    handleClose={handleSnkClose}
                    severity={severity}
                    messageSnk={messageSnk}
                  />
                </form>
              )}
            </Formik>
          </Box>
        </main>
      </div>
    </>
  );
};

export default AddAdmin;
