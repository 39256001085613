import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ref, onValue, update, remove, get } from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import {
  Autocomplete,
  CssBaseline,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
  useTheme,
  Stack,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Formik } from "formik";
import * as yup from "yup";

import { db } from "../../../../../../firebase";
import Topbar from "../../../../../../components/topbar/TopBar";
import Header from "../../../../../../components/header/Header";
import { tokens } from "../../../../../../theme";
import SnackBar from "../../../../../../components/snackbar/SnackBar";
import { getDriverAndVehicle } from "../../../../../../api/users/driver/driver";
import {
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../../../../helpers/token/refreshToken";
import { getEnterpriseEmployeeAddress } from "../../../../../../api/enterprise/address";
import {
  createBookingBusiness,
  updateBookingBusiness,
} from "../../../../../../api/booking/bookingBusiness";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { dateFormat, timeFormat } from "../../../../../../helpers/date/date";
import { formatterTimePicker } from "../../../../../../helpers/date/formatterTimePicker";
import { sendNotificationToken } from "../../../../../../api/fcm/notification";
import { UpdateOrderBackup } from "../../../backup/usecases/UpdateOrderBackup";
import {
  createAdvanceCash,
  getAdvanceCashOrder,
} from "../../../../../../api/booking/advance";
import { Fragment } from "react";

const AddBookingBusinessLater = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [driver, setDriver] = useState(null);

  const [commissionOption, setCommissionOption] = useState(true);
  const [commissionValue, setCommissionValue] = useState(0);

  const [activePhone, setActivePhone] = useState(true);
  const handleActivePhone = (event) => setActivePhone(event.target.checked);

  // lista de direcciones
  const [addressApi, setAddressApi] = useState([]);
  const [addressOrigin, setAddressOrigin] = useState(null);
  const [addressDestination, setAddressDestination] = useState(null);

  const [listPayments, setListPayments] = useState([]);
  const [noteAdmin, setNoteAdmin] = useState("");

  useEffect(() => {
    const starCountRef = ref(db, `BookingBusiness/${uuid}`);
    onValue(starCountRef, (snapshot) => {
      let data = snapshot.val();
      if (data === null) return console.log("No Booking");
      const origin = {
        id: uuidv4(),
        name: data.origin.name,
        latitude: data.origin.lat,
        longitude: data.origin.lng,
      };
      const destination = {
        id: uuidv4(),
        name: data.destination.name,
        latitude: data.destination.lat,
        longitude: data.destination.lng,
      };
      setAddressOrigin(origin);
      setAddressDestination(destination);
      setBooking(data);
      setActivePhone(data.phoneActive !== undefined ? data.phoneActive : true);
      setNoteAdmin(data.noteAdmin ? data.noteAdmin : "");
      const getPaymentsMysql = async () => {
        try {
          const resp = await getPaymentsList(data.counterBooking);
          setListPayments(resp.data.resp);
        } catch (error) {
          console.log(error);
        }
      };
      getPaymentsMysql();
    });
  }, []);

  const getPaymentsList = async (orderNumber) => {
    const resp = await getAdvanceCashOrder(orderNumber);
    return resp;
  };

  const [dateformat, setDateFormat] = useState("");
  const [timeFormatter, setTimeFormat] = useState("");
  const [dateEndFormat, setDateEndFormat] = useState("");
  const [timeEndFormatter, setTimeEndFormat] = useState("");

  useEffect(() => {
    if (booking) {
      if (booking.driver) {
        const driver = {
          name: booking.driver.name,
          lastname: booking.driver.lastname,
          brand: booking.driver.brand,
          color: booking.driver.color,
          driver_id: booking.driver.driver_id,
          model: booking.driver.model,
          bodywork: booking.driver.bodywork,
          plate: booking.driver.plate,
          vehicle_id: booking.driver.vehicle_id,
          percentageValue: booking.driver.percentageValue,
        };
        setCommissionValue(driver.percentageValue);
        setDriver(driver);
      }
      setDateFormat(dayjs(booking.date, "DD/MM/YYYY").toDate());
      setTimeFormat(formatterTimePicker(booking.date, booking.time));
      if (booking.booking_type == 3) {
        setDateEndFormat(dayjs(booking.dateEnd, "DD/MM/YYYY").toDate());
        setTimeEndFormat(formatterTimePicker(booking.dateEnd, booking.timeEnd));
      }
      setAdvance(booking.advance ? booking.advance : 0);
    }
  }, [booking]);

  useEffect(() => {
    const driversData = async () => {
      const verify = verifyExpireTokenRefresh();
      if (verify) return console.log("no token");

      const token = getTokenRefresh();
      try {
        const response = await getDriverAndVehicle(token);
        setDrivers(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    driversData();
  }, []);

  useEffect(() => {
    if (booking) {
      const getAddressEnterprise = async () => {
        try {
          const response = await getEnterpriseEmployeeAddress(
            "",
            booking.client,
            booking.enterpriseID
          );
          setAddressApi([]);
          Object.values(response.data).map((address) => {
            const _address = {
              id: address.id,
              latitude: address.latitude,
              longitude: address.longitude,
              name: address.name,
              url: address.url,
            };
            setAddressApi((oldData) => [...oldData, _address]);
          });
        } catch (error) {
          console.log(error);
        }
      };
      getAddressEnterprise();
    }
  }, [booking]);

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleMouseDownPassword = (e) => e.preventDefault();

  const checkoutSchema = yup.object().shape({});

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    values.driver = driver;
    values.statusService = driver ? "ASIGNADO" : "PENDIENTE";
    values.statusPayment = values.total > 0 ? "ASIGNADO" : "PENDIENTE";
    values.phoneActive = activePhone;
    values.advance = advance;
    values.noteAdmin = noteAdmin;
    try {
      values.origin = {
        name: addressOrigin.name,
        lat: addressOrigin.latitude,
        lng: addressOrigin.longitude,
        url: addressOrigin.url ? addressOrigin.url : "",
      };
      values.destination = {
        name: addressDestination.name,
        lat: addressDestination.latitude,
        lng: addressDestination.longitude,
        url: addressDestination.url ? addressDestination.url : "",
      };

      values.date = values.date.$d ? dateFormat(values.date.$d) : values.date;
      values.time = values.time.$d ? timeFormat(values.time.$d) : values.time;
      if (values.booking_type == 3) {
        values.dateEnd = values.dateEnd.$d
          ? dateFormat(values.dateEnd.$d)
          : values.dateEnd;
        values.timeEnd = values.timeEnd.$d
          ? timeFormat(values.timeEnd.$d)
          : values.timeEnd;
      }

      const openpay = {
        createBy: "A",
        status: "pending",
        btnPay: true,
        currency: "",
        transaction_id: "",
        order_id: "",
        operation_date: "",
        url: "",
      };
      values.openpay = openpay;

      if (driver) {
        const token = "asdf";
        if (commissionOption) {
          values.commission = values.driver.percentageValue;
        } else {
          values.commission = commissionValue;
        }
        if (!values.isCreated) {
          values.isCreated = true;
          values.statusBooking = "PAGADO";
          values.state_payment = "PENDIENTE";
          values.assigned = true;

          try {
            await createBookingBusiness(token, values);
            const dbRef = ref(db, `BookingBusiness/${values.uuid}`);
            await remove(dbRef);
            const driverId = values.driver.driver_id;
            const dbRefToken = ref(db, `Token/Driver/${driverId}`);

            get(dbRefToken).then(async (snapshot) => {
              const data = snapshot.val();
              if (data != null) {
                if (data.isBooking && data.isPremium) {
                  const token = data.token;
                  const title = "TIENES RESERVA ASIGNADA EMPRESA";
                  const body = "Ingrese a Reservas para ver los detalles";
                  await sendNotificationToken(token, title, body);
                } else {
                  snackBarDialog("CONDUCTOR NO RECIBE NOTIFICACIONES", "error");
                }
              } else {
                snackBarDialog("CONDUCTOR NO RECIBE NOTIFICACIONES", "error");
              }
            });
            setTimeout(() => {
              navigate("/admin/list-book-business-later");
            }, 5000);
          } catch (error) {
            snackBarDialog("NO SE LOGRO ASIGNAR CONDUCTOR", "error");
          }
        } else {
          await updateBookingBusiness(token, values);
          setTimeout(() => {
            navigate("/admin/list-book-business-later");
          }, 5000);
        }
        setIsLoading(false);
      } else {
        const urlRef = `BookingBusiness/${values.uuid}`;
        await update(ref(db, urlRef), values);

        const backup = {
          id: values.counterBooking,
          originName: addressOrigin.name,
          originLat: addressOrigin.latitude,
          originLng: addressOrigin.longitude,
          destinationName: addressDestination.name,
          destinationLat: addressDestination.latitude,
          destinationLng: addressDestination.longitude,
          date: values.date.$d ? dateFormat(values.date.$d) : values.date,
          time: values.time.$d ? timeFormat(values.time.$d) : values.time,

          persons: values.persons,
          advance: advance,
          tax: values.tax,
          toll: values.toll,
          price: values.price,
          total: values.total,
          discount: 0,
          note: values.note,
          noteAdmin: values.noteAdmin,
          currency: values.currency,
          paymentType: values.payment_type,
          otherPay: false,
          operationDate: "",
        };
        console.log("backup", backup);

        if (otherPay) {
          const currencyList = {
            1: "PEN",
            2: "USD",
          };

          const currDate = new Date();
          const datePay = `${dateFormat(currDate)} ${timeFormat(currDate)}`;

          const advReg = {
            orderNumber: values.counterBooking,
            transactionId: `${values.counterBooking}-${Date.now()}`,
            currency: currencyList[values.currency],
            amount: advance,
            operationDate: datePay,
            status: "completed",
            createdBy: "ADMIN",
          };
          const resp = await createAdvanceCash(advReg);
        }
        const resp = await UpdateOrderBackup(backup);

        // console.log(resp);

        if (resp.message === "success") {
          snackBarDialog("DATOS ACTUALIZADOS");
          setTimeout(() => {
            navigate("/admin/list-book-business-later");
          }, 2000);
        } else {
          snackBarDialog("ERROR: No se logro actualizar el Backup", "error");
        }

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      snackBarDialog("Error: No Actualizado", "error");
    }
  };

  const [advance, setAdvance] = useState(0);
  const [otherPay, setOtherPay] = useState(false);

  const handleOtherPay = (e) => {
    const isChecked = e.target.checked;
    setOtherPay(isChecked);
  };

  return (
    <div>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header
                title={`RESERVA: ${booking ? booking.enterpriseName : null}`}
                subtitle=""
              />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 1">
                {booking && (
                  <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={booking}
                    validationSchema={checkoutSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Box backgroundColor={colors.primary[400]} p={1}>
                          <Grid container spacing={1} p={1}>
                            <Grid item xs={12}>
                              <TextField
                                value={values.counterBooking}
                                disabled
                                type="text"
                                label={"PEDIDO"}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {addressApi ? (
                                <Autocomplete
                                  value={addressOrigin}
                                  disablePortal
                                  name="client"
                                  id="combo-box-origin"
                                  onChange={(e, newValue) => {
                                    setAddressOrigin(newValue);
                                  }}
                                  options={addressApi}
                                  getOptionLabel={(option) => `${option.name}`}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Origen" />
                                  )}
                                />
                              ) : (
                                <TextField
                                  fullWidth
                                  disabled
                                  type="text"
                                  label={t("Lista de Direcciones")}
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {addressApi ? (
                                <Autocomplete
                                  value={addressDestination}
                                  disablePortal
                                  name="client"
                                  id="combo-box-origin"
                                  onChange={(e, newValue) => {
                                    setAddressDestination(newValue);
                                  }}
                                  options={addressApi}
                                  getOptionLabel={(option) => `${option.name}`}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Destino" />
                                  )}
                                />
                              ) : (
                                <TextField
                                  fullWidth
                                  disabled
                                  type="text"
                                  label={t("Lista de Direcciones")}
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                value={values.clientName}
                                fullWidth
                                variant="outlined"
                                color="success"
                                type="text"
                                label={t("client")}
                                InputProps={{
                                  endAdornment: (
                                    <Checkbox
                                      style={{ marginRight: -15 }}
                                      checked={activePhone}
                                      onChange={handleActivePhone}
                                      color="success"
                                    />
                                  ),
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Grid container spacing={1}>
                                <Grid item xs={6} sm={3} md={3}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Stack spacing={2}>
                                      <MobileDatePicker
                                        label={t("date")}
                                        inputFormat="DD/MM/YYYY"
                                        name="date"
                                        value={dateformat}
                                        onChange={(newValue) => {
                                          setDateFormat(newValue);
                                          values.date = newValue;
                                        }}
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                      />
                                    </Stack>
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Stack spacing={3}>
                                      <MobileTimePicker
                                        label={t("time")}
                                        value={timeFormatter}
                                        name="time"
                                        onChange={(newValue) => {
                                          setTimeFormat(newValue);
                                          values.time = newValue;
                                        }}
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                      />
                                    </Stack>
                                  </LocalizationProvider>
                                </Grid>

                                <Grid item xs={6} sm={3} md={3}>
                                  <TextField
                                    value={values.persons}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="text"
                                    label={t("persons")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="persons"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                  <FormControl fullWidth>
                                    <InputLabel>{t("Model")}</InputLabel>
                                    <Select
                                      value={values.vehicle}
                                      id="vehicle"
                                      name="vehicle"
                                      label={t("vehicle")}
                                      onChange={handleChange}
                                    >
                                      <MenuItem value={1}>
                                        {t("SEDAN")}
                                      </MenuItem>
                                      <MenuItem value={2}>{t("SUV")}</MenuItem>
                                      <MenuItem value={3}>
                                        {t("MINIVAN")}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <TextField
                                value={values.note}
                                fullWidth
                                variant="outlined"
                                color="success"
                                type="text"
                                label={t("notes")}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="note"
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              {drivers && (
                                <Autocomplete
                                  value={driver}
                                  disablePortal
                                  id="combo-box-driver"
                                  onChange={(e, newValue) => {
                                    setDriver(newValue);
                                  }}
                                  options={drivers}
                                  getOptionLabel={(option) =>
                                    `${option.name} ${option.lastname} ${option.brand} ${option.model} ${option.bodywork} ${option.plate} ${option.color}`
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t("driver")}
                                    />
                                  )}
                                />
                              )}
                            </Grid>

                            <Grid item xs={12} sm={3} md={3}>
                              <Grid container spacing={1}>
                                <Grid item xs={6} sm={12} md={12}>
                                  <FormControl fullWidth>
                                    <InputLabel>{t("Tipo Pago")}</InputLabel>
                                    <Select
                                      value={values.payment_type}
                                      id="payment_type"
                                      name="payment_type"
                                      label={t("payment")}
                                      onChange={handleChange}
                                    >
                                      <MenuItem value={1}>
                                        {t("EFECTIVO")}
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        {t("CREDITO")}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={12} md={12}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">
                                      COMISION
                                    </InputLabel>
                                    <OutlinedInput
                                      value={commissionValue}
                                      name="commissionValue"
                                      onChange={(e) => {
                                        setCommissionValue(e.target.value);
                                      }}
                                      disabled={commissionOption}
                                      id="control-dni"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                            onClick={(e) => {
                                              setCommissionOption(
                                                !commissionOption
                                              );
                                            }}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      label="COMISION"
                                      aria-describedby="outlined-helper-text"
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={3} md={3}>
                              <Grid container spacing={1}>
                                <Grid item xs={6} sm={12} md={12}>
                                  <FormControl fullWidth>
                                    <InputLabel>{t("currency")}</InputLabel>
                                    <Select
                                      value={values.currency}
                                      id="currency"
                                      name="currency"
                                      label={t("currency")}
                                      onChange={handleChange}
                                    >
                                      <MenuItem value={1}>{t("PEN")}</MenuItem>
                                      <MenuItem value={2}>{t("USD")}</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {values.booking_type == 3 && (
                                  <Grid item xs={6} sm={12}>
                                    <Grid container spacing={1}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <Stack spacing={2}>
                                            <MobileDatePicker
                                              label={t("dateEnd")}
                                              inputFormat="DD/MM/YYYY"
                                              name="dateEnd"
                                              value={dateEndFormat}
                                              onChange={(newValue) => {
                                                setDateEndFormat(newValue);
                                                values.dateEnd = newValue;
                                              }}
                                              renderInput={(params) => (
                                                <TextField {...params} />
                                              )}
                                            />
                                          </Stack>
                                        </LocalizationProvider>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <Stack spacing={3}>
                                            <MobileTimePicker
                                              label={t("timeEnd")}
                                              value={timeEndFormatter}
                                              name="timeEnd"
                                              onChange={(newValue) => {
                                                setTimeEndFormat(newValue);
                                                values.timeEnd = newValue;
                                              }}
                                              renderInput={(params) => (
                                                <TextField {...params} />
                                              )}
                                            />
                                          </Stack>
                                        </LocalizationProvider>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={3} md={3}>
                              <Grid container spacing={1}>
                                <Grid item xs={6} sm={12} md={12}>
                                  <TextField
                                    value={values.tax}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label={t("IMPUESTO")}
                                    onChange={handleChange}
                                    name="tax"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={12} md={12}>
                                  <TextField
                                    value={values.toll}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label={t("PEAJE")}
                                    onChange={handleChange}
                                    name="toll"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={3} md={3}>
                              <Grid container spacing={1}>
                                <Grid item xs={6} sm={12} md={12}>
                                  <TextField
                                    value={values.price}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label={t("P CONDUCTOR")}
                                    onChange={handleChange}
                                    name="price"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={12} md={12}>
                                  <TextField
                                    value={values.total}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label={t("TOTAL")}
                                    onChange={handleChange}
                                    name="total"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              <TextField
                                value={noteAdmin}
                                fullWidth
                                variant="outlined"
                                color="success"
                                type="text"
                                label="Notas del Admin"
                                onChange={(e) => setNoteAdmin(e.target.value)}
                                name="noteAdmin"
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              <div>
                                {listPayments.map((pay, idx) => {
                                  return (
                                    <Fragment key={idx}>
                                      <span style={{ fontWeight: "bold" }}>
                                        {pay.created_by}
                                      </span>
                                      {", "}
                                      <span style={{ fontWeight: "bold" }}>
                                        Pago:{" "}
                                      </span>
                                      {pay.amount} {pay.currency},{" "}
                                      <span style={{ fontWeight: "bold" }}>
                                        Fecha:{" "}
                                      </span>
                                      {pay.operation_date}
                                      {". "}
                                    </Fragment>
                                  );
                                })}
                              </div>
                            </Grid>

                            <Grid item xs={6} sm={3} md={3}>
                              <TextField
                                value={advance}
                                name="advance"
                                fullWidth
                                variant="outlined"
                                color="success"
                                type="number"
                                label="ADELANTO"
                                onChange={(e) => setAdvance(e.target.value)}
                                InputProps={{
                                  endAdornment: (
                                    <Checkbox
                                      style={{ marginRight: -15 }}
                                      checked={otherPay}
                                      onChange={handleOtherPay}
                                      color="success"
                                    />
                                  ),
                                }}
                              />
                            </Grid>

                            <Grid item xs={0} sm={6} md={6}></Grid>

                            <Grid item xs={12} sm={3} md={3}>
                              <Button
                                fullWidth
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{ height: 45 }}
                                disabled={isLoading}
                              >
                                {"GUARDAR"}
                              </Button>
                            </Grid>
                          </Grid>
                          <SnackBar
                            stateSnk={stateSnk}
                            vertical={vertical}
                            horizontal={horizontal}
                            duration={4000}
                            handleClose={handleSnkClose}
                            severity={severity}
                            messageSnk={messageSnk}
                          />
                        </Box>
                      </form>
                    )}
                  </Formik>
                )}
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};

export default AddBookingBusinessLater;
