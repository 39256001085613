import { createTourEmpty } from "../../../data/repository/tour.repo";

export const CreateTourEmptyUseCase = async () => {
  try {
    const response = await createTourEmpty();
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
