import React from "react";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
} from "@react-pdf/renderer";
import { Button } from "@mui/material";
import Header from "./elements/Header";
import Body from "./elements/Body";

const Habilitacion = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  return (
    <PDFDownloadLink
      document={
        <Document>
          <Page size="A4" style={styles.page}>
            <Header />
            <Body data={data} />
          </Page>
        </Document>
      }
      fileName={"formulario-inscripcion.pdf"}
    >
      <Button
        fullWidth
        color="secondary"
        variant="contained"
        sx={{ height: 45 }}
      >
        INSCRIPCION
      </Button>
    </PDFDownloadLink>
  );
};

export default Habilitacion;
