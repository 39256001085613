import React, { useEffect, useRef, useState } from "react";
import { Box, Button, CssBaseline, Grid, TextField } from "@mui/material";
import JoditEditor from "jodit-react";
import { validateQuestion } from "../../../domain/validation/question";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import SnackBar from "../../../../components/snackbar/SnackBar";
import QuestionTable from "../components/QuestionTable";
import { GetListQuestionUseCase } from "../../../domain/usecases/question/GetListQuestionUseCase";
import { CreateQuestionUseCase } from "../../../domain/usecases/question/CreateQuestionUseCase";

const QuestionCreateScreen = () => {
  const joditRef = useRef(null);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [listQuestion, setListQuestion] = useState([]);
  //
  useEffect(() => {
    getListQuestion();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);
  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
    setIsLoading(false);
  };

  const getListQuestion = async () => {
    const resp = await GetListQuestionUseCase();
    if (resp.message === "success") setListQuestion(resp.data);
    else setListQuestion([]);
  };

  const createQuestion = async () => {
    try {
      const question = { title, content };

      const check = validateQuestion(question);
      if (check.status) {
        setIsLoading(true);
        const resp = await CreateQuestionUseCase(question);
        if (resp.message === "success") {
          getListQuestion();
          snackBarDialog("agregando");
        } else {
          snackBarDialog("ERROR: NO SE LOGRO CREAR", "error");
        }
      } else {
        snackBarDialog(check.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={"PREGUNTAS FRECUENTES"} subtitle="" />
            </Box>
            {/* crear la pregunta */}
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="TITULO"
                    value={title}
                    variant="outlined"
                    type="text"
                    onChange={(e) => setTitle(e.target.value)}
                    InputLabelProps={{ style: { color: "white" } }}
                    name="title"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box style={{ background: "white", color: "black" }}>
                    <JoditEditor
                      ref={joditRef}
                      value={content}
                      tabIndex={1}
                      onBlur={(newContent) => setContent(newContent)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    sx={{ height: "100%" }}
                    onClick={createQuestion}
                    disabled={isLoading}
                  >
                    AGREGAR
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* lista de preguntas */}
            <Box>
              <QuestionTable data={listQuestion} />
            </Box>
            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleSnkClose}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </main>
      </div>
    </>
  );
};

export default QuestionCreateScreen;
