import { getBackup, getBackupOld } from "../../../../../api/backup/backup";

export const ReadBackup = async () => {
  try {
    const response = await getBackup();
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const ReadBackupOld = async () => {
  try {
    const response = await getBackupOld();
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
