import { baseBackend } from "../../base/baseUrl";

export const getDriverRecharge = async (token, id) => {
  return await baseBackend.get(`/api/v1/recharge/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllRecharge = async (token) => {
  return await baseBackend.get(`/api/v1/recharge`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createRecharge = async (token, recharge) => {
  return await baseBackend.post(
    `/api/v1/recharge`,
    JSON.stringify({ recharge }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
