import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { convertDate } from "../../../../helpers/date/convertDate";

const borderColor = "#424949";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    fontStyle: "bold",
  },
  index: {
    width: "7%",
    height: "100%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // paddingLeft: 1,
    fontSize: 7,
  },
  fulldate: {
    width: "7%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  fullTime: {
    width: "7%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  origin: {
    width: "25%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  destination: {
    width: "25%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  note: {
    width: "14%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  toll: {
    width: "6%",
    height: "100%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 4,
    fontSize: 8,
  },
  price: {
    width: "9%",
    height: "100%",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 6,
    fontSize: 8,
  },
});

const stylesHour = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    height: 50,
    fontStyle: "bold",
  },
  index: {
    width: "7%",
    height: "100%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // paddingLeft: 1,
    fontSize: 7,
  },
  fulldate: {
    width: "14%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 7,
    marginLeft: 2,
    fontSize: 6.5,
  },
  origin: {
    width: "64%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  toll: {
    width: "6%",
    height: "100%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 4,
    fontSize: 8,
  },
  price: {
    width: "9%",
    height: "100%",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 6,
    fontSize: 8,
  },
});

const TableRow = ({ items }) => {
  const compareByDate = (a, b) => {
    const dateB = convertDate(b.date, a.time);
    const dateA = convertDate(a.date, a.time);
    return dateA - dateB;
  };
  const Br = () => "\n";
  const rows = items.sort(compareByDate).map((item) =>
    !item.dateEnd ? (
      <View style={styles.row} key={item.uuid.toString()}>
        <Text style={styles.index}>{item.counterBooking}</Text>
        <Text style={styles.fulldate}>{item.date}</Text>
        <Text style={styles.fullTime}>{item.time}</Text>
        <Text style={styles.origin}>{item.fullorigin}</Text>
        <Text style={styles.destination}>{item.fulldestination}</Text>
        <Text style={styles.note}>{item.note}</Text>
        <Text style={styles.toll}>{item.persons}</Text>
        <Text style={styles.price}>{Number(item.total).toFixed(2)}</Text>
      </View>
    ) : (
      <View style={stylesHour.row} key={item.uuid.toString()}>
        <Text style={stylesHour.index}>{item.counterBooking}</Text>
        <Text style={stylesHour.fulldate}>
          {item.date} {item.time}
          <Br />
          {item.dateEnd} {item.timeEnd}
        </Text>
        <Text style={stylesHour.origin}>
          ORIGEN: {item.fullorigin}
          <Br />
          {item.note}
          <Br />
          DESTINO: {item.fulldestination}
        </Text>
        <Text style={stylesHour.toll}>{item.persons}</Text>
        <Text style={stylesHour.price}>{Number(item.total).toFixed(2)}</Text>
      </View>
    )
  );
  return <Fragment>{rows}</Fragment>;
};

export default TableRow;
