import { createQuestion } from "../../../data/repository/question.repo";

export const CreateQuestionUseCase = async (question) => {
  try {
    const resp = await createQuestion(question);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
