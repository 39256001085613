import { useState } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentEvents, setCurrentEvents] = useState([]);

  const handleDateClick = (selected) => {
    const title = prompt("Titulo del evento");
    const calendarApi = selected.view.calendar;
    calendarApi.unselect();

    if (title) {
      calendarApi.addEvent({
        id: `${selected.startStr}-${title}`,
        title,
        start: selected.startStr,
        end: selected.endStr,
        allDay: selected.allDay,
      });
      console.log(calendarApi);
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [infoSelect, setInfoSelect] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleEventClick = (selected) => {
    setOpen(true);
    console.log(selected.event);
    setInfoSelect(selected);
    //console.log(infoSelect);
    // if (window.confirm(`Eliminar Evento? '${selected.event.title}'`)) {
    //   selected.event.remove();
    // }
  };

  // <GoogleLogin
  //   clientId="684862221723-67amr4vivsj6tuscghuvcba0si8f4rh9.apps.googleusercontent.com"
  //   buttonText="Login"
  //   onSuccess={responseGoogle}
  //   onFailure={responseGoogle}
  //   cookiePolicy={'single_host_origin'}
  // />

  return (
    <Box m="5px">
      <Box display="flex" justifyContent="space-between">
        {/* CALENDAR SIDEBAR */}
        <Grid container spacing={0}>
          <Grid xs={12} sm={10}>
            <Box>
              <FullCalendar
                locale={"es"}
                height="75vh"
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                ]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                }}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                select={handleDateClick}
                eventClick={handleEventClick}
                eventsSet={(events) => {
                  console.log(events);
                  setCurrentEvents(events);
                }}
                initialEvents={initialValues}
              />
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="detail-modal"
                aria-describedby="detail-modal-description"
              >
                <Box sx={style}>
                  <TextField
                    fullWidth
                    label="Title"
                    value={infoSelect ? infoSelect.event.title : ""}
                    id="name"
                    variant="outlined"
                    disabled
                  />
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                  ></Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Duis mollis, est non commodo luctus, nisi erat porttitor
                    ligula.
                  </Typography>
                </Box>
              </Modal>
            </Box>
          </Grid>
          <Grid xs={12} sm={2}>
            <Box
              backgroundColor={colors.primary[400]}
              p={1}
              m={1}
              borderRadius="4px"
            >
              <Typography variant="h5">Eventos</Typography>
              <List>
                {currentEvents.map((event) => (
                  <ListItem
                    key={event.id}
                    sx={{
                      backgroundColor: colors.greenAccent[500],
                      margin: "10px 0",
                      borderRadius: "2px",
                    }}
                  >
                    <ListItemText
                      primary={event.title}
                      secondary={
                        <Typography>
                          {formatDate(event.start, {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Calendar;

const initialValues = [
  {
    id: "1",
    title: "All-day event",
    start: "2022-12-14",
    end: "2022-12-16",
    color: "black", // an option!
    textColor: "yellow", // an option!
  },
  {
    id: "2",
    title: "Timed event",
    start: "2022-12-09",
    end: "2022-12-12",
  },
];
