import { URL_GLOBAL_CONFIGURATION } from "../../../helpers/endpoint";
import { baseBackend } from "../../base/baseUrl";

export const getGlobalConfig = async (company_id) => {
  return await baseBackend.get(`${URL_GLOBAL_CONFIGURATION}/${company_id}`, {
    headers: { "Content-Type": "application/json" },
    //withCredentials: true,
  });
};

export const putGlobalConfig = async (token, globalConfiguration) => {
  return await baseBackend.put(
    URL_GLOBAL_CONFIGURATION,
    JSON.stringify({ globalConfiguration }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const putOneComission = async (token, globalConfiguration) => {
  return await baseBackend.put(
    `/api/v1/resource/global-configuration-one`,
    JSON.stringify({ globalConfiguration }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const putOnlyPremium = async (token, commissions) => {
  return await baseBackend.put(
    `/api/v1/resource/global-configuration-premium`,
    JSON.stringify({ commissions }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
