import { createUserState } from "../../../data/repository/UserStatusRepo";


export const UpdateUserStateUseCase = async (userStatus) => {
  try {
    const resp = await createUserState(userStatus);
    console.log(resp)
  } catch (error) {
    console.log(error);
  }
};
