import {
  Autocomplete,
  Box,
  Button,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidebar from "../../../../components/sidebar/Sidebar";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { Formik } from "formik";
import * as yup from "yup";
import { tokens } from "../../../../theme";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { bonusInitial, bonusSchema } from "./BonusSchema";
import { getTokenRefresh } from "../../../../helpers/token/refreshToken";
import { getDrivers } from "../../../../api/users/driver/driver";
import {
  createRecharge,
  getAllRecharge,
} from "../../../../api/resources/recharge/recharge";
import { DataGrid, esES } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { dateFormat, timeFormat } from "../../../../helpers/date/date";
import {
  createAllBonus,
  createBonus,
  getAllBonus,
} from "../../../../api/resources/bonus/bonus";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Bonus = () => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const colors = tokens(theme.palette.mode);

  const recharge = yup.object().shape(bonusSchema(yup));
  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);
  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [driver, setDriver] = useState(null);
  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    //traer la lista de conductores
    listDrivers();
  }, []);

  useEffect(() => {
    try {
      allBonus();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const listDrivers = async () => {
    try {
      const token = getTokenRefresh();
      if (token) {
        const response = await getDrivers(token);
        setDrivers([]);
        let count = 0;
        Object.values(response.data).map((driver) => {
          if (driver.status !== 3 && driver.approved) {
            let plates = "";
            let bodyworks = "";
            Object.values(driver.vehicle).map((v) => {
              plates = plates + v.vehicle_plate + ", ";
              bodyworks = bodyworks + v.vehicle_bodywork + ", ";
            });
            driver.plates = plates;
            driver.bodyworks = bodyworks;
            driver.index = ++count;
            setDrivers((olData) => [...olData, driver]);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const allBonus = async () => {
    try {
      const response = await getAllBonus();

      const compareByDate = (a, b) => {
        const dateB = a.created_at;
        const dateA = b.created_at;
        return dateA - dateB;
      };
      setData([]);
      Object.values(response?.data?.transactions)
        .sort(compareByDate)
        .map((recharge) => {
          setData((oldArray) => [...oldArray, recharge]);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      headerName: "CONDUCTOR",
      type: "string",
      field: "name",
      align: "left",
      width: 300,
      renderCell: ({ row: { name, lastname } }) => {
        return (
          <p>
            {name} {lastname}
          </p>
        );
      },
    },
    {
      headerName: "MONTO",
      field: "amount",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "FECHA INICIO",
      field: "created_at",
      headerAlign: "left",
      align: "left",
      width: 120,
      renderCell: ({ row: { created_at } }) => {
        const date = dateFormat(new Date(created_at));
        const time = timeFormat(new Date(created_at));
        return (
          <p>
            {date} {time}
          </p>
        );
      },
    },
    {
      headerName: "FECHA EXPIRACION",
      field: "expire_at",
      headerAlign: "left",
      align: "left",
      width: 120,
      renderCell: ({ row: { expire_at } }) => {
        const date = dateFormat(new Date(expire_at));
        const time = timeFormat(new Date(expire_at));
        return (
          <p>
            {date} {time}
          </p>
        );
      },
    },
  ];
  // {
  //   headerName: "Actions",
  //   field: "actions",
  //   width: 140,
  //   align: "center",
  //   renderCell: ({ row: { tour_id } }) => {
  //     return (
  //       <Stack direction="row" spacing={1}>
  //         {/* UPDATE */}
  //         <IconButton
  //           color="success"
  //           aria-label="add"
  //           component="label"
  //           sx={{ background: "#ffff" }}
  //           onClick={() => {
  //             // setUpdateOpen(true);
  //             // tours.map((el) => {
  //             //   if (el.tour_id === tour_id) {
  //             //     console.log(el);
  //             //     setUpdateTour(el);
  //             //   }
  //             // });
  //           }}
  //         >
  //           <BorderColorOutlinedIcon />
  //         </IconButton>
  //         {/* DELETE */}
  //         <IconButton
  //           aria-label="delete"
  //           component="label"
  //           sx={{ background: "#AA151B" }}
  //           onClick={() => {
  //             // setOpen(true);
  //           }}
  //         >
  //           <DeleteIcon />
  //         </IconButton>
  //       </Stack>
  //     );
  //   },
  // },
  const [data, setData] = useState([]);
  const [all, setAll] = useState(false);
  const [date, setDate] = useState(null);

  const handleFormSubmit = async (values) => {
    try {
      const datata = new Date(date.$d);
      const miliSeconds = datata.getTime();

      if (all) {
        const bonus = {
          amount: values.amount,
          expire_at: miliSeconds,
          operations_id: 2,
        };
        const response = await createAllBonus(bonus);
        console.log(response);
      } else {
        const bonus = {
          id: driver.id,
          amount: values.amount,
          expire_at: miliSeconds,
          operations_id: 1,
        };
        const response = await createBonus("", bonus);
        console.log(response);
      }
      allBonus();
      snackBarDialog("BONUS CREADO");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO SE HA LOGRADO CREAR EL BONUS", "error");
      setIsLoading(false);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="5px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="BONUS" subtitle="" />
              <Box></Box>
            </Box>
            {/* ROW CARDS */}
            <Box marginX={matchesSM ? 0 : 3}>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={bonusInitial}
                validationSchema={recharge}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box backgroundColor={colors.primary[400]} p={2}>
                      <Grid
                        container
                        spacing={1.5}
                        sx={{ alignItems: "center" }}
                      >
                        <Grid item xs={12} sm={2}>
                          <FormControl>
                            <FormLabel id="radio-button-bonus-label">
                              SELECCIONE
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="radio-button-bonus-label"
                              value={all}
                              onChange={(e) => {
                                setAll(JSON.parse(e.target.value));
                              }}
                              name="radio-buttons-group"
                            >
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="INDIVIDUAL"
                              />
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="TODOS"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        {all ? (
                          <></>
                        ) : (
                          <Grid item xs={12} sm={5}>
                            {drivers && (
                              <Autocomplete
                                value={driver}
                                disablePortal
                                id="select-origin"
                                onChange={(e, newValue) => {
                                  setDriver(newValue);
                                }}
                                options={drivers}
                                getOptionLabel={(option) =>
                                  `${option.id} ${option.name} ${option.lastname}`
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                renderInput={(params) => (
                                  <TextField {...params} label={"CONDUCTOR"} />
                                )}
                              />
                            )}
                          </Grid>
                        )}

                        <Grid item xs={12} sm={1}>
                          <TextField
                            value={values.amount}
                            fullWidth
                            type="text"
                            label={"MONTO"}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="amount"
                          />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2}>
                              <MobileDatePicker
                                label={"FECHA DE EXPIRACION"}
                                inputFormat="DD/MM/YYYY"
                                name="date"
                                value={date}
                                onChange={(newValue) => {
                                  setDate(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={2} textAlign={"center"}>
                          <Button
                            type="submit"
                            color="secondary"
                            fullWidth
                            variant="contained"
                            sx={{ height: 45 }}
                            disabled={isLoading}
                          >
                            {"AGREGAR"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <SnackBar
                      stateSnk={stateSnk}
                      vertical={vertical}
                      horizontal={horizontal}
                      duration={5000}
                      handleClose={handleSnkClose}
                      severity={severity}
                      messageSnk={messageSnk}
                    />
                  </form>
                )}
              </Formik>
              <Box
                m="10px 0 0 0"
                height="75vh"
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                    color: colors.greenAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                  },
                }}
              >
                <DataGrid
                  getRowId={(row) => row.id}
                  rows={data}
                  columns={columns}
                  localeText={
                    esES.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default Bonus;
