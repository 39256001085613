import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import TableHourHeader from "../tableHour/TableHourHeader";
import TableHourRow from "../tableHour/TableHourRow";
import TableTotal from "../table/TableTotal";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
  },
});

const TableHour = ({ invoice }) => {
  return (
    <View style={styles.tableContainer}>
      <TableHourHeader />
      <TableHourRow items={invoice.items} />
      <TableTotal invoice={invoice} />
    </View>
  );
};

export default TableHour;
