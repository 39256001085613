import { getTourUrl } from "../../../data/repository/tour.repo";

export const GetTourUrlUseCase = async () => {
  try {
    const response = await getTourUrl();
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
