import React, { useState, useEffect } from "react";
import { Button, Box } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  PDFViewer,
  Page,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import Title from "./elements/Title";
import Number from "./elements/Number";
import BillTo from "./elements/BillTo";
import DocumentName from "./elements/DocumentName";
import TableCustomers from "./elements/TableCustomers";
import {
  getBookingNowByOrderNumber,
  getReportTraveller,
} from "../../../../../api/booking/bookingNow";

const ReportManifest = () => {
  const { id, order_number } = useParams();
  const [data, setData] = useState(null);
  const [booking, setBooking] = useState(null);

  useEffect(() => {
    const getTravellers = async () => {
      try {
        const responseBooking = await getBookingNowByOrderNumber(
          "token",
          order_number
        );
        setBooking([]);
        responseBooking.data.map((book) => {
          const origin = book.origin_name.split(",");
          const destination = book.destination_name.split(",");
          book.origin_name =
            origin.length >= 2 ? `${origin[0]}, ${origin[1]}` : `${origin[0]}`;
          book.destination_name =
            destination.length >= 2
              ? `${destination[0]}, ${destination[1]}`
              : `${destination[0]}`;
          setBooking((oldBooking) => [...oldBooking, book]);
        });

        console.log(responseBooking.data);
        const response = await getReportTraveller("token", id);
        console.log(response.data);

        const dataGeneral = {
          id: "5df3180a09ea16dc4b95f910",
          company: "TAXIMUNDO",
          email: "info@taximundo.com",
          phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
          address: "Servicios Generales Trans Mundo E.I.R.L.",

          invoice_no: response?.data?.order_number,
          trans_date: "2019-09-12",
          due_date: "2019-10-12",
          items: response?.data,
        };
        setData(dataGeneral);
        //console.log(response);
      } catch (error) {
        console.log(error);
      }
    };
    getTravellers();
  }, []);

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  return (
    <PDFDownloadLink
      document={
        <Document>
          {booking && data && (
            <Page size="A4" style={styles.page}>
              <Title title="Taxi Mundo PERU" />
              <BillTo invoice={data} />
              <DocumentName
                title={"MANIFESTO DE PASAJEROS"}
                number={booking[0].order_number}
              />
              <Number invoice={booking[0]} />
              <TableCustomers invoice={data} />
            </Page>
          )}
        </Document>
      }
      fileName={"detalles-cotizacion-taximundo.pdf"}
    >
      <Box width={"100%"}>
        {booking && data ? (
          <Button fullWidth variant="contained" color="success">
            <PictureAsPdfIcon /> DESCARGAR
          </Button>
        ) : (
          <>cargardo...</>
        )}
      </Box>
    </PDFDownloadLink>
  );
};
export default ReportManifest;
