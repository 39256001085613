import React, { useState, useEffect } from "react";
import { Box, CssBaseline } from "@mui/material";

import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { GetBookingListUseCase } from "../../../domain/usecases/booking/GetBookingListUseCase";
import ReserveTable from "../components/ReserveTable";

const BookingListScreen = () => {
  const [listReserve, setListReserve] = useState([]);

  useEffect(() => {
    const getReserveList = async () => {
      const resp = await GetBookingListUseCase();
      setListReserve([]);
      if (resp.message === "success") {
        console.log(resp.reservation);
        resp.reservation.map((item) => {
          if (!Boolean(item.service_enterprise)) {
            setListReserve((oldData) => [...oldData, item]);
          }
        });
      } else setListReserve([]);
    };
    getReserveList();
  }, []);

  //SNACKBAR
  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleSelectReservation = (data) => {};

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="RESERVAS" subtitle="" />
            </Box>
            <Box>
              <ReserveTable
                data={listReserve}
                handleSelectReservation={handleSelectReservation}
              />
              <SnackBar
                stateSnk={stateSnk}
                vertical={vertical}
                horizontal={horizontal}
                duration={4000}
                handleClose={handleSnkClose}
                severity={severity}
                messageSnk={messageSnk}
              />
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default BookingListScreen;
