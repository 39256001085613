import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#3498DB";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000000",
    backgroundColor: "#3498DB",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    color: "white",
    fontFamily: "Helvetica-Bold",
    flexGrow: 1,
    fontSize: 9,
  },
  index: {
    width: "7%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 3,
    fontSize: 9,
  },
  plate: {
    width: "7%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
    fontSize: 9,
  },
  fullname: {
    width: "46%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
    fontSize: 9,
  },
  year: {
    width: "6%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
    fontSize: 9,
  },
  setare: {
    width: "12%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
    fontSize: 9,
  },
  weight: {
    width: "11%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
    fontSize: 9,
  },
  cylinder: {
    width: "11%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    marginTop: 4,
    fontSize: 9,
  },
});

const TableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.index}>No</Text>
    <Text style={styles.plate}>PLACA</Text>
    <Text style={styles.fullname}>PROPIETARIO</Text>
    <Text style={styles.year}>AÑO</Text>
    <Text style={styles.setare}>SETARE</Text>
    <Text style={styles.weight}>PESO</Text>
    <Text style={styles.cylinder}>CILINDRADO</Text>
  </View>
);

export default TableHeader;
