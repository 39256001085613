import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../../../../theme";
import Header from "../../../../../components/header/Header";
import Topbar from "../../../../../components/topbar/TopBar";
import { getCompanies } from "../../../../../api/users/admin/company";
import { Formik } from "formik";
import * as yup from "yup";
import SnackBar from "../../../../../components/snackbar/SnackBar";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../components/sidebar/Sidebar";

export const ListCompany = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    const getListCompanies = async () => {
      try {
        const response = await getCompanies("token");
        console.log(response.data);
        setData([]);
        response.data.map((admin, index) => {
          admin.index = index + 1;
          setData((oldaData) => [...oldaData, admin]);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getListCompanies();
  }, []);

  const dataAdmin = {
    name: "",
    address: "",
  };

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    address: yup.string().required("required"),
  });

  const columns = [
    { field: "index", headerName: "ID", width: 50 },
    {
      headerName: "EMPRESA",
      field: "name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 130,
    },
    {
      headerName: "DIRECCION",
      field: "address",
      width: 200,
    },
    {
      headerName: "ESTADO",
      field: "status",
      width: 90,
      align: "center",
    },
  ];

  const handleFormSubmit = async (values) => {
    try {
      setIsLoading(true);
      const admin = {
        name: values.name.toUpperCase(),
        address: values.address.toUpperCase(),
      };
      snackBarDialog("ADMIN CREADO");
      setIsLoading(false);
    } catch (error) {
      snackBarDialog("ERROR NO SE CREO EL ADMIN", "error");
      setIsLoading(false);
      console.log(error);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box margin={1}>
            <Box marginLeft={1} marginRight={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <Header title="EMPRESAS-ADMIN" subtitle="" />
                </Grid>
              </Grid>
            </Box>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={dataAdmin}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    backgroundColor={colors.primary[400]}
                    borderRadius={3}
                    p={1}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5}>
                        <TextField
                          value={values.name}
                          fullWidth
                          variant="outlined"
                          type="text"
                          label={"NOMBRE"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="name"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={!!touched.name && !!errors.name}
                          helperText={touched.name && errors.name}
                          color="success"
                        />
                      </Grid>

                      <Grid item xs={12} sm={5}>
                        <TextField
                          value={values.address}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="text"
                          label={"DIRECCION"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="address"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={!!touched.address && !!errors.address}
                          helperText={touched.address && errors.address}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2} textAlign={"end"}>
                        <Button
                          fullWidth
                          type="submit"
                          color="secondary"
                          variant="contained"
                          sx={{ height: 50 }}
                          disabled={isLoading}
                        >
                          CREAR CUENTA
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <SnackBar
                    stateSnk={stateSnk}
                    vertical={vertical}
                    horizontal={horizontal}
                    duration={4000}
                    handleClose={handleSnkClose}
                    severity={severity}
                    messageSnk={messageSnk}
                  />
                </form>
              )}
            </Formik>
            <Box
              m="10px 0 0 0"
              height="100vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid
                getRowId={(row) => row.id}
                rows={data}
                columns={columns}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};
