import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { Box, Button, CssBaseline } from "@mui/material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

import { getTokenRefresh } from "../../../../helpers/token/refreshToken";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import VehicleForm from "../../../../components/form/vehicle/VehicleForm";
import { getVehicle } from "../../../../api/users/driver/vehicle";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

const Vehicle = () => {
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const [vehicle, setVehicle] = useState(null);
  const [isUpdate, setIsUpdate] = useState(true);

  const { id } = useParams();

  const checkoutSchema = yup.object().shape({
    brand: yup.string().required("required"),
    model: yup.string().required("required"),
    bodywork: yup.string().email("invalid email").required("required"),
    year: yup.string().required("required"),
    color: yup.string().required("required"),
    technical_review: yup.number().required("required"),
    start: yup.number().required("required"),
    end: yup.number().required("required"),
    soat: yup.number().required("required"),
    soatStart: yup.number().required("required"),
    soatEnd: yup.number().required("required"),
    setare: yup.number().required("required"),
    setareStart: yup.number().required("required"),
    setareEnd: yup.number().required("required"),
  });

  const initialValues = {
    brand: "",
    model: "",
    bodywork: "",
    plate: "",
    year: "",
    color: "",
    technical_review: "",
    start: "",
    end: "",
    soat: "",
    soatStart: "",
    soatEnd: "",
    setare: "",
    setareStart: "",
    setareEnd: "",
  };

  useEffect(() => {
    vehicleComplete();
  }, []);

  const vehicleComplete = async () => {
    try {
      const token = getTokenRefresh();
      const response = await getVehicle(token, id);
      console.log(response);
      setVehicle(response?.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="VEHICULO" subtitle="" />

              <Button
                color="secondary"
                onClick={(e) => {
                  setIsUpdate(!isUpdate);
                }}
              >
                <CreateOutlinedIcon />
                EDITAR VEHICULO
              </Button>
            </Box>
            {vehicle ? (
              <VehicleForm
                vehicle={vehicle}
                operation={true}
                isUpdate={isUpdate}
              />
            ) : (
              <div>loading...</div>
            )}
          </Box>
        </main>
      </div>
    </>
  );
};

export default Vehicle;
