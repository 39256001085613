import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  useTheme,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";

import { tokens } from "../../../../../theme";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import { tourInitial, tourSchema } from "./schema/TourSchema";
import { getListCountryUseCase } from "../usecases/country/GetListCountryUseCase";
import { createTourUseCase } from "../usecases/tour/CreateTourUseCase";

const TourForm = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const checkoutSchema = yup.object().shape(tourSchema(yup));

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getCountrys = async () => {
      try {
        const response = await getListCountryUseCase();
        setCountries([]);
        setCountries(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCountrys();
  }, []);

  const handleFormSubmit = async (values) => {
    try {
      if (country === null) {
        snackBarDialog("SELECCIONE EL PAIS", "error");
        return;
      }
      console.log(values);
      setIsLoading(true);
      const tour = {
        country_id: country.id,
        code: values.code.toUpperCase(),
        description: values.description.toUpperCase(),
        url: values.url,
        price: values.price,
      };
      const response = await createTourUseCase(tour);
      console.log(response);
      snackBarDialog("TARIFA AGREGADA");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO RE AGREGO LA TARIFA", "error");
      setIsLoading(false);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={tourInitial}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box backgroundColor={colors.primary[400]} p={2}>
            <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
              <Grid item xs={12} sm={2}>
                <TextField
                  value={values.code}
                  fullWidth
                  type="text"
                  label={"CODIGO"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  InputLabelProps={{ style: { color: "white" } }}
                  name="code"
                  error={!!touched.code && !!errors.code}
                  helperText={touched.code && errors.code}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  value={values.description}
                  fullWidth
                  type="text"
                  label={t("DESCRIPCION").toUpperCase()}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  InputLabelProps={{ style: { color: "white" } }}
                  name="description"
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                />
              </Grid>

              <Grid item xs={12} sm={5}>
                <TextField
                  value={values.url}
                  fullWidth
                  type="text"
                  label={t("URL").toUpperCase()}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="url"
                  error={!!touched.url && !!errors.url}
                  helperText={touched.url && errors.url}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                {countries && (
                  <Autocomplete
                    value={country}
                    disablePortal
                    id="select-origin"
                    onChange={(e, newValue) => {
                      setCountry(newValue);
                    }}
                    options={countries}
                    getOptionLabel={(option) => `${option.id} ${option.name}`}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("PAIS")} />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={2}>
                <TextField
                  value={values.price}
                  fullWidth
                  type="number"
                  label={"PRECIO"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="price"
                  error={!!touched.price && !!errors.price}
                  helperText={touched.price && errors.price}
                />
              </Grid>

              <Grid item xs={12} sm={3} textAlign={"center"}>
                <Button
                  type="submit"
                  fullWidth
                  color="secondary"
                  variant="contained"
                  sx={{ height: 45 }}
                  disabled={isLoading}
                >
                  {t("AGREGAR")}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={5000}
            handleClose={handleSnkClose}
            severity={severity}
            messageSnk={messageSnk}
          />
        </form>
      )}
    </Formik>
  );
};

export default TourForm;
