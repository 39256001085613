import { URL_ENTEPRISE } from "../../helpers/endpoint";
import { baseBackend } from "../base/baseUrl";

export const getListEnterprises = async (token, company_id) => {
  return await baseBackend.get(`${URL_ENTEPRISE}/${company_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getListEnterprisesInvoice = async (token, company_id) => {
  return await baseBackend.get(`${URL_ENTEPRISE}/invoice/${company_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getEnterprise = async (token, enterprise_id) => {
  return await baseBackend.get(`${URL_ENTEPRISE}/edit/${enterprise_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteEnterpriseApi = async (enterprise_id, status) => {
  return await baseBackend.delete(`${URL_ENTEPRISE}/${enterprise_id}`, {
    data: { status },
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const createdEnterpriseAndEmployee = async (enterprise, employee) => {
  return await baseBackend.post(
    `/api/v1/employee/business`,
    JSON.stringify({ enterprise, employee }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const createEnterprise = async (token, enterprise) => {
  return await baseBackend.post(
    `/api/v1/employee/enterprise`,
    JSON.stringify({ enterprise }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateEnterprise = async (token, enterprise) => {
  return await baseBackend.put(URL_ENTEPRISE, JSON.stringify({ enterprise }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const searchBookingPerEnterprise = async (enterprise_id, from, to) => {
  return await baseBackend.get(
    `/api/v1/report/enterprise/${enterprise_id}/from/${from}/to/${to}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
