import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Modal,
  Stack,
  useTheme,
} from "@mui/material";

import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import TableBookBusinessLater from "../../../../components/datatable/TableBookBusinessLater";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../../../theme";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { DataGrid } from "@mui/x-data-grid";
import { db } from "../../../../firebase";
import { onValue, ref, remove } from "firebase/database";
import { convertDate } from "../../../../helpers/date/convertDate";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { format } from "date-fns";
import { dateFormat } from "../../../../helpers/date/date";

const ListBookBusinessNow = () => {
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [booking, setBooking] = useState([]);
  const [UID, setUID] = useState("");

  useEffect(() => {
    const starCountRef = ref(db, "BookingBusiness");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setBooking([]);
      if (data !== null) {
        const currentDate = new Date();
        const [day, month, year] = data.date;
        if (
          Number(day) === currentDate.getDate() &&
          Number(month - 1) === currentDate.getMonth() &&
          Number(year) === currentDate.getFullYear()
        ) {
          const compareByDate = (a, b) => {
            const dateB = convertDate(b.date, b.time);
            const dateA = convertDate(a.date, a.time);
            return dateA - dateB;
          };
          Object.values(data)
            .sort(compareByDate)
            .map((booking) => {
              if (booking.cotizar) {
                booking.fullorigin = booking.origin.name;
                booking.fulldestination = booking.destination.name;
                setBooking((oldArray) => [...oldArray, booking]);
                console.log(booking);
              }
            });
        }
      }
    });
  }, []);

  const columnsClient = [
    {
      headerName: "PEDIDO",
      field: "counterBooking",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      headerName: "FECHA",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "HORA",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "CLIENTE",
      field: "clientName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 220,
    },
    {
      headerName: "EMPRESA",
      field: "enterpriseName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
    },
    {
      headerName: "Origen",
      field: "fullorigin",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
    },
    {
      headerName: "destino",
      field: "fulldestination",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
    },
    {
      headerName: "Pack",
      field: "persons",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      headerName: "Notas",
      field: "note",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
    },
    {
      headerName: "Conductor",
      field: "statusService",
      type: "string",
      width: 90,
      renderCell: ({ row: { statusService } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              width: "100%",
              color: "white",
              background: statusService === "PENDIENTE" ? "red" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {statusService}
          </div>
        );
      },
    },
    {
      headerName: "Estado",
      field: "statusPayment",
      width: 90,
      renderCell: ({ row: { statusPayment } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              width: "100%",
              color: "white",
              background: statusPayment === "PENDIENTE" ? "red" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {statusPayment}
          </div>
        );
      },
    },
    {
      headerName: "Creado",
      field: "createBooking",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      renderCell: ({ row: { client, uuid } }) => {
        return (
          <Stack direction="row" spacing={2}>
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() =>
                navigate(`/admin/add-booking-business-later/${uuid}`)
              }
            >
              <Edit />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleCloseSnk = () => setStateSnk(false);

  return (
    <div>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="RESERVAS DE HOY" subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box>
                <Box
                  m="10px 0 0 0"
                  height="75vh"
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .name-column--cell": {
                      color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: colors.blueAccent[700],
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                      borderTop: "none",
                      backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                  }}
                >
                  <DataGrid
                    checkboxSelection
                    getRowId={(row) => row.uuid}
                    rows={booking}
                    columns={columnsClient}
                  />
                </Box>
                <SnackBar
                  stateSnk={stateSnk}
                  vertical={vertical}
                  horizontal={horizontal}
                  duration={4000}
                  handleClose={handleCloseSnk}
                  severity={severity}
                  messageSnk={messageSnk}
                />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};

export default ListBookBusinessNow;
