import { updateBackupData } from "../../../../../api/backup/backup";

export const UpdateDataBackup = async (backup) => {
  try {
    const response = await updateBackupData(backup);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
