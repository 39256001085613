import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CssBaseline,
  Grid,
  TextField,
  useTheme,
  Stack,
  IconButton,
  Modal,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useParams } from "react-router-dom";
import Topbar from "../../../../../components/topbar/TopBar";
import { tokens } from "../../../../../theme";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "./DataGridStyle";
import { Formik } from "formik";
import * as yup from "yup";
import { rateInitial, rateSchema } from "./RateScheme";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import {
  createdDriverRate,
  deleteDriverRate,
  getDriverRate,
  modifyDriverRate,
  updateDriverRate,
} from "../../../../../api/users/driver/rate";
import { getListTourCodeUseCase } from "../../tour/usecases/tour/GetListTourCodeUseCase";
import Header from "../../../../../components/header/Header";
import { modalStyle } from "./ModalStyle";
import { dateFormat, timeFormat } from "../../../../../helpers/date/date";
import { TimerOffOutlined } from "@mui/icons-material";

const DriverRate = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const checkoutSchema = yup.object().shape(rateSchema(yup));

  const [UID, setUID] = useState();
  const [open, setOpen] = useState(false);
  const handleModalClose = () => setOpen(false);
  const [openEdit, setOpenEdit] = useState(false);
  const handleEditModalClose = () => setOpenEdit(false);

  const [code, setCode] = useState();
  const [codes, setCodes] = useState([]);
  const [data, setData] = useState([]);
  const [rateEdit, setRateEdit] = useState({});

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const location = useLocation();
  const driverName = location.state.fullname;
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  useEffect(() => {
    const getListTourCode = async () => {
      try {
        const response = await getListTourCodeUseCase();
        console.log(response.data);
        setCodes(response.data.response);
      } catch (error) {
        console.log(error);
      }
    };

    getListTourCode();
  }, []);

  useEffect(() => {
    const getDriverRateID = async () => {
      try {
        const response = await getDriverRate(id);
        console.log(response.data.response);
        setData(response.data.response);
      } catch (error) {
        console.log(error);
      }
    };
    getDriverRateID();
  }, []);

  const columns = [
    {
      headerName: "CODIGO",
      field: "code",
      with: 50,
    },
    {
      headerName: "ORIGEN",
      field: "origin",
      width: 250,
    },
    {
      headerName: "DESTINO",
      field: "destination",
      width: 250,
    },
    {
      headerName: "PRECIO",
      field: "price",
      align: "center",
      width: 80,
    },
    {
      headerName: "NOTAS",
      field: "notes",
      width: 200,
    },
    {
      headerName: "MOFIFICACION",
      field: "updated_at",
      width: 120,
      renderCell: ({ row: { updated_at } }) => {
        const updated = new Date(updated_at);
        return (
          <div>
            {dateFormat(updated)} {timeFormat(updated)}
          </div>
        );
      },
    },
    {
      headerName: "",
      field: "actions",
      width: 100,
      renderCell: ({ row: { id, origin, destination, notes, price } }) => {
        return (
          <Stack direction="row" spacing={0.5}>
            {/* EDITAR */}
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                setUID(id);
                setRateEdit({
                  id,
                  origin,
                  destination,
                  notes,
                  price,
                });
                setOpenEdit(true);
              }}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
            {/* ELIMINAR */}
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(id);
                setOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const handleFormSubmit = async (values) => {
    try {
      setIsLoading(true);

      const driver_rate = {
        drivers_id: id,
        tour_code_id: code ? code.id : null,
        origin: values.origin.toUpperCase(),
        destination: values.destination.toUpperCase(),
        price: values.price,
        notes: values.notes.toUpperCase(),
      };

      await createdDriverRate(driver_rate);
      snackBarDialog("TARIFA AGREGADA");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO RE AGREGO LA TARIFA", "error");
      setIsLoading(false);
    }
  };

  const handleUpdateSubmit = async (values) => {
    try {
      setIsLoading(true);
      console.log(values);

      const driver_rate = {
        id: values.id,
        drivers_id: id,
        tour_code_id: code ? code.id : null,
        origin: values.origin.toUpperCase(),
        destination: values.destination.toUpperCase(),
        price: values.price,
        notes: values.notes.toUpperCase(),
      };

      await updateDriverRate(driver_rate);
      snackBarDialog("TARIFA ACTUALIZADA");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO SE ACTUALIZO LA TARIFA", "error");
      setIsLoading(false);
    }
  };

  const handleDeleteDriverRate = async () => {
    setIsLoading(true);
    try {
      await deleteDriverRate(UID);
      snackBarDialog("CONDUCTOR ELIMINADO");
      //setDrivers(drivers.filter((driver) => driver.id !== UID));
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      snackBarDialog("error no se ha eliminado");
      console.log(error);
      setIsLoading(false);
    }
  };

  const [modifyRate, setModifyRate] = useState(false);

  const modifyRateChange = (e) => {
    setModifyRate(JSON.parse(e.target.value));
  };

  const handleModifyRate = async () => {
    try {
      await modifyDriverRate(id, modifyRate);
      snackBarDialog("ESTADO DE TARIFA MODIFICADO");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR AL ACTUALIZAR ESTADO");
      setIsLoading(false);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Header title={driverName} subtitle="TARIFAS" />
            <Grid container alignItems="center" justify="center" spacing={1}>
              <Grid item xs={12} sm={2}>
                <FormControl>
                  <FormLabel id="radio-modify" color="success">
                    MODIFICAR TARIFAS
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="radio-modify"
                    value={modifyRate}
                    onChange={modifyRateChange}
                    name="radio"
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio color="success" />}
                      label="SI"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="success" />}
                      label="NO"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={2}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  sx={{ height: 30 }}
                  disabled={isLoading}
                  onClick={handleModifyRate}
                >
                  {"ACTUALIZAR"}
                </Button>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Formik
                  onSubmit={handleFormSubmit}
                  initialValues={rateInitial}
                  validationSchema={checkoutSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box backgroundColor={colors.primary[400]} p={2}>
                        <Grid
                          container
                          spacing={1.5}
                          sx={{ alignItems: "center" }}
                        >
                          <Grid item xs={12} sm={2}>
                            <Autocomplete
                              id="id-codes"
                              value={code}
                              onChange={(e, newValue) => setCode(newValue)}
                              options={codes}
                              getOptionLabel={(option) => `${option.code}`}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"CODIGO TOUR"}
                                  InputLabelProps={{
                                    style: { color: "white" },
                                  }}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={5}>
                            <TextField
                              value={values.origin}
                              fullWidth
                              type="text"
                              label={"ORIGEN"}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="origin"
                              error={!!touched.origin && !!errors.origin}
                              helperText={touched.origin && errors.origin}
                            />
                          </Grid>

                          <Grid item xs={12} sm={5}>
                            <TextField
                              value={values.destination}
                              fullWidth
                              type="text"
                              label={"DESTINO"}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="destination"
                              error={
                                !!touched.destination && !!errors.destination
                              }
                              helperText={
                                touched.destination && errors.destination
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <TextField
                              value={values.price}
                              fullWidth
                              type="number"
                              label={"PRECIO"}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="price"
                              InputLabelProps={{ style: { color: "white" } }}
                              error={!!touched.price && !!errors.price}
                              helperText={touched.price && errors.price}
                            />
                          </Grid>

                          <Grid item xs={12} sm={8}>
                            <TextField
                              value={values.notes}
                              fullWidth
                              type="text"
                              label={"NOTAS"}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="notes"
                              error={!!touched.notes && !!errors.notes}
                              helperText={touched.notes && errors.notes}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2} textAlign={"center"}>
                            <Button
                              type="submit"
                              fullWidth
                              color="secondary"
                              variant="contained"
                              sx={{ height: 45 }}
                              disabled={isLoading}
                            >
                              {"AGREGAR"}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                      <SnackBar
                        stateSnk={stateSnk}
                        vertical={vertical}
                        horizontal={horizontal}
                        duration={5000}
                        handleClose={handleSnkClose}
                        severity={severity}
                        messageSnk={messageSnk}
                      />
                    </form>
                  )}
                </Formik>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box m="10px 0 0 0" height="100vh" sx={DataGridStyle(colors)}>
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={data}
                    columns={columns}
                  />
                </Box>
              </Grid>
            </Grid>

            <Modal
              open={openEdit}
              onClose={handleEditModalClose}
              aria-labelledby="modal-debt-title"
            >
              <Box
                sx={{
                  ...modalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Formik
                  onSubmit={handleUpdateSubmit}
                  initialValues={rateEdit}
                  validationSchema={checkoutSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box backgroundColor={colors.primary[400]} p={2}>
                        <Grid
                          container
                          spacing={1.5}
                          sx={{ alignItems: "center" }}
                        >
                          <Grid item xs={12} sm={12}>
                            <Autocomplete
                              id="id-codes"
                              value={code}
                              onChange={(e, newValue) => setCode(newValue)}
                              options={codes}
                              getOptionLabel={(option) => `${option.code}`}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"CODIGO TOUR"}
                                  InputLabelProps={{
                                    style: { color: "white" },
                                  }}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <TextField
                              value={values.origin}
                              fullWidth
                              type="text"
                              label={"ORIGEN"}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="origin"
                              error={!!touched.origin && !!errors.origin}
                              helperText={touched.origin && errors.origin}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <TextField
                              value={values.destination}
                              fullWidth
                              type="text"
                              label={"DESTINO"}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="destination"
                              error={
                                !!touched.destination && !!errors.destination
                              }
                              helperText={
                                touched.destination && errors.destination
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <TextField
                              value={values.price}
                              fullWidth
                              type="number"
                              label={"PRECIO"}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="price"
                              InputLabelProps={{ style: { color: "white" } }}
                              error={!!touched.price && !!errors.price}
                              helperText={touched.price && errors.price}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <TextField
                              value={values.notes}
                              fullWidth
                              type="text"
                              label={"NOTAS"}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="notes"
                              error={!!touched.notes && !!errors.notes}
                              helperText={touched.notes && errors.notes}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} textAlign={"center"}>
                            <Button
                              type="submit"
                              fullWidth
                              color="secondary"
                              variant="contained"
                              sx={{ height: 45 }}
                              disabled={isLoading}
                            >
                              {"ACTUALIZAR"}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                      <SnackBar
                        stateSnk={stateSnk}
                        vertical={vertical}
                        horizontal={horizontal}
                        duration={5000}
                        handleClose={handleSnkClose}
                        severity={severity}
                        messageSnk={messageSnk}
                      />
                    </form>
                  )}
                </Formik>
              </Box>
            </Modal>
            {/* DELETE */}
            <Modal open={open} onClose={handleModalClose}>
              <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
                <Typography variant="h6" component="h2" textAlign={"center"}>
                  ELIMINAR TARIFA?
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} md={6} textAlign="center">
                    <Button
                      color="secondary"
                      variant="contained"
                      fullWidth
                      onClick={handleDeleteDriverRate}
                      disabled={isLoading}
                    >
                      ELIMINAR
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6} textAlign="center">
                    <Button
                      color="error"
                      variant="contained"
                      fullWidth
                      onClick={handleModalClose}
                      disabled={isLoading}
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </Box>
        </main>
      </div>
    </>
  );
};

export default DriverRate;
