import { baseBackend } from "../../../api/base/baseUrl";
const URL_CITY = "api/v1/module/city";

export const createCity = async (city) => {
  return await baseBackend.post(`${URL_CITY}`, city, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getCity = async () => {
  return await baseBackend.get(`${URL_CITY}/list`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getCityById = async (id) => {
  return await baseBackend.get(`${URL_CITY}/id/${id}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const updateCity = async (city) => {
  return await baseBackend.put(`${URL_CITY}/update`, city, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getCountryAndCityList = async () => {
  return await baseBackend.get(`${URL_CITY}/country`, {
    headers: { "Content-Type": "application/json" },
  });
};
