import { Box, CssBaseline } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../../../../components/header/Header";
import Topbar from "../../../../../components/topbar/TopBar";
import AddressForm from "./AddressForm";
import AddressList from "./AddressList";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../components/sidebar/Sidebar";
import { getEnterpriseAddress } from "../../../../../api/enterprise/address";
import { getToken } from "../../../../../helpers/token/refreshToken";
import { useEffect, useState } from "react";

const AddressAdd = () => {
  const { id } = useParams();
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const location = useLocation();
  const enterpriseName = location.state.enterpriseName;

  //aqui seria traer toda la lista de direcciones
  //
  const [address, setAddress] = useState([]);

  useEffect(() => {
    const getAddressEnterprise = async () => {
      try {
        const token = getToken("atknre");
        const response = await getEnterpriseAddress(token, id);
        setAddress([]);
        Object.values(response?.data).map((_address, index) => {
          _address.index = index + 1;
          setAddress((oldData) => [...oldData, _address]);
        });
        //}
      } catch (error) {
        console.log(error);
      }
    };
    getAddressEnterprise();
  }, []);

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={enterpriseName} subtitle="AGREGAR DIRECCIONES" />
              <Box></Box>
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <AddressForm address={address} enterprise_id={id} />
                <AddressList address={address} enterprise_id={id} />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default AddressAdd;
