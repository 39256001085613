import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    marginTop: 1,
    display: "flex",
    alignSelf: "center",
  },
  document: {
    color: "#4B44BE",
    fontSize: 18,
    textTransform: "uppercase",
    fontFamily: "Helvetica-Bold",
    marginRight: 60,
  },
  numb: {
    color: "#4B44BE",
    fontSize: 18,
    textTransform: "uppercase",
    fontFamily: "Helvetica-Bold",
    marginLeft: 10,
  },
});

const DocumentName = ({ title, number }) => (
  <View style={styles.titleContainer}>
    <Text style={styles.document}>{title}</Text>
    <Text style={styles.numb}>Nro: {number}</Text>
  </View>
);

export default DocumentName;
