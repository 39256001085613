import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CssBaseline,
  Menu,
  MenuItem,
  useTheme,
  Fade,
  Popper,
  Paper,
  Typography,
  Grid,
  Modal,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Autocomplete,
  Checkbox,
} from "@mui/material";

import Topbar from "../../../../../components/topbar/TopBar";
import Header from "../../../../../components/header/Header";
import {
  getBookingNow,
  updateBookingTravelStatus,
  updatePaymentBookingNow,
  updateStatusBooking,
} from "../../../../../api/booking/bookingNow";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import { convertDate } from "../../../../../helpers/date/convertDate";
import { tokens } from "../../../../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../components/sidebar/Sidebar";
import {
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../../../helpers/token/refreshToken";
import { getDriverAndVehicle } from "../../../../../api/users/driver/driver";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
} from "@react-pdf/renderer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { DataGrid, esES } from "@mui/x-data-grid";
import DocumentName from "./pdf/now/elements/DocumentName";
import TableBooking from "./pdf/now/elements/TableBooking";
import Contact from "../../vehicle/pdf/elements/Contact";
import {
  createAdvanceCash,
  getAdvanceCashOrder,
} from "../../../../../api/booking/advance";
import { dateFormat, timeFormat } from "../../../../../helpers/date/date";
import HeaderM from "./pdf/later/elements/HeaderM";
import VoucherHistory from "../../../../../components/datatable/voucherfinal/Voucher";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const HistoryListBookingNow = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const [booking, setBooking] = useState([]);
  const [UID, setUID] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const payment = { 1: "EFECTIVO", 2: "CREDITO" };

  const travelStatus = {
    1: "ASIGNADO",
    2: "EN RUTA",
    3: "FINALIZADO",
  };

  const [drivers, setDrivers] = useState(null);
  const [driverSelect, setDriverSelect] = useState(null);
  const [advance, setAdvance] = useState(0);
  const [otherPay, setOtherPay] = useState(false);
  const [datePay, setDatePay] = useState("");

  const handleOtherPay = (e) => {
    const isChecked = e.target.checked;
    setOtherPay(isChecked);
    if (isChecked) {
      const currentDate = new Date();
      setDatePay(`${dateFormat(currentDate)} ${timeFormat(currentDate)}`);
    } else {
      setDatePay("");
    }
  };

  useEffect(() => {
    const historyBooking = async () => {
      const token = "ff";
      try {
        const response = await getBookingNow(token);
        const compareByDate = (a, b) => {
          const dateB = convertDate(b.date, b.time);
          const dateA = convertDate(a.date, a.time);
          return dateB - dateA;
        };
        setBooking([]);
        Object.values(response?.data)
          .sort(compareByDate)
          .map((booking) => {
            setBooking((oldArray) => [...oldArray, booking]);
          });
      } catch (error) {
        console.log(error);
      }
    };
    historyBooking();
  }, []);

  useEffect(() => {
    const driversData = async () => {
      const verify = verifyExpireTokenRefresh();
      if (verify) return console.log("no token");

      const token = getTokenRefresh();
      try {
        const response = await getDriverAndVehicle(token);
        setDrivers(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    driversData();
  }, []);

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };

  const columns = [
    {
      headerName: "Pedido",
      field: "order_number",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "Fecha",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Hora",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
      renderCell: ({
        row: {
          time,
          client_fullname,
          clientPhone,
          origin_name,
          destination_name,
          date,
          persons,
          driver_fullname,
          driver_phone,
          vehicle_bodywork,
          vehicle_plate,
          vehicle_color,
          vehicle_brand,
          notes,
        },
      }) => {
        const url = `https://api.whatsapp.com/send?phone=${clientPhone}=&text=*Estimado Usuario:* ${client_fullname} ,%0A*Origen:* ${encodeURIComponent(
          origin_name
        )}%0A*Destino:* ${encodeURIComponent(
          destination_name
        )}%0A*Fecha:* ${date} - *Hora:* ${time}%0A*Pasajeros:* ${persons}%0A*Conductor Asignado:* ${driver_fullname}%0A*Model:* ${vehicle_brand}%0A*Carroceria:* ${vehicle_bodywork}%0A*Placa:* ${vehicle_plate}%0A*Color:* ${vehicle_color}%0A*NOTA:* ${notes}%0A*Telefono Conductor:* ${driver_phone}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 14,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {time}
          </a>
        );
      },
    },
    {
      headerName: "Nombre",
      field: "client_fullname",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 180,
      renderCell: ({ row: { client_fullname, clients_id, order_number } }) => {
        const url = `/admin/report-manifest/${clients_id}/${order_number}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 14,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {client_fullname}
          </a>
        );
      },
    },
    {
      headerName: "Origen",
      field: "origin_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
      renderCell: ({ row: { origin_name, origin_lat, origin_lng } }) => {
        const url = `https://maps.google.com/?q=${origin_lat},${origin_lng}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 14,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {origin_name}
          </a>
        );
      },
    },
    {
      headerName: "Destino",
      field: "destination_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
      renderCell: ({
        row: { destination_name, destination_lat, destination_lng },
      }) => {
        const url = `https://maps.google.com/?q=${destination_lat},${destination_lng}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 14,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {destination_name}
          </a>
        );
      },
    },
    {
      headerName: "Pax",
      field: "persons",
      headerAlign: "left",
      align: "center",
      width: 20,
    },
    {
      headerName: "Notas",
      field: "notes",
      width: 200,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Placa",
      field: "vehicle_plate",
      width: 90,
    },
    {
      headerName: "Tipo",
      field: "vehicle_bodywork",
      width: 90,
    },
    {
      headerName: "Conductor",
      field: "driver_fullname",
      width: 200,
      renderCell: ({
        row: {
          time,
          clientPhone,
          client_fullname,
          origin_name,
          destination_name,
          date,
          persons,
          driver_fullname,
          driver_phone,
          vehicle_bodywork,
          vehicle_plate,
          vehicle_color,
          vehicle_brand,
          notes,
        },
      }) => {
        const url = `https://api.whatsapp.com/send?phone=${driver_phone}=&text=*Pasajero:* ${client_fullname} ,%0A*Origen:* ${encodeURIComponent(
          origin_name
        )}%0A*Destino:* ${encodeURIComponent(
          destination_name
        )}%0A*Fecha:* ${date} - *Hora:* ${time}%0A*Pasajeros:* ${persons}%0A*Conductor Asignado:* ${driver_fullname}%0A*Model:* ${vehicle_brand}%0A*Carroceria:* ${vehicle_bodywork}%0A*Placa:* ${vehicle_plate}%0A*Color:* ${vehicle_color}%0A*NOTA:* ${notes}%0A*Telefono Cliente:* ${clientPhone}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 14,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {driver_fullname}
          </a>
        );
      },
    },
    {
      headerName: "Impuesto",
      field: "payment_tax",
      width: 90,
    },
    {
      headerName: "Peaje",
      field: "payment_toll",
      width: 90,
    },
    {
      headerName: "Precio C.",
      field: "payment_price_driver",
      width: 90,
    },
    {
      headerName: "TOTAL",
      field: "payment_price_total",
      width: 90,
    },
    {
      headerName: "Estado",
      field: "state_payment",
      width: 90,
    },
    {
      headerName: "Creado",
      field: "created_by",
      width: 80,
    },
    {
      headerName: "SERVICIO",
      field: "travel_status_id",
      width: 100,
      renderCell: ({ row: { travel_status_id } }) => {
        return (
          <a
            style={{
              color: "white",
              fontSize: 13,
              listStyle: "none",
              padding: "5px",
              textDecoration: "none",
              background: travel_status_id == 2 ? "blue" : "red",
            }}
          >
            {travelStatus[travel_status_id]}
          </a>
        );
      },
    },
    {
      headerName: "",
      field: "actions",
      width: 90,
      renderCell: ({ row: { id } }) => {
        return (
          <div>
            <Button
              color="secondary"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => {
                setUID(id);
                setAnchorEl(event.currentTarget);
              }}
            >
              OPCIONES
            </Button>
            <Menu
              id="menu-options"
              MenuListProps={{ "aria-labelledby": "fade-button" }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem
                onClick={() => {
                  handleOpenEditModal();
                  setBookingBusinessData();
                  handleClose();
                }}
              >
                VER
              </MenuItem>

              <MenuItem
                onClick={async () => {
                  const booking = {
                    id: UID,
                    discount: 0,
                    tax: 0,
                    toll: 0,
                    price_driver: 0,
                    price_total: 0,
                    commission: 0,
                    status: "PAGADO",
                  };
                  try {
                    const token = "";
                    await updateStatusBooking(token, booking);
                    snackBarDialog("PEDIDO PAGADO");
                    handleClose();
                  } catch (error) {
                    snackBarDialog("ERROR: NO SE PAGO EL PEDIDO", "error");
                    handleClose();
                    console.log(error);
                  }
                }}
              >
                PAGADO
              </MenuItem>

              <MenuItem
                onClick={async () => {
                  const booking = {
                    id: UID,
                    discount: 0,
                    tax: 0,
                    toll: 0,
                    price_driver: 0,
                    price_total: 0,
                    commission: 0,
                    status: "CREDITO",
                  };
                  try {
                    const token = "";
                    await updateStatusBooking(token, booking);
                    snackBarDialog("PEDIDO CREDITO");
                    handleClose();
                  } catch (error) {
                    snackBarDialog("ERROR: NO SE PAGO EL PEDIDO", "error");
                    handleClose();
                    console.log(error);
                  }
                }}
              >
                CREDITO
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  const booking = {
                    id: UID,
                    discount: 0,
                    tax: 0,
                    toll: 0,
                    price_driver: 0,
                    price_total: 0,
                    commission: 0,
                    status: "CANCELADO",
                  };
                  try {
                    const token = "";
                    await updateStatusBooking(token, booking);
                    snackBarDialog("PEDIDO CANCELADO");
                    handleClose();
                  } catch (error) {
                    snackBarDialog("ERROR: NO SE PAGO EL PEDIDO", "error");
                    handleClose();
                    console.log(error);
                  }
                }}
              >
                CANCELADO
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  try {
                    await updateBookingTravelStatus(UID, 3);
                    snackBarDialog("PEDIDO FINALIZADO");
                    handleClose();
                  } catch (error) {
                    snackBarDialog("ERROR: NO SE PAGO EL PEDIDO", "error");
                    handleClose();
                    console.log(error);
                  }
                }}
              >
                FINALIZADO
              </MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];

  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };
  const handleCloseSnk = () => setStateSnk(false);

  //MODAL
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectBooking, setSelectBooking] = useState(null);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);

  const [advanceList, setAdvanceList] = useState([]);

  const setBookingBusinessData = async () => {
    const order = booking.filter((book) => {
      if (book.id === UID) return true;
      return false;
    });

    if (order.length > 0) {
      setSelectBooking(order[0]);
      const resp = await getPaymentsList(order[0].order_number);
      console.log("advanceList", resp);
      setAdvanceList(resp.data.resp);
    }
    console.log(order);
  };

  const getPaymentsList = async (orderNumber) => {
    const resp = await getAdvanceCashOrder(orderNumber);
    return resp;
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #000",
    boxShadow: 20,
    width: 300,
    p: 2,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [igv, setIgv] = useState(0);

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 35,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  const [dataSelected, setDataSelected] = useState([]);
  const [tableData, setTableData] = useState(null);

  const getAdvanceCashItems = async (orderNumber) => {
    const resp = await getAdvanceCashOrder(orderNumber);
    return resp;
  };

  useEffect(() => {
    if (dataSelected.length > 0) {
      const promises = Object.values(dataSelected).map(async (book) => {
        const advanceList = await getAdvanceCashItems(book.order_number);
        let advance = 0;
        if (advanceList.data.resp.length > 0) {
          advanceList.data.resp.forEach((adv) => {
            advance += Number(adv.amount);
          });
        }

        const total = Number(book.payment_price_total);
        const advanceAmount = advance;
        const discountAmount = Number(book.payment_discount);

        return { total, advance: advanceAmount, discount: discountAmount };
      });

      Promise.all(promises).then((values) => {
        let total = 0;
        let advance = 0;
        let discount = 0;

        values.forEach(
          ({
            total: bookTotal,
            advance: bookAdvance,
            discount: bookDiscount,
          }) => {
            total += Number(bookTotal);
            advance += Number(bookAdvance);
            discount += Number(bookDiscount);
          }
        );

        setTableData({
          id: "5df3180a09ea16dc4b95f910",
          invoice_no: "201906-28",
          company: "TAXIMUNDO",
          email: "info@taximundo.com",
          phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
          address: "Servicios Generales Trans Mundo E.I.R.L.",
          items: dataSelected,
          igv: 0,
          total: total,
          discount,
          advance,
          peaje: 0,
          subTotal: 0,
          from: 0,
          to: 0,
        });
      });
    } else {
      setDataSelected([]);
      setTableData(null);
    }

    // let total = 0;
    // Object.values(dataSelected).map((book) => {
    //   total += Number(book.payment_price_total);
    // });
    // setTableData({});
    // setTableData({
    //   id: "5df3180a09ea16dc4b95f910",
    //   invoice_no: "201906-28",
    //   company: "TAXIMUNDO",
    //   email: "info@taximundo.com",
    //   phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
    //   address: "Servicios Generales Trans Mundo E.I.R.L.",
    //   items: dataSelected,
    //   igv: 0,
    //   total: total,
    //   peaje: 0,
    //   subTotal: 0,
    //   from: 0,
    //   to: 0,
    // });
  }, [dataSelected]);

  const listState = ["PENDIENTE", "PAGADO", "CANCELADO"];
  const [driverStatus, setDriverStatus] = useState("");
  return (
    <div>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="HISTORIAL DE PEDIDOS" subtitle="" />
              <Box width="200px">
                <PDFDownloadLink
                  document={
                    <Document>
                      {dataSelected.length > 0 && tableData != null && (
                        <Page size="A4" style={styles.page}>
                          <HeaderM />
                          {/* <Title title={"Taxi Mundo PERU"} /> */}
                          <Contact invoice={tableData} />
                          <DocumentName
                            title={"RESERVAS"}
                            from={"16/11/2023"}
                            to={"16/11/2023"}
                          />
                          <TableBooking invoice={tableData} />
                        </Page>
                      )}
                    </Document>
                  }
                  fileName={"detalles-cotizacion-taximundo.pdf"}
                >
                  <Box width={"100%"}>
                    <Button fullWidth variant="contained" color="success">
                      <PictureAsPdfIcon /> DESCARGAR
                    </Button>
                  </Box>
                </PDFDownloadLink>
                {dataSelected.length > 0 && tableData != null && (
                  <VoucherHistory data={tableData} />
                )}
              </Box>
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <Box
                  m="10px 0 0 0"
                  height="100vh"
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .name-column--cell": {
                      color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: colors.blueAccent[700],
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                      borderTop: "none",
                      backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                  }}
                >
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={booking}
                    columns={columns}
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    checkboxSelection
                    onSelectionModelChange={(ids) => {
                      setDataSelected([]);
                      ids.map((id) => {
                        const select = booking.find((row) => row.id === id);
                        setDataSelected((oldData) => [...oldData, select]);
                      });
                    }}
                  />
                </Box>
                {/* <TableBookNow type_booking={1} /> */}
              </Box>
            </Box>

            <Modal open={openEditModal} onClose={handleCloseEditModal}>
              <Box
                sx={{ ...styleModal, height: "95vh", overflow: "auto" }}
                backgroundColor={colors.primary[400]}
              >
                {selectBooking && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h5" component="h2">
                        PEDIDO :
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <TextField
                        value={selectBooking.order_number}
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="text"
                        label={"NRO PEDIDO"}
                        name="order_number"
                      />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="select-type">CONDUCTOR</InputLabel>
                        <Select
                          value={selectBooking.payment_type}
                          fullWidth
                          labelId="select-type"
                          id="payment_type"
                          onChange={(e) => {
                            selectBooking.payment_type = e.target.value;
                            setIgv(e.target.value);
                          }}
                          label="CONDUCTOR"
                          name="payment_type"
                        >
                          <MenuItem value={1}>EFECTIVO</MenuItem>
                          <MenuItem value={2}>CREDITO</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} md={6}>
                      <TextField
                        value={selectBooking.payment_commission}
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="number"
                        label={"COMISION"}
                        name="payment_commission"
                        onChange={(e) => {
                          selectBooking.payment_commission = e.target.value;
                          setIgv(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} md={6}>
                      <TextField
                        value={selectBooking.payment_tax}
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="number"
                        label={"IMPUESTO"}
                        name="payment_tax"
                        onChange={(e) => {
                          selectBooking.payment_tax = e.target.value;
                          setIgv(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} md={6}>
                      <TextField
                        value={selectBooking.payment_toll}
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="number"
                        label={"PEAJE"}
                        name="payment_toll"
                        onChange={(e) => {
                          selectBooking.payment_toll = e.target.value;
                          setIgv(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} md={6}>
                      <TextField
                        value={selectBooking.payment_price_driver}
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="number"
                        label={"PRECIO C."}
                        name="payment_price_driver"
                        onChange={(e) => {
                          selectBooking.payment_price_driver = e.target.value;
                          setIgv(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} md={6}>
                      <TextField
                        value={selectBooking.payment_price_total}
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="number"
                        label={"TOTAL"}
                        name="payment_price_total"
                        onChange={(e) => {
                          selectBooking.payment_price_total = e.target.value;
                          setIgv(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} sm={6} md={6}>
                      <TextField
                        value={advance}
                        name="advance"
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="number"
                        label="ADELANTO"
                        onChange={(e) => setAdvance(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <Checkbox
                              style={{ marginRight: -15 }}
                              checked={otherPay}
                              onChange={handleOtherPay}
                              color="success"
                            />
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Autocomplete
                        value={
                          driverStatus === ""
                            ? selectBooking.status
                            : driverStatus
                        }
                        disablePortal
                        id="combo-box-driver-status"
                        onChange={(e, newValue) => {
                          if (newValue != null) setDriverStatus(newValue);
                        }}
                        name="driverStatus"
                        options={listState}
                        getOptionLabel={(option) => `${option}`}
                        renderInput={(params) => (
                          <TextField {...params} label={"ESTADO"} />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {drivers && (
                        <Autocomplete
                          value={driverSelect}
                          disablePortal
                          id="combo-box-driver"
                          onChange={(e, newValue) => {
                            setDriverSelect(newValue);
                          }}
                          options={drivers}
                          getOptionLabel={(option) =>
                            `${option.name} ${option.lastname} ${option.brand} ${option.model} ${option.bodywork} ${option.plate} ${option.color}`
                          }
                          renderInput={(params) => (
                            <TextField {...params} label={t("driver")} />
                          )}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <TextField
                        value={selectBooking.notes}
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="text"
                        label={"NOTAS"}
                        name="notes"
                        onChange={(e) => {
                          selectBooking.notes = e.target.value;
                          setIgv(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <TextField
                        value={selectBooking.notes_admin}
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="text"
                        label={"NOTAS ADMIN"}
                        name="notesAdmin"
                        onChange={(e) => {
                          selectBooking.notes_admin = e.target.value;
                          setIgv(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <div>
                        {advanceList.map((pay, idx) => {
                          return (
                            <Fragment key={idx}>
                              <span style={{ fontWeight: "bold" }}>
                                {pay.created_by}
                              </span>
                              {", "}
                              <span style={{ fontWeight: "bold" }}>Pago: </span>
                              {pay.amount} {pay.currency},{" "}
                              <span style={{ fontWeight: "bold" }}>
                                Fecha:{" "}
                              </span>
                              {pay.operation_date}
                              {". "}
                            </Fragment>
                          );
                        })}
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        sx={{ height: 40 }}
                        disabled={isLoading}
                        onClick={async (e) => {
                          e.preventDefault();
                          try {
                            //UPDATE los nuevos datos
                            setIsLoading(true);
                            const book = {
                              id: selectBooking.id,
                              order_number: selectBooking.order_number,
                              payment_type_id: selectBooking.payment_type,
                              commission: selectBooking.payment_commission,
                              tax: selectBooking.payment_tax,
                              toll: selectBooking.payment_toll,
                              driver: selectBooking.payment_price_driver,
                              total: selectBooking.payment_price_total,
                              status:
                                driverStatus === ""
                                  ? selectBooking.status
                                  : driverStatus,
                              driver_id: driverSelect
                                ? driverSelect.driver_id
                                : selectBooking.drivers_id,
                              vehicle_id: driverSelect
                                ? driverSelect.vehicle_id
                                : selectBooking.vehicles_id,
                              notes: selectBooking.notes,
                            };

                            if (otherPay) {
                              const currencyList = {
                                1: "PEN",
                                2: "USD",
                              };
                              const advReg = {
                                orderNumber: selectBooking.order_number,
                                transactionId: `${
                                  selectBooking.order_number
                                }-${Date.now()}`,
                                currency:
                                  currencyList[selectBooking.payment_currency],
                                amount: advance,
                                operationDate: datePay,
                                status: "completed",
                              };
                              const resp = await createAdvanceCash(advReg);
                              console.log(resp.data);
                            }

                            await updatePaymentBookingNow("token", book);
                            snackBarDialog("DATOS ACTUALIZADOS");
                            handleCloseEditModal();
                            setIsLoading(false);
                          } catch (error) {
                            setIsLoading(false);
                            snackBarDialog(
                              "NO SE PUEDE ACTUALIZAR LOS DATOS",
                              "error"
                            );
                            console.log(error);
                            handleCloseEditModal();
                          }
                        }}
                      >
                        GUARDAR
                      </Button>
                    </Grid>

                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="error"
                        sx={{ height: 40 }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleCloseEditModal();
                        }}
                      >
                        CANCELAR
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Modal>

            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleCloseSnk}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </main>
      </div>
    </div>
  );
};

export default HistoryListBookingNow;
