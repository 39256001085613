import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  TextField,
  useTheme,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { Formik } from "formik";
import Header from "../../../../components/header/Header";
import Topbar from "../../../../components/topbar/TopBar";
import * as yup from "yup";
import { tokens } from "../../../../theme";
import SnackBar from "../../../../components/snackbar/SnackBar";
import {
  getGlobalConfig,
  putGlobalConfig,
  putOneComission,
  putOnlyPremium,
} from "../../../../api/resources/global/globalConfiguration";
import {
  getMenuToken,
  setMenuToken,
} from "../../../../helpers/token/menuToken";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

import { db } from "../../../../firebase";
import { ref, onValue, remove, update } from "firebase/database";
import { getDrivers } from "../../../../api/users/driver/driver";
import { getTokenRefresh } from "../../../../helpers/token/refreshToken";
import {
  getRates,
  updateRate,
} from "../../../../api/users/enterprise/enterpriseApi";

const GlobalConfiguration = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const [config, setConfig] = useState();
  const [menuSelect, setMenuSelect] = useState("");
  const checkoutSchema = yup.object().shape({
    percentage_commission: yup.number().required("required"),
    fixed_commission: yup.number().required("required"),
    igv: yup.number().required("required"),
  });

  const checkoutPriceSchema = yup.object().shape({});

  const dataMenu = getMenuToken();
  useEffect(() => {
    if (dataMenu) {
      setMenuSelect(dataMenu);
    } else {
      setMenuToken("drawer");
      setMenuSelect("drawer");
    }
  }, []);

  useEffect(() => {
    const getConfig = async () => {
      try {
        const response = await getGlobalConfig(1);
        setConfig(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    getConfig();
  }, []);

  const [dataPrice, setDataPrice] = useState([]);
  const [dataP, setDataP] = useState([]);
  useEffect(() => {
    const starCountRef = ref(db, "BusinessRate/Price/Arequipa/Rider");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      const names = Object.keys(data);
      //console.log(data);
      //console.log(names);

      if (data !== null) {
        setDataPrice(data);
        setDataP([]);
        // for (const city in data) {
        //   const enterprises = data[city];
        //   for (const enterprise in enterprises) {
        //     const enterpriseData = enterprises[enterprise];
        for (const sub in data) {
          const subEnterprise = data[sub];
          const newprice = {
            city: "Arequipa",
            user: "Rider",
            userName: sub,
            userData: subEnterprise,
          };
          setDataP((oldata) => [...oldata, newprice]);
        }
        //}
        //}
      }
    });
  }, []);

  useEffect(() => {
    ratesData();
  }, []);

  const [rateList, setRateList] = useState([]);
  const ratesData = async () => {
    try {
      const response = await getRates(1);
      setRateList([]);
      setRateList(response?.data?.rateDefault);
      console.log("rates", response?.data.rateDefault);
    } catch (error) {
      console.log(error);
    }
  };

  const typeService = {
    1: "GLOBAL",
    2: "SEDAN",
    3: "SUV",
    4: "MINIVAN",
    5: "MINIVAN_15",
    6: "MINIVAN_19",
  };

  useEffect(() => {});

  useEffect(() => {
    if (dataP !== null) {
      console.log(dataP);
    }
  }, [dataP]);

  const handlePricesFormSubmit = async (values) => {
    console.log(values);
    try {
      await update(ref(db, `ListPrices/`), values);
      snackBarDialog("CONFIGURACION ACTUALIZADA");
    } catch (error) {
      snackBarDialog("ERROR DE ACTUALIZACION", "error");
      console.log(error);
    }
  };

  const vertical = "top";
  const horizontal = "center";
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleChangeBooking = (event) => setMenuSelect(event.target.value);

  const handleElementSubmit = async (values) => {
    console.log(values);
    console.log(values.userData);
    try {
      const dbRef = `BusinessRate/Price/${values.city}/${values.user}/${values.userName}`;
      await update(ref(db, dbRef), values.userData);
      snackBarDialog("PRECIOS DE CLIENTE ACTUALIZADOS");
    } catch (error) {
      snackBarDialog("ERROR DE ACTUALIZACION", "error");
      console.log(error);
    }
  };

  const users = {
    1: "PREMIUM",
    2: "NORMAL",
    3: "INDIVIDUAL",
    4: "TODOS",
  };
  const [selectGroup, setSelectGroup] = useState(1);
  const [driver, setDriver] = useState(null);
  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    listDrivers();
  }, []);

  const listDrivers = async () => {
    try {
      const token = getTokenRefresh();
      if (token) {
        const response = await getDrivers(token);
        setDrivers([]);
        let count = 0;

        Object.values(response.data).map((driver) => {
          if (driver.status !== 3 && driver.approved) {
            let plates = "";
            let bodyworks = "";
            Object.values(driver.vehicle).map((v) => {
              plates = plates + v.vehicle_plate + ", ";
              bodyworks = bodyworks + v.vehicle_bodywork + ", ";
            });
            driver.plates = plates;
            driver.bodyworks = bodyworks;
            driver.index = ++count;
            setDrivers((olData) => [...olData, driver]);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSubmit = async (values) => {
    try {
      const token = "";
      if (selectGroup == 1) {
        const config = {
          percentage_commission: values.percentage_commission,
          fixed_commission: values.fixed_commission,
          premium: true,
        };
        await putOnlyPremium(token, config);
        //solo premium
      } else if (selectGroup == 2) {
        //solo a normales
        const config = {
          percentage_commission: values.percentage_commission,
          fixed_commission: values.fixed_commission,
          premium: false,
        };
        await putOnlyPremium(token, config);
      } else if (selectGroup == 3) {
        //individual
        const config = {
          percentage_commission: values.percentage_commission,
          fixed_commission: values.fixed_commission,
          commissions_id: driver.commissions_id,
        };
        await putOneComission(token, config);
      } else {
        //todos
        const config = {
          id: values.id,
          percentage_commission: values.percentage_commission,
          fixed_commission: values.fixed_commission,
          igv: values.igv,
        };
        await putGlobalConfig(token, config);
      }
      snackBarDialog("CONFIGURACION ACTUALIZADA");
    } catch (error) {
      snackBarDialog("ERROR DE ACTUALIZACION", "error");
      console.log(error);
    }
  };

  const updateRateGlobalSubmit = async (values) => {
    try {
      console.log(values);
      const response = await updateRate(values);
      console.log(response);
    } catch (error) {
      snackBarDialog("ERROR DE ACTUALIZACION", "error");
      console.log(error);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="Configuraciones" subtitle="" />
            </Box>

            <Grid container spacing={1} textAlign={"center"}>
              <Grid item xs={12} sm={4} md={4}>
                {config ? (
                  <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={config}
                    validationSchema={checkoutSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Box backgroundColor={colors.primary[400]} p={1}>
                          <Grid container spacing={1} textAlign={"center"}>
                            <Grid item xs={12} sm={12}>
                              <FormControl fullWidth>
                                <InputLabel id="commission-select-label">
                                  PARA
                                </InputLabel>
                                <Select
                                  labelId="commission-select-label"
                                  id="demo-simple-select"
                                  value={selectGroup}
                                  label="PARA"
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setSelectGroup(e.target.value);
                                  }}
                                >
                                  <MenuItem value={1}>{users[1]}</MenuItem>
                                  <MenuItem value={2}>{users[2]}</MenuItem>
                                  <MenuItem value={3}>{users[3]}</MenuItem>
                                  <MenuItem value={4}>{users[4]}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            {selectGroup != 3 ? (
                              <></>
                            ) : (
                              <Grid item xs={12} sm={12}>
                                {drivers && (
                                  <Autocomplete
                                    value={driver}
                                    disablePortal
                                    id="select-driver"
                                    onChange={(e, newValue) => {
                                      setDriver(newValue);
                                    }}
                                    options={drivers}
                                    getOptionLabel={(option) =>
                                      `${option.name} ${option.lastname} - ${option.dni} - ${option.phone}`
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={"CONDUCTOR"}
                                      />
                                    )}
                                  />
                                )}
                              </Grid>
                            )}

                            <Grid item xs={12} sm={12}>
                              <TextField
                                value={values.percentage_commission}
                                variant="outlined"
                                fullWidth
                                type="number"
                                label={"COMISION PORCENTUAL"}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                color="success"
                                name="percentage_commission"
                                error={!!touched.brand && !!errors.brand}
                                helperText={touched.brand && errors.brand}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                value={values.fixed_commission}
                                variant="outlined"
                                color="success"
                                type="number"
                                fullWidth
                                label="COMISION FIJA"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="fixed_commission"
                                error={!!touched.model && !!errors.model}
                                helperText={touched.model && errors.model}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                value={values.igv}
                                variant="outlined"
                                color="success"
                                type="number"
                                fullWidth
                                label="IGV"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="igv"
                                error={!!touched.bodywork && !!errors.bodywork}
                                helperText={touched.bodywork && errors.bodywork}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                                fullWidth
                                sx={{ height: 40 }}
                              >
                                ACTUALIZAR
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        <SnackBar
                          stateSnk={stateSnk}
                          vertical={vertical}
                          horizontal={horizontal}
                          duration={4000}
                          handleClose={handleSnkClose}
                          severity={severity}
                          messageSnk={messageSnk}
                        />
                      </form>
                    )}
                  </Formik>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Box backgroundColor={colors.primary[400]} p={1}>
                  <Grid container spacing={1} textAlign={"center"}>
                    <Grid item xs={12} sm={12} md={12} textAlign="center">
                      <FormControl>
                        <FormLabel id="row-radio-buttons">MENU:</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="row-radio-buttons"
                          value={menuSelect}
                          onChange={handleChangeBooking}
                        >
                          <FormControlLabel
                            value={"drawer"}
                            control={<Radio color="success" />}
                            label="DRAWER"
                          />
                          <FormControlLabel
                            value={"sidebar"}
                            control={<Radio color="success" />}
                            label="SIDEBAR"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        fullWidth
                        sx={{ height: 40 }}
                        onClick={(e) => {
                          e.preventDefault();
                          setMenuToken(menuSelect);
                        }}
                      >
                        ACTUALIZAR
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {/* DATA PRICE */}
              <Grid item xs={12} sm={4} md={4}>
                {dataP ? (
                  dataP.map((city, index) => (
                    <Grid key={index} item xs={12} sm={12}>
                      <Typography variant="h4">
                        {city.city} / {city.user}
                      </Typography>
                      <Formik
                        key={index}
                        onSubmit={handleElementSubmit}
                        initialValues={city}
                        validationSchema={checkoutPriceSchema}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Box
                              backgroundColor={colors.primary[400]}
                              padding={1}
                            >
                              <Grid container spacing={1} textAlign={"center"}>
                                <Grid item xs={12} sm={12}>
                                  <Typography variant="h4">
                                    {"AREQUIPA"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.userData.priceMinimal}
                                    variant="outlined"
                                    fullWidth
                                    color="success"
                                    type="number"
                                    label="PRECIO MINIMO/BASE"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="userData.priceMinimal"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.userData.distanceMinimal}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    label={"DISTANCIA MINIMA (metros)"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    color="success"
                                    name="userData.distanceMinimal"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.userData.pricePerMinute}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    label={"PRECIO POR MINUTO"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    color="success"
                                    name="userData.pricePerMinute"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.userData.pricePerHour}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    label={"PRECIO POR HORA"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    color="success"
                                    name="userData.pricePerHour"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.userData.pricePerKM}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    label={"PRECIO POR KILOMETRO"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    color="success"
                                    name="userData.pricePerKM"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.userData.priceNight}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    label={"PRECIO NOCHE"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    color="success"
                                    name="userData.priceNight"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.userData.priceFestiveDate}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    label={"PRECIO FERIADOS"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    color="success"
                                    name="userData.priceFestiveDate"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.userData.priceRushTime}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    label={"PRECIO HORAS PUNTA"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    color="success"
                                    name="userData.priceRushTime"
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <Button
                                    type="submit"
                                    fullWidth
                                    color="secondary"
                                    variant="contained"
                                    sx={{ height: 45 }}
                                  >
                                    ACTUALIZAR
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                            <SnackBar
                              stateSnk={stateSnk}
                              vertical={vertical}
                              horizontal={horizontal}
                              duration={4000}
                              handleClose={handleSnkClose}
                              severity={severity}
                              messageSnk={messageSnk}
                            />
                          </form>
                        )}
                      </Formik>
                    </Grid>
                  ))
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                {rateList ? (
                  rateList.map((city, index) => (
                    <Grid key={index} item xs={12} sm={12}>
                      <Typography variant="h4"></Typography>
                      <Formik
                        key={index}
                        onSubmit={updateRateGlobalSubmit}
                        initialValues={city}
                        validationSchema={checkoutPriceSchema}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Box
                              backgroundColor={colors.primary[400]}
                              padding={1}
                            >
                              <Grid container spacing={1} textAlign={"center"}>
                                <Grid item xs={12} sm={12}>
                                  <Typography variant="h4">
                                    {typeService[values.rate_type_id]}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.price_minimal}
                                    variant="outlined"
                                    fullWidth
                                    color="success"
                                    type="number"
                                    label="PRECIO MINIMO/BASE"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_minimal"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.price_distance_minimal}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    label={"DISTANCIA MINIMA (metros)"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    color="success"
                                    name="price_distance_minimal"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.price_per_minute}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    label={"PRECIO POR MINUTO"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    color="success"
                                    name="price_per_minute"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.price_per_hour}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    label={"PRECIO POR HORA"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    color="success"
                                    name="price_per_hour"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.price_per_km}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    label={"PRECIO POR KILOMETRO"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    color="success"
                                    name="price_per_km"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.price_night}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    label={"PRECIO NOCHE"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    color="success"
                                    name="price_night"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.price_festive_date}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    label={"PRECIO FERIADOS"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    color="success"
                                    name="price_festive_date"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    value={values.price_rush_time}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    label={"PRECIO HORAS PUNTA"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    color="success"
                                    name="price_rush_time"
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <Button
                                    type="submit"
                                    fullWidth
                                    color="secondary"
                                    variant="contained"
                                    sx={{ height: 45 }}
                                  >
                                    ACTUALIZAR
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                            <SnackBar
                              stateSnk={stateSnk}
                              vertical={vertical}
                              horizontal={horizontal}
                              duration={4000}
                              handleClose={handleSnkClose}
                              severity={severity}
                              messageSnk={messageSnk}
                            />
                          </form>
                        )}
                      </Formik>
                    </Grid>
                  ))
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>

            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleSnkClose}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </main>
      </div>
    </>
  );
};

export default GlobalConfiguration;
