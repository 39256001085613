import { updateTour } from "../../../data/repository/tour.repo";

export const UpdateTourUseCase = async (tour) => {
  try {
    const response = await updateTour(tour);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
