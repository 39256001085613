import { createBindQuestion } from "../../../data/repository/question.repo";

export const CreateBindQuestionUseCase = async (
  idQuest,
  typeList,
  tourList
) => {
  try {
    const resp = await createBindQuestion(idQuest, typeList, tourList);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
