export const addressSchema = (yup) => {
  return {
    url: yup.string(),
    name: yup.string().required("required"),
    latitude: yup.string(),
  };
};

export const addressInitial = {
  url: "",
  name: "",
  latitude: "",
};
