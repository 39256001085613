import React, { useState, useEffect, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { db } from "../../../../firebase";
import { ref, onValue, update, remove, get } from "firebase/database";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
  useTheme,
  Stack,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Formik } from "formik";
import * as yup from "yup";

import { getDriverAndVehicle } from "../../../../api/users/driver/driver";
import {
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../../helpers/token/refreshToken";
import SnackBar from "../../../../components/snackbar/SnackBar";
import {
  createBookingNow,
  updateDataBookingNow,
} from "../../../../api/booking/bookingNow";
import { tokens } from "../../../../theme";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { suggestionBackgroundColor } from "../booking/style/BookingStyle";
import { sendNotificationToken } from "../../../../api/fcm/notification";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { formatterTimePicker } from "../../../../helpers/date/formatterTimePicker";
import { dateFormat, timeFormat } from "../../../../helpers/date/date";
import { UpdateOrderBackup } from "../backup/usecases/UpdateOrderBackup";
import {
  createAdvanceCash,
  getAdvanceCashOrder,
} from "../../../../api/booking/advance";

const FormBookingLater = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id, uuid } = useParams();
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [driver, setDriver] = useState(null);
  const { t } = useTranslation();
  const [discount, setDiscount] = useState(0);
  const [advance, setAdvance] = useState(0);
  const [noteAdmin, setNoteAdmin] = useState("");
  const [datePay, setDatePay] = useState("");

  const [commissionOption, setCommissionOption] = useState(true);
  const [commissionValue, setCommissionValue] = useState(0);

  const [activePhone, setActivePhone] = useState(true);
  const handleActivePhone = (event) => setActivePhone(event.target.checked);

  const [otherPay, setOtherPay] = useState(false);
  const handleOtherPay = (e) => {
    const isChecked = e.target.checked;
    setOtherPay(isChecked);
    if (isChecked) {
      const currentDate = new Date();
      setDatePay(`${dateFormat(currentDate)} ${timeFormat(currentDate)}`);
    } else {
      setDatePay("");
    }
  };

  useEffect(() => {
    const starCountRef = ref(db, `bookingNow/${uuid}`);
    onValue(starCountRef, (snapshot) => {
      let data = snapshot.val();
      if (data === null) return console.log("No Booking");

      setAddressOrigin(data.origin.name);
      setCoordinatesOrigin({ lat: data.origin.lat, lng: data.origin.lng });
      setAddressDestination(data.destination.name);
      setCoordinatesDestination({
        lat: data.destination.lat,
        lng: data.destination.lng,
      });
      setBooking(data);
    });
  }, []);

  const [dateformatter, setDateFormat] = useState("");
  const [timeFormatter, setTimeFormat] = useState("");
  const [listPayments, setListPayments] = useState([]);
  useEffect(() => {
    if (booking) {
      if (booking.driver) {
        const driver = {
          brand: booking.driver.brand,
          color: booking.driver.color,
          driver_id: booking.driver.driver_id,
          lastname: booking.driver.lastname,
          model: booking.driver.model,
          name: booking.driver.name,
          bodywork: booking.driver.bodywork,
          plate: booking.driver.plate,
          vehicle_id: booking.driver.vehicle_id,
          percentageValue: booking.driver.percentageValue,
        };
        setCommissionValue(driver.percentageValue);
        setDriver(driver);
      }
      setActivePhone(booking.phoneActive ? booking.phoneActive : true);
      setOtherPay(booking.otherPay ? booking.otherPay : false);
      setDateFormat(dayjs(booking.date, "DD/MM/YYYY").toDate());
      setTimeFormat(formatterTimePicker(booking.date, booking.time));
      setDiscount(booking.discount ? booking.discount : 0);
      setAdvance(booking.advance ? booking.advance : 0);
      setNoteAdmin(booking.noteAdmin ? booking.noteAdmin : "");
      const change =
        booking?.openpay?.operation_date != undefined &&
        booking?.openpay?.operation_date != "";
      setDatePay(change ? booking.openpay.operation_date : "");
      //traer los avances de firebase
      const getPaymentsMysql = async () => {
        try {
          const resp = await getPaymentsList(booking.counterBooking);
          console.log(resp.data);
          setListPayments(resp.data.resp);
        } catch (error) {
          console.log(error);
        }
      };
      getPaymentsMysql();
    }
  }, [booking]);

  const getPaymentsList = async (orderNumber) => {
    const resp = await getAdvanceCashOrder(orderNumber);
    return resp;
  };

  useEffect(() => {
    const driversData = async () => {
      const verify = verifyExpireTokenRefresh();
      if (verify) return console.log("no token");

      const token = getTokenRefresh();
      try {
        const response = await getDriverAndVehicle(token);
        console.log(response.data);
        setDrivers(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    driversData();
  }, []);

  const checkoutSchema = yup.object().shape({});

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    values.driver = driver;
    values.statusService = driver ? "ASIGNADO" : "PENDIENTE";
    values.phoneActive = activePhone;
    try {
      const urlRef = `bookingNow/${values.uuid}`;

      values.origin.name = addressOrigin;
      values.origin.lat = coordinatesOrigin.lat;
      values.origin.lng = coordinatesOrigin.lng;
      values.destination.name = addressDestination;
      values.destination.lat = coordinatesDestination.lat;
      values.destination.lng = coordinatesDestination.lng;
      values.date = values.date.$d ? dateFormat(values.date.$d) : values.date;
      values.time = values.time.$d ? timeFormat(values.time.$d) : values.time;
      values.advance = otherPay ? 0 : advance;
      values.discount = discount;
      values.noteAdmin = noteAdmin;

      const openpay = {
        createBy: "A",
        status: "pending",
        btnPay: true,
        currency: "",
        transaction_id: "",
        order_id: "",
        operation_date: "",
        url: "",
      };
      values.openpay = openpay;

      if (driver) {
        const token = "asdf";
        if (!commissionOption) values.commission = commissionValue;
        else values.commission = values.driver.percentageValue;

        if (!values.isCreated) {
          values.isCreated = true;
          values.statusBooking = "PAGADO";
          values.assigned = true;

          await createBookingNow(token, values);

          const dbRef = ref(db, urlRef);
          await remove(dbRef);

          const dbRefToken = ref(db, `Token/Driver/${driver.driver_id}`);
          get(dbRefToken)
            .then(async (snapshot) => {
              const data = snapshot.val();
              if (data != null) {
                if (data.isBooking && data.isPremium) {
                  const token = data.token;
                  const title = "TIENES RESERVA ASIGNADA CLIENTE";
                  const body = "Ingrese a Reservas para ver los detalles";
                  await sendNotificationToken(token, title, body);
                }
              }
            })
            .catch((error) => {
              console.error(
                "PEDIDO ASIGNADO, PERO NO SE LOGRO ENVIAR NOTIFICACION AL CONDUCTOR",
                error
              );
            });
        } else await updateDataBookingNow(token, values);

        setTimeout(() => {
          navigate("/admin/list-book-later");
        }, 5000);
      } else {
        const backup = {
          id: values.counterBooking,
          originName: addressOrigin,
          originLat: coordinatesOrigin.lat,
          originLng: coordinatesOrigin.lng,
          destinationName: addressDestination,
          destinationLat: coordinatesDestination.lat,
          destinationLng: coordinatesDestination.lng,
          date: values.date.$d ? dateFormat(values.date.$d) : values.date,
          time: values.time.$d ? timeFormat(values.time.$d) : values.time,
          persons: values.persons,
          advance: advance,
          tax: values.tax,
          toll: values.toll,
          price: values.price,
          total: values.total,
          discount: discount,
          note: values.note,
          noteAdmin: noteAdmin,
          currency: values.currency,
          paymentType: values.payment_type,
          otherPay: otherPay,
          operationDate: datePay,
        };

        await update(ref(db, urlRef), values); //actualiza firebase

        if (otherPay) {
          const currencyList = {
            1: "PEN",
            2: "USD",
          };
          const advReg = {
            orderNumber: values.counterBooking,
            transactionId: `${values.counterBooking}-${Date.now()}`,
            currency: currencyList[values.currency],
            amount: advance,
            operationDate: datePay,
            status: "completed",
            createdBy: "ADMIN",
          };
          const resp = await createAdvanceCash(advReg);
          console.log(resp.data);
        }

        const putBackup = await UpdateOrderBackup(backup); //actualiza mysql backup

        if (putBackup.message === "success") {
          snackBarDialog("BACKUP ACTUALIZADO");
          setTimeout(() => {
            navigate("/admin/list-book-later");
          }, 5000);
        } else {
          snackBarDialog("Ha ocurrido un error al actualizar el backup");
        }
      }

      setIsLoading(false);
      // snackBarDialog("Pedido Actualizado");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      snackBarDialog("Error: No Actualizado", "error");
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleMouseDownPassword = (e) => e.preventDefault();
  //select origin
  const [addressOrigin, setAddressOrigin] = useState("");
  const [addressDestination, setAddressDestination] = useState("");
  const [coordinatesOrigin, setCoordinatesOrigin] = useState({
    lat: null,
    lng: null,
  });
  const [coordinatesDestination, setCoordinatesDestination] = useState({
    lat: null,
    lng: null,
  });
  const handSelectOrigin = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setAddressOrigin(value);
    setCoordinatesOrigin(ll);
  };

  const handSelectDestination = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setAddressDestination(value);
    setCoordinatesDestination(ll);
  };

  return (
    <div>
      {booking && (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={booking}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box backgroundColor={colors.primary[400]} p={1}>
                <Grid container spacing={1} p={1}>
                  <Grid item xs={12} sm={6}>
                    <PlacesAutocomplete
                      value={addressOrigin}
                      onChange={setAddressOrigin}
                      onSelect={handSelectOrigin}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div key={suggestions.description}>
                          <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              onChange={handleChange("weight")}
                              aria-describedby="outlined-weight-helper-text"
                              {...getInputProps({
                                placeholder: "Origen",
                                className: "location-search-input",
                              })}
                            />
                          </FormControl>
                          <Box
                            key={suggestions.description}
                            className="autocomplete-dropdown-container suggestions"
                          >
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              const style = suggestion.active
                                ? suggestionBackgroundColor(colors.primary[400])
                                : suggestionBackgroundColor(
                                    colors.primary[400]
                                  );
                              return (
                                <div
                                  key={suggestion.description}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </Box>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PlacesAutocomplete
                      value={addressDestination}
                      onChange={setAddressDestination}
                      onSelect={handSelectDestination}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div key={suggestions.description}>
                          <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              onChange={handleChange("weight")}
                              aria-describedby="outlined-weight-helper-text"
                              {...getInputProps({
                                placeholder: "Destino",
                                className: "location-search-input",
                              })}
                            />
                          </FormControl>
                          <Box
                            key={suggestions.description}
                            className="autocomplete-dropdown-container suggestions"
                          >
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              const style = suggestion.active
                                ? suggestionBackgroundColor(colors.primary[400])
                                : suggestionBackgroundColor(
                                    colors.primary[400]
                                  );
                              return (
                                <div
                                  key={suggestion.description}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </Box>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={values.clientName}
                      fullWidth
                      variant="outlined"
                      color="success"
                      type="text"
                      label={t("client")}
                      name="client"
                      InputProps={{
                        endAdornment: (
                          <Checkbox
                            style={{ marginRight: -15 }}
                            checked={activePhone}
                            onChange={handleActivePhone}
                            color="success"
                          />
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={3} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={2}>
                            <MobileDatePicker
                              label={t("date")}
                              inputFormat="DD/MM/YYYY"
                              name="date"
                              value={dateformatter}
                              onChange={(newValue) => {
                                setDateFormat(newValue);
                                values.date = newValue;
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <MobileTimePicker
                              label={t("time")}
                              value={timeFormatter}
                              name="time"
                              onChange={(newValue) => {
                                setTimeFormat(newValue);
                                values.time = newValue;
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <TextField
                          value={values.persons}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="text"
                          label={t("persons")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="persons"
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <FormControl fullWidth>
                          <InputLabel>{t("Model")}</InputLabel>
                          <Select
                            value={values.vehicle}
                            id="vehicle"
                            name="vehicle"
                            label={t("vehicle")}
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>{t("SEDAN")}</MenuItem>
                            <MenuItem value={2}>{t("SUV")}</MenuItem>
                            <MenuItem value={3}>{t("MINIVAN")}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={values.note}
                      fullWidth
                      variant="outlined"
                      color="success"
                      type="text"
                      label={t("notes")}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="note"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {drivers && (
                      <Autocomplete
                        value={driver}
                        disablePortal
                        id="combo-box-driver"
                        onChange={(e, newValue) => {
                          setDriver(newValue);
                        }}
                        options={drivers}
                        getOptionLabel={(option) =>
                          `${option.name} ${option.lastname} ${option.brand} ${option.model} ${option.bodywork} ${option.plate} ${option.color}`
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={t("driver")} />
                        )}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={3} md={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={12} md={12}>
                        <FormControl fullWidth>
                          <InputLabel>{t("Tipo Pago")}</InputLabel>
                          <Select
                            value={values.payment_type}
                            id="payment_type"
                            name="payment_type"
                            label={t("payment")}
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>{t("EFECTIVO")}</MenuItem>
                            <MenuItem value={2}>{t("CREDITO")}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={12} md={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">
                            COMISION
                          </InputLabel>
                          <OutlinedInput
                            value={commissionValue}
                            name="commissionValue"
                            onChange={(e) => {
                              setCommissionValue(e.target.value);
                            }}
                            disabled={commissionOption}
                            id="control-dni"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  onClick={(e) => {
                                    setCommissionOption(!commissionOption);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="COMISION"
                            aria-describedby="outlined-helper-text"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={12} md={12}>
                        <FormControl fullWidth>
                          <InputLabel>{t("currency")}</InputLabel>
                          <Select
                            value={values.currency}
                            id="currency"
                            name="currency"
                            label={t("currency")}
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>{t("PEN")}</MenuItem>
                            <MenuItem value={2}>{t("USD")}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} sm={6} md={6}>
                        <TextField
                          value={discount}
                          name="discount"
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="number"
                          label="DESCUENTO"
                          onChange={(e) => setDiscount(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3} sm={6} md={6}>
                        <TextField
                          value={advance}
                          name="advance"
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="number"
                          label="ADELANTO"
                          onChange={(e) => setAdvance(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <Checkbox
                                style={{ marginRight: -15 }}
                                checked={otherPay}
                                onChange={handleOtherPay}
                                color="success"
                              />
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={12} md={12}>
                        <TextField
                          value={values.tax}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="number"
                          label={t("IMPUESTO")}
                          onChange={handleChange}
                          name="tax"
                        />
                      </Grid>
                      <Grid item xs={6} sm={12} md={12}>
                        <TextField
                          value={values.toll}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="number"
                          label={t("PEAJE")}
                          onChange={handleChange}
                          name="toll"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={12} md={12}>
                        <TextField
                          value={values.price}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="number"
                          label={t("P CONDUCTOR")}
                          onChange={handleChange}
                          name="price"
                        />
                      </Grid>
                      <Grid item xs={6} sm={12} md={12}>
                        <TextField
                          value={values.total}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="number"
                          label={t("TOTAL")}
                          onChange={handleChange}
                          name="total"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <div>
                      {listPayments.map((pay, idx) => {
                        return (
                          <Fragment key={idx}>
                            <span style={{ fontWeight: "bold" }}>
                              {pay.created_by}
                            </span>
                            {", "}
                            <span style={{ fontWeight: "bold" }}>Pago: </span>
                            {pay.amount} {pay.currency},{" "}
                            <span style={{ fontWeight: "bold" }}>Fecha: </span>
                            {pay.operation_date}
                            {". "}
                          </Fragment>
                        );
                      })}
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <TextField
                      value={noteAdmin}
                      fullWidth
                      variant="outlined"
                      color="success"
                      type="text"
                      label="Notas del Admin"
                      onChange={(e) => setNoteAdmin(e.target.value)}
                      name="noteAdmin"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <TextField
                      value={datePay}
                      fullWidth
                      variant="outlined"
                      color="success"
                      type="text"
                      label="Fecha de Pago"
                      disabled
                      name="datePay"
                      InputProps={{
                        endAdornment: <p>{values?.openpay?.createBy}</p>,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} md={3}>
                    <Button
                      fullWidth
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{ height: "50px" }}
                      disabled={isLoading}
                    >
                      {"GUARDAR"}
                    </Button>
                  </Grid>
                </Grid>
                <SnackBar
                  stateSnk={stateSnk}
                  vertical={vertical}
                  horizontal={horizontal}
                  duration={4000}
                  handleClose={handleSnkClose}
                  severity={severity}
                  messageSnk={messageSnk}
                />
              </Box>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default FormBookingLater;
