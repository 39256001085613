import React from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import firma from "../../../../assets/contratofirma.png";

const borderColor = "#000000";
const styles = StyleSheet.create({
  container: {
    width: "100%",
    marginTop: 15,
    alignContent: "flex-end",
    marginHorizontal: "auto",
  },
  textTitle: {
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    fontSize: 10,
  },
  containerBox: {
    width: "100%",
    flexDirection: "column",
  },

  containerHeader: {
    flexDirection: "row",
    margin: 0,
    padding: 0,
    border: borderColor,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "black",
    fontFamily: "Helvetica-Bold",
    fontSize: 7,
  },
  containerTextRow: {
    flexDirection: "row",
    margin: 0,
    padding: 0,
    border: borderColor,
    borderWidth: 1,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "black",
    fontFamily: "Helvetica",
    fontSize: 7,
  },
  rowOne: {
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingTop: 4,
  },
  rowTwo: {
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingTop: 4,
  },
  rowThree: {
    textAlign: "center",
    paddingTop: 4,
  },
  rowFour: {
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingTop: 4,
    paddingLeft: 2,
  },
  rowFive: {
    textAlign: "center",
    paddingTop: 4,
  },

  containerSignature: {
    width: "40%",
    flexDirection: "column",
    marginLeft: "auto",
    marginTop: 20,
  },
  signatureHeader: {
    flexDirection: "colum",
    margin: 0,
    padding: 0,
    border: borderColor,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "black",
    fontFamily: "Helvetica-Bold",
    fontSize: 7,
  },
  signatureText: {
    textAlign: "center",
    paddingTop: 4,
  },
  logo: {
    width: 130,
    height: 60,
  },
});

const Body = ({ data }) => {
  return (
    <View style={styles.container}>
      <View style={styles.containerBox}>
        <Text style={styles.textTitle}>1. DATOS DE LA PERSONA JURIDICA</Text>
        {/* primero */}
        <View style={styles.containerHeader}>
          <Text style={{ ...styles.rowOne, width: "15%" }}>CÓDIGO</Text>
          <Text style={{ ...styles.rowTwo, width: "70%" }}>RAZON SOCIAL</Text>
          <Text style={{ ...styles.rowThree, width: "15%" }}>RUC</Text>
        </View>
        <View style={styles.containerTextRow}>
          <Text style={{ ...styles.rowOne, width: "15%", height: 15 }}>
            E-265
          </Text>
          <Text style={{ ...styles.rowTwo, width: "70%", height: 15 }}>
            SERVICIOS GENERALES TRANS MUNDO E.I.R.L.
          </Text>
          <Text style={{ ...styles.rowThree, width: "15%", height: 15 }}>
            20455135134
          </Text>
        </View>
        {/* segundo */}
        <View style={{ ...styles.containerHeader, borderTopWidth: 0 }}>
          <Text style={{ ...styles.rowOne, width: "50%" }}>
            DOMICILIO LEGAL DE LA PERSONA JURIDICA
          </Text>
          <Text style={{ ...styles.rowTwo, width: "35%" }}>
            CORREO ELECTRONICO
          </Text>
          <Text style={{ ...styles.rowThree, width: "15%" }}>TELEFONO</Text>
        </View>
        <View style={styles.containerTextRow}>
          <Text style={{ ...styles.rowOne, width: "50%", height: 15 }}>
            CALLE ABRAHAM VALDELOMAR 126 ALTO SELVA ALEGRE
          </Text>
          <Text style={{ ...styles.rowTwo, width: "35%", height: 15 }}>
            info@taximundo.com
          </Text>
          <Text style={{ ...styles.rowThree, width: "15%", height: 15 }}>
            054 200900
          </Text>
        </View>
        {/* tercero */}
        <View style={{ ...styles.containerHeader, borderTopWidth: 0 }}>
          <Text style={{ ...styles.rowOne, width: "46%" }}>
            NOMBRE DEL REPRESENTANTE LEGAL DE LA PERSONA JURIDICA
          </Text>
          <Text style={{ ...styles.rowTwo, width: "39%" }}>
            DOMICILIO DEL REPRESENTANTE LEGAL
          </Text>
          <Text style={{ ...styles.rowThree, width: "15%" }}>CELULAR</Text>
        </View>
        <View style={styles.containerTextRow}>
          <Text style={{ ...styles.rowOne, width: "46%", height: 15 }}>
            JULIO LAURA CHAMBILLA
          </Text>
          <Text style={{ ...styles.rowTwo, width: "39%", height: 15 }}>
            CALLE ABRAHAM VALDELOMAR 126 ALTO SELVA ALEGRE
          </Text>
          <Text style={{ ...styles.rowThree, width: "15%", height: 15 }}>
            41697491
          </Text>
        </View>
      </View>

      <View style={styles.containerBox}>
        <Text style={{ ...styles.textTitle, marginTop: 5 }}>
          2. DATOS DEL PROPIETARIO DEL VEHICULO
        </Text>
        <View style={styles.containerHeader}>
          <Text style={{ ...styles.rowOne, width: "30%" }}>
            APELLIDO PATERNO
          </Text>
          <Text style={{ ...styles.rowTwo, width: "30%" }}>
            APELLIDO MATERNO
          </Text>
          <Text style={{ ...styles.rowThree, width: "40%" }}>NOMBRES</Text>
        </View>
        <View style={styles.containerTextRow}>
          <Text style={{ ...styles.rowOne, width: "30%", height: 15 }}>
            {data?.driverLastname?.split(" ")[0]}
          </Text>
          <Text style={{ ...styles.rowTwo, width: "30%", height: 15 }}>
            {data?.driverLastname.split(" ")[1]}
          </Text>
          <Text style={{ ...styles.rowThree, width: "40%", height: 15 }}>
            {data?.driverName}
          </Text>
        </View>
        <View style={{ ...styles.containerHeader, borderTopWidth: 0 }}>
          <Text style={{ ...styles.rowOne, width: "20%" }}>
            DOCUMENTO DE IDENTIDAD
          </Text>
          <Text style={{ ...styles.rowTwo, width: "60%" }}>DIRECCION</Text>
          <Text style={{ ...styles.rowThree, width: "20%" }}>TELEFONO</Text>
        </View>
        <View style={styles.containerTextRow}>
          <Text style={{ ...styles.rowOne, width: "20%", height: 15 }}>
            {data.driverDni}
          </Text>
          <Text style={{ ...styles.rowTwo, width: "60%", height: 15 }}>
            {data.driverAddress}
          </Text>
          <Text style={{ ...styles.rowThree, width: "20%", height: 15 }}>
            {data.driverPhone}
          </Text>
        </View>
      </View>

      <View style={styles.containerBox}>
        <Text style={{ ...styles.textTitle, marginTop: 5 }}>
          3. DATOS DEL VEHICULO
        </Text>
        <View style={styles.containerHeader}>
          <Text style={{ ...styles.rowOne, width: "20%" }}>COLOR</Text>
          <Text style={{ ...styles.rowTwo, width: "20%" }}>MARCA</Text>
          <Text style={{ ...styles.rowTwo, width: "20%" }}>MODELO</Text>
          <Text style={{ ...styles.rowTwo, width: "20%" }}>AÑO</Text>
          <Text style={{ ...styles.rowThree, width: "20%" }}>PLACA</Text>
        </View>
        <View style={styles.containerTextRow}>
          <Text style={{ ...styles.rowOne, width: "20%", height: 15 }}>
            {data.color}
          </Text>
          <Text style={{ ...styles.rowTwo, width: "20%", height: 15 }}>
            {data.brand}
          </Text>
          <Text style={{ ...styles.rowTwo, width: "20%", height: 15 }}>
            {data.model}
          </Text>
          <Text style={{ ...styles.rowTwo, width: "20%", height: 15 }}>
            {data.year}
          </Text>
          <Text style={{ ...styles.rowThree, width: "20%", height: 15 }}>
            {data.plate}
          </Text>
        </View>
      </View>

      <View style={styles.containerBox}>
        <Text style={{ ...styles.textTitle, marginTop: 5 }}>
          4. DOCUMENTOS ADJUNTOS
        </Text>
        <View style={styles.containerHeader}>
          <Text style={{ ...styles.rowFour, width: "90%" }}>
            Recibo de pago por derechos S/. 72.60 +23.70 (CARACTERÍSTICAS)
          </Text>
          <Text style={{ ...styles.rowFive, width: "10%" }}></Text>
        </View>
        <View style={styles.containerHeader}>
          <Text style={{ ...styles.rowFour, width: "90%" }}>
            Copia simple del contrato de arrendamiento
          </Text>
          <Text style={{ ...styles.rowFive, width: "10%" }}></Text>
        </View>
        <View style={styles.containerHeader}>
          <Text style={{ ...styles.rowFour, width: "90%" }}>
            Boletin informativo de SUNARP con antiguedad no mayor a 30 dias
          </Text>
          <Text style={{ ...styles.rowFive, width: "10%" }}></Text>
        </View>
        <View style={styles.containerHeader}>
          <Text style={{ ...styles.rowFour, width: "90%" }}>
            Copia simple del SOAT o AFOCAT, vigente
          </Text>
          <Text style={{ ...styles.rowFive, width: "10%" }}></Text>
        </View>
        <View style={styles.containerHeader}>
          <Text style={{ ...styles.rowFour, width: "90%" }}>
            Certificado de constatación de características aprobado y vigente
          </Text>
          <Text style={{ ...styles.rowFive, width: "10%" }}></Text>
        </View>
        <View style={styles.containerHeader}>
          <Text style={{ ...styles.rowFour, width: "90%" }}>
            Copia de Certificado de Inspección Técnica Vehicular complementaria
            aprobado y vigente
          </Text>
          <Text style={{ ...styles.rowFive, width: "10%" }}></Text>
        </View>
        <View style={styles.containerHeader}>
          <Text style={{ ...styles.rowFour, width: "90%" }}>
            Copia simple del certificado que acredite el ingles basico (solo
            REMISSE)
          </Text>
          <Text style={{ ...styles.rowFive, width: "10%" }}></Text>
        </View>
        <View style={styles.containerHeader}>
          <Text style={{ ...styles.rowFour, width: "90%" }}>
            Declaracion Jurada que acredite valores culturales y turísticos del
            Perú y técnicas de atención al usuario (solo REMISSE)
          </Text>
          <Text style={{ ...styles.rowFive, width: "10%" }}></Text>
        </View>
        <View style={styles.containerHeader}>
          <Text style={{ ...styles.rowFour, width: "90%" }}>
            Solo para vehiculos que se encuentren dentro de los alcances del
            acceso regulados en los Decretos Supremos Nos. 058-2003-MTC y
            017-2009-MTC, Ordenanza Municipal N°640 y 869
          </Text>
          <Text style={{ ...styles.rowFive, width: "10%" }}></Text>
        </View>
        <View style={{ ...styles.containerHeader, marginTop: 10 }}>
          <Text style={{ ...styles.rowFour, width: "100%" }}>
            TODA FALSEDAD DE DOCUMENTOS Y/O DATOS CONSIGNADOS, INVALIDARA
            AUTOMATICAMENTE EL PROCEDIMIENTO ADMINISTRATIVO EN CURSO Y DARA
            LUGAR A QUE SE INTERPONGAN LAS SANCIONES DE LEY
          </Text>
        </View>
      </View>

      <View style={styles.containerBox}>
        <Text style={{ ...styles.textTitle, marginTop: 5 }}>
          5. OBSERVACIONES
        </Text>
        <View style={styles.containerHeader}>
          <Text style={{ ...styles.rowFour, width: "100%", height: 50 }}></Text>
        </View>
      </View>

      <View style={styles.containerSignature}>
        <View style={styles.signatureHeader}>
          <Text style={{ ...styles.signatureText }}>
            FIRMA Y SELLO DEL REPRESENTANTE LEGAL
          </Text>
          <Image style={styles.logo} src={firma} />
        </View>
      </View>
    </View>
  );
};

export default Body;
