import { updateTourCron } from "../../../data/repository/tour.repo";

export const UpdateTourCronUseCase = async (tour) => {
  try {
    const response = await updateTourCron(tour);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
