import { baseBackend } from "../../../../../../api/base/baseUrl";

const URL_COST_CENTER = "/api/v2/costcenter";

export const createCostCenter = async (costCenter) => {
  return await baseBackend.post(
    `${URL_COST_CENTER}/register`,
    JSON.stringify({ costCenter }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const readEnterpriseCostCenter = async (enterpriseId) => {
  return await baseBackend.get(
    `${URL_COST_CENTER}/enterprise/id/${enterpriseId}`,
    { headers: { "Content-Type": "application/json" } }
  );
};

export const readRelationCostCenter = async (employeeId) => {
  return await baseBackend.get(`${URL_COST_CENTER}/relation/id/${employeeId}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const updateCostCenter = async (costCenter) => {
  return await baseBackend.put(
    `${URL_COST_CENTER}/update`,
    JSON.stringify({ costCenter }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const deleteCostCenter = async (id) => {
  return await baseBackend.delete(`${URL_COST_CENTER}/delete/id/${id}`, {
    headers: { "Content-Type": "application/json" },
  });
};
