import { baseBackend } from "../base/baseUrl";
import { URL_LOGIN } from "../../helpers/endpoint";

export const login = async (email, password) => {
  return await baseBackend.post(
    URL_LOGIN,
    JSON.stringify({ email, password, role: 1 }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};
