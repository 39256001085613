import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { db } from "../../../../../../firebase";
import { set, ref, get } from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Modal,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Stack,
  useTheme,
  Autocomplete,
  useMediaQuery,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { Formik } from "formik";
import * as yup from "yup";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { getTokenRefresh } from "../../../../../../helpers/token/refreshToken";

import "../../style/booking.css";
import {
  boxModalStyle,
  circularProgressStyle,
  suggestionBackgroundColor,
} from "../../style/BookingStyle";
import { getAddressAndLatLng } from "../../../../../../api/resources/url/expandUrl";
import SnackBar from "../../../../../../components/snackbar/SnackBar";
import { tokens } from "../../../../../../theme";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { getClients } from "../../../../../../api/users/client/client";
import { dateFormat, timeFormat } from "../../../../../../helpers/date/date";
import { sendICSEmail } from "../../../../../../api/resources/ics/ics";
import { getListCountryUseCase } from "../../../tour/usecases/country/GetListCountryUseCase";
import { getListTourByCountry } from "../../../tour/data/repository/tourRepo";
import { sendNotificationTokens } from "../../../../../../api/fcm/notification";
import { CreateBackupUseCase } from "../../../backup/usecases/CreateBackupOrder";

const BookingClient = ({ model }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);

  const [dateEnd, setDateEnd] = useState(null);
  const [timeEnd, setTimeEnd] = useState(null);
  const [checked, setChecked] = useState(false);
  const handleChangeCheckBox = (event) => setChecked(event.target.checked);
  const [phoneActive, setPhoneActive] = useState(true);
  const changePhoneActive = (e) => setPhoneActive(e.target.checked);
  const [typeCheck, setTypeCheck] = useState(false);
  const changeTypeCheck = (e) => setTypeCheck(e.target.checked);

  useEffect(() => {
    const getListClients = async () => {
      try {
        const token = getTokenRefresh();
        const response = await getClients(token);
        setClients(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getListClients();
  }, []);

  //traer los precios de firebase
  const [riderRates, setRiderRates] = useState(null);
  useEffect(() => {
    try {
      const dbRefRates = ref(db, `BusinessRate/Price/Arequipa/Rider`);
      get(dbRefRates)
        .then(async (snapshot) => {
          const data = snapshot.val();
          if (data != null) {
            setRiderRates(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

  const [addressDestination, setAddressDestination] = useState("");
  const [coordDestination, setCoordDestination] = useState({
    lat: null,
    lng: null,
  });

  const [serviceList, setServiceList] = useState([]);
  const [route1, setRoute1] = useState("");
  const [indice, setIndice] = useState(-1);

  const checkoutSchema = yup.object().shape({
    persons: yup.number().required("required"),
    booking: yup.number().required("required"),
    note: yup.string(),
  });

  const initialValues = {
    persons: 1,
    booking: 1,
    note: "",
    price: 0,
    tax: 0,
  };

  const handSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setAddress(value);
    setCoordinates(ll);
  };

  const handSelectRoute = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    const list = [...serviceList];
    list[indice].service = value;
    list[indice].lat = ll.lat;
    list[indice].lng = ll.lng;
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    if (indice === -1) {
      setServiceList([...serviceList, { service: "" }]);
      setIndice(indice + 1);
    } else if (serviceList[indice].service !== "") {
      setServiceList([...serviceList, { service: "" }]);
      setIndice(indice + 1);
      setRoute1("");
    } else {
      window.alert("INGRESE UNA RUTA ANTES DE CREAR OTRO CAMPO");
    }
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
    setIndice(indice - 1);
  };

  const handleSelectDestination = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setAddressDestination(value);
    setCoordDestination(ll);
  };

  const [openOrigin, setOpenOrigin] = useState(false);
  const [isOrigin, setIsOrigin] = useState(false);
  const [originURL, setOriginURL] = useState("");

  const [openDestination, setOpenDestination] = useState(false);
  const [isDestination, setIsDestination] = useState(false);
  const [destinationURL, setDestinationURL] = useState("");

  const [openIntermediate, setOpenIntermediate] = useState(false);
  const [isIntermediate, setIsIntermediate] = useState(false);
  const [intermediateURL, setIntermediateURL] = useState("");

  const handleOpenOrigin = () => setOpenOrigin(true);
  const handleCloseOrigin = () => setOpenOrigin(false);

  const handleOpenDestination = () => setOpenDestination(true);
  const handleCloseDestination = () => setOpenDestination(false);

  const handleOpenIntermediate = () => setOpenIntermediate(true);
  const handleCloseIntermediate = () => setOpenIntermediate(false);

  const handleIntermediateRoute = async () => {
    if (intermediateURL) {
      setIsIntermediate(true);
      try {
        const getIntermediateRoute = await getAddressAndLatLng(intermediateURL);
        if (getIntermediateRoute?.data?.message === "success")
          setIsIntermediate(false);

        const list = [...serviceList];
        list[indice].service = getIntermediateRoute?.data?.address;
        list[indice].lat = getIntermediateRoute?.data?.lat;
        list[indice].lng = getIntermediateRoute?.data?.lng;
        setServiceList(list);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleOriginAccept = async () => {
    if (originURL) {
      setIsOrigin(true);
      try {
        const getAddress = await getAddressAndLatLng(originURL);
        if (getAddress.data.message === "success") setIsOrigin(false);

        console.log(getAddress?.data);
        setAddress(getAddress?.data?.address);
        setCoordinates({
          lat: getAddress?.data?.lat,
          lng: getAddress?.data?.lng,
        });
        setOpenOrigin(false);
      } catch (error) {
        console.log(error);
      }
    }
    setOpenOrigin(false);
  };

  const handleDestinationAccept = async () => {
    if (destinationURL) {
      setIsDestination(true);
      try {
        const getAddress = await getAddressAndLatLng(destinationURL);
        if (getAddress.data.message === "success") setIsDestination(false);
        console.log(getAddress?.data);
        setAddressDestination(getAddress?.data?.address);
        setCoordDestination({
          lat: getAddress?.data?.lat,
          lng: getAddress?.data?.lng,
        });
        setOpenDestination(false);
      } catch (error) {
        console.log(error);
      }
    }
    setOpenDestination(false);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleFormSubmit = async (values) => {
    if (!client)
      return snackBarDialog("Asigne un Cliente para el pedido", "error");
    if (!coordinates.lat || !coordinates.lng)
      return snackBarDialog("Ingrese el Origen del servicio", "error");
    if (!coordDestination.lat || !coordDestination.lng)
      return snackBarDialog("Ingrese el Destino del servicio", "error");

    setIsLoading(true);
    const uuid = uuidv4();
    const nDate = new Date(Date.now());

    const data = createDataMySql(values, uuid, nDate);
    const backup = await CreateBackupUseCase(data);

    if (backup.message == "success") {
      const orderNumber = backup.insertId;
      const fireData = createDataFirebase(values, uuid, orderNumber, nDate);
      await createBookingFirebase(fireData);

      if (values.booking != 1 && !checked) {
        await sendEmail(values, orderNumber);
      }

      setAddress("");
      setCoordinates({ lat: null, lng: null });
      setAddressDestination("");
      setCoordDestination({ lat: null, lng: null });
      setServiceList([]);
      setClient(null);
      values.persons = 1;
      values.vehicle = "";
      values.note = "";
      snackBarDialog("PEDIDO CREADO, ver historial");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      snackBarDialog("ERROR: NO SE LOGRO CREAR EL SERVICIO", "error");
    }
  };

  const createDataMySql = (values, uuid, nDate) => {
    const uid = client.id;
    return {
      uuid,
      userType: 1,
      originName: address,
      originLat: coordinates.lat,
      originLng: coordinates.lng,
      originUrl: "",
      destinationName: addressDestination,
      destinationLat: coordDestination.lat,
      destinationLng: coordDestination.lng,
      destinationUrl: "",
      date: values.booking === 1 ? dateFormat(nDate) : dateFormat(date.$d),
      time: values.booking === 1 ? timeFormat(nDate) : timeFormat(time.$d),
      dateEnd: values.booking === 3 ? dateFormat(dateEnd.$d) : "",
      timeEnd: values.booking === 3 ? timeFormat(timeEnd.$d) : "",
      persons: values.persons,
      vehicleModel: vehicleSelect,
      notes: values.note ? values.note.toUpperCase() : "",
      notesAdmin: "",
      userId: uid,
      driverId: 0,
      vehicleId: 0,
      paymentId: 0,
      bookingType: values.booking,
      tourId: values.booking === 4 ? tour.tour_id : "",
      invoiceId: 0,
      createdBy: "ADMIN",
      status: 1,
      statePayment: "PENDIENTE",
      assigned: false,
      invoice: false,
      invoiceStatus: false,
      travelStatus: 1,
      currency: 1,
      paymentType: 1,
      paymentVoucher: 1,
      tax: values.tax ? values.tax : 0,
      toll: 0,
      price: values.price ? values.price : 0,
      total: priceTotal === "" ? 0 : priceTotal,
      discount: 0,
      advance: 0,
      quote: values.booking != 1 ? !checked : true,
    };
  };

  const createDataFirebase = (values, uuid, counter, nDate) => {
    const uid = client.id;
    return {
      uuid,
      client: uid,
      clientName: `${client.name} ${client.lastname}`,
      clientEmail: client.email,
      clientPhone: client.phone,
      origin: {
        name: address,
        lat: coordinates.lat,
        lng: coordinates.lng,
      },
      destination: {
        name: addressDestination,
        lat: coordDestination.lat,
        lng: coordDestination.lng,
      },
      intermediate: serviceList,
      persons: values.persons,
      vehicle: vehicleSelect,
      date: values.booking === 1 ? dateFormat(nDate) : dateFormat(date.$d),
      time: values.booking === 1 ? timeFormat(nDate) : timeFormat(time.$d),
      dateEnd: values.booking === 3 ? dateFormat(dateEnd.$d) : "",
      timeEnd: values.booking === 3 ? timeFormat(timeEnd.$d) : "",

      tour_id: values.booking === 4 ? tour.tour_id : "",
      tour_description: values.booking === 4 ? tour.description : "",

      note: values.note ? values.note.toUpperCase() : "",
      noteAdmin: "",
      currency: 1,
      payment_type: 1,
      payment_voucher: 1,
      tax: values.tax ? values.tax : 0,
      toll: 0,
      price: values.price ? values.price : 0,
      total: priceTotal === "" ? 0 : priceTotal,
      discount: 0,
      advance: 0,
      statusService: "PENDIENTE",
      statusPayment: "PENDIENTE",
      statusBooking: "PENDIENTE",
      createBooking: "ADMIN",
      booking_type: values.booking,
      counterBooking: counter,
      created: Date.now(),
      isCreated: false,
      cotizado: values.booking != 1 ? !checked : true,
      phoneActive: phoneActive,
    };
  };

  const sendEmail = async (values, counter) => {
    try {
      const newBooking = {
        counterBooking: counter,
        clientName: `${client.name} ${client.lastname}`,
        clientEmail: client.email,
        clientPhone: client.phone,
        origin: { name: address },
        destination: { name: addressDestination },
        date: dateFormat(date.$d),
        time: timeFormat(time.$d),
        note: values.note ? values.note.toUpperCase() : "",
        persons: values.persons,
        vehicle: values.vehicle,
        web: "Booking",
      };
      await sendICSEmail("token", newBooking);
    } catch (error) {
      console.log(error);
    }
  };

  const createBookingFirebase = async (data) => {
    const dbRef = `bookingNow/${data.uuid}`;
    await set(ref(db, dbRef), data);

    if (data.booking_type == 1) {
      await notificationToken();
    }
  };

  const notificationToken = async () => {
    try {
      const dbRefToken = ref(db, `Token/Driver`);
      get(dbRefToken)
        .then(async (snapshot) => {
          const data = snapshot.val();
          if (data != null) {
            let tokens = await listToken(data);
            if (tokens.length > 0) {
              const title = "PEDIDO PARA AHORA CLIENTES";
              const body = "Ingrese para aceptar";
              await sendNotificationTokens(tokens, title, body);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const listToken = async (data) => {
    let tokens = [];
    const promises = Object.values(data).map(async (_token) => {
      tokens.push(_token.token);
    });
    await Promise.all(promises);
    return tokens;
  };

  const handleMouseDownPassword = (event) => event.preventDefault();

  // TOUR
  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getCountrys = async () => {
      try {
        const response = await getListCountryUseCase();
        setCountries([]);
        setCountries(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCountrys();
  }, []);

  //SELECCIONA UN PAIS TRAER LISTA DE TOUR
  const [tour, setTour] = useState(null);
  const [tours, setTours] = useState([]);

  useEffect(() => {
    if (country) {
      const getListTour = async () => {
        try {
          console.log(country.id);
          const response = await getListTourByCountry(country.id);
          console.log(response);
          setTours([]);
          setTour(null);
          setTours(response.data.data);
        } catch (error) {
          console.log(error);
        }
      };
      getListTour();
    }
  }, [country]);

  const [priceTotal, setPriceTotal] = useState(0);
  const [vehicleSelect, setVehicleSelect] = useState(1);
  useEffect(() => {
    if (!coordinates.lat || !coordDestination.lat) {
    } else {
      //https://maps.googleapis.com/maps/api/directions/json?destination=Montreal&origin=Toronto&key=YOUR_API_KEY
      const pointA = {
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      };
      const pointB = {
        latitude: coordDestination.lat,
        longitude: coordDestination.lng,
      };
      const distance = distanceBetweenCoordinates(pointA, pointB);
      //distance en kilometros
      switch (vehicleSelect) {
        case 1: {
          console.log(riderRates.Sedan);
          const fare = riderRates.Sedan;
          var total = (distance / 1000) * fare.pricePerKM;
          if (total < fare.priceMinimal) total = fare.priceMinimal;
          setPriceTotal(total);
          break;
        }
        case 2: {
          console.log(riderRates.Suv);
          const fare = riderRates.Suv;
          var total = (distance / 1000) * fare.pricePerKM;
          if (total < fare.priceMinimal) total = fare.priceMinimal;
          setPriceTotal(total);
          break;
        }
        case 3: {
          console.log(riderRates.Minivan);
          const fare = riderRates.Minivan;
          var total = (distance / 1000) * fare.pricePerKM;
          if (total < fare.priceMinimal) total = fare.priceMinimal;
          setPriceTotal(total);
          break;
        }
        case 4: {
          console.log(riderRates.Minivan_15);
          const fare = riderRates.Minivan_15;
          var total = (distance / 1000) * fare.pricePerKM;
          if (total < fare.priceMinimal) total = fare.priceMinimal;
          setPriceTotal(total);
          break;
        }
        default: {
          console.log(riderRates.Global);
          const fare = riderRates.Global;
          var total = (distance / 1000) * fare.pricePerKM;
          if (total < fare.priceMinimal) total = fare.priceMinimal;
          setPriceTotal(total);
          break;
        }
      }
      // console.log(riderRates);
    }
  }, [coordinates, coordDestination, vehicleSelect]);

  function distanceBetweenCoordinates(pointA, pointB) {
    const earthRadius = 6371;
    const diffLat = toRadians(pointB.latitude - pointA.latitude);
    const diffLng = toRadians(pointB.longitude - pointA.longitude);

    const a =
      Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
      Math.cos(toRadians(pointA.latitude)) *
        Math.cos(toRadians(pointB.latitude)) *
        Math.sin(diffLng / 2) *
        Math.sin(diffLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;
    return distance <= 0.3;
  }

  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            onSubmit={handleSubmit}
            backgroundColor={colors.primary[400]}
            p={2}
          >
            <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  id="id-clients"
                  value={client}
                  onChange={(e, newValue) => setClient(newValue)}
                  options={clients}
                  getOptionLabel={(option) =>
                    `${option.name} ${option.lastname} - ${option.email} - ${option.phone}`
                  }
                  renderInput={(params) => (
                    <TextField {...params} label={t("LISTA DE CLIENTES")} />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div key={suggestions.description}>
                      <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          onChange={handleChange("weight")}
                          endAdornment={
                            address ? (
                              <InputAdornment
                                position="end"
                                onClick={handleServiceAdd}
                              >
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  <AddOutlinedIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : (
                              <InputAdornment
                                position="end"
                                onClick={handleOpenOrigin}
                              >
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  <LinkOutlinedIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }
                          aria-describedby="outlined-weight-helper-text"
                          {...getInputProps({
                            placeholder: t("origin"),
                            className: "location-search-input",
                          })}
                        />
                      </FormControl>
                      <Box
                        key={suggestions.description}
                        className="autocomplete-dropdown-container suggestions"
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          const style = suggestion.active
                            ? suggestionBackgroundColor(colors.primary[400])
                            : suggestionBackgroundColor(colors.primary[400]);
                          return (
                            <div
                              key={suggestion.description}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </Box>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Grid>
              <Modal open={openOrigin} onClose={handleCloseOrigin}>
                <Box
                  minWidth={300}
                  sx={{
                    ...boxModalStyle,
                    backgroundColor: colors.primary[500],
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography id="modal-title" variant="h5" component="h2">
                        URL Google Maps
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="modal-description"
                        type="text"
                        name="originURL"
                        value={originURL}
                        onChange={(e) => {
                          setOriginURL(e.target.value);
                        }}
                        sx={{ mt: 1 }}
                      />
                    </Grid>

                    <Grid item xs={4} md={2}>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleOriginAccept}
                      >
                        aceptar
                      </Button>
                    </Grid>
                    {isOrigin && (
                      <CircularProgress size={24} sx={circularProgressStyle} />
                    )}
                    <Grid item xs={4} md={2}>
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={handleCloseOrigin}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              {serviceList.map((singleService, index) => (
                <Grid key={index} item xs={12} sm={6}>
                  <PlacesAutocomplete
                    name="service"
                    value={
                      serviceList[index].service
                        ? serviceList[index].service
                        : route1
                    }
                    onChange={setRoute1}
                    onSelect={handSelectRoute}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div key={suggestions.description}>
                        <FormControl fullWidth variant="outlined">
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            onChange={handleChange("weight")}
                            endAdornment={
                              serviceList[index].service ? (
                                <InputAdornment
                                  position="end"
                                  onClick={() => handleServiceRemove(index)}
                                >
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    <ClearOutlinedIcon />
                                  </IconButton>
                                </InputAdornment>
                              ) : (
                                <InputAdornment
                                  position="end"
                                  onClick={handleOpenIntermediate}
                                >
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    <LinkOutlinedIcon />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }
                            aria-describedby="outlined-weight-helper-text"
                            {...getInputProps({
                              placeholder: `Route ${index + 1}`,
                              className: "location-search-input",
                            })}
                          />
                        </FormControl>
                        <Box
                          key={suggestions.description}
                          className="autocomplete-dropdown-container suggestionsDestination"
                        >
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? suggestionBackgroundColor(colors.primary[500])
                              : suggestionBackgroundColor(colors.primary[300]);
                            return (
                              <div
                                key={suggestion.description}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </Box>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Grid>
              ))}
              <Modal
                open={openIntermediate}
                onClose={handleCloseIntermediate}
                aria-labelledby="modal-inter-title"
                aria-describedby="modal-inter-description"
              >
                <Box sx={boxModalStyle}>
                  <Typography id="modal-title" variant="h5" component="h2">
                    URL GoogleMaps(https://goo.gl/maps/...)
                  </Typography>
                  <TextField
                    fullWidth
                    id="modal-description"
                    type="text"
                    name="originURL"
                    value={intermediateURL}
                    onChange={(e) => {
                      setIntermediateURL(e.target.value);
                    }}
                    sx={{ mt: 1 }}
                  />
                  <Button color="primary" onClick={handleIntermediateRoute}>
                    aceptar
                  </Button>
                  {isOrigin && (
                    <CircularProgress size={24} sx={circularProgressStyle} />
                  )}
                  <Button color="warning" onClick={handleCloseIntermediate}>
                    Cancelar
                  </Button>
                </Box>
              </Modal>

              <Grid item xs={12} sm={6}>
                <PlacesAutocomplete
                  value={addressDestination}
                  onChange={setAddressDestination}
                  onSelect={handleSelectDestination}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div key={suggestions.description}>
                      <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          onChange={handleChange("weight")}
                          endAdornment={
                            addressDestination ? (
                              ""
                            ) : (
                              <InputAdornment
                                position="end"
                                onClick={handleOpenDestination}
                              >
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  <LinkOutlinedIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }
                          aria-describedby="outlined-weight-helper-text"
                          {...getInputProps({
                            placeholder: t("destination"),
                            className: "location-search-input",
                          })}
                        />
                      </FormControl>
                      <Box
                        key={suggestions.description}
                        className="autocomplete-dropdown-container suggestionsDestination"
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          const style = suggestion.active
                            ? suggestionBackgroundColor(colors.primary[500])
                            : suggestionBackgroundColor(colors.primary[300]);
                          return (
                            <div
                              key={suggestion.description}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </Box>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Grid>
              <Modal
                open={openDestination}
                onClose={handleCloseDestination}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  minWidth={300}
                  sx={{
                    ...boxModalStyle,
                    backgroundColor: colors.primary[500],
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography id="modal-title" variant="h5" component="h2">
                        URL Google Maps
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="modal-description"
                        type="text"
                        label={t("Destination")}
                        name="destination"
                        value={destinationURL}
                        onChange={(e) => {
                          setDestinationURL(e.target.value);
                        }}
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                    {isDestination && (
                      <CircularProgress size={24} sx={circularProgressStyle} />
                    )}
                    <Grid item xs={4} md={2}>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleDestinationAccept}
                      >
                        aceptar
                      </Button>
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={handleCloseDestination}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Grid item xs={12} sm={6}>
                <TextField
                  value={values.persons}
                  fullWidth
                  type="number"
                  label={t("persons")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="persons"
                  error={!!touched.persons && !!errors.persons}
                  helperText={touched.persons && errors.persons}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>{t("vehicle")}</InputLabel>
                  <Select
                    value={vehicleSelect}
                    id="vehicle"
                    name="vehicle"
                    label={t("vehicle")}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setVehicleSelect(e.target.value);
                    }}
                  >
                    {model.map((model, index) => (
                      <MenuItem key={index} value={model.id}>
                        {t(model.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={values.booking === 3 ? 4 : 6}
                md={values.booking === 3 ? 4 : 6}
              >
                <FormControl fullWidth>
                  <InputLabel>{t("Para")}</InputLabel>
                  <Select
                    id="booking"
                    value={values.booking}
                    name="booking"
                    label={t("booking")}
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>{t("now")}</MenuItem>
                    <MenuItem value={2}>{t("later")}</MenuItem>
                    <MenuItem value={3}>{t("Por HORA")}</MenuItem>
                    <MenuItem value={4}>{t("TOUR")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={6}
                sm={values.booking === 3 ? 2 : 3}
                sx={
                  {
                    display: {
                      xs:
                        values.booking === 2 ||
                        values.booking === 3 ||
                        values.booking === 4
                          ? "block"
                          : "none",
                      md:
                        values.booking === 2 ||
                        values.booking === 3 ||
                        values.booking === 4
                          ? "block"
                          : "none",
                    },
                  }
                  // displayBox(values.booking, 2, 3)
                }
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <MobileDatePicker
                      label={values.booking === 3 ? t("startDate") : t("date")}
                      inputFormat="DD/MM/YYYY"
                      name="date"
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                        setDateEnd(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={6}
                sm={values.booking === 3 ? 2 : 3}
                sx={{
                  display: {
                    xs:
                      values.booking === 2 ||
                      values.booking === 3 ||
                      values.booking === 4
                        ? "block"
                        : "none",
                    md:
                      values.booking === 2 ||
                      values.booking === 3 ||
                      values.booking === 4
                        ? "block"
                        : "none",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <MobileTimePicker
                      label={values.booking === 3 ? t("startTime") : t("time")}
                      value={time}
                      name="time"
                      onChange={(newValue) => {
                        setTime(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={6}
                sm={2}
                sx={{
                  display: {
                    xs: values.booking === 3 ? "block" : "none",
                    md: values.booking === 3 ? "block" : "none",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <MobileDatePicker
                      label={t("dateEnd")}
                      inputFormat="DD/MM/YYYY"
                      name="dateEnd"
                      value={dateEnd}
                      onChange={(newValue) => {
                        setDateEnd(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={6}
                sm={2}
                sx={{
                  display: {
                    xs: values.booking === 3 ? "block" : "none",
                    md: values.booking === 3 ? "block" : "none",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <MobileTimePicker
                      label={t("timeEnd")}
                      value={timeEnd}
                      name="timeEnd"
                      onChange={(newValue) => {
                        setTimeEnd(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>

              {countries && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    display: {
                      xs: values.booking === 4 ? "block" : "none",
                      md: values.booking === 4 ? "block" : "none",
                    },
                  }}
                >
                  <Autocomplete
                    value={country}
                    disablePortal
                    id="select-origin"
                    onChange={(e, newValue) => {
                      setCountry(newValue);
                    }}
                    options={countries}
                    getOptionLabel={(option) => `${option.name}`}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("PAIS")} />
                    )}
                  />
                </Grid>
              )}

              {tours.length > 0 && (
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    display: {
                      xs: values.booking === 4 ? "block" : "none",
                      md: values.booking === 4 ? "block" : "none",
                    },
                  }}
                >
                  <Grid container sx={{ alignItems: "center" }}>
                    <Grid xs={11}>
                      <Autocomplete
                        value={tour}
                        disablePortal
                        id="select-origin"
                        onChange={(e, newValue) => {
                          setTour(newValue);
                        }}
                        options={tours}
                        getOptionLabel={(option) => `${option.description}`}
                        isOptionEqualToValue={(option, value) =>
                          option.tour_id === value.tour_id
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={t("TOUR")} />
                        )}
                      />
                    </Grid>
                    {tour && (
                      <Grid xs={1} padding={1}>
                        <spam
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            padding: "5px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            display: "inline-block",
                          }}
                          onClick={() => window.open(`${tour.url}`, "_blank")}
                        >
                          Info
                        </spam>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  value={values.note}
                  fullWidth
                  type="text"
                  label={t("notes")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="note"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.note && !!errors.note}
                  helperText={touched.note && errors.note}
                />
              </Grid>

              {!matchesSM && (
                <>
                  <Grid item xs={2} sm={1}>
                    <Checkbox
                      checked={checked}
                      onChange={handleChangeCheckBox}
                      color="success"
                    />
                  </Grid>

                  <Grid item xs={2} sm={1}>
                    <Checkbox
                      checked={phoneActive}
                      onChange={changePhoneActive}
                      color="success"
                    />
                  </Grid>

                  <Grid item xs={2} sm={1}>
                    <Checkbox
                      checked={typeCheck}
                      onChange={changeTypeCheck}
                      color="success"
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={4} sm={2}>
                <TextField
                  value={values.price}
                  fullWidth
                  type="number"
                  label={"Conductor"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="price"
                />
              </Grid>

              <Grid item xs={4} sm={2}>
                <TextField
                  value={values.tax}
                  fullWidth
                  type="number"
                  label={"Impuesto"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="tax"
                />
              </Grid>

              <Grid item xs={4} sm={2}>
                <TextField
                  value={priceTotal}
                  fullWidth
                  type="number"
                  label={"Total"}
                  onBlur={handleBlur}
                  onChange={(e) => setPriceTotal(e.target.value)}
                  name="total"
                />
              </Grid>

              {matchesSM && (
                <Grid item xs={1} sm={1} padding={0}>
                  <Checkbox
                    checked={checked}
                    onChange={handleChangeCheckBox}
                    color="success"
                  />
                </Grid>
              )}

              <Grid item xs={9} sm={3}>
                <Button
                  fullWidth
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ height: 45 }}
                  disabled={isLoading}
                >
                  <Typography variant="string">
                    &#128196;&nbsp;{" "}
                    {values.booking === 1
                      ? "Pedir Ahora"
                      : t("Guardar Reserva")}{" "}
                    &nbsp; &#128241;&nbsp;&nbsp;&nbsp;&#128179;
                  </Typography>
                </Button>
              </Grid>

              {matchesSM && (
                <>
                  <Grid item xs={1} sm={1} marginLeft={-1}>
                    <Checkbox
                      checked={phoneActive}
                      onChange={changePhoneActive}
                      color="success"
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <Checkbox
                      checked={typeCheck}
                      onChange={changeTypeCheck}
                      color="success"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={4000}
            handleClose={handleClose}
            severity={severity}
            messageSnk={messageSnk}
          />
        </form>
      )}
    </Formik>
  );
};

export default BookingClient;
