import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  useTheme,
  Grid,
  TextField,
  CssBaseline,
} from "@mui/material";
import { tokens } from "../../../../../theme";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import {
  updateSubrole,
  getSubrole,
} from "../../../../../api/enterprise/subroles";
import Topbar from "../../../../../components/topbar/TopBar";
import Header from "../../../../../components/header/Header";
import { useParams } from "react-router-dom";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../components/sidebar/Sidebar";

const RoleEdit = ({ token }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { id, enterprise_id } = useParams();

  const [role, setSubrole] = useState(null);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => {
    setStateSnk(false);
  };

  useEffect(() => {
    const getRole = async () => {
      try {
        console.log("id", id);
        console.log("enteprise", enterprise_id);
        const response = await getSubrole("token", enterprise_id, id);
        console.log(response.data);
        setSubrole(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    getRole();
  }, []);

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
  });

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleFormSubmit = async (values) => {
    console.log(values);
    try {
      const subrole = {
        enterprise_id: values.enterprises_id,
        subrole_id: values.id,
        name: values.name.toUpperCase(),
      };
      await updateSubrole(token, subrole);
      snackBarDialog("ROL ACTUALIZADO CORRECTAMENTE");
    } catch (error) {
      snackBarDialog("NO SE PUEDE ACTUALIZAR A UN ROL REPETIDOS", "error");
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="EDITAR ROL" subtitle="" />
              <Box></Box>
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                {role && (
                  <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={role}
                    validationSchema={checkoutSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Box backgroundColor={colors.primary[400]} p={2}>
                          <Grid
                            container
                            spacing={1.5}
                            sx={{ alignItems: "center" }}
                          >
                            <Grid item xs={12} sm={8}>
                              <TextField
                                value={values.name}
                                fullWidth
                                type="text"
                                label={t("role").toUpperCase()}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="name"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4} textAlign={"center"}>
                              <Button
                                fullWidth
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{ height: 45 }}
                              >
                                {t("GUARDAR")}
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        <SnackBar
                          stateSnk={stateSnk}
                          vertical={vertical}
                          horizontal={horizontal}
                          duration={5000}
                          handleClose={handleSnkClose}
                          severity={severity}
                          messageSnk={messageSnk}
                        />
                      </form>
                    )}
                  </Formik>
                )}
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default RoleEdit;
