import { baseBackend } from "../../../api/base/baseUrl";

const URL_QUESTIONS = "/api/v1/module/question";

export const getListQuestion = async () => {
  return await baseBackend.get(`${URL_QUESTIONS}/list`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const createQuestion = async (question) => {
  return await baseBackend.post(
    `${URL_QUESTIONS}/create`,
    JSON.stringify({ question }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const createBindQuestion = async (idQuest, typeList, tourList) => {
  return await baseBackend.post(
    `${URL_QUESTIONS}/bind`,
    JSON.stringify({ idQuest, typeList, tourList }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getListTour = async () => {
  return await baseBackend.get(`${URL_QUESTIONS}/tour`, {
    headers: { "Content-Type": "application/json" },
  });
};
