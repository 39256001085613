import { readScheduled } from "../../../data/repository/scheduled.repository";

export const ReadScheduledUseCase = async () => {
  try {
    const resp = await readScheduled();
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
