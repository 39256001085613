import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  Stack,
  CssBaseline,
  Modal,
  Typography,
  Grid,
  Button,
  useTheme,
  Popper,
  Paper,
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { getTokenRefresh } from "../../../../helpers/token/refreshToken";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { modalStyle } from "./VehicleStyle";
import { tokens } from "../../../../theme";
import {
  deleteVehicleApi,
  getVehicles,
} from "../../../../api/users/driver/vehicle";
import { dateFormat } from "../../../../helpers/date/date";
import { DataGrid, esES } from "@mui/x-data-grid";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Title from "./pdf/elements/Title";
import Contact from "./pdf/elements/Contact";
import DocumentName from "./pdf/elements/DocumentName";
import Table from "./pdf/elements/Table";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const Listvehicles = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [vehicles, setVehicles] = useState([]);
  const [UID, setUID] = useState("");
  const [STATUS, setSTATUS] = useState("");
  const [dataSelected, setDataSelected] = useState([]);
  const vehicleStatus = { 1: "Activo", 2: "Suspendido", 3: "Eliminado" };
  const vehiclePremium = { 0: "NO", 1: "SI" };

  useEffect(() => {
    const listVehicles = async () => {
      try {
        const token = getTokenRefresh();
        if (token) {
          const response = await getVehicles(token);
          console.log(response.data);
          setVehicles([]);
          Object.values(response.data).map((vehicle, index) => {
            if (vehicle.status !== 3) {
              vehicle.soat_end = dateFormat(new Date(vehicle.soat_end));
              vehicle.tr_end = dateFormat(new Date(vehicle.tr_end));
              if (vehicle.setare_end) {
                vehicle.setare_end = dateFormat(new Date(vehicle.setare_end));
              }
              vehicle.vehicleStatus = vehicleStatus[vehicle.status];
              vehicle.vehiclePremium = vehiclePremium[vehicle.premium];
              setVehicles((oldData) => [...oldData, vehicle]);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    listVehicles();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      headerName: "Propietario",
      field: "owner",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 120,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Marca",
      field: "brand",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 120,
    },
    {
      headerName: "Model",
      field: "model",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 120,
    },
    {
      headerName: "Carroceria",
      field: "bodywork",
      width: 80,
    },
    {
      headerName: "Placa",
      field: "plate",
      width: 80,
    },
    {
      headerName: "Año",
      field: "year",
      width: 60,
    },
    {
      headerName: "Color",
      field: "color",
      width: 100,
    },
    {
      headerName: "Cilindrada",
      field: "cylinder",
      width: 70,
    },
    {
      headerName: "Peso",
      field: "weight",
      width: 60,
    },
    {
      headerName: "FV SOAT",
      field: "soat_end",
      width: 80,
    },
    {
      headerName: "FV REVISION",
      field: "tr_end",
      width: 80,
    },
    {
      headerName: "FV TUC",
      field: "setare_end",
      width: 80,
    },
    {
      headerName: "ESTADO",
      field: "vehicleStatus",
      width: 70,
    },
    {
      headerName: "VIP",
      field: "vehiclePremium",
      width: 30,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      renderCell: ({ row: { id, status } }) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                navigate(`/admin/vehicle/${id}`);
              }}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(id);
                setSTATUS(status);
                setOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const data = {
    id: "5df3180a09ea16dc4b95f910",
    invoice_no: "201906-28",
    company: "TAXIMUNDO",
    email: "info@taximundo.com",
    phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
    address: "Servicios Generales Trans Mundo E.I.R.L.",
    items: dataSelected,
    igv: 0,
    total: 0,
    peaje: 0,
    subTotal: 0,
    from: dataSelected.length > 0 ? dataSelected[0].date : "",
    to:
      dataSelected.length > 0 ? dataSelected[dataSelected.length - 1].date : "",
  };

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };

  const [open, setOpen] = useState(false);
  const handleModalClose = () => setOpen(false);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const deleteVehicle = async () => {
    console.log("delete");
    setIsLoading(true);
    try {
      const eliminar = await deleteVehicleApi(UID, 3);
      snackBarDialog("vehiculo eliminado");
      setVehicles(vehicles.filter((vehicle) => vehicle.id !== UID));
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      snackBarDialog("error no se ha eliminado");
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateStatusVehicle = async () => {
    console.log("delete");
    setIsLoading(true);
    try {
      await deleteVehicleApi(UID, 2);
      snackBarDialog("Vehiculo Suspendido");
      vehicles.map((vehicle) => {
        if (vehicle.id === UID) {
          vehicle.vehicleStatus = "Suspendido";
          vehicle.status = 2;
        }
      });
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      snackBarDialog("Error no se podido Suspender");
      console.log(error);
      setIsLoading(false);
    }
  };

  const activeStatusVehicle = async () => {
    console.log("delete");
    setIsLoading(true);
    try {
      await deleteVehicleApi(UID, 1);
      snackBarDialog("Vehiculo Habilidado");
      vehicles.map((vehicle) => {
        if (vehicle.id === UID) {
          vehicle.vehicleStatus = "Activo";
          vehicle.status = 1;
        }
      });
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      snackBarDialog("Error no se podido Suspender");
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="VEHICULOS" subtitle="" />
              <Box>
                <PDFDownloadLink
                  document={
                    <Document>
                      <Page size="A4" style={styles.page}>
                        <Title title="Taxi Mundo PERU" />
                        <Contact invoice={data} />
                        <DocumentName
                          title={"DETALLE DE SERVICIOS"}
                          from={data.from}
                          to={data.to}
                        />
                        <Table invoice={data} />
                      </Page>
                    </Document>
                  }
                  fileName={"lista-vehiculos-taximundo.pdf"}
                >
                  <Box width={"100%"}>
                    <Button fullWidth variant="contained" color="success">
                      <PictureAsPdfIcon /> DESCARGAR
                    </Button>
                  </Box>
                </PDFDownloadLink>
              </Box>
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <Box
                  m="10px 0 0 0"
                  height="100vh"
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .name-column--cell": {
                      color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: colors.blueAccent[700],
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                      borderTop: "none",
                      backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                  }}
                >
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={vehicles}
                    columns={columns}
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    checkboxSelection={true}
                    onSelectionModelChange={(ids) => {
                      setDataSelected([]);
                      ids.map((id) => {
                        const select = vehicles.find((row) => row.id === id);
                        setDataSelected((oldData) => [...oldData, select]);
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Modal open={open} onClose={handleModalClose}>
            <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
              <Typography variant="h6" component="h2" textAlign={"center"}>
                ELIMINAR VEHICULO?
              </Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={4} md={4} textAlign="center">
                  {STATUS === 1 ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={updateStatusVehicle}
                      disabled={isLoading}
                    >
                      SUSPENDER
                    </Button>
                  ) : (
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={activeStatusVehicle}
                      disabled={isLoading}
                    >
                      HABILITAR
                    </Button>
                  )}
                </Grid>
                <Grid item xs={4} md={4} textAlign="center">
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={deleteVehicle}
                    disabled={isLoading}
                  >
                    ELIMINAR
                  </Button>
                </Grid>
                <Grid item xs={4} md={4} textAlign="center">
                  <Button
                    color="error"
                    variant="contained"
                    onClick={handleModalClose}
                    disabled={isLoading}
                  >
                    CANCELAR
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={5000}
            handleClose={handleSnkClose}
            severity={severity}
            messageSnk={messageSnk}
          />
        </main>
      </div>
    </>
  );
};

export default Listvehicles;
