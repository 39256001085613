import React, { useEffect } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { getAllBookingPending } from "../../../../api/booking/bookingNow";
import { useState } from "react";
import { getInvoicePending } from "../../../../api/booking/Invoice";
import { dateFormat } from "../../../../helpers/date/date";
import { getTokenRefresh } from "../../../../helpers/token/refreshToken";
import { getDrivers } from "../../../../api/users/driver/driver";

const GridTransactions = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [clients, setClients] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [enterprises, setEnterprises] = useState(null);

  const ListIgv = {
    1: "INAFECTO",
    2: "AFECTO",
    3: "MAS IGV",
  };

  useEffect(() => {
    const getBookingPending = async () => {
      try {
        const response = await getAllBookingPending("token");
        const responseBusiness = await getInvoicePending("token");
        setClients([]);
        setClients(response.data);
        setEnterprises([]);

        Object.values(responseBusiness.data).map((data) => {
          data.igv = ListIgv[data.igv];
          if (data.igv === "INAFECTO") {
            data.subTotal = 0;
            data.subIgv = 0;
          } else if (data.igv === "AFECTO") {
            const sub = Math.round((data.total / 1.18) * 100) / 100;
            data.subTotal = sub;
            data.subIgv = Math.round((data.total - sub) * 100) / 100;
          } else if (data.igv === "MAS IGV") {
            const imp = Math.round(Number(data.total) * 0.18 * 100) / 100;
            data.subIgv = imp;
            data.subTotal = data.total;
            data.total = Number(data.total) + imp;
          }
          setEnterprises((oldData) => [...oldData, data]);
        });
        //setEnterprises(responseBusiness.data);
      } catch (error) {
        console.log(error);
      }
    };
    getBookingPending();
  }, []);

  useEffect(() => {
    const listDrivers = async () => {
      try {
        const token = getTokenRefresh();
        if (token) {
          const response = await getDrivers(token);
          setDrivers([]);
          let count = 0;
          Object.values(response.data).map((driver) => {
            if (driver.status !== 3) {
              let plates = "";
              let bodyworks = "";
              Object.values(driver.vehicle).map((v) => {
                plates = plates + v.vehicle_plate + ", ";
                bodyworks = bodyworks + v.vehicle_bodywork + ", ";
              });
              driver.plates = plates;
              driver.bodyworks = bodyworks;
              driver.index = ++count;
              setDrivers((olData) => [...olData, driver]);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    listDrivers();
  }, []);

  return (
    <Grid container spacing={1} mt={1}>
      <Grid item xs={12} sm={6} md={4}>
        <Box
          maxHeight={400}
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              EMPRESAS
            </Typography>
          </Box>
          {enterprises &&
            enterprises.map((invoice, i) => (
              <Box
                key={`${invoice.id}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                p="10px"
              >
                <Box>
                  <Typography
                    color={colors.greenAccent[500]}
                    variant="h5"
                    fontWeight="600"
                  >
                    {invoice.invoice.toUpperCase()}
                  </Typography>
                  <Typography color={colors.grey[100]}>
                    {invoice.commertial_name}
                  </Typography>
                </Box>
                <Box color={colors.grey[100]}>
                  <p style={{ fontSize: 12, margin: -1 }}>
                    {dateFormat(new Date(invoice.date_issue))}
                  </p>
                  <p style={{ fontSize: 12, margin: -1 }}>
                    {dateFormat(new Date(invoice.date_expire))}
                  </p>
                </Box>
                <Box
                  backgroundColor={colors.greenAccent[500]}
                  p="5px 5px"
                  borderRadius="4px"
                >
                  {invoice.symbol}{" "}
                  {Math.round(Number(invoice.total) * 100) / 100}
                </Box>
              </Box>
            ))}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box
          maxHeight={400}
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              CONDUCTORES
            </Typography>
          </Box>
          {drivers &&
            drivers.map((driver, index) =>
              driver.total_amount < 0 ? (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p="15px"
                >
                  <Box>
                    <Typography color={colors.grey[100]}>
                      {driver.fullname}
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>{driver.date}</Box>
                  <Box
                    backgroundColor={colors.greenAccent[500]}
                    p="5px 10px"
                    borderRadius="4px"
                  >
                    {Math.round(driver.total_amount * 10) / 10}
                  </Box>
                </Box>
              ) : null
            )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box
          maxHeight={400}
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              CLIENTES
            </Typography>
          </Box>
          {clients &&
            clients.map((book, i) => (
              <Box
                key={`${book.id}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                p="10px"
              >
                <Box>
                  <Typography
                    color={colors.greenAccent[500]}
                    variant="h5"
                    fontWeight="600"
                    textAlign={"center"}
                  >
                    {book.order_number}
                  </Typography>
                  <Typography color={colors.grey[100]} sx={{ fontSize: 13 }}>
                    {book.client_fullname}
                  </Typography>
                </Box>
                <Box color={colors.grey[100]}>
                  <p style={{ fontSize: 12, margin: -1 }}>{book.date}</p>
                  <p style={{ fontSize: 12, margin: -1 }}>{book.time}</p>
                </Box>
                <Box
                  backgroundColor={colors.greenAccent[500]}
                  p="5px 5px"
                  borderRadius="4px"
                >
                  {Number(book.payment_price_total)}
                </Box>
              </Box>
            ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default GridTransactions;
