import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import { Formik } from "formik";

import * as yup from "yup";
import DataTable from "./DataTable";
import { tokens } from "../../../../theme";

import { modalStyle } from "./AddressStyle";
import { addressSchema } from "./AddressValues";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { db } from "../../../../firebase";
import { onValue, ref, remove, update } from "firebase/database";
import { getRateAddress } from "../../../../api/users/enterprise/enterpriseApi";

const RateTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rates, setRates] = useState([]);
  const checkoutSchema = yup.object().shape(addressSchema(yup));
  const [updateAddress, setUpdateAddress] = useState([]);

  //traer todo
  //traer
  useEffect(() => {
    const getAddress = async () => {
      try {
        const response = await getRateAddress();
        console.log(response.data.city);
        setRates(response.data.city);
      } catch (error) {
        console.log(error);
      }
    };
    getAddress();
  }, []);

  const handleFormSubmit = async (values) => {
    try {
      setIsLoading(true);
      console.log(values);

      snackBarDialog("DIRECCION ACTUALIZADA");
      setIsLoading(false);
      setUpdateOpen(false);
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR DE ACTUALIZACION", "error");
      setIsLoading(false);
      setUpdateOpen(false);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      headerName: "DIRECCION",
      type: "string",
      field: "name",
      align: "left",
      width: 350,
    },
    {
      headerName: "CIUDAD",
      field: "city_name",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "RADIO",
      field: "radius",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 120,
      align: "center",
      renderCell: ({ row: { id } }) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                setUID(id);
                setUpdateOpen(true);
                rates.map((el) => {
                  if (el.id === id) {
                    console.log(el);
                    setUpdateAddress(el);
                  }
                });
              }}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(id);
                setOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);
  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [UID, setUID] = useState("");
  const handleClose = () => setOpen(false);
  const handleUpdateClose = () => setUpdateOpen(false);

  const handleAddressDelete = async () => {
    try {
      setIsLoading(true);
      const dbRef = ref(db, `BusinessRate/Address/Arequipa/${UID}`);
      remove(dbRef);
      snackBarDialog("TARIFA ELIMINADA");
      setIsLoading(false);
      handleClose();
    } catch (error) {
      snackBarDialog("ERROR AL ELIMINAR", "error");
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <main className="content">
        <Box>
          <Box display="grid" gridAutoRows="120px">
            <Box gridColumn="span 12" gridRow="span 2">
              <DataTable data={rates} columns={columns} />
            </Box>
          </Box>
        </Box>

        <Modal open={updateOpen} onClose={handleUpdateClose}>
          <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
            <Typography variant="h6" component="h2" textAlign={"center"}>
              ACTUALIZAR
            </Typography>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={updateAddress}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box backgroundColor={colors.primary[400]}>
                    <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          value={values.name}
                          fullWidth
                          type="text"
                          label={t("address").toUpperCase()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 50,
                            style: { textTransform: "uppercase" },
                          }}
                          name="name"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          value={values.lat}
                          fullWidth
                          type="number"
                          label={t("latitud").toUpperCase()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          name="lat"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          value={values.lng}
                          fullWidth
                          type="number"
                          label={t("longitud").toUpperCase()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          name="lng"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          value={values.radius}
                          fullWidth
                          type="number"
                          label={t("Radio").toUpperCase()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="radius"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} textAlign={"center"}>
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          sx={{ height: 45 }}
                          disabled={isLoading}
                        >
                          {t("actualizar")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Modal>

        <Modal open={open} onClose={handleClose}>
          <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
            <Typography variant="h6" component="h2">
              ELIMINAR DIRECCION?
            </Typography>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6} md={6} textAlign="center">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleAddressDelete}
                  disabled={isLoading}
                >
                  ELIMINAR
                </Button>
              </Grid>
              <Grid item xs={6} md={6} textAlign="center">
                <Button
                  color="error"
                  variant="contained"
                  onClick={handleClose}
                  disabled={isLoading}
                >
                  CANCELAR
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <SnackBar
          stateSnk={stateSnk}
          vertical={vertical}
          horizontal={horizontal}
          duration={5000}
          handleClose={handleSnkClose}
          severity={severity}
          messageSnk={messageSnk}
        />
      </main>
    </>
  );
};

export default RateTable;
