import { baseBackend } from "../../../../../api/base/baseUrl";

const URL_RIDER_GRAPH = "/api/v2/graph";
export const readRiderGraph = async () => {
  return await baseBackend.get(`${URL_RIDER_GRAPH}/rider`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const readEnterpriseGraph = async () => {
  return await baseBackend.get(`${URL_RIDER_GRAPH}/enterprise`, {
    headers: { "Content-Type": "application/json" },
  });
};
