import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  CssBaseline,
  IconButton,
  Stack,
  Modal,
  Typography,
  Button,
  Grid,
  useTheme,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from "@mui/material";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

import Topbar from "../../../../components/topbar/TopBar";

import Header from "../../../../components/header/Header";
import DataTable from "../../../../components/datatable/DataTable";
import { getTokenRefresh } from "../../../../helpers/token/refreshToken";
import {
  enabledClientUseCase,
  getClients,
  updateClientApi,
} from "../../../../api/users/client/client";
import { tokens } from "../../../../theme";
import { modalStyle } from "../driver/DriverStyle";
import {
  createAdminTraveller,
  deleteTraveller,
  getTravellers,
  updateTraveller,
} from "../../../../api/users/client/traveller";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { dateFormat, timeFormat } from "../../../../helpers/date/date";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";
import { getSunatDni } from "../../../../api/resources/sunat/sunat";
import {
  StatusEnum,
  StatusEnumName,
} from "../../../../helpers/constants/Status";
import { UserStatus } from "../../../../module_firebase/domain/usecases/status/model/UserStatusModel";
import { UpdateUserStateUseCase } from "../../../../module_firebase/domain/usecases/status/UserStatusUseCase";
import { UsersEnum } from "../../../../helpers/constants/Users";
import { UpdateStatusClientUseCase } from "./domain/UpdateStatusClientUseCase";

const ListClients = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const theme = useTheme();
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const colors = tokens(theme.palette.mode);
  const [UID, setUID] = useState("");
  const [STATUS, setSTATUS] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const handleModalClose = () => setOpenModal(false);

  const [openTravellerModal, setOpenTravellerModal] = useState(false);
  const handleTravellerModalClose = () => setOpenTravellerModal(false);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  useEffect(() => {
    const listClients = async () => {
      try {
        const token = getTokenRefresh();
        if (token) {
          const response = await getClients(token);
          setClients([]);
          let count = 0;
          Object.values(response.data).map((client, index) => {
            if (client.status !== 3) {
              client.index = ++count;
              client.clientStatus = StatusEnumName(client.status);
              setClients((olData) => [...olData, client]);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    listClients();
  }, []);

  const [traveller, setTraveller] = useState(null);

  const getListTravellers = async (uid) => {
    try {
      const token = getTokenRefresh();
      const response = await getTravellers(token, uid);
      const data = response.data.travelers.filter((traveller) => traveller.id);
      setTraveller(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [uuid, setUuid] = useState();
  const [nameTravel, setNameTravel] = useState("");
  const [ageTravel, setAgeTravel] = useState(0);
  const [countryTravel, setCountryTravel] = useState("");
  const [documentTravel, setDocumentTravel] = useState("");

  const changeNameTravel = (e) => setNameTravel(e.target.value);
  const changeAgeTravel = (e) => setAgeTravel(e.target.value);
  const changeCountryTravel = (e) => setCountryTravel(e.target.value);
  const changeDocumentTravel = (e) => setDocumentTravel(e.target.value);
  const handleMouseDownPassword = (e) => e.preventDefault();

  const [addModal, setAddModal] = useState(false);
  const handleAddModalClose = () => setAddModal(false);
  //error
  const checkName = () => isTextFieldClicked && nameTravel.length < 3;
  const checkAge = () =>
    isTextFieldClicked && (Number(ageTravel) <= 0 || Number(ageTravel) > 100);
  const checkCountry = () => isTextFieldClicked && countryTravel.length < 3;
  const checkDocument = () => isTextFieldClicked && documentTravel.length < 8;

  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);
  const handleTextFieldClick = () => setIsTextFieldClicked(true);

  const inputValidation = () => {
    return (
      nameTravel.length < 3 ||
      Number(ageTravel) <= 0 ||
      Number(ageTravel) > 100 ||
      countryTravel.length < 3 ||
      documentTravel.length < 8
    );
  };

  const searchDni = async (e) => {
    if (documentTravel.length === 8) {
      try {
        const response = await getSunatDni(documentTravel);

        const data = response.data;
        setNameTravel(
          `${data.nombres} ${data.apellidoPaterno} ${data.apellidoMaterno}`
        );
      } catch (error) {
        snackBarDialog("DNI no encontrado", "error");
      }
    } else {
      snackBarDialog("DNI de 8 digitos", "error");
    }
  };

  const addTraveller = async (e) => {
    try {
      e.preventDefault();
      handleTextFieldClick();
      if (inputValidation()) return snackBarDialog("ingrese los campos");

      const response = await createAdminTraveller(
        nameTravel.toUpperCase(),
        ageTravel,
        documentTravel,
        countryTravel.toUpperCase(),
        uuid
      );

      snackBarDialog("USUARIO AGREGADO");
      handleAddModalClose();
    } catch (error) {
      handleAddModalClose();
    }
  };

  const columns = [
    { field: "index", headerName: "ID", width: 50 },
    {
      headerName: "NOMBRE",
      field: "name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 130,
    },
    {
      headerName: "APELLIDOS",
      field: "lastname",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "EMAIL",
      field: "email",
      width: 200,
    },
    {
      headerName: "TELEFONO",
      field: "phone",
      width: 110,
    },
    {
      headerName: "PAIS",
      field: "country",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      headerName: "SERVICIOS",
      field: "num_booking",
      width: 90,
      align: "center",
    },
    {
      headerName: "HABILITADO",
      field: "verified",
      width: 80,
      align: "center",
      renderCell: ({ row: { verified } }) => {
        return <p>{verified === 1 ? "SI" : "NO"}</p>;
      },
    },
    {
      headerName: "ESTADO",
      field: "clientStatus",
      width: 90,
      align: "center",
    },
    {
      headerName: "REGISTRADO",
      field: "created_at",
      width: 120,
      align: "center",
      renderCell: ({ row: { created_at } }) => {
        return (
          <div>
            {dateFormat(new Date(created_at))}{" "}
            {timeFormat(new Date(created_at))}
          </div>
        );
      },
    },
    {
      headerName: "OPCIONES",
      field: "actions",
      headerAlign: "center",
      width: 180,
      align: "center",
      renderCell: ({ row: { id, status } }) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                navigate(`/admin/client/${id}`);
              }}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
            <IconButton
              color="success"
              aria-label="travel"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={async () => {
                await getListTravellers(id);
                setOpenTravellerModal(true);
              }}
            >
              <Diversity3Icon />
            </IconButton>

            <IconButton
              color="success"
              aria-label="travel"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={async () => {
                setUuid(id);
                setAddModal(true);
              }}
            >
              <GroupAddOutlinedIcon />
            </IconButton>

            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(id);
                setSTATUS(status);
                setOpenModal(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const updateStatus = async (status) => {
    setIsLoading(true);
    try {
      const resp = await UpdateStatusClientUseCase(UID, status);

      if (resp.message) {
        const userStatus = new UserStatus(UID, UsersEnum.CLIENT, status);
        await UpdateUserStateUseCase(userStatus);
        switch (status) {
          case 1 && 2:
            snackBarDialog("ESTADO ACTUALIZADO");
            clients.map((client) => {
              if (client.id === UID) {
                client.status = status;
                client.clientStatus = StatusEnumName(status);
              }
            });
            break;

          case 3:
            setClients(clients.filter((client) => client.id !== UID));
            snackBarDialog("CONDUCTOR ELIMINADO");
            break;
        }
      } else {
        snackBarDialog("ERROR: NO SE LOGRO ACTUALIZAR EL ESTADO", "error");
      }

      setIsLoading(false);
      setOpenModal(false);
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO SE LOGRO ACTUALIZAR EL ESTADO", "error");
      setIsLoading(false);
    }
  };

  const enabledClient = async () => {
    setIsLoading(true);
    try {
      await enabledClientUseCase(UID);
      snackBarDialog("CLIENTE HABILITADO");
      setIsLoading(false);
      setOpenModal(false);
    } catch (error) {
      snackBarDialog("Error no se podido Habilitar");
      setIsLoading(false);
    }
  };

  const modalTravelStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    p: 1,
    overflow: "scroll",
  };

  const editTraveller = async (traveller) => {
    try {
      setIsLoading(true);
      const token = getTokenRefresh();
      await updateTraveller(token, traveller);
      snackBarDialog("DATOS DE VIAJERO ACTUALIZADO");
      setIsLoading(false);
      // const index = traveller.findIndex(
      //   (travel) => travel.id === putTraveller.id
      // );
      // traveller[index] = putTraveller;
      // handleCloseEditModal();
      // setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      snackBarDialog("ERROR NO SE LOGRO ACTUALIZAR DATOS", "error");
      console.log(error);
    }
  };

  const eliminateTraveller = async (id) => {
    try {
      setIsLoading(true);
      const token = getTokenRefresh();
      await deleteTraveller(token, id);
      // const index = traveller.findIndex((travel) => travel.id === id);
      // traveller.splice(index, 1);
      snackBarDialog("VIAJERO ELIMINADO");
      handleTravellerModalClose();
      setIsLoading(false);
      // setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      snackBarDialog("ERROR NO SE LOGRO ELIMINAR VIAJERO", "error");
      console.log(error);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="LISTA CLIENTES" subtitle="" />
            </Box>
            <Box>
              <Box>
                <DataTable data={clients} columns={columns} />
              </Box>
              <SnackBar
                stateSnk={stateSnk}
                vertical={vertical}
                horizontal={horizontal}
                duration={4000}
                handleClose={handleSnkClose}
                severity={severity}
                messageSnk={messageSnk}
              />
              <Modal open={openModal} onClose={handleModalClose}>
                <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
                  <Typography variant="h6" component="h2" textAlign={"center"}>
                    ELIMINAR CLIENTE?
                  </Typography>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={3}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={enabledClient}
                        disabled={isLoading}
                      >
                        VERIFICAR
                      </Button>
                    </Grid>
                    <Grid item xs={4} md={3} textAlign="center">
                      {STATUS === 1 ? (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => updateStatus(StatusEnum.SUSPENDIDO)}
                          disabled={isLoading}
                        >
                          SUSPENDER
                        </Button>
                      ) : (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => updateStatus(StatusEnum.ACTIVO)}
                          disabled={isLoading}
                        >
                          HABILITAR
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={4} md={3} textAlign="center">
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => updateStatus(StatusEnum.ELIMINADO)}
                        disabled={isLoading}
                      >
                        ELIMINAR
                      </Button>
                    </Grid>
                    <Grid item xs={4} md={3} textAlign="center">
                      <Button
                        color="error"
                        variant="contained"
                        onClick={handleModalClose}
                        disabled={isLoading}
                      >
                        CANCELAR
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
              <Modal
                open={openTravellerModal}
                onClose={handleTravellerModalClose}
              >
                <Box
                  backgroundColor={colors.primary[400]}
                  sx={modalTravelStyle}
                  maxHeight={"80%"}
                >
                  <Typography variant="h6" component="h2" textAlign={"center"}>
                    Lista de Viajeros?
                  </Typography>
                  <Grid container spacing={1} alignItems="center">
                    {traveller &&
                      traveller.map((travel, index) => (
                        <Fragment key={index}>
                          <Grid item xs={12} md={12} textAlign="center">
                            <TextField
                              value={travel.name}
                              onChange={(e) => {
                                const newTraveller = [...traveller];
                                newTraveller[index].name = e.target.value;
                                setTraveller(newTraveller);
                              }}
                              fullWidth
                              type="text"
                              label={t("Nombre")}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2} textAlign="center">
                            <TextField
                              value={travel.age}
                              onChange={(e) => {
                                const newTraveller = [...traveller];
                                newTraveller[index].age = e.target.value;
                                setTraveller(newTraveller);
                              }}
                              fullWidth
                              type="text"
                              label={t("Edad")}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={5} textAlign="center">
                            <TextField
                              value={travel.country}
                              onChange={(e) => {
                                const newTraveller = [...traveller];
                                newTraveller[index].country = e.target.value;
                                setTraveller(newTraveller);
                              }}
                              fullWidth
                              type="text"
                              label={t("Nacionalidad")}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={5} textAlign="center">
                            <TextField
                              value={travel.document}
                              onChange={(e) => {
                                const newTraveller = [...traveller];
                                newTraveller[index].document = e.target.value;
                                setTraveller(newTraveller);
                              }}
                              fullWidth
                              type="text"
                              label={t("DNI")}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} textAlign="center">
                            <Button
                              fullWidth
                              variant="contained"
                              color="success"
                              onClick={(e) => {
                                e.preventDefault();
                                editTraveller(travel);
                              }}
                              disabled={isLoading}
                            >
                              EDITAR
                            </Button>
                          </Grid>
                          <Grid item xs={12} md={6} textAlign="center">
                            <Button
                              fullWidth
                              variant="contained"
                              color="error"
                              onClick={(e) => {
                                e.preventDefault();
                                eliminateTraveller(travel.id);
                              }}
                              disabled={isLoading}
                            >
                              ELIMINAR
                            </Button>
                          </Grid>
                        </Fragment>
                      ))}
                  </Grid>
                </Box>
              </Modal>

              {/* AGREGAR TRAVEL */}
              <Modal open={addModal} onClose={handleAddModalClose}>
                <Box
                  backgroundColor={colors.primary[400]}
                  sx={modalTravelStyle}
                  maxHeight={"80%"}
                >
                  <Typography variant="h6" component="h2" textAlign={"center"}>
                    AGREGAR VIAJERO
                  </Typography>
                  <Grid container spacing={1} alignItems="center">
                    <form>
                      <Box gap="20px" sx={{ margin: "5px" }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              value={nameTravel}
                              type="text"
                              label={"NOMBRE"}
                              name="nameTravel"
                              onChange={changeNameTravel}
                              fullWidth
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              error={checkName()}
                              helperText={
                                checkName() ? "Ingrese su Nombre" : ""
                              }
                              onClick={handleTextFieldClick}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              value={ageTravel}
                              type="number"
                              label={"EDAD"}
                              name="ageTravel"
                              onChange={changeAgeTravel}
                              error={checkAge()}
                              helperText={checkAge() ? "Edad" : ""}
                              onClick={handleTextFieldClick}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              value={countryTravel}
                              type="text"
                              label={"country"}
                              name="countryTravel"
                              onChange={changeCountryTravel}
                              fullWidth
                              error={checkCountry()}
                              helperText={
                                checkCountry() ? "Ingrese su Pais" : ""
                              }
                              onClick={handleTextFieldClick}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <FormControl fullWidth variant="outlined">
                              <InputLabel htmlFor="input-document">
                                DNI
                              </InputLabel>
                              <OutlinedInput
                                value={documentTravel}
                                name="documentTravel"
                                maxRows={1}
                                id="control-dni"
                                onChange={changeDocumentTravel}
                                inputProps={{ maxLength: 16 }}
                                error={checkDocument()}
                                onClick={handleTextFieldClick}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle visibility"
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                      onClick={searchDni}
                                    >
                                      <SendOutlinedIcon />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="DNI"
                              />
                              {checkDocument() ? (
                                <FormHelperText sx={{ color: "red" }}>
                                  Ingrese DNI/Passaport
                                </FormHelperText>
                              ) : (
                                <></>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Button
                              fullWidth
                              type="submit"
                              color="success"
                              variant="contained"
                              onClick={addTraveller}
                            >
                              agregar
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>

                      {/* <SnackBar
          stateSnk={stateSnk}
          vertical={vertical}
          horizontal={horizontal}
          duration={4000}
          handleClose={handleCloseSnk}
          severity={severity}
          messageSnk={messageSnk}
        /> */}
                    </form>
                  </Grid>
                </Box>
              </Modal>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default ListClients;
