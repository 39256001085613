import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import cambayRegular from "../../../../font/CambayRegular.ttf";

Font.register({
  family: "Cambria",
  src: cambayRegular,
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  container: {
    textAlign: "center",
    width: "100%",
    marginTop: "5px",
  },
  text: {
    color: "#000000",
    fontSize: 15,
    fontFamily: "Cambria",
    textDecoration: "underline",
  },
});

const Title = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>CONTRATO DE ARRENDAMIENTO Y OPERACIÓN</Text>
    </View>
  );
};

export default Title;
