import { readSubscribedById } from "../../../data/repository/scheduled.repository";

export const ReadSubscribedByIdUseCase = async (id) => {
  try {
    const resp = await readSubscribedById(id);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
