import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Stack,
  CssBaseline,
  Modal,
  Typography,
  Grid,
  Button,
  useTheme,
  TextField,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Popper,
  Paper,
} from "@mui/material";
import PropTypes from "prop-types";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { getTokenRefresh } from "../../../../helpers/token/refreshToken";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { tokens } from "../../../../theme";
import { modalStyle } from "./DriverStyle";
import {
  createDriverPayment,
  getDrivers,
} from "../../../../api/users/driver/driver";
import { DataGrid, esES } from "@mui/x-data-grid";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";
import {
  StatusEnum,
  StatusEnumName,
} from "../../../../helpers/constants/Status";
import { UsersEnum } from "../../../../helpers/constants/Users";
import { UserStatus } from "../../../../module_firebase/domain/usecases/status/model/UserStatusModel";
import { UpdateUserStateUseCase } from "../../../../module_firebase/domain/usecases/status/UserStatusUseCase";
import { DeleteDriverUseCase } from "./domain/DeleteDriverUseCase";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const ListDrivers = () => {
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [drivers, setDrivers] = useState([]);
  const [UID, setUID] = useState("");
  const [STATUS, setSTATUS] = useState("");
  const [FULLNAME, setFULLNAME] = useState("");
  const [DEBT, setDEBT] = useState(0);
  const [notes, setNotes] = useState("");
  const [pay, setPay] = useState(0);

  useEffect(() => {
    const listDrivers = async () => {
      try {
        const token = getTokenRefresh();
        if (token) {
          const response = await getDrivers(token);
          setDrivers([]);
          console.log(response)
          let count = 0;
          Object.values(response.data).map((driver) => {
            if (driver.status !== 3 && driver.approved) {
              let plates = "";
              let bodyworks = "";
              Object.values(driver.vehicle).map((v) => {
                plates = plates + v.vehicle_plate + ", ";
                bodyworks = bodyworks + v.vehicle_bodywork + ", ";
              });
              driver.plates = plates;
              driver.bodyworks = bodyworks;
              driver.index = ++count;
              driver.driverStatus = StatusEnumName(driver.status);
              setDrivers((olData) => [...olData, driver]);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    listDrivers();
  }, []);

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };

  const columns = [
    { headerName: "ID", field: "index", width: 50 },
    {
      headerName: "NOMBRE",
      field: "fullname",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
      renderCell: ({ row: { ...props } }) => {
        const url = `https://api.whatsapp.com/send?=&text=*Conductor:* ${props.fullname}%0A*DNI:* ${props.dni}%0A*Placa:* ${props.vehicle[0].vehicle_plate}%0A*Carroceria:* ${props.vehicle[0].vehicle_bodywork}%0A*Telefono:* ${props.phone}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 14,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {props.fullname}
          </a>
        );
      },
    },
    {
      headerName: "EMAIL",
      field: "email",
      width: 180,
    },
    {
      headerName: "TELEFONO",
      field: "phone",
      width: 100,
    },
    {
      headerName: "DIRECCION",
      field: "address",
      width: 100,
      renderCell: renderCellExpand,
    },
    {
      headerName: "DNI",
      field: "dni",
      width: 80,
    },
    {
      headerName: "PLACA",
      field: "plates",
      width: 80,
      renderCell: renderCellExpand,
    },
    {
      headerName: "CARROCERIA",
      field: "bodyworks",
      width: 80,
      renderCell: renderCellExpand,
    },
    {
      headerName: "EDAD",
      field: "date_of_birth",
      width: 50,
    },
    {
      headerName: "ESTADO",
      field: "driverStatus",
      width: 90,
    },
    {
      headerName: "RESERVAS",
      field: "bookings",
      width: 80,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row: { bookings } }) => {
        return (
          <div
            style={{
              color: bookings == 1 ? "white" : "red",
              fontSize: 18,
              width: "100%",
              textAlign: "center",
            }}
          >
            {bookings == 1 ? "SI" : "NO"}
          </div>
        );
      },
    },
    {
      headerName: "DEUDA",
      field: "total_amount",
      width: 90,
      renderCell: ({ row: { total_amount } }) => {
        return (
          <div
            style={{
              color: total_amount >= 0 ? "white" : "red",
              fontSize: 18,
              width: "100%",
              textAlign: "right",
            }}
          >
            {(Math.round(total_amount * 100) / 100).toFixed(2)}
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 260,
      renderCell: ({ row: { id, fullname, total_amount, status } }) => {
        return (
          <Stack direction="row" spacing={0.5}>
            {/* HISTORIAL */}
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                navigate(`/admin/driver-history/${id}`, {
                  state: { fullname },
                });
              }}
            >
              <HistoryOutlinedIcon />
            </IconButton>
            {/* HISTORIAL DE PAGOS */}
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                navigate(`/admin/driver-history-fixed/${id}`, {
                  state: { fullname },
                });
              }}
            >
              <LocalMallOutlinedIcon />
            </IconButton>
            {/* COBRAR/PAGAR */}
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                setUID(id);
                setSTATUS(status);
                setFULLNAME(fullname);
                setDEBT(total_amount);
                setOpenModalDebt(true);
              }}
            >
              <CurrencyExchangeOutlinedIcon />
            </IconButton>
            {/* EDITAR */}
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                navigate(`/admin/driver/${id}`, {
                  state: { fullname },
                });
              }}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
            {/* ELIMINAR */}
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(id);
                setSTATUS(status);
                setOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
            {/* EDITAR */}
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                navigate(`/admin/driver-rate/${id}`, {
                  state: { fullname },
                });
              }}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const [open, setOpen] = useState(false);
  const handleModalClose = () => setOpen(false);

  const [openModalDebt, setOpenModalDebt] = useState(false);
  const handleModalDebtClose = () => setOpenModalDebt(false);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const updateStatusDriver = async (status) => {
    setIsLoading(true);
    try {
      const resp = await DeleteDriverUseCase(UID, status);
      if (resp.message === "SUCCESS") {
        const userStatus = new UserStatus(UID, UsersEnum.DRIVER, status);
        await UpdateUserStateUseCase(userStatus);

        switch (status) {
          case 1 && 2:
            drivers.map((driver) => {
              if (driver.id === UID) {
                driver.status = status;
                driver.driverStatus = StatusEnumName(status);
              }
            });
            snackBarDialog("ESTADO ACTUALIZADO");
            break;
          case 3:
            setDrivers(drivers.filter((driver) => driver.id !== UID));
            snackBarDialog("CONDUCTOR ELIMINADO");
            break;
        }
      } else snackBarDialog("ERROR: NO SE LOGRO REALIZAR EL CAMBIO");

      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      snackBarDialog("ERROR: NO SE LOGRO REALIZAR EL CAMBIO");
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateDriverPayment = async (payment, notes) => {
    try {
      if (value) {
        setIsLoading(true);
        console.log(UID, payment, notes);
        const token = "";
        if (value === "cobrar") {
          await createDriverPayment(token, UID, -payment, notes.toUpperCase());
        } else {
          await createDriverPayment(token, UID, payment, notes.toUpperCase());
        }
        snackBarDialog("ESTADO ACTUALIZADO");
        drivers.map((driver) => {
          if (driver.id === UID) {
            if (value === "cobrar") {
              driver.total_amount =
                Math.round((Number(DEBT) + Number(pay)) * 10) / 10;
            } else {
              driver.total_amount =
                Math.round((Number(DEBT) - Number(pay)) * 10) / 10;
            }
          }
        });
        setDEBT(0);
        setPay(0);
        setFULLNAME("");
        setIsLoading(false);
        setOpenModalDebt(false);
      } else {
        snackBarDialog("SELECCIONE OPERACION", "error");
      }
    } catch (error) {
      snackBarDialog("ERROR NO SE GUARDO EL COBRO/PAGO", "error");
      console.log(error);
      setIsLoading(false);
    }
  };

  const [value, setValue] = React.useState(null);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="LISTA DE CONDUCTORES" subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                {/* <DataTable data={drivers} columns={columns} /> */}
                <Box
                  m="10px 0 0 0"
                  height="100vh"
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .name-column--cell": {
                      color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: colors.blueAccent[700],
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                      borderTop: "none",
                      backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                  }}
                >
                  <DataGrid
                    getRowId={(row) => row.index}
                    rows={drivers}
                    columns={columns}
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                  />
                </Box>
              </Box>
            </Box>
            {/* DELETE */}
            <Modal open={open} onClose={handleModalClose}>
              <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
                <Typography variant="h6" component="h2" textAlign={"center"}>
                  ELIMINAR CONDUCTOR?
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4} md={4} textAlign="center">
                    {STATUS === 1 ? (
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() =>
                          updateStatusDriver(StatusEnum.SUSPENDIDO)
                        }
                        disabled={isLoading}
                      >
                        SUSPENDER
                      </Button>
                    ) : (
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => updateStatusDriver(StatusEnum.ACTIVO)}
                        disabled={isLoading}
                      >
                        HABILITAR
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={4} md={4} textAlign="center">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => updateStatusDriver(StatusEnum.ELIMINADO)}
                      disabled={isLoading}
                    >
                      ELIMINAR
                    </Button>
                  </Grid>
                  <Grid item xs={4} md={4} textAlign="center">
                    <Button
                      color="error"
                      variant="contained"
                      onClick={handleModalClose}
                      disabled={isLoading}
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* DELETE */}
            <Modal
              open={openModalDebt}
              onClose={handleModalDebtClose}
              aria-labelledby="modal-debt-title"
            >
              <Box
                sx={{
                  ...modalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1.5}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-debt-title">PAGO</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={FULLNAME}
                      fullWidth
                      disabled
                      label="CONDUCTOR"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      value={DEBT.toFixed(2)}
                      fullWidth
                      disabled
                      label="DEBE"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      value={notes}
                      fullWidth
                      label="NOTAS"
                      variant="outlined"
                      name="notes"
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="radio-buttons">OPERACION</FormLabel>
                      <RadioGroup
                        row
                        name="radio-buttons"
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="pagar"
                          control={<Radio />}
                          label="PAGAR"
                        />
                        <FormControlLabel
                          value="cobrar"
                          control={<Radio />}
                          label="COBRAR"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      value={pay}
                      fullWidth
                      type="number"
                      label={
                        value === "pagar" ? "MONTO A PAGAR" : "MONTO A COBRAR"
                      }
                      variant="outlined"
                      name="pay"
                      onChange={(e) => {
                        setPay(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        console.log(UID, pay);
                        updateDriverPayment(pay, notes);
                      }}
                      disabled={isLoading}
                    >
                      {value === "pagar" ? "pagar" : "cobrar"}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={5000}
              handleClose={handleSnkClose}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </main>
      </div>
    </>
  );
};

export default ListDrivers;
