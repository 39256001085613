import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Header from "../../../../components/header/Header";
import Topbar from "../../../../components/topbar/TopBar";
import { VisuallyHiddenInput } from "../components/VisuallyHiddenInput";
import { MetaTypes } from "../../../../helpers/constants/Metadata";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { CreateCountryUseCase } from "../../../domain/usecases/country/CreateCountryUseCase";

const CountryCreateScreen = () => {
  const [country, setCountry] = useState("");
  const [abbreviature, setAbbreviature] = useState("");
  const [mainImage, setMainImage] = useState(null);
  const [imageAlt, setImageAlt] = useState("");
  const [imageTitle, setImageTitle] = useState("");
  const [searchCheck, setSearchCheck] = useState(false);

  const [titlePage, setTitlePage] = useState("");
  const [metaList, setMetaList] = useState([
    { type: "", typeContent: "", content: "" },
  ]);
  const [aux1, setAux1] = useState("");
  const [aux2, setAux2] = useState("");
  const [aux3, setAux3] = useState("");

  const handleServiceAdd = () => {
    const size = metaList.length - 1;
    if (
      metaList[size].type !== "" &&
      metaList[size].typeContent !== "" &&
      metaList[size].content !== ""
    ) {
      setMetaList([...metaList, { type: "", typeContent: "", content: "" }]);
      setAux1("");
      setAux2("");
      setAux3("");
    } else {
      snackBarDialog("INGRESE EL CAMPO PARA SEGUIR CREANDO", "error");
    }
  };

  const handleServiceRemove = (index) => {
    const list = [...metaList];
    list.splice(index, 1);
    setMetaList(list);
  };

  const addServiceType = (value, index) => {
    metaList[index].type = value;
  };

  const addServiceMeta = (value, index) => {
    metaList[index].content = value;
  };

  const addServiceTypeContent = (value, index) => {
    metaList[index].typeContent = value;
  };

  const validateCountry = () => {
    if (titlePage === "")
      return snackBarDialog("INGRESE TITULO DE LA PAGINA", "error");

    if (country === "")
      return snackBarDialog("INGRESE NOMBRE DEL PAIS", "error");

    if (abbreviature === "")
      return snackBarDialog("INGRESE LA ABREVIATURA DEL PAIS", "error");

    if (mainImage === "") return snackBarDialog("INGRESE UNA IMAGEN", "error");

    return true;
  };

  const handleCreateCountry = async () => {
    try {
      setIsLoading(true);
      if (validateCountry()) {
        const data = new FormData();
        data.append("mainImage", mainImage);

        const settings = {
          titlePage,
          metaList,
        };

        const item = {
          name: country,
          abbreviature: abbreviature,
          settings: settings,
          showDestination: searchCheck,
          imageAlt: imageAlt,
          imageTitle: imageTitle,
        };

        data.append("country", JSON.stringify(item));
        const resp = await CreateCountryUseCase(data);

        if (resp.message === "success") {
          snackBarDialog("PAIS CREADO CORRECTAMENTE");
        } else {
          snackBarDialog("ERROR: NO SE LOGRO CREAR EL PAIS", "error");
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const position = { vertical: "top", horizontal: "center" };

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={"CREAR PAIS"} subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      value={titlePage}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setTitlePage(e.target.value)}
                      label={"TITULO DE LA PAGINA"}
                      InputLabelProps={{ style: { color: "white" } }}
                      name="titlePage"
                    />
                  </Grid>

                  {metaList.map((service, idx) => (
                    <Fragment key={idx}>
                      <Grid item xs={12} sm={2}>
                        <FormControl fullWidth>
                          <InputLabel>META(tipo)</InputLabel>
                          <Select
                            value={service.type ? service.type : aux1}
                            onChange={(e) => {
                              setAux1(e.target.value);
                              addServiceType(e.target.value, idx);
                            }}
                            label={"META(tipo)"}
                            name="aux1"
                          >
                            {MetaTypes.map((elem, index) => (
                              <MenuItem key={index} value={elem.type}>
                                {elem.type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          value={
                            service.typeContent ? service.typeContent : aux2
                          }
                          fullWidth
                          variant="outlined"
                          type="text"
                          onChange={(e) => {
                            setAux2(e.target.value);
                            addServiceTypeContent(e.target.value, idx);
                          }}
                          label={"Nombre"}
                          InputLabelProps={{
                            style: { color: "white" },
                          }}
                          name="route2"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={service.content ? service.content : aux3}
                          fullWidth
                          variant="outlined"
                          type="text"
                          onChange={(e) => {
                            setAux3(e.target.value);
                            addServiceMeta(e.target.value, idx);
                          }}
                          label={"CONTENIDO"}
                          InputLabelProps={{ style: { color: "white" } }}
                          name="aux3"
                          InputProps={{
                            endAdornment: (
                              <>
                                <button
                                  style={{ marginRight: -5 }}
                                  color="success"
                                  onClick={handleServiceAdd}
                                >
                                  +
                                </button>
                                <button
                                  style={{ marginRight: -5 }}
                                  color="success"
                                  onClick={(e) => handleServiceRemove(idx)}
                                >
                                  -
                                </button>
                              </>
                            ),
                          }}
                        />
                      </Grid>
                    </Fragment>
                  ))}

                  <Grid item xs={12} sm={2}>
                    <TextField
                      value={country}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setCountry(e.target.value)}
                      label={"NOMBRE DEL PAIS"}
                      InputLabelProps={{ style: { color: "white" } }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      name="country"
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      value={abbreviature}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setAbbreviature(e.target.value)}
                      label={"ABREVIATURA"}
                      InputLabelProps={{ style: { color: "white" } }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      name="abbreviature"
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <label style={{ display: "block" }}>PORTADA</label>
                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                    >
                      <VisuallyHiddenInput
                        type="file"
                        name="mainImage"
                        onChange={(e) => setMainImage(e.target.files[0])}
                      />
                    </Button>
                    {mainImage ? mainImage.name : ""}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      value={imageAlt}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setImageAlt(e.target.value)}
                      label={"ALT"}
                      InputLabelProps={{ style: { color: "white" } }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      name="alt"
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      value={imageTitle}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setImageTitle(e.target.value)}
                      label={"Titulo Imagen"}
                      InputLabelProps={{ style: { color: "white" } }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      name="imageTitle"
                    />
                  </Grid>

                  <Grid item xs={6} sm={2}>
                    <label style={{ display: "block" }}>DESTINOS?</label>
                    <FormControlLabel
                      control={<Checkbox color="success" size="small" />}
                      label="Activar"
                      checked={searchCheck}
                      onChange={(e) => setSearchCheck(e.target.checked)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={10} md={10}>
                    <Box display="flex" justifyContent="end" mt="10px">
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleCreateCountry}
                        sx={{
                          width: { xs: "100%", sm: "200px", md: "200px" },
                          height: 45,
                        }}
                        disabled={isLoading}
                      >
                        GUARDAR
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>

          <SnackBar
            stateSnk={stateSnk}
            vertical={position.vertical}
            horizontal={position.horizontal}
            duration={4000}
            handleClose={handleSnkClose}
            severity={severity}
            messageSnk={messageSnk}
          />
        </main>
      </div>
    </>
  );
};

export default CountryCreateScreen;
