const TOUR_STORE = "TOUR_STORE";

export const createTourStore = (tour) => {
  window.localStorage.setItem(TOUR_STORE, JSON.stringify(tour));
};

export const getTourStore = () => {
  const value = window.localStorage.getItem(TOUR_STORE);
  if (!value) return null;

  return JSON.parse(value);
};

export const removeTourStore = () => {
  window.localStorage.removeItem(TOUR_STORE);
};
