export const addressSchema = (yup) => {
  return {
    name: yup.string().required("required"),
    lat: yup.number(),
    lng: yup.number(),
    url: yup.string(),
    radius: yup.number().required("required"),
  };
};

export const addressInitial = {
  name: "",
  lat: 0,
  lng: 0,
  url: "",
  radius: 0,
};
