import { URL_CLIENT } from "../../../helpers/endpoint";
import { baseBackend } from "../../base/baseUrl";

export const getClients = async (token) => {
  return await baseBackend.get(URL_CLIENT, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getClientId = async (token, id) => {
  return await baseBackend.get(`${URL_CLIENT}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateClient = async (token, client) => {
  return await baseBackend.put(URL_CLIENT, JSON.stringify({ client }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateClientApi = async (client_id, status) => {
  return await baseBackend.delete(`${URL_CLIENT}/${client_id}`, {
    data: { status },
    headers: { "Content-Type": "application/json" },
  });
};

export const enabledClientUseCase = async (client_id) => {
  return await baseBackend.get(`${URL_CLIENT}-enabled/${client_id}`, {
    headers: { "Content-Type": "application/json" },
  });
};
