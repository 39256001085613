import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";

import logo from "../../../assets/logo.png";
import { Copyright } from "../../../components/copyright/Copyright";
import Notification from "../../../components/notificarions/AlertNotification";
import { saveToken } from "../../../helpers/token/refreshToken";
import { avatarStyle, boxStyle } from "./LoginStyle";
import { login } from "../../../api/auth/login";

const theme = createTheme();

export default function SignIn() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const emailRequire = t("emailRequire");
  const emailInvalid = t("emailInvalid");
  const passwordRequire = t("passwordRequire");
  const passwordLong = t("passwordLong");

  const checkoutSchema = yup.object().shape({
    email: yup.string().email(emailInvalid).required(emailRequire),
    password: yup.string().required(passwordRequire).min(6, passwordLong),
    // .matches(REGEX_PASSWORD, "Password Error Format "),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const handleFormSubmit = async (values) => {
    try {
      setDisableButton(true);
      console.log(values.email, values.password)
      const response = await login(values.email, values.password);
      const token = response?.data?.token;
      const dataToken = response?.data?.dataToken;

      if (!token) navigate("/");

      saveToken("atknre", token);
      saveToken("adttkn", dataToken);
      navigate("/admin");
    } catch (err) {
      console.log(err)
      setDisableButton(false);
      setErrorMessage(err.response.data.error);
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={boxStyle}>
          <img
            src={logo}
            style={{ margin: 3, width: 152, height: 152 }}
            alt="Logo"
          />
          <Typography component="h1" variant="h5">
            {t("login")}
          </Typography>

          <Box sx={{ mt: 1, width: 350 }}>
            <Notification description={errorMessage} alertType="error" />
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box display="grid" gap="10px" sx={{ margin: "4px" }}>
                    <TextField
                      value={values.name}
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      autoComplete="email"
                      autoFocus
                    />
                    <TextField
                      value={values.password}
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label={t("password")}
                      type="password"
                      id="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      autoComplete="current-password"
                    />
                  </Box>
                  <Box display="flex" justifyContent="end" mt="10px">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      disabled={disableButton}
                    >
                      {t("login")}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
