import { getPictureList } from "../../../data/repository/picture.repo";

export const GetPictureListUseCase = async () => {
  try {
    const resp = await getPictureList();
    return resp.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
