import { getTourById } from "../../../data/repository/tour.repo";

export const GetTourByIdUseCase = async (tourId) => {
  try {
    const response = await getTourById(tourId);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
