import { getListQuestion } from "../../../data/repository/question.repo";

export const GetListQuestionUseCase = async () => {
  try {
    const resp = await getListQuestion();
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
