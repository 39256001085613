import axios from "axios";

const baseBackend = axios.create({ baseURL: "https://api.openpay.pe" });
// const MERCHANT_ID_PEN = "mqt4y63pjj4lhz1jdtpq";
// const OPENPAY_SK_PEN = "sk_9c4d09b8a8394dc29192d3fec7892475";
// const MERCHANT_ID_USD = "mu1gf7jz7zkkucbbfcar";
// const OPENPAY_SK_USD = "sk_0ceb7e5b4c1148c08424a98e8c22f53d";

//pen
const MERCHANT_ID_PEN = "mbcuorbjysurfa0zp1ir";
const OPENPAY_SK_PEN = "sk_d783a147107042d2a58d9208d8b3ac29";
//usd
const MERCHANT_ID_USD = "mpc9dkrlgm8esbtkwcsp";
const OPENPAY_SK_USD = "sk_88881f708de84ae3ac85b44fcd9421f7";

export const generateOpenPayURL = async (bookingOpenPay) => {
  return await baseBackend.post(
    `/v1/${MERCHANT_ID_PEN}/charges`,
    JSON.stringify(bookingOpenPay),
    {
      auth: {
        username: OPENPAY_SK_PEN,
        password: "",
      },
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const checkStatusPayment = async (transaction_id) => {
  return await baseBackend.get(
    `/v1/${MERCHANT_ID_PEN}/charges/${transaction_id}`,
    {
      auth: {
        username: OPENPAY_SK_PEN,
        password: "",
      },
      headers: { "Content-Type": "application/json" },
    }
  );
};
//USD
export const generateOpenPayURLUSD = async (bookingOpenPay) => {
  return await baseBackend.post(
    `/v1/${MERCHANT_ID_USD}/charges`,
    JSON.stringify(bookingOpenPay),
    {
      auth: {
        username: OPENPAY_SK_USD,
        password: "",
      },
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const checkStatusPaymentUSD = async (transaction_id) => {
  return await baseBackend.get(
    `/v1/${MERCHANT_ID_USD}/charges/${transaction_id}`,
    {
      auth: {
        username: OPENPAY_SK_USD,
        password: "",
      },
      headers: { "Content-Type": "application/json" },
    }
  );
};
