import React from "react";
import { useTranslation } from "react-i18next";
import { Box, CssBaseline } from "@mui/material";
import Topbar from "../../../../../components/topbar/TopBar";
import Header from "../../../../../components/header/Header";
import EmployeeForm from "./EmployeeForm";
import { useLocation, useParams } from "react-router-dom";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../components/sidebar/Sidebar";

const EmployeeAdd = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const location = useLocation();
  const enterpriseName = location.state.enterpriseName;

  return (
    <div>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={enterpriseName} subtitle="CREAR COLABORADOR" />
            </Box>

            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="120px"
              gap="0px"
            >
              <Box gridColumn="span 12" gridRow="span 2">
                <EmployeeForm enterprise_id={id} />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};

export default EmployeeAdd;
