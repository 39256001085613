import { getPictureHasTour } from "../../../data/repository/picture.repo";

export const GetPictureHasTourUseCase = async () => {
  try {
    const resp = await getPictureHasTour();
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
