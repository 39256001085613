import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Stack,
  CssBaseline,
  Modal,
  Typography,
  Grid,
  Button,
  useTheme,
  TextField,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Popper,
  Paper,
} from "@mui/material";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import Sidebar from "../../../../../components/sidebar/Sidebar";
import Topbar from "../../../../../components/topbar/TopBar";
import Header from "../../../../../components/header/Header";
import { DataGrid, esES } from "@mui/x-data-grid";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import { tokens } from "../../../../../theme";
import { getTokenRefresh } from "../../../../../helpers/token/refreshToken";
import {
  createDriverPayment,
  deleteDriverApi,
  getDrivers,
  updateApproved,
} from "../../../../../api/users/driver/driver";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { modalStyle } from "./DriverStyle";
import { dateFormat } from "../../../../../helpers/date/date";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const ListNewDriver = () => {
  const { t } = useTranslation();
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [drivers, setDrivers] = useState([]);
  const [UID, setUID] = useState("");
  const [STATUS, setSTATUS] = useState("");
  const driverStatus = {
    1: "Activo",
    2: "Suspendido",
    3: "Eliminado",
  };

  useEffect(() => {
    const listDrivers = async () => {
      try {
        const token = getTokenRefresh();
        if (token) {
          const response = await getDrivers(token);
          console.log(response.data);
          setDrivers([]);
          let count = 0;
          Object.values(response.data).map((driver) => {
            if (driver.status !== 3 && Boolean(driver.approved) === false) {
              let plates = "";
              let bodyworks = "";
              Object.values(driver.vehicle).map((v) => {
                plates = plates + v.vehicle_plate + ", ";
                bodyworks = bodyworks + v.vehicle_bodywork + ", ";
              });
              driver.plates = plates;
              driver.bodyworks = bodyworks;
              driver.index = ++count;
              driver.driverStatus = driverStatus[driver.status];
              setDrivers((olData) => [...olData, driver]);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    listDrivers();
  }, []);

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };

  const columns = [
    { headerName: "ID", field: "index", width: 50 },
    {
      headerName: "NOMBRE",
      field: "fullname",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "EMAIL",
      field: "email",
      width: 180,
    },
    {
      headerName: "TELEFONO",
      field: "phone",
      width: 100,
    },
    {
      headerName: "DIRECCION",
      field: "address",
      width: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: "DNI",
      field: "dni",
      width: 80,
    },
    {
      headerName: "PLACA",
      field: "plates",
      width: 80,
      renderCell: renderCellExpand,
    },
    {
      headerName: "CARROCERIA",
      field: "bodyworks",
      width: 80,
      renderCell: renderCellExpand,
    },
    {
      headerName: "EDAD",
      field: "date_of_birth",
      width: 50,
    },
    {
      headerName: "F. REGISTRO",
      field: "created_at",
      width: 90,
      renderCell: ({ row: { created_at } }) => {
        const date = dateFormat(new Date(created_at));
        return date;
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 220,
      renderCell: ({ row: { id, fullname, status } }) => {
        return (
          <Stack direction="row" spacing={0.5}>
            {/* EDITAR */}
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                navigate(`/admin/driver/${id}`, {
                  state: { fullname },
                });
              }}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
            {/* APROBAR */}
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                setUID(id);
                setOpenApproved(true);
              }}
            >
              <CheckCircleOutlinedIcon />
            </IconButton>

            {/* ELIMINAR */}
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(id);
                setSTATUS(status);
                setOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const [open, setOpen] = useState(false);
  const handleModalClose = () => setOpen(false);

  const [openApprovedModal, setOpenApproved] = useState(false);
  const closeApprovedModal = () => setOpenApproved(false);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const deleteVehicle = async () => {
    console.log("delete");
    setIsLoading(true);
    try {
      await deleteDriverApi(UID, 3);
      snackBarDialog("CONDUCTOR ELIMINADO");
      setDrivers(drivers.filter((driver) => driver.id !== UID));
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      snackBarDialog("error no se ha eliminado", "error");
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateStatusDriver = async (status) => {
    setIsLoading(true);
    try {
      await deleteDriverApi(UID, status);
      snackBarDialog("ESTADO ACTUALIZADO");
      drivers.map((driver) => {
        if (driver.id === UID) {
          driver.status = status;
          driver.driverStatus = driverStatus[status];
        }
      });
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      snackBarDialog("Error no se podido Suspender", "error");
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateApprovedDriver = async () => {
    setIsLoading(true);
    try {
      const token = getTokenRefresh();
      const driver = {
        id: UID,
        approved: true,
      };
      await updateApproved(token, driver);
      snackBarDialog("CONDUCTOR APROBADO");
      setDrivers(drivers.filter((driver) => driver.id !== UID));
      setIsLoading(false);
      setOpenApproved(false);
    } catch (error) {
      snackBarDialog("ERROR: No se logro aprobar al conductor", "error");
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="NUEVOS CONDUCTORES" subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <Box
                  m="10px 0 0 0"
                  height="100vh"
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .name-column--cell": {
                      color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: colors.blueAccent[700],
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                      borderTop: "none",
                      backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                  }}
                >
                  <DataGrid
                    getRowId={(row) => row.index}
                    rows={drivers}
                    columns={columns}
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                  />
                </Box>
              </Box>
            </Box>
            {/* DELETE */}
            <Modal open={open} onClose={handleModalClose}>
              <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
                <Typography variant="h6" component="h2" textAlign={"center"}>
                  ELIMINAR CONDUCTOR?
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4} md={4} textAlign="center">
                    {STATUS === 1 ? (
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => updateStatusDriver(2)}
                        disabled={isLoading}
                      >
                        SUSPENDER
                      </Button>
                    ) : (
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => updateStatusDriver(1)}
                        disabled={isLoading}
                      >
                        HABILITAR
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={4} md={4} textAlign="center">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={deleteVehicle}
                      disabled={isLoading}
                    >
                      ELIMINAR
                    </Button>
                  </Grid>
                  <Grid item xs={4} md={4} textAlign="center">
                    <Button
                      color="error"
                      variant="contained"
                      onClick={handleModalClose}
                      disabled={isLoading}
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>

            <Modal open={openApprovedModal} onClose={closeApprovedModal}>
              <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
                <Typography variant="h6" component="h2" textAlign={"center"}>
                  APROBAR CONDUCTOR?
                </Typography>
                <Grid container spacing={1} alignItems="center" marginTop={1}>
                  <Grid item xs={6} md={6} textAlign="center">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => updateApprovedDriver()}
                      disabled={isLoading}
                    >
                      APROBAR
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6} textAlign="center">
                    <Button
                      color="error"
                      variant="contained"
                      onClick={closeApprovedModal}
                      disabled={isLoading}
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>

            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={5000}
              handleClose={handleSnkClose}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </main>
      </div>
    </>
  );
};
export default ListNewDriver;
