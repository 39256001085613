import { baseBackend } from "../../../api/base/baseUrl";

const URL_PICTURE = "/api/v1/module/image";

export const createPictureList = async (picture) => {
  return await baseBackend.post(URL_PICTURE, picture, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const createPictureTour = async (relation) => {
  return await baseBackend.post(
    `${URL_PICTURE}-tour`,
    JSON.stringify({ relation }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getPictureList = async () => {
  return await baseBackend.get(`${URL_PICTURE}/list`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getPictureHasTour = async () => {
  return await baseBackend.get(`${URL_PICTURE}-has-tour`, {
    headers: { "Content-Type": "application/json" },
  });
};