import { readDriverList } from "../../../../api/users/driver/driver";

export const ReadDriverListUseCase = async () => {
  try {
    const resp = await readDriverList();
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
