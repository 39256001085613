import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../../../components/header/Header";
import Topbar from "../../../../../components/topbar/TopBar";
import {
  Box,
  CssBaseline,
  Switch,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  TableBody,
  Paper,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  createPermission,
  getPermissions,
  updatePermission,
} from "../../../../../api/resources/permissions/permissions";

export const AddPermissions = () => {
  const { id } = useParams();
  const [listPermissions, setListPermisions] = useState(null);
  const [statePermission, setStatePermission] = useState(false);

  useEffect(() => {
    const getListPermissions = async () => {
      try {
        const response = await getPermissions("token", id);
        if (response.data.length > 0) {
          setListPermisions(response.data);
          setStatePermission(true);
        } else {
          setListPermisions(permissions);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getListPermissions();
  }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [checked, setChecked] = useState(false);
  const handleChange = (event, index) => {
    //creation = event.target.checked;
    permissions[index].creation = event.target.checked;
    setChecked(event.target.checked);
    // console.log(creation);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 12,
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const permissions = [
    {
      name: "CONDUCTOR",
      reading: false,
      creation: false,
      actualize: false,
      elimination: false,
    },
    {
      name: "COMISION CONDUCTOR",
      reading: false,
      creation: false,
      actualize: false,
      elimination: false,
    },
    {
      name: "EMPRESAS",
      reading: false,
      creation: false,
      actualize: false,
      elimination: false,
    },
    {
      name: "CLIENTES",
      reading: false,
      creation: false,
      actualize: false,
      elimination: false,
    },
    {
      name: "VEHICULOS",
      reading: false,
      creation: false,
      actualize: false,
      elimination: false,
    },
    {
      name: "PEDIDOS CLIENTES",
      reading: false,
      creation: false,
      actualize: false,
      elimination: false,
    },
    {
      name: "PEDIDOS EMPRESA",
      reading: false,
      creation: false,
      actualize: false,
      elimination: false,
    },
    {
      name: "COTIZACION CLIENTE",
      reading: false,
      creation: false,
      actualize: false,
      elimination: false,
    },
    {
      name: "COTIZACION EMPRESA",
      reading: false,
      creation: false,
      actualize: false,
      elimination: false,
    },
    {
      name: "RESERVA CLIENTE",
      reading: false,
      creation: false,
      actualize: false,
      elimination: false,
    },
    {
      name: "RESERVA EMPRESA",
      reading: false,
      creation: false,
      actualize: false,
      elimination: false,
    },
    {
      name: "FACTURA EMPRESA",
      reading: false,
      creation: false,
      actualize: false,
      elimination: false,
    },
    {
      name: "HISTORIAL CLIENTES",
      reading: false,
      creation: false,
      actualize: false,
      elimination: false,
    },
    {
      name: "HISTORIAL EMPRESAS",
      reading: false,
      creation: false,
      actualize: false,
      elimination: false,
    },
  ];

  const creationPermissions = async () => {
    try {
      if (statePermission) {
        console.log(listPermissions);
        await updatePermission("token", listPermissions);
      } else {
        await createPermission("token", id, 1, listPermissions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginLeft={2}
            >
              <Header title="PERMISOS" subtitle="" />
            </Box>
            <Box>
              <Container component="main" maxWidth="lg">
                {listPermissions && (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 400 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>{}</StyledTableCell>
                          <StyledTableCell>PERMISOS</StyledTableCell>
                          <StyledTableCell>CREAR</StyledTableCell>
                          <StyledTableCell>EDITAR</StyledTableCell>
                          <StyledTableCell>VISUALIZAR</StyledTableCell>
                          <StyledTableCell>ELIMINAR</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listPermissions.map((row, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Switch
                                {...label}
                                checked={row.creation}
                                onChange={(e) => {
                                  const newData = [...listPermissions];
                                  newData[index].creation = e.target.checked;
                                  setListPermisions(newData);
                                }}
                                color="secondary"
                              />
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Switch
                                {...label}
                                checked={row.actualize}
                                onChange={(e) => {
                                  const newData = [...listPermissions];
                                  newData[index].actualize = e.target.checked;
                                  setListPermisions(newData);
                                }}
                                color="secondary"
                              />
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Switch
                                {...label}
                                checked={row.reading}
                                onChange={(e) => {
                                  const newData = [...listPermissions];
                                  newData[index].reading = e.target.checked;
                                  setListPermisions(newData);
                                }}
                                color="secondary"
                              />
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Switch
                                {...label}
                                checked={row.elimination}
                                onChange={(e) => {
                                  const newData = [...listPermissions];
                                  newData[index].elimination = e.target.checked;
                                  setListPermisions(newData);
                                }}
                                color="secondary"
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                <Button
                  variant="contained"
                  color="success"
                  onClick={creationPermissions}
                >
                  GUARDAR CAMBIOS
                </Button>
              </Container>

              {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    defaultValue="Conductores"
                    size="small"
                    disabled
                    variant="standard"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Switch {...label} defaultChecked color="secondary" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Switch {...label} defaultChecked color="secondary" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Switch {...label} defaultChecked color="secondary" />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Switch {...label} defaultChecked color="secondary" />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Switch {...label} defaultChecked color="secondary" />
                </Grid>
              </Grid> */}
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};
