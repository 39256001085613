import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  Page,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import Title from "./report/elements/Title";
import DocumentName from "./report/elements/DocumentName";
import Contact from "./report/elements/Contact";
import Table from "./report/elements/Table";
import EnterpriseData from "../../../../components/datatable/pdf/elements/EnterpriseData";
import TableHorizontal from "./report/elements/TableHorizontal";

const ReportHistory = ({ userData, invoice, enterpriseData }) => {
  const [tableData, setTableData] = useState(null);
  useEffect(() => {
    const total = invoice.sumTotal;
    const toll = invoice.sumToll;
    let imp = 0;
    let totalPay = 0;
    let subTotal = 0;
    if (invoice.igv === "MAS IGV") {
      imp = Math.round((Number(total) + Number(toll)) * 0.18 * 100) / 100;
      subTotal = Math.round((Number(total) + Number(toll)) * 100) / 100;
      totalPay =
        Math.round((Number(total) + Number(toll) + Number(imp)) * 100) / 100;
    } else if (invoice.igv === "INAFECTO") {
      totalPay = Math.round((Number(total) + Number(toll)) * 100) / 100;
    } else if (invoice.igv === "AFECTO") {
      subTotal = Math.round((total / 1.18) * 100) / 100;
      imp = Math.round((total - subTotal) * 100) / 100;
      totalPay = Math.round((Number(total) + Number(toll)) * 100) / 100;
    }

    setTableData([]);
    setTableData({
      id: "5df3180a09ea16dc4b95f910",
      invoice_no: "201906-28",
      company: "TAXIMUNDO",
      email: "info@taximundo.com",
      phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
      address: "Servicios Generales Trans Mundo E.I.R.L.",
      items: userData,
      igv: imp,
      total: totalPay,
      peaje: toll,
      subTotal: subTotal,
      from: userData.length > 0 ? userData[0].date : "",
      to: userData.length > 0 ? userData[userData.length - 1].date : "",
    });
  }, [userData]);

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    pageHorizontal: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });
  const [value, setValue] = useState("vertical");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      {tableData && (
        <>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="horizontal"
                control={<Radio color="success" />}
                label="Horizontal"
                sx={{ color: "white" }}
              />
              <FormControlLabel
                value="vertical"
                control={<Radio color="success" />}
                label="Vertical"
                sx={{ color: "white" }}
              />
            </RadioGroup>
          </FormControl>
          {value === "vertical" && (
            <PDFDownloadLink
              document={
                <Document>
                  <Page size="A4" style={styles.page}>
                    <Title title="Taxi Mundo PERU" />
                    <Contact invoice={tableData} />
                    <DocumentName
                      title={"DETALLE DE SERVICIOS"}
                      from={tableData.from}
                      to={tableData.to}
                    />
                    <EnterpriseData selectEnterprise={enterpriseData} />
                    <Table invoice={tableData} />
                  </Page>
                </Document>
              }
              fileName={"detalles-taximundo.pdf"}
            >
              <Button color="secondary" variant="contained">
                Descargar
              </Button>
            </PDFDownloadLink>
          )}
          {value === "horizontal" && (
            <PDFDownloadLink
              document={
                <Document>
                  <Page size={[841.89, 595.28]} style={styles.pageHorizontal}>
                    <Title title="Taxi Mundo PERU" />
                    <Contact invoice={tableData} />
                    <DocumentName
                      title={"DETALLE DE SERVICIOS"}
                      from={tableData.from}
                      to={tableData.to}
                    />
                    <EnterpriseData selectEnterprise={enterpriseData} />
                    <TableHorizontal invoice={tableData} />
                  </Page>
                </Document>
              }
              fileName={"detalles-taximundo.pdf"}
            >
              <Button color="secondary" variant="contained">
                Descargar
              </Button>
            </PDFDownloadLink>
          )}
        </>
      )}
    </>
  );
};
export default ReportHistory;
