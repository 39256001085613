import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

import Topbar from "../../../../../components/topbar/TopBar";
import Header from "../../../../../components/header/Header";
import DataTable from "../../../../../components/datatable/DataTable";
import { getTokenRefresh } from "../../../../../helpers/token/refreshToken";
import {
  deleteEmployeeApi,
  getEmployeesFromEnterprise,
  verifyEmployeeServer,
} from "../../../../../api/users/employee/employeeApi";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import { tokens } from "../../../../../theme";
import { modalStyle } from "../EmployeeStyle";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../components/sidebar/Sidebar";
import {
  StatusEnum,
  StatusEnumName,
} from "../../../../../helpers/constants/Status";
import { UserStatus } from "../../../../../module_firebase/domain/usecases/status/model/UserStatusModel";
import { UpdateUserStateUseCase } from "../../../../../module_firebase/domain/usecases/status/UserStatusUseCase";
import { UsersEnum } from "../../../../../helpers/constants/Users";
import { UpdateStatusEmployeeUseCase } from "../domain/UpdateStatusEmployeeUseCase";

const EmployeeList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const [UID, setUID] = useState("");
  const [STATUS, setSTATUS] = useState("");
  const [verify, setVerify] = useState(0);
  const { id } = useParams();
  const [employees, setEmployees] = useState([]);
  const location = useLocation();
  const enterpriseName = location.state.enterpriseName;

  useEffect(() => {
    const getEmployees = async () => {
      try {
        const token = getTokenRefresh();
        const response = await getEmployeesFromEnterprise(token, id);
        setEmployees([]);
        let count = 0;
        Object.values(response?.data).map((employee) => {
          if (employee.status !== 3) {
            employee.index = ++count;
            employee.employeeStatus = StatusEnumName(employee.status);
            setEmployees((oldData) => [...oldData, employee]);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    getEmployees();
  }, []);

  const columns = [
    { field: "index", headerName: "ID", width: 50 },
    {
      headerName: "NOMBRE",
      field: "name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 130,
    },
    {
      headerName: "APELLIDOS",
      field: "lastname",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "DNI",
      field: "dni",
      width: 100,
    },
    {
      headerName: "EMAIL",
      field: "email",
      width: 200,
    },
    {
      headerName: "TELEFONO",
      field: "phone",
      width: 110,
    },
    {
      headerName: "ROL",
      field: "subrole_name",
      width: 110,
    },
    {
      headerName: "SEDE",
      field: "sede_name",
      width: 110,
    },

    {
      headerName: "ESTADO",
      field: "employeeStatus",
      width: 100,
    },
    {
      headerName: "VERIFICADO",
      headerAlign: "center",
      field: "verified",
      align: "center",
      width: 100,
      renderCell: ({ row: { verified } }) => {
        return <p>{verified == 0 ? "NO" : "SI"}</p>;
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 120,
      align: "center",
      renderCell: ({ row: { id, status, verified } }) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                navigate(`/admin/employee/${id}`);
              }}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(id);
                setSTATUS(status);
                setVerify(verified);
                setOpenModal(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const handleModalClose = () => setOpenModal(false);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const updateStatus = async (status) => {
    try {
      const resp = await UpdateStatusEmployeeUseCase(UID, status);

      if (resp.message === "SUCCESS") {
        const userStatus = new UserStatus(UID, UsersEnum.EMPLOYEE, status);
        await UpdateUserStateUseCase(userStatus);

        switch (status) {
          case 1 && 2:
            employees.map((employee) => {
              if (employee.id === UID) {
                employee.status = status;
                employee.employeeStatus = StatusEnumName(status);
              }
            });
            snackBarDialog("ESTADO ACTUALIZADO");
            break;
          case 3:
            setEmployees(employees.filter((employee) => employee.id !== UID));
            snackBarDialog("EMPLEADO ELIMINADO");
            break;
        }
      } else {
        snackBarDialog("ERROR NO SE LOGRO ACTUALIZAR EL ESTADO", "error");
      }

      setIsLoading(false);
      setOpenModal(false);
    } catch (error) {
      snackBarDialog("Error no se podido Suspender", "error");
      console.log(error);
      setIsLoading(false);
    }
  };

  const verifyEmployee = async () => {
    try {
      setIsLoading(true);
      const response = await verifyEmployeeServer(UID);
      employees.map((employee) => {
        if (employee.id === UID) employee.verified = 1;
      });

      setIsLoading(false);
      setOpenModal(false);
      snackBarDialog("EMPLEADO VERIFICADO");
    } catch (error) {
      setIsLoading(false);
      setOpenModal(false);
      console.log(error);
      snackBarDialog("NO SE LOGRO VERIFICAR AL USUARIO", "error");
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={enterpriseName} subtitle="LISTA DE EMPLEADOS" />
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="120px"
              gap="10px"
            >
              <Box gridColumn="span 12" gridRow="span 2">
                <DataTable data={employees} columns={columns} />
              </Box>
            </Box>
            <Modal open={openModal} onClose={handleModalClose}>
              <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
                <Typography variant="h6" component="h2" textAlign={"center"}>
                  ELIMINAR EMPLEADO?
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  {verify == 0 && (
                    <Grid item xs={3} md={3} textAlign="center">
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={verifyEmployee}
                        disabled={isLoading}
                      >
                        VERIFICAR
                      </Button>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={verify == 1 ? 4 : 3}
                    md={verify == 1 ? 4 : 3}
                    textAlign="center"
                  >
                    {STATUS === 1 ? (
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => updateStatus(StatusEnum.SUSPENDIDO)}
                        disabled={isLoading}
                        fullWidth
                      >
                        SUSPENDER
                      </Button>
                    ) : (
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => updateStatus(StatusEnum.ACTIVO)}
                        disabled={isLoading}
                        fullWidth
                      >
                        HABILITAR
                      </Button>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={verify == 1 ? 4 : 3}
                    md={verify == 1 ? 4 : 3}
                    textAlign="center"
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => updateStatus(StatusEnum.ELIMINADO)}
                      disabled={isLoading}
                      fullWidth
                    >
                      ELIMINAR
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={verify == 1 ? 4 : 3}
                    md={verify == 1 ? 4 : 3}
                    textAlign="center"
                  >
                    <Button
                      color="error"
                      variant="contained"
                      onClick={handleModalClose}
                      disabled={isLoading}
                      fullWidth
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={5000}
              handleClose={handleSnkClose}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </main>
      </div>
    </>
  );
};

export default EmployeeList;
