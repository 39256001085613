import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  CssBaseline,
  Modal,
  Typography,
  Grid,
  Button,
  useTheme,
  TextField,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { getTokenRefresh } from "../../../../helpers/token/refreshToken";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { tokens } from "../../../../theme";
import { modalStyle } from "./DriverStyle";
import {
  createDriverPayment,
  deleteDriverApi,
} from "../../../../api/users/driver/driver";
import { getFixedPayments } from "../../../../api/booking/bookingNow";
import { convertDate } from "../../../../helpers/date/convertDate";
import { DataGrid, esES } from "@mui/x-data-grid";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

const DriverHistory = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const [drivers, setDrivers] = useState([]);

  const [bookings, setBookings] = useState(null);
  const [bookSort, setBookSort] = useState(null);

  const [UID, setUID] = useState("");
  const [STATUS, setSTATUS] = useState("");
  const [FULLNAME, setFULLNAME] = useState("");
  const [DEBT, setDEBT] = useState(0);
  const [pay, setPay] = useState(0);
  const location = useLocation();
  const driverName = location.state.fullname;

  const driverStatus = {
    1: "Activo",
    2: "Suspendido",
    3: "Eliminado",
  };

  useEffect(() => {
    const listBooking = async () => {
      try {
        const token = getTokenRefresh();
        const commissionPayment = await getFixedPayments(token, id);
        console.log(commissionPayment.data);
        let count = 0;

        setBookings([]);
        Object.values(commissionPayment.data).map((booking) => {
          if (booking.booking_type) {
            setBookings((oldData) => [...oldData, booking]);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    listBooking();
  }, []);

  useEffect(() => {
    if (bookings) {
      const compareByDate = (a, b) => {
        const dateB = convertDate(b.date, b.time);
        const dateA = convertDate(a.date, a.time);
        return dateB - dateA;
      };
      setBookSort([]);
      console.log(bookings);
      Object.values(bookings)
        .sort(compareByDate)
        .map((book, index) => {
          book.index = index + 1;
          setBookSort((oldData) => [...oldData, book]);
        });
    }
  }, [bookings]);

  const columns = [
    { field: "index", headerName: "ID", width: 50 },
    {
      headerName: "Numero",
      field: "order_number",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 90,
    },
    {
      headerName: "FECHA",
      field: "date",
      width: 80,
    },
    {
      headerName: "HORA",
      field: "time",
      width: 70,
    },
    {
      headerName: "ORIGEN",
      field: "origin_name",
      width: 200,
      renderCell: ({
        row: { origin_name, origin_lat, origin_lng, origin_url },
      }) => {
        let url;
        if (!origin_url) {
          url = `https://www.google.com/maps/search/?api=1&query=${origin_lat},${origin_lng}&zoom=20`;
        } else {
          url = origin_url;
        }
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 14,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {origin_name}
          </a>
        );
      },
    },
    {
      headerName: "DESTINO",
      field: "destination_name",
      width: 200,
      renderCell: ({
        row: {
          destination_name,
          destination_lat,
          destination_lng,
          destination_url,
        },
      }) => {
        let url;
        if (!destination_url) {
          url = `https://www.google.com/maps/search/?api=1&query=${destination_lat},${destination_lng}&zoom=20`;
        } else {
          url = destination_url;
        }
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 14,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {destination_name}
          </a>
        );
      },
    },
    {
      headerName: "NOMBRE",
      field: "fullname",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "EMPRESA",
      field: "commertial_name",
      width: 200,
    },
    {
      headerName: "TIPO",
      field: "payment_type",
      width: 100,
    },
    {
      headerName: "IMPUESTO",
      field: "tax",
      width: 100,
    },
    {
      headerName: "PEAJE",
      field: "toll",
      width: 100,
    },
    {
      headerName: "PAGO TOTAL",
      field: "price_total",
      width: 100,
    },
    {
      headerName: "PAGO CONDUCTOR",
      field: "price_driver",
      width: 100,
    },
    {
      headerName: "Porcentual",
      field: "commission",
      width: 100,
    },
    {
      headerName: "CANTIDAD %",
      field: "amount",
      width: 100,
    },
  ];

  const columnsFixed = [
    { field: "index", headerName: "ID", width: 50 },
    {
      headerName: "Fijo",
      field: "fixed",
      width: 100,
    },
  ];

  const [open, setOpen] = useState(false);
  const handleModalClose = () => setOpen(false);

  const [openModalDebt, setOpenModalDebt] = useState(false);
  const handleModalDebtClose = () => setOpenModalDebt(false);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const deleteVehicle = async () => {
    console.log("delete");
    setIsLoading(true);
    try {
      await deleteDriverApi(UID, 3);
      snackBarDialog("CONDUCTOR ELIMINADO");
      setDrivers(drivers.filter((driver) => driver.id !== UID));
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      snackBarDialog("error no se ha eliminado");
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateStatusDriver = async (status) => {
    console.log("delete");
    setIsLoading(true);
    try {
      await deleteDriverApi(UID, status);
      snackBarDialog("ESTADO ACTUALIZADO");
      drivers.map((driver) => {
        if (driver.id === UID) {
          driver.status = status;
          driver.driverStatus = driverStatus[status];
        }
      });
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      snackBarDialog("Error no se podido Suspender");
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateDriverPayment = async (payment) => {
    console.log("updateDriverPayment");
    setIsLoading(true);
    try {
      if (value) {
        console.log(UID, payment);
        const token = "";
        if (value === "cobrar") {
          await createDriverPayment(token, UID, payment);
        } else {
          await createDriverPayment(token, UID, -payment);
        }
        snackBarDialog("ESTADO ACTUALIZADO");
        drivers.map((driver) => {
          if (driver.id === UID) {
            if (value === "cobrar") {
              driver.total_amount = Number(DEBT) + Number(pay);
            } else {
              driver.total_amount = Number(DEBT) - Number(pay);
            }
          }
        });
        setDEBT(0);
        setPay(0);
        setFULLNAME("");
        setIsLoading(false);
        setOpenModalDebt(false);
      } else {
        snackBarDialog("SELECCION OPERACION", "error");
      }
    } catch (error) {
      snackBarDialog("Error no se podido Suspender", "error");
      console.log(error);
      setIsLoading(false);
    }
  };

  const [value, setValue] = React.useState(null);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={driverName} subtitle="HISTORIAL DE SERVICIOS" />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                {bookSort ? (
                  <Box
                    m="10px 0 0 0"
                    height="100vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                    }}
                  >
                    <DataGrid
                      getRowId={(row) => row.order_number}
                      rows={bookSort}
                      columns={columns}
                      localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                      }
                    />
                  </Box>
                ) : null}
              </Box>
            </Box>
            <Modal open={open} onClose={handleModalClose}>
              <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
                <Typography variant="h6" component="h2" textAlign={"center"}>
                  ELIMINAR CONDUCTOR?
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4} md={4} textAlign="center">
                    {STATUS === 1 ? (
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => updateStatusDriver(2)}
                        disabled={isLoading}
                      >
                        SUSPENDER
                      </Button>
                    ) : (
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => updateStatusDriver(1)}
                        disabled={isLoading}
                      >
                        HABILITAR
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={4} md={4} textAlign="center">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={deleteVehicle}
                      disabled={isLoading}
                    >
                      ELIMINAR
                    </Button>
                  </Grid>
                  <Grid item xs={4} md={4} textAlign="center">
                    <Button
                      color="error"
                      variant="contained"
                      onClick={handleModalClose}
                      disabled={isLoading}
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            <Modal
              open={openModalDebt}
              onClose={handleModalDebtClose}
              aria-labelledby="modal-debt-title"
            >
              <Box
                sx={{
                  ...modalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1.5}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-debt-title">PAGO</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={FULLNAME}
                      fullWidth
                      disabled
                      label="CONDUCTOR"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={DEBT}
                      fullWidth
                      disabled
                      label="DEBE"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="radio-buttons">OPERACION</FormLabel>
                      <RadioGroup
                        row
                        name="radio-buttons"
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="pagar"
                          control={<Radio />}
                          label="PAGAR"
                        />
                        <FormControlLabel
                          value="cobrar"
                          control={<Radio />}
                          label="COBRAR"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      value={pay}
                      fullWidth
                      type="number"
                      label={
                        value === "pagar" ? "MONTO A PAGAR" : "MONTO A COBRAR"
                      }
                      variant="outlined"
                      name="pay"
                      onChange={(e) => {
                        setPay(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        console.log(UID, pay);
                        updateDriverPayment(pay);
                      }}
                    >
                      {value === "pagar" ? "pagar" : "cobrar"}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={5000}
              handleClose={handleSnkClose}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </main>
      </div>
    </>
  );
};

export default DriverHistory;
