import React, { useState, useEffect } from "react";
import { Box, Button, CssBaseline, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";

import { set, ref, onValue } from "firebase/database";
import { db } from "../../../../../firebase";

import Topbar from "../../../../../components/topbar/TopBar";
import Sidebar from "../../../../../components/sidebar/Sidebar";
import Header from "../../../../../components/header/Header";
import { DataGrid, esES } from "@mui/x-data-grid";

import { tokens } from "../../../../../theme";
import { ReadBackup } from "../usecases/ReadBackup";
import { convertDate } from "../../../../../helpers/date/convertDate";
import SnackBar from "../../../../../components/snackbar/SnackBar";

const BackupRider = () => {
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //data backup
  const [listBackup, setListBackup] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);

  useEffect(() => {
    const getBackups = async () => {
      try {
        const response = await ReadBackup();
        if (response.message == "success") {
          setListBackup([]);
          console.log(response?.backup);
          const compareByDate = (a, b) => {
            const dateB = convertDate(b.date, b.time);
            const dateA = convertDate(a.date, a.time);
            return dateB - dateA;
          };

          Object.values(response?.backup)
            .sort(compareByDate)
            .map((book) => {
              if (book.user_type == 1) {
                setListBackup((oldArray) => [...oldArray, book]);
              }
            });
        } else {
          console.log(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getBackups();
  }, []);

  const columns = [
    {
      headerName: "Pedido",
      field: "id",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "Fecha",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Hora",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Nombre",
      field: "client_name",
      width: 200,
      renderCell: ({ row: { client_name, client_lastname } }) => {
        return (
          <>
            {client_name} {client_lastname}
          </>
        );
      },
    },
    {
      headerName: "RESERVA",
      field: "user_type",
      width: 80,
      renderCell: ({ row: { user_type } }) => {
        return <div>{user_type == 0 ? "EMPRESA" : "CLIENTE"}</div>;
      },
    },
    {
      headerName: "Origen",
      field: "origin_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 210,
    },
    {
      headerName: "Destino",
      field: "destination_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 210,
    },
    {
      headerName: "Pack",
      field: "persons",
      headerAlign: "left",
      align: "center",
      width: 20,
    },
    {
      headerName: "Notas",
      field: "notes",
      width: 180,
    },
    {
      headerName: "Creado",
      field: "created_by",
      width: 80,
    },
  ];

  const createBookingFirebase = async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      dataSelected.map(async (book, idx) => {
        const data = await createDataFirebase(book);

        if (book.user_type == 0) {
          const counter = await exitsData(
            data.counterBooking,
            listBookingBusines
          );
          if (counter > 0) {
            setIsLoading(false);
            return snackBarDialog("ESTE PEDIDO YA EXISTE", "error");
          } else {
            await restoreEnterprise(data);
            setIsLoading(false);
            return snackBarDialog("PEDIDO RESTAURADO");
          }
        } else {
          const counter = await exitsData(data.counterBooking, listBookingNow);
          if (counter > 0) {
            setIsLoading(false);
            return snackBarDialog("ESTE PEDIDO YA EXISTE", "error");
          } else {
            const response = await restoreClient(data);
            setIsLoading(false);
            return snackBarDialog("PEDIDO RESTAURADO");
          }
        }
      });
    } catch (error) {
      setIsLoading(false);
      snackBarDialog("HA OCURRIDO UN ERROR EN LA PAGINA", "error");
      console.log(error);
    }
  };

  const [listBookingNow, setListBookingNow] = useState([]);
  const [listBookingBusines, setListBookingBusiness] = useState([]);

  useEffect(() => {
    const getListBookingNow = async () => {
      const starCountRef = ref(db, "bookingNow");

      onValue(starCountRef, (snapshot) => {
        if (snapshot.exists()) {
          setListBookingNow([]);
          Object.values(snapshot.val()).map((item) => {
            setListBookingNow((oldData) => [...oldData, item]);
          });
        }
      });
    };
    const getListBookingBusiness = async () => {
      const starCountRef = ref(db, "BookingBusiness");
      onValue(starCountRef, (snapshot) => {
        if (snapshot.exists()) {
          setListBookingBusiness([]);
          Object.values(snapshot.val()).map((item) => {
            setListBookingBusiness((oldData) => [...oldData, item]);
          });
        }
      });
    };

    getListBookingNow();
    getListBookingBusiness();
  }, []);

  const exitsData = async (orderNumber, listBooking) => {
    let counter = 0;
    listBooking.map((book) => {
      if (orderNumber === book.counterBooking) {
        counter++;
      }
    });
    return counter;
  };

  const restoreClient = async (data) => {
    try {
      const dbRef = `bookingNow/${data.uuid}`;
      await set(ref(db, dbRef), data);
      snackBarDialog("PEDIDO RESTAURADO EN FIREBASE");
    } catch (error) {
      snackBarDialog("NO SE LOGRO RESTAURAR EN PEDIDOS CLIENTE", "error");
      console.log(error);
    }
  };

  const restoreEnterprise = async (data) => {
    try {
      const dbRef = `BookingBusiness/${data.uuid}`;
      await set(ref(db, dbRef), data);
      snackBarDialog("PEDIDO RESTAURADO EN FIREBASE");
    } catch (error) {
      snackBarDialog("ERROR: NO SE LOGRO RESTAURAR", "error");
      console.log(error);
    }
  };

  const createDataFirebase = async (values) => {
    const data = {
      uuid: values.uuid,
      client: values.user_id,
      clientName: `${values.client_name} ${values.client_lastname}`,
      clientEmail: values.client_email,
      clientPhone: values.client_phone,
      enterpriseId: values.enterprise_id ? values.enterprise_id : "",
      enterpriseName: values.enterprise_name ? values.enterprise_name : "",
      origin: {
        name: values.origin_name,
        lat: values.origin_lat,
        lng: values.origin_lng,
      },
      destination: {
        name: values.destination_name,
        lat: values.destination_lat,
        lng: values.destination_lng,
      },
      persons: values.persons,
      vehicle: values.vehicle_model,
      date: values.date,
      time: values.time,
      dateEnd: values.date_end,
      timeEnd: values.date_end,
      tour_id: values.tour_id > 0 ? values.tour_id : "",
      tour_description: values.description ? values.description : "",
      note: values.notes.toUpperCase(),
      noteAdmin: values.notes_admin,
      currency: values.currency,
      payment_type: values.payment_type,
      payment_voucher: values.payment_voucher,
      tax: values.tax,
      toll: values.toll,
      price: values.price,
      total: values.total,
      advance: values.advance,
      discount: values.discount,
      statusService: "PENDIENTE",
      statusPayment: "PENDIENTE",
      statusBooking: "PENDIENTE",
      createBooking: values.created_by,
      booking_type: values.booking_type,
      counterBooking: values.id,
      created: new Date(values.created_at).getTime(),
      isCreated: false,
      cotizar: Boolean(values.quote),
      cotizado: Boolean(values.quote),
      phoneActive: true,
    };

    return data;
  };

  //SNACKBAR
  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);
  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            m={2}
          >
            <Header title="BACKUP CLIENTES" subtitle="" />
            {dataSelected.length > 0 && (
              <Button
                variant="contained"
                color="success"
                onClick={createBookingFirebase}
                disabled={isLoading}
              >
                RESTAURAR A FIREBASE
              </Button>
            )}
          </Box>

          <Box
            m="10px 0 0 0"
            height="100vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              getRowId={(row) => row.id}
              rows={listBackup}
              columns={columns}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              checkboxSelection
              onSelectionModelChange={(ids) => {
                setDataSelected([]);
                ids.map((id) => {
                  const select = listBackup.find((row) => row.id === id);
                  setDataSelected((oldData) => [...oldData, select]);
                });
              }}
            />
          </Box>

          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={5000}
            handleClose={handleSnkClose}
            severity={severity}
            messageSnk={messageSnk}
          />
        </main>
      </div>
    </>
  );
};

export default BackupRider;
