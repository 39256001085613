import { URL_EMPLOYEE } from "../../../helpers/endpoint";
import { baseBackend } from "../../base/baseUrl";

export const getEmployeesByName = async (token, name) => {
  return await baseBackend.get(`${URL_EMPLOYEE}/${name}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getEmployeeById = async (token, id) => {
  return await baseBackend.get(`${URL_EMPLOYEE}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createdEmployee = async (token, employee) => {
  return await baseBackend.post(URL_EMPLOYEE, JSON.stringify({ employee }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteEmployeeApi = async (enterprise_id, status) => {
  return await baseBackend.delete(`${URL_EMPLOYEE}/${enterprise_id}`, {
    data: { status },
    headers: { "Content-Type": "application/json" },
  });
};

export const getEmployeesFromEnterprise = async (token, enterprise_id) => {
  return await baseBackend.get(`${URL_EMPLOYEE}s/${enterprise_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateEmployee = async (token, employee) => {
  return await baseBackend.put(URL_EMPLOYEE, JSON.stringify({ employee }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const verifyEmployeeServer = async (employeeId) => {
  return await baseBackend.put(
    `${URL_EMPLOYEE}-verified/verified`,
    JSON.stringify({ employeeId }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
