export const compareDate = (fechaString, days) => {
  const [dia, mes, anio] = fechaString.split("/");
  const fecha = new Date(anio, mes - 1, dia);
  const current = new Date();
  const authString = `${current.getDate()}/${current.getMonth()}/${current.getFullYear()}`;
  const [day, moth, year] = authString.split("/");
  const currentDate = new Date(year, moth, day);
  var sevenDays = new Date(new Date().setDate(current.getDate() + days));

  return fecha <= sevenDays && fecha >= currentDate;
};
