import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  TextField,
  useTheme,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Snackbar,
  Grid,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import SendIcon from "@mui/icons-material/Send";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import { es } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { tokens } from "../../../../theme";
import { getGenres } from "../../../../api/resources/genres/genres";
import { getVehicles } from "../../../../api/users/driver/vehicle";
import { countries } from "../../../../helpers/countrys";
import {
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../../helpers/token/refreshToken";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { createdDriver } from "../../../../api/users/driver/driver";
import { getSunatDni } from "../../../../api/resources/sunat/sunat";
import { getGlobalConfig } from "../../../../api/resources/global/globalConfiguration";

const FormDriver = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [genre, setGenre] = useState([]);
  const [commissionPercentage, setCommissionPercentage] = useState("");
  const [commissionFixed, setCommissionFixed] = useState("");
  const [vehicle, setVehicle] = useState([]);
  const [expiration, setExpiration] = useState(null);
  const [dateBirth, setDateBirth] = useState(null);
  const [dni, setDni] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnackbar, setStateSnackbar] = useState(false);

  const vertical = "top";
  const horizontal = "center";

  const handleCloseSnk = () => setStateSnackbar(false);

  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");

  const handleClose = () => {
    setStateSnk(false);
  };

  const [startContract, setStartContract] = useState(null);
  const [endContract, setEndContract] = useState(null);

  const [value, setValue] = useState([]);

  const [phoneCode, setPhoneCode] = useState(null);

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    lastname: yup.string().required("required"),
    phone: yup.string().required("required"),
    address: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    relationship: yup.string().required("required"),
    genre: yup.number().required("required"),
  });

  const initialValues = {
    name: "",
    lastname: "",
    phone: "+51 ",
    phone_ref: "+51 ",
    relationship: "",
    email: "",
    address: "",
    genre: "",
    percentage: 0,
    fixed: 0,
    numberLicense: "",
    image: "",
    numberContract: "",
    vehicle: "",
  };

  useEffect(() => {
    const genres = async () => {
      try {
        const response = await getGenres();
        const data = response.data.genres.filter((genre) => genre.id);
        setGenre(data);
      } catch (error) {
        console.log(error);
      }
    };
    genres();
  }, []);

  useEffect(() => {
    const commissions = async () => {
      try {
        const response = await getGlobalConfig(1);
        console.log(response.data);
        setCommissionPercentage(response.data[0].percentage_commission);
        setCommissionFixed(response.data[0].fixed_commission);
      } catch (error) {
        console.log(error);
      }
    };
    commissions();
  }, []);

  useEffect(() => {
    const verify = verifyExpireTokenRefresh();
    if (verify) navigate("/");

    const vehicles = async () => {
      try {
        const token = getTokenRefresh();
        const response = await getVehicles(token);
        const data = response?.data?.filter((vehicle) => vehicle.id);
        setVehicle(data);
      } catch (error) {
        console.log(error);
      }
    };
    vehicles();
  }, []);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const contract = {
      number: values.numberContract,
      start: startContract,
      end: endContract,
    };

    const license = {
      number: values.numberLicense.toUpperCase(),
      expiration: expiration,
      image: "image",
    };

    const driver = {
      email: values.email,
      password: dni,
      name: values.name.toUpperCase(),
      lastname: values.lastname.toUpperCase(),
      phone: values.phone,
      phone_reference: values.phone_ref,
      dni: dni,
      relationship: values.relationship.toUpperCase(),
      address: values.address.toUpperCase(),
      date_of_birth: dateBirth.toISOString().slice(0, 19).replace("T", " "),
      company_id: 1,
      genres_id: values.genre,
      bookings: bookingState,
      approved: true,
    };

    const commissions = {
      percentageValue: commissionPercentage,
      fixedValue: commissionFixed,
      monthly: fixedPay,
    };

    const vehicles = value;
    console.log(vehicles);
    const expireToken = verifyExpireTokenRefresh();
    if (expireToken) navigate("/");

    try {
      const token = getTokenRefresh();
      await createdDriver(
        token,
        contract,
        license,
        driver,
        commissions,
        vehicles
      );
      setMessageSnk("Conductor Registrado Exitosamente");
      setSeverity("success");
      setStateSnk(true);
      values.name = "";
      values.lastname = "";
      values.dni = "";
      values.email = "";
      values.phone = "";
      values.phone_ref = "";
      values.address = "";
      values.genre = "";
      values.numberContract = "";
      values.numberLicense = "";
      values.percentage = "";
      values.fixed = "";
      setValue([]);
      setStartContract(null);
      setEndContract(null);
      setExpiration(null);
      setIsLoading(false);
    } catch (error) {
      console.log(error.response.data.error);
      setIsLoading(false);
      setMessageSnk("Fallo el Registro de Conductor");
      setSeverity("error");
      setStateSnk(true);
    }
  };

  const [fixedPay, setFixedPay] = useState(false);
  const [bookingState, setBookingState] = useState(false);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const handleChangeFixed = (event) => setFixedPay(event.target.value);
  const handleChangeBooking = (event) => setBookingState(event.target.value);

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box backgroundColor={colors.primary[400]} p={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={values.name}
                    fullWidth
                    variant="outlined"
                    type="text"
                    label={t("name")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="name"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    color="success"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={values.lastname}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="text"
                    label={t("lastname")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="lastname"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={!!touched.lastname && !!errors.lastname}
                    helperText={touched.lastname && errors.lastname}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      DNI
                    </InputLabel>
                    <OutlinedInput
                      value={dni}
                      name="dni"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setDni(e.target.value);
                        console.log(e.target.value);
                        values.numberLicense = e.target.value;
                      }}
                      id="control-dni"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            onClick={async (e) => {
                              if (dni.length === 8) {
                                try {
                                  const res = await getSunatDni(dni);
                                  values.name = res?.data?.nombres;
                                  values.lastname = `${res?.data?.apellidoPaterno} ${res.data?.apellidoMaterno}`;
                                  setMessageSnk("Success");
                                  setStateSnackbar(true);
                                } catch (error) {
                                  setMessageSnk("DNI no encontrado");
                                  setStateSnackbar(true);
                                  console.log(error);
                                }
                              } else {
                                setMessageSnk("DNI de 8 digitos");
                                setStateSnackbar(true);
                              }
                            }}
                          >
                            <SendIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="DNI"
                      aria-describedby="outlined-helper-text"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={values.email}
                    name="email"
                    label={t("email")}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={dni}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="password"
                    label={t("password")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="password"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={dni}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="password"
                    label={t("confirmPassword")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="re_password"
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Autocomplete
                    id="country-phone"
                    options={countries}
                    defaultValue={countries[172]}
                    autoHighlight
                    getOptionLabel={(option) =>
                      `(${option.phone}) ${option.label}`
                    }
                    onChange={(e) => {
                      const cad = e.target.innerText;
                      const words = cad.split("+");
                      setPhoneCode(`+${words[1]}`);
                      values.phone = `+${words[1]} `;
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code}) {option.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    value={values.phone}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="tel"
                    label={t("phone")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="phone"
                    error={!!touched.phone && !!errors.phone}
                    helperText={touched.phone && errors.phone}
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Autocomplete
                    id="country-phone-ref"
                    options={countries}
                    defaultValue={countries[172]}
                    autoHighlight
                    getOptionLabel={(option) =>
                      `(${option.phone}) ${option.label}`
                    }
                    onChange={(e) => {
                      const cad = e.target.innerText;
                      const words = cad.split("+");
                      setPhoneCode(`+${words[1]}`);
                      values.phone_ref = `+${words[1]} `;
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code}) {option.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    value={values.phone_ref}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="tel"
                    label={t("phone_reference")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="phone_ref"
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    value={values.relationship}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="text"
                    label={t("Parentesco")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="relationship"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={!!touched.relationship && !!errors.relationship}
                    helperText={touched.relationship && errors.relationship}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={values.address}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="text"
                    label={t("address")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="address"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={!!touched.address && !!errors.address}
                    helperText={touched.address && errors.address}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={es}
                  >
                    <Stack spacing={2}>
                      <MobileDatePicker
                        value={dateBirth}
                        label={t("Fecha de Nacimiento")}
                        inputFormat="dd/MM/yyyy"
                        name="dateBirth"
                        onChange={(newValue) => {
                          setDateBirth(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel>{t("genre")}</InputLabel>
                    <Select
                      value={values.genre}
                      id="genre"
                      name="genre"
                      label={t("genre")}
                      onChange={handleChange}
                    >
                      {genre.map((genre, index) => (
                        <MenuItem key={index} value={genre.id}>
                          {t(genre.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <TextField
                    value={commissionPercentage}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="number"
                    label={t("PORCENTAJE")}
                    onChange={(e) => {
                      setCommissionPercentage(e.target.value);
                    }}
                    name="commissionPercentage"
                  />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <TextField
                    value={commissionFixed}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="number"
                    label={t("Fijo (diario)")}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setCommissionFixed(e.target.value);
                    }}
                    name="commissionFixed"
                  />
                </Grid>

                <Grid item xs={12} sm={3} textAlign="center">
                  <FormControl>
                    <FormLabel id="row-radio-buttons">COBRAR POR:</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="row-radio-buttons"
                      value={fixedPay}
                      onChange={handleChangeFixed}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio color="success" />}
                        label="DIA"
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio color="success" />}
                        label="MES"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={3} textAlign="center">
                  <FormControl>
                    <FormLabel id="row-radio-buttons">RESERVAS:</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="row-radio-buttons"
                      value={bookingState}
                      onChange={handleChangeBooking}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio color="success" />}
                        label="NO"
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio color="success" />}
                        label="SI"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    value={values.numberLicense}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="text"
                    label={t("numberLicense")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="numberLicense"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <MobileDatePicker
                        label={t("expirationDate")}
                        inputFormat="DD/MM/YYYY"
                        name="date"
                        value={expiration}
                        onChange={(newValue) => {
                          setExpiration(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={4}>
                  {/* <TextField
                    value={values.image}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="text"
                    label={t("image")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="image"
                  /> */}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    value={values.numberContract}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="text"
                    label={t("numberContract")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="numberContract"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <MobileDatePicker
                        label={t("start")}
                        inputFormat="DD/MM/YYYY"
                        name="start"
                        value={startContract}
                        onChange={(newValue) => {
                          setStartContract(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <MobileDatePicker
                        label={t("end")}
                        inputFormat="DD/MM/YYYY"
                        name="end"
                        value={endContract}
                        onChange={(newValue) => {
                          setEndContract(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    multiple
                    id="id-vehicles"
                    value={value}
                    onChange={(e, newValue) => {
                      setValue([...newValue]);
                    }}
                    options={vehicle}
                    getOptionLabel={(option) =>
                      `${option.id}: ${option.brand} ${option.model} ${option.plate}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("vehicle")} />
                    )}
                    sx={{ gridColumn: "span 4" }}
                  />
                </Grid>
              </Grid>

              <Snackbar
                autoHideDuration={4000}
                anchorOrigin={{ vertical, horizontal }}
                open={stateSnackbar}
                onClose={handleCloseSnk}
                message={messageSnk}
                key={"vertical+horizontal"}
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isLoading}
              >
                {t("createNewDriver")}
              </Button>
            </Box>
            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleClose}
              severity={severity}
              messageSnk={messageSnk}
            />
          </form>
        )}
      </Formik>
    </>
  );
};

export default FormDriver;
