import { updateBackupPay } from "../../../../../api/backup/backup";

export const UpdateOrderBackup = async (backup) => {
  try {
    const response = await updateBackupPay(backup);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
