import { updateReservation } from "../../../data/repository/booking.repo";

export const UpdateBookingUseCase = async (reservation) => {
  try {
    const resp = await updateReservation(reservation);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
