import React from "react";
import { Box, CssBaseline } from "@mui/material";
import Topbar from "../../../../components/topbar/TopBar";
import TableBookBusinessQuoteLater from "../../../../components/datatable/TableBookBusinessQuoteLater";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

const QuoteBookingBusinessLater = () => {
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  return (
    <div>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <TableBookBusinessQuoteLater />
          </Box>
        </main>
      </div>
    </div>
  );
};

export default QuoteBookingBusinessLater;
