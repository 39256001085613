import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import PropTypes from "prop-types";
import { ref, onValue, remove, update, get } from "firebase/database";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Fade,
  useTheme,
  Popper,
  Paper,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ContactlessIcon from "@mui/icons-material/Contactless";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import Edit from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WhereToVoteOutlinedIcon from "@mui/icons-material/WhereToVoteOutlined";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { convertDate } from "../../helpers/date/convertDate";
import SnackBar from "../snackbar/SnackBar";
import { ModalStyle } from "./TableBookStyle";
import { memo } from "react";
import { useRef } from "react";
import {
  checkStatusPayment,
  checkStatusPaymentUSD,
} from "../../api/openpay/openpay";
import { sendNotificationTokens } from "../../api/fcm/notification";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
} from "@react-pdf/renderer";
import DocumentName from "./pdf/now/elements/DocumentName";
import TableBooking from "./pdf/now/elements/TableBooking";
import HeaderPdf from "./pdf/now/elements/Header";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Header from "../header/Header";
import Voucher from "./voucher/Voucher";
import { getAdvanceCashOrder } from "../../api/booking/advance";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const TableBookLater = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [booking, setBooking] = useState([]);
  const [sortBooking, setSortBooking] = useState([]);
  const [UID, setUID] = useState("");
  const [clientID, setClientID] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const handleClosePaymentModal = () => setOpenPaymentModal(false);

  const [openActiveBtnModal, setOpenActiveBtnModal] = useState(false);
  const handleCloseActiveBtnModal = () => setOpenActiveBtnModal(false);

  const currency = { 1: "PEN", 2: "USD" };

  useEffect(() => {
    const starCountRef = ref(db, "bookingNow/");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setBooking([]);
      if (data !== null) {
        Object.values(data).map((_book) => {
          if (_book.booking_type && _book.cotizado && _book.booking_type != 1) {
            _book.fullorigin = _book.origin.name;
            _book.originLat = _book.origin.lat;
            _book.originLng = _book.origin.lng;
            _book.fulldestination = _book.destination.name;
            _book.destinationLat = _book.destination.lat;
            _book.destinationLng = _book.destination.lng;

            if (_book.booking_type === 4) {
              _book.note = `${_book.tour_description}. ${_book.note}`;
            }
            setBooking((oldArray) => [...oldArray, _book]);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (booking) {
      const compareByDate = (a, b) => {
        const dateB = convertDate(b.date, b.time);
        const dateA = convertDate(a.date, a.time);
        return dateA - dateB;
      };
      setSortBooking([]);
      Object.values(booking)
        .sort(compareByDate)
        .map((book) => {
          setSortBooking((oldData) => [...oldData, book]);
        });
    }
  }, [booking]);

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };

  const [openPublishmentModal, setOpenPublishmentModal] = useState(false);
  const handleClosePublishmentModal = () => setOpenPublishmentModal(false);
  const [isLoading, setIsLoading] = useState(false);

  //data menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);

  const [clientParam, setClientParam] = useState("");
  const [uuidParam, setUuidParam] = useState("");
  const [currencyParam, setCurrencyParam] = useState("");
  const [totalParam, setTotalParam] = useState("");
  const [openpayParam, setOpenpayParam] = useState("");
  const [publishmentParam, setPublishmentParam] = useState(false);
  const [counterBookingParam, setCounterBookingParam] = useState("");
  //client, uuid, currency, total, openpay, publishment
  const columnsClient = [
    {
      headerName: "PEDIDO",
      field: "counterBooking",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 50,
      renderCell: ({ row: { ...props } }) => {
        return (
          <a
            href={`https://api.whatsapp.com/send?text=*PEDIDO:* ${
              props.counterBooking
            }%0A*Cliente:* ${
              props.clientName
            } ,%0A*Origen:* ${encodeURIComponent(
              props.fullorigin
            )}%0A*Destino:* ${encodeURIComponent(
              props.fulldestination
            )}%0A*Fecha:* ${props.date} - *Hora:* ${
              props.time
            }%0A*Pasajeros:* ${props.persons}%0A*NOTA:* ${
              props.note
            }%0A*TELEFONO:* ${props.clientPhone}`}
            target="_blank"
            style={{ color: "white", textDecoration: "none" }}
          >
            {props.counterBooking}
          </a>
        );
      },
    },
    {
      headerName: "FECHA",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "HORA",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "CLIENTE",
      field: "clientName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 220,
      renderCell: ({ row: { clientName, clientPhone } }) => {
        return (
          <a
            href={`https://api.whatsapp.com/send?phone=${clientPhone}&text=*`}
            target="_blank"
            style={{ color: "white", textDecoration: "none" }}
          >
            {clientName}
          </a>
        );
      },
    },
    {
      headerName: "Origen",
      field: "fullorigin",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: ({ row: { fullorigin, originLat, originLng } }) => {
        const url = `https://maps.google.com/?q=${originLat},${originLng}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 13,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {fullorigin}
          </a>
        );
      },
    },
    {
      headerName: "destino",
      field: "fulldestination",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: ({
        row: { fulldestination, destinationLat, destinationLng },
      }) => {
        const url = `https://maps.google.com/?q=${destinationLat},${destinationLng}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 13,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {fulldestination}
          </a>
        );
      },
    },
    {
      headerName: "Pack",
      field: "persons",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      headerName: "Notas",
      field: "note",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Conductor",
      headerAlign: "center",
      field: "statusService",
      type: "string",
      align: "center",
      width: 90,
      renderCell: ({ row: { statusService } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              alignItems: "center",
              color: "white",
              background: statusService === "PENDIENTE" ? "red" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {statusService}
          </div>
        );
      },
    },
    {
      headerName: "Precio",
      headerAlign: "center",
      field: "precio_asing",
      width: 90,
      align: "center",
      renderCell: ({ row: { total } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              color: "white",
              alignItems: "center",
              background: total <= 0 ? "red" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {total <= 0 ? "PENDIENTE" : "ASIGNADO"}
          </div>
        );
      },
    },
    {
      headerName: "PAGO",
      headerAlign: "center",
      field: "statusPayment",
      align: "center",
      width: 90,
      renderCell: ({ row: { statusBooking, statusPayment } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              alignItems: "center",
              color: "white",
              background: statusPayment === "PAGADO" ? "blue" : "red",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {statusPayment}
          </div>
        );
      },
    },
    {
      headerName: "Creado",
      field: "createBooking",
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      headerName: "",
      field: "actions",
      width: 90,
      renderCell: ({
        row: {
          client,
          uuid,
          currency,
          total,
          openpay,
          publishment,
          counterBooking,
          originLat,
          originLng,
        },
      }) => {
        return (
          <>
            <Button
              color="secondary"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => {
                setClientParam(client);
                setUuidParam(uuid);
                setCurrencyParam(currency);
                setTotalParam(total);
                setOpenpayParam(openpay);
                setCounterBookingParam(counterBooking);
                setPublishmentParam(publishment);
                setAnchorEl(event.currentTarget);
              }}
            >
              OPCIONES
            </Button>
            <Menu
              id="menu-options"
              MenuListProps={{ "aria-labelledby": "fade-button" }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem
                onClick={() => {
                  navigate(
                    `/admin/add-booking-later/${clientParam}/${uuidParam}`
                  );
                  handleClose();
                }}
              >
                <Edit sx={{ color: "#FF0404" }} />
                &nbsp;EDITAR
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setUID(uuidParam);
                  setClientID(clientParam);
                  setOpenPaymentModal(true);
                  setAnchorEl(null);
                }}
              >
                <CheckCircleOutlineOutlinedIcon sx={{ color: "#FF0404" }} />
                &nbsp;CONFIRMAR PAGO
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setUID(uuidParam);
                  setClientID(clientParam);
                  setCurrencyType(currencyParam);
                  setCurrencyPrice(totalParam);
                  setOpenActiveBtnModal(true);
                  setAnchorEl(null);
                }}
              >
                <ContactlessIcon
                  sx={{ color: openpayParam ? "#FFFFFF" : "#FF0404" }}
                />
                &nbsp;PAGO CON TARJETA
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setUID(uuidParam);
                  setClientID(clientParam);
                  setOpenPublishmentModal(true);
                  setAnchorEl(null);
                  setClientOrigin({
                    latitude: originLat,
                    longitude: originLng,
                  });
                }}
              >
                <BookmarkAddOutlinedIcon
                  sx={{
                    color:
                      publishmentParam && publishmentParam === true
                        ? "#FFFFFF"
                        : "#FF0404",
                  }}
                />
                &nbsp;PUBLICAR RESERVA
              </MenuItem>

              {/* revisar si se ha apgado */}
              {openpay &&
                openpay.status === "pending" &&
                openpay.transaction_id != "" && (
                  <MenuItem
                    color="success"
                    onClick={() => {
                      setUID(uuidParam);
                      setClientID(clientParam);
                      checkPaymentClient(openpayParam, currencyParam);
                      setAnchorEl(null);
                      //setOpenActiveBtnModal(true);
                      //solo si no se ha pagado el pedido
                    }}
                  >
                    <PriceCheckIcon color="success" />
                    &nbsp; VERIFICAR PAGO
                  </MenuItem>
                )}

              <MenuItem
                onClick={() => {
                  setUID(uuidParam);
                  setClientID(clientParam);
                  setOpenDeleteModal(true);
                  setAnchorEl(null);
                }}
              >
                <DeleteIcon sx={{ color: "#FF0404" }} />
                &nbsp; ELIMINAR RESERVA
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setUID(uuidParam);
                  setClientID(clientParam);
                  setAnchorEl(null);
                }}
              >
                <WhereToVoteOutlinedIcon sx={{ color: "#FF0404" }} />
                &nbsp; FINALIZAR VIAJE
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const [currencyType, setCurrencyType] = useState("");
  const [currencyPrice, setCurrencyPrice] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleCloseSnk = () => setStateSnk(false);

  const deleteQuote = async () => {
    try {
      await remove(ref(db, `bookingNow/${UID}`));
      handleCloseDeleteModal();
      snackBarDialog("RESERVA ELIMINADA");
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO SE PUEDE ELIMINAR LA RESERVA", "error");
    }
  };

  const updateQuote = async (state) => {
    try {
      const value = { statusPayment: state };
      await update(ref(db, `bookingNow/${UID}`), value);
      handleClosePaymentModal();
      snackBarDialog("COTIZACION CONFIRMADA");
    } catch (error) {
      handleClosePaymentModal();
      console.log(error);
      snackBarDialog("ERROR: COTIZACION ", "error");
    }
  };

  const updateActiveBtnQuote = async (currency) => {
    try {
      const openpayData = {
        openpay: {
          status: "pending",
          btnPay: true,
          currency,
          transaction_id: "",
          order_id: "",
          url: "",
        },
      };
      await update(ref(db, `bookingNow/${UID}`), openpayData);
      handleCloseActiveBtnModal();
      snackBarDialog("BOTON ACTIVADO PARA PAGOS");
    } catch (error) {
      handleCloseActiveBtnModal();
      console.log(error);
      snackBarDialog("ERROR DE ACTIVACION DE BOTON DE PAGO ", "error");
    }
  };

  //obtener la notificacion del conductor
  //obtener la lista de todos conductores
  const [driverToken, setDriverToken] = useState([]);
  useEffect(() => {
    const dbRefToken = ref(db, "Token/Driver");
    get(dbRefToken)
      .then(async (snapshot) => {
        const data = snapshot.val();
        setDriverToken([]);
        if (data) {
          snapshot.forEach((childSnapshot) => {
            const key = childSnapshot.key;
            const value = childSnapshot.val();
            if (value.isBooking && value.isPremium) {
              const token = {
                token: value.token,
                key,
              };
              setDriverToken((oldTokens) => [...oldTokens, token]);
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error al obtener los datos:", error);
      });
  }, []);

  const checkDriversLatLng = async () => {
    try {
      const dbRefDriver = ref(db, "ActiveDrivers");
      const snapshot = await get(dbRefDriver);
      let listLatLng = [];
      if (snapshot.exists()) {
        snapshot.forEach((children) => {
          const key = children.key;
          driverToken.forEach((item) => {
            if (key === item.key) {
              const latLng = {
                latitude: children.val().latitude,
                longitude: children.val().longitude,
                token: item.token,
              };
              listLatLng.push(latLng);
            }
          });
        });
        return listLatLng;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const [clientOrigin, setClientOrigin] = useState({
    latitude: 0,
    longitude: 0,
  });
  const updatePublishmentQuote = async (operation) => {
    try {
      setIsLoading(true);
      const value = { publishment: Boolean(operation) };
      const title = "NUEVA RESERVA PUBLICADA CLIENTE";
      const body = "Ingrese a Reservas para ver el pedido";
      await update(ref(db, `bookingNow/${UID}`), value);

      if (Boolean(operation)) {
        const tokens = driverToken.map((driver) => driver.token);
        await sendNotificationTokens(tokens, title, body);
        snackBarDialog("RESERVA PUBLICADA");
      } else {
        snackBarDialog("PUBLICACION ANULADA");
      }

      setIsLoading(false);
      handleClosePublishmentModal();
    } catch (error) {
      setIsLoading(false);
      snackBarDialog("ERROR: NO SE PUEDE PUBLICAR ESTE PEDIDO", "error");
      handleClosePublishmentModal();
    }
  };

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.asin(Math.sqrt(a));

    // Radio de la Tierra en kilómetros (puedes ajustarlo según tus necesidades)
    const radiusOfEarth = 6371.0;

    return c * radiusOfEarth;
  };

  const toRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  const checkPaymentClient = async (openpay, currency) => {
    try {
      if (currency === 1) {
        const response = await checkStatusPayment(openpay.transaction_id);
        const status = response.data.status;
        const order_id = response.data.order_id;
        const transaction_id = response.data.id;
        const operation_date = response.data.operation_date;
        const currencyArr = { 1: "PEN", 2: "USD" };
        if (status === "completed") {
          const openpayData = {
            statusPayment: "PAGADO",
            openpay: {
              order_id: order_id,
              transaction_id: transaction_id,
              operation_date: operation_date,
              status: status,
              btnPay: false,
              currency: currencyArr[currency],
            },
          };
          await update(ref(db, `bookingNow/${uuidParam}`), openpayData);
          snackBarDialog("EL PAGO DEL CLIENTE FUE COMPLETADO");
        } else {
          snackBarDialog("NO SE LOGO VERIFICAR EL PAGO", "error");
        }
      } else {
        const response = await checkStatusPaymentUSD(openpay.transaction_id);
        const status = response.data.status;
        const order_id = response.data.order_id;
        const transaction_id = response.data.id;
        const operation_date = response.data.operation_date;
        const currencyArr = { 1: "PEN", 2: "USD" };
        if (status === "completed") {
          const openpayData = {
            statusPayment: "PAGADO",
            openpay: {
              order_id: order_id,
              transaction_id: transaction_id,
              operation_date: operation_date,
              status: status,
              btnPay: false,
              currency: currencyArr[currency],
            },
          };
          await update(ref(db, `bookingNow/${uuidParam}`), openpayData);
          snackBarDialog("EL PAGO DEL CLIENTE FUE COMPLETADO");
        } else {
          snackBarDialog("NO SE LOGO VERIFICAR EL PAGO", "error");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 35,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });
  const [dataSelected, setDataSelected] = useState([]);
  const [tableData, setTableData] = useState(null);

  const getAdvanceCashItems = async (orderNumber) => {
    const resp = await getAdvanceCashOrder(orderNumber);
    console.log(resp);
    return resp;
  };

  useEffect(() => {
    if (dataSelected.length > 0) {
      const promises = Object.values(dataSelected).map(async (book) => {
        const advanceList = await getAdvanceCashItems(book.counterBooking);

        let advance = 0;
        if (advanceList.data.resp.length > 0) {
          advanceList.data.resp.forEach((adv) => {
            advance += Number(adv.amount);
          });
        }

        const total = Number(book.total);
        const advanceAmount = advance + Number(book.advance ? book.advance : 0);
        const discountAmount = Number(book.discount);

        console.log("advance", advance);
        return { total, advance: advanceAmount, discount: discountAmount };
      });

      Promise.all(promises).then((values) => {
        let total = 0;
        let advance = 0;
        let discount = 0;

        console.log("values", values);

        values.forEach(
          ({
            total: bookTotal,
            advance: bookAdvance,
            discount: bookDiscount,
          }) => {
            total += Number(bookTotal);
            advance += Number(bookAdvance);
            discount += Number(bookDiscount);
          }
        );

        setTableData({
          id: "5df3180a09ea16dc4b95f910",
          invoice_no: "201906-28",
          company: "TAXIMUNDO",
          email: "info@taximundo.com",
          phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
          address: "Servicios Generales Trans Mundo E.I.R.L.",
          items: dataSelected,
          igv: 0,
          total: total,
          discount,
          advance,
          peaje: 0,
          subTotal: 0,
          from: 0,
          to: 0,
        });
      });
    } else {
      setDataSelected([]);
      setTableData(null);
    }
  }, [dataSelected]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="RESERVAS CLIENTES" subtitle="" />

        <Box width={"200px"}>
          <PDFDownloadLink
            document={
              <Document>
                {dataSelected.length > 0 && tableData != null ? (
                  <Page size="A4" style={styles.page}>
                    <HeaderPdf />
                    <DocumentName
                      title={"RESERVAS CLIENTES"}
                      from={"15/11/2023"}
                      to={"15/11/2023"}
                    />
                    <TableBooking invoice={tableData} />
                  </Page>
                ) : (
                  <></>
                )}
              </Document>
            }
            fileName={"detalles-cotizacion-taximundo.pdf"}
          >
            <Box width={"100%"}>
              <Button fullWidth variant="contained" color="success">
                <PictureAsPdfIcon /> DESCARGAR
              </Button>
            </Box>
          </PDFDownloadLink>
          {dataSelected.length > 0 && tableData != null && (
            <Voucher data={tableData} />
          )}
        </Box>
      </Box>
      <Box display="grid" gap="10px">
        <Box>
          <Box>
            <Box
              m="10px 0 0 0"
              height="100vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid
                checkboxSelection
                getRowId={(row) => row.uuid}
                rows={sortBooking}
                columns={columnsClient}
                onSelectionModelChange={(ids) => {
                  setDataSelected([]);
                  ids.map((id) => {
                    const select = booking.find((row) => row.uuid === id);
                    console.log(select);
                    //buscar todos los pagos realizados
                    setDataSelected((oldData) => [...oldData, select]);
                  });
                }}
              />
            </Box>
            {/* MODAL PUBLISHMENT */}
            <Modal
              open={openPublishmentModal}
              onClose={handleClosePublishmentModal}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box
                sx={{
                  ...ModalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-title">PUBLICAR RESERVA</h2>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        updatePublishmentQuote(true);
                      }}
                      disabled={isLoading}
                    >
                      PUBLICAR
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        updatePublishmentQuote(false);
                      }}
                      disabled={isLoading}
                    >
                      ANULAR
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      onClick={handleClosePublishmentModal}
                      disabled={isLoading}
                    >
                      CERRAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* eliminar reserva */}
            <Modal
              open={openDeleteModal}
              onClose={handleCloseDeleteModal}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box
                sx={{
                  ...ModalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-title">
                      ELIMINAR RESERVA: {counterBookingParam}
                    </h2>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={deleteQuote}
                    >
                      ACEPTAR
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      onClick={handleCloseDeleteModal}
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* CONFIRMAR / CREDITO / CANCELADO */}
            <Modal
              open={openPaymentModal}
              onClose={handleClosePaymentModal}
              aria-labelledby="modal-title"
            >
              <Box
                sx={{
                  ...ModalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={11} textAlign={"center"}>
                    <h2 id="modal-title">CONFIRMAR PAGO</h2>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton color="error" onClick={handleClosePaymentModal}>
                      <CloseOutlinedIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={(e) => updateQuote("PAGADO")}
                    >
                      PAGADO
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={(e) => updateQuote("CREDITO")}
                    >
                      CREDITO
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      onClick={(e) => updateQuote("CANCELADO")}
                    >
                      CANCELADO
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* ACTIVAR BTN DE PAGO CLIENTE */}
            <Modal
              open={openActiveBtnModal}
              onClose={handleCloseActiveBtnModal}
              aria-labelledby="modal-title"
            >
              <Box
                sx={{
                  ...ModalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-title">ACTIVAR BOTON PAGO</h2>
                    <h3 id="sub-title" style={{ margin: 0 }}>
                      {currency[currencyType]} {currencyPrice}
                    </h3>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={(e) =>
                        updateActiveBtnQuote(currency[currencyType])
                      }
                    >
                      ACTIVAR PAGO
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      onClick={handleCloseActiveBtnModal}
                    >
                      CERRAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>

            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleCloseSnk}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TableBookLater;

// const listLatLng = await checkDriversLatLng();
// let listToken = [];
// const promise = listLatLng.map((latLng) => {
//   const distance = haversineDistance(
//     clientOrigin.latitude,
//     clientOrigin.longitude,
//     latLng.latitude,
//     latLng.longitude
//   );

//   if (distance < 9) listToken.push(latLng.token);

//   return listToken;
// });
// Promise.all(promise).then(async (values) => {
//   if (listToken.length > 0) {
//   }
// });
