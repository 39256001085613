import { createTour } from "../repository/tour.repository";

export const CreateTourUseCase = async (tour) => {
  try {
    const response = await createTour(tour);
    console.log(response);
  } catch (error) {
    console.log("useCase", error);
  }
};
