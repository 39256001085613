import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { db } from "../../firebase";
import { ref, onValue, remove, update } from "firebase/database";
import {
  Box,
  Button,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  useTheme,
  Modal,
  TextField,
  Grid,
  FormControl,
  Select,
  InputLabel,
  Typography,
  Popper,
  Paper,
  Tooltip,
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { ModalStyle } from "./TableBookStyle";
import SnackBar from "../snackbar/SnackBar";
import { putBookingNow } from "../../api/booking/bookingNow";
import { sendICSEmail } from "../../api/resources/ics/ics";
import { dateFormat, timeFormat } from "../../helpers/date/date";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
} from "@react-pdf/renderer";
import HeaderPdf from "./pdf/elements/Header";
import Title from "./pdf/elements/Title";
import Contact from "./pdf/elements/Contact";
import DocumentName from "./pdf/elements/DocumentName";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Rider from "./pdf/elements/Rider";
import TableRider from "./pdf/elements/TableRider";
import { UpdateQuoteBackup } from "../../views/users/admin/backup/usecases/UpdateQuoteBackup";
import Header from "../header/Header";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) 
      return undefined;
    

    function handleKeyDown(nativeEvent) {
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc")
        setShowFullCell(false);
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const TableBookNow = ({ type_booking }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [booking, setBooking] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openQuoteModal, setOpenQuoteModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [UID, setUID] = useState("");
  const [orderNumber, setOrderNumber] = useState(0);
  const [bookingType, setBookingType] = useState(0);
  const [clientID, setClientID] = useState("");
  const [currencyType, setCurrencyType] = useState("");
  const [currencyPrice, setCurrencyPrice] = useState("");
  const [bookBill, setBookBill] = useState({});
  const [payment, setPayment] = useState("PAGADO");
  const [discount, setDiscount] = useState(0);

  const handleClose = () => setAnchorEl(null);

  const currency = {
    1: "PEN",
    2: "USD",
  };

  const handleWhatsappClient = (booking, uuid) => {
    booking.map((book) => {
      if (book.uuid === uuid) {
        if (book.clientPhone) {
          if (book.driver) {
            window.open(
              `https://api.whatsapp.com/send?phone=${book.clientPhone}=&text=*Cliente:* ${book.clientName} ,%0A *Origen:* ${book.origin.name}%0A *Destino:* ${book.destination.name}%0A *Fecha:* ${book.date} - *Hora:* ${book.time}%0A *Pasajeros:* ${book.persons}%0A *Conductor:* ${book.driver.name}%0A *Marca:* ${book.driver.brand}%0A  *Carroceria:* ${book.driver.bodywork}%0A *Placa:* ${book.driver.plate}%0A *Color:* ${book.driver.color}`,
              "_blank",
              "noreferrer"
            );
          } else {
            window.open(
              `https://api.whatsapp.com/send?phone=${book.clientPhone}=&text=*Cliente:* ${book.clientName} ,%0A *Origen:* ${book.origin.name}%0A *Destino:* ${book.destination.name}%0A *Fecha:* ${book.date} - *Hora:* ${book.time}%0A *Pasajeros:* ${book.persons}`,
              "_blank",
              "noreferrer"
            );
          }
        } else {
          window.alert("no phone");
        }
      }
    });
    setAnchorEl(null);
  };

  const handleWhatsappDriver = (booking, uuid) => {
    booking.map((book) => {
      if (book.uuid === uuid) {
        if (book.driver) {
          window.open(
            `https://api.whatsapp.com/send?phone=${
              book.driver.phone
            }=&text=*Cliente:* ${book.clientName} ,%0A *Origen:* ${
              book.origin.name
            }%0A *Destino:* ${book.destination.name}%0A *Fecha:* ${
              book.date
            } - *Hora:* ${book.time}%0A *Pasajeros:* ${book.persons}%0A ${
              book.payment_type === 1
                ? `*Servicio:* EFECTIVO`
                : `*Servicio:* CREDITO`
            }%0A ${
              book.payment_type === 1
                ? `*Total Cobrar:* ${book.total}`
                : `*Total Cobrar:* ${book.price}`
            }`,
            "_blank",
            "noreferrer"
          );
        } else {
          window.alert("no phone");
        }
      }
    });
    setAnchorEl(null);
  };

  const handleOpenModal = (booking, uuid) => {
    booking.map((book) => {
      if (book.uuid === uuid) {
        setAnchorEl(null);
        setOpenModal(true);
        setBookBill(book);
        setPayment(book.payment_type);
      }
    });
  };

  const handleCloseModal = () => setOpenModal(false);
  const handleCloseQuoteModal = () => setOpenQuoteModal(false);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  useEffect(() => {
    if (type_booking === 1) {
      const starCountRef = ref(db, "bookingNow/");
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        setBooking([]);
        if (data !== null) {
          Object.values(data).map((booking) => {
            if (booking.booking_type === 1) {
              booking.originName = booking.origin.name;
              booking.originLat = booking.origin.lat;
              booking.originLng = booking.origin.lng;
              booking.destinationName = booking.destination.name;
              booking.destinationLat = booking.destination.lat;
              booking.destinationLng = booking.destination.lng;
              setBooking((oldArray) => [...oldArray, booking]);
            }
          });
        }
      });
    } else {
      try {
        const starCountRef = ref(db, "bookingNow/");
        onValue(starCountRef, (snapshot) => {
          const data = snapshot.val();
          setBooking([]);
          if (data !== null) {
            Object.values(data).map((booking) => {
              if (
                booking.booking_type != 1 &&
                booking.statusPayment != "PAGADO" &&
                !booking.cotizado
              ) {
                booking.currency = currency[booking.currency];
                booking.fullorigin = booking?.origin?.name;
                booking.originLat = booking.origin.lat;
                booking.originLng = booking.origin.lng;

                booking.fulldestination = booking.destination.name;
                booking.destinationLat = booking.destination.lat;
                booking.destinationLng = booking.destination.lng;
                if (booking.booking_type === 3)
                  booking.note = `HORA: ${booking.dateEnd} ${
                    booking.timeEnd
                  } - ${booking.note} ${
                    booking.noteAdmin ? `,Admin: ${booking.noteAdmin}` : ""
                  }`;
                if (booking.booking_type === 4)
                  booking.note = `TOUR: ${booking.tour_description}. ${
                    booking.note
                  } ${booking.noteAdmin ? `,Admin: ${booking.noteAdmin}` : ""}`;

                setBooking((oldArray) => [...oldArray, booking]);
              }
            });
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };

  const colBooking = [
    {
      headerName: "Pedido x",
      field: "counterBooking",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "Fecha",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Hora",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Nombre",
      field: "clientName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 180,
      renderCell: ({ row: { clientName, clientPhone } }) => {
        return (
          <a
            href={`https://api.whatsapp.com/send?phone=${clientPhone}&text=*`}
            target="_blank"
            style={{ color: "white", textDecoration: "none" }}
          >
            {clientName}
          </a>
        );
      },
    },
    {
      headerName: "Origen",
      field: "originName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
      renderCell: ({ row: { originName, originLat, originLng } }) => {
        const url = `https://maps.google.com/?q=${originLat},${originLng}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 13,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {originName}
          </a>
        );
      },
    },
    {
      headerName: "Destino",
      field: "destinationName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
      renderCell: ({
        row: { destinationName, destinationLat, destinationLng },
      }) => {
        const url = `https://maps.google.com/?q=${destinationLat},${destinationLng}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 13,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {destinationName}
          </a>
        );
      },
    },
    {
      headerName: "Pack",
      field: "persons",
      headerAlign: "left",
      align: "center",
      width: 20,
    },
    {
      headerName: "Notas",
      field: "note",
      width: 180,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Conductor",
      field: "statusService",
      width: 90,
      renderCell: ({ row: { statusService } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              width: "100%",
              textAlign: "right",
              color: "white",
              background: statusService === "PENDIENTE" ? "red" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {statusService}
          </div>
        );
      },
    },
    {
      headerName: "Precio",
      field: "statusPayment",
      width: 90,
      renderCell: ({ row: { statusPayment, total } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              width: "100%",
              textAlign: "right",
              color: "white",
              background: total == 0 ? "red" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {total > 0 ? "ASIGNADO" : "PENDIENTE"}
          </div>
        );
      },
    },
    {
      headerName: "TARJETA",
      field: "statusPayment2",
      width: 90,
      renderCell: ({ row: { openpay, statusPayment } }) => {
        return (
          <Tooltip
            title={
              openpay ? (
                openpay?.status === "pending" ? (
                  ""
                ) : (
                  <Typography fontSize={12}>
                    {dateFormat(new Date(openpay?.operation_date))}&nbsp;
                    {timeFormat(new Date(openpay?.operation_date))}
                  </Typography>
                )
              ) : (
                ""
              )
            }
            arrow
          >
            <div
              style={{
                fontSize: 14,
                width: "100%",
                textAlign: "center",
                color: "white",
                background: openpay
                  ? openpay?.status === "pending"
                    ? "red"
                    : "blue"
                  : "red",
                borderRadius: 3,
                padding: 2,
              }}
            >
              {openpay
                ? openpay?.status === "pending"
                  ? "PENDIENTE"
                  : "PAGADO"
                : "PENDIENTE"}
            </div>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Creado",
      field: "createBooking",
      width: 80,
    },
    {
      headerName: "",
      field: "actions",
      width: 90,
      renderCell: ({ row: { uuid, currency, total } }) => {
        return (
          <div>
            <Button
              color="secondary"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => {
                setUID(uuid);
                setAnchorEl(event.currentTarget);
              }}
            >
              OPCIONES
            </Button>
            <Menu
              id="menu-options"
              MenuListProps={{ "aria-labelledby": "fade-button" }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  navigate(`/admin/add-booking/${UID}`);
                }}
              >
                <BorderColorIcon sx={{ color: "#FF0404" }} /> &nbsp;VER
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  handleWhatsappClient(booking, UID);
                }}
              >
                <WhatsAppIcon sx={{ color: "#FF0404" }} /> &nbsp;PASAJERO
              </MenuItem>

              <MenuItem onClick={(e) => handleWhatsappDriver(booking, UID)}>
                <WhatsAppIcon sx={{ color: "#FF0404" }} /> &nbsp;CONDUCTOR
              </MenuItem>

              <MenuItem
                onClick={async () => {
                  handleOpenModal(booking, UID);
                }}
              >
                FACTURA
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  setCurrencyType(currency);
                  setCurrencyPrice(total);
                  setOpenActiveBtnModal(true);
                }}
              >
                PAGO CON TARJETA
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  setOpenDeleteModal(true);
                }}
              >
                <DeleteIcon sx={{ color: "#FF0404" }} /> &nbsp;ELIMINAR
              </MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];

  const columnsClient = [
    {
      headerName: "Pedido",
      field: "counterBooking",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      headerName: "FECHA",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "HORA",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "Nombre",
      field: "clientName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
      renderCell: ({ row: { clientName, clientPhone } }) => {
        return (
          <a
            href={`https://api.whatsapp.com/send?phone=${clientPhone}&text=*`}
            target="_blank"
            style={{ color: "white", textDecoration: "none" }}
          >
            {clientName}
          </a>
        );
      },
    },
    {
      headerName: "Telefono",
      field: "clientPhone",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 110,
      renderCell: ({ row: { clientPhone } }) => {
        const phone = clientPhone.split(" ");
        return (
          <Stack direction="row" spacing={0}>
            <Typography
              color="success"
              aria-label="add"
              component="label"
              onClick={() => {
                window.open(`https://wa.me/${phone[0]}${phone[1]}`);
              }}
              target="_blank"
            >
              {clientPhone}
            </Typography>
          </Stack>
        );
      },
    },
    {
      headerName: "Origen",
      field: "fullorigin",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: ({ row: { fullorigin, originLat, originLng } }) => {
        const url = `https://maps.google.com/?q=${originLat},${originLng}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 13,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {fullorigin}
          </a>
        );
      },
    },
    {
      headerName: "Destino",
      field: "fulldestination",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: ({
        row: { fulldestination, destinationLat, destinationLng },
      }) => {
        const url = `https://maps.google.com/?q=${destinationLat},${destinationLng}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 13,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {fulldestination}
          </a>
        );
      },
    },
    {
      headerName: "Pack",
      field: "persons",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      headerName: "Notas",
      field: "note",
      type: "string",
      headerAlign: "left",
      width: 250,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Moneda",
      field: "currency",
      type: "string",
      headerAlign: "left",
      align: "center",
      width: 70,
    },
    {
      headerName: "Precio",
      field: "total",
      type: "string",
      headerAlign: "left",
      align: "center",
      width: 50,
    },
    {
      headerName: "Estado",
      field: "statusPayment",
      width: 90,
      renderCell: ({ row: { statusPayment } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              width: "100%",
              textAlign: "right",
              color: "white",
              background: statusPayment === "PENDIENTE" ? "red" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {statusPayment}
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 190,
      renderCell: ({
        row: {
          booking_type,
          client,
          uuid,
          counterBooking,
          clientName,
          clientEmail,
          clientPhone,
          fullorigin,
          fulldestination,
          date,
          time,
          persons,
          vehicle,
          note,
        },
      }) => {
        return (
          <Stack direction="row" spacing={1}>
            {/* EDIT QUOTE */}
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                navigate(`/admin/add-quote-hour/${uuid}`);
              }}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
            {/* CONFIRM QUOTE */}
            <IconButton
              component="label"
              color="success"
              sx={{ background: "#ffff" }}
              onClick={() => {
                setUID(uuid);
                setOrderNumber(counterBooking);
                setBookingType(booking_type);
                setClientID(client);
                setEmailBooking({
                  counterBooking: counterBooking,
                  clientName: clientName,
                  clientEmail: clientEmail,
                  clientPhone: clientPhone,
                  origin: { name: fullorigin },
                  destination: { name: fulldestination },
                  date: date,
                  time: time,
                  persons: persons,
                  vehicle: vehicle,
                  note: note,
                  web: "Booking",
                });
                setOpenQuoteModal(true);
              }}
            >
              <CheckCircleOutlineOutlinedIcon />
            </IconButton>
            {/* DELETE */}
            <IconButton
              aria-label="delete"
              component="label"
              onClick={() => {
                setUID(uuid);
                setBookingType(booking_type);
                setClientID(client);
                setOpenDeleteModal(true);
              }}
            >
              <DeleteIcon sx={{ color: "#FF0404" }} />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const updateFacture = async (uuid, payment, discount) => {
    try {
      bookBill.payment_type = payment;
      bookBill.discount = discount;
      const token = "asdf";

      await putBookingNow(token, discount, payment, bookBill.counterBooking);
      const dbRef = ref(db, `bookingNow/${uuid}`);
      await remove(dbRef);
      handleCloseModal();
      setDiscount(0);
      setPayment("PAGADO");
      snackBarDialog("PEDIDO FACTURADO");
    } catch (error) {
      console.log(error);
      handleCloseModal();
      snackBarDialog("ERROR, NO SE GUARDO LOS CAMBIOS", "error");
    }
  };

  const [emailBooking, setEmailBooking] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleCloseSnk = () => setStateSnk(false);

  const updateQuote = async () => {
    setIsLoading(true);
    try {
      const value = { cotizado: true };
      const hourRef = `bookingNow/${UID}`;
      await update(ref(db, hourRef), value);
      const backup = {
        id: orderNumber,
        quote: true,
      };
      const response = await UpdateQuoteBackup(backup);
      console.log(response);
      if (response.message === "success") snackBarDialog("BACKUP ACTUALIZADO");
      else snackBarDialog("NO SE LOGRO ACTUALIZAR EL BACKUP", "error");

      await sendICSEmail("token", emailBooking);
      snackBarDialog("COTIZACION CONFIRMADA");
      setIsLoading(false);
      handleCloseQuoteModal();
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      snackBarDialog("ERROR: NO SE PUEDE CONFIRMAR LA COTIZACION", "error");
      handleCloseDeleteModal();
    }
  };

  const deleteQuote = async () => {
    setIsLoading(true);
    try {
      await remove(ref(db, `bookingNow/${UID}`));
      setIsLoading(false);
      handleCloseDeleteModal();
      snackBarDialog("COTIZACION ELIMINADA");
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      snackBarDialog("ERROR: NO SE PUEDE ELIMINA LA COTIZACION", "error");
      handleCloseDeleteModal();
    }
  };

  const [openActiveBtnModal, setOpenActiveBtnModal] = useState(false);
  const handleCloseActiveBtnModal = () => setOpenActiveBtnModal(false);

  const updateActiveBtnQuote = async (currency) => {
    try {
      const openpayData = {
        openpay: {
          status: "pending",
          btnPay: true,
          currency,
          transaction_id: "",
          order_id: "",
          url: "",
        },
      };

      await update(ref(db, `bookingNow/${UID}`), openpayData);
      handleCloseActiveBtnModal();
      snackBarDialog("BOTON ACTIVADO PARA PAGOS");
    } catch (error) {
      handleCloseActiveBtnModal();
      console.log(error);
      snackBarDialog("ERROR DE ACTIVACION DE BOTON DE PAGO ", "error");
    }
  };

  const [dataSelected, setDataSelected] = useState([]);

  const [tableData, setTableData] = useState(null);
  useEffect(() => {
    let total = 0;
    Object.values(dataSelected).map((book) => {
      total += Number(book.total);
    });

    setTableData({});
    setTableData({
      id: "5df3180a09ea16dc4b95f910",
      invoice_no: "201906-28",
      company: "TAXIMUNDO",
      email: "info@taximundo.com",
      phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
      address: "Servicios Generales Trans Mundo E.I.R.L.",
      items: dataSelected,
      igv: 0,
      total: total,
      peaje: 0,
      subTotal: 0,
      from: 0,
      to: 0,
    });
  }, [dataSelected]);

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 35,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title={type_booking == 1 ? "Pedidos Ahora" : "Cotizacion Cliente"}
          subtitle=""
        />
        {dataSelected.length > 0 && (
          <Box width="200px">
            <PDFDownloadLink
              document={
                <Document>
                  {dataSelected.length > 0 ? (
                    <Page size="A4" style={styles.page}>
                      <HeaderPdf />
                      <Contact invoice={tableData} />
                      <DocumentName
                        title={"COTIZACION"}
                        from={tableData.from}
                        to={tableData.to}
                      />
                      <Rider invoice={tableData} />
                      <TableRider invoice={tableData} />
                    </Page>
                  ) : (
                    <></>
                  )}
                </Document>
              }
              fileName={"detalles-cotizacion-taximundo.pdf"}
            >
              <Box width={"100%"}>
                <Button fullWidth variant="contained" color="success">
                  <PictureAsPdfIcon /> DESCARGAR
                </Button>
              </Box>
            </PDFDownloadLink>
          </Box>
        )}
      </Box>
      <Box display="grid" gap="10px">
        <Box gridColumn="span 12" gridRow="span 2">
          <Box>
            <Box
              m="10px 0 0 0"
              height="100vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid
                getRowId={(row) => row.uuid}
                rows={booking}
                columns={type_booking === 1 ? colBooking : columnsClient}
                checkboxSelection={true}
                onSelectionModelChange={(ids) => {
                  setDataSelected([]);
                  ids.map((id) => {
                    const select = booking.find((row) => row.uuid === id);
                    // console.log(select);
                    setDataSelected((oldData) => [...oldData, select]);
                  });
                }}
              />
            </Box>
            {/* MODAL PARA  PAGO*/}
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box
                sx={{
                  ...ModalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-title">FACTURA</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={bookBill.counterBooking}
                      fullWidth
                      disabled
                      label="Nro Pedido"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={bookBill.clientName}
                      fullWidth
                      disabled
                      label="Client"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={discount}
                      fullWidth
                      type={"number"}
                      label="Descuento"
                      variant="outlined"
                      onChange={(e) => {
                        setDiscount(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Tipo Pago</InputLabel>
                      <Select
                        value={payment}
                        id="payment_type"
                        name="payment_type"
                        label="Tipo de Pago"
                        onChange={(e) => setPayment(e.target.value)}
                      >
                        <MenuItem value={"PAGADO"}>PAGADO</MenuItem>
                        <MenuItem value={"CREDITO"}>CREDITO</MenuItem>
                        <MenuItem value={"CANCELADO"}>CANCELADO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: {
                        xs: payment === 2 ? "block" : "none",
                        md: payment === 2 ? "block" : "none",
                      },
                    }}
                  >
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      Pago con tarjeta
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        updateFacture(UID, payment, discount);
                      }}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* MODAL PARA CONFIRMAR COTIZACION */}
            <Modal
              open={openQuoteModal}
              onClose={handleCloseQuoteModal}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box
                sx={{
                  ...ModalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-title">CONFIRMAR COTIZACION</h2>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        updateQuote();
                      }}
                      disabled={isLoading}
                    >
                      ACEPTAR
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      onClick={handleCloseQuoteModal}
                      disabled={isLoading}
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* MODAL ELIMINAR PEDIDO */}
            <Modal
              open={openDeleteModal}
              onClose={handleCloseDeleteModal}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box
                sx={{
                  ...ModalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-title">ELIMINAR COTIZACION </h2>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={deleteQuote}
                    >
                      ACEPTAR
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      onClick={handleCloseDeleteModal}
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* MODAL ACTIVAR BOTON DE PAGO */}
            {/* ACTIVAR BTN DE PAGO CLIENTE */}
            <Modal
              open={openActiveBtnModal}
              onClose={handleCloseActiveBtnModal}
              aria-labelledby="modal-title"
            >
              <Box
                sx={{
                  ...ModalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-title" style={{ margin: 0 }}>
                      ACTIVAR BOTON PAGO
                    </h2>
                    <h3 id="sub-title" style={{ margin: 0 }}>
                      {currency[currencyType]} {currencyPrice}
                    </h3>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={(e) =>
                        updateActiveBtnQuote(currency[currencyType])
                      }
                    >
                      ACTIVAR PAGO
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      onClick={handleCloseActiveBtnModal}
                    >
                      CERRAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleCloseSnk}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TableBookNow;
