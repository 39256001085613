import { readRiderGraph } from "../repository/graph.repository";

export const ReadRiderGraph = async () => {
  try {
    const response = await readRiderGraph();
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
