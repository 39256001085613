import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Modal,
  Stack,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import DataTable from "../../../../components/datatable/DataTable";
import { getTokenRefresh } from "../../../../helpers/token/refreshToken";
import {
  deleteEnterpriseApi,
  getListEnterprises,
} from "../../../../api/enterprise/enterprise";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { modalStyle } from "./EmployeeStyle";
import { tokens } from "../../../../theme";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

const EnterpriseList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const [employees, setEmployees] = useState([]);
  const [UID, setUID] = useState("");
  const [enterpriseName, setEnterpriseName] = useState("");
  const [IGV, setIGV] = useState("");
  const [STATUS, setSTATUS] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuClose = () => setAnchorEl(null);

  const enterpriseStatus = {
    1: "Activo",
    2: "Suspendido",
    3: "Eliminado",
  };

  useEffect(() => {
    const getEnterprises = async () => {
      try {
        const token = getTokenRefresh();
        const response = await getListEnterprises(token, 1);
        console.log(response.data);
        setEmployees([]);
        let count = 0;
        Object.values(response.data).map((enterprise) => {
          if (enterprise.id) {
            if (enterprise.status !== 3) {
              enterprise.index = ++count;
              enterprise.enterpriseStatus = enterpriseStatus[enterprise.status];
              setEmployees((oldData) => [...oldData, enterprise]);
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    getEnterprises();
  }, []);

  const columns = [
    { field: "index", headerName: "ID", width: 50 },
    {
      headerName: "RUC",
      field: "ruc",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "NOMBRE COMERCIAL",
      field: "commertial_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "RAZON SOCIAL",
      field: "business_name",
      width: 200,
    },
    {
      headerName: "EMAIL",
      field: "email",
      width: 200,
      align: "left",
    },
    {
      headerName: "TELEFONO",
      field: "phone",
      width: 100,
      align: "center",
    },
    {
      headerName: "PEDIDOS",
      field: "booking_count",
      width: 100,
      align: "center",
    },
    {
      headerName: "EMPLEADOS",
      field: "employees_count",
      width: 100,
      align: "center",
    },
    {
      headerName: "PAGO",
      field: "payment_type",
      width: 100,
      align: "left",
    },
    {
      headerName: "ESTADO",
      field: "enterpriseStatus",
      width: 100,
      align: "left",
    },
    {
      headerName: "Acciones",
      field: "actions",
      width: 100,
      align: "center",
      renderCell: ({
        row: { id, commertial_name, status, igv, cost_center },
      }) => {
        return (
          <Stack direction="row" spacing={1}>
            <Button
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setUID(id);
                setEnterpriseName(commertial_name);
                setSTATUS(status);
                setIGV(igv);
                setCostCenter(cost_center);
              }}
            >
              OPCIONES
            </Button>
          </Stack>
        );
      },
    },
  ];
  const [costCenter, setCostCenter] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const handleModalClose = () => setOpenModal(false);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const updateStatusDriver = async (status) => {
    console.log("delete");
    setIsLoading(true);
    try {
      await deleteEnterpriseApi(UID, status);
      snackBarDialog("ESTADO ACTUALIZADO");
      employees.map((enterprise) => {
        if (enterprise.id === UID) {
          enterprise.status = status;
          enterprise.enterpriseStatus = enterpriseStatus[status];
        }
      });
      setIsLoading(false);
      setOpenModal(false);
    } catch (error) {
      snackBarDialog("Error no se podido Suspender", "error");
      console.log(error);
      setIsLoading(false);
    }
  };

  const deleteEnterprise = async () => {
    console.log("delete");
    setIsLoading(true);
    try {
      await deleteEnterpriseApi(UID, 3);
      snackBarDialog("EMPRESA ELIMINADA");
      setEmployees(employees.filter((enterprise) => enterprise.id !== UID));
      setIsLoading(false);
      setOpenModal(false);
    } catch (error) {
      snackBarDialog("ERROR", "error");
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={10} md={10}>
                  <Header title="EMPRESAS" subtitle="" />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={(e) => {
                      navigate("/admin/add-enterprise");
                    }}
                  >
                    AGREGAR EMPRESA
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <DataTable data={employees} columns={columns} />
              </Box>

              <Modal open={openModal} onClose={handleModalClose}>
                <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
                  <Typography variant="h6" component="h2" textAlign={"center"}>
                    ELIMINAR EMPRESA?
                  </Typography>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={4} md={4} textAlign="center">
                      {STATUS === 1 ? (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => updateStatusDriver(2)}
                          disabled={isLoading}
                        >
                          SUSPENDER
                        </Button>
                      ) : (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => updateStatusDriver(1)}
                          disabled={isLoading}
                        >
                          HABILITAR
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={4} md={4} textAlign="center">
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={deleteEnterprise}
                        disabled={isLoading}
                      >
                        ELIMINAR
                      </Button>
                    </Grid>
                    <Grid item xs={4} md={4} textAlign="center">
                      <Button
                        color="error"
                        variant="contained"
                        onClick={handleModalClose}
                        disabled={isLoading}
                      >
                        CANCELAR
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => {
                    navigate(`/admin/history-business/${UID}`, {
                      state: {
                        userData: IGV,
                        enterpriseName,
                      },
                    });
                  }}
                >
                  <ListItemIcon>
                    <HistoryOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  HISTORIAL PEDIDOS
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(`/admin/add-employee/${UID}`, {
                      state: { enterpriseName },
                    });
                  }}
                >
                  <ListItemIcon>
                    <PersonAddOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  AGREGAR EMPLEADOS
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(`/admin/list-employee/${UID}`, {
                      state: { enterpriseName },
                    });
                  }}
                >
                  <ListItemIcon>
                    <PeopleAltOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  LISTA DE EMPLEADOS
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(`/admin/roles/${UID}`, {
                      state: { enterpriseName },
                    });
                  }}
                >
                  <ListItemIcon>
                    <AdminPanelSettingsOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  CREAR ROLES
                </MenuItem>

                {costCenter == 1 && (
                  <MenuItem
                    onClick={() => {
                      navigate(`/admin/centro-costo/${UID}`, {
                        state: { enterpriseName },
                      });
                    }}
                  >
                    <ListItemIcon>
                      <AdminPanelSettingsOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    C.COSTO/SEDE
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() => {
                    navigate(`/admin/address/${UID}`, {
                      state: { enterpriseName },
                    });
                  }}
                >
                  <ListItemIcon>
                    <AssistantDirectionIcon fontSize="small" />
                  </ListItemIcon>
                  DIRECCIONES
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(`/admin/enterprise/${UID}`, {
                      state: { enterpriseName },
                    });
                  }}
                >
                  <ListItemIcon>
                    <BorderColorOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  EDITAR
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    navigate(
                      `/admin/enterprise/rate/add/${UID}/${enterpriseName}`
                    )
                  }
                >
                  <ListItemIcon>
                    <BorderColorOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  TARIFAS
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    navigate(
                      `/admin/enterprise/route/add/${UID}/${enterpriseName}`
                    )
                  }
                >
                  <ListItemIcon>
                    <BorderColorOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  RUTAS
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  ELIMINAR
                </MenuItem>
              </Menu>
              <SnackBar
                stateSnk={stateSnk}
                vertical={vertical}
                horizontal={horizontal}
                duration={5000}
                handleClose={handleSnkClose}
                severity={severity}
                messageSnk={messageSnk}
              />
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default EnterpriseList;
