import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  useTheme,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Grid,
  FormControlLabel,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  Snackbar,
  Modal,
  Typography,
} from "@mui/material";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";

import { getTokenRefresh } from "../../../../../helpers/token/refreshToken";
import { tokens } from "../../../../../theme";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import { getEmployeesFromEnterprise } from "../../../../../api/users/employee/employeeApi";
import { addressInitial, addressSchema } from "./AddressValues";
import { createAddress } from "../../../../../api/enterprise/address";
import { modalStyle } from "./AddressStyle";

const AddressForm = ({ address, enterprise_id }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [radio, setRadio] = useState("globalApp");
  const [employees, setEmployees] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const checkoutSchema = yup.object().shape(addressSchema(yup));

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  useEffect(() => {
    const getEmployees = async () => {
      try {
        //necesito el enterprise_id
        const token = getTokenRefresh();
        const response = await getEmployeesFromEnterprise(token, enterprise_id);
        setEmployees(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmployees();
  }, []);

  const similarity = async (str1, str2) => {
    const set1 = new Set(str1.split(""));
    const set2 = new Set(str2.split(""));

    const intersection = new Set([...set1].filter((char) => set2.has(char)));
    const union = new Set([...set1, ...set2]);

    return intersection.size / union.size;
  };

  const areSimilarSimple = (msg1, msg2) => {
    return (
      msg1.toLowerCase().includes(msg2.toLowerCase()) ||
      msg2.toLowerCase().includes(msg1.toLowerCase())
    );
  };

  const checkSimilarity = async (name) => {
    //setSimilarityList([]);
    let similarityList = [];
    Object.values(address).map(async (item) => {
      const indice = areSimilarSimple(
        name.toUpperCase(),
        item.name.toUpperCase()
      );
      if (indice) {
        similarityList.push(item.name);
      }
    });
    return similarityList;
  };

  const [addressName, setAddressName] = useState([]);
  const [addressValue, setAddressValue] = useState();

  const validateCoords = (values) => {
    console.log(values);
    if (values.latitude === "")
      return snackBarDialog("INGRESE LATITUD Y LONGITUD", "error");

    const coord = values.latitude.split(",");

    if (coord[0] === "" || coord[1] === "")
      return snackBarDialog("COORDENADAS INCORRECTAS", "error");

    return true;
  };

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    setAddressName([]);
    if (validateCoords(values)) {
      const similarityList = await checkSimilarity(values.name);
      if (similarityList.length > 0) {
        setAddressValue();
        setOpen(true);
        setAddressName(similarityList);
        setAddressValue(values);
      } else {
        handleCreateAddress(values);
      }
    }
  };

  const handleCreateAddress = async (values) => {
    try {
      const coordinate = values.latitude.split(",");

      const token = getTokenRefresh();
      const address = {
        global: true,
        global_enterprise: false,
        url: values.url,
        name: values.name.toUpperCase(),
        latitude: Number(coordinate[0]),
        longitude: Number(coordinate[1]),
        enterprises_id: null,
        employees_id: null,
      };

      if (radio !== "globalApp") {
        address.global = false;
        address.enterprises_id = enterprise_id;
        if (radio === "globalEnterprise") {
          address.global_enterprise = true;
        } else if (radio === "employee") {
          address.employees_id = employee.id;
        }
      }

      const response = await createAddress(token, address);

      snackBarDialog("DIRECCION AGREGADA");
      values.url = "";
      values.name = "";
      values.latitude = "";
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO RE AGREGO DIRECCION", "error");
      setIsLoading(false);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setIsLoading(false);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={addressInitial}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box backgroundColor={colors.primary[400]} p={2}>
            <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <OutlinedInput
                    value={values.url}
                    id="address-url"
                    name="url"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="des"
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <LinkOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  value={values.name}
                  fullWidth
                  type="text"
                  label={t("address").toUpperCase()}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  name="name"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  value={values.latitude}
                  fullWidth
                  type="text"
                  label={"LATITUD y LONGITUD"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  name="latitude"
                />
              </Grid>

              <Grid item xs={12} sm={6} textAlign="center">
                <FormControl>
                  <FormLabel id="label-placement" color="secondary">
                    DIRECCION
                  </FormLabel>
                  <RadioGroup row name="position" defaultValue="globalApp">
                    <FormControlLabel
                      value="globalApp"
                      control={
                        <Radio
                          color="success"
                          onClick={(e) => {
                            console.log(e.target.value);
                            setRadio(e.target.value);
                          }}
                        />
                      }
                      label="GLOBAL APP"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="globalEnterprise"
                      control={
                        <Radio
                          color="success"
                          onClick={(e) => {
                            console.log(e.target.value);
                            setRadio(e.target.value);
                          }}
                        />
                      }
                      label="GLOBAL EMPRESA"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="employee"
                      control={
                        <Radio
                          color="success"
                          onClick={(e) => {
                            console.log(e.target.value);
                            setRadio(e.target.value);
                          }}
                        />
                      }
                      label="INDIVIDUAL"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {radio === "employee" ? (
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={employee}
                    disablePortal
                    name="employee"
                    id="combo-box-employee"
                    onChange={(e, newValue) => {
                      setEmployee(newValue);
                    }}
                    options={employees}
                    getOptionLabel={(option) =>
                      `${option.id}: ${option.name} ${option.lastname} - ${option.dni} - ${option.subrole_name}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("employee")} />
                    )}
                  />
                </Grid>
              ) : (
                <></>
              )}

              <Grid item xs={12} sm={12} textAlign={"center"}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ height: 45 }}
                  disabled={isLoading}
                >
                  {t("AGREGAR")}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={5000}
            handleClose={handleSnkClose}
            severity={severity}
            messageSnk={messageSnk}
          />
          <Modal open={open} onClose={handleClose}>
            <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
              <Typography variant="h4" component="h2">
                DIRECCIONES SIMILARES
              </Typography>
              <Typography variant="h6" component="h3">
                {addressName.map((elem, idx) => (
                  <div key={idx}>
                    {elem} <br />
                  </div>
                ))}
              </Typography>
              <Grid container spacing={1} alignItems="center" marginTop={1}>
                <Grid item xs={6} md={6} textAlign="center">
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={(e) => handleCreateAddress(addressValue)}
                  >
                    AGREGAR
                  </Button>
                </Grid>
                <Grid item xs={6} md={6} textAlign="center">
                  <Button
                    color="error"
                    variant="contained"
                    onClick={handleClose}
                  >
                    CANCELAR
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </form>
      )}
    </Formik>
  );
};

export default AddressForm;
