import { baseBackend } from "./base/baseUrl";
import { URL_COUNT_USERS } from "../helpers/endpoint";

export const getCountUser = async () => {
  return await baseBackend.get(
    URL_COUNT_USERS,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};
