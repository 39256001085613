import { baseBackend } from "../../../../../../api/base/baseUrl";

const URL_FILTER_HISTORY = "/api/v2/enterprise";
export const getReportHistory = async (params) => {
  return await baseBackend.post(
    `${URL_FILTER_HISTORY}/history`,
    JSON.stringify({ params }),
    { headers: { "Content-Type": "application/json" } }
  );
};
