import { baseBackend } from "../base/baseUrl";
import {
  URL_GENERATE_YEARS,
  URL_TRAVEL_DAY,
  URL_TRAVEL_MONTH,
  URL_TRAVEL_WEEK,
  URL_TRAVEL_YEAR,
} from "../../helpers/endpoint";

export const getTravelAndQuantityPerDay = async () => {
  return await baseBackend.get(URL_TRAVEL_DAY, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getTripsAndEarning= async () => {
  return await baseBackend.get("/api/v1/counter-and-earning", {
    headers: { "Content-Type": "application/json" },
  });
};

export const getTravelAndQuantityPerWeek = async () => {
  return await baseBackend.get(URL_TRAVEL_WEEK, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getTravelAndQuantityPerMonth = async () => {
  return await baseBackend.get(URL_TRAVEL_MONTH, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getTravelAndQuantityPerYear = async () => {
  return await baseBackend.get(URL_TRAVEL_YEAR, {
    headers: { "Content-Type": "application/json" },
  });
};
