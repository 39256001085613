import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  useTheme,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
  Autocomplete,
  InputLabel,
} from "@mui/material";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { addressInitial, addressSchema } from "./AddressValues";
import { tokens } from "../../../../theme";
import SnackBar from "../../../../components/snackbar/SnackBar";
import {
  createRateAddress,
  getCityList,
} from "../../../../api/users/enterprise/enterpriseApi";

const RatesForm = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const checkoutSchema = yup.object().shape(addressSchema(yup));

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const getCity = async () => {
      const response = await getCityList();
      console.log(response.data);
      setCities(response.data.city);
    };

    getCity();
  }, []);

  const validateLatLng = (lat, lng) => lat == 0 && lng == 0;

  const handleFormSubmit = async (values) => {
    try {
      if (!city) {
        snackBarDialog("SELECCIONE LA CIUDAD", "error");
        return;
      }
      values.name = values.name.toUpperCase();
      if (values.url == "") {
        if (validateLatLng(values.lat, values.lng)) {
          snackBarDialog("INGRESE URL O LATITUD Y LONGITUD", "error");
          return;
        } else {
          values.city_id = city.id;
          await createRateAddress(values);
        }
      } else {
        values.city_id = city.id;
        console.log(values);
        await createRateAddress(values);
      }

      snackBarDialog("DIRECCION AGREGADA");
      setIsLoading(false);
      values.name = "";
      values.lat = 0;
      values.lng = 0;
      values.url = "";
      values.radius = 0;
      setCity(null);
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO RE AGREGO LA DIRECCION", "error");
      setIsLoading(false);
    }
  };

  const handleMouseDownPassword = (event) => event.preventDefault();

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={addressInitial}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box backgroundColor={colors.primary[400]} p={2}>
            <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
              <Grid item xs={12} sm={4}>
                <TextField
                  value={values.name}
                  fullWidth
                  type="text"
                  label="DIRECCION"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  name="name"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="address-url">*DIRECCION</InputLabel>
                  <OutlinedInput
                    value={values.url}
                    id="address-url"
                    name="url"
                    label="*DIRECCION"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="des"
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <LinkOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                {cities && (
                  <Autocomplete
                    value={city}
                    disablePortal
                    id="select-origin"
                    onChange={(e, newValue) => {
                      setCity(newValue);
                    }}
                    options={cities}
                    getOptionLabel={(option) => `${option.name}`}
                    renderInput={(params) => (
                      <TextField {...params} label={t("CIUDAD")} />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  value={values.lat}
                  fullWidth
                  type="number"
                  label={t("latitud").toUpperCase()}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="lat"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  value={values.lng}
                  fullWidth
                  type="number"
                  label={t("longitud").toUpperCase()}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="lng"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  value={values.radius}
                  fullWidth
                  type="number"
                  label={t("Radio").toUpperCase()}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="radius"
                />
              </Grid>

              <Grid item xs={12} sm={12} textAlign={"center"}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ height: 45 }}
                  disabled={isLoading}
                >
                  {t("AGREGAR")}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={5000}
            handleClose={handleSnkClose}
            severity={severity}
            messageSnk={messageSnk}
          />
        </form>
      )}
    </Formik>
  );
};

export default RatesForm;
