import { baseBackend } from "../../../api/base/baseUrl";
const URL_SCHEDULED = "/api/v1/module/scheduled";

export const createScheduled = async (scheduled) => {
  return await baseBackend.post(
    `${URL_SCHEDULED}/create`,
    JSON.stringify({ scheduled }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const readScheduled = async () => {
  return await baseBackend.get(`${URL_SCHEDULED}/read`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const readSubscribed = async () => {
  return await baseBackend.get(`${URL_SCHEDULED}/subscribed`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const readSubscribedById = async (id) => {
  return await baseBackend.get(`${URL_SCHEDULED}/booking/${id}`, {
    headers: { "Content-Type": "application/json" },
  });
};
