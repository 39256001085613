import React from "react";
import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import cambria from "../../../../font/Cambria.ttf";
import firma from "../../../../assets/contratofirma.png";

Font.register({
  family: "Cambria",
  src: cambria,
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    marginTop: 60,
    
    alignItems: "center", // Alinear verticalmente en el centro
    justifyContent: "center", // Centrar horizontalmentealignContent: "center"
  },
  text: {
    color: "#000000",
    fontSize: 11,
    fontFamily: "Cambria",
    width: 150,
    textDecoration: "underline",
    textAlign: "center",
  },
  logo: {
    width: 150,
    height: 80,
  },
});

const Signature = () => {
  return (
    <View style={styles.container}>
      <Image style={styles.logo} src={firma} />
      <Text style={styles.text}>Gerente General</Text>
    </View>
  );
};

export default Signature;
