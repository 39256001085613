import { json } from "react-router-dom";
import { baseBackend } from "../../base/baseUrl";

export const getDriverBonus = async (token, id) => {
  return await baseBackend.get(`/api/v1/bonus/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllBonus = async (token) => {
  return await baseBackend.get(`/api/v1/bonus`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createAllBonus = async (bonus) => {
  return await baseBackend.post(`/api/v1/bonus`, JSON.stringify({ bonus }), {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const createBonus = async (token, bonus) => {
  return await baseBackend.post(
    `/api/v1/bonus/driver`,
    JSON.stringify({ bonus }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
