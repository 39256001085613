import { createReservation } from "../../../data/repository/booking.repo";

export const CreateBookingUseCase = async (reservation) => {
  try {
    const resp = await createReservation(reservation);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
