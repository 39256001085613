import { ref, set } from "firebase/database";
import { db } from "../../../firebase";

const PATH = "UserStatus";

export const createUserState = async (userState) => {
  const dbRef = `${PATH}/${userState.type}/${userState.id}`;
  const resp = await set(ref(db, dbRef), userState.status);
  return resp;
};
