import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { db } from "../../firebase";
import { ref, onValue, remove, update } from "firebase/database";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  useTheme,
  Modal,
  TextField,
  Grid,
  FormControl,
  Select,
  InputLabel,
  Paper,
  Popper,
  Typography,
  TableSortLabel,
} from "@mui/material";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { ModalStyle } from "./TableBookStyle";
import SnackBar from "../snackbar/SnackBar";
import { putBookingNow } from "../../api/booking/bookingNow";
import { convertDate } from "../../helpers/date/convertDate";
import Title from "./pdf/elements/Title";
import Contact from "./pdf/elements/Contact";
import DocumentName from "./pdf/elements/DocumentName";
import Enterprise from "./pdf/elements/Enterprise";
import Table from "./pdf/elements/Table";
import TableHour from "./pdf/elements/TableHour";
import { getEnterprise } from "../../api/enterprise/enterprise";
import { UpdateQuoteBackup } from "../../views/users/admin/backup/usecases/UpdateQuoteBackup";
import Header from "../header/Header";
import { sendICSEmail } from "../../api/resources/ics/ics";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const TableBookBusinessQuoteLater = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [booking, setBooking] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openQuoteModal, setOpenQuoteModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [UID, setUID] = useState("");
  const [orderNumber, setOrderNumber] = useState(0);
  const [bookBill, setBookBill] = useState({});
  const [payment, setPayment] = useState("PAGADO");
  const [discount, setDiscount] = useState(0);

  const handleCloseModal = () => setOpenModal(false);
  const handleCloseQuoteModal = () => setOpenQuoteModal(false);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const [dataSelected, setDataSelected] = useState([]);

  const currency = { 1: "PEN", 2: "USD" };

  useEffect(() => {
    const starCountRef = ref(db, "BookingBusiness/");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();

      if (data !== null) {
        const compareByDate = (a, b) => {
          const dateB = convertDate(b.date, a.time);
          const dateA = convertDate(a.date, a.time);
          return dateA - dateB;
        };

        setBooking([]);
        Object.values(data).map((booking) => {
          if (
            booking.booking_type &&
            booking.booking_type != 1 &&
            !booking.cotizar
          ) {
            booking.currency = currency[booking.currency];
            booking.fullorigin = booking.origin.name;
            booking.originLat = booking.origin.lat;
            booking.originLng = booking.origin.lng;
            booking.fulldestination = booking.destination.name;
            booking.destinationLat = booking.destination.lat;
            booking.destinationLng = booking.destination.lng;

            if (booking.booking_type === 3)
              booking.fullnote = `HORA: ${booking.dateEnd} ${booking.timeEnd} - ${booking.note}`;
            if (booking.booking_type === 4)
              booking.fullnote = `TOUR: ${booking.tour_description}. ${booking.note}`;
            else booking.fullnote = booking.note;

            setBooking((oldArray) =>
              [...oldArray, booking].sort(compareByDate)
            );
          }
        });
      }
    });
  }, []);

  // useEffect(() => {
  //   if (dataSelected.length == 1) {
  //     getEnterpriseData(dataSelected[0].enterpriseId);
  //   }
  //   if (dataSelected.length == 0) {
  //     setSelectEnterprise("");
  //   }
  // }, [dataSelected]);

  const [selectEnterprise, setSelectEnterprise] = useState("");

  const getEnterpriseData = async (enterpriseId) => {
    try {
      const response = await getEnterprise("token", enterpriseId);
      console.log(response);
      setSelectEnterprise(response.data[0]);
      return response.data[0];
    } catch (error) {
      setSelectEnterprise("");
      return "error";
    }
  };

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };

  const columns = [
    {
      headerName: "Pedido",
      field: "counterBooking",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      headerName: "FECHA",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "HORA",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "NOMBRE TRABAJADOR",
      field: "clientName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: ({ row: { clientName, clientPhone } }) => {
        return (
          <a
            href={`https://api.whatsapp.com/send?phone=${clientPhone}&text=*`}
            target="_blank"
            style={{ color: "white", textDecoration: "none" }}
          >
            {clientName}
          </a>
        );
      },
    },
    {
      headerName: "EMPRESA",
      field: "enterpriseName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "ORIGEN",
      field: "fullorigin",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: ({ row: { fullorigin, originLat, originLng } }) => {
        const url = `https://maps.google.com/?q=${originLat},${originLng}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 12,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {fullorigin}
          </a>
        );
      },
    },
    {
      headerName: "DESTINO",
      field: "fulldestination",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: ({
        row: { fulldestination, destinationLat, destinationLng },
      }) => {
        const url = `https://maps.google.com/?q=${destinationLat},${destinationLng}`;
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 12,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {fulldestination}
          </a>
        );
      },
    },
    {
      headerName: "Pack",
      field: "persons",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      headerName: "NOTAS",
      field: "fullnote",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Moneda",
      field: "currency",
      type: "string",
      headerAlign: "left",
      align: "center",
      width: 70,
    },
    {
      headerName: "Precio",
      field: "total",
      type: "string",
      headerAlign: "left",
      align: "center",
      width: 50,
    },
    {
      headerName: "Estado",
      field: "statusPayment",
      width: 90,
      renderCell: ({ row: { statusPayment } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              width: "100%",
              textAlign: "right",
              color: "white",
              background: statusPayment === "PENDIENTE" ? "red" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {statusPayment}
          </div>
        );
      },
    },
    {
      headerName: "",
      field: "actions",
      width: 160,
      renderCell: ({
        row: {
          uuid,
          counterBooking,
          clientName,
          clientEmail,
          clientPhone,
          fullorigin,
          fulldestination,
          date,
          time,
          persons,
          vehicle,
          note,
        },
      }) => {
        return (
          <Stack direction="row" spacing={2}>
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => navigate(`/admin/add-business-quote/${uuid}`)}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
            <IconButton
              component="label"
              color="success"
              sx={{ background: "#ffff" }}
              onClick={(e) => {
                e.preventDefault();
                setUID(uuid);
                setOrderNumber(counterBooking);
                setEmailBooking({
                  counterBooking: counterBooking,
                  clientName: clientName,
                  clientEmail: clientEmail,
                  clientPhone: clientPhone,
                  origin: { name: fullorigin },
                  destination: { name: fulldestination },
                  date: date,
                  time: time,
                  persons: persons,
                  vehicle: vehicle,
                  note: note,
                  web: "Business",
                });
                setOpenQuoteModal(true);
              }}
            >
              <CheckCircleOutlineOutlinedIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(uuid);
                setOpenDeleteModal(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const updateFacture = async (uuid, payment, discount) => {
    try {
      bookBill.payment_type = payment;
      bookBill.discount = discount;
      const token = "asdf";

      await putBookingNow(token, discount, payment, bookBill.counterBooking);
      const dbRef = ref(db, `bookingNow/${uuid}`);
      remove(dbRef);
      handleCloseModal();
      setDiscount(0);
      setPayment("PAGADO");
      snackBarDialog("PEDIDO FACTURADO");
    } catch (error) {
      console.log(error);
      handleCloseModal();
      snackBarDialog("ERROR, NO SE GUARDO LOS CAMBIOS", "error");
    }
  };

  const [emailBooking, setEmailBooking] = useState(null);

  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleCloseSnk = () => setStateSnk(false);

  const [isLoading, setIsLoading] = useState(false);

  const updateQuote = async () => {
    setIsLoading(true);
    try {
      const value = { cotizar: true };
      console.log("before");
      await update(ref(db, `BookingBusiness/${UID}`), value);
      const backup = {
        id: orderNumber,
        quote: true,
      };
      const response = await UpdateQuoteBackup(backup);

      if (response.message == "success") snackBarDialog("BACKUP ACTUALIZADO");
      else snackBarDialog("NO SE ACTUALIZO EL BACKUP", "error");
      handleCloseQuoteModal();

      await sendICSEmail("token", emailBooking);

      setEmailBooking(null);
      snackBarDialog("COTIZACION CONFIRMADA");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      snackBarDialog("ERROR: COTIZACION ", "error");
    }
  };

  const deleteQuote = async () => {
    try {
      setIsLoading(true);
      await remove(ref(db, `BookingBusiness/${UID}`));
      setIsLoading(false);
      handleCloseDeleteModal();
      snackBarDialog("COTIZACION ELIMINADA");
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      snackBarDialog("ERROR: NO SE PUEDE ELIMINA LA COTIZACION", "error");
    }
  };

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  const [tableData, setTableData] = useState(null);
  useEffect(() => {
    if (dataSelected.length > 0) {
      const getEnterpriseSelected = async () => {
        try {
          const enterpriseResp = await getEnterpriseData(
            dataSelected[0].enterpriseId
          );
          if (enterpriseResp === undefined)
            throw new Error("Enterprise Not found");

          if (enterpriseResp === "error") throw new Error("enterprise error");

          console.log("enterprise", enterpriseResp);
          let total = 0;
          Object.values(dataSelected).map((book) => {
            console.log(book);
            total += Number(book.total);
          });

          let imp = 0;
          let totalPay = 0;
          let subTotal = 0;

          if (enterpriseResp.igv === "MAS IGV") {
            imp = Math.round(Number(total) * 0.18 * 100) / 100;
            subTotal = Number(total);
            totalPay = Math.round((Number(total) + Number(imp)) * 10) / 10;
          } else if (enterpriseResp.igv === "INAFECTO") {
            totalPay = Number(total);
          } else if (enterpriseResp.igv === "AFECTO") {
            subTotal = Math.round((total / 1.18) * 100) / 100;
            imp = Math.round((total - subTotal) * 100) / 100;
            totalPay = Math.round(Number(total) * 10) / 10;
          }

          console.log("total", total);

          setTableData({});
          setTableData({
            id: "5df3180a09ea16dc4b95f910",
            invoice_no: "201906-28",
            company: "TAXIMUNDO",
            email: "info@taximundo.com",
            phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
            address: "Servicios Generales Trans Mundo E.I.R.L.",
            items: dataSelected,
            igv: imp,
            total: totalPay,
            peaje: 0,
            subTotal: subTotal,
            from: 0,
            to: 0,
          });
        } catch (error) {
          console.log(error);
        }
      };
      getEnterpriseSelected();
    }
  }, [dataSelected]);

  const data = {
    id: "5df3180a09ea16dc4b95f910",
    invoice_no: "201906-28",
    company: "TAXIMUNDO",
    email: "info@taximundo.com",
    phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
    address: "Servicios Generales Trans Mundo E.I.R.L.",
    items: dataSelected,
    igv: 0,
    total: 0,
    peaje: 0,
    subTotal: 0,
    from: dataSelected.length > 0 ? dataSelected[0].date : 0,
    to:
      dataSelected.length > 0 ? dataSelected[dataSelected.length - 1].date : 0,
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Cotizacion Empresas" subtitle="" />
        {dataSelected.length > 0 && (
          <Grid item xs={12} sm={12} md={2} textAlign="end">
            <PDFDownloadLink
              document={
                <Document>
                  {selectEnterprise != "" && tableData?.id ? (
                    <Page size="A4" style={styles.page}>
                      <Title title="Taxi Mundo PERU" />
                      <Contact invoice={tableData} />
                      <DocumentName
                        title={"COTIZACION"}
                        from={tableData.from}
                        to={tableData.to}
                      />
                      <Enterprise
                        invoice={tableData}
                        selectEnterprise={selectEnterprise}
                      />
                      <Table invoice={tableData} />
                    </Page>
                  ) : (
                    <></>
                  )}
                </Document>
              }
              fileName={"detalles-cotizacion-taximundo.pdf"}
            >
              <Box width={"100%"}>
                <Button fullWidth variant="contained" color="success">
                  <PictureAsPdfIcon /> DESCARGAR
                </Button>
              </Box>
            </PDFDownloadLink>
          </Grid>
        )}
      </Box>
      <Box display="grid" gap="10px">
        <Box gridColumn="span 12" gridRow="span 2">
          <Box m="0px">
            <Box
              m="10px 0 0 0"
              height="100vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid
                getRowId={(row) => row.uuid}
                rows={booking}
                columns={columns}
                checkboxSelection={true}
                onSelectionModelChange={(ids) => {
                  setDataSelected([]);
                  ids.map((id) => {
                    const select = booking.find((row) => row.uuid === id);
                    setDataSelected((oldData) => [...oldData, select]);
                  });
                }}
              />
            </Box>
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box
                sx={{
                  ...ModalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-title">FACTURA</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={bookBill.counterBooking}
                      fullWidth
                      disabled
                      label="Nro Pedido"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={bookBill.clientName}
                      fullWidth
                      disabled
                      label="Client"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={discount}
                      fullWidth
                      type={"number"}
                      label="Descuento"
                      variant="outlined"
                      onChange={(e) => {
                        setDiscount(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Tipo Pago</InputLabel>
                      <Select
                        value={payment}
                        id="payment_type"
                        name="payment_type"
                        label="Tipo de Pago"
                        onChange={(e) => {
                          setPayment(e.target.value);
                        }}
                      >
                        <MenuItem value={"PAGADO"}>PAGADO</MenuItem>
                        <MenuItem value={"CREDITO"}>CREDITO</MenuItem>
                        <MenuItem value={"CANCELADO"}>CANCELADO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: {
                        xs: payment === 2 ? "block" : "none",
                        md: payment === 2 ? "block" : "none",
                      },
                    }}
                  >
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        //actualizar
                      }}
                    >
                      Pago con tarjeta
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        updateFacture(UID, payment, discount);
                      }}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* MODAL PARA CONFIRMAR COTIZACION */}
            <Modal
              open={openQuoteModal}
              onClose={handleCloseQuoteModal}
              aria-labelledby="modal-title"
            >
              <Box
                sx={{
                  ...ModalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-title">CONFIRMAR COTIZACION</h2>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        updateQuote();
                      }}
                      disabled={isLoading}
                    >
                      ACEPTAR
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      onClick={handleCloseQuoteModal}
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* ELIMINAR COTIZACION */}
            <Modal
              open={openDeleteModal}
              onClose={handleCloseDeleteModal}
              aria-labelledby="modal-title"
            >
              <Box
                sx={{
                  ...ModalStyle,
                  width: 300,
                  backgroundColor: colors.primary[400],
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign={"center"}>
                    <h2 id="modal-title">ELIMINAR COTIZACION</h2>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={deleteQuote}
                    >
                      ACEPTAR
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      onClick={handleCloseDeleteModal}
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleCloseSnk}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TableBookBusinessQuoteLater;
