import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CssBaseline,
  Grid,
  Stack,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import { tokens } from "../../../../theme";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { createdEnterpriseAndEmployee } from "../../../../api/enterprise/enterprise";
import { getSunatRuc } from "../../../../api/resources/sunat/sunat";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

const EnterpriseAdd = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const { t } = useTranslation();

  const checkoutSchema = yup.object().shape({
    commertial_name: yup.string().required("required"),
    business_name: yup.string().required("required"),
    ruc: yup.string().required("required"),
  });

  const enterpriseData = {
    commertial_name: "",
    business_name: "",
    email: "",
    phone: "",
    address: "",
    ruc: "",
    payment_type: "",
    igv: "",
    notes: "",
    contract: "",
    contract_start: "",
    contract_end: "",
  };

  const handleFormSubmit = async (values) => {
    console.log(values);
    try {
      const enterprise = {
        commertial: values.commertial_name.toUpperCase(),
        business_name: values.business_name.toUpperCase(),
        ruc: values.ruc,
        address: values.address.toUpperCase(),
        email: values.email,
        phone: values.phone,
        category: "",
        category_other: "",
        budget: "",
        frequency: "",
        payment_type: values.payment_type,
        notes: values.notes ? values.notes.toUpperCase() : "",
        igv: values.igv,
        noEmail: true,
      };
      const employee = {
        dni: "empty",
        name: "empty",
        lastname: "empty",
        email: "empty",
        password: "empty",
        phone: "empty",
        genres_id: "empty",
        company_id: 1,
        role: 4,
        sub_role: 1,
      };

      await createdEnterpriseAndEmployee(enterprise, employee);
      snackBarDialog("DATOS ACTUALIZADOS");
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR AL ACTUALIZAR DATOS", "error");
    }
  };

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleMouseDownPassword = (e) => e.preventDefault();

  const searchRUC = async (values) => {
    if (values.ruc.toString().length === 11) {
      try {
        const res = await getSunatRuc(values.ruc);
        console.log(res);
        values.business_name = res?.data?.nombre;
        values.address = `${res?.data?.direccion} ${res.data?.distrito} ${res.data?.provincia} ${res.data?.departamento}`;
        snackBarDialog("Busqueda Exitosa");
      } catch (error) {
        snackBarDialog("No se encontro empresa con este RUC", "error");
        console.log(error);
      }
    } else {
      snackBarDialog("RUC de 11 digitos", "error");
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="EMPRESA" subtitle="" />
            </Box>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={enterpriseData}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    backgroundColor={colors.primary[400]}
                    borderRadius={3}
                    p={2}
                  >
                    <Grid container spacing={1.5}>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={!!touched.ruc && !!errors.ruc}
                        >
                          <InputLabel>RUC</InputLabel>
                          <OutlinedInput
                            value={values.ruc}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="ruc"
                            type="number"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    searchRUC(values);
                                    //handleSearchEnterprise();
                                  }}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  <SendIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="RUC"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.commertial_name}
                          fullWidth
                          variant="outlined"
                          type="text"
                          label={t("NOMBRE COMERCIAL")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="commertial_name"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={
                            !!touched.commertial_name &&
                            !!errors.commertial_name
                          }
                          helperText={
                            touched.commertial_name && errors.commertial_name
                          }
                          color="success"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.business_name}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="text"
                          label={t("RAZON SOCIAL")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="business_name"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={
                            !!touched.business_name && !!errors.business_name
                          }
                          helperText={
                            touched.business_name && errors.business_name
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.address}
                          fullWidth
                          variant="outlined"
                          name="address"
                          color="success"
                          type="text"
                          label={t("ADDRESS")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.email}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="email"
                          label={"Email"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="email"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.phone}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="tel"
                          label={t("phone")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="phone"
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="select-payment">
                            Tipo de Pago
                          </InputLabel>
                          <Select
                            labelId="payment_type"
                            id="select-payment"
                            value={values.payment_type}
                            name="payment_type"
                            label="Tipo de Pago"
                            onChange={handleChange}
                          >
                            <MenuItem value={"EFECTIVO"}>EFECTIVO</MenuItem>
                            <MenuItem value={"CREDITO"}>CREDITO</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="select-igv">IGV</InputLabel>
                          <Select
                            id="select-igv"
                            value={values.igv}
                            name="igv"
                            label="IGV"
                            onChange={handleChange}
                          >
                            <MenuItem value={"AFECTO"}>AFECTO</MenuItem>
                            <MenuItem value={"INAFECTO"}>INAFECTO</MenuItem>
                            <MenuItem value={"MAS IGV"}>MAS IGV</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          value={values.notes}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="text"
                          label={t("Notas")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          name="notes"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} textAlign={"end"}>
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                        >
                          CREAR EMPRESA
                        </Button>
                      </Grid>
                    </Grid>
                    <SnackBar
                      stateSnk={stateSnk}
                      vertical={vertical}
                      horizontal={horizontal}
                      duration={5000}
                      handleClose={handleSnkClose}
                      severity={severity}
                      messageSnk={messageSnk}
                    />
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </main>
      </div>
    </>
  );
};

export default EnterpriseAdd;
