import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { CssBaseline, Tab, Tabs } from "@mui/material";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { getModels } from "../../../../api/resources/model/model";
import BookingBusiness from "./business/create/BookingBusiness";
import BookingClient from "./client/create/BookingClient";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BookingTab = () => {
  const [value, setValue] = useState(0);
  const [model, setModel] = useState([]);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const models = async () => {
      try {
        const response = await getModels();
        const data = response.data.filter((model) => model.id);
        setModel(data);
      } catch (error) {
        console.log(error);
      }
    };
    models();
  }, []);

  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="Crear Pedidos" subtitle="" />
              <Box></Box>
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Booking-Tabs"
                centered
                textColor="secondary"
                indicatorColor="secondary"
              >
                <Tab
                  label={"Clientes"}
                  {...a11yProps(0)}
                  sx={{ fontSize: 18 }}
                />
                <Tab
                  label={"Empresas"}
                  {...a11yProps(0)}
                  sx={{ fontSize: 18 }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <BookingClient model={model} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <BookingBusiness model={model} />
            </TabPanel>
          </Box>
        </main>
      </div>
    </>
  );
};

export default BookingTab;
