import React from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import arequipa from "../../../../assets/shieldarequipa.png";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignContent: "center",
    width: "100%",
    marginHorizontal: "auto",
  },
  logo: {
    width: 80,
    height: 90,
    marginRight: "auto",
  },
  textContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: "#000000",
    fontSize: 25,
    fontWeight: "bold",
  },
  subtext: {
    color: "#000000",
    fontSize: 10,
    textAlign: "center",
  },
});

const Header = () => {
  const mainTitle = "HABILITACION VEHICULAR SETARE";
  const subTitle1 =
    "FORMULARIO UNICO DE TRAMITE DE PROCEDIMIENTO DE INSCRIPCION";
  const subTitle2 = "SERVICIO DE TAXI AREQUIPA (SETARE)";
  const subTitle3 = "Ordenanza Municipla Nº 1106-2018- MPA";
  const subTitle4 = "Resolucion Gerencial 367-2018/MPA/GTUCV(Cronograma)";
  const subTitle5 = `( DECLARACIÓN JURADA )`;

  return (
    <View style={styles.container}>
      <Image style={styles.logo} src={arequipa} />
      <View style={styles.textContainer}>
        <Text style={styles.text}>{mainTitle}</Text>
        <View style={{ marginTop: 5, alignItems: "center" }}>
          <Text style={{ ...styles.subtext }}>{subTitle1}</Text>
          <Text
            style={{
              ...styles.subtext,
              fontFamily: "Helvetica-Bold",
              fontSize: 8,
            }}
          >
            {subTitle2}
          </Text>
          <Text
            style={{
              ...styles.subtext,
              fontFamily: "Helvetica-Bold",
              fontSize: 8,
            }}
          >
            {subTitle3}
          </Text>
          <Text
            style={{
              ...styles.subtext,
              fontFamily: "Helvetica-Bold",
              fontSize: 8,
            }}
          >
            {subTitle4}
          </Text>
          <Text style={{ ...styles.subtext, fontSize: 7 }}>{subTitle5}</Text>
        </View>
      </View>
    </View>
  );
};

export default Header;
