import { Box, Button, Grid, Modal, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import SnackBar from "../../../../../../../components/snackbar/SnackBar";
import CostCenterTable from "./CostCenterTable";
import { tokens } from "../../../../../../../theme";
import { modalStyle } from "../style/ModalStyle";

const CostCenterList = ({ data, columns }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [UID, setUID] = useState("");
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };
  const handleClose = () => setOpen(false);

  const handleAddressDelete = async () => {
    try {
      
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <Box>
        <CostCenterTable data={data} columns={columns} />
      </Box>
    </>
  );
};

export default CostCenterList;
