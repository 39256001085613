export const shiftListES = [
  { id: 0, shift: "POR DEFINIR" },
  { id: 1, shift: "MAÑANA" },
  { id: 2, shift: "TARDE" },
  { id: 3, shift: "NOCHE" },
];

export const shiftListEN = [
  { id: 0, shift: "UNDEFINED" },
  { id: 1, shift: "MORNING" },
  { id: 2, shift: "AFTERNOON" },
  { id: 3, shift: "NIGHT" },
];
