import React from "react";
import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import cambria from "../../../../font/Cambria.ttf";
import firma from "../../../../assets/contratofirma.png";
import { monthList } from "../../MonthList";

Font.register({
  family: "Cambria",
  src: cambria,
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  container: {
    width: "90%",
    marginTop: 15,
    alignContent: "flex-end",
    marginHorizontal: "auto",
  },
  presentation: {
    width: "60%",
    marginLeft: "auto",
  },
  content: {
    marginTop: 20,
    width: "100%",
    marginLeft: "auto",
  },
  option: {
    width: "90%",
    marginLeft: "auto",
    flexDirection: "row",
  },
  text: {
    color: "#000000",
    fontSize: 12,
    fontFamily: "Cambria",
    textAlign: "justify",
  },
  subTitle: {
    marginTop: 10,
    color: "#000000",
    fontSize: 13,
    fontFamily: "Cambria",
    textAlign: "justify",
  },
  date: {
    marginTop: 10,
    color: "#000000",
    fontSize: 12,
    fontFamily: "Cambria",
    textAlign: "justify",
  },
  signature: {
    width: "60%",
    marginTop: 10,
    color: "#000000",
    fontSize: 12,
    fontFamily: "Cambria",
    borderTop: 1,
    borderTopColor: "#000000",
    borderTopStyle: "dashed",
  },
  logo: {
    width: 150,
    height: 70,
    marginRight: "auto",
    marginTop: 20,
  },
});

const Body = ({ plate }) => {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth();
  const year = currentDate.getFullYear();
  const paragraph1 = `Yo, JULIO LAURA CHAMBILLA, representante legal de la empresa SERVICIOS GENERALES TRANSMUNDO E.I.R.L (Taxi Mundo), de código E-265; ante Ud. respetuosamente me presento y digo:`;

  const paragraph2 = `Que, con la finalidad obtener la Autorización para prestar el servicio de TAXI, es que solicito a Ud. ordene a quien corresponda, realizar la CONSTATACION DE CARACTERíSTICAS al vehículo de placa de rodaje N° ${plate}, afilado a mi representada. Adjunto:`;

  const option1 = `Copia de la tarjeta de propiedad (sin fedatear)`;
  const option2 = `Pago S/. 23.70`;

  const paragraph3 = `POR LO EXPUESTO`;
  const paragraph4 = `Pido a Ud. atención a mi solicitud`;
  const paragraph5 = `Arequipa, ${day} de ${monthList[month]} de ${year}`;

  const signature = `Firma del representante legal`;

  return (
    <View style={styles.container}>
      <View style={styles.presentation}>
        <Text style={styles.text}>{paragraph1}</Text>
      </View>
      <View style={styles.content}>
        <Text style={styles.text}>{paragraph2}</Text>
      </View>
      <View style={styles.option}>
        <Text style={{ marginHorizontal: 8, marginTop: 20 }}>•</Text>
        <Text style={{ marginTop: 20 }}>{option1}</Text>
      </View>
      <View style={styles.option}>
        <Text style={{ marginHorizontal: 8 }}>•</Text>
        <Text>{option2}</Text>
      </View>
      <View style={styles.presentation}>
        <Text style={styles.subTitle}>{paragraph3}</Text>
        <Text style={styles.text}>{paragraph4}</Text>
        <Text style={styles.date}>{paragraph5}</Text>
      </View>
      <View style={styles.presentation}>
        <Image style={styles.logo} src={firma} />
        <Text style={styles.signature}>{signature}</Text>
      </View>
    </View>
  );
};

export default Body;
