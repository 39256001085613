import { readRelationCostCenter } from "../respository/costcenter.respository";

export const ReadRelationCostCenter = async (employeeId) => {
  try {
    const response = await readRelationCostCenter(employeeId);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
