import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import TableHeader from "./table/TableHeader";
import TableRow from "./table/TableRow";
import TableRiderTotal from "./table/TableRiderTotal";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
  },
});

const TableBooking = ({ invoice }) => {
  return (
    <View style={styles.tableContainer}>
      <Text>CLIENTE: {invoice?.items[0]?.clientName}</Text>
      <TableHeader />
      <TableRow items={invoice.items} />
      <TableRiderTotal invoice={invoice} />
    </View>
  );
};

export default TableBooking;
