import { getReservation } from "../../../data/repository/booking.repo";

export const GetBookingUseCase = async (id) => {
  try {
    const resp = await getReservation(id);
    return resp.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
