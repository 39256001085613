import { updateBackupQuote } from "../../../../../api/backup/backup";

export const UpdateQuoteBackup = async (backup) => {
  try {
    const response = await updateBackupQuote(backup);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
