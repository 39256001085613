import { Box, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { tokens } from "../../../../theme";
import { getCountUser } from "../../../../api/priceDates";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import StatBoxDouble from "../../../../components/stats/StatBoxDouble";
import StatBoxSimple from "../../../../components/StartBoxSimple";
import BusinessIcon from "@mui/icons-material/Business";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { ReadCounterDriver } from "../dashboard/usecases/ReadCounterDriver";

const GridCount = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [counter, setCounter] = useState("");
  const [countVehicle, setCountVehicle] = useState("");
  const [countBooking, setCountBooking] = useState([]);
  const [countBookingLater, setCountBookingLater] = useState([]);
  const [countBusiness, setCountBusiness] = useState([]);
  const [countBusinessLater, setCountBusinessLater] = useState([]);

  useEffect(() => {
    const listCountUsers = async () => {
      try {
        const response = await getCountUser();
        setCounter(response?.data.count[0]);
        setCountVehicle(response?.data.vehicles);
        setCountBooking(response?.data.listBookingNow);
        setCountBookingLater(response?.data.listBookingLater);
        setCountBusiness(response?.data.listBookingBusiness);
        setCountBusinessLater(response?.data.listBookingBusinessLater);

        let count = 0;
        response?.data.vehicles.map((vehicle) => {
          if (
            vehicle.bodywork === "SEDAN" ||
            vehicle.bodywork === "SUV" ||
            vehicle.bodywork === "MINIVAN"
          ) {
            count = count + vehicle.count;
          }
        });
        setTotalVehicles(count);
      } catch (error) {
        console.log(error);
      }
    };

    listCountUsers();
  }, []);

  const [counterDriver, setCounterDriver] = useState([]);
  const [totalVehicles, setTotalVehicles] = useState(0);
  useEffect(() => {
    const getCounterDrivers = async () => {
      try {
        const response = await ReadCounterDriver();

        if (response.message == "success") {
          console.log(response);
          setCounterDriver(response.counter);
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCounterDrivers();
  }, []);

  return (
    <>
      {counter ? (
        <Grid container spacing={0.5}>
          <Grid item xs={12} sm={4} md={2}>
            <Box
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="space-around"
            >
              <StatBoxSimple
                title="CLIENTES"
                icon={
                  <PersonAddIcon
                    sx={{
                      color: colors.greenAccent[600],
                      fontSize: "40px",
                    }}
                  />
                }
                subtitle="Total"
                quantity={counter.clients}
                frequent={counter.clients_f}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Box
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBoxDouble
                title="EMPRESAS"
                subtitle1="Empresas"
                subtitle2="Empleados"
                quantity1={counter.enterprises}
                quantity2={counter.employees}
                icon={
                  <BusinessIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                  />
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Box
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box width="100%" height={140}>
                <Grid
                  container
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-around"}
                >
                  <Grid item xs={8} sm={10} p={"2px"}>
                    <Typography
                      fontWeight={"bold"}
                      variant="h4"
                      sx={{ color: colors.greenAccent[500] }}
                    >
                      CONDUCTOR: {counter.drivers}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} sm={2} textAlign={"end"}>
                    <TimeToLeaveIcon
                      sx={{
                        color: colors.greenAccent[600],
                        fontSize: "40px",
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={12} textAlign={"center"}>
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{ color: colors.greenAccent[500] }}
                    >
                      Total: {counter.drivers}
                    </Typography>
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={12} textAlign={"center"}>
                    <Typography
                      variant="h3"
                      sx={{ color: colors.greenAccent[500] }}
                    >
                      Normal: {counterDriver?.normal ? counterDriver.normal : 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} textAlign={"center"}>
                    <Typography
                      variant="h3"
                      sx={{ color: colors.greenAccent[500] }}
                    >
                      Reservas:{" "}
                      {counterDriver?.bookings ? counterDriver.bookings : 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} textAlign={"center"}>
                    <Typography
                      variant="h3"
                      sx={{ color: colors.greenAccent[500] }}
                    >
                      Premium:{" "}
                      {counterDriver?.premium ? counterDriver.premium : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Box
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box width="100%" height={140}>
                <Grid
                  container
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-around"}
                >
                  <Grid item xs={9} sm={9} p={"2px"}>
                    <Typography
                      fontWeight={"bold"}
                      variant="h4"
                      sx={{ color: colors.greenAccent[500] }}
                    >
                      VEHICULOS: {totalVehicles}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} textAlign={"end"} p={"2px"}>
                    <TimeToLeaveIcon
                      sx={{
                        color: colors.greenAccent[600],
                        fontSize: "40px",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
                    {countVehicle.map((vehicle, index) => {
                      if (
                        vehicle.bodywork === "SEDAN" ||
                        vehicle.bodywork === "SUV" ||
                        vehicle.bodywork === "MINIVAN"
                      ) {
                        return (
                          <div key={index}>
                            <Typography
                              variant="h3"
                              sx={{ color: colors.greenAccent[500] }}
                            >
                              {vehicle.bodywork}: {vehicle.count}
                            </Typography>
                          </div>
                        );
                      }
                    })}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBoxDouble
                title="RESERVAS"
                subtitle1="HOY"
                subtitle2="DESPUES"
                quantity1={countBooking.length + countBusiness.length}
                quantity2={countBookingLater.length + countBusinessLater.length}
                icon={
                  <LibraryBooksIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                  />
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBoxSimple
                title="PENDIENTES"
                subtitle="Total"
                icon={
                  <MenuBookOutlinedIcon
                    sx={{
                      color: colors.greenAccent[600],
                      fontSize: "40px",
                    }}
                  />
                }
                quantity="0"
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <div>loading ...</div>
      )}
    </>
  );
};

export default GridCount;

{
  /* GRID & CHARTS */
}
{
  /* <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="140px"
              gap="20px"
            > */
}
{
  /* ROW 1 */
}
{
  /* <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              > */
}
