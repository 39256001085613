import { counterDrivers } from "../repository/counter.repository";

export const ReadCounterDriver = async () => {
  try {
    const response = await counterDrivers();
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
