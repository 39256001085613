export const boxStyle = {
  marginTop: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const avatarStyle = {
  width: 152,
  height: 152,
  m: 3,
  bgcolor: "secondary.main",
};
