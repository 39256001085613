import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { v4 as uuidv4 } from "uuid";
import { VisuallyHiddenInput } from "../components/VisuallyHiddenInput";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { MetaTypes } from "../../../../helpers/constants/Metadata";
import { GetCountryAndCityUseCase } from "../../../domain/usecases/country/GetCountryAndCityUseCase";
import { CreateCityUseCase } from "../../../domain/usecases/city/CreateCityUseCase";
import SnackBar from "../../../../components/snackbar/SnackBar";

const CityCreateScreen = () => {
  const [titlePage, setTitlePage] = useState("");
  const [city, setCity] = useState("");
  const [mainImage, setMainImage] = useState();
  const [imageAlt, setImageAlt] = useState("");
  const [imageTitle, setImageTitle] = useState("");

  const [searchCheck, setSearchCheck] = useState(false);

  const [meta, setMeta] = useState("");
  const [route, setRoute] = useState("");
  const [route1, setRoute1] = useState("");
  const [route2, setRoute2] = useState("");

  const [metaList, setMetaList] = useState([
    { type: "", typeContent: "", content: "" },
  ]);

  const [indice, setIndice] = useState(0);
  const [type, setType] = useState("");
  const [typeContent, setTypeContent] = useState("");

  const handleServiceAdd = () => {
    if (metaList[metaList.length - 1].content !== "") {
      console.log("here");
      setMetaList([...metaList, { type: "", typeContent: "", content: "" }]);
      setIndice(indice + 1);
      setRoute("");
      setRoute1("");
      setRoute2("");
    } else {
      window.alert("INGRESE EL CAMPO PARA SEGUIR CREANDO");
    }
  };

  const handleServiceRemove = (index) => {
    const list = [...metaList];
    list.splice(index, 1);
    console.log(metaList);
    setMetaList(list);
  };

  const addServiceMeta = (value, meta, index) => {
    if (index == 0) {
      metaList[0] = {
        type: meta.type,
        typeContent: meta.typeContent,
        content: value,
      };
    } else {
      metaList[index].content = value;
    }
  };

  const addServiceType = (value, meta, index) => {
    if (index == 0) {
      metaList[0] = {
        type: value,
        typeContent: meta.typeContent,
        content: meta.content,
      };
    } else {
      metaList[index].type = value;
    }
  };

  const addServiceTypeContent = (value, meta, index) => {
    if (index == 0) {
      metaList[0] = {
        type: meta.type,
        typeContent: value,
        content: meta.content,
      };
    } else {
      metaList[index].typeContent = value;
    }
  };

  const [country, setCountry] = useState(0);
  const [countryList, setCountryList] = useState([]);
  useEffect(() => {
    const getCountryAndCityList = async () => {
      const response = await GetCountryAndCityUseCase();
      console.log(response.countryList);
      setCountryList([]);
      if (response.message == "success") {
        setCountryList(response.countryList);
      } else {
        console.log(response);
      }
    };

    getCountryAndCityList();
  }, []);

  const checkedCityData = () => {
    if (titlePage === "")
      return snackBarDialog("INGRESE EL TITULO DE LA PAGINA", "error");
    if (city === "")
      return snackBarDialog("INGRESE NOMBRE DE LA CIUDAD", "error");
    if (country === 0) return snackBarDialog("SELECCIONE EL PAIS", "error");
    if (mainImage === null)
      return snackBarDialog("SELECCIONE LA IMAGEN DE PORTADA", "error");

    return true;
  };

  const handleCreateCity = async () => {
    try {
      const check = checkedCityData();
      if (check) {
        setIsLoading(true);
        const data = new FormData();
        data.append("mainImage", mainImage);
        const settings = {
          titlePage,
          metaList,
        };
        const tour = {
          uuid: uuidv4(),
          city,
          countryId: country,
          showDestination: searchCheck,
          settings,
          imageAlt,
          imageTitle,
        };
        data.append("city", JSON.stringify(tour));
        const response = await CreateCityUseCase(data);

        if (response === "") {
          snackBarDialog("CIUDAD CREADO CORRECTAMENTE");
        } else {
          if (response.message === "DUPLICATE_COUNTRY_CITY_NAME") {
            snackBarDialog("YA EXISTE EL PAIS Y CIUDAD", "error");
          } else if (response.message === "ERROR_CREATE_CITY") {
            snackBarDialog("ERROR AL REGISTRAR LA CIUDAD", "error");
          } else {
            snackBarDialog("ERROR DE SERVIDOR", "error");
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      snackBarDialog("ERROR EN LA WEB - REVISAR", "error");
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const position = { vertical: "top", horizontal: "center" };

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={"CREAR PAGINA DE CIUDAD"} subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      value={titlePage}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setTitlePage(e.target.value)}
                      label={"TITULO DE LA PAGINA"}
                      InputLabelProps={{ style: { color: "white" } }}
                      name="titlePage"
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel>META</InputLabel>
                      <Select
                        value={type}
                        onChange={(e) => {
                          addServiceType(e.target.value, metaList[0], 0);
                          setType(e.target.value);
                        }}
                        label={"META"}
                        name="type"
                      >
                        {MetaTypes.map((elem, index) => (
                          <MenuItem key={index} value={elem.type}>
                            {elem.type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={typeContent}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => {
                        setTypeContent(e.target.value);
                        addServiceTypeContent(e.target.value, metaList[0], 0);
                      }}
                      label={"Nombre"}
                      InputLabelProps={{ style: { color: "white" } }}
                      name="typeContent"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={meta}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => {
                        setMeta(e.target.value);
                        addServiceMeta(e.target.value, metaList[0], 0);
                      }}
                      label={"CONTENIDO"}
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      name="titleUrl"
                      InputProps={{
                        endAdornment: (
                          <button
                            style={{ marginRight: -5 }}
                            color="success"
                            onClick={handleServiceAdd}
                          >
                            +
                          </button>
                        ),
                      }}
                    />
                  </Grid>
                  {metaList.map((service, index) => {
                    if (index != 0) {
                      return (
                        <Fragment key={index}>
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel>META(tipo)</InputLabel>
                              <Select
                                value={
                                  metaList[index].type
                                    ? metaList[index].type
                                    : route1
                                }
                                onChange={(e) => {
                                  setRoute1(e.target.value);
                                  addServiceType(
                                    e.target.value,
                                    metaList[index],
                                    index
                                  );
                                }}
                                label={"TIPO"}
                                name="route1"
                              >
                                {MetaTypes.map((elem, index) => (
                                  <MenuItem key={index} value={elem.type}>
                                    {elem.type}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              value={
                                metaList[index].typeContent
                                  ? metaList[index].typeContent
                                  : route2
                              }
                              fullWidth
                              variant="outlined"
                              type="text"
                              onChange={(e) => {
                                setRoute2(e.target.value);
                                addServiceTypeContent(
                                  e.target.value,
                                  metaList[index],
                                  index
                                );
                              }}
                              label={"Nombre"}
                              InputLabelProps={{
                                style: { color: "white" },
                              }}
                              name="route2"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              value={
                                metaList[index].content
                                  ? metaList[index].content
                                  : route
                              }
                              fullWidth
                              variant="outlined"
                              type="text"
                              onChange={(e) => {
                                setRoute(e.target.value);
                                addServiceMeta(
                                  e.target.value,
                                  metaList[index],
                                  index
                                );
                              }}
                              label={"CONTENIDO"}
                              InputLabelProps={{
                                style: { color: "white" },
                              }}
                              name="route"
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <button
                                      style={{ marginRight: -5 }}
                                      color="success"
                                      onClick={handleServiceAdd}
                                    >
                                      +
                                    </button>
                                    <button
                                      style={{ marginRight: -5 }}
                                      color="success"
                                      onClick={(e) =>
                                        handleServiceRemove(index)
                                      }
                                    >
                                      -
                                    </button>
                                  </>
                                ),
                              }}
                            />
                          </Grid>
                        </Fragment>
                      );
                    } else return null;
                  })}

                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                      <InputLabel>PAIS</InputLabel>
                      <Select
                        value={country}
                        id="country"
                        onChange={(e) => setCountry(e.target.value)}
                        label={"PAIS"}
                        name="country"
                      >
                        {countryList.map((elem, index) => (
                          <MenuItem key={index} value={elem.countryId}>
                            {elem.countryName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      value={city}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setCity(e.target.value)}
                      label={"CIUDAD"}
                      InputLabelProps={{ style: { color: "white" } }}
                      name="city"
                    />
                  </Grid>

                  <Grid item xs={6} sm={2}>
                    <label style={{ display: "block" }}>PORTADA</label>

                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                    >
                      <VisuallyHiddenInput
                        type="file"
                        name="mainImage"
                        onChange={(e) => {
                          setMainImage(e.target.files[0]);
                        }}
                      />
                    </Button>
                    {mainImage ? mainImage.name : ""}
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={imageAlt}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setImageAlt(e.target.value)}
                      label={"ALT"}
                      InputLabelProps={{ style: { color: "white" } }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      name="alt"
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={imageTitle}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setImageTitle(e.target.value)}
                      label={"Titulo Imagen"}
                      InputLabelProps={{ style: { color: "white" } }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      name="imageTitle"
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <label style={{ display: "block" }}>DESTINOS?</label>
                    <FormControlLabel
                      control={<Checkbox color="success" size="small" />}
                      label="Activar"
                      checked={searchCheck}
                      onChange={(e) => setSearchCheck(e.target.checked)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box display="flex" justifyContent="end" mt="10px">
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleCreateCity}
                        sx={{ width: 200, height: 45 }}
                        disabled={isLoading}
                      >
                        GUARDAR
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </main>
        <SnackBar
          stateSnk={stateSnk}
          vertical={position.vertical}
          horizontal={position.horizontal}
          duration={4000}
          handleClose={handleSnkClose}
          severity={severity}
          messageSnk={messageSnk}
        />
      </div>
    </>
  );
};

export default CityCreateScreen;
