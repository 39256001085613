export const tourSchema = (yup) => {
  return {
    code: yup.string().required("Requerido"),
    description: yup.string().required("Requerido"),
    url: yup.string().required("Requerido"),
    price: yup.number().required("Requerido"),
  };
};

export const tourInitial = {
  code: "",
  description: "",
  url: "",
  price: 0.0,
};
