import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CssBaseline,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { tokens } from "../../../../theme";
import { TableStyle } from "../components/TableStyle";
import { ReadScheduledUseCase } from "../../../domain/usecases/schedule/ReadScheduledUseCase";
import { ReadSubscribedByIdUseCase } from "../../../domain/usecases/schedule/ReadSubscribedByIdUseCase";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";

export const ScheduledListScreen = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [scheduled, setScheduled] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    const scheduledList = async () => {
      const resp = await ReadScheduledUseCase();
      console.log(resp);
      if (resp.message === "success") {
        setScheduled(resp.schedule);
      }
    };
    scheduledList();
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [scheduleId, setScheduleId] = useState(0);
  const columns = [
    {
      headerName: "TITULO",
      field: "tour_title",
      type: "number",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderCell: ({ row: { tour_code, tour_title } }) => {
        return (
          <div>
            <p>{tour_code}</p>
            <p>{tour_title}</p>
          </div>
        );
      },
    },
    {
      headerName: "PAIS",
      field: "country_name",
      type: "string",
      width: 100,
      renderCell: ({ row: { country_name, city_name } }) => {
        return (
          <div>
            <p>{country_name}</p>
            <p>{city_name}</p>
          </div>
        );
      },
    },
    {
      headerName: "FECHA",
      headerAlign: "left",
      field: "date",
      width: 130,
      renderCell: ({ row: { date, time } }) => {
        return (
          <div>
            <p>{date}</p>
            <p>{time}</p>
          </div>
        );
      },
    },
    {
      headerName: "ORIGEN",
      field: "origin",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 230,
      renderCell: ({ row: { origin, destination } }) => {
        return (
          <div>
            <p>{origin}</p>
            <p>{destination}</p>
          </div>
        );
      },
    },
    {
      headerName: "Cantidad",
      field: "capacity_total",
      width: 70,
    },
    {
      headerName: "Ocupado",
      field: "capacity",
      width: 70,
      renderCell: ({ row: { capacity_total, capacity } }) => {
        return (
          <div>
            <p>{capacity_total - capacity}</p>
          </div>
        );
      },
    },
    {
      headerName: "Nota",
      field: "note",
      width: 200,
    },
    {
      headerName: "",
      field: "actions",
      headerAlign: "center",
      width: 180,
      align: "center",
      renderCell: ({ row: { id } }) => {
        return (
          <Stack direction="row" spacing={1}>
            <Button
              id="basic-button"
              aria-controls={openMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              onClick={(e) => {
                setScheduleId(id);
                handleClick(e);
              }}
              sx={{ color: "white" }}
            >
              OPCIONES
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              MenuListProps={{ "aria-labelledby": "basic-button" }}
            >
              <MenuItem
                onClick={async (e) => {
                  handleClose();
                  await getSubscribedsById(scheduleId);
                  handleClientOpen();
                }}
              >
                LISTA CLIENTES
              </MenuItem>
            </Menu>
          </Stack>
        );
      },
    },
  ];

  const clientCol = [
    {
      headerName: "CLIENTE",
      field: "rider_name",
      width: 190,
      renderCell: ({ row: { rider_name, rider_lastname, rider_phone } }) => {
        return (
          <div>
            <p>
              {rider_name} {rider_lastname}
            </p>
            <p>{rider_phone}</p>
          </div>
        );
      },
    },
    {
      headerName: "EMAIL",
      field: "rider_email",
      width: 160,
    },
    {
      headerName: "Personas",
      field: "persons",
      width: 70,
      align: "center",
    },
    {
      headerName: "Precio",
      field: "price",
      width: 70,
      align: "center",
    },
    {
      headerName: "Adelanto",
      field: "advance",
      width: 70,
      align: "center",
      renderCell: ({ row: { advance } }) => {
        let amount = 0;
        advance.map((item) => {
          amount += Number(item.amount);
          return amount;
        });
        return <p>{amount}</p>;
      },
    },
    {
      headerName: "Nota",
      field: "note",
      width: 200,
    },
    {
      headerName: "OPCIONES",
      field: "actions",
      headerAlign: "center",
      width: 100,
      align: "center",
      renderCell: ({ row: { id } }) => {
        return (
          <Stack direction="row" spacing={1}>
            <CancelPresentationOutlinedIcon
              sx={{ color: "red", cursor: "pointer" }}
              onClick={(e) => alert("cancel")}
            />
          </Stack>
        );
      },
    },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90vh",
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "15px",
    p: 2,
  };
  const [openClients, setOpenClients] = useState(false);
  const handleClientOpen = () => setOpenClients(true);
  const handleClientClose = () => setOpenClients(false);
  const [clientList, setClientList] = useState([]);

  const getSubscribedsById = async (id) => {
    const resp = await ReadSubscribedByIdUseCase(id);
    console.log(resp);
    if (resp.message === "success") {
      setClientList(resp.data);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box display="flex" justifyContent="space-between">
              <Header title={"LISTA TOURS PROGRAMADOS"} subtitle="" />
            </Box>
            <Box gap="10px">
              <Box>
                <Box m="10px 0 0 0" height="100vh" sx={TableStyle(colors)}>
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={scheduled}
                    columns={columns}
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    // checkboxSelection
                    // onRowSelectionModelChange={(ids) => {
                    //   var list = [];
                    //   ids.map((id) => {
                    //     const select = scheduled.find((row) => row.id === id);
                    //     list.push(select);
                    //   });
                    //   // dataSelected(list);
                    // }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </main>
      </div>
      <Modal
        open={openClients}
        onClose={handleClientClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              LISTA DE CLIENTES
            </Typography>
            <Button color="success">PDF</Button>
          </div>

          <DataGrid
            sx={{ height: "80vh" }}
            getRowId={(row) => row.id}
            rows={clientList}
            columns={clientCol}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </Modal>
    </>
  );
};
