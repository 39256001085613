import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Box, CssBaseline } from "@mui/material";


import Calendar from "../../../../components/calendar/Calendar";
import Topbar from "../../../../components/topbar/TopBar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import Header from "../../../../components/header/Header";

const BookCalendar = () => {
  const { t } = useTranslation();

  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <div>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={t("Calendar")} subtitle="" />
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="120px"
              gap="10px"
            >
              <Box gridColumn="span 12" gridRow="span 2">
                <Calendar />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};

export default BookCalendar;
