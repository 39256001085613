import React, { useState } from "react";
import { Box, CssBaseline, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useTheme as themeStyles } from "@mui/material/styles";
import Topbar from "../../../../../../components/topbar/TopBar";
import Header from "../../../../../../components/header/Header";
import Sidebar from "../../../../../../components/sidebar/Sidebar";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../../../../../theme";

const ListTransactions = () => {
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [transfer, setTransfer] = useState([]);
  const colTransfer = [
    {
      headerName: "Pedido",
      field: "order_id",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "Transaccion",
      field: "transaction_id",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "Fecha",
      field: "operation_date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 120,
    },
    {
      headerName: "Moneda",
      field: "currency",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "Monto",
      field: "amount",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "Creado",
      field: "createBy",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
  ];

  return (
    <div>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="HISTORIAL DE OPENPAY" subtitle="" />
            </Box>

            <Box>
              <Box
                m="10px 0 0 0"
                height="100vh"
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                    color: colors.greenAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                  },
                }}
              >
                <DataGrid
                  getRowId={(row) => row.id}
                  rows={transfer}
                  columns={colTransfer}
                  localeText={
                    esES.components.MuiDataGrid.defaultProps.localeText
                  }
                  checkboxSelection
                  onSelectionModelChange={(ids) => {
                    // setDataSelected([]);
                    // ids.map((id) => {
                    //   const select = booking.find((row) => row.id === id);
                    //   setDataSelected((oldData) => [...oldData, select]);
                    // });
                  }}
                />
              </Box>
            </Box>

            {/* <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleCloseSnk}
              severity={severity}
              messageSnk={messageSnk}
            /> */}
          </Box>
        </main>
      </div>
    </div>
  );
};

export default ListTransactions;
