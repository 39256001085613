import { getTourList } from "../../../data/repository/tour.repo";

export const GetTourListUseCase = async () => {
  try {
    const response = await getTourList();
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
