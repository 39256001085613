import { updateCostCenter } from "../respository/costcenter.respository";

export const UpdateCostCenter = async (costCenter) => {
  try {
    const response = await updateCostCenter(costCenter);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
