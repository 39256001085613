import React, { useState } from "react";
import {
  Box,
  Button,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../../components/sidebar/Sidebar";
import Topbar from "../../../../../../components/topbar/TopBar";
import Header from "../../../../../../components/header/Header";
import { Formik } from "formik";
import * as yup from "yup";
import SnackBar from "../../../../../../components/snackbar/SnackBar";
import { tokens } from "../../../../../../theme";
import { get, ref, set } from "firebase/database";
import { db } from "../../../../../../firebase";

import { sendNotificationTokens } from "../../../../../../api/fcm/notification";

import { v4 as uuidv4 } from "uuid";

const Notification = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));

  const checkSchema = yup.object().shape({
    title: yup.string().required("required"),
    body: yup.string().required("required"),
  });

  const initial = {
    title: "",
    body: "",
  };

  const vertical = "top";
  const horizontal = "center";
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [user, setUser] = useState("");
  const handleUserChange = (event) => {
    setUser(event.target.value);
  };

  const sendNotification = async (values) => {
    setIsLoading(true);
    try {
      console.log(values);
      console.log(user);
      if (user === "") {
        snackBarDialog("DEBE SELECCIONAR UN USUARIO", "error");
        return;
      }

      const dbRefToken = ref(db, `Token/${user}`);
      get(dbRefToken)
        .then(async (snapshot) => {
          const data = snapshot.val();
          let listToken = [];
          if (data != null) {
            Object.values(data).map((token, index) => {
              listToken[index] = token.token;
            });
          }
          const uuid = uuidv4();
          const dbRefNews = ref(db, `News/${user}/${uuid}`);
          const dataNews = {
            title: values.title,
            body: values.body,
            date: Date.now(),
          };
          await set(dbRefNews, dataNews);

          const response = await sendNotificationTokens(
            listToken,
            values.title,
            values.body
          );
          console.log("enviado", response);
          snackBarDialog("NOTIFICACIONES ENVIADAS");
        })
        .catch((error) => {
          console.error("Error al obtener los datos:", error);
        });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      snackBarDialog("ERROR: NO SE PUEDE PUBLICAR ESTE PEDIDO", "error");
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="NOTIFICACIONES" subtitle="" />
            </Box>
            <Box>
              <Grid container spacing={1.5} paddingX={matchesSM ? 0 : 10}>
                <Grid item xs={12} sm={12} md={12}>
                  <Formik
                    onSubmit={sendNotification}
                    initialValues={initial}
                    validationSchema={checkSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Box backgroundColor={colors.primary[400]} p={1}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                value={values.title}
                                variant="outlined"
                                fullWidth
                                type="text"
                                label={"TITULO"}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                color="success"
                                name="title"
                                error={!!touched.title && !!errors.title}
                                helperText={touched.title && errors.title}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                value={values.body}
                                variant="outlined"
                                color="success"
                                type="text"
                                fullWidth
                                label="DESCRIPCION"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="body"
                                error={!!touched.body && !!errors.body}
                                helperText={touched.body && errors.body}
                              />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                              <FormControl fullWidth>
                                <InputLabel id="form-id-label">Para</InputLabel>
                                <Select
                                  labelId="form-id-label"
                                  value={user}
                                  label="Para"
                                  name="user"
                                  onChange={handleUserChange}
                                >
                                  <MenuItem value={"Client"}>CLIENTES</MenuItem>
                                  <MenuItem value={"Driver"}>
                                    CONDUCTORES
                                  </MenuItem>
                                  <MenuItem value={"Enterprise"}>
                                    EMPRESAS
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                              <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                                fullWidth
                                sx={{ height: 40 }}
                                disabled={isLoading}
                              >
                                ENVIAR
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        <SnackBar
                          stateSnk={stateSnk}
                          vertical={vertical}
                          horizontal={horizontal}
                          duration={4000}
                          handleClose={handleSnkClose}
                          severity={severity}
                          messageSnk={messageSnk}
                        />
                      </form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default Notification;
