import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  CssBaseline,
  Modal,
  Typography,
  Grid,
  useTheme,
} from "@mui/material";

import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { tokens } from "../../../../theme";
import { getDriverPayments } from "../../../../api/booking/bookingNow";
import { dateFormat, timeFormat } from "../../../../helpers/date/date";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

const DriverHistoryFixed = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const [fixed, setFixed] = useState([]);
  const location = useLocation()
  const driverName = location.state.fullname

  useEffect(() => {
    const getPayments = async () => {
      try {
        const response = await getDriverPayments("token", id);
        setFixed([]);
        console.log(response.data);
        response.data.paymentList.map((payment) => {
          if (payment.fixed || payment.amount) {
            setFixed((oldData) => [...oldData, payment]);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    getPayments();
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: colors.primary[400],
    border: "2px solid #000",
    boxShadow: 20,
    p: 1,
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={driverName} subtitle="HISTORIAL DE PAGOS" />
            </Box>

            <Grid container alignItems="center" justify="center" spacing={1}>
              <Grid item xs={12} sm={12}>
                <Box
                  height="100vh"
                  backgroundColor={colors.primary[400]}
                  overflow="auto"
                >
                  {fixed.map((transaction, i) =>
                    i < 100 ? (
                      <Box
                        key={i}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom={`4px solid ${colors.primary[500]}`}
                        p="10px"
                      >
                        <Box>
                          <Typography
                            color={colors.greenAccent[500]}
                            variant="h5"
                            fontWeight="600"
                          >
                            {transaction.fixed >= 0 && "COMISION FIJA"}
                            {transaction.amount &&
                              transaction.amount >= 0 &&
                              "COBRADO"}
                            {transaction.amount &&
                              transaction.amount < 0 &&
                              "PAGADO"}{" "}
                            {transaction.notes ? transaction.notes.toUpperCase(): ""}
                          </Typography>
                          <Typography color={colors.grey[100]}>
                            {dateFormat(new Date(transaction.date))} -
                            {timeFormat(new Date(transaction.date))}
                          </Typography>
                        </Box>

                        <Box p="3px 3px">
                          {transaction.fixed >= 0 ? (
                            <Typography
                              color={"red"}
                              variant="h5"
                              fontWeight="600"
                            >
                              S/. {transaction.fixed}
                            </Typography>
                          ) : (
                            <Typography
                              color={transaction.amount >= 0 ? "green" : "red"}
                              variant="h5"
                              fontWeight="600"
                            >
                              S/. {Math.abs(transaction.amount)}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    ) : null
                  )}
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      {/* <TransactionDetail detail={detail} /> */}
                    </Box>
                  </Modal>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </main>
      </div>
    </>
  );
};

export default DriverHistoryFixed;
