import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import courgette from "../../../../font/CourgetteRegular.ttf";

Font.register({
  family: "Courgette",
  src: courgette,
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignContent: "center",
    marginTop: 5
  },

  divContainer: {
    width: "80%",
    alignContent: "center",
    margin: "auto",
    textAlign: "center",
  },

  text: {
    color: "#000000",
    fontSize: 10,
    fontFamily: "Courgette",
  },
});

const Footer = () => {
  const phone = `Atención al cliente +51-54-200900, WhatsApp 951-200900, WhatApp 958-200900`;
  const web = `Pág. Web. www.taximundo.com Email info@taximundo.com`;
  return (
    <View style={styles.container}>
      <View style={styles.divContainer}>
        <Text style={styles.text}>{phone}</Text>
        <Text style={styles.text}>{web}</Text>
      </View>
    </View>
  );
};

export default Footer;
