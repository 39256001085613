import React, { useState, Fragment, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";

//ICONS
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
//custom
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { BoxEdit, ButtomStyle } from "../components/styles/TourStyle";

import { VisuallyHiddenInput } from "../components/styles/VisuallyHiddenInput";
import { shiftListEN, shiftListES } from "../../../core/resources/shiftList";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { GetCountryAndCityUseCase } from "../../../domain/usecases/country/GetCountryAndCityUseCase";
import { GetTourUrlUseCase } from "../../../domain/usecases/tour/GetTourUrlUseCase";
import { tourList } from "../../../core/resources/tourList";

import {
  createTourStore,
  getTourStore,
  removeTourStore,
} from "../../../../helpers/store/TourStore";
import { CreateTourModel } from "./TourModel";
import {
  MetaContentTypes,
  MetaTypes,
} from "../../../../helpers/constants/Metadata";
import { languajeList } from "../../../../helpers/constants/Language";
import { CreateTourEmptyUseCase } from "../../../domain/usecases/tour/CreateTourEmptyUseCase";
import { UpdateTourUseCase } from "../../../domain/usecases/tour/UpdateTourUseCase";
import { UpdateTourCronUseCase } from "../../../domain/usecases/tour/UpdateTourCronUseCase";

const TourCreateScreen = () => {
  const secctionRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  //meta
  const [urlList, setUrlList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    const getCountryAndCityList = async () => {
      try {
        const response = await GetCountryAndCityUseCase();
        setCountryList([]);
        if (response.message == "success") {
          setCountryList(response.countryList);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const getTourUrlList = async () => {
      try {
        const response = await GetTourUrlUseCase();
        if (response.message === "success") {
          setUrlList(response.urlList);
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    };

    getCountryAndCityList();
    getTourUrlList();
  }, []);

  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const snackPosition = { vertical: "top", horizontal: "center" };
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [mainImage, setMainImage] = useState();
  const [destinationImage, setDestinationImage] = useState(null);
  const [activityImage, setActivityImage] = useState();
  const [destinationCheck, setDestinationCheck] = useState(false);
  const [activityCheck, setActivityCheck] = useState(false);

  const checkedTourData = (data) => {
    if (data.titlePage == "") return alert("INGRESE EL TITULO DE LA PAGINA");
    if (data.url == "") return alert("INGRESE LA URL");

    urlList.map((urlItem) => {
      if (data.url === urlItem) return alert("ESTA URL YA EXISTE");
    });

    if (data.title == "") return alert("INGRESE EL TITULO DEL TOUR");
    if (data.code === "") return alert("INGRESE EL CODIGO DEL TOUR");
    if (data.descriptionText == "")
      return alert("INGRESE LA DESCRIPCION DEL TOUR");
    if (data.country == 0) return alert("SELECCIONE EL PAIS");
    if (data.city == 0) return alert("SELECCIONE LA CIUDAD");

    if (mainImage == null) return alert("SELECCIONE LA IMAGEN DE PORTADA");

    if (data.priceShow == 2) {
      if (data.priceSharePen <= 0)
        return alert("INGRESE EL PRECIO SOLES COMPARTIDO");
      if (data.priceShareUsd <= 0)
        return alert("INGRESE EL PRECIO DOLARES COMPARTIDO");

      if (data.pricePrivPen <= 0)
        return alert("INGRESE EL PRECIO SOLES PRIVADO");
      if (data.pricePrivUsd <= 0)
        return alert("INGRESE EL PRECIO DOLARES PRIVADO");
    }
    if (data.priceShow == 1) {
      if (data.pricePrivPen <= 0)
        return alert("INGRESE EL PRECIO SOLES PRIVADO");
      if (data.pricePrivUsd <= 0)
        return alert("INGRESE EL PRECIO DOLARES PRIVADO");
    }
    if (data.priceShow == 0) {
      if (data.priceSharePen <= 0)
        return alert("INGRESE EL PRECIO SOLES COMPARTIDO");
      if (data.priceShareUsd <= 0)
        return alert("INGRESE EL PRECIO DOLARES COMPARTIDO");
    }

    if (data.typeTour == 0) return alert("SELECCIONE EL TIPO DE TOUR");
    if (data.language == 0) return alert("SELECCIONE EL IDIOMA DE LA PAGINA");
    if (
      data.horaryList[0].shiftName == "" ||
      data.horaryList[0].shiftDescription == ""
    )
      return alert("INGRESE LOS HORARIOS DE TOUR COMPARTIDO");

    return true;
  };

  const checkImageData = () => {
    if (mainImage == null) return alert("SELECCIONE LA IMAGEN DE PORTADA");
    return true;
  };

  const handleCreatedTour = async (published) => {
    try {
      const settings = {
        titlePage: formData.titlePage,
        metaList: formData.metaList,
      };

      const tour = {
        id: formData.id,
        uuid: uuidv4(),
        url: formData.url,
        title: formData.title,
        code: formData.code,
        descriptionText: formData.descriptionText,
        countryId: formData.country.countryId,
        cityId: formData.city.cityId,
        priceSharePen: formData.priceSharePen,
        priceShareUsd: formData.priceShareUsd,
        pricePrivPen: formData.pricePrivPen,
        pricePrivUsd: formData.pricePrivUsd,
        priceShow: formData.priceShow,
        typeTour: formData.typeTour.id,
        language: formData.language,
        image: {
          destination: destinationCheck,
          activity: activityCheck,
          mainChange: true,
        },
        search: formData.search,
        settings,
        horaryList: formData.horaryList,
        defOrigin: formData.defOrigin,
        defDestination: formData.defDestination,
        sectionList: formData.sectionList,
        published,
      };

      if (published) {
        const check = checkedTourData(formData);
        if (check) {
          setIsLoading(true);
          const data = new FormData();
          data.append("mainImage", mainImage);

          if (destinationCheck)
            data.append("destinationImage", destinationImage);
          if (activityCheck) data.append("activityImage", activityImage);

          data.append("tour", JSON.stringify(tour));
          const resp = await UpdateTourUseCase(data);
          if (resp.message === "success") {
            snackBarDialog("SE HA CREADO CORRECTAMENTE");
            removeTourStore();
          } else {
            if (resp.message === "ERROR_URL_TOUR_EXCEEDS_MAXIMUM_ALLOWED") {
              snackBarDialog("YA EXISTEN 2 URL IGUALES ASIGNADOS", "error");
            } else {
              snackBarDialog("HA OCURRIDO UN ERROR EN LA CREACION", "error");
            }
          }
          setIsLoading(false);
        }
      } else {
        if (checkImageData()) {
          setIsLoading(true);
          const data = new FormData();
          data.append("mainImage", mainImage);

          if (destinationCheck)
            data.append("destinationImage", destinationImage);
          if (activityCheck) data.append("activityImage", activityImage);

          data.append("tour", JSON.stringify(tour));
          const resp = await UpdateTourUseCase(data);
          if (resp.message === "success") {
            snackBarDialog("SE HA CREADO CORRECTAMENTE");
          } else {
            if (resp.message === "ERROR_URL_TOUR_EXCEEDS_MAXIMUM_ALLOWED") {
              snackBarDialog("YA EXISTEN 2 URL IGUALES ASIGNADOS", "error");
            } else {
              snackBarDialog("HA OCURRIDO UN ERROR EN LA CREACION", "error");
            }
          }
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      snackBarDialog("HA OCURRIDO UN ERROR EN LA CREACION", "error");
    }
  };

  const [content, setContent] = useState("");

  const [formData, setFormData] = useState({
    id: 0,
    titlePage: "",
    metaList: [{ type: "", typeContent: "", content: "" }],
    url: "",
    title: "",
    code: "",
    country: {},
    city: {},
    typeTour: {},
    descriptionText: "",
    priceSharePen: 0,
    priceShareUsd: 0,
    pricePrivPen: 0,
    pricePrivUsd: 0,
    priceShow: 2,
    language: 1,
    search: false,
    horaryList: [{ shiftName: "", shiftDescription: "" }],
    defOrigin: "",
    defDestination: "",
    sectionList: [{ sectionTitle: "", content: "" }],
    published: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputMetalist = (e, index) => {
    const { name, value } = e;
    const updatedMetaList = [...formData.metaList];

    updatedMetaList[index] = {
      ...updatedMetaList[index],
      [name]: value,
    };

    setFormData({
      ...formData,
      metaList: updatedMetaList,
    });
  };

  const handleInputHorary = (e, index) => {
    const { name, value } = e;
    console.log(name, value, index);
    const updatedList = [...formData.horaryList];

    updatedList[index] = {
      ...updatedList[index],
      [name]: value,
    };

    setFormData({
      ...formData,
      horaryList: updatedList,
    });
  };

  const handleInputSectionTitle = (e, index) => {
    const { name, value } = e;
    console.log(name, value);
    const updatedSectionList = [...formData.sectionList];

    updatedSectionList[index] = {
      ...updatedSectionList[index],
      [name]: value,
    };

    setFormData({
      ...formData,
      sectionList: updatedSectionList,
    });
  };

  const handleInputSectionContent = (content, index) => {
    const updatedSectionList = [...formData.sectionList];

    updatedSectionList[index] = {
      ...updatedSectionList[index],
      ["content"]: content,
    };

    setFormData({
      ...formData,
      sectionList: updatedSectionList,
    });
  };

  const handleAddNewMeta = (metaItem) => {
    if (
      metaItem.type !== "" &&
      metaItem.typeContent !== "" &&
      metaItem.content !== ""
    ) {
      const newItem = { type: "", typeContent: "", content: "" };
      const updatedMetaList = [...formData.metaList];

      setFormData({
        ...formData,
        metaList: [...updatedMetaList, newItem],
      });
    } else {
      window.alert("INGRESE TODOS LOS CAMPOS PARA SEGUIR AGREGANDO");
    }
  };

  const handleRemoveMeta = (index) => {
    const metaList = [...formData.metaList];
    metaList.splice(index, 1);

    setFormData({
      ...formData,
      metaList: [...metaList],
    });
  };

  const handleAddNewSection = () => {
    if (
      formData.sectionList[formData.sectionList.length - 1].title !== "" &&
      formData.sectionList[formData.sectionList.length - 1].content !== ""
    ) {
      const newItem = { sectionTitle: "", content: "" };
      const updatedSectionList = [...formData.sectionList];

      setFormData({
        ...formData,
        sectionList: [...updatedSectionList, newItem],
      });
    } else {
      window.alert("INGRESE TODOS LOS CAMPOS PARA SEGUIR AGREGANDO");
    }
  };

  const addItemHoraryList = (e) => {
    e.preventDefault();
    if (
      formData.horaryList[formData.horaryList.length - 1].shiftName !== "" &&
      formData.horaryList[formData.horaryList.length - 1].shiftDescription !==
        ""
    ) {
      const newItem = { shiftName: "", shiftDescription: "" };
      const updatedList = [...formData.horaryList];

      setFormData({
        ...formData,
        horaryList: [...updatedList, newItem],
      });
    } else {
      window.alert("INGRESE TODOS LOS CAMPOS DE HORARIO");
    }
  };

  const removeItemHoraryList = (index) => {
    const list = [...formData.horaryList];
    list.splice(index, 1);

    setFormData({
      ...formData,
      horaryList: [...list],
    });
  };

  const [isFirstIteration, setIsFirstIteration] = useState(true);
  useEffect(() => {
    const tourStore = getTourStore();
    if (tourStore != null || tourStore != undefined) {
      if (isFirstIteration) {
        setIsFirstIteration(false);
        setFormData(tourStore);
      } else createTourStore(formData);
    } else CreateTourEmpty();
  }, [formData, content]);

  useEffect(() => {
    if (formData.id > 0) {
      const interval = setInterval(() => {
        const tour = CreateTourModel(formData);
        UpdateTourCron(tour);
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [formData]);

  const CreateTourEmpty = async () => {
    const resp = await CreateTourEmptyUseCase();
    switch (resp.message) {
      case "SUCCESS":
        const id = resp.tourId;
        formData.id = id;
        createTourStore(formData);
        break;
      default:
        console.log(resp.message);
        break;
    }
  };

  const UpdateTourCron = async (tour) => {
    await UpdateTourCronUseCase(tour);
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={"CREAR PAGINA DE TOUR"} subtitle="" />
            </Box>
            {formData && (
              <form>
                <Box
                  border={2}
                  borderColor="green"
                  padding={1}
                  borderRadius={3}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={10}>
                      <TextField
                        value={formData.titlePage}
                        label={"TITULO DE LA PAGINA"}
                        name="titlePage"
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={handleInputChange}
                        InputLabelProps={{ style: { color: "white" } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormControl fullWidth>
                        <InputLabel style={{ color: "white" }}>
                          IDIOMA
                        </InputLabel>{" "}
                        <Select
                          value={formData.language}
                          onChange={handleInputChange}
                          label="IDIOMA"
                          name="language"
                        >
                          {languajeList.map((elem, index) => (
                            <MenuItem key={index} value={elem.id}>
                              {elem.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {formData.metaList.map((meta, index) => {
                      return (
                        <Fragment key={index}>
                          <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                              <InputLabel>META</InputLabel>
                              <Select
                                value={meta.type}
                                onChange={(e) =>
                                  handleInputMetalist(e.target, index)
                                }
                                label={"META"}
                                name="type"
                              >
                                {MetaTypes.map((elem, index) => (
                                  <MenuItem key={index} value={elem.type}>
                                    {elem.type}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                              <InputLabel>SELECCTIONE</InputLabel>
                              <Select
                                value={meta.typeContent}
                                onChange={(e) => {
                                  handleInputMetalist(e.target, index);
                                }}
                                label={"SELECCIONE"}
                                name="typeContent"
                              >
                                {MetaContentTypes.map((elem, index) => (
                                  <MenuItem key={index} value={elem.type}>
                                    {elem.type}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              value={meta.content}
                              fullWidth
                              variant="outlined"
                              type="text"
                              onChange={(e) => {
                                handleInputMetalist(e.target, index);
                              }}
                              label={"CONTENIDO"}
                              InputLabelProps={{
                                style: { color: "white" },
                              }}
                              name="content"
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <button
                                      style={{ marginRight: -5 }}
                                      color="success"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleAddNewMeta(meta);
                                      }}
                                    >
                                      +
                                    </button>
                                    <button
                                      style={{ marginRight: -5 }}
                                      color="success"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveMeta(index);
                                      }}
                                    >
                                      -
                                    </button>
                                  </>
                                ),
                              }}
                            />
                          </Grid>
                        </Fragment>
                      );
                    })}
                  </Grid>
                </Box>

                <Box
                  border={2}
                  borderColor="green"
                  padding={1}
                  borderRadius={3}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        value={formData.url}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={handleInputChange}
                        label={"URL"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="url"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        value={formData.title}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={handleInputChange}
                        label={"TITULO"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="title"
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        value={formData.code}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={handleInputChange}
                        label={"CODIGO DE TOUR"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="code"
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel style={{ color: "white" }}>PAIS</InputLabel>
                        <Select
                          value={formData?.country?.countryId || ""}
                          id="country"
                          onChange={(e) => {
                            const { name, value } = e.target;
                            const selectedCountry = countryList.find(
                              (country) => country.countryId === value
                            );
                            const event = {
                              target: { name, value: selectedCountry },
                            };
                            handleInputChange(event);
                          }}
                          label={"PAIS"}
                          name="country"
                        >
                          {countryList.map((elem, index) => (
                            <MenuItem key={index} value={elem.countryId}>
                              {elem.countryName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel style={{ color: "white" }}>
                          CIUDAD
                        </InputLabel>
                        <Select
                          value={formData?.city?.cityId || ""}
                          id="city"
                          onChange={(e) => {
                            const { name, value } = e.target;
                            const list = formData.country?.city;
                            const selectedCity = list.find(
                              (city) => city.cityId === value
                            );
                            const event = {
                              target: { name, value: selectedCity },
                            };
                            handleInputChange(event);
                          }}
                          label={"CIUDAD"}
                          name="city"
                        >
                          {formData.country?.city?.map((elem, index) => {
                            return (
                              <MenuItem key={index} value={elem.cityId}>
                                {elem.cityName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel style={{ color: "white" }}>
                          TIPO DE TOUR
                        </InputLabel>
                        <Select
                          value={formData.typeTour?.id || ""}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            console.log(value);
                            const selectedType = tourList.find(
                              (type) => type.id === value
                            );
                            console.log(selectedType);
                            const event = {
                              target: { name, value: selectedType },
                            };
                            handleInputChange(event);
                          }}
                          label={"TIPO DE TOUR"}
                          name="typeTour"
                        >
                          {tourList.map((elem, index) => (
                            <MenuItem key={index} value={elem.id}>
                              {elem.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <label style={{ display: "block" }}>PORTADA</label>

                      <Button
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                      >
                        <VisuallyHiddenInput
                          type="file"
                          name="mainImage"
                          onChange={(e) => {
                            console.log(e.target.files);
                            setMainImage(e.target.files[0]);
                          }}
                        />
                      </Button>
                      {mainImage ? mainImage.name : ""}
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <label style={{ display: "block" }}>DESTINOS</label>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="success"
                            size="small"
                            checked={destinationCheck}
                            onChange={(e) =>
                              setDestinationCheck(e.target.checked)
                            }
                          />
                        }
                        label="Activar"
                      />
                      <Button
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                      >
                        <VisuallyHiddenInput
                          type="file"
                          name="fileDestination"
                          onChange={(e) =>
                            setDestinationImage(e.target.files[0])
                          }
                        />
                      </Button>
                      {destinationImage ? destinationImage.name : ""}
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <label style={{ display: "block" }}>ACTIVIDADES</label>
                      <FormControlLabel
                        control={<Checkbox color="success" size="small" />}
                        label="Activar"
                        checked={activityCheck}
                        onChange={(e) => {
                          console.log(e.target.checked);
                          setActivityCheck(e.target.checked);
                        }}
                      />
                      <Button
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                      >
                        <VisuallyHiddenInput
                          type="file"
                          name="fileActivities"
                          onChange={(e) => {
                            console.log(e.target.files);
                            setActivityImage(e.target.files[0]);
                          }}
                        />
                      </Button>
                      {activityImage ? activityImage.name : ""}
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <label style={{ display: "block" }}>TOP DESTINOS?</label>
                      <FormControlLabel
                        control={<Checkbox color="success" size="small" />}
                        label="Activar"
                        name="search"
                        checked={formData.search}
                        onChange={(e) => {
                          const { name, checked } = e.target;
                          const event = { target: { name, value: checked } };
                          handleInputChange(event);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>
                          Descripcion Texto (para las cards)
                        </InputLabel>
                        <OutlinedInput
                          value={formData.descriptionText}
                          name="descriptionText"
                          fullWidth
                          variant="outlined"
                          type="text"
                          onChange={handleInputChange}
                          label="Descripcion Texto (para las cards)"
                          endAdornment={
                            <InputAdornment position="end">
                              {formData.descriptionText.length}
                            </InputAdornment>
                          }
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "white",
                            },
                            "&:hover > .MuiOutlinedInput-notchedOutline": {
                              borderColor: "white",
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} textAlign={"center"}>
                      <Typography variant="h5" fontWeight={"bold"}>
                        PRECIOS
                      </Typography>
                    </Grid>

                    <Grid item xs={6} sm={4}>
                      {(Number(formData.priceShow) === 0 ||
                        Number(formData.priceShow) === 2) && (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              fontWeight={"bold"}
                              textAlign={"center"}
                            >
                              COMPARTIDO
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <TextField
                              value={formData.priceSharePen}
                              fullWidth
                              variant="outlined"
                              type="number"
                              onChange={handleInputChange}
                              label={"PEN"}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="priceSharePen"
                            />
                          </Grid>

                          <Grid item xs={6} sm={6}>
                            <TextField
                              value={formData.priceShareUsd}
                              fullWidth
                              variant="outlined"
                              type="number"
                              onChange={handleInputChange}
                              label={"USD"}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="priceShareUsd"
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={6} sm={4}>
                      {(Number(formData.priceShow) == 1 ||
                        Number(formData.priceShow) == 2) && (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              fontWeight={"bold"}
                              textAlign={"center"}
                            >
                              PRIVADO
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <TextField
                              value={formData.pricePrivPen}
                              fullWidth
                              variant="outlined"
                              type="number"
                              onChange={handleInputChange}
                              label={"PEN"}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="pricePrivPen"
                            />
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <TextField
                              value={formData.pricePrivUsd}
                              fullWidth
                              variant="outlined"
                              type="number"
                              onChange={handleInputChange}
                              label={"USD"}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="pricePrivUsd"
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h5"
                            fontWeight={"bold"}
                            textAlign={"center"}
                          >
                            PRECIO VISIBLE
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormControl>
                            <RadioGroup
                              row
                              name="priceShow"
                              value={formData.priceShow}
                              onChange={handleInputChange}
                            >
                              <FormControlLabel
                                value={0}
                                control={<Radio color="success" />}
                                label="Compartido"
                              />
                              <FormControlLabel
                                value={1}
                                control={<Radio color="success" />}
                                label="Privado"
                              />
                              <FormControlLabel
                                value={2}
                                control={<Radio color="success" />}
                                label="Ambos"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} marginTop={"10px"}>
                      <Typography
                        variant="h5"
                        fontWeight={"bold"}
                        textAlign={"center"}
                      >
                        HORARIOS DE TOUR COMPARTIDO
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} marginTop={"5px"}>
                      {formData.horaryList.map((item, idx) => (
                        <Grid container spacing={1} key={idx} marginTop={"3px"}>
                          <Grid item xs={12} sm={4} md={4}>
                            <FormControl fullWidth>
                              <InputLabel>SELECCTIONE</InputLabel>
                              <Select
                                value={item.shiftName || ""}
                                onChange={(e) => {
                                  handleInputHorary(e.target, idx);
                                }}
                                label={"SELECCIONE"}
                                name="shiftName"
                              >
                                {formData.language == 0 && (
                                  <h5>SELECCIONE IDIOMA DE PAGINA</h5>
                                )}
                                {formData.language == 1 &&
                                  shiftListES.map((elem, index) => (
                                    <MenuItem key={index} value={elem.shift}>
                                      {elem.shift}
                                    </MenuItem>
                                  ))}

                                {formData.language == 2 &&
                                  shiftListEN.map((elem, index) => (
                                    <MenuItem key={index} value={elem.shift}>
                                      {elem.shift}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={10} sm={6} md={6}>
                            <TextField
                              value={item.shiftDescription}
                              fullWidth
                              variant="outlined"
                              type="text"
                              onChange={(e) => {
                                handleInputHorary(e.target, idx);
                              }}
                              label={"HORARIOS"}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="shiftDescription"
                            />
                          </Grid>

                          <Grid item xs={1} sm={1} md={1}>
                            <button
                              style={{
                                height: "100%",
                                width: "100%",
                                color: "white",
                                backgroundColor: "green",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                              }}
                              onClick={addItemHoraryList}
                            >
                              +
                            </button>
                          </Grid>
                          {formData.horaryList.length > 1 && idx != 0 && (
                            <Grid item xs={1} sm={1} md={1}>
                              <button
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  color: "white",
                                  backgroundColor: "red",
                                  border: "none",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  removeItemHoraryList(idx);
                                }}
                              >
                                -
                              </button>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Grid>

                    <Grid item xs={12} marginTop={"10px"}>
                      <Typography
                        variant="h5"
                        fontWeight={"bold"}
                        textAlign={"center"}
                      >
                        ESTABLECER ORIGEN DESTINO PARA TOUR COMPARTIDO
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={formData.defOrigin}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={handleInputChange}
                        label="LUGAR DE RECOJO"
                        InputLabelProps={{ style: { color: "white" } }}
                        name="defOrigin"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={formData.defDestination}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={handleInputChange}
                        label="DESTINO FINAL"
                        InputLabelProps={{ style: { color: "white" } }}
                        name="defDestination"
                      />
                    </Grid>

                    <Grid
                      container
                      spacing={1}
                      sx={{ marginTop: 1 }}
                      paddingLeft={1}
                    >
                      <Grid item xs={12} marginBottom={1} textAlign={"end"}>
                        <Button
                          variant="contained"
                          color="success"
                          sx={{ width: { xs: "100%", sm: 200 } }}
                          onClick={(e) => {
                            handleAddNewSection();
                          }}
                        >
                          AGREGAR SECCION
                        </Button>
                      </Grid>

                      {formData.sectionList.map((section, idx) => {
                        return (
                          <Fragment key={idx}>
                            <Grid item xs={12}>
                              <TextField
                                value={section.sectionTitle}
                                fullWidth
                                variant="outlined"
                                type="text"
                                name="sectionTitle"
                                onChange={(e) => {
                                  handleInputSectionTitle(e.target, idx);
                                }}
                                label="TITULO DE SECCION"
                                InputLabelProps={{ style: { color: "white" } }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Box style={BoxEdit}>
                                <JoditEditor
                                  ref={secctionRef}
                                  value={section.content}
                                  tabIndex={1}
                                  onBlur={(newContent) => {
                                    setContent(newContent);
                                    handleInputSectionContent(newContent, idx);
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Fragment>
                        );
                      })}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box display="flex" justifyContent="end" mt="10px">
                        <Button
                          color="warning"
                          variant="contained"
                          sx={ButtomStyle}
                          onClick={(e) => handleCreatedTour(false)}
                          disabled={isLoading}
                        >
                          BORRADOR
                        </Button>
                        <Button
                          color="secondary"
                          variant="contained"
                          sx={ButtomStyle}
                          onClick={(e) => handleCreatedTour(true)}
                          disabled={isLoading}
                        >
                          GUARDAR
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          </Box>
        </main>
      </div>
      <SnackBar
        stateSnk={stateSnk}
        vertical={snackPosition.vertical}
        horizontal={snackPosition.horizontal}
        duration={4000}
        handleClose={handleSnkClose}
        severity={severity}
        messageSnk={messageSnk}
      />
    </>
  );
};

export default TourCreateScreen;
