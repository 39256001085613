import { Box, CssBaseline } from "@mui/material";
import { useParams } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Topbar from "../../../../components/topbar/TopBar";

//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";
//FIREBASE
import { db } from "../../../../firebase";
import { ref, onValue, remove } from "firebase/database";
import RateTable from "./RateTable";
import RatesForm from "./RatesForm";

const RatesList = () => {
  const { id } = useParams();
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="LISTA DE TARIFAS" subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <RatesForm />
                <RateTable />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default RatesList;
