export const StatusEnum = {
  ACTIVO: 1,
  SUSPENDIDO: 2,
  ELIMINADO: 3,
};

export const InvertedStatusEnum = Object.fromEntries(
  Object.entries(StatusEnum).map(([key, value]) => [value, key])
);

export const StatusEnumName = (value) => {
  return InvertedStatusEnum[value];
};
