import { deleteEmployeeApi } from "../../../../../api/users/employee/employeeApi";

export const UpdateStatusEmployeeUseCase = async (employeeId, status) => {
  try {
    const resp = await deleteEmployeeApi(employeeId, status);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
