import React from "react";
import { Box, CssBaseline, Modal, Typography } from "@mui/material";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";

const ShoppingCreateScreen = () => {
  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="COTIZACION" subtitle="" />
            </Box>
            <Box>
              {/* <ReserveTable data={listShopping} columns={columns} /> */}
              {/* <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleSnkClose}
              severity={severity}
              messageSnk={messageSnk}
            /> */}

              {/* MODAL PARA GUARDAR LA RESERVA DE SHOPPING  */}
              {/* <Modal
              open={modalReservation}
              onClose={modalReservationClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign={"center"}
                  fontWeight={"bold"}
                >
                  CONFIRMAR RESERVACION
                </Typography>
                <Grid container spacing={1}>
                  {itemSelect != null && (
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>TITULO:</span>{" "}
                        {itemSelect.tour_title}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>CLIENTE:</span>{" "}
                        {itemSelect.rider_name.toUpperCase()}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>PRECIO:</span>{" "}
                        {itemSelect.currency} {itemSelect.price}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>PRECIO:</span>{" "}
                        {itemSelect.service}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>FECHA:</span>{" "}
                        {itemSelect.date} HORA: {itemSelect.time}
                        {itemSelect.horary}
                      </Typography>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      value={origin}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setOrigin(e.target.value)}
                      label="ORIGEN"
                      InputLabelProps={{ style: { color: "white" } }}
                      name="origin"
                      error={errorOrigin}
                      helperText={
                        errorOrigin ? "Complete Lugar de Recogida" : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      value={destination}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setDestination(e.target.value)}
                      label="DESTINO"
                      InputLabelProps={{ style: { color: "white" } }}
                      name="destination"
                      error={errorDestination}
                      helperText={errorDestination ? "Complete Destino" : ""}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      value={notes}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => {
                        setNotes(e.target.value);
                      }}
                      label="NOTAS"
                      InputLabelProps={{ style: { color: "white" } }}
                      name="notes"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      variant="contained"
                      color="success"
                      fullWidth
                      sx={{ height: 50, color: "black", fontWeight: "bold" }}
                      onClick={handleCreateReservation}
                      disabled={isLoading}
                    >
                      GUARDAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal> */}

              {/* DELETE SHOPPING */}
              {/* <Modal open={modalDelete} onClose={modalDeleteClose}>
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign={"center"}
                  fontWeight={"bold"}
                >
                  ELIMINAR ESTA COTIZACION?
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6}>
                    <Button
                      variant="contained"
                      color="success"
                      fullWidth
                      sx={{ height: 45, color: "black", fontWeight: "bold" }}
                      onClick={handleDeleteReservation}
                      disabled={isLoading}
                    >
                      ELIMINAR
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Button
                      variant="contained"
                      color="error"
                      fullWidth
                      sx={{ height: 45, color: "black", fontWeight: "bold" }}
                      onClick={modalDeleteClose}
                      disabled={isLoading}
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal> */}
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default ShoppingCreateScreen;
