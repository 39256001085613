import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#424949";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 20,
    fontStyle: "bold",
  },
  index: {
    width: "80%",
    textAlign: "center",
    fontSize: 8,
  },
  title: {
    width: "15%",
    height: 20,
    borderRightColor: borderColor,
    borderRightWidth: 0,
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    fontSize: 10,
    padding: 2,
  },
  price: {
    width: "10%",
    height: 20,
    textAlign: "right",
    fontSize: 10,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    padding: 2,
  },
});

const TableRiderTotal = ({ invoice }) => {
  const currencyList = {
    1: "PEN",
    2: "USD",
  };
  const currencyTable = currencyList[invoice?.items[0]?.payment_currency];
  const totalPrice = invoice.total - invoice.discount - invoice.advance;
  return (
    <>
      <View style={styles.row}>
        <Text style={styles.index}>{}</Text>
        <Text style={styles.title}>Total: {currencyTable}</Text>
        <Text style={styles.price}>{Number(invoice.total).toFixed(2)}</Text>
      </View>
      {invoice.discount > 0 && (
        <View style={styles.row}>
          <Text style={styles.index}>{}</Text>
          <Text style={styles.title}>Descuento: {currencyTable}</Text>
          <Text style={styles.price}>
            {Number(invoice.discount).toFixed(2)}
          </Text>
        </View>
      )}

      {invoice.advance > 0 && (
        <View style={styles.row}>
          <Text style={styles.index}>{}</Text>
          <Text style={styles.title}>Adelanto: {currencyTable}</Text>
          <Text style={styles.price}>{Number(invoice.advance).toFixed(2)}</Text>
        </View>
      )}

      <View style={styles.row}>
        <Text style={styles.index}>{}</Text>
        <Text style={styles.title}>Total Pagar: {currencyTable}</Text>
        <Text style={styles.price}>{totalPrice.toFixed(2)}</Text>
      </View>
    </>
  );
};

export default TableRiderTotal;
