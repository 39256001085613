import { getListReservationCompleted } from "../../../data/repository/booking.repo";

export const GetBookingCompleteUseCase = async () => {
  try {
    const resp = await getListReservationCompleted();
    return resp.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
