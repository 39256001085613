import { baseBackend } from "../../base/baseUrl";

export const createdDriverRate = async (driver_rate) => {
  return await baseBackend.post(
    "/api/v1/driver-rate",
    JSON.stringify({ driver_rate }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getDriverRate = async (id) => {
  return await baseBackend.get(`/api/v1/driver-rate/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateDriverRate = async (driver_rate) => {
  return await baseBackend.put(
    "/api/v1/driver-rate",
    JSON.stringify({ driver_rate }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const modifyDriverRate = async (id, modify) => {
  return await baseBackend.put(
    `/api/v1/driver-rate/id/${id}/modify/${modify}`,
    { headers: { "Content-Type": "application/json" } }
  );
};

export const deleteDriverRate = async (id) => {
  return await baseBackend.delete(`/api/v1/driver-rate/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
