import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { VisuallyHiddenInput } from "../components/VisuallyHiddenInput";
import { metaTypeList } from "../../../core/resources/metadataList";
import { ReadCountryByIdUseCase } from "../../../domain/usecases/country/ReadCountryByIdUseCase";
import { UpdateCountryUseCase } from "../../../domain/usecases/country/UpdateCountryUseCase";

const CountryUpdateScreen = () => {
  const { id } = useParams();

  const [titlePage, setTitlePage] = useState("");
  const [metaList, setMetaList] = useState([
    { type: "", typeContent: "", content: "" },
  ]);
  const [UID, setUID] = useState(0);
  const [country, setCountry] = useState("");
  const [abbreviature, setAbbreviature] = useState("");
  const [mainImage, setMainImage] = useState(null);
  const [image, setImage] = useState("");
  const [imageAlt, setImageAlt] = useState("");
  const [imageTitle, setImageTitle] = useState("");
  const [searchCheck, setSearchCheck] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [typeTour, setTypeTour] = useState(null);
  const [aux1, setAux1] = useState("");
  const [aux2, setAux2] = useState("");
  const [aux3, setAux3] = useState("");

  const [showData, setShowData] = useState(false);

  useEffect(() => {
    const getCountry = async () => {
      try {
        const resp = await ReadCountryByIdUseCase(id);
        setShowData(true);
        if (resp.message == "success") {
          console.log(resp);
          const data = resp.data;

          if (resp.data.settings !== "") {
            const sett = await JSON.parse(resp.data.settings);
            setTitlePage(sett.titlePage);
            setMetaList(sett.metaList);
          }

          setUID(data.id);
          setCountry(data.name);
          setAbbreviature(data.abbreviature);
          setImage(data.image);
          setMainImage(data.image);
          setImageAlt(data.image_alt);
          setImageTitle(data.image_title);
          setSearchCheck(Boolean(data.show_destination));
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCountry();
  }, []);

  const handleServiceAdd = () => {
    const size = metaList.length - 1;
    if (
      metaList[size].type !== "" &&
      metaList[size].typeContent !== "" &&
      metaList[size].content !== ""
    ) {
      setMetaList([...metaList, { type: "", typeContent: "", content: "" }]);
      setAux1("");
      setAux2("");
      setAux3("");
    } else {
      window.alert("INGRESE EL CAMPO PARA SEGUIR CREANDO");
    }
  };

  const handleServiceRemove = (index) => {
    const list = [...metaList];
    list.splice(index, 1);
    setMetaList(list);
  };

  const addServiceType = (value, index) => {
    metaList[index].type = value;
  };

  const addServiceMeta = (value, index) => {
    metaList[index].content = value;
  };

  const addServiceTypeContent = (value, index) => {
    metaList[index].typeContent = value;
  };

  const handleCountryUpdate = async () => {
    try {
      const data = new FormData();

      if (image !== mainImage) data.append("mainImage", mainImage);

      const settings = {
        titlePage,
        metaList,
      };

      const countryModel = {
        id: UID,
        name: country,
        abbreviature: abbreviature,
        settings: settings,
        showDestination: searchCheck,
        image: image === mainImage ? image : "",
        imageAlt: imageAlt,
        imageTitle: imageTitle,
      };

      data.append("country", JSON.stringify(countryModel));
      const resp = await UpdateCountryUseCase(data);

      console.log(resp);
      if (resp.message === "success") {
        window.alert("PAIS ACTUALIZADO");
      } else {
        window.alert("ERROR");
      }
    } catch (error) {
      console.log(error);
      window.alert("ERROR");
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={`ACTUALIZAR: `} subtitle="" />
            </Box>
            {showData && (
              <Box padding={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      value={titlePage}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setTitlePage(e.target.value)}
                      label={"TITULO DE LA PAGINA"}
                      InputLabelProps={{ style: { color: "white" } }}
                      name="title"
                    />
                  </Grid>

                  {metaList.map((service, index) => {
                    return (
                      <Fragment key={index}>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <InputLabel>META(tipo)</InputLabel>
                            <Select
                              value={service.type ? service.type : aux1}
                              onChange={(e) => {
                                setAux1(e.target.value);
                                addServiceType(e.target.value, index);
                              }}
                              label={"META(tipo)"}
                              name="route1"
                            >
                              {metaTypeList.map((elem, index) => (
                                <MenuItem key={index} value={elem.type}>
                                  {elem.type}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            value={
                              service.typeContent ? service.typeContent : aux2
                            }
                            fullWidth
                            variant="outlined"
                            type="text"
                            onChange={(e) => {
                              setAux2(e.target.value);
                              addServiceTypeContent(e.target.value, index);
                            }}
                            label={"Nombre"}
                            InputLabelProps={{
                              style: { color: "white" },
                            }}
                            name="route2"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={service.content ? service.content : aux3}
                            fullWidth
                            variant="outlined"
                            type="text"
                            onChange={(e) => {
                              setAux3(e.target.value);
                              addServiceMeta(e.target.value, index);
                            }}
                            label={"CONTENIDO"}
                            InputLabelProps={{
                              style: { color: "white" },
                            }}
                            name="route"
                            InputProps={{
                              endAdornment: (
                                <>
                                  <button
                                    style={{ marginRight: -5 }}
                                    color="success"
                                    onClick={handleServiceAdd}
                                  >
                                    +
                                  </button>
                                  <button
                                    style={{ marginRight: -5 }}
                                    color="success"
                                    onClick={(e) => handleServiceRemove(index)}
                                  >
                                    -
                                  </button>
                                </>
                              ),
                            }}
                          />
                        </Grid>
                      </Fragment>
                    );
                  })}

                  <Grid item xs={12} sm={3}>
                    <TextField
                      value={country}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setCountry(e.target.value)}
                      label={"NOMBRE DEL PAIS"}
                      InputLabelProps={{ style: { color: "white" } }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      name="country"
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      value={abbreviature}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setAbbreviature(e.target.value)}
                      label={"ABREVIATURA"}
                      InputLabelProps={{ style: { color: "white" } }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      name="abbreviature"
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <label style={{ display: "block" }}>PORTADA</label>
                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                    >
                      <VisuallyHiddenInput
                        type="file"
                        name="mainImage"
                        onChange={(e) => setMainImage(e.target.files[0])}
                      />
                    </Button>
                    {mainImage ? mainImage.name : ""}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      value={imageAlt}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setImageAlt(e.target.value)}
                      label={"ALT"}
                      InputLabelProps={{ style: { color: "white" } }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      name="alt"
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      value={imageTitle}
                      fullWidth
                      variant="outlined"
                      type="text"
                      onChange={(e) => setImageTitle(e.target.value)}
                      label={"Titulo Imagen"}
                      InputLabelProps={{ style: { color: "white" } }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      name="imageTitle"
                    />
                  </Grid>

                  <Grid item xs={6} sm={2}>
                    <label style={{ display: "block" }}>DESTINOS?</label>
                    <FormControlLabel
                      control={<Checkbox color="success" size="small" />}
                      label="Activar"
                      checked={searchCheck}
                      onChange={(e) => setSearchCheck(e.target.checked)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box display="flex" justifyContent="end" mt="10px">
                      <Button
                        color="secondary"
                        variant="contained"
                        sx={{ width: 200, height: 45 }}
                        onClick={handleCountryUpdate}
                      >
                        ACTUALIZAR
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </main>
      </div>
    </>
  );
};

export default CountryUpdateScreen;
