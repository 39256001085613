import { baseBackend } from "../../../api/base/baseUrl";

const URL_RESERVATION = "/api/v1/module/booking";

export const getListReservation = async () => {
  return await baseBackend.get(`${URL_RESERVATION}/all`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getReservation = async (id) => {
  return await baseBackend.get(`${URL_RESERVATION}/single/${id}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getListReservationCompleted = async () => {
  return await baseBackend.get(`${URL_RESERVATION}/complete`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const updateReservation = async (reservation) => {
  return await baseBackend.put(
    `${URL_RESERVATION}/update`,
    JSON.stringify({ reservation }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const updateReservationState = async (reservation) => {
  return await baseBackend.put(
    `${URL_RESERVATION}/state`,
    JSON.stringify({ reservation }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const createReservation = async (reservation) => {
  return await baseBackend.post(
    `${URL_RESERVATION}/create-admin`,
    JSON.stringify({ reservation }),
    { headers: { "Content-Type": "application/json" } }
  );
};
