import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CssBaseline,
  MenuItem,
  Popper,
  Paper,
  Typography,
  Grid,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  TextField,
  IconButton,
  Stack,
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import Topbar from "../../../../../components/topbar/TopBar";
import Header from "../../../../../components/header/Header";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import { tokens } from "../../../../../theme";
import { getListEnterprisesInvoice } from "../../../../../api/enterprise/enterprise";
import { getEmployeesFromEnterprise } from "../../../../../api/users/employee/employeeApi";
import { getToken } from "../../../../../helpers/token/refreshToken";
import { getSubroles } from "../../../../../api/enterprise/subroles";
import {
  searchBookingEnterprisePerEmployee,
  searchBookingEnterprisePerRole,
  searchBookingPerEnterprise,
} from "../../../../../api/booking/Invoice";
import GridDataTable from "../DataTable/GridDataTable";
import { getBookingBusiness } from "../../../../../api/booking/bookingBusiness";
import { useNavigate } from "react-router-dom";
import { convertDate } from "../../../../../helpers/date/convertDate";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../components/sidebar/Sidebar";
import { GetFilterHistory } from "./usecases/GetFilterHistory";
import { ReadEnterpriseSede } from "../../employee/costcenter/usecases/ReadEnterpriseSede";
import { ReadCostCenter } from "../../employee/costcenter/usecases/ReadCostCenter";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ReportHistory from "../../employee/ReportHistory";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const CreditBooking = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  //VARIABLES
  const [typeReport, setTypeReport] = useState(0);
  const [enterprises, setEnterprises] = useState([]);
  const [enterprise, setEnterprise] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [role, setRole] = useState(null);
  const [roles, setRoles] = useState([]);

  const [booking, setBooking] = useState([]);
  //EMPRESAS
  useEffect(() => {
    const getEnterprises = async () => {
      try {
        const token = "";
        const response = await getListEnterprisesInvoice(token, 1);
        setEnterprises([]);
        console.log(response.data);
        setEnterprises(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEnterprises();
  }, []);

  useEffect(() => {
    const getBookingBusinessFun = async () => {
      try {
        const token = "";
        const response = await getBookingBusiness(token, 1);
        console.log(response.data);
        setBooking([]);
        const compareByDate = (a, b) => {
          const dateB = convertDate(b.date, b.time);
          const dateA = convertDate(a.date, a.time);
          return dateB - dateA;
        };
        Object.values(response.data)
          .sort(compareByDate)
          .map((booking) => {
            if (
              booking.enterprise_payment === "CREDITO" &&
              booking.invoice == 0 &&
              booking.invoice_id == null &&
              booking.payment_type_id == 2
            ) {
              console.log("payment", booking.enterpise_payment);
              booking.clientfullname = `${booking.client_name} ${booking.client_lastname}`;
              booking.driverfullname = `${booking.driver_name} ${booking.driver_lastname}`;
              setBooking((oldData) => [...oldData, booking]);
            }
          });
      } catch (error) {
        console.log(error);
      }
    };
    getBookingBusinessFun();
  }, []);

  const getEmployees = async (enterprise_id) => {
    try {
      console.log(enterprise_id);
      const token = getToken("atknre");
      const response = await getEmployeesFromEnterprise(token, enterprise_id);
      setEmployees(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSubRoles = async (enterprise_id) => {
    try {
      const token = getToken("atknre");
      const response = await getSubroles(token, enterprise_id);
      setRoles([]);
      Object.values(response?.data).map((role, index) => {
        role.index = index + 1;
        setRoles((oldData) => [...oldData, role]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getReports = async () => {
    try {
      setBooking([]);
      if (typeReport === 1) {
        if (!employee) return snackBarDialog("SELECCIONAR TRABAJADOR", "error");
        await searchBookingByEmployee(employee.id);
      } else if (typeReport === 2) {
        if (!role) return snackBarDialog("SELECCIONAR AREA", "error");
        await searchBookingByRole(role.enterprises_id, role.id);
      } else if (typeReport === 3 || typeReport === 4 || typeReport === 5) {
        await searchBookingBySede(typeReport);
      } else if (typeReport === 6) {
        await searchBookingByEnterprise();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchBookingByEmployee = async (employee_id) => {
    try {
      const response = await searchBookingEnterprisePerEmployee(
        employee_id,
        "null",
        "null"
      );
      setBooking([]);
      setHistory([]);
      console.log(response.data);
      const compareByDate = (a, b) => {
        const dateB = convertDate(b.date, b.time);
        const dateA = convertDate(a.date, a.time);
        return dateB - dateA;
      };
      Object.values(response.data)
        .sort(compareByDate)
        .map((book) => {
          if (
            book.enterprise_payment === "CREDITO" &&
            book.invoice == 0 &&
            book.invoice_id == null
          ) {
            book.clientfullname = book.fullname_employee;
            book.driverfullname = book.fullname_driver;
            book.vehicle_plate = book.plate;
            book.payment_tax = book.tax;
            book.payment_toll = book.toll;
            book.payment_price_driver = book.price_driver;
            book.payment_price_total = book.price_total;
            setBooking((oldData) => [...oldData, book]);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const searchBookingByRole = async (enterprise_id, subrole_id) => {
    try {
      const response = await searchBookingEnterprisePerRole(
        enterprise_id,
        subrole_id,
        "null",
        "null"
      );
      setBooking([]);
      setHistory([]);
      const compareByDate = (a, b) => {
        const dateB = convertDate(b.date, b.time);
        const dateA = convertDate(a.date, a.time);
        return dateB - dateA;
      };
      Object.values(response.data)
        .sort(compareByDate)
        .map((book, index) => {
          if (
            book.enterprise_payment === "CREDITO" &&
            book.invoice == 0 &&
            book.invoice_id == null
          ) {
            book.clientfullname = book.fullname_employee;
            book.driverfullname = book.fullname_driver;
            book.vehicle_plate = book.plate;
            book.payment_tax = book.tax;
            book.payment_toll = book.toll;
            book.payment_price_driver = book.price_driver;
            book.payment_price_total = book.price_total;
            setBooking((oldData) => [...oldData, book]);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const searchBookingByEnterprise = async () => {
    try {
      const response = await searchBookingPerEnterprise(
        enterprise.id,
        "null",
        "null"
      );
      console.log(response?.data);
      setBooking([]);
      setHistory([]);
      const compareByDate = (a, b) => {
        const dateB = convertDate(b.date, b.time);
        const dateA = convertDate(a.date, a.time);
        return dateB - dateA;
      };
      Object.values(response.data)
        .sort(compareByDate)
        .map((book) => {
          if (
            book.enterprise_payment === "CREDITO" &&
            book.invoice == 0 &&
            book.invoice_id == null &&
            book.payment_type_id == 2
          ) {
            book.clientfullname = book.fullname_employee;
            book.driverfullname = book.fullname_driver;
            book.vehicle_plate = book.plate;
            book.payment_tax = book.tax;
            book.payment_toll = book.toll;
            book.payment_price_driver = book.price_driver;
            book.payment_price_total = book.price_total;
            setBooking((oldData) => [...oldData, book]);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const searchBookingBySede = async (report) => {
    try {
      const params = {
        sedeId: report === 3 || report === 5 ? sede.id : 0,
        costCenterId: report === 4 || report === 5 ? costCenter.id : 0,
        enterpriseId: enterprise.id,
        dateStart: fromDate,
        dateEnd: toDate,
      };
      setBooking([]);
      const response = await GetFilterHistory(params);
      if (response.message === "success") {
        console.log(response.history);
        setHistory([]);
        response.history.map((book) => {
          if (
            book.enterprise_payment === "CREDITO" &&
            book.invoice == 0 &&
            book.invoice_id == null &&
            book.payment_type_id == 2
          ) {
            setHistory((oldData) => [...oldData, book]);
          }
        });
      } else if (response.message === "ERROR_EMPTY_SEDE_HISTORY") {
        setHistory([]);
        snackBarDialog("SEDES: RESULTADO ES 0", "error");
      } else if (response.message === "ERROR_EMPTY_COST_CENTER_HISTORY") {
        setHistory([]);
        snackBarDialog("CENTRO DE COSTOS: RESULTADO ES 0", "error");
      } else if ("ERROR_EMPTY_COST_CENTER_SEDE_HISTORY") {
        setHistory([]);
        snackBarDialog("SEDE Y CENTRO DE COSTO: RESULTADO ES 0", "error");
      } else {
        snackBarDialog("FALLO EL SERVIDOR", "error");
      }
    } catch (error) {}
  };

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };

  const columns = [
    {
      headerName: "Pedido",
      field: "order_number",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "Fecha",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Hora",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "Nombre",
      field: "clientfullname",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 180,
    },
    {
      headerName: "Empresa",
      field: "enterprise_commertial",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 180,
    },
    {
      headerName: "Origen",
      field: "origin_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "Destino",
      field: "destination_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "Pax",
      field: "persons",
      headerAlign: "left",
      align: "center",
      width: 20,
    },
    {
      headerName: "Notas",
      field: "notes",
      width: 200,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Placa",
      field: "vehicle_plate",
      width: 90,
    },
    {
      headerName: "Tipo",
      field: "vehicle_bodywork",
      width: 90,
    },
    {
      headerName: "Conductor",
      field: "driverfullname",
      width: 200,
    },
    {
      headerName: "Impuesto",
      field: "payment_tax",
      width: 90,
    },
    {
      headerName: "Peaje",
      field: "payment_toll",
      width: 90,
    },
    {
      headerName: "Precio C.",
      field: "payment_price_driver",
      width: 90,
    },
    {
      headerName: "TOTAL",
      field: "payment_price_total",
      width: 90,
    },
    {
      headerName: "Estado",
      field: "state_payment",
      width: 90,
    },
    {
      headerName: "Creado",
      field: "created_by",
      width: 80,
    },
    {
      headerName: "",
      field: "actions",
      width: 60,
      renderCell: ({ row: { id } }) => {
        return (
          <Stack direction="row" spacing={2}>
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => navigate(`/admin/credit-booking/edit/${id}`)}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const historyColumns = [
    { headerName: "PEDIDO", field: "order_number", width: 50 },
    {
      headerName: "FECHA",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "HORA",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "TRABAJADOR",
      field: "fullname_employee",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
      renderCell: ({ row: { name, lastname } }) => {
        return (
          <>
            {name} {lastname}
          </>
        );
      },
    },
    {
      headerName: "ORIGEN",
      field: "origin_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "DESTINO",
      field: "destination_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "CONDUCTOR",
      field: "driver_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
      renderCell: ({ row: { driver_name, driver_lastname } }) => {
        return (
          <>
            {driver_name} {driver_lastname}
          </>
        );
      },
    },
    {
      headerName: "PLATE",
      field: "plate",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "PEAJE",
      field: "toll",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "PRECIO",
      field: "price_total",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
  ];

  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };
  const handleCloseSnk = () => setStateSnk(false);
  const [dataSelected, setDataSelected] = useState([]);

  //
  const [reportData, setReportData] = useState([]);
  const [totalData, setTotalData] = useState(null);
  const [enterpriseData, setEnterpriseData] = useState("");
  const userData = 0;

  useEffect(() => {
    try {
      if (dataSelected.length > 0) {
        setReportData([]);
        let sumTotal = 0;
        let sumToll = 0;
        Object.values(dataSelected).map((book) => {
          sumToll += Number(book.toll);
          sumTotal += Number(book.price_total);
          setReportData((oldData) => [...oldData, book]);
        });
        setTotalData({
          sumTotal,
          sumToll,
          igv: enterprise ? enterprise.igv : "MAS IGV",
        });
      } else {
        setReportData([]);
        setTotalData({
          sumTotal: 0,
          sumToll: 0,
          igv: enterprise ? enterprise.igv : "MAS IGV",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [dataSelected]);

  const [history, setHistory] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [costCenters, setCostCenters] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [costCenter, setCostCenter] = useState({});
  const [sede, setSede] = useState({});

  const getSedesAndCost = async (enterprise_id) => {
    try {
      const listSedes = await ReadEnterpriseSede(enterprise_id);
      const listCost = await ReadCostCenter(enterprise_id);
      if (listSedes.message === "success") {
        setSedes(listSedes.sede);
        setSede(listSedes.sede.length > 0 ? listSedes.sede[0] : {});
      }
      console.log(listCost);
      if (listCost.message === "success") {
        setCostCenters(listCost.costcenter);
        setCostCenter(
          listCost.costcenter.length > 0 ? listCost.costcenter[0] : {}
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="PEDIDOS CREDITO" subtitle="" />
              <Box>
                {enterprise && totalData && reportData && (
                  <ReportHistory
                    userData={reportData}
                    invoice={totalData}
                    enterpriseData={enterprise}
                  />
                )}
              </Box>
            </Box>
            <Box backgroundColor={colors.primary[400]} p={1}>
              <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
                <Grid item xs={12} sm={4} md={4}>
                  <Autocomplete
                    value={enterprise}
                    disablePortal
                    name="employee"
                    id="combo-box-employee"
                    onChange={(e, newValue) => {
                      setEnterprise(newValue);
                    }}
                    options={enterprises}
                    getOptionLabel={(option) =>
                      `${option.commertial_name} - ${option.ruc} - ${option.business_name}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("EMPRESAS")} />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="select-report">BUSCAR</InputLabel>
                    <Select
                      fullWidth
                      labelId="select-report"
                      id="typeReport"
                      value={typeReport}
                      name="typeReport"
                      onChange={(e, newValue) => {
                        if (!enterprise)
                          return snackBarDialog(
                            "SELECCIONE UNA EMPRESA",
                            "error"
                          );
                        setTypeReport(newValue.props.value);
                        if (newValue.props.value === 1) {
                          getEmployees(enterprise.id);
                        } else if (newValue.props.value === 2) {
                          getSubRoles(enterprise.id);
                        } else if (
                          newValue.props.value === 3 ||
                          newValue.props.value === 4
                        ) {
                          getSedesAndCost(enterprise.id);
                        }
                      }}
                      label="BUSCAR"
                    >
                      <MenuItem value={0}>SELECCIONE</MenuItem>
                      <MenuItem value={1}>POR PERSONA</MenuItem>
                      <MenuItem value={2}>POR AREA</MenuItem>
                      <MenuItem value={3}>POR SEDE</MenuItem>
                      <MenuItem value={4}>POR CENTRO DE COSTOS</MenuItem>
                      <MenuItem value={5}>POR SEDE Y CENTRO DE COSTOS</MenuItem>
                      <MenuItem value={6}>TOTAL</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {typeReport === 1 && (
                  <>
                    <Grid item xs={12} sm={2} md={2}>
                      <Autocomplete
                        value={employee}
                        disablePortal
                        name="employee"
                        id="combo-box-employee"
                        onChange={(e, newValue) => {
                          setEmployee(newValue);
                        }}
                        options={employees}
                        getOptionLabel={(option) =>
                          `${option.id}: ${option.name} ${option.lastname} - ${option.dni} - ${option.subrole_name}`
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={t("COLABORADOR")} />
                        )}
                      />
                    </Grid>
                  </>
                )}

                {typeReport === 2 && (
                  <>
                    <Grid item xs={12} sm={2} md={2}>
                      <Autocomplete
                        value={role}
                        disablePortal
                        name="role"
                        id="combo-box-roles"
                        onChange={(e, newValue) => {
                          setRole(newValue);
                        }}
                        options={roles}
                        getOptionLabel={(option) =>
                          `${option.index}: ${option.name}`
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={t("ROLES")} />
                        )}
                      />
                    </Grid>
                  </>
                )}

                {(typeReport == 3 || typeReport == 5) && (
                  <>
                    <Grid item xs={12} sm={6} md={typeReport == 3 ? 4 : 2}>
                      <Autocomplete
                        value={sede}
                        disablePortal
                        name="sede"
                        id="box-sedes"
                        onChange={(e, newValue) => {
                          setSede(newValue);
                        }}
                        options={sedes}
                        getOptionLabel={(option) => `${option.name}`}
                        renderInput={(params) => (
                          <TextField {...params} label={t("SEDES")} />
                        )}
                      />
                    </Grid>
                  </>
                )}

                {(typeReport == 4 || typeReport == 5) && (
                  <>
                    <Grid item xs={12} sm={6} md={typeReport == 4 ? 4 : 2}>
                      <Autocomplete
                        value={costCenter}
                        disablePortal
                        name="costCenter"
                        id="box-costcenter"
                        onChange={(e, newValue) => {
                          setCostCenter(newValue);
                        }}
                        options={costCenters}
                        getOptionLabel={(option) => `${option.name}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("CENTRO DE COSTOS")}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}

                {(typeReport === 3 || typeReport === 4 || typeReport === 5) && (
                  <>
                    <Grid item xs={6} sm={2} md={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack>
                          <MobileDatePicker
                            value={fromDate}
                            label="Desde"
                            inputFormat="DD/MM/YYYY"
                            onChange={(newValue) => {
                              setFromDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack>
                          <MobileDatePicker
                            value={toDate}
                            label="Hasta"
                            inputFormat="DD/MM/YYYY"
                            onChange={(newValue) => {
                              setToDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                  </>
                )}

                <Grid
                  item
                  xs={6}
                  sm={typeReport === 1 || typeReport === 2 ? 2 : 4}
                  md={typeReport === 1 || typeReport === 2 ? 2 : 4}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{ height: 50 }}
                    onClick={(e) => {
                      e.preventDefault();
                      getReports();
                    }}
                  >
                    BUSCAR
                  </Button>
                </Grid>

                {booking.length > 0 && (
                  <Grid item xs={12} sm={12} md={12}>
                    <GridDataTable
                      data={booking}
                      columns={columns}
                      checkboxSelection={true}
                      setDataSelected={setDataSelected}
                    />
                  </Grid>
                )}
                {history.length > 0 && (
                  <Grid item xs={12} sm={12} md={12}>
                    <GridDataTable
                      data={history}
                      columns={historyColumns}
                      checkboxSelection={false}
                      // setDataSelected={setDataSelected}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>

            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleCloseSnk}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </main>
      </div>
    </div>
  );
};

export default CreditBooking;
