import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MenuItem } from "react-pro-sidebar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  MenuList,
  Typography,
  useTheme,
  Badge,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { tokens } from "../../theme";
import "react-pro-sidebar/dist/css/styles.css";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      {/* <Box display={"flex"} justifyContent="space-between">
        <Badge badgeContent={1} color="error" max={99} />
      </Box> */}
      <Link to={to} />
    </MenuItem>
  );
};

const MenuSidebar = ({ paddingLeft }) => {
  const theme = useTheme();
  const [selected, setSelected] = useState("Dashboard");
  const colors = tokens(theme.palette.mode);

  return (
    <Box paddingLeft={paddingLeft}>
      <Item
        title="INICIO"
        to="/admin"
        icon={<HomeOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />

      <Item
        title="CREAR PEDIDO"
        to="/admin/create-booking"
        icon={<ListAltOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />

      <Accordion sx={{ background: colors.primary[400] }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="client-content"
          id="client-header"
        >
          <PeopleOutlinedIcon />
          {paddingLeft && (
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "0px 0 0px 10px" }}
            >
              CLIENTES
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails sx={{ m: -2 }}>
          <MenuList>
            <Item
              title="Clientes"
              to="/admin/list-clients"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Empresas"
              to="/admin/list-enterprise"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </MenuList>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ background: colors.primary[400] }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="driver-content"
          id="driver-header"
        >
          <PeopleOutlinedIcon />
          {paddingLeft && (
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "0px 0 5px 10px" }}
            >
              Conductores
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails sx={{ m: -2 }}>
          <MenuList>
            <Item
              title="Lista Conductores"
              to="/admin/list-driver"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Lista de Vehiculos"
              to="/admin/list-vehicle"
              icon={<DirectionsCarFilledOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Crear Conductor"
              to="/admin/add-driver"
              icon={<DirectionsCarFilledOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Crear Vehiculo"
              to="/admin/add-vehicle"
              icon={<DirectionsCarFilledOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </MenuList>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ background: colors.primary[400] }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="booking-content"
          id="booking-header"
        >
          <ListAltOutlinedIcon />
          {paddingLeft && (
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "0px 0 5px 10px" }}
            >
              Pedidos
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails sx={{ m: -2 }}>
          <MenuList>
            <Item
              title="Pedidos Cliente"
              to="/admin/list-book-now"
              icon={<LibraryBooksOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pedidos Empresa"
              to="/admin/list-booking-business"
              icon={<LibraryBooksOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Cotizacion Cliente"
              to="/admin/list-book-quote"
              icon={<LibraryBooksOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Cotizacion Empresa"
              to="/admin/list-book-business-quote"
              icon={<MenuBookOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Reservas Clientes"
              to="/admin/list-book-later"
              icon={<MenuBookOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Reservas Empresas"
              to="/admin/list-book-business-later"
              icon={<MenuBookOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="CREDITOS"
              to="/admin/history/booking-credit"
              icon={<MenuBookOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </MenuList>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ background: colors.primary[400] }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="booking-content"
          id="booking-header"
        >
          <ListAltOutlinedIcon />
          {paddingLeft && (
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "0px 0 5px 10px" }}
            >
              Historial
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails sx={{ m: -2 }}>
          <MenuList>
            <Item
              title="Pedidos Cliente"
              to="/admin/history/booking-now"
              icon={<LibraryBooksOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pedidos Empresa"
              to="/admin/history/booking-business"
              icon={<LibraryBooksOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Factura"
              to="/admin/history/booking-invoice"
              icon={<LibraryBooksOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </MenuList>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ background: colors.primary[400] }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="booking-content"
          id="booking-rate"
        >
          <ListAltOutlinedIcon />
          {paddingLeft && (
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "0px 0 5px 10px" }}
            >
              TARIFAS
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails sx={{ m: -2 }}>
          <MenuList>
            <Item
              title="Lista de Direcciones"
              to="/admin/address"
              icon={<LibraryBooksOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Rutas Globales"
              to="/admin/rutas-global"
              icon={<LibraryBooksOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </MenuList>
        </AccordionDetails>
      </Accordion>


      <Item
        title="Lista Admin"
        to="/admin/list-admin"
        icon={<AdminPanelSettingsIcon />}
        selected={selected}
        setSelected={setSelected}
      />
    </Box>
  );
};

export default MenuSidebar;
