import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import { Formik } from "formik";

import * as yup from "yup";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import DataTable from "./DataTable";
import { tokens } from "../../../../../theme";
import {
  getToken,
  getTokenRefresh,
} from "../../../../../helpers/token/refreshToken";
import {
  deleteAddress,
  putAddress,
} from "../../../../../api/enterprise/address";
import { modalStyle } from "./AddressStyle";
import { addressSchema } from "./AddressValues";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import { getEmployeesFromEnterprise } from "../../../../../api/users/employee/employeeApi";

const AddressList = ({ address, enterprise_id }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [coordinate, setCoordinate] = useState("");
  //update
  useEffect(() => {
    const getEmployees = async () => {
      try {
        const token = getToken("atknre");
        const response = await getEmployeesFromEnterprise(token, enterprise_id);
        setEmployees(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmployees();
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [radio, setRadio] = useState("global");
  const [employees, setEmployees] = useState(null);
  const [employee, setEmployee] = useState(null);

  const checkoutSchema = yup.object().shape(addressSchema(yup));

  const [updateAddress, setUpdateAddress] = useState([]);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleFormSubmit = async (values) => {
    try {
      setIsLoading(true);
      const token = getTokenRefresh();

      console.log(radio);
      const objAddress = {
        address_id: UID,
        global: false,
        global_enterprise: true,
        url: values.url,
        name: values.name.toUpperCase(),
        latitude: Number(coordinate.split(",")[0]),
        longitude: Number(coordinate.split(",")[1]),
        enterprises_id: enterprise_id,
        employees_id: null,
      };

      if (radio == "globalApp") {
        objAddress.global = true;
        objAddress.global_enterprise = false;
        objAddress.enterprises_id = null;
      }
      if (radio == "globalEmp") {
        objAddress.global = false;
        objAddress.global_enterprise = true;
      }
      if (radio == "employee") {
        objAddress.global = false;
        objAddress.global_enterprise = false;
        objAddress.employees_id = employee.id;
      }

      console.log(objAddress);

      const response = await putAddress(token, objAddress);
      //dispatch(updatedAddress(data));
      snackBarDialog("DIRECCION ACTUALIZADA");
      setIsLoading(false);
      setUpdateOpen(false);
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR DE ACTUALIZACION", "error");
      setIsLoading(false);
      setUpdateOpen(false);
    }
  };

  const columns = [
    { field: "index", headerName: "ID", width: 50 },
    {
      headerName: "URL",
      field: "url",
      align: "left",
      width: 120,
      renderCell: ({ row: { url, latitude, longitude } }) => {
        const urlFound =
          url.length > 10
            ? url
            : `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        return (
          <Stack direction="row" spacing={0}>
            <Button
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                window.open(urlFound);
              }}
              target="_blank"
            >
              VER UBICACION
            </Button>
          </Stack>
        );
      },
    },
    {
      headerName: "REFERENCIA",
      field: "url_address",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
    },
    {
      headerName: "DIRECCION",
      field: "name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 300,
      wrap: true,
    },
    {
      headerName: "NAME",
      field: "fullname",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      headerName: "ACTUALIZADO",
      field: "updated_at",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "ROLE",
      field: "subrole",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 120,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 120,
      align: "center",
      renderCell: ({ row: { id } }) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                setUID(id);
                setUpdateOpen(true);
                address.map((el) => {
                  if (el.id === id) {
                    console.log("");
                    setCoordinate(`${el.latitude},${el.longitude}`);
                    setUpdateAddress(el);
                  }
                });
              }}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(id);
                setOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);
  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [UID, setUID] = useState("");
  const handleClose = () => setOpen(false);
  const handleUpdateClose = () => setUpdateOpen(false);

  const handleAddressDelete = async () => {
    setIsLoading(true);
    try {
      const token = getToken("etknre");
      await deleteAddress(token, UID);
      setUID("");
      snackBarDialog("DIRECCION ELIMINADA");
      setIsLoading(false);
      setOpen(false);
      //setAddress(address.filter((el) => el.id !== UID));
    } catch (error) {
      snackBarDialog("ERROR AL ELIMINAR LA DIRECCION", "error");
      setIsLoading(false);
      setOpen(false);
    }
  };

  return (
    <>
      <main className="content">
        <Box>
          <Box display="grid" gridAutoRows="120px">
            <Box gridColumn="span 12" gridRow="span 2">
              <DataTable data={address} columns={columns} />
            </Box>
          </Box>
        </Box>
        <Modal open={updateOpen} onClose={handleUpdateClose}>
          <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
            <Typography variant="h6" component="h2">
              ACTUALIZAR
            </Typography>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={updateAddress}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box backgroundColor={colors.primary[400]}>
                    <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth variant="outlined">
                          <OutlinedInput
                            value={values.url}
                            id="address-url"
                            name="url"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="des"
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  <LinkOutlinedIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          value={values.name}
                          fullWidth
                          type="text"
                          label={t("address").toUpperCase()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 50,
                            style: { textTransform: "uppercase" },
                          }}
                          name="name"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          value={coordinate}
                          fullWidth
                          type="text"
                          label={"LATITUD y LONGITUD"}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setCoordinate(e.target.value);
                          }}
                          name="coordinate"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} textAlign="center">
                        <FormControl>
                          <FormLabel id="label-placement" color="secondary">
                            DIRECCION
                          </FormLabel>
                          <RadioGroup row name="position" defaultValue="global">
                            <FormControlLabel
                              value="globalApp"
                              control={
                                <Radio
                                  color="success"
                                  onClick={(e) => {
                                    console.log(e.target.value);
                                    setRadio(e.target.value);
                                  }}
                                />
                              }
                              label="GLOBAL APP"
                              labelPlacement="end"
                            />

                            <FormControlLabel
                              value="globalEmp"
                              control={
                                <Radio
                                  color="success"
                                  onClick={(e) => {
                                    console.log(e.target.value);
                                    setRadio(e.target.value);
                                  }}
                                />
                              }
                              label="GLOBAL EMPRESA"
                              labelPlacement="end"
                            />

                            <FormControlLabel
                              value="employee"
                              control={
                                <Radio
                                  color="success"
                                  onClick={(e) => {
                                    console.log(e.target.value);
                                    setRadio(e.target.value);
                                  }}
                                />
                              }
                              label="INDIVIDUAL"
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      {radio === "employee" ? (
                        <Grid item xs={12} sm={12}>
                          <Autocomplete
                            value={employee}
                            disablePortal
                            name="employee"
                            id="combo-box-employee"
                            onChange={(e, newValue) => {
                              setEmployee(newValue);
                            }}
                            options={employees}
                            getOptionLabel={(option) =>
                              `${option.id}: ${option.name} ${option.lastname} - ${option.dni}`
                            }
                            renderInput={(params) => (
                              <TextField {...params} label={t("employee")} />
                            )}
                          />
                        </Grid>
                      ) : (
                        <></>
                      )}

                      <Grid item xs={12} sm={12} textAlign={"center"}>
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          sx={{ height: 45 }}
                          disabled={isLoading}
                        >
                          {t("actualizar")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Modal>
        <Modal open={open} onClose={handleClose}>
          <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
            <Typography variant="h6" component="h2">
              ELIMINAR DIRECCION?
            </Typography>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6} md={6} textAlign="center">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleAddressDelete}
                  disabled={isLoading}
                >
                  ELIMINAR
                </Button>
              </Grid>
              <Grid item xs={6} md={6} textAlign="center">
                <Button
                  color="error"
                  variant="contained"
                  onClick={handleClose}
                  disabled={isLoading}
                >
                  CANCELAR
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <SnackBar
          stateSnk={stateSnk}
          vertical={vertical}
          horizontal={horizontal}
          duration={5000}
          handleClose={handleSnkClose}
          severity={severity}
          messageSnk={messageSnk}
        />
      </main>
    </>
  );
};

export default AddressList;
