export const MODE = "dev";

export const URL_LOGIN = "/api/v1/signin";
export const URL_REGISTER = "/api/v1/register";
export const URL_FORGOT_PASSWORD = "/api/v1/forgot-password";
export const URL_RESET = "/api/v1/reset-password";

export const URL_MODEL = "/api/v1/resource/models";
export const URL_GENRES = "/api/v1/resource/genres";
export const URL_ADDRESS_LATLNG = "/api/v1/resource/url";
export const URL_SUNAT_DNI = "/api/v1/resource/dni";
export const URL_SUNAT_RUC = "/api/v1/resource/ruc";

export const URL_ADMIN = "/api/v1/admin";
export const URL_DRIVER = "/api/v1/driver";
export const URL_CLIENT = "/api/v1/client";
export const URL_EMPLOYEE = "/api/v1/employee";
export const URL_TRAVELLER = "/api/v1/traveller";
export const URL_VEHICLE = "/api/v1/vehicle";
export const URL_ENTEPRISE = "/api/v1/enterprise";

export const URL_COMPANY = "/api/v1/admin/company";

export const URL_COUNT_USERS = "/api/v1/counter";
export const URL_GENERATE_WEEK = "/api/v1/generate-week";
export const URL_GENERATE_MONTHS = "/api/v1/generate-month";
export const URL_GENERATE_YEARS = "/api/v1/generate-year";

export const URL_TRAVEL_DAY = "/api/v1/travel-day";
export const URL_TRAVEL_WEEK = "/api/v1/travel-week";
export const URL_TRAVEL_MONTH = "/api/v1/travel-month";
export const URL_TRAVEL_YEAR = "/api/v1/travel-year";

export const URL_BOOKING_NOW = "/api/v1/booking-now";
export const URL_BOOKING_BUSINESS = "/api/v1/booking-business";

export const URL_ADDRESS = "/api/v1/address";
export const URL_SUB_ROLE = "/api/v1/subrole";

export const URL_GLOBAL_CONFIGURATION = "/api/v1/resource/global-configuration";

export const URL_REPORT_BOOKING = "/api/v1/report";
//invoice
export const URL_INVOICE = "/api/v1/invoice";
