import React, { useState } from "react";
import { Box, Button, CssBaseline, Grid, TextField } from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { VisuallyHiddenInput } from "../components/VisuallyHiddenInput";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { CreatePictureListUseCase } from "../../../domain/usecases/image/CreatePictureListUseCase";

const ImageCreateScreen = () => {
  const [pictureList, setPictureList] = useState([{ file: "", imgUrl: "" }]);
  const [fileName, setFileName] = useState("");
  const [aux, setAux] = useState("");

  const handleAddElement = () => {
    if (pictureList[pictureList.length - 1].file !== "") {
      setPictureList([...pictureList, { file: "", imgUrl: "" }]);
      setAux("");
    } else {
      snackBarDialog("TIENE UN CAMPO DE IMAGEN SIN SELECCIONAR", "error");
    }
  };

  const addPicture = (value, index) => {
    const imageUrl = URL.createObjectURL(value);
    pictureList[index] = {
      file: value,
      imgUrl: imageUrl,
    };
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleCreatePicture = async () => {
    try {
      setIsLoading(true);
      const data = new FormData();
      pictureList.map((picture, idx) => {
        data.append(`images`, picture.file);
      });
      data.append("name", JSON.stringify(fileName));

      const resp = await CreatePictureListUseCase(data);
      if (resp.message === "success") {
        snackBarDialog("IMAGENES GUARDADO CORRECTAMENTE");
        setIsLoading(false);
        setPictureList([{ file: "", imgUrl: "" }]);
      } else {
        snackBarDialog("ERROR NO SE LOGRO GUARDAR LAS IMAGENES", "error");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header
                title={"CREAR GALERIA DE IMAGENES PARA LOS TOUR"}
                subtitle=""
              />
            </Box>
            <Box display="grid" gap="10px">
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12} sm={4}>
                  <TextField
                    value={fileName}
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(e) => setFileName(e.target.value)}
                    label="NOMBRE DE LA GALERIA DE IMAGEN"
                    InputLabelProps={{ style: { color: "white" } }}
                    name="url"
                  />
                </Grid>

                <Grid item xs={12} sm={12} textAlign={"end"}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleAddElement}
                  >
                    + AGREGAR
                  </Button>
                  <Button variant="contained" color="error">
                    - ELIMINAR
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                {pictureList.map((picture, idx) => {
                  return (
                    <Grid key={idx} item xs={12} sm={6} md={4}>
                      <Button
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        fullWidth
                        color="success"
                      >
                        SELECCIONE IMAGEN
                        <VisuallyHiddenInput
                          type="file"
                          name="image"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            setAux(file);
                            addPicture(file, idx);
                          }}
                        />
                      </Button>
                      {picture.file && (
                        <p style={{ fontSize: 15 }}>{picture.file.name}</p>
                      )}
                      <img width={"100%"} height={140} src={picture.imgUrl} />
                    </Grid>
                  );
                })}
              </Grid>

              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12} sm={4} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={handleCreatePicture}
                    disabled={isLoading}
                  >
                    GUARDAR
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </main>
      </div>
      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={4000}
        handleClose={handleSnkClose}
        severity={severity}
        messageSnk={messageSnk}
      />
    </>
  );
};

export default ImageCreateScreen;
