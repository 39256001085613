import { URL_BOOKING_NOW } from "../../helpers/endpoint";
import { baseBackend } from "../base/baseUrl";

export const putBookingNow = async (token, discount, payment_type, order) => {
  return await baseBackend.put(
    URL_BOOKING_NOW,
    JSON.stringify({
      discount,
      payment_type,
      order,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateDataBookingNow = async (token, booking) => {
  return await baseBackend.put(
    `${URL_BOOKING_NOW}/data`,
    JSON.stringify({ booking }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const createBookingNow = async (token, booking) => {
  return await baseBackend.post(URL_BOOKING_NOW, JSON.stringify({ booking }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBookingNow = async (token) => {
  return await baseBackend.get(URL_BOOKING_NOW, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBookingNowByOrderNumber = async (token, order_number) => {
  return await baseBackend.get(
    `${URL_BOOKING_NOW}/order-number/${order_number}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getBookingNowClientById = async (token, id) => {
  return await baseBackend.get(`${URL_BOOKING_NOW}/client/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateStatusBooking = async (token, booking) => {
  return await baseBackend.put(
    `${URL_BOOKING_NOW}/status`,
    JSON.stringify({ booking }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const updateBookingTravelStatus = async (id, travelStatus) => {
  return await baseBackend.put(
    `${URL_BOOKING_NOW}-travel`,
    JSON.stringify({ id, travelStatus }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const updatePaymentBooking = async (token, booking) => {
  return await baseBackend.put(
    `/api/v1/booking-business/payment`,
    JSON.stringify({ booking }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
//BOOKING_NOW
export const updatePaymentBookingNow = async (token, booking) => {
  return await baseBackend.put(
    `/api/v1/booking-now/payment`,
    JSON.stringify({ booking }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
//driver
export const getBookingDriver = async (token, id) => {
  return await baseBackend.get(`/api/v1/booking/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBookingBusinessDriver = async (token, id) => {
  return await baseBackend.get(`/api/v1/booking/business/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getFixedPayments = async (token, id) => {
  return await baseBackend.get(`/api/v1/driver-fixed/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDriverPayments = async (token, id) => {
  return await baseBackend.get(`/api/v1/driver-payments/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getReportTraveller = async (token, id) => {
  return await baseBackend.get(`${URL_BOOKING_NOW}/traveller/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllBookingPending = async (token) => {
  return await baseBackend.get(`${URL_BOOKING_NOW}/pending`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
