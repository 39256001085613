import { getListTour } from "../../../data/repository/question.repo";

export const GetListTourUseCase = async () => {
  try {
    const resp = await getListTour();
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
