import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { UpdateBookingUseCase } from "../../../domain/usecases/booking/UpdateBookingUseCase";
import { GetBookingUseCase } from "../../../domain/usecases/booking/GetBookingUseCase";
import { GetAdvanceListUseCase } from "../../../domain/usecases/advance/GetAdvanceListUseCase";
import SnackBar from "../../../../components/snackbar/SnackBar";
import "dayjs/locale/es";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { ReadDriverListUseCase } from "../../../domain/usecases/driver/ReadDriverListUseCase";
import { CreateAdvanceUseCase } from "../../../domain/usecases/advance/CreateAdvanceUseCase";

const BookingUpdateScreen = () => {
  const { id } = useParams();

  const [reservation, setReservation] = useState(null);
  const [advanceList, setAdvanceList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [driver, setDriver] = useState({
    id: 0,
    name: "SELECT",
    lastname: "",
    vehicleId: 0,
    vehicleBodywork: "",
    vehiclePlate: "",
  });

  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [persons, setPersons] = useState(0);
  const [currency, setCurrency] = useState("");
  const [total, setTotal] = useState(0);
  const [service, setService] = useState(1);
  const [state, setState] = useState(1);
  const [trip, setTrip] = useState(1);
  const [notes, setNotes] = useState("");
  const [notesAdmin, setNotesAdmin] = useState("");
  const [dateIni, setDateIni] = useState("");
  const [timeIni, setTimeIni] = useState("");
  const [horary, setHorary] = useState("");

  const [guide, setGuide] = useState("SI");
  const [serviceEnterprise, setServiceEnterprise] = useState(false);
  const [enableGuide, setEnableGuide] = useState(false);

  //traer a todos los conductore
  useEffect(() => {
    const getReservation = async () => {
      try {
        const [resResp, advResp, driResp] = await Promise.all([
          GetBookingUseCase(id),
          GetAdvanceListUseCase(id),
          ReadDriverListUseCase(),
        ]);

        const data = resResp.reservation;
        setAdvanceList(advResp.data);
        setReservation(resResp.reservation);
        setDriverList(driResp.data);

        // console.log("RESERVATION LIST", resResp);
        setOrigin(data.origin);
        setDestination(data.destination);
        setDate(dayjs(data.date, "DD/MM/YYYY").toDate());
        setTime(
          data.reservation_service_id === 2
            ? formatterTimePicker(data.date, data.time)
            : ""
        );
        setDateIni(data.date);
        setTimeIni(data.time);
        setHorary(data.reservation_service_id == 1 ? data.horary : "");
        setPersons(data.persons);
        setCurrency(data.currency);
        setTotal(data.total);
        setService(data.reservation_service_id);
        setState(data.reservation_state_id);
        setTrip(data.reservation_trip_id);
        setNotes(data.notes);
        setNotesAdmin(data.notes_admin);
        setServiceEnterprise(Boolean(data.service_enterprise));
        setGuide(data.guide);
        setIntermediate(JSON.parse(data.intermediate));

        if (data.driverId != null) {
          const driverSearch = driResp.data.filter(
            (item) =>
              item.id == data.driverId && item.vehicleId == data.vehicleId
          );
          setDriver({
            id: driverSearch[0].id,
            name: driverSearch[0].name,
            lastname: driverSearch[0].lastname,
            vehicleId: driverSearch[0].vehicleId,
            vehicleBodywork: driverSearch[0].vehicleBodywork,
            vehiclePlate: driverSearch[0].vehiclePlate,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getReservation();
  }, []);

  const [createPay, setCreatePay] = useState(false);
  const [advance, setAdvance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const hanldeCreatePay = (e) => {
    const isChecked = e.target.checked;
    setCreatePay(isChecked);
  };

  const dateOpenpayFormat = () => {
    var fecha = new Date();

    var año = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    var dia = ("0" + fecha.getDate()).slice(-2);
    var hora = ("0" + fecha.getHours()).slice(-2);
    var minuto = ("0" + fecha.getMinutes()).slice(-2);
    var segundo = ("0" + fecha.getSeconds()).slice(-2);

    var formatoFecha =
      año + "-" + mes + "-" + dia + "T" + hora + ":" + minuto + ":" + segundo;

    return formatoFecha;
  };

  const createReservationAdvance = async () => {
    try {
      setIsLoading(true);
      if (advance <= 0) {
        snackBarDialog("EL ADELANDO TIENE QUE SER MAYOR A 0");
        setIsLoading(false);
        return;
      }

      const advanceModel = {
        reservationId: reservation.id,
        createdBy: createPay ? "ADMIN" : "",
        transactionId: createPay ? reservation.id : "",
        currency: reservation.currency,
        amount: advance,
        operationDate: createPay ? dateOpenpayFormat() : "",
        status: createPay ? "completed" : "pending",
      };
      //CREAR EL ADVANCE
      const resp = await CreateAdvanceUseCase(advanceModel);
      if (resp.message === "success") snackBarDialog("ADELANTO CREADO");
      else snackBarDialog("ERROR: NO SE LOGRO CREAR EL ADELANDO", "error");

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const updateReservation = async () => {
    try {
      setIsLoading(true);
      const order = {
        id,
        driverId: driver.id === 0 ? null : driver.id,
        vehicleId: driver.vehicleId === 0 ? null : driver.vehicleId,
        origin,
        destination,
        persons,
        reservationState: state,
        reservationService: service,
        reservationTrip: trip,
        date: datePickerToDate(date),
        time: service == 2 ? timePickerToTime(time) : "",
        horary: service == 1 ? horary : "",
        currency,
        total,
        notes,
        notesAdmin,
        intermediate,
        guide,
        serviceEnterprise,
      };
      console.log(order);
      const resp = await UpdateBookingUseCase(order);
      if (resp.message === "success") snackBarDialog("RESERVACION ACTUALIZADA");
      else snackBarDialog("ERROR AL ACTUALIZAR LA RESERVACION", "error");

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [intermediate, setIntermediate] = useState([]);
  const [routeInt, setRouteInt] = useState("");
  const handleAddRoute = () => {
    if (intermediate.length == 0) {
      setIntermediate([
        ...intermediate,
        { index: intermediate.length, route: "" },
      ]);
    } else if (intermediate[intermediate.length - 1].route !== "") {
      setIntermediate([
        ...intermediate,
        { index: intermediate.length, route: "" },
      ]);
      setRouteInt("");
    } else {
      window.alert("INGRESE LA RUTA INTERMEDIA");
    }
  };

  const addRouteIntermediate = (value, index) => {
    intermediate[index] = {
      index: index,
      route: value,
    };
  };

  const formatterTimePicker = (date, time) => {
    const [day, month, year] = date.split("/");
    const [hourMin, amPm] = time.split(" ");
    const [hour, minute] = hourMin.split(":");
    const realHour =
      amPm == "AM" ? hour : hour == 12 ? hour : parseInt(hour) + 12;
    return new Date(year, month - 1, day, realHour, minute);
  };

  const timePickerToTime = (time) => {
    if (!time.$H) return timeIni;
    const { $H, $m } = time;
    const hour =
      $H > 12
        ? $H % 12 < 10
          ? "0" + ($H % 12)
          : $H % 12
        : $H > 9
        ? $H
        : "0" + $H;
    const minute = $m < 10 ? `0${$m}` : $m;
    return `${hour}:${minute} ${$H > 11 ? "PM" : "AM"}`;
  };

  const datePickerToDate = (date) => {
    if (!date.$D) return dateIni;
    const { $D, $M, $y } = date;
    const day = $D > 9 ? $D : `0${$D}`;
    const month = $M + 1 > 9 ? $M + 1 : `0${$M + 1}`;
    return `${day}/${month}/${$y}`;
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="ACTUALIZAR RESERVA" subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              {reservation && (
                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        value={reservation.name}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => {
                          reservation.title = e.target.value;
                        }}
                        label={"CLIENTE"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="name"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        value={reservation.email}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => {
                          reservation.title = e.target.value;
                        }}
                        label={"EMAIL"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="email"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        value={reservation.phone}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => {
                          reservation.title = e.target.value;
                        }}
                        label={"TELEFONO"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="phone"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        value={`${reservation.code} - ${reservation.title}`}
                        fullWidth
                        variant="outlined"
                        type="text"
                        label={"TOUR"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="title"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={6} sm={2} md={2}>
                      <FormControl fullWidth>
                        <InputLabel style={{ color: "white" }}>
                          EMPRESA
                        </InputLabel>
                        <Select
                          value={serviceEnterprise}
                          onChange={(e) => setServiceEnterprise(e.target.value)}
                          label="EMPRESA"
                          name="serviceEnterprise"
                        >
                          <MenuItem value={false}>NO</MenuItem>
                          <MenuItem value={true}>SI</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2}>
                      <FormControl fullWidth>
                        <InputLabel style={{ color: "white" }}>GUIA</InputLabel>
                        <Select
                          value={guide}
                          onChange={(e) => setGuide(e.target.value)}
                          label="GUIA"
                          name="guide"
                          disabled={enableGuide}
                        >
                          <MenuItem value={"SI"}>SI</MenuItem>
                          <MenuItem value={"NO"}>NO</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={6} md={2}>
                      <FormControl fullWidth>
                        <InputLabel>{"SERVICIO"}</InputLabel>
                        <Select
                          value={service}
                          id="vehicle"
                          name="vehicle"
                          label={"SERVICIO"}
                          onChange={(e) => {
                            setService(e.target.value);
                          }}
                        >
                          <MenuItem value={1}>COMPARTIDO</MenuItem>
                          <MenuItem value={2}>PRIVADO</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <TextField
                        value={persons}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setPersons(e.target.value)}
                        label={"PERSONAS"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="persons"
                      />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"es"}
                      >
                        <MobileDatePicker
                          value={date}
                          label="FECHA"
                          inputFormat="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>

                    {(service == 0 || service == 1) && (
                      <Grid item xs={6} sm={3}>
                        <TextField
                          value={horary}
                          fullWidth
                          variant="outlined"
                          type="text"
                          onChange={(e) => setHorary(e.target.value)}
                          label={"HORARIO"}
                          InputLabelProps={{ style: { color: "white" } }}
                          name="horary"
                        />
                      </Grid>
                    )}

                    {service == 2 && (
                      <Grid item xs={6} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            label="HORA"
                            value={time}
                            name="time"
                            onChange={(newValue) => {
                              setTime(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField fullWidth {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6} md={2}>
                      <FormControl fullWidth>
                        <InputLabel>{"MONEDA"}</InputLabel>
                        <Select
                          value={currency}
                          id="currency"
                          name="currency"
                          label={"MONEDA"}
                          onChange={(e) => {
                            setCurrency(e.target.value);
                          }}
                          // error={!!touched.vehicle && !!errors.vehicle}
                        >
                          <MenuItem value={"PEN"}>PEN</MenuItem>
                          <MenuItem value={"USD"}>USD</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        value={total}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setTotal(e.target.value)}
                        label={"TOTAL"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="total"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="success"
                            onClick={handleAddRoute}
                          >
                            Agregar Intermedios
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            value={origin}
                            fullWidth
                            variant="outlined"
                            type="text"
                            onChange={(e) => setOrigin(e.target.value)}
                            label={"RECOJER"}
                            InputLabelProps={{ style: { color: "white" } }}
                            name="origin"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </Grid>

                        {intermediate.map((elem, idx) => {
                          return (
                            <Grid item xs={12} sm={6} key={idx}>
                              <TextField
                                value={elem.route ? elem.route : routeInt}
                                fullWidth
                                variant="outlined"
                                type="text"
                                onChange={(e) => {
                                  setRouteInt(e.target.value);
                                  addRouteIntermediate(e.target.value, idx);
                                }}
                                label={`${idx + 1} INTERMEDIO`}
                                InputLabelProps={{ style: { color: "white" } }}
                                name="route"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                              />
                            </Grid>
                          );
                        })}

                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            value={destination}
                            fullWidth
                            variant="outlined"
                            type="text"
                            onChange={(e) => setDestination(e.target.value)}
                            label={"DESTINO"}
                            InputLabelProps={{
                              style: {
                                color: "white",
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "white",
                                },
                              },
                            }}
                            name="destination"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <InputLabel>{"ESTADO"}</InputLabel>
                        <Select
                          value={state}
                          id="vehicle"
                          name="vehicle"
                          label={"ESTADO"}
                          onChange={(e) => {
                            setState(e.target.value);
                          }}
                          // error={!!touched.vehicle && !!errors.vehicle}
                        >
                          <MenuItem value={1}>COTIZACION</MenuItem>
                          <MenuItem value={2}>ACEPTADO</MenuItem>
                          <MenuItem value={3}>CANCELADO</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <Autocomplete
                        value={driver}
                        disablePortal
                        name="driver"
                        id="combo-box-driver"
                        onChange={(e, newValue) => {
                          setDriver(newValue);
                        }}
                        options={driverList}
                        getOptionLabel={(opt) =>
                          `${opt.name} ${opt.lastname} - VEHICULO: ${opt.vehicleBodywork}, ${opt.vehiclePlate}`
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={"Conductor"} />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        value={notes}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setNotes(e.target.value)}
                        label={"NOTAS CLIENTE"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="notes"
                        inputProps={{
                          style: { textTransform: "uppercase" },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        value={notesAdmin}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setNotesAdmin(e.target.value)}
                        label={"NOTAS ADMIN"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="notesAdmin"
                        inputProps={{
                          style: { textTransform: "uppercase" },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <p style={{ fontWeight: "bold" }}>ADELANTOS:</p>
                      {advanceList.map((item, idx) => {
                        return (
                          <p key={idx}>
                            {item.currency} {item.amount},{" "}
                            <span style={{ fontWeight: "bold" }}>ESTADO:</span>{" "}
                            {item.status} {item.created_by},{" "}
                            <span style={{ fontWeight: "bold" }}>FECHA:</span>{" "}
                            {item.operation_date}
                          </p>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Box border={2} borderColor="green" padding={2} borderRadius={3}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={3}>
                    <TextField
                      value={advance}
                      name="advance"
                      fullWidth
                      variant="outlined"
                      color="success"
                      type="number"
                      label="ADELANTO"
                      onChange={(e) => setAdvance(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <Checkbox
                            style={{ marginRight: -15 }}
                            checked={createPay}
                            onChange={hanldeCreatePay}
                            color="success"
                          />
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="success"
                      sx={{ height: 50, color: "black", fontWeight: "bold" }}
                      onClick={createReservationAdvance}
                      disabled={isLoading}
                    >
                      {createPay ? "GUARDAR ADELANDO" : "CREAR ADELANTO"}
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <InputLabel>{"ESTADO DE VIAJE"}</InputLabel>
                      <Select
                        value={trip}
                        id="trip"
                        name="trip"
                        label={"ESTADO DE VIAJE"}
                        onChange={(e) => setTrip(e.target.value)}
                      >
                        <MenuItem value={1}>ESPERA</MenuItem>
                        <MenuItem value={2}>RUTA</MenuItem>
                        <MenuItem value={3}>FINALIZADO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Button
                      variant="contained"
                      color="success"
                      fullWidth
                      onClick={updateReservation}
                      sx={{ height: 50, color: "black", fontWeight: "bold" }}
                      disabled={isLoading}
                    >
                      ACTUALIZAR RESERVA
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <SnackBar
                stateSnk={stateSnk}
                vertical={vertical}
                horizontal={horizontal}
                duration={4000}
                handleClose={handleSnkClose}
                severity={severity}
                messageSnk={messageSnk}
              />
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default BookingUpdateScreen;
