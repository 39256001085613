import { createVehicle } from "../../../../../api/users/driver/vehicle";

export const CreateVehicleUseCase = async (
  token,
  vehicle,
  technical_review,
  soat,
  setare
) => {
  try {
    const resp = await createVehicle(
      token,
      vehicle,
      technical_review,
      soat,
      setare
    );
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
