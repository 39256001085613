import axios from "axios";

const URL_DEV = "http://localhost:4000";
const URL_PRO = "https://backend.taximundo.com";

export const baseBackend = axios.create({
  baseURL: URL_PRO,
});

export const baseFcm = axios.create({
  baseURL: "https://fcm.googleapis.com/",
});

export const baseNotification = axios.create({
  baseURL: "https://alert.usa.pe/",
});
