import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  Button,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";

import Topbar from "../../../../../components/topbar/TopBar";
import Header from "../../../../../components/header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { onValue, ref, update } from "firebase/database";
import { db } from "../../../../../firebase";
import { tokens } from "../../../../../theme";
import SnackBar from "../../../../../components/snackbar/SnackBar";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../components/sidebar/Sidebar";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { formatterTimePicker } from "../../../../../helpers/date/formatterTimePicker";
import { dateFormat, timeFormat } from "../../../../../helpers/date/date";
import { UpdateDataBackup } from "../../backup/usecases/UpdateDataBackup";
import { getEnterpriseAddress } from "../../../../../api/enterprise/address";

const AddBookingQuoteBusiness = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [booking, setBooking] = useState(null);
  const { t } = useTranslation();

  const [total, setTotal] = useState([]);
  const [currency, setCurrency] = useState(1);
  const [note, setNote] = useState("");
  const [dateformat, setDateFormat] = useState("");
  const [timeFormatter, setTimeFormat] = useState("");

  const [acc, setAcc] = useState("");
  const [persons, setPersons] = useState(1);
  const [typeCar, setTypeCar] = useState(1);

  useEffect(() => {
    const starCountRef = ref(db, `BookingBusiness/${uuid}`);
    onValue(starCountRef, (snapshot) => {
      let data = snapshot.val();
      if (data === null) return console.log("No Booking");

      setPersons(data.persons);
      setTypeCar(data.vehicle);
      setNote(data.note);
      setCurrency(data.currency);
      setTotal(data.total);
      setBooking(data);
      setDateFormat(dayjs(data.date, "DD/MM/YYYY").toDate());
      setTimeFormat(formatterTimePicker(data.date, data.time));
      setOriAddr({ ...data.origin, id: data.uuid, url_address: "" });
      setDestAddr({ ...data.destination, id: data.uuid + 1, url_address: "" });
      setOriRef({ ...data.origin, id: data.uuid, url_address: "" });
      setDestRef({ ...data.destination, id: data.uuid + 1, url_address: "" });
    });
  }, []);

  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleCloseSnk = () => setStateSnk(false);

  const handleClickSave = async (value) => {
    try {
      value.persons = persons;
      value.vehicle = typeCar;
      value.total = total;
      value.cotizar = false;
      value.currency = currency;
      value.statusPayment = "ASIGNADO";
      value.note = note;
      value.date = value.date.$d ? dateFormat(value.date.$d) : value.date;
      value.time = value.time.$d ? timeFormat(value.time.$d) : value.time;

      if (oriAddr.name != oriRef.name) {
        value.origin = {
          lat: oriAddr.latitude,
          lng: oriAddr.longitude,
          name: oriAddr.name,
          url_address: "",
        };
      }
      if (destAddr.name != destRef.name) {
        value.destination = {
          lat: destAddr.latitude,
          lng: destAddr.longitude,
          name: destAddr.name,
          url_address: "",
        };
      }

      await update(ref(db, `BookingBusiness/${value.uuid}`), value);

      const backup = {
        id: value.counterBooking,
        date: value.date.$d ? dateFormat(value.date.$d) : value.date,
        time: value.time.$d ? timeFormat(value.time.$d) : value.time,
        currency: currency,
        advance: value.advance ? Number(value.advance) : 0,
        discount: value.discount ? Number(value.discount) : 0,
        total: Number(total),
        persons: Number(persons),
        note: note,
        noteAdmin: value.noteAdmin ? value.noteAdmin : "",
      };

      await UpdateDataBackup(backup);

      snackBarDialog("COTIZACION ACTUALIZADA");

      setTimeout(() => {
        navigate(`/admin/list-book-business-quote`);
      }, 5000);
    } catch (error) {
      snackBarDialog("ERROR: NO SE ACTUALIZO COTIZACION", "error");
      console.log(error);
    }
  };

  const [oriAddr, setOriAddr] = useState(null);
  const [destAddr, setDestAddr] = useState(null);
  const [oriRef, setOriRef] = useState(null);
  const [destRef, setDestRef] = useState(null);

  const [addressApi, setAddressApi] = useState([]);

  useEffect(() => {
    if (booking) {
      getAddressEnterprise(booking.enterpriseId);
    }
  }, [booking]);

  const getAddressEnterprise = async (enterpriseId) => {
    try {
      const token = "";
      setAddressApi([]);
      const response = await getEnterpriseAddress(token, enterpriseId);
      console.log(response);

      setAddressApi(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={"COTIZACION EMPRESA"} subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 1">
                {booking && (
                  <Box
                    backgroundColor={colors.primary[400]}
                    p={2}
                    m={1}
                    borderRadius={3}
                  >
                    <Grid container spacing={1}>
                      {/* agregar */}
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          value={oriAddr}
                          disablePortal
                          name="addressOrigin"
                          id="combo-box-origin"
                          onChange={(e, newValue) => {
                            setOriAddr(newValue);
                          }}
                          options={addressApi}
                          getOptionLabel={(option) =>
                            `${option.name} ${option.url_address}`
                          }
                          renderInput={(params) => (
                            <TextField {...params} label={t("Origen")} />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          value={destAddr}
                          disablePortal
                          name="client"
                          id="combo-box-destination"
                          onChange={(e, newValue) => {
                            setDestAddr(newValue);
                          }}
                          options={addressApi}
                          getOptionLabel={(option) =>
                            `${option.name} ${option.url_address}`
                          }
                          renderInput={(params) => (
                            <TextField {...params} label={t("Destino")} />
                          )}
                        />
                      </Grid>

                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          value={booking.origin.name}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="text"
                          label={t("origin")}
                          name="client"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={booking.destination.name}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="text"
                          label={t("destination")}
                          name="destination"
                        />
                      </Grid> */}

                      <Grid item xs={12} md={6}>
                        <TextField
                          value={booking.clientName}
                          fullWidth
                          disabled
                          variant="outlined"
                          color="success"
                          type="text"
                          label={t("client")}
                          name="client"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={6} sm={3} md={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Stack spacing={2}>
                                <MobileDatePicker
                                  label={t("date")}
                                  inputFormat="DD/MM/YYYY"
                                  name="date"
                                  value={dateformat}
                                  onChange={(newValue) => {
                                    setDateFormat(newValue);
                                    booking.date = newValue;
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={6} sm={3} md={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Stack spacing={3}>
                                <MobileTimePicker
                                  label={t("time")}
                                  value={timeFormatter}
                                  name="time"
                                  onChange={(newValue) => {
                                    setTimeFormat(newValue);
                                    booking.time = newValue;
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={6} sm={3} md={3}>
                            <TextField
                              value={persons}
                              fullWidth
                              variant="outlined"
                              color="success"
                              type="number"
                              label={t("persons")}
                              name="persons"
                              onChange={(e) => {
                                setPersons(e.target.value);
                                //booking.persons = e.target.value;
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3} md={3}>
                            <FormControl fullWidth>
                              <InputLabel>{t("Model")}</InputLabel>
                              <Select
                                value={typeCar}
                                id="vehicle"
                                name="typeCar"
                                label={t("vehicle")}
                                onChange={(e) => {
                                  setTypeCar(e.target.value);
                                }}
                              >
                                <MenuItem value={1}>{t("SEDAN")}</MenuItem>
                                <MenuItem value={2}>{t("SUV")}</MenuItem>
                                <MenuItem value={3}>{t("MINIVAN")}</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={note}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="text"
                          label={t("notes")}
                          name="note"
                          onChange={(e) => setNote(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel>{t("currency")}</InputLabel>
                          <Select
                            value={currency}
                            id="currency"
                            name="currency"
                            label={t("currency")}
                            onChange={(e) => setCurrency(e.target.value)}
                          >
                            <MenuItem value={1}>{t("PEN")}</MenuItem>
                            <MenuItem value={2}>{t("USD")}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          value={total}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="number"
                          label={t("total")}
                          onChange={(e) => setTotal(e.target.value)}
                          name="total"
                          inputProps={{ style: { fontSize: 14 } }}
                        />
                      </Grid>

                      <Grid item xs={6} sm={12} textAlign={"end"}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={(e) => {
                            handleClickSave(booking);
                          }}
                        >
                          {t("saveChanges")}
                        </Button>
                      </Grid>
                    </Grid>
                    <SnackBar
                      stateSnk={stateSnk}
                      vertical={vertical}
                      horizontal={horizontal}
                      duration={4000}
                      handleClose={handleCloseSnk}
                      severity={severity}
                      messageSnk={messageSnk}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};

export default AddBookingQuoteBusiness;
