import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import TableHeader from "./Table/TableHeader";
import TableRow from "./Table/TableRow";

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    //borderWidth: 1,
    // borderColor: "#bff0fd",
  },
});

const TableCustomers = ({ invoice }) => (
  <View style={styles.tableContainer}>
    <TableHeader />
    <TableRow items={invoice.items} />
  </View>
);

export default TableCustomers;
