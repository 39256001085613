import { Box, Typography, useTheme, Grid } from "@mui/material";
import { tokens } from "../theme";

const StatBoxSimple = ({ title, icon, subtitle, quantity, frequent }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width={"100%"} height={140}>
      <Grid
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-around"}
      >
        <Grid item xs={6} sm={6} p={"2px"}>
          <Typography
            fontWeight={"bold"}
            variant="h3"
            sx={{ color: colors.greenAccent[500] }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} textAlign={"end"} width={"100%"} p={"2px"}>
          {icon}
        </Grid>

        <Grid item xs={12} sm={12} textAlign={"center"}>
          <Typography variant="h3" sx={{ color: colors.greenAccent[500] }}>
            {subtitle}: {quantity}
          </Typography>
        </Grid>
        {title === "CLIENTES" && (
          <Grid item xs={12} sm={12} textAlign={"center"}>
            <Typography variant="h3" sx={{ color: colors.greenAccent[500] }}>
              Frecuentes: {frequent}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default StatBoxSimple;
