import { baseBackend } from "../../../api/base/baseUrl";

const URL_ADVANCE = "/api/v1/module/advance";

export const getListAdvance = async (id) => {
  return await baseBackend.get(`${URL_ADVANCE}/read/${id}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const createReservationAdvance = async (advance) => {
  return await baseBackend.post(
    `${URL_ADVANCE}/create`,
    JSON.stringify({ advance }),
    { headers: { "Content-Type": "application/json" } }
  );
};
