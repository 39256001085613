import axios from "axios";
import { URL_SUNAT_DNI, URL_SUNAT_RUC } from "../../../helpers/endpoint";
import { baseBackend } from "../../base/baseUrl";
//https://api.apis.net.pe/v1/dni?numero=46027897
//https://api.apis.net.pe/v1/ruc?numero=10460278975
const baseSunat = axios.create({
  baseURL: "https://api.apis.net.pe",
});

const token = "apis-token-1.aTSI1U7KEuT-6bbbCguH-4Y8TI6KS73N";

export const getSunatDni = async (dni) => {
  try {
    const response = await baseBackend.post(
      URL_SUNAT_DNI,
      JSON.stringify({
        dni,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

//https://api.apis.net.pe/v1/dni?numero=46027897
//https://api.apis.net.pe/v1/ruc?numero=10460278975
export const getSunatRuc = async (ruc) => {
  return await baseBackend.post(
    URL_SUNAT_RUC,
    JSON.stringify({
      ruc,
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
