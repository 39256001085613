import { getListReservation } from "../../../data/repository/booking.repo";

export const GetBookingListUseCase = async () => {
  try {
    const resp = await getListReservation();
    return resp.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
