import { Box, CssBaseline, Stack, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../../../theme";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { TableStyle } from "../components/TableStyle";
import { ReadSubscribedUseCase } from "../../../domain/usecases/schedule/ReadSubscribedUseCase";

const ScheduledEndScreen = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [scheduled, setScheduled] = useState([]);
  useEffect(() => {
    const scheduledList = async () => {
      const resp = await ReadSubscribedUseCase();
      console.log(resp);
      if (resp.message === "success") {
        setScheduled(resp.data);
      }
    };
    scheduledList();
  }, []);

  const columns = [
    {
      headerName: "ID",
      field: "id",
      type: "number",
      headerAlign: "left",
      align: "left",
      width: 60,
      renderCell: ({ row: { id } }) => {
        return (
          <div>
            <p>{id}</p>
          </div>
        );
      },
    },
    {
      headerName: "TITULO",
      field: "tour_title",
      type: "number",
      headerAlign: "left",
      align: "left",
      width: 180,
      renderCell: ({ row: { tour_code, tour_title } }) => {
        return (
          <div>
            <p>{tour_code.toUpperCase()}</p>
            <p>{tour_title.toUpperCase()}</p>
          </div>
        );
      },
    },
    {
      headerName: "FECHA",
      headerAlign: "left",
      field: "date",
      width: 100,
      renderCell: ({ row: { date, time } }) => {
        return (
          <div>
            <p>{date}</p>
            <p>{time}</p>
          </div>
        );
      },
    },
    {
      headerName: "CLIENTE",
      field: "rider_name",
      width: 150,
      renderCell: ({ row: { rider_name, rider_lastname, rider_phone } }) => {
        return (
          <div>
            <p>
              {rider_name} {rider_lastname}
            </p>
            <p>{rider_phone}</p>
          </div>
        );
      },
    },
    {
      headerName: "Personas",
      field: "persons",
      width: 80,
    },
    {
      headerName: "Precio",
      field: "price",
      width: 80,
    },
    {
      headerName: "Nota",
      field: "note",
      width: 200,
    },
    {
      headerName: "OPCIONES",
      field: "actions",
      headerAlign: "center",
      width: 180,
      align: "center",
      renderCell: ({ row: { id } }) => {
        return <Stack direction="row" spacing={1}></Stack>;
      },
    },
  ];

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box display="flex" justifyContent="space-between">
              <Header title={"RESERVAS PROGRAMADAS: CONFIRMADAS"} subtitle="" />
            </Box>
            <Box gap="10px">
              <Box>
                <Box m="10px 0 0 0" height="100vh" sx={TableStyle(colors)}>
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={scheduled}
                    columns={columns}
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    checkboxSelection
                    // onRowSelectionModelChange={(ids) => {
                    //   var list = [];
                    //   ids.map((id) => {
                    //     const select = data.find((row) => row.id === id);
                    //     list.push(select);
                    //   });
                    //   // dataSelected(list);
                    // }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default ScheduledEndScreen;
