import { createSede } from "../respository/sede.repository";

export const CreateSede = async (sede) => {
  try {
    const response = await createSede(sede);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
