import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 0,
  },
  rowContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  ruc: {
    width: "50%",
    fontFamily: "Helvetica-Bold",
  },
  rider: {
    fontFamily: "Helvetica-Bold",
  },
  subText: {
    fontFamily: "Helvetica",
  },
});

const TableContact = ({ name }) => (
  <View style={styles.headerContainer}>
    <View style={styles.rowContainer}>
      <Text style={styles.ruc}>
        Empresa: <Text style={styles.subText}>{name}</Text>
      </Text>
      {/* <Text style={styles.rider}>
        RUC: <Text style={styles.subText}>{ruc}</Text>
      </Text> */}
    </View>
  </View>
);

export default TableContact;
