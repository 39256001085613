import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#424949";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 20,
    fontStyle: "bold",
  },
  index: {
    width: "81%",
    textAlign: "center",
    fontSize: 8,
  },
  title: {
    width: "10%",
    height: 20,
    borderRightColor: borderColor,
    borderRightWidth: 0,
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    fontSize: 8,
    padding: 2,
  },
  price: {
    width: "9%",
    height: 20,
    textAlign: "right",
    fontSize: 8,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    padding: 2,
  },
});

const TableTotal = ({ invoice }) => {
  const currency = {
    1: "PEN",
    2: "USD",
  };
  return (
    <>
      {/* <View style={styles.row}>
      <Text style={styles.index}>{}</Text>
      <Text style={styles.title}>{"Peajes"}</Text>
      <Text style={styles.price}>{invoice.peaje}</Text>
    </View> */}
      <View style={styles.row}>
        <Text style={styles.index}>{}</Text>
        <Text style={styles.title}>
          {"SubTotal: "}
          {currency[invoice?.items[0]?.payment_currency]}
        </Text>
        <Text style={styles.price}>{invoice.subTotal.toFixed(2)}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.index}>{}</Text>
        <Text style={styles.title}>
          {"IGV: "}
          {currency[invoice?.items[0]?.payment_currency]}
        </Text>
        <Text style={styles.price}>{invoice.igv.toFixed(2)}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.index}>{}</Text>
        <Text style={styles.title}>
          {"Total: "}
          {currency[invoice?.items[0]?.payment_currency]}
        </Text>
        <Text style={styles.price}>{invoice.total.toFixed(2)}</Text>
      </View>
    </>
  );
};

export default TableTotal;
