export const rateSchema = (yup) => {
  return {
    origin: yup.string().required("Requerido"),
    destination: yup.string().required("Requerido"),
    price: yup.number().required("Requerido"),
    notes: yup.string(),
  };
};

export const rateInitial = {
  origin: "",
  destination: "",
  notes: "",
  price: 0.0,
};
