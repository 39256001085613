import { updateCountry } from "../../../data/repository/country.repo";

export const UpdateCountryUseCase = async (country) => {
  try {
    const resp = await updateCountry(country);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
