import { Navigate, Outlet } from "react-router-dom";
import {
  deleteTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../helpers/token/refreshToken";

export const ProtectedRoute = ({ children, redirectTo = "/admin" }) => {
  const token = verifyExpireTokenRefresh();
  if (token) {
    deleteTokenRefresh(token);
    return children ? children : <Outlet />;
  }

  return <Navigate to={redirectTo} />;
};

export const ProtectedDashboardRoute = ({ children, redirectTo = "/" }) => {
  const token = verifyExpireTokenRefresh();
  if (token) {
    deleteTokenRefresh(token);
    return <Navigate to={redirectTo} />;
  }

  return children ? children : <Outlet />;
};
