import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { db } from "../../../../../../firebase";
import { set, ref, get } from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import {
  Autocomplete,
  Box,
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  useTheme,
  Stack,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "../../style/booking.css";
import SnackBar from "../../../../../../components/snackbar/SnackBar";
import { tokens } from "../../../../../../theme";
import { getEmployeesFromEnterprise } from "../../../../../../api/users/employee/employeeApi";
import { getListEnterprises } from "../../../../../../api/enterprise/enterprise";
import { getEnterpriseAddress } from "../../../../../../api/enterprise/address";
import { dateFormat, timeFormat } from "../../../../../../helpers/date/date";

import { sendICSEmail } from "../../../../../../api/resources/ics/ics";
import { getListTourByCountry } from "../../../tour/data/repository/tourRepo";
import { getListCountryUseCase } from "../../../tour/usecases/country/GetListCountryUseCase";
import { sendNotificationTokens } from "../../../../../../api/fcm/notification";
import { getRatesAndRoutesAddress } from "../../../../../../api/users/enterprise/enterpriseApi";
import { CreateBackupUseCase } from "../../../backup/usecases/CreateBackupOrder";

const BookingBusiness = ({ model }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [employee, setEmployee] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [enterprise, setEnterprise] = useState(null);
  const [enterprises, setEnterprises] = useState([]);

  const [addressOrigin, setAddressOrigin] = useState(null);
  const [addressDestination, setAddressDestination] = useState(null);
  const [addressApi, setAddressApi] = useState([]);
  const [vehicleSelect, setVehicleSelect] = useState(1);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const [dateEnd, setDateEnd] = useState(null);
  const [timeEnd, setTimeEnd] = useState(null);

  const [checked, setChecked] = useState(false);
  const handleChangeCheckBox = (event) => setChecked(event.target.checked);

  const [phoneActive, setPhoneActive] = useState(true);
  const changePhoneActive = (e) => setPhoneActive(e.target.checked);

  const [serviceList, setServiceList] = useState([]);
  const [route1, setRoute1] = useState("");
  const [indice, setIndice] = useState(-1);

  const checkoutSchema = yup.object().shape({
    persons: yup.number().required("required"),
    booking: yup.number().required("required"),
    vehicle: yup.number().required("required"),
    note: yup.string(),
  });
  //razon social business_name
  const initialValues = {
    name: "",
    ruc: "",
    persons: 1,
    vehicle: "",
    booking: 1,
    note: "",
    price: 0,
    tax: 0,
  };

  useEffect(() => {
    const getEnterprises = async () => {
      try {
        const token = "";
        const response = await getListEnterprises(token, 1);
        setEnterprises(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEnterprises();
  }, []);

  useEffect(() => {
    const getAddressEnterprise = async () => {
      try {
        if (enterprise) {
          const token = "";
          setAddressApi([]);
          setAddressOrigin(null);
          setAddressDestination(null);
          const response = await getEnterpriseAddress(token, enterprise.id);
          console.log(response);
          setAddressApi(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAddressEnterprise();
  }, [enterprise]);

  //address/enterprise/:enterprise_id
  const handleServiceAdd = () => {
    if (indice === -1) {
      setServiceList([...serviceList, { service: "" }]);
      setIndice(indice + 1);
    } else if (serviceList[indice].service !== "") {
      setServiceList([...serviceList, { service: "" }]);
      setIndice(indice + 1);
      setRoute1("");
    } else {
      window.alert("INGRESE UNA RUTA ANTES DE CREAR OTRO CAMPO");
    }
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
    setIndice(indice - 1);
    console.log(indice);
    console.log(serviceList);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const getEmployees = async (id) => {
    try {
      const token = "asdfasd";
      setEmployees(null);
      setEmployee(null);
      const response = await getEmployeesFromEnterprise(token, id);
      setEmployees(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [routesEnterprise, setRoutesEnterprise] = useState([]);
  const getRatesAndRoutes = async (id) => {
    try {
      const resp = await getRatesAndRoutesAddress(id);
      console.log("routesEnterprise", resp);
      setRoutesEnterprise(resp.data.routes);
    } catch (error) {
      console.log(error);
    }
  };

  const createBookingFirebase = async (data) => {
    const dbRef = ref(db, `BookingBusiness/${data.uuid}`);
    await set(dbRef, data);
    snackBarDialog("Pedido Creado. Enviando Email...");
    setServiceList([]);

    if (data.booking_type == 1) {
      await notificationToken();
    }
  };

  const sendEmailBooking = async (values, counter) => {
    try {
      const newBooking = {
        counterBooking: counter,
        clientName: `${employee.name} ${employee.lastname}`,
        clientEmail: employee.email,
        clientPhone: employee.phone,
        origin: { name: addressOrigin.name },
        destination: { name: addressDestination.name },
        date: dateFormat(date.$d),
        time: timeFormat(time.$d),
        note: values.note ? values.note : "",
        persons: values.persons,
        vehicle: values.vehicle,
        web: "Business",
      };
      await sendICSEmail("token", newBooking);
    } catch (error) {
      console.log(error);
    }
  };

  const notificationToken = async () => {
    try {
      const dbRefToken = ref(db, `Token/Driver`);
      get(dbRefToken)
        .then(async (snapshot) => {
          const data = snapshot.val();
          if (data != null) {
            let tokens = await listToken(data);
            if (tokens.length != 0) {
              const title = "PEDIDO PARA AHORA EMPRESAS";
              const body = "Ingrese para aceptar";
              await sendNotificationTokens(tokens, title, body);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const listToken = async (data) => {
    let tokens = [];
    Object.values(data).map(async (_token) => {
      if (_token.isBooking) {
        tokens.push(_token.token);
      }
    });

    return tokens;
  };

  const handleFormSubmit = async (values) => {
    if (!enterprise) return snackBarDialog("SELECCIONE LA EMPRESA", "error");
    if (!employee) return snackBarDialog("SELECCIONE AL TRABAJADOR", "error");
    if (!addressOrigin.latitude || !addressOrigin.longitude)
      return snackBarDialog("SELECCIONE EL ORIGEN DEL SERVICIO", "error");
    if (!addressDestination.latitude || !addressDestination.longitude)
      return snackBarDialog("SELECCIONE EL DESTINO DEL SERVICIO", "error");

    setIsLoading(true);
    const uuid = uuidv4();
    const nDate = new Date(Date.now());

    //crear backup
    setIsLoading(true);
    const data = createDataMySql(values, uuid, nDate);
    const backup = await CreateBackupUseCase(data);

    if (backup.message == "success") {
      const orderNumber = backup.insertId;
      const fireData = createDataFirebase(values, uuid, orderNumber, nDate);
      await createBookingFirebase(fireData);
      if (values.booking != 1 && !checked) {
        await sendEmailBooking(values, orderNumber);
      }

      setEnterprise(null);
      setEmployee(null);
      setAddressOrigin(null);
      setAddressDestination(null);
      values.persons = 1;
      values.vehicle = "";
      values.note = "";
      snackBarDialog("PEDIDO CREADO");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      snackBarDialog("ERROR: NO SE LOGRO CREAR EL SERVICIO", "error");
    }

    console.log(backup);
  };

  const createDataMySql = (values, uuid, nDate) => {
    const uid = employee.id;
    return {
      uuid,
      userType: 0,
      originName: addressOrigin.name,
      originLat: addressOrigin.latitude,
      originLng: addressOrigin.longitude,
      originUrl: addressOrigin.url,
      destinationName: addressDestination.name,
      destinationLat: addressDestination.latitude,
      destinationLng: addressDestination.longitude,
      destinationUrl: addressDestination.url,
      date: values.booking === 1 ? dateFormat(nDate) : dateFormat(date.$d),
      time: values.booking === 1 ? timeFormat(nDate) : timeFormat(time.$d),
      dateEnd: values.booking === 3 ? dateFormat(dateEnd.$d) : "",
      timeEnd: values.booking === 3 ? timeFormat(timeEnd.$d) : "",
      persons: values.persons,
      vehicleModel: values.vehicle,
      notes: values.note ? values.note.toUpperCase() : "",
      notesAdmin: "",
      userId: uid,
      driverId: 0,
      vehicleId: 0,
      paymentId: 0,
      bookingType: values.booking,
      tourId: values.booking === 4 ? tour.tour_id : "",
      invoiceId: 0,
      createdBy: "ADMIN",
      status: 1,
      statePayment: "PENDIENTE",
      assigned: false,
      invoice: false,
      invoiceStatus: false,
      travelStatus: 1,
      currency: 1,
      paymentType: enterprise.payment_type === "EFECTIVO" ? 1 : 2,
      paymentVoucher: 1,
      tax: tax,
      toll: 0,
      price: priceDriver === "" ? 0 : priceDriver,
      total: priceTotal === "" ? 0 : priceTotal,
      discount: 0,
      advance: 0,
      quote: values.booking != 1 ? !checked : true,
    };
  };

  const createDataFirebase = (values, uuid, counter, nDate) => {
    const uid = employee.id;
    return {
      uuid,
      client: uid,
      clientName: `${employee.name} ${employee.lastname}`,
      clientEmail: employee.email,
      clientPhone: employee.phone,
      enterpriseId: enterprise.id,
      enterpriseName: enterprise.commertial_name,
      origin: {
        name: addressOrigin.name,
        lat: addressOrigin.latitude,
        lng: addressOrigin.longitude,
        url: addressOrigin.url,
      },
      destination: {
        name: addressDestination.name,
        lat: addressDestination.latitude,
        lng: addressDestination.longitude,
        url: addressDestination.url,
      },
      intermediate: serviceList,
      persons: values.persons,
      vehicle: values.vehicle,
      date: values.booking === 1 ? dateFormat(nDate) : dateFormat(date.$d),
      time: values.booking === 1 ? timeFormat(nDate) : timeFormat(time.$d),
      dateEnd: values.booking === 3 ? dateFormat(dateEnd.$d) : "",
      timeEnd: values.booking === 3 ? timeFormat(timeEnd.$d) : "",
      tour_id: values.booking === 4 ? tour.tour_id : "",
      tour_description: values.booking === 4 ? tour.description : "",
      note: values.note ? values.note.toUpperCase() : "",
      noteAdmin: "",
      currency: 1,
      payment_type: enterprise.payment_type === "EFECTIVO" ? 1 : 2,
      payment_voucher: 1,
      tax: tax,
      toll: 0,
      price: priceDriver === "" ? 0 : priceDriver,
      total: priceTotal === "" ? 0 : priceTotal,
      discount: 0,
      advance: 0,
      statusService: "PENDIENTE",
      statusPayment: "PENDIENTE",
      statusBooking: "PENDIENTE",
      createBooking: "ADMIN",
      booking_type: values.booking,
      counterBooking: counter,
      created: Date.now(),
      isCreated: false,
      cotizar: values.booking != 1 ? !checked : true,
      phoneActive: phoneActive,
    };
  };

  // TOUR
  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getCountrys = async () => {
      try {
        const response = await getListCountryUseCase();
        setCountries([]);
        setCountries(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCountrys();
  }, []);

  //SELECCIONA UN PAIS TRAER LISTA DE TOUR
  const [tour, setTour] = useState(null);
  const [tours, setTours] = useState([]);

  useEffect(() => {
    if (country) {
      const getListTour = async () => {
        try {
          console.log(country.id);
          const response = await getListTourByCountry(country.id);
          console.log(response);
          setTours([]);
          setTour(null);
          setTours(response.data.data);
        } catch (error) {
          console.log(error);
        }
      };
      getListTour();
    }
  }, [country]);

  const [priceTotal, setPriceTotal] = useState(0);
  const [priceDriver, setPriceDriver] = useState(0);
  const [tax, setTax] = useState(0);

  useEffect(() => {
    setPriceTotal(0);
    setPriceDriver(0);
    setTax(0);
    if (addressOrigin != null && addressDestination != null) {
      const nameOrigin = addressOrigin.name;
      const nameDestination = addressDestination.name;

      routesEnterprise.map((route) => {
        const checkOriginName = nameOrigin === route.originName;
        const checkDestinationaName = nameDestination === route.destinationName;
        const checkOriRevName = nameOrigin === route.destinationName;
        const checkDestRevName = nameDestination === route.originName;

        if (
          (checkOriginName && checkDestinationaName) ||
          (checkOriRevName && checkDestRevName)
        ) {
          const origin = {
            latitude: route.originLat,
            longitude: route.originLng,
          };
          const destination = {
            latitude: route.destinationLat,
            longitude: route.destinationLng,
          };

          const checkOrigin = distanceBetweenCoordinates(addressOrigin, origin);
          const checkDestination = distanceBetweenCoordinates(
            addressDestination,
            destination
          );

          const checkOriRev = distanceBetweenCoordinates(
            addressOrigin,
            destination
          );
          const checkDestRev = distanceBetweenCoordinates(
            addressDestination,
            origin
          );

          if (
            (checkOrigin && checkDestination) ||
            (checkOriRev && checkDestRev)
          ) {
            if (vehicleSelect == 1) {
              setPriceTotal(Number(route.total));
              setPriceDriver(Number(route.driver));
              setTax(Number(route.tax));
            }
            if (vehicleSelect == 2) {
              setPriceTotal(Number(route.suv_total));
              setPriceDriver(Number(route.suv_driver));
              setTax(Number(route.suv_tax));
            }
            if (vehicleSelect == 3) {
              setPriceTotal(Number(route.mini_total));
              setPriceDriver(Number(route.mini_driver));
              setTax(Number(route.mini_tax));
            }
            if (vehicleSelect == 4) {
              setPriceTotal(Number(route.mini15_total));
              setPriceDriver(Number(route.mini15_driver));
              setTax(Number(route.mini15_tax));
            }
          }
        }
      });
    }
  }, [addressOrigin, addressDestination, vehicleSelect]);

  function distanceBetweenCoordinates(pointA, pointB) {
    const earthRadius = 6371;
    const diffLat = toRadians(pointB.latitude - pointA.latitude);
    const diffLng = toRadians(pointB.longitude - pointA.longitude);

    const a =
      Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
      Math.cos(toRadians(pointA.latitude)) *
        Math.cos(toRadians(pointB.latitude)) *
        Math.sin(diffLng / 2) *
        Math.sin(diffLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;
    return distance <= 0.3;
  }

  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            onSubmit={handleSubmit}
            backgroundColor={colors.primary[400]}
            p={2}
            sx={{ margin: "1px" }}
          >
            <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
              <Grid item xs={12} md={6}>
                {enterprises ? (
                  <Autocomplete
                    value={enterprise}
                    disablePortal
                    name="enterprise"
                    id="combo-box-enterprise"
                    onChange={(e, newValue) => {
                      setEnterprise(newValue);
                      getEmployees(newValue.id);
                      getRatesAndRoutes(newValue.id);
                    }}
                    options={enterprises}
                    getOptionLabel={(option) =>
                      `${option.commertial_name} - ${option.ruc} - ${option.business_name}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("Empresas")} />
                    )}
                  />
                ) : (
                  <TextField
                    fullWidth
                    disabled
                    type="text"
                    label={t("Lista de Empresas")}
                  />
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                {employees ? (
                  <Autocomplete
                    value={employee}
                    disablePortal
                    name="client"
                    id="combo-box-client"
                    onChange={(e, newValue) => {
                      setEmployee(newValue);
                    }}
                    options={employees}
                    getOptionLabel={(option) =>
                      `${option.name} ${option.lastname} - ${option.phone} - ${option.subrole_name}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("Empleados")} />
                    )}
                  />
                ) : (
                  <TextField
                    fullWidth
                    disabled
                    type="text"
                    label={t("Lista de Empleados")}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                {addressApi ? (
                  <Autocomplete
                    value={addressOrigin}
                    disablePortal
                    name="addressOrigin"
                    id="combo-box-origin"
                    onChange={(e, newValue) => {
                      setAddressOrigin(newValue);
                    }}
                    options={addressApi}
                    getOptionLabel={(option) =>
                      `${option.name} ${option.url_address}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("Origen")} />
                    )}
                  />
                ) : (
                  <TextField
                    fullWidth
                    disabled
                    type="text"
                    label={t("Lista de Direcciones")}
                  />
                )}
              </Grid>

              {serviceList.map((singleService, index) => (
                <Grid key={index} item xs={12} sm={6}>
                  {addressApi ? (
                    <Autocomplete
                      value={addressDestination}
                      disablePortal
                      name="client"
                      id="combo-box-destination"
                      onChange={(e, newValue) => {
                        setAddressDestination(newValue);
                      }}
                      options={addressApi}
                      getOptionLabel={(option) =>
                        `${option.name} ${option.url_address}`
                      }
                      renderInput={(params) => (
                        <TextField {...params} label={t("Destino")} />
                      )}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled
                      type="text"
                      label={t("Lista de Direcciones")}
                    />
                  )}
                </Grid>
              ))}

              <Grid item xs={12} sm={6}>
                {addressApi ? (
                  <Autocomplete
                    value={addressDestination}
                    disablePortal
                    name="addressDestination"
                    id="combo-box-destination"
                    onChange={(e, newValue) => {
                      setAddressDestination(newValue);
                    }}
                    options={addressApi}
                    getOptionLabel={(option) =>
                      `${option.name} ${option.url_address}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("Destino")} />
                    )}
                  />
                ) : (
                  <TextField
                    fullWidth
                    disabled
                    type="text"
                    label={t("Lista de Direcciones")}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  value={values.persons}
                  fullWidth
                  type="number"
                  label={t("persons")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="persons"
                  error={!!touched.persons && !!errors.persons}
                  helperText={touched.persons && errors.persons}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>{t("vehicle")}</InputLabel>
                  <Select
                    value={values.vehicle}
                    id="vehicle"
                    name="vehicle"
                    label={t("vehicle")}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      setVehicleSelect(e.target.value);
                    }}
                    error={!!touched.vehicle && !!errors.vehicle}
                  >
                    {model.map((model, index) => (
                      <MenuItem key={index} value={model.id}>
                        {t(model.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={values.booking === 3 ? 4 : 6}
                md={values.booking === 3 ? 4 : 6}
              >
                <FormControl fullWidth>
                  <InputLabel>{"Para:"}</InputLabel>
                  <Select
                    id="booking"
                    value={values.booking}
                    name="booking"
                    label={"Para:"}
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>{t("AHORA")}</MenuItem>
                    <MenuItem value={2}>{t("DESPUES")}</MenuItem>
                    <MenuItem value={3}>{t("POR HORA")}</MenuItem>
                    <MenuItem value={4}>{t("TOUR")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={6}
                sm={values.booking === 3 ? 2 : 3}
                sx={{
                  display: {
                    xs:
                      values.booking === 2 ||
                      values.booking === 3 ||
                      values.booking === 4
                        ? "block"
                        : "none",
                    md:
                      values.booking === 2 ||
                      values.booking === 3 ||
                      values.booking === 4
                        ? "block"
                        : "none",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <MobileDatePicker
                      label={values.booking === 3 ? t("startTime") : t("date")}
                      inputFormat="DD/MM/YYYY"
                      name="date"
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                        setDateEnd(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={6}
                sm={values.booking === 3 ? 2 : 3}
                sx={{
                  display: {
                    xs:
                      values.booking === 2 ||
                      values.booking === 3 ||
                      values.booking === 4
                        ? "block"
                        : "none",
                    md:
                      values.booking === 2 ||
                      values.booking === 3 ||
                      values.booking === 4
                        ? "block"
                        : "none",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <MobileTimePicker
                      label={values.booking === 3 ? t("startTime") : t("time")}
                      value={time}
                      name="time"
                      onChange={(newValue) => {
                        setTime(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2}
                sx={{
                  display: {
                    xs: values.booking === 3 ? "block" : "none",
                    md: values.booking === 3 ? "block" : "none",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <MobileDatePicker
                      label={t("dateEnd")}
                      inputFormat="DD/MM/YYYY"
                      name="dateEnd"
                      value={dateEnd}
                      onChange={(newValue) => {
                        setDateEnd(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={6}
                sm={2}
                sx={{
                  display: {
                    xs: values.booking === 3 ? "block" : "none",
                    md: values.booking === 3 ? "block" : "none",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <MobileTimePicker
                      label={t("timeEnd")}
                      value={timeEnd}
                      name="timeEnd"
                      onChange={(newValue) => {
                        setTimeEnd(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>

              {countries && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    display: {
                      xs: values.booking === 4 ? "block" : "none",
                      md: values.booking === 4 ? "block" : "none",
                    },
                  }}
                >
                  <Autocomplete
                    value={country}
                    disablePortal
                    id="select-origin"
                    onChange={(e, newValue) => {
                      setCountry(newValue);
                    }}
                    options={countries}
                    getOptionLabel={(option) => `${option.id} ${option.name}`}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("PAIS")} />
                    )}
                  />
                </Grid>
              )}

              {tours.length > 0 && (
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    display: {
                      xs: values.booking === 4 ? "block" : "none",
                      md: values.booking === 4 ? "block" : "none",
                    },
                  }}
                >
                  <Grid container sx={{ alignItems: "center" }}>
                    <Grid xs={11}>
                      <Autocomplete
                        value={tour}
                        disablePortal
                        id="select-origin"
                        onChange={(e, newValue) => {
                          setTour(newValue);
                        }}
                        options={tours}
                        getOptionLabel={(option) =>
                          `${option.tour_id} ${option.description}`
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.tour_id === value.tour_id
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={t("TOUR")} />
                        )}
                      />
                    </Grid>
                    {tour && (
                      <Grid xs={1} padding={1}>
                        <spam
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            padding: "5px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            display: "inline-block",
                          }}
                          onClick={() => window.open(`${tour.url}`, "_blank")}
                        >
                          Info
                        </spam>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  value={values.note}
                  fullWidth
                  type="text"
                  label={t("notes")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="note"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.note && !!errors.note}
                  helperText={touched.note && errors.note}
                />
              </Grid>

              {!matchesSM && (
                <>
                  <Grid item xs={2} sm={1}>
                    <Checkbox
                      checked={checked}
                      onChange={handleChangeCheckBox}
                      color="success"
                    />
                  </Grid>

                  <Grid item xs={2} sm={1}>
                    <Checkbox
                      checked={phoneActive}
                      onChange={changePhoneActive}
                      color="success"
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={4} sm={2}>
                <TextField
                  value={priceDriver}
                  fullWidth
                  type="number"
                  label={"Conductor"}
                  onBlur={handleBlur}
                  onChange={(e) => setPriceDriver(e.target.value)}
                  name="priceDriver"
                />
              </Grid>

              <Grid item xs={4} sm={2}>
                <TextField
                  value={tax}
                  fullWidth
                  type="number"
                  label={"Impuesto"}
                  onBlur={handleBlur}
                  onChange={(e) => setTax(e.target.value)}
                  name="tax"
                />
              </Grid>

              <Grid item xs={4} sm={2}>
                <TextField
                  value={priceTotal}
                  fullWidth
                  type="number"
                  label={"Total"}
                  onChange={(e) => setPriceTotal(e.target.value)}
                  name="priceTotal"
                />
              </Grid>
              {matchesSM && (
                <Grid item xs={1} sm={1} marginLeft={-1}>
                  <Checkbox
                    checked={checked}
                    onChange={handleChangeCheckBox}
                    color="success"
                  />
                </Grid>
              )}
              <Grid item xs={10} sm={4}>
                <Button
                  fullWidth
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ height: 45 }}
                  disabled={isLoading}
                >
                  <Typography variant="string">
                    &#128196;&nbsp; {t("booknow")}&nbsp; &#128241;
                    {/* &nbsp;&#128231; */}
                  </Typography>
                </Button>
              </Grid>
              {matchesSM && (
                <>
                  <Grid item xs={1} sm={1}>
                    <Checkbox
                      checked={phoneActive}
                      onChange={changePhoneActive}
                      color="success"
                    />
                  </Grid>
                  {/* <Grid item xs={1} sm={1}>
                    <Checkbox
                      checked={emailActive}
                      onChange={changeEmailActive}
                      color="success"
                    />
                  </Grid> */}
                </>
              )}
            </Grid>
          </Box>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={4000}
            handleClose={handleClose}
            severity={severity}
            messageSnk={messageSnk}
          />
        </form>
      )}
    </Formik>
  );
};

export default BookingBusiness;
