import { createCostCenter } from "../respository/costcenter.respository";

export const CreateCostCenter = async (costCenter) => {
  try {
    const response = await createCostCenter(costCenter);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
