import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { ref, onValue, remove, update } from "firebase/database";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  useTheme,
  Modal,
  TextField,
  Grid,
  FormControl,
  Select,
  InputLabel,
  Typography,
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";

import { db } from "../../../../../../../../firebase";
import { tokens } from "../../../../../../../../theme";
import { putBookingNow } from "../../../../../../../../api/booking/bookingNow";
import SnackBar from "../../../../../../../../components/snackbar/SnackBar";

import { ModalStyle } from "../style/QuoteTourStyle";
import { sendICSEmail } from "../../../../../../../../api/resources/ics/ics";
import { GridCellExpand } from "../../core/GridCellExpand";

const QuoteTourTable = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [booking, setBooking] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openQuoteModal, setOpenQuoteModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [UID, setUID] = useState("");
  const [bookingType, setBookingType] = useState(0);
  const [clientID, setClientID] = useState("");
  const [currencyType, setCurrencyType] = useState("");
  const [currencyPrice, setCurrencyPrice] = useState("");
  const [bookBill, setBookBill] = useState({});
  const [payment, setPayment] = useState("PAGADO");
  const [discount, setDiscount] = useState(0);

  const [checkboxSelection, setCheckboxSelection] = useState(true);

  const handleClose = () => setAnchorEl(null);

  const currency = {
    1: "PEN",
    2: "USD",
  };

  const handleWhatsappClient = (booking, uuid) => {
    booking.map((book) => {
      if (book.uuid === uuid) {
        if (book.clientPhone) {
          if (book.driver) {
            window.open(
              `https://api.whatsapp.com/send?phone=${book.clientPhone}=&text=*Cliente:* ${book.clientName} ,%0A *Origen:* ${book.origin.name}%0A *Destino:* ${book.destination.name}%0A *Fecha:* ${book.date} - *Hora:* ${book.time}%0A *Pasajeros:* ${book.persons}%0A *Conductor:* ${book.driver.name}%0A *Marca:* ${book.driver.brand}%0A  *Carroceria:* ${book.driver.bodywork}%0A *Placa:* ${book.driver.plate}%0A *Color:* ${book.driver.color}`,
              "_blank",
              "noreferrer"
            );
          } else {
            window.open(
              `https://api.whatsapp.com/send?phone=${book.clientPhone}=&text=*Cliente:* ${book.clientName} ,%0A *Origen:* ${book.origin.name}%0A *Destino:* ${book.destination.name}%0A *Fecha:* ${book.date} - *Hora:* ${book.time}%0A *Pasajeros:* ${book.persons}`,
              "_blank",
              "noreferrer"
            );
          }
        } else {
          window.alert("no phone");
        }
      }
    });
    setAnchorEl(null);
  };

  const handleWhatsappDriver = (booking, uuid) => {
    booking.map((book) => {
      if (book.uuid === uuid) {
        if (book.driver) {
          window.open(
            `https://api.whatsapp.com/send?phone=${
              book.driver.phone
            }=&text=*Cliente:* ${book.clientName} ,%0A *Origen:* ${
              book.origin.name
            }%0A *Destino:* ${book.destination.name}%0A *Fecha:* ${
              book.date
            } - *Hora:* ${book.time}%0A *Pasajeros:* ${book.persons}%0A ${
              book.payment_type === 1
                ? `*Servicio:* EFECTIVO`
                : `*Servicio:* CREDITO`
            }%0A ${
              book.payment_type === 1
                ? `*Total Cobrar:* ${book.total}`
                : `*Total Cobrar:* ${book.price}`
            }`,
            "_blank",
            "noreferrer"
          );
        } else {
          window.alert("no phone");
        }
      }
    });
    setAnchorEl(null);
  };

  const handleOpenModal = (booking, uuid) => {
    booking.map((book) => {
      if (book.uuid === uuid) {
        setAnchorEl(null);
        setOpenModal(true);
        setBookBill(book);
        setPayment(book.payment_type);
      }
    });
  };

  const handleCloseModal = () => setOpenModal(false);
  const handleCloseQuoteModal = () => setOpenQuoteModal(false);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  useEffect(() => {
    const starCountRef = ref(db, "bookingNow/");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setBooking([]);
      if (data !== null) {
        Object.values(data).map((booking) => {
          if (booking.booking_type === 4 && !booking.cotizado) {
            booking.originName = booking.origin.name;
            booking.destinationName = booking.destination.name;
            booking.note = `${booking.tour_description}. ${booking.note}`;
            setBooking((oldArray) => [...oldArray, booking]);
          }
        });
      }
    });
  }, []);

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };

  const columnsClient = [
    {
      headerName: "Pedido",
      field: "counterBooking",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      headerName: "FECHA",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "HORA",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "Nombre",
      field: "clientName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "Telefono",
      field: "clientPhone",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 110,
      renderCell: ({ row: { clientPhone } }) => {
        const phone = clientPhone.split(" ");
        return (
          <Stack direction="row" spacing={0}>
            <Typography
              color="success"
              aria-label="add"
              component="label"
              onClick={() => {
                window.open(`https://wa.me/${phone[0]}${phone[1]}`);
              }}
              target="_blank"
            >
              {clientPhone}
            </Typography>
          </Stack>
        );
      },
    },
    {
      headerName: "Origen",
      field: "originName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Destino",
      field: "destinationName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Pack",
      field: "persons",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      headerName: "Notas",
      field: "note",
      type: "string",
      headerAlign: "left",
      width: 250,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Moneda",
      field: "currency",
      type: "string",
      headerAlign: "left",
      align: "center",
      width: 70,
    },
    {
      headerName: "Precio",
      field: "total",
      type: "string",
      headerAlign: "left",
      align: "center",
      width: 50,
    },
    {
      headerName: "Estado",
      field: "statusPayment",
      width: 90,
      renderCell: ({ row: { statusPayment } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              width: "100%",
              textAlign: "right",
              color: "white",
              background: statusPayment === "PENDIENTE" ? "red" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {statusPayment}
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 190,
      renderCell: ({
        row: {
          booking_type,
          client,
          uuid,
          counterBooking,
          clientName,
          clientEmail,
          clientPhone,
          fullorigin,
          fulldestination,
          date,
          time,
          persons,
          vehicle,
          note,
        },
      }) => {
        return (
          <Stack direction="row" spacing={1}>
            {/* EDIT QUOTE */}
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                navigate(`/admin/add-quote-hour/${uuid}`);
              }}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
            {/* CONFIRM QUOTE */}
            <IconButton
              component="label"
              color="success"
              sx={{ background: "#ffff" }}
              onClick={() => {
                setUID(uuid);
                setBookingType(booking_type);
                setClientID(client);
                setEmailBooking({
                  counterBooking: counterBooking,
                  clientName: clientName,
                  clientEmail: clientEmail,
                  clientPhone: clientPhone,
                  origin: { name: fullorigin },
                  destination: { name: fulldestination },
                  date: date,
                  time: time,
                  persons: persons,
                  vehicle: vehicle,
                  note: note,
                  web: "Booking",
                });
                setOpenQuoteModal(true);
              }}
            >
              <CheckCircleOutlineOutlinedIcon />
            </IconButton>
            {/* DELETE */}
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(uuid);
                setBookingType(booking_type);
                setClientID(client);
                setOpenDeleteModal(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const updateFacture = async (uuid, payment, discount) => {
    try {
      bookBill.payment_type = payment;
      bookBill.discount = discount;
      const token = "asdf";

      await putBookingNow(token, discount, payment, bookBill.counterBooking);
      const dbRef = ref(db, `bookingNow/${uuid}`);
      await remove(dbRef);
      handleCloseModal();
      setDiscount(0);
      setPayment("PAGADO");
      snackBarDialog("PEDIDO FACTURADO");
    } catch (error) {
      console.log(error);
      handleCloseModal();
      snackBarDialog("ERROR, NO SE GUARDO LOS CAMBIOS", "error");
    }
  };

  const [emailBooking, setEmailBooking] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleCloseSnk = () => setStateSnk(false);

  const updateQuote = async () => {
    setIsLoading(true);
    try {
      const value = { cotizado: true };
      const hourRef = `bookingNow/${UID}`;
      await update(ref(db, hourRef), value);
      //await sendICSEmail("token", emailBooking);
      snackBarDialog("COTIZACION CONFIRMADA");
      setIsLoading(false);
      handleCloseQuoteModal();
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      snackBarDialog("ERROR: NO SE PUEDE CONFIRMAR LA COTIZACION", "error");
      handleCloseDeleteModal();
    }
  };

  const deleteQuote = async () => {
    setIsLoading(true);
    try {
      await remove(ref(db, `bookingNow/${UID}`));
      setIsLoading(false);
      handleCloseDeleteModal();
      snackBarDialog("COTIZACION ELIMINADA");
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      snackBarDialog("ERROR: NO SE PUEDE ELIMINA LA COTIZACION", "error");
      handleCloseDeleteModal();
    }
  };

  const [openActiveBtnModal, setOpenActiveBtnModal] = useState(false);
  const handleCloseActiveBtnModal = () => setOpenActiveBtnModal(false);

  const updateActiveBtnQuote = async (currency) => {
    try {
      const openpayData = {
        openpay: {
          status: "pending",
          btnPay: true,
          currency,
          transaction_id: "",
          order_id: "",
          url: "",
        },
      };

      await update(ref(db, `bookingNow/${UID}`), openpayData);
      handleCloseActiveBtnModal();
      snackBarDialog("BOTON ACTIVADO PARA PAGOS");
    } catch (error) {
      handleCloseActiveBtnModal();
      console.log(error);
      snackBarDialog("ERROR DE ACTIVACION DE BOTON DE PAGO ", "error");
    }
  };

  return (
    <Box m="0px">
      <Box
        m="10px 0 0 0"
        height="100vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection={checkboxSelection}
          getRowId={(row) => row.uuid}
          rows={booking}
          columns={columnsClient}
        />
      </Box>
      {/* MODAL PARA  PAGO*/}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            ...ModalStyle,
            width: 300,
            backgroundColor: colors.primary[400],
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} textAlign={"center"}>
              <h2 id="modal-title">FACTURA</h2>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={bookBill.counterBooking}
                fullWidth
                disabled
                label="Nro Pedido"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={bookBill.clientName}
                fullWidth
                disabled
                label="Client"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={discount}
                fullWidth
                type={"number"}
                label="Descuento"
                variant="outlined"
                onChange={(e) => {
                  setDiscount(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Tipo Pago</InputLabel>
                <Select
                  value={payment}
                  id="payment_type"
                  name="payment_type"
                  label="Tipo de Pago"
                  onChange={(e) => setPayment(e.target.value)}
                >
                  <MenuItem value={"PAGADO"}>PAGADO</MenuItem>
                  <MenuItem value={"CREDITO"}>CREDITO</MenuItem>
                  <MenuItem value={"CANCELADO"}>CANCELADO</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: {
                  xs: payment === 2 ? "block" : "none",
                  md: payment === 2 ? "block" : "none",
                },
              }}
            >
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Pago con tarjeta
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  updateFacture(UID, payment, discount);
                }}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* MODAL PARA CONFIRMAR COTIZACION */}
      <Modal
        open={openQuoteModal}
        onClose={handleCloseQuoteModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            ...ModalStyle,
            width: 300,
            backgroundColor: colors.primary[400],
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} textAlign={"center"}>
              <h2 id="modal-title">CONFIRMAR COTIZACION</h2>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="success"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  updateQuote();
                }}
                disabled={isLoading}
              >
                ACEPTAR
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="error"
                variant="contained"
                onClick={handleCloseQuoteModal}
                disabled={isLoading}
              >
                CANCELAR
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* MODAL ELIMINAR PEDIDO */}
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            ...ModalStyle,
            width: 300,
            backgroundColor: colors.primary[400],
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} textAlign={"center"}>
              <h2 id="modal-title">ELIMINAR COTIZACION</h2>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="success"
                variant="contained"
                onClick={deleteQuote}
              >
                ACEPTAR
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="error"
                variant="contained"
                onClick={handleCloseDeleteModal}
              >
                CANCELAR
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* MODAL ACTIVAR BOTON DE PAGO */}
      {/* ACTIVAR BTN DE PAGO CLIENTE */}
      <Modal
        open={openActiveBtnModal}
        onClose={handleCloseActiveBtnModal}
        aria-labelledby="modal-title"
      >
        <Box
          sx={{
            ...ModalStyle,
            width: 300,
            backgroundColor: colors.primary[400],
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} textAlign={"center"}>
              <h2 id="modal-title" style={{ margin: 0 }}>
                ACTIVAR BOTON PAGO
              </h2>
              <h3 id="sub-title" style={{ margin: 0 }}>
                {currency[currencyType]} {currencyPrice}
              </h3>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="success"
                variant="contained"
                onClick={(e) => updateActiveBtnQuote(currency[currencyType])}
              >
                ACTIVAR PAGO
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="error"
                variant="contained"
                onClick={handleCloseActiveBtnModal}
              >
                CERRAR
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={4000}
        handleClose={handleCloseSnk}
        severity={severity}
        messageSnk={messageSnk}
      />
    </Box>
  );
};

export default QuoteTourTable;
