import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  Modal,
  Typography,
  Badge,
  TextField,
  Button,
} from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import DrawerUI from "../drawer/Drawer";
import { deleteTokenRefresh } from "../../helpers/token/refreshToken";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getMenuToken, setMenuToken } from "../../helpers/token/menuToken";
import logo from "../../assets/logo.png";
import { getBirthdayDrivers } from "../../api/users/driver/driver";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
} from "@react-pdf/renderer";
import TextName from "./pdf/TextName";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorNotificationEl, setAnchorNotificationEl] = useState(null);
  const openNotification = Boolean(anchorNotificationEl);
  const menuOpen = getMenuToken();
  const [birthdays, setBirthdays] = useState([]);

  useEffect(() => {
    if (!menuOpen) {
      setMenuToken("drawer");
    }
  }, []);

  useEffect(() => {
    const getBirthdays = async () => {
      try {
        const response = await getBirthdayDrivers();
        setBirthdays([]);
        response.data.map((bith) => {
          setBirthdays((oldData) => [...oldData, bith]);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getBirthdays();
  }, []);

  const handleClick = (event) => {
    //console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    // console.log(e.target.innerText);
    if (e.target.innerText === "Logout") {
      console.log("cerrar sesion");
      deleteTokenRefresh();
      navigate("/");
    } else if (e.target.innerText === "Profile") {
      console.log("Perfil");
    } else if (e.target.innerText === "My account") {
      console.log("Mi Cuenta");
    }

    setAnchorEl(null);
  };
  const handleClickNotification = (event) => {
    setAnchorNotificationEl(event.currentTarget);
  };
  const handleCloseNotification = (e) => {
    setAnchorNotificationEl(null);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    handleCloseNotification();
    setOpenModal(true);
  };

  const [textPdf, setTextPdf] = useState("");
  const [namePdf, setNamePdf] = useState(false);
  const openModalPdf = () => setNamePdf(true);
  const closeModalPdf = () => setNamePdf(false);

  const handleCloseModal = () => setOpenModal(false);

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 30,
      lineHeight: 1.5,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
  });

  return (
    <Box display="flex" justifyContent="space-between" p={1}>
      {/* SEARCH BAR */}
      <Box display="flex" justifyContent="space-between" mt={1} ml="10px">
        {menuOpen === "drawer" && <DrawerUI />}
        {menuOpen === "sidebar" && matchesSM && <DrawerUI />}
      </Box>
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <img
          src={logo}
          style={{ marginTop: 7, width: 35, height: 35 }}
          alt="Logo"
        />
        {/* <Translation backgroundColor={colors.primary[400]} /> */}
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>

        <IconButton onClick={openModalPdf}>
          <BadgeOutlinedIcon />
        </IconButton>

        <IconButton onClick={handleClickNotification}>
          <NotificationsOutlinedIcon />
          <Badge
            variant="dot"
            invisible={!(birthdays.length > 0)}
            color="error"
          />
        </IconButton>
        <IconButton onClick={() => navigate("/admin/configuration")}>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleClick}>
          <PersonOutlinedIcon />
        </IconButton>

        <Menu
          id="menu-options"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ "aria-labelledby": "basic-button" }}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu>
        <Menu
          id="notification-options"
          anchorEl={anchorNotificationEl}
          open={openNotification}
          onClose={handleCloseNotification}
          MenuListProps={{ "aria-labelledby": "basic-button" }}
        >
          <MenuItem onClick={handleOpenModal}>
            <p style={{ margin: 0 }}>
              Cumpleaños&nbsp;&nbsp;
              <Badge
                variant="dot"
                invisible={!(birthdays.length > 0)}
                color="error"
              />
            </p>
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              navigate("/admin/list-book-business-now");
              handleCloseNotification();
            }}
          >
            Reservas
          </MenuItem>
        </Menu>
      </Box>
      {/* LISTA DE CUMPLE */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            LISTA DE CUMPLEANEROS
          </Typography>
          {birthdays &&
            birthdays.map((driver, index) => (
              <Typography key={index} id="modal-description" sx={{ mt: 2 }}>
                {driver.name} {driver.lastname} - {driver.birthday}
              </Typography>
            ))}
        </Box>
      </Modal>

      <Modal open={namePdf} onClose={closeModalPdf}>
        <Box sx={style}>
          <Typography variant="h6" component={"h2"}>
            NOMBRE PARA IMPRIMIR
          </Typography>
          <TextField
            name="NOMBRE PARA IMPRIMIR"
            label="NOMBRE PARA IMPRIMIR"
            fullWidth
            sx={{
              marginTop: 2,
            }}
            onChange={(e) => setTextPdf(e.target.value)}
          />
          <div
            style={{
              marginTop: "5px",
              display: "flex",
            }}
          >
            <PDFDownloadLink
              document={
                <Document>
                  {textPdf.length > 0 && (
                    <Page size={[841.89, 595.28]} style={styles.page}>
                      <TextName name={textPdf} />
                    </Page>
                  )}
                </Document>
              }
              fileName={`nombre-imprimir.pdf`}
              style={{ width: "50%" }}
            >
              {textPdf.length > 0 && (
                <Button
                  variant="contained"
                  color="success"
                  sx={{ width: "100%" }}
                  disabled={textPdf.length === 0}
                >
                  IMPRIMIR
                </Button>
              )}
            </PDFDownloadLink>

            <Button
              variant="contained"
              color="error"
              sx={{ width: "50%" }}
              onClick={closeModalPdf}
            >
              CANCELAR
            </Button>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default Topbar;
