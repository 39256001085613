import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CssBaseline,
  Grid,
  Stack,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

import { tokens } from "../../../../theme";
import {
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../../helpers/token/refreshToken";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SnackBar from "../../../../components/snackbar/SnackBar";
import {
  getEnterprise,
  updateEnterprise,
} from "../../../../api/enterprise/enterprise";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../components/sidebar/Sidebar";

const EnterpriseEdit = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const { id } = useParams();
  const { t } = useTranslation();
  const [isUpdate, setIsUpdate] = useState(true);
  const [enterprise, setEnterprise] = useState("");

  const [dateInvoice, setDateInvoice] = useState(null);

  const [licente, setLicente] = useState("");
  const location = useLocation();
  const enterpriseName = location.state.enterpriseName;

  const checkoutSchema = yup.object().shape({
    commertial_name: yup.string().required("required"),
    business_name: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    phone: yup.string().required("required"),
    address: yup.string().required("required"),
    ruc: yup.string().required("required"),
  });

  useEffect(() => {
    const driver = async () => {
      try {
        const token = getTokenRefresh();
        const response = await getEnterprise(token, id);
        setEnterprise(response?.data[0]);
        setChecked(Boolean(response?.data[0].cost_center));
      } catch (error) {
        console.log(error);
      }
    };
    driver();
  }, []);

  const handleFormSubmit = async (values) => {
    const expireToken = verifyExpireTokenRefresh();
    console.log(values);
    //if (expireToken) navigate("/");

    try {
      const token = getTokenRefresh();
      const enterpriseData = {
        id: values.id,
        commertial_name: values.commertial_name.toUpperCase(),
        business_name: values.business_name.toUpperCase(),
        ruc: values.ruc,
        address: values.address.toUpperCase(),
        email: values.email,
        phone: values.phone,
        contract: values.contract ? values.contract.toUpperCase() : "",
        contract_start: values.contract_start,
        contract_end: values.contract_end,
        payment_type: values.payment_type,
        notes: values.notes ? values.notes.toUpperCase() : "",
        igv: values.igv,
        costCenter: checked,
      };

      await updateEnterprise(token, enterpriseData);
      snackBarDialog("DATOS ACTUALIZADOS");
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR AL ACTUALIZAR DATOS", "error");
    }
  };

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [checked, setChecked] = useState(false);

  const handleChange1 = (event) => {
    console.log(event.target.checked);
    setChecked(event.target.checked);
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={enterpriseName} subtitle="EMPRESA" />

              <Button
                color="secondary"
                onClick={(e) => {
                  setIsUpdate(!isUpdate);
                }}
              >
                <CreateOutlinedIcon />
                EDITAR EMPRESA
              </Button>
            </Box>
            {enterprise ? (
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={enterprise}
                validationSchema={checkoutSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      backgroundColor={colors.primary[400]}
                      borderRadius={3}
                      p={2}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.ruc}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            type="text"
                            label={t("RUC")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="ruc"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            error={!!touched.ruc && !!errors.ruc}
                            helperText={touched.ruc && errors.ruc}
                            color="success"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.commertial_name}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            type="text"
                            label={t("NOMBRE COMERCIAL")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="commertial_name"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            error={
                              !!touched.commertial_name &&
                              !!errors.commertial_name
                            }
                            helperText={
                              touched.commertial_name && errors.commertial_name
                            }
                            color="success"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.business_name}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="text"
                            label={t("RAZON SOCIAL")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="business_name"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            error={
                              !!touched.business_name && !!errors.business_name
                            }
                            helperText={
                              touched.business_name && errors.business_name
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.address}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            name="address"
                            color="success"
                            type="text"
                            label={t("ADDRESS")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={!!touched.address && !!errors.address}
                            helperText={touched.address && errors.address}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.email}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="email"
                            label={t("email")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            value={values.phone}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="tel"
                            label={t("phone")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="phone"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            value={values.contract}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="text"
                            label={t("Contrato")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="contract"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2}>
                              <MobileDatePicker
                                value={values.contract_start}
                                label={t("start")}
                                inputFormat="DD/MM/YYYY"
                                name="contract_start"
                                onChange={(newValue) => {
                                  console.log(newValue);
                                  values.contract_start = newValue;
                                  setLicente(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                disabled={isUpdate}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2}>
                              <MobileDatePicker
                                value={values.contract_end}
                                label={t("end")}
                                inputFormat="DD/MM/YYYY"
                                name="contract_end"
                                onChange={(newValue) => {
                                  console.log(newValue);
                                  values.contract_end = newValue;
                                  setLicente(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                disabled={isUpdate}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth disabled={isUpdate}>
                            <InputLabel id="select-payment">
                              Tipo de Pago
                            </InputLabel>
                            <Select
                              labelId="payment_type"
                              id="select-payment"
                              value={values.payment_type}
                              name="payment_type"
                              label="Tipo de Pago"
                              onChange={handleChange}
                            >
                              <MenuItem value={"EFECTIVO"}>EFECTIVO</MenuItem>
                              <MenuItem value={"CREDITO"}>CREDITO</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth disabled={isUpdate}>
                            <InputLabel id="select-igv">IGV</InputLabel>
                            <Select
                              id="select-igv"
                              value={values.igv}
                              name="igv"
                              label="IGV"
                              onChange={handleChange}
                            >
                              <MenuItem value={"AFECTO"}>AFECTO</MenuItem>
                              <MenuItem value={"INAFECTO"}>INAFECTO</MenuItem>
                              <MenuItem value={"MAS IGV"}>MAS IGV</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <TextField
                            value={dateInvoice}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="text"
                            label={t("DIA DE FACTUACION")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="dateInvoice"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            error={
                              !!touched.dateInvoice && !!errors.dateInvoice
                            }
                            helperText={
                              touched.dateInvoice && errors.dateInvoice
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <TextField
                            value={values.notes}
                            disabled={isUpdate}
                            fullWidth
                            variant="outlined"
                            color="success"
                            type="text"
                            label={t("Notas")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            name="notes"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <FormControlLabel
                            disabled={isUpdate}
                            label="CENTRO COSTOS"
                            labelPlacement="top"
                            control={
                              <Checkbox
                                color="success"
                                checked={checked}
                                onChange={handleChange1}
                              />
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} textAlign={"end"}>
                          <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            disabled={isUpdate}
                          >
                            {t("ACTUALIZAR EMPRESA")}
                          </Button>
                        </Grid>
                      </Grid>
                      <SnackBar
                        stateSnk={stateSnk}
                        vertical={vertical}
                        horizontal={horizontal}
                        duration={5000}
                        handleClose={handleSnkClose}
                        severity={severity}
                        messageSnk={messageSnk}
                      />
                    </Box>
                  </form>
                )}
              </Formik>
            ) : (
              <>Cargando datos ...</>
            )}
          </Box>
        </main>
      </div>
    </>
  );
};

export default EnterpriseEdit;
