import React from "react";
import { Box, Button } from "@mui/material";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
} from "@react-pdf/renderer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Header from "./elements/Header";
import DocumentName from "./elements/DocumentName";
import TableBooking from "./elements/TableBooking";
import Footer from "./elements/Footer";

const VoucherHistory = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 35,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  return (
    <PDFDownloadLink
      document={
        <Document>
          <Page size="A4" style={styles.page}>
            <Header />
            <DocumentName title={"RECIBO"} />
            <TableBooking invoice={data} />
            <Footer />
          </Page>
        </Document>
      }
      fileName={"recibo-taximundo.pdf"}
    >
      <Box width={"100%"}>
        <Button fullWidth variant="contained" sx={{ backgroundColor: "blue" }}>
          <PictureAsPdfIcon /> Recibo
        </Button>
      </Box>
    </PDFDownloadLink>
  );
};

export default VoucherHistory;
