import { createScheduled } from "../../../data/repository/scheduled.repository";

export const CreateScheduledUsecase = async (scheduled) => {
  try {
    const resp = await createScheduled(scheduled);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
