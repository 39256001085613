import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Autocomplete,
  Box,
  Button,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

import "dayjs/locale/es";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import SnackBar from "../../../../components/snackbar/SnackBar";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { ServiceList } from "../../../../helpers/constants/ServiceList";

import { GetCountryAndCityListUsecase } from "../../../domain/usecases/city/GetCountryAndCityListUseCase";
import { GetTourCompleteListUseCase } from "../../../domain/usecases/tour/GetTourCompleteListUseCase";
import { getTokenRefresh } from "../../../../helpers/token/refreshToken";
import { getClients } from "../../../../api/users/client/client";
import { CreateBookingUseCase } from "../../../domain/usecases/booking/CreateBookingUseCase";

const BookingCreateScreen = () => {
  const [tourSelect, setTourSelect] = useState({
    code: "",
    title: "",
    language_abbreviation: "",
  });
  const [tourListApi, setTourListApi] = useState([]);
  const [tourComplete, setTourComplete] = useState([]);
  const [riderSelect, setRiderSelect] = useState({ name: "", phone: "" });
  const [riderComplete, setRiderComplete] = useState([]);
  const [horaryComplete, setHoraryComplete] = useState([]);
  const [countryComplete, setCountryComplete] = useState([]);
  const [countrySelect, setCountrySelect] = useState({ id: "", name: "" });
  const [cityComplete, setCityComplete] = useState([]);
  const [citySelect, setCitySelect] = useState({ id: "", name: "" });

  const [persons, setPersons] = useState(0);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [horary, setHorary] = useState("");
  const [currency, setCurrency] = useState("");
  const [total, setTotal] = useState(0);
  const [note, setNote] = useState("");
  const [noteAdmin, setNoteAdmin] = useState("");
  const [service, setService] = useState("");
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [guide, setGuide] = useState("SI");
  const [serviceEnterprise, setServiceEnterprise] = useState(false);

  useEffect(() => {
    getTourComplete();
  }, []);

  const getTourComplete = async () => {
    const token = getTokenRefresh();
    Promise.all([
      GetTourCompleteListUseCase(),
      getClients(token),
      GetCountryAndCityListUsecase(),
    ]).then(([tourResp, riderResp, countryList]) => {
      if (tourResp.message === "success") {
        setTourListApi(tourResp.tour);
      } else setTourListApi([]);
      console.log(riderResp);

      // if (riderResp.message === "success") {
      setRiderComplete([]);
      riderResp.data.map((rider) => {
        if (rider.status == 1) {
          setRiderComplete((oldRider) => [...oldRider, rider]);
        }
      });
      // } else setRiderComplete([]);

      if (countryList.message === "success") {
        setCountryComplete(countryList.country);
      } else setCountryComplete([]);
    });
  };

  const handleTourSelect = (tour) => {
    setTourSelect(tour);
    setOrigin(tour.def_origin);
    setDestination(tour.def_destination);
    setHoraryComplete(tour.horary);
  };

  const [enableGuide, setEnableGuide] = useState(false);
  const handleServiceSelect = (e) => {
    const value = e.target.value;
    setService(value);
    if (value == 1) {
      setEnableGuide(true);
      setGuide("SI");
    } else setEnableGuide(false);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const validationData = () => {
    if (tourSelect.code === "")
      return snackBarDialog("SELECCIONE EL TOUR", "error");
    if (riderSelect.name === "")
      return snackBarDialog("SELECCIONE CLIENTE", "error");
    if (Number(persons) <= 0)
      return snackBarDialog("INGRESE NUM PERSONAS", "error");
    if (service == 0)
      return snackBarDialog("SELECCIONE EL TIPO DE SERVICIO", "error");
    if (date == null) return snackBarDialog("SELECCIONE LA FECHA", "error");
    if (service == 1 && horary == "")
      return snackBarDialog("SELECCIONE EL HORARIO", "error");
    if (service == 2 && time == null)
      return snackBarDialog("SELECCIONE LA HORA", "error");
    if (currency === "") return snackBarDialog("SELECCIONE LA DIVISA", "error");
    if (total <= 0) return snackBarDialog("INGRESE EL TOTAL", "error");

    return true;
  };

  const timePickerToTime = (time) => {
    const { $H, $m } = time;
    const hour =
      $H > 12
        ? $H % 12 < 10
          ? "0" + ($H % 12)
          : $H % 12
        : $H > 9
        ? $H
        : "0" + $H;
    const minute = $m < 10 ? `0${$m}` : $m;
    return `${hour}:${minute} ${$H > 11 ? "PM" : "AM"}`;
  };

  const datePickerToDate = (date) => {
    const { $D, $M, $y } = date;
    const day = $D > 9 ? $D : `0${$D}`;
    const month = $M + 1 > 9 ? $M + 1 : `0${$M + 1}`;
    return `${day}/${month}/${$y}`;
  };

  const handleReservationCreate = async () => {
    try {
      if (validationData()) {
        setIsLoading(true);

        const reservation = {
          uuid: uuidv4(),
          driverId: null,
          tourId: tourSelect.id,
          riderId: riderSelect.id,
          riderType: 1,
          guide: guide, //string [si no]
          origin: origin,
          destination,
          persons,
          rating: 4.8,
          reservationState: 2,
          trip: 1,
          service,
          date: datePickerToDate(date),
          time: service == 1 ? "" : timePickerToTime(time),
          horary: service == 1 ? horary : "",
          currency,
          price: total,
          notes: note,
          noteAdmin,
          serviceEnterprise,
          intermediate,
          riderData: {
            name: riderSelect.name,
            email: riderSelect.email,
            phone: riderSelect.phone,
          },
        };

        const resp = await CreateBookingUseCase(reservation);
        if (resp.message === "success") {
          snackBarDialog("RESERVA CREADA");
        } else {
          snackBarDialog("ERROR AL CREAR LA RESERVA", "error");
        }

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const [intermediate, setIntermediate] = useState([]);
  const [routeInt, setRouteInt] = useState("");
  const handleAddRoute = () => {
    if (intermediate.length == 0) {
      setIntermediate([
        ...intermediate,
        { index: intermediate.length, route: "" },
      ]);
    } else if (intermediate[intermediate.length - 1].route !== "") {
      setIntermediate([
        ...intermediate,
        { index: intermediate.length, route: "" },
      ]);
      setRouteInt("");
    } else {
      window.alert("INGRESE LA RUTA INTERMEDIA");
    }
  };

  const addRouteIntermediate = (value, index) => {
    intermediate[index] = {
      index: index,
      route: value,
    };
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="CREAR RESERVACION" subtitle="" />
            </Box>
            <Box>
              {/* crear un reservacion */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Stack spacing={2}>
                    <Autocomplete
                      id="rider"
                      freeSolo
                      value={riderSelect}
                      onChange={(e, newValue) => {
                        if (newValue != null) setRiderSelect(newValue);
                      }}
                      options={riderComplete}
                      getOptionLabel={(option) =>
                        `${option.name} ${option.phone}`
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="SELECCIONE CLIENTE" />
                      )}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={6} sm={2}>
                  <Stack spacing={2}>
                    <Autocomplete
                      id="country"
                      freeSolo
                      value={countrySelect}
                      onChange={(e, newValue) => {
                        if (newValue != null) {
                          setCountrySelect(newValue);
                          setCityComplete(newValue.cityList);
                        }
                      }}
                      onInputChange={(e, newInputValue) => {
                        if (newInputValue == "") {
                          setCityComplete([]);
                        }
                      }}
                      options={countryComplete}
                      getOptionLabel={(option) => `${option.name}`}
                      renderInput={(params) => (
                        <TextField {...params} label="PAIS" />
                      )}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={6} sm={2}>
                  <Stack spacing={2}>
                    <Autocomplete
                      id="rider"
                      freeSolo
                      value={citySelect}
                      onChange={(e, newValue) => {
                        if (newValue != null) {
                          setCitySelect(newValue);
                          setTourComplete([]);
                          console.log(citySelect.id);
                          console.log(countrySelect.id);
                          tourListApi.map((elem, idx) => {
                            if (
                              elem.country_id == countrySelect.id &&
                              elem.city_id == newValue.id
                            ) {
                              setTourComplete((oldTour) => [...oldTour, elem]);
                            }
                          });
                        }
                      }}
                      onInputChange={(e, newInputValue) => {
                        if (newInputValue == "") setTourComplete([]);
                      }}
                      options={cityComplete}
                      getOptionLabel={(option) => `${option.name}`}
                      renderInput={(params) => (
                        <TextField {...params} label="CIUDAD" />
                      )}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={4}>
                  {tourComplete.length > 0 && (
                    <Autocomplete
                      id="search"
                      freeSolo
                      value={tourSelect}
                      onChange={(e, newValue) => {
                        if (newValue != null) handleTourSelect(newValue);
                      }}
                      options={tourComplete}
                      getOptionLabel={(option) =>
                        `${option.code} ${option.title} ${option.language_abbreviation}`
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="SELECCIONE TOUR" />
                      )}
                    />
                  )}
                </Grid>

                <Grid item xs={6} sm={2}>
                  <FormControl fullWidth>
                    <InputLabel style={{ color: "white" }}>EMPRESA</InputLabel>
                    <Select
                      value={serviceEnterprise}
                      onChange={(e) => setServiceEnterprise(e.target.value)}
                      label="EMPRESA"
                      name="serviceEnterprise"
                    >
                      <MenuItem value={false}>NO</MenuItem>
                      <MenuItem value={true}>SI</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={2}>
                  <FormControl fullWidth>
                    <InputLabel style={{ color: "white" }}>GUIA</InputLabel>
                    <Select
                      value={guide}
                      onChange={(e) => setGuide(e.target.value)}
                      label="GUIA"
                      name="guide"
                      disabled={enableGuide}
                    >
                      <MenuItem value={"SI"}>SI</MenuItem>
                      <MenuItem value={"NO"}>NO</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={2}>
                  <FormControl fullWidth>
                    <InputLabel style={{ color: "white" }}>SERVICIO</InputLabel>
                    <Select
                      value={service}
                      onChange={handleServiceSelect}
                      label={"SERVICIO"}
                      name="service"
                    >
                      {ServiceList.map((elem, index) => (
                        <MenuItem key={index} value={elem.id}>
                          {elem.service}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    value={persons}
                    fullWidth
                    variant="outlined"
                    type="number"
                    onChange={(e) => setPersons(e.target.value)}
                    label={"PERSONAS"}
                    InputLabelProps={{ style: { color: "white" } }}
                    name="persons"
                  />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"es"}
                  >
                    <MobileDatePicker
                      value={date}
                      label="FECHA"
                      inputFormat="DD/MM/YYYY"
                      onChange={(newValue) => {
                        console.log(newValue);
                        setDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                {service == 2 && (
                  <Grid item xs={6} sm={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        label="HORA"
                        value={time}
                        name="time"
                        onChange={(newValue) => {
                          console.log(newValue);
                          setTime(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                )}

                {(service == 0 || service == 1) && (
                  <Grid item xs={6} sm={3}>
                    <FormControl fullWidth>
                      <InputLabel style={{ color: "white" }}>
                        HORARIOS
                      </InputLabel>
                      <Select
                        value={horary}
                        onChange={(e) => setHorary(e.target.value)}
                        label="HORARIOS"
                        name="horary"
                      >
                        {horaryComplete.map((item, idx) => (
                          <MenuItem key={idx} value={item.description}>
                            {item.name}-{item.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={4} sm={2}>
                  <FormControl fullWidth>
                    <InputLabel style={{ color: "white" }}>DIVISA</InputLabel>
                    <Select
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                      label="DIVISA"
                      name="currency"
                    >
                      <MenuItem value={"PEN"}>PEN</MenuItem>
                      <MenuItem value={"USD"}>USD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4} sm={2}>
                  <TextField
                    value={total}
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(e) => setTotal(e.target.value)}
                    label={"TOTAL"}
                    InputLabelProps={{ style: { color: "white" } }}
                    name="total"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleAddRoute}
                      >
                        Agregar Intermedios
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={origin}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setOrigin(e.target.value)}
                        label={"ORIGEN"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="origin"
                        inputProps={{
                          style: { textTransform: "uppercase" },
                        }}
                      />
                    </Grid>

                    {intermediate.map((elem, idx) => {
                      return (
                        <Grid item xs={12} sm={6} key={idx}>
                          <TextField
                            value={elem.route ? elem.route : routeInt}
                            fullWidth
                            variant="outlined"
                            type="text"
                            onChange={(e) => {
                              setRouteInt(e.target.value);
                              addRouteIntermediate(e.target.value, idx);
                            }}
                            label={`${idx + 1} INTERMEDIO`}
                            InputLabelProps={{ style: { color: "white" } }}
                            name="route"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </Grid>
                      );
                    })}

                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={destination}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setDestination(e.target.value)}
                        label={"DESTINO"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="destination"
                        inputProps={{
                          style: { textTransform: "uppercase" },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    value={note}
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(e) => setNote(e.target.value)}
                    label={"NOTAS"}
                    InputLabelProps={{ style: { color: "white" } }}
                    name="note"
                    inputProps={{
                      style: { textTransform: "uppercase" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={noteAdmin}
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(e) => setNoteAdmin(e.target.value)}
                    label={"NOTAS ADMIN"}
                    InputLabelProps={{ style: { color: "white" } }}
                    name="noteAdmin"
                    inputProps={{
                      style: { textTransform: "uppercase" },
                    }}
                  />
                </Grid>
                <Grid item xs={0} sm={8}></Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="success"
                    onClick={handleReservationCreate}
                    disabled={isLoading}
                  >
                    GUARDAR
                  </Button>
                </Grid>
              </Grid>

              <SnackBar
                stateSnk={stateSnk}
                vertical={vertical}
                horizontal={horizontal}
                duration={4000}
                handleClose={handleSnkClose}
                severity={severity}
                messageSnk={messageSnk}
              />
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default BookingCreateScreen;
