import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  CssBaseline,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Formik } from "formik";
import * as yup from "yup";

import Header from "../../../../../components/header/Header";
import { tokens } from "../../../../../theme";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import { getDriverAndVehicle } from "../../../../../api/users/driver/driver";
import {
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../../../helpers/token/refreshToken";
import { getEnterpriseEmployeeAddress } from "../../../../../api/enterprise/address";
import { updateBookingBusinessCredit } from "../../../../../api/booking/bookingBusiness";
import { getBookingBusinessByID } from "../../../../../api/booking/bookingBusiness";
import Topbar from "../../../../../components/topbar/TopBar";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../components/sidebar/Sidebar";

const UpdateBookingCredit = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [driver, setDriver] = useState(null);

  const [commissionOption, setCommissionOption] = useState(true);
  const [commissionValue, setCommissionValue] = useState(0);
  // lista de direcciones
  const [addressApi, setAddressApi] = useState([]);
  const [addressOrigin, setAddressOrigin] = useState(null);
  const [addressDestination, setAddressDestination] = useState(null);

  useEffect(() => {
    //buscar
    const searchBooking = async () => {
      try {
        console.log(id);
        const response = await getBookingBusinessByID("token", id);
        console.log(response.data);
        setAddressOrigin({
          name: response.data[0].origin_name,
          latitude: response.data[0].origin_lat,
          longitude: response.data[0].origin_lng,
        });
        setAddressDestination({
          name: response.data[0].destination_name,
          latitude: response.data[0].destination_lat,
          longitude: response.data[0].destination_lng,
        });
        setDriver({
          driver_id: response.data[0].drivers_id,
          name: response.data[0].driver_name,
          lastname: response.data[0].driver_lastname,
          vehicle_id: response.data[0].vehicles_id,
          brand: response.data[0].vehicle_brand,
          model: response.data[0].vehicle_model,
          bodywork: response.data[0].vehicle_bodywork,
          plate: response.data[0].vehicle_plate,
          color: response.data[0].vehicle_color,
          percentageValue: response.data[0].payment_commission,
          phone: response.data[0].phone,
        });
        response.data[0].clientName = `${response.data[0].client_name} ${response.data[0].client_lastname}`;
        setBooking(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    searchBooking();
  }, []);

  useEffect(() => {
    const driversData = async () => {
      const verify = verifyExpireTokenRefresh();
      if (verify) return console.log("no token");

      const token = getTokenRefresh();
      try {
        const response = await getDriverAndVehicle(token);
        console.log(response?.data);
        setDrivers(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    driversData();
  }, []);

  useEffect(() => {
    if (booking) {
      const getAddressEnterprise = async () => {
        try {
          const response = await getEnterpriseEmployeeAddress(
            "",
            booking.client_id,
            booking.enterprise_id
          );
          setAddressApi([]);
          Object.values(response.data).map((address) => {
            const _address = {
              id: address.id,
              latitude: address.latitude,
              longitude: address.longitude,
              name: address.name,
            };
            setAddressApi((oldData) => [...oldData, _address]);
          });
        } catch (error) {
          console.log(error);
        }
      };
      getAddressEnterprise();
    }
  }, [booking]);

  //traer el pedido desde mysql
  //traer lista de driver desde mysql
  //actualizar los pedidos
  //regresar a lista de creditos
  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleMouseDownPassword = (e) => e.preventDefault();

  const checkoutSchema = yup.object().shape({});

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    try {
      const origin = {
        name: addressOrigin.name,
        lat: addressOrigin.latitude,
        lng: addressOrigin.longitude,
      };
      const destination = {
        name: addressDestination.name,
        lat: addressDestination.latitude,
        lng: addressDestination.longitude,
      };
      values.driver = driver;
      values.origin = origin;
      values.destination = destination;
      /*
        SI: ESTA ACTIVADO EL TEXTFIELD DE COMISION
          TOMAR Y ACTUALIZAR ESA COMISION
        NO:
          SI CAMBIA DE DRIVER
            VERIFICAR SU COMISION
          NO
            TOMAR SU MISMA COMISION
      */
      const updateCommission = !commissionOption
        ? values.payment_commission
        : driver.percentageValue;

      const bookingBusiness = {
        driver,
        origin: values.origin,
        destination: values.destination,
        date: values.date,
        time: values.time,
        persons: values.persons,
        notes: values.notes,
        booking_type: values.payment_type,
        currency: values.payment_currency,
        tax: values.payment_tax,
        toll: values.payment_toll,
        price: values.payment_price_driver,
        total: values.payment_price_total,
        discount: 0,
        commission: updateCommission,
        counterBooking: values.order_number,
      };

      await updateBookingBusinessCredit("token", bookingBusiness);
      snackBarDialog("RESERVA ACTUALIZADA");
      navigate("/admin/history/booking-credit");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      snackBarDialog("ERROR: NO SE PUDO ACTUALIZAR ESTA RESERVA", "error");
    }
  };

  return (
    <div>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="PEDIDO CREDITO: Editar" subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 1">
                {booking && (
                  <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={booking}
                    validationSchema={checkoutSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Box backgroundColor={colors.primary[400]} p={1}>
                          <Grid container spacing={1} p={1}>
                            <Grid item xs={12} sm={6}>
                              {addressApi ? (
                                <Autocomplete
                                  value={addressOrigin}
                                  disablePortal
                                  name="client"
                                  id="combo-box-origin"
                                  onChange={(e, newValue) => {
                                    setAddressOrigin(newValue);
                                  }}
                                  options={addressApi}
                                  getOptionLabel={(option) => `${option.name}`}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Origen" />
                                  )}
                                />
                              ) : (
                                <TextField
                                  fullWidth
                                  disabled
                                  type="text"
                                  label={t("Lista de Direcciones")}
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {addressApi ? (
                                <Autocomplete
                                  value={addressDestination}
                                  disablePortal
                                  name="client"
                                  id="combo-box-origin"
                                  onChange={(e, newValue) => {
                                    setAddressDestination(newValue);
                                  }}
                                  options={addressApi}
                                  getOptionLabel={(option) => `${option.name}`}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Destino" />
                                  )}
                                />
                              ) : (
                                <TextField
                                  fullWidth
                                  disabled
                                  type="text"
                                  label={t("Lista de Direcciones")}
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                value={values.clientName}
                                fullWidth
                                disabled
                                variant="outlined"
                                color="success"
                                type="text"
                                label={t("client")}
                                name="client"
                              />
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Grid container spacing={1}>
                                <Grid item xs={6} sm={3} md={3}>
                                  <TextField
                                    value={values.date}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="text"
                                    label={t("date")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="date"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                  <TextField
                                    value={values.time}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="text"
                                    label={t("time")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="time"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                  <TextField
                                    value={values.persons}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="text"
                                    label={t("persons")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="persons"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                  <TextField
                                    value={values.vehicle_bodywork}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="text"
                                    label={t("Modelo")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="vehicle_bodywork"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                value={values.notes}
                                name="notes"
                                fullWidth
                                variant="outlined"
                                color="success"
                                type="text"
                                label={t("notes")}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {drivers && (
                                <Autocomplete
                                  value={driver}
                                  disablePortal
                                  id="combo-box-driver"
                                  onChange={(e, newValue) => {
                                    setDriver(newValue);
                                  }}
                                  options={drivers}
                                  getOptionLabel={(option) =>
                                    `${option.name} ${option.lastname} ${option.brand} ${option.model} ${option.bodywork} ${option.plate} ${option.color}`
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t("driver")}
                                    />
                                  )}
                                />
                              )}
                            </Grid>

                            <Grid item xs={12} sm={3} md={3}>
                              <Grid container spacing={1}>
                                <Grid item xs={6} sm={12} md={12}>
                                  <FormControl fullWidth>
                                    <InputLabel>{t("Tipo Pago")}</InputLabel>
                                    <Select
                                      value={values.payment_type}
                                      id="payment_type"
                                      name="payment_type"
                                      label={t("payment")}
                                      onChange={handleChange}
                                    >
                                      <MenuItem value={1}>
                                        {t("EFECTIVO")}
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        {t("CREDITO")}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={12} md={12}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel>COMISION</InputLabel>
                                    <OutlinedInput
                                      value={values.payment_commission}
                                      name="payment_commission"
                                      onChange={handleChange}
                                      disabled={commissionOption}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                            onClick={(e) => {
                                              setCommissionOption(
                                                !commissionOption
                                              );
                                            }}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      label="COMISION"
                                      aria-describedby="outlined-helper-text"
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={3} md={3}>
                              <Grid container spacing={1}>
                                <Grid item xs={6} sm={12} md={12}>
                                  <FormControl fullWidth>
                                    <InputLabel>{t("currency")}</InputLabel>
                                    <Select
                                      value={values.payment_currency}
                                      id="payment_currency"
                                      name="payment_currency"
                                      label={t("currency")}
                                      onChange={handleChange}
                                    >
                                      <MenuItem value={1}>{t("PEN")}</MenuItem>
                                      <MenuItem value={2}>{t("USD")}</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={3} md={3}>
                              <Grid container spacing={1}>
                                <Grid item xs={6} sm={12} md={12}>
                                  <TextField
                                    value={values.payment_tax}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label={t("IMPUESTO")}
                                    onChange={handleChange}
                                    name="payment_tax"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={12} md={12}>
                                  <TextField
                                    value={values.payment_toll}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label={t("PEAJE")}
                                    onChange={handleChange}
                                    name="payment_toll"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={3} md={3}>
                              <Grid container spacing={1}>
                                <Grid item xs={6} sm={12} md={12}>
                                  <TextField
                                    value={values.payment_price_driver}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label={t("P CONDUCTOR")}
                                    onChange={handleChange}
                                    name="payment_price_driver"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={12} md={12}>
                                  <TextField
                                    value={values.payment_price_total}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label={t("TOTAL")}
                                    onChange={handleChange}
                                    name="payment_price_total"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={0} sm={9}></Grid>
                            <Grid item xs={12} sm={3} md={3}>
                              <Button
                                fullWidth
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{ height: 45 }}
                              >
                                {"ACTUALIZAR"}
                              </Button>
                            </Grid>
                          </Grid>
                          <SnackBar
                            stateSnk={stateSnk}
                            vertical={vertical}
                            horizontal={horizontal}
                            duration={4000}
                            handleClose={handleSnkClose}
                            severity={severity}
                            messageSnk={messageSnk}
                          />
                        </Box>
                      </form>
                    )}
                  </Formik>
                )}
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};

export default UpdateBookingCredit;
