import { Box, useTheme } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../../../../theme";

const GridDataTable = ({
  data,
  columns,
  checkboxSelection = true,
  setDataSelected,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Box
        m="0px 0 0 0"
        height="100vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          checkboxSelection={checkboxSelection}
          onSelectionModelChange={(ids) => {
            if (checkboxSelection) {
              setDataSelected([]);
              ids.map((id) => {
                const select = data.find((row) => row.id === id);
                setDataSelected((oldData) => [...oldData, select]);
              });
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default GridDataTable;
