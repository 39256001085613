import { baseBackend } from "../../base/baseUrl";

export const getPermissions = async (token, id) => {
  return await baseBackend.get(`/api/v1/resource/permissions/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createPermission = async (
  token,
  admin_id,
  role_id,
  permissions
) => {
  return await baseBackend.post(
    `/api/v1/resource/permissions/admin/${admin_id}/role/${role_id}`,
    JSON.stringify({ permissions }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updatePermission = async (token, permissions) => {
  return await baseBackend.put(
    `/api/v1/resource/permissions`,
    JSON.stringify({ permissions }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
