import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CssBaseline,
  Menu,
  MenuItem,
  Fade,
  Popper,
  Paper,
  Typography,
  Grid,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  TextField,
  Modal,
  Stack,
  IconButton,
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";

import Topbar from "../../../../../components/topbar/TopBar";
import Header from "../../../../../components/header/Header";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import { tokens } from "../../../../../theme";
import { getListEnterprisesInvoice } from "../../../../../api/enterprise/enterprise";

import {
  createInvoice,
  getInvoiceBusiness,
  payInvoice,
  searchBookingPerEnterprise,
  searchInvoiceBusiness,
  updateInvoice,
} from "../../../../../api/booking/Invoice";
import GridDataTable from "../DataTable/GridDataTable";
import { updateBookingInvoice } from "../../../../../api/booking/bookingBusiness";
import { dateFormat, timeFormat } from "../../../../../helpers/date/date";
import { convertDate } from "../../../../../helpers/date/convertDate";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../components/sidebar/Sidebar";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const InvoiceBooking = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const { t } = useTranslation();
  //VARIABLES
  const [enterprises, setEnterprises] = useState([]);
  const [enterprise, setEnterprise] = useState(null);
  //VARIABLE MODAL
  const [invoice, setInvoice] = useState("");
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [notes, setNotes] = useState("");
  const [igv, setIgv] = useState(1);

  const [peaje, setPeaje] = useState(0);
  const [igvVal, setIgvVal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [service, setService] = useState("PAGADO");

  const [booking, setBooking] = useState(null);
  const [invoiceData, setInvoiceData] = useState([]);
  const [UID, setUID] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);

  const ListIgv = {
    1: "INAFECTO",
    2: "AFECTO",
    3: "MAS IGV",
  };

  useEffect(() => {
    const getInvoice = async () => {
      const response = await getInvoiceBusiness("token");
      console.log(response.data);
      setInvoiceData([]);

      const compareByDate = (a, b) => {
        const dateB = convertDate(
          dateFormat(new Date(b.date_issue)),
          "00:00 AM"
        );
        const dateA = convertDate(
          dateFormat(new Date(a.date_issue)),
          "00:00 AM"
        );
        return dateB - dateA;
      };

      Object.values(response.data)
        .sort(compareByDate)
        .map((data, index) => {
          data.index = index + 1;
          data.invoice = data.invoice.toUpperCase();
          data.date_issue = dateFormat(new Date(data.date_issue));
          data.date_expire = dateFormat(new Date(data.date_expire));
          data.igv = ListIgv[data.igv];

          if (data.status === "ANULADO") {
            data.total = 0;
            data.subTotal = 0;
            data.subIgv = 0;
          } else {
            const total = Number(data.total);
            const toll = Number(data.toll);
            const fullPrice = total + toll;

            if (data.igv === "INAFECTO") {
              data.subTotal = 0;
              data.subIgv = 0;
            } else if (data.igv === "AFECTO") {
              const sub = Math.round((fullPrice / 1.18) * 100) / 100;
              data.subTotal = sub;
              data.subIgv = Math.round((fullPrice - sub) * 100) / 100;
            } else if (data.igv === "MAS IGV") {
              const imp = Math.round(fullPrice * 0.18 * 100) / 100;
              data.subIgv = imp;
              data.subTotal = data.total;
              data.total = Math.round((fullPrice + imp) * 100) / 100;
            }
          }
          setInvoiceData((oldData) => [...oldData, data]);
        });
    };
    getInvoice();
  }, []);
  //EMPRESAS
  useEffect(() => {
    const getEnterprises = async () => {
      try {
        const token = "";
        const response = await getListEnterprisesInvoice(token, 1);
        setEnterprises([]);
        setEnterprises(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEnterprises();
  }, []);

  const searchBookingByEnterprise = async () => {
    try {
      const response = await searchBookingPerEnterprise(
        enterprise.id,
        "null",
        "null"
      );
      setBooking([]);
      Object.values(response.data).map((_book) => {
        if (_book.invoice_id === null && _book.invoice === 0) {
          console.log(_book);
          setBooking((oldData) => [...oldData, _book]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };

  const [invoiceName, setInvoiceName] = useState("");

  const columns = [
    {
      headerName: "ITEM",
      field: "index",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      headerName: "FACTURA",
      field: "invoice",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "EMISION",
      field: "date_issue",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "VENCE",
      field: "date_expire",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "NOMBRE EMPRESA",
      field: "commertial_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 180,
    },
    {
      headerName: "DESCRIPCION",
      field: "notes",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
      renderCell: renderCellExpand,
    },
    {
      headerName: "IGV",
      field: "igv",
      width: 90,
    },
    {
      headerName: "SUBTOTAL",
      field: "subTotal",
      width: 80,
      align: "center",
    },
    {
      headerName: "IGV",
      field: "subIgv",
      width: 80,
      align: "center",
    },
    {
      headerName: "TOTAL",
      field: "total",
      width: 80,
      align: "center",
    },
    {
      headerName: "Estado",
      field: "status",
      width: 90,
      align: "center",
      renderCell: ({ row: { status } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              width: "100%",
              color: "white",
              textAlign: "center",
              background:
                status === "PAGADO"
                  ? "blue"
                  : status === "ANULADO"
                  ? "orange"
                  : "red",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {status}
          </div>
        );
      },
    },
    {
      headerName: "",
      field: "actions",
      width: 90,
      renderCell: ({ row: { id, invoice } }) => {
        return (
          <div>
            <Button
              color="secondary"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => {
                setUID(id);
                setAnchorEl(event.currentTarget);
              }}
            >
              OPCIONES
            </Button>
            <Menu
              id="menu-options"
              MenuListProps={{ "aria-labelledby": "fade-button" }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem
                onClick={async () => {
                  const token = "";
                  console.log(id);
                  setInvoiceName(invoice);
                  setOpenInvoiceModal(true);
                  searchInvoiceBusinessFun();
                  handleClose();
                }}
              >
                VER
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  const booking = {
                    id: UID,
                    discount: 0,
                    tax: 0,
                    toll: 0,
                    price_driver: 0,
                    price_total: 0,
                    commission: 0,
                    status: "PAGADO",
                    invoice: 1,
                    invoice_status: 0,
                    state_payment: "PAGADO",
                  };
                  try {
                    const token = "";
                    await payInvoice(token, booking);
                    snackBarDialog("FACTURA PAGADO");
                    handleClose();
                  } catch (error) {
                    snackBarDialog("ERROR NO SE ACTUALIZO LA FACTURA", "error");
                    console.log(error);
                    handleClose();
                  }
                }}
              >
                PAGADO
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  const booking = {
                    id: UID,
                    discount: 0,
                    tax: 0,
                    toll: 0,
                    price_driver: 0,
                    price_total: 0,
                    commission: 0,
                    status: "ANULADO",
                    invoice: 0,
                    invoice_status: 1,
                    state_payment: "PENDIENTE",
                  };
                  try {
                    const token = "";
                    await updateInvoice(token, booking);
                    snackBarDialog("FACTURA ANULADA");
                    handleClose();
                  } catch (error) {
                    snackBarDialog("ERROR NO SE PUDO FACTURA PEDIDO", "error");
                    console.log(error);
                    handleClose();
                  }
                }}
              >
                ANULAR
              </MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];

  const columnsInvoice = [
    {
      headerName: "Pedido",
      field: "order_number",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
      renderCell: ({ row: { invoice_status, order_number } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              color: "white",
              background: invoice_status === 1 ? "red" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {order_number}
          </div>
        );
      },
    },
    {
      headerName: "NOMBRE",
      field: "fullname_employee",
      width: 200,
    },
    {
      headerName: "FECHA",
      field: "date",
      width: 80,
    },
    {
      headerName: "ORIGEN",
      field: "origin_name",
      width: 250,
    },
    {
      headerName: "DESTINO",
      field: "destination_name",
      width: 250,
    },
    {
      headerName: "PEAJE",
      field: "toll",
      width: 100,
    },
    {
      headerName: "TOTAL",
      field: "price_total",
      width: 100,
    },
  ];

  const subInvoiceColums = [
    {
      headerName: "FECHA",
      field: "date",
      width: 80,
    },
    {
      headerName: "Pedido",
      field: "order_number",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
      renderCell: ({ row: { invoice_status, order_number } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              color: "white",
              background: invoice_status === 1 ? "red" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {order_number}
          </div>
        );
      },
    },
    {
      headerName: "NOMBRE",
      field: "fullname_employee",
      width: 200,
      renderCell: ({ row: { employee_name, employee_lastname } }) => {
        return (
          <div>
            {employee_name} {employee_lastname}
          </div>
        );
      },
    },

    {
      headerName: "ORIGEN",
      field: "origin_name",
      width: 250,
    },
    {
      headerName: "DESTINO",
      field: "destination_name",
      width: 250,
    },
    {
      headerName: "PEAJE",
      field: "toll",
      width: 100,
    },
    {
      headerName: "TOTAL",
      field: "price_total",
      width: 100,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };
  const handleCloseSnk = () => setStateSnk(false);

  const [dataSelected, setDataSelected] = useState([]);
  const [counterTotal, setCounterTotal] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setSubTotal(0);
    setPeaje(0);
    setCounterTotal(0);
    setOpenModal(false);
  };

  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const handleOpenInvoiceModal = () => setOpenInvoiceModal(true);
  const handleCloseInvoiceModal = () => setOpenInvoiceModal(false);

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #000",
    boxShadow: 20,
    width: "100%",
    p: 2,
    overflow: "scroll",
  };
  const styleInvoiceModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #000",
    boxShadow: 20,
    width: 800,
    p: 2,
    overflow: "scroll",
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (dataSelected) {
      setPeaje(0);
      setIgvVal(0);
      setSubTotal(0);
      let sumToll = 0;
      let sumTotal = 0;
      Object.values(dataSelected).map((data) => {
        sumToll = Number(sumToll) + Number(data.toll);
        sumTotal = Number(sumTotal) + Number(data.price_total);
      });

      if (igv === 1) {
        setPeaje(sumToll);
        setCounterTotal(Math.round((sumTotal + sumToll) * 10) / 10);
      } else if (igv === 2) {
        setPeaje(sumToll);
        const sub = Math.round((sumTotal / 1.18) * 100) / 100;
        setSubTotal(sub);
        setIgvVal(Math.round((sumTotal - sub) * 100) / 100);
        setCounterTotal(Math.round((sumTotal + sumToll) * 10) / 10);
      } else if (igv === 3) {
        setPeaje(sumToll);
        const imp = Math.round((sumTotal + sumToll) * 0.18 * 100) / 100;
        setIgvVal(imp);
        setSubTotal(sumTotal + sumToll);
        setCounterTotal(Math.round((sumTotal + sumToll + imp) * 10) / 10);
      }
    }
  }, [dataSelected, igv]);

  const [invoiceList, setInvoiceList] = useState(null);

  const searchInvoiceBusinessFun = async () => {
    try {
      console.log(UID);
      const response = await searchInvoiceBusiness("token", UID);
      console.log(response.data);
      setInvoiceList([]);
      Object.values(response.data).map((_book, index) => {
        _book.index = index + 1;
        _book.id = _book.order_number;
        setInvoiceList((oldData) => [...oldData, _book]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createInvoiceEnterprise = async () => {
    //SI ESTA PAGADO ACTUALIZAR state_payment a pagado
    try {
      setIsLoading(true);
      if (invoice.length > 1) {
        const invoiceObject = {
          invoice,
          igv,
          date_issue: dateStart,
          date_expire: dateEnd,
          notes: notes.toUpperCase(),
          status: service,
        };
        const invoice_id = await createInvoice("token", invoiceObject);

        const promises = Object.values(dataSelected).map(async (data) => {
          const keys = {
            booking_id: data.id,
            invoice_id: invoice_id.data,
          };
          await updateBookingInvoice("token", keys);
        });

        await Promise.all(promises);
        snackBarDialog("FACTURA CREADA");
        handleCloseDialog();
        handleCloseModal();
        setInvoice("");
      } else {
        snackBarDialog("INGRESE EL NUMERO DE FACTURA");
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      snackBarDialog("ERROR NO SE CREO LA FACTURA");
      handleCloseDialog();
      handleCloseModal();
      console.log(error);
    }
  };

  return (
    <div>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="FACTURAS DE EMPRESA" subtitle="" />
            </Box>
            <Box backgroundColor={colors.primary[400]} p={1}>
              <Grid container spacing={1} sx={{ alignItems: "center" }}>
                <Grid item xs={12} sm={8} md={8}>
                  <Autocomplete
                    value={enterprise}
                    disablePortal
                    name="employee"
                    id="combo-box-employee"
                    onChange={(e, newValue) => {
                      setEnterprise(newValue);
                    }}
                    options={enterprises}
                    getOptionLabel={(option) =>
                      `${option.commertial_name} - ${option.ruc} - ${option.business_name}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("EMPRESAS")} />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{ height: 45 }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!enterprise)
                        return snackBarDialog("SELECCIONE LA EMPRESA", "error");

                      handleOpenModal();
                      searchBookingByEnterprise();
                    }}
                  >
                    FACTURAR
                  </Button>
                </Grid>
                {invoiceData && (
                  <Grid item xs={12} sm={12} md={12}>
                    {invoiceData && (
                      <GridDataTable
                        data={invoiceData}
                        columns={columns}
                        checkboxSelection={false}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </Box>
            {/* MODAL DE FACTURAS */}
            <Modal open={openModal} onClose={handleCloseModal}>
              <Box
                height={"100%"}
                sx={styleModal}
                backgroundColor={colors.primary[400]}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="h5" component="h2">
                      FACTURA PARA:{""}
                      {enterprise ? enterprise.commertial_name : ""}
                      {" - "}
                      {enterprise ? enterprise.ruc : ""}
                      {" - "}
                      {enterprise ? enterprise.email : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h5" component="h2">
                      TIPO: {enterprise ? enterprise.payment_type : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={2}>
                    <TextField
                      value={invoice}
                      fullWidth
                      variant="outlined"
                      color="success"
                      type="text"
                      label={"NRO FACTURA"}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setInvoice(e.target.value);
                      }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      name="invoice"
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={2}>
                    <FormControl fullWidth>
                      <InputLabel id="select-report">IGV</InputLabel>
                      <Select
                        fullWidth
                        labelId="select-report"
                        id="typeReport"
                        value={igv}
                        onChange={(e) => setIgv(e.target.value)}
                        label="IGV"
                        name="igv"
                      >
                        <MenuItem value={1}>INAFECTO</MenuItem>
                        <MenuItem value={2}>AFECTO</MenuItem>
                        <MenuItem value={3}>MAS IGV</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6} md={2}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={es}
                    >
                      <Stack spacing={2}>
                        <MobileDatePicker
                          value={dateStart}
                          label={"EMISION"}
                          inputFormat="dd/MM/yyyy"
                          name="dateStart"
                          onChange={(e) => {
                            setDateStart(e);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} sm={6} md={2}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={es}
                    >
                      <Stack spacing={2}>
                        <MobileDatePicker
                          value={dateEnd}
                          label={"VENCIMIENTO"}
                          inputFormat="dd/MM/yyyy"
                          name="dateEnd"
                          onChange={(e) => {
                            setDateEnd(e);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={3} md={1}>
                    <TextField
                      value={peaje}
                      fullWidth
                      variant="outlined"
                      color="success"
                      type="text"
                      label={"PEAJE"}
                      name="notes"
                    />
                  </Grid>

                  <Grid item xs={3} md={1}>
                    <TextField
                      value={subTotal}
                      fullWidth
                      variant="outlined"
                      color="success"
                      type="text"
                      label={"SUBTOTAL"}
                      name="notes"
                    />
                  </Grid>

                  <Grid item xs={3} md={1}>
                    <TextField
                      value={igvVal}
                      fullWidth
                      variant="outlined"
                      color="success"
                      type="text"
                      label={"IGV"}
                      name="notes"
                    />
                  </Grid>

                  <Grid item xs={3} md={1}>
                    <TextField
                      value={counterTotal}
                      fullWidth
                      variant="outlined"
                      color="success"
                      type="text"
                      label={"TOTAL"}
                      name="notes"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      value={notes}
                      fullWidth
                      variant="outlined"
                      color="success"
                      type="text"
                      label={"DESCRIPCION"}
                      onChange={(e) => setNotes(e.target.value)}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      name="notes"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="select-report">SERVICIO</InputLabel>
                      <Select
                        fullWidth
                        sx={{ height: 45 }}
                        labelId="select-report"
                        id="typeReport"
                        value={service}
                        onChange={(e) => setService(e.target.value)}
                        label="SERVICIO"
                        name="service"
                      >
                        <MenuItem value={"PAGADO"}>PAGADO</MenuItem>
                        <MenuItem value={"NO PAGADO"}>NO PAGADO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      sx={{ height: 45 }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenDialog();
                      }}
                    >
                      GENERAR FACTURA
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="error"
                      sx={{ height: 45 }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleCloseModal();
                      }}
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    {booking && (
                      <GridDataTable
                        data={booking}
                        checkboxSelection={true}
                        columns={columnsInvoice}
                        setDataSelected={setDataSelected}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* MODAL DE CONFIRMAR FACTURA */}
            <Modal open={openDialog} onClose={handleCloseDialog}>
              <Box sx={style} backgroundColor={colors.primary[400]}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    FACTURA: {service}
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      sx={{ height: 45 }}
                      disabled={isLoading}
                      onClick={(e) => {
                        e.preventDefault();
                        createInvoiceEnterprise();
                      }}
                    >
                      CONFIRMAR
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="error"
                      sx={{ height: 45 }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleCloseDialog();
                      }}
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* LISTA DE PEDIDOS DE UNA FACTURA */}
            <Modal open={openInvoiceModal} onClose={handleCloseInvoiceModal}>
              <Box
                height={"70%"}
                sx={styleInvoiceModal}
                backgroundColor={colors.primary[400]}
              >
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                    <Typography variant="h5" component="h2">
                      FACTURA : {invoiceName}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} textAlign="end">
                    <IconButton
                      variant="contained"
                      color="error"
                      sx={{ height: 45 }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleCloseInvoiceModal();
                      }}
                    >
                      <HighlightOffOutlinedIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    {invoiceList && (
                      <GridDataTable
                        data={invoiceList}
                        checkboxSelection={false}
                        columns={subInvoiceColums}
                        setDataSelected={setDataSelected}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleCloseSnk}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </main>
      </div>
    </div>
  );
};

export default InvoiceBooking;
