export const tourList = [
  { id: 0, name: "SELECCIONA EL TIPO DE TOUR" },
  { id: 1, name: "TOUR" },
  { id: 2, name: "TRASLADO" },
  { id: 3, name: "PAQUETE" },
];

export const tourTypeList = {
  1: "TOUR",
  2: "TRASLADO",
  3: "PAQUETE",
};
