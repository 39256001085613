import * as React from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { useNavigate } from "react-router-dom";
import { Typography, useTheme, MenuList, Badge } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import "react-pro-sidebar/dist/css/styles.css";
import { IconButton } from "@mui/material";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { decodeAdminToken, getToken } from "../../helpers/token/refreshToken";

import { ref, onValue } from "firebase/database";
import { db } from "../../firebase";
import { compareDate } from "../../helpers/date/compareDate";
import logo from "../../assets/logo.png";

const DrawerUI = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [state, setState] = React.useState({
    left: false,
  });
  const navigate = useNavigate();
  const adttkn = getToken("adttkn");
  const { name, lastname } = decodeAdminToken(adttkn);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, ["left"]: open });
  };

  const redirectTo = (to) => (event) => {
    navigate(to);
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": { borderBottom: 0 },
    "&:before": { display: "none" },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": { marginLeft: theme.spacing(1) },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0),
    borderTop: "0px solid rgba(0, 0, 0, .125)",
  }));

  const listMain = [
    {
      title: "Clientes",
      subList: [
        { title: "Clientes", url: "/admin/list-clients" },
        { title: "Empresas", url: "/admin/list-enterprise" },
      ],
    },
    {
      title: "Conductores",
      subList: [
        { title: "Lista Conductores", url: "/admin/list-driver" },
        { title: "Nuevos Conductores", url: "/admin/list-driver-new" },
        { title: "Lista Vehiculos", url: "/admin/list-vehicle" },
        { title: "Crear Conductor", url: "/admin/add-driver" },
        { title: "Crear Vehiculo", url: "/admin/add-vehicle" },
      ],
    },
    {
      title: "Pedidos",
      subList: [
        { title: "Pedidos Cliente", url: "/admin/list-book-now" },
        { title: "Pedidos Empresa", url: "/admin/list-booking-business" },
        { title: "Cotizacion Cliente", url: "/admin/list-book-quote" },
        { title: "Cotizacion Empresa", url: "/admin/list-book-business-quote" },
        { title: "Cotizacion Tour", url: "/admin/list-tour-quote" },
        { title: "Reservas Clientes", url: "/admin/list-book-later" },
        { title: "Reservas Empresas", url: "/admin/list-book-business-later" },
        { title: "CREDITO", url: "/admin/history/booking-credit" },
      ],
    },
    {
      title: "Historial",
      subList: [
        { title: "Pedidos Clientes", url: "/admin/history/booking-now" },
        { title: "Pedidos Empresas", url: "/admin/history/booking-business" },
        { title: "FACTURA", url: "/admin/history/booking-invoice" },
        { title: "OPENPAY", url: "/admin/history/openpay" },
      ],
    },
    {
      title: "Tarifas",
      subList: [
        { title: "Lista de Direcciones", url: "/admin/address" },
        { title: "Rutas Globales", url: "/admin/rutas-global" },
      ],
    },
  ];

  const backupList = [
    {
      title: "BACKUP",
      subList: [
        { title: "Clientes", url: "/admin/backup-clientes" },
        { title: "Empresas", url: "/admin/backup-empresas" },
        { title: "Antiguos", url: "/admin/backup-old" },
      ],
    },
  ];
  const routeTour = [
    {
      title: "PAGINA TOUR",
      subList: [
        {
          title: "DATOS FOOTER",
          subList: [{ title: "CREAR", url: "/admin/footer/crear" }],
        },
        {
          title: "TOUR",
          subList: [
            { title: "LISTA", url: "/admin/tour/lista" },
            { title: "CREAR", url: "/admin/tour/crear" },
          ],
        },
        {
          title: "SHOPPING",
          subList: [{ title: "LISTA", url: "/admin/shopping/lista" }],
        },
        {
          title: "RESERVAS",
          subList: [
            { title: "CREAR", url: "/admin/reservas/crear" },
            { title: "LISTA", url: "/admin/reservas/lista" },
            { title: "FINALIZADOS", url: "/admin/reservas/finalizados" },
          ],
        },
        {
          title: "PROGRAMADOS",
          subList: [
            { title: "CREAR", url: "/admin/programados/crear" },
            { title: "LISTA", url: "/admin/programados/lista" },
            { title: "CONFIRMADOS", url: "/admin/programados/confirmados" },
          ],
        },
        {
          title: "PAIS",
          subList: [
            { title: "CREAR", url: "/admin/pais/crear" },
            { title: "LISTA", url: "/admin/pais/lista" },
          ],
        },
        {
          title: "IMAGENES",
          subList: [
            { title: "CREAR", url: "/admin/imagen/crear" },
            { title: "LISTA", url: "/admin/imagen/lista" },
          ],
        },
        {
          title: "RECURSOS",
          subList: [
            { title: "CIUDAD", url: "/admin/ciudad/crear" },
            { title: "PREGUNTAS", url: "/admin/preguntas/crear" },
          ],
        },
      ],
    },
  ];

  const [bookCount, setBookCount] = React.useState(0);
  const [bookingLaterCounter, setBookingLaterCounter] = React.useState(0);
  const [quoteBookingLaterCounter, setQuoteBookingLaterCounter] =
    React.useState(0);

  const [bookingBusinessLater, setBookingBusinessLater] = React.useState(0);
  const [businessNowCounter, setBusinessLaterCounter] = React.useState(0);
  const [bookingR, setBookingR] = React.useState(0);

  React.useEffect(() => {
    const startRef = ref(db, `bookingNow`);
    onValue(startRef, (snapshot) => {
      let data = snapshot.val();
      if (data === null) {
        setBookingLaterCounter(0);
        setBookCount(0);
        setBookingR(0);
      } else {
        let counter = 0;
        let count = 0;
        let countR = 0;
        Object.values(data).map((book) => {
          if (
            book.booking_type === 1 &&
            !book.cotizar &&
            book.statusBooking === "PENDIENTE"
          ) {
            setBookCount(++counter);
          }
          if (
            book.booking_type != 1 &&
            book.statusBooking === "PENDIENTE" &&
            !book.cotizado
          ) {
            setBookingLaterCounter(++count);
          }

          if (book.booking_type != 1 && book.cotizado) {
            if (compareDate(book.date, 7)) {
              setBookingR(++countR);
            }
          }
        });
      }
    });
  }, []);

  React.useEffect(() => {
    const startRef = ref(db, `BookingBusiness`);
    onValue(startRef, (snapshot) => {
      let data = snapshot.val();
      if (data === null) {
        setBusinessLaterCounter(0);
      } else {
        let counter = 0;
        let quote = 0;
        let booking = 0;
        Object.values(data).map((book, index) => {
          if (
            book.booking_type === 1 &&
            book.statusBooking === "PENDIENTE" &&
            !book.cotizar
          ) {
            setBusinessLaterCounter(++counter);
          }
          if (
            book.booking_type != 1 &&
            book.statusBooking === "PENDIENTE" &&
            !book.cotizar
          ) {
            setQuoteBookingLaterCounter(++quote);
          }

          if (book.booking_type != 1 && book.cotizar) {
            if (compareDate(book.date, 7)) {
              setBookingBusinessLater(++booking);
            }
          }
        });
      }
    });
  }, []);

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <Box mt={2} mb={1}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            alt="profile-user"
            width="60px"
            height="60px"
            src={logo}
            style={{ cursor: "pointer", borderRadius: "50%" }}
          />
        </Box>
        <Box textAlign="center">
          <Typography
            variant="h3"
            color={colors.grey[100]}
            fontWeight="bold"
            sx={{ m: "2px 0 0 0" }}
          >
            {`${name.split(" ")[0]} ${lastname.split(" ")[0]}`}
          </Typography>
        </Box>
      </Box>
      <List>
        <ListItemButton onClick={redirectTo("/admin")}>
          <HomeOutlinedIcon />
          <ListItemText primary="INICIO" sx={{ marginLeft: 2 }} />
        </ListItemButton>
        <ListItemButton onClick={redirectTo("/admin/create-booking")}>
          <ListAltOutlinedIcon />
          <ListItemText primary="CREAR PEDIDO" sx={{ marginLeft: 2 }} />
        </ListItemButton>

        {listMain.map((list, index) => (
          <Accordion key={index} sx={{ background: colors.primary[400] }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="booking-content"
              id="booking-header"
            >
              <Typography width={"90%"} variant="h6" color={colors.grey[300]}>
                {list.title}
              </Typography>
              {list.title === "Pedidos" && (
                <Badge
                  variant="dot"
                  invisible={
                    bookCount +
                      businessNowCounter +
                      bookingLaterCounter +
                      quoteBookingLaterCounter +
                      bookingBusinessLater +
                      bookingR ===
                    0
                      ? true
                      : false
                  }
                  color="error"
                />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <MenuList>
                {list.subList.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemButton
                      onClick={redirectTo(item.url)}
                      sx={{ marginTop: -2 }}
                    >
                      <InboxIcon />
                      <ListItemText
                        primary={item.title}
                        sx={{ marginLeft: 2 }}
                      />
                      {item.title === "Pedidos Cliente" && (
                        <Badge
                          badgeContent={bookCount}
                          color="error"
                          max={99}
                        />
                      )}
                      {item.title === "Pedidos Empresa" && (
                        <Badge
                          badgeContent={businessNowCounter}
                          color="error"
                          max={99}
                        />
                      )}
                      {item.title === "Cotizacion Cliente" && (
                        <Badge
                          badgeContent={bookingLaterCounter}
                          color="error"
                          max={99}
                        />
                      )}
                      {item.title === "Cotizacion Empresa" && (
                        <Badge
                          badgeContent={quoteBookingLaterCounter}
                          color="error"
                          max={99}
                        />
                      )}
                      {item.title === "Reservas Clientes" && (
                        <Badge badgeContent={bookingR} color="error" max={99} />
                      )}
                      {item.title === "Reservas Empresas" && (
                        <Badge
                          badgeContent={bookingBusinessLater}
                          color="error"
                          max={99}
                        />
                      )}
                    </ListItemButton>
                  </ListItem>
                ))}
              </MenuList>
            </AccordionDetails>
          </Accordion>
        ))}
        <ListItemButton onClick={redirectTo("/admin/tour")}>
          <ListAltOutlinedIcon />
          <ListItemText primary="TOUR" sx={{ marginLeft: 2 }} />
        </ListItemButton>
        <ListItemButton onClick={redirectTo("/admin/recharge")}>
          <ListAltOutlinedIcon />
          <ListItemText primary="RECARGAS" sx={{ marginLeft: 2 }} />
        </ListItemButton>
        <ListItemButton onClick={redirectTo("/admin/bonus")}>
          <ListAltOutlinedIcon />
          <ListItemText primary="BONUS" sx={{ marginLeft: 2 }} />
        </ListItemButton>
        <ListItemButton onClick={redirectTo("/admin/list-admin")}>
          <AdminPanelSettingsIcon />
          <ListItemText primary="LISTA ADMIN" sx={{ marginLeft: 2 }} />
        </ListItemButton>
        <ListItemButton onClick={redirectTo("/admin/notification")}>
          <AdminPanelSettingsIcon />
          <ListItemText primary="NOTIFICACIONES" sx={{ marginLeft: 2 }} />
        </ListItemButton>
        {backupList.map((list, index) => (
          <Accordion key={index} sx={{ background: colors.primary[400] }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="booking-content"
              id="booking-header"
            >
              <Typography width={"90%"} variant="h6" color={colors.grey[300]}>
                {list.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MenuList>
                {list.subList.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemButton
                      onClick={redirectTo(item.url)}
                      sx={{ marginTop: -2 }}
                    >
                      <InboxIcon />
                      <ListItemText
                        primary={item.title}
                        sx={{ marginLeft: 2 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </MenuList>
            </AccordionDetails>
          </Accordion>
        ))}
        {routeTour.map((list, index) => (
          <Accordion key={index} sx={{ background: colors.primary[400] }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="booking-content"
              id="booking-header"
            >
              <Typography width={"90%"} variant="h6" color={colors.grey[300]}>
                {list.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {list.subList.map((item, idx) => (
                <Accordion key={idx} sx={{ background: colors.primary[400] }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      width={"90%"}
                      variant="h6"
                      color={"white"}
                      sx={{ marginLeft: 1 }}
                    >
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MenuList>
                      {item.subList.map((item, idx) => (
                        <ListItem key={idx}>
                          <ListItemButton
                            onClick={redirectTo(item.url)}
                            sx={{ marginTop: -2 }}
                          >
                            <InboxIcon />
                            <ListItemText
                              primary={item.title}
                              sx={{ marginLeft: 2 }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </MenuList>
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <IconButton onClick={toggleDrawer(true)}>
          <MenuOutlinedIcon />
        </IconButton>
        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer(false)}
          PaperProps={{ sx: { background: colors.primary[400] } }}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default DrawerUI;
