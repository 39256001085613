import { createCity } from "../../../data/repository/city.repo";

export const CreateCityUseCase = async (data) => {
  try {
    const response = await createCity(data);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
