import { Box, CssBaseline } from "@mui/material";
import Header from "../../../components/header/Header";
import Sidebar from "../../../components/sidebar/Sidebar";
import Topbar from "../../../components/topbar/TopBar";
import GridCount from "./form/GridCount";
import GridLine from "./form/GridLine";
import GridReports from "./form/GridReports";
import GridTransactions from "./form/GridTransactions";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ReadRiderGraph } from "./dashboard/usecases/ReadRiderGraph";
import { useEffect, useState } from "react";
import { tokens } from "../../../theme";
import { ReadEnterpriseGraph } from "./dashboard/usecases/ReadEnterpriseGraph";

export const DashboardAdmin = () => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [listWeekRider, setListWeekRider] = useState([]);
  const [listMonthsRider, setListMonthsRider] = useState([]);
  const [listYearsRider, setListYearsRider] = useState([]);
  const [listWeekEnterprise, setListWeekEnterprise] = useState([]);
  const [listMonthsEnterprise, setListMonthsEnterprise] = useState([]);
  const [listYearsEnterprise, setListYearsEnterprise] = useState([]);

  const dataWeek = [
    {
      id: "DIAS",
      color: tokens("dark").blueAccent[300],
      data: [],
    },
  ];

  const dataMonths = [
    {
      id: "MESES",
      color: tokens("dark").blueAccent[300],
      data: [],
    },
  ];

  const dataYears = [
    {
      id: "AÑOS",
      color: tokens("dark").blueAccent[300],
      data: [],
    },
  ];

  const dataEnterpriseWeek = [
    {
      id: "DIAS",
      color: tokens("dark").blueAccent[300],
      data: [],
    },
  ];

  const dataEnterpriseMonths = [
    {
      id: "MESES",
      color: tokens("dark").blueAccent[300],
      data: [],
    },
  ];

  const dataEnterpriseYears = [
    {
      id: "AÑOS",
      color: tokens("dark").blueAccent[300],
      data: [],
    },
  ];

  useEffect(() => {
    const getRiderGraph = async () => {
      try {
        const response = await ReadRiderGraph();
        dataWeek[0].data = response.graphWeek;
        setListWeekRider(dataWeek);

        dataMonths[0].data = response.graphMonth;
        setListMonthsRider(dataMonths);

        dataYears[0].data = response.graphYear;
        setListYearsRider(dataYears);
      } catch (error) {
        console.log(error);
      }
    };
    const getEnterpriseGraph = async () => {
      try {
        const response = await ReadEnterpriseGraph();
        dataEnterpriseWeek[0].data = response.graphWeek;
        setListWeekEnterprise(dataEnterpriseWeek);

        dataEnterpriseMonths[0].data = response.graphMonth;
        setListMonthsEnterprise(dataEnterpriseMonths);

        dataEnterpriseYears[0].data = response.graphYear;
        setListYearsEnterprise(dataEnterpriseYears);
      } catch (error) {
        console.log(error);
      }
    };
    getRiderGraph();
    getEnterpriseGraph();
  }, []);

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="5px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header
                title="DASHBOARD"
                subtitle="Bienvenido al Panel de Admin"
              />
              <Box></Box>
            </Box>
            {/* ROW CARDS */}
            <GridCount />
            {/* ROW CHARTS CLIENTS */}
            {listWeekRider.length > 0 ? (
              <GridLine
                title={"GRAFICA CLIENTES"}
                week={listWeekRider}
                month={listMonthsRider}
                year={listYearsRider}
              />
            ) : (
              <div>loading...</div>
            )}

            {/* ROW CHARTS ENTERPRISE */}
            {listWeekEnterprise.length > 0 ? (
              <GridLine
                title={"GRAFICA EMPRESAS"}
                week={listWeekEnterprise}
                month={listMonthsEnterprise}
                year={listYearsEnterprise}
              />
            ) : (
              <div>loading...</div>
            )}
            {/* ROW TRANSACTIONS */}
            <GridTransactions />
            {/* ROW REPORTS */}
            <GridReports />
          </Box>
        </main>
      </div>
    </>
  );
};
