import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    justifyContent: "center", // Centra verticalmente
    alignItems: "center", // Centra horizontalmente
  },
  reportTitle80: {
    color: "#000000",
    fontSize: 90,
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "center",
    padding: 0,
    margin: 0,
  },

  reportTitle70: {
    color: "#000000",
    fontSize: 80,
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "center",
    padding: 0,
    margin: 0,
  },
});

Font.registerHyphenationCallback((word) => [word]);

const TextName = ({ name }) => (
  <View wrap={false} style={styles.titleContainer}>
    {name.length > 45 ? (
      <Text style={styles.reportTitle70}>{name}</Text>
    ) : (
      <Text style={styles.reportTitle80}>{name}</Text>
    )}
  </View>
);

export default TextName;
