import { baseBackend } from "../../base/baseUrl";

export const sendICSEmail = async (token, booking) => {
  return await baseBackend.post(
    `/api/v1/resource/send-ics`,
    JSON.stringify({ booking }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const sendICSEmailNow = async (token, booking) => {
  return await baseBackend.post(
    `/api/v1/resource/send-now`,
    JSON.stringify({ booking }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};