import { Box, Typography, useTheme, Grid } from "@mui/material";
import { tokens } from "../../theme";

const StatBoxDouble = ({
  title,
  subtitle1,
  subtitle2,
  quantity1,
  quantity2,
  icon,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" height={140}>
      <Grid
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-around"}
      >
        <Grid item xs={6} sm={6} textAlign={"start"} p={"2px"}>
          <Typography
            fontWeight={"bold"}
            variant="h3"
            sx={{ color: colors.greenAccent[500] }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} width={"100%"} textAlign={"end"} p={"2px"}>
          {icon}
        </Grid>

        <Grid item xs={12} sm={12} ml={2} sx={{ textAlign: "center" }}>
          <Typography variant="h3" sx={{ color: colors.greenAccent[500] }}>
            {subtitle1}: {quantity1}
          </Typography>
          <Typography variant="h3" sx={{ color: colors.greenAccent[500] }}>
            {subtitle2}: {quantity2}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatBoxDouble;
