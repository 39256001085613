import React, { useState } from "react";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";

import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { tokens } from "../../../../theme";
import { TableStyle } from "../components/styles/TableStyle";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { ButtonStyle, ModalStyle } from "../components/styles/ModalStyle";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

const ShoppingReadScreen = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openShopping, setOpenShopping] = useState(false);
  const handleOpenShopping = () => setOpenShopping(true);
  const handleCloseShopping = () => setOpenShopping(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setDeleteModal(true);
  const handleCloseDeleteModal = () => setDeleteModal(false);

  const [itemSelect, setItemSelect] = useState({});

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const position = { vertical: "top", horizontal: "center" };

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const closeMenu = () => setAnchorEl(null);

  const handleOptionClick = (event, props) => {
    console.log(props);
    setItemSelect(props);
    setAnchorEl(event.currentTarget);
  };

  const columns = [
    {
      headerName: "FECHA",
      field: "date",
      width: 200,
      renderCell: ({ row: { date, time, horary } }) => {
        return (
          <div>
            <p>{date}</p>
            <p>{time}</p>
            <p>{horary}</p>
          </div>
        );
      },
    },
    {
      headerName: "CLIENTE",
      field: "rider_name",
      width: 200,
      renderCell: ({ row: { rider_name, rider_phone } }) => {
        return (
          <div>
            <p>{rider_name.toUpperCase()}</p>
            <p>{rider_phone}</p>
          </div>
        );
      },
    },
    {
      headerName: "TOUR",
      field: "tour_title",
      width: 200,
      renderCell: ({ row: { tour_code, tour_title } }) => {
        return (
          <div>
            <p>{tour_code}</p>
            <p>{tour_title}</p>
          </div>
        );
      },
    },

    {
      headerName: "Pax",
      field: "persons",
      width: 100,
    },
    {
      headerName: "Precio",
      field: "price",
      width: 90,
      renderCell: ({ row: { price, currency } }) => {
        return (
          <div>
            <p>
              {currency} {price}
            </p>
          </div>
        );
      },
    },
    {
      headerName: "Servicio",
      field: "service",
      width: 120,
    },
    {
      headerName: "NOTAS",
      field: "note",
      width: 200,
    },
    {
      headerName: "",
      field: "actions",
      headerAlign: "center",
      width: 100,
      align: "center",
      renderCell: ({ row: { ...props } }) => {
        return (
          <>
            <Button
              id="btn-menu"
              variant="contained"
              color="success"
              onClick={(e) => handleOptionClick(e, props)}
            >
              opciones
            </Button>
            <Menu
              id="shopping-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={closeMenu}
              MenuListProps={{ "aria-labelledby": "basic-button" }}
            >
              <MenuItem onClick={closeMenu}>
                <Button
                  startIcon={<SaveIcon sx={{ color: "green" }} />}
                  sx={{ color: "white" }}
                  // onClick={modalReservationOpen}
                >
                  CONFIRMAR
                </Button>
              </MenuItem>
              <MenuItem onClick={closeMenu}>
                <Button
                  startIcon={<DeleteIcon sx={{ color: "red" }} />}
                  sx={{ color: "white" }}
                  onClick={handleOpenDeleteModal}
                >
                  Delete
                </Button>
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="SHOPPING LISTA" subtitle="" />
            </Box>
            <Box>
              <Box m="10px 0 0 0" height="100vh" sx={TableStyle(colors)}>
                <DataGrid
                  getRowId={(row) => row.id}
                  rows={data}
                  columns={columns}
                  localeText={
                    esES.components.MuiDataGrid.defaultProps.localeText
                  }
                  checkboxSelection={false}
                />
              </Box>
            </Box>
          </Box>

          <Modal
            open={openShopping}
            onClose={handleCloseShopping}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={ModalStyle}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign={"center"}
                fontWeight={"bold"}
              >
                CONFIRMAR RESERVACION
              </Typography>
              <Grid container spacing={1}>
                {itemSelect != null && (
                  <Grid item xs={12} sm={12} md={12}>
                    {/* <Typography>
                      <span style={{ fontWeight: "bold" }}>TITULO:</span>{" "}
                      {itemSelect.tour_title}
                    </Typography>
                    <Typography>
                      <span style={{ fontWeight: "bold" }}>CLIENTE:</span>{" "}
                      {itemSelect.rider_name.toUpperCase()}
                    </Typography>
                    <Typography>
                      <span style={{ fontWeight: "bold" }}>PRECIO:</span>{" "}
                      {itemSelect.currency} {itemSelect.price}
                    </Typography>
                    <Typography>
                      <span style={{ fontWeight: "bold" }}>PRECIO:</span>{" "}
                      {itemSelect.service}
                    </Typography>
                    <Typography>
                      <span style={{ fontWeight: "bold" }}>FECHA:</span>{" "}
                      {itemSelect.date} HORA: {itemSelect.time}
                      {itemSelect.horary} 
                    </Typography>*/}
                  </Grid>
                )}

                {/* <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    value={origin}
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(e) => setOrigin(e.target.value)}
                    label="ORIGEN"
                    InputLabelProps={{ style: { color: "white" } }}
                    name="origin"
                    error={errorOrigin}
                    helperText={errorOrigin ? "Complete Lugar de Recogida" : ""}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    value={destination}
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(e) => setDestination(e.target.value)}
                    label="DESTINO"
                    InputLabelProps={{ style: { color: "white" } }}
                    name="destination"
                    error={errorDestination}
                    helperText={errorDestination ? "Complete Destino" : ""}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    value={notes}
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(e) => {
                      setNotes(e.target.value);
                    }}
                    label="NOTAS"
                    InputLabelProps={{ style: { color: "white" } }}
                    name="notes"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    sx={{ height: 50, color: "black", fontWeight: "bold" }}
                    onClick={handleCreateReservation}
                    disabled={isLoading}
                  >
                    GUARDAR
                  </Button>
                </Grid> */}
              </Grid>
            </Box>
          </Modal>

          {/* DELETE SHOPPING */}
          <Modal open={deleteModal} onClose={handleCloseDeleteModal}>
            <Box sx={ModalStyle}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign={"center"}
                fontWeight={"bold"}
              >
                ELIMINAR ESTA COTIZACION?
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={6}>
                  <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    sx={ButtonStyle}
                    // onClick={handleDeleteReservation}
                    disabled={isLoading}
                  >
                    ELIMINAR
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    sx={ButtonStyle}
                    onClick={handleCloseDeleteModal}
                    disabled={isLoading}
                  >
                    CANCELAR
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <SnackBar
            stateSnk={stateSnk}
            vertical={position.vertical}
            horizontal={position.horizontal}
            duration={4000}
            handleClose={handleSnkClose}
            severity={severity}
            messageSnk={messageSnk}
          />
        </main>
      </div>
    </>
  );
};

export default ShoppingReadScreen;
