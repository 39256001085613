import { Box, Typography, useTheme, Grid } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";

const StatBox = ({
  title,
  booking,
  tax,
  toll,
  commission,
  driver,
  total,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="10px">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {title}
            </Typography>
          </Box>

          <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
            Servicios : {booking}
          </Typography>
          <Typography variant="h5" sx={{ color: colors.greenAccent[600] }}>
            Impuesto : {tax}
          </Typography>
          <Typography variant="h5" sx={{ color: colors.greenAccent[600] }}>
            Peaje : {toll}
          </Typography>
          <Typography variant="h5" sx={{ color: colors.greenAccent[600] }}>
            Comision: {commission}
          </Typography>
          <Typography variant="h5" sx={{ color: colors.greenAccent[600] }}>
            Conductor : {driver}
          </Typography>
          <Typography variant="h5" sx={{ color: colors.greenAccent[600] }}>
            Total : {total}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatBox;
