import React, { useState } from "react";
import { t } from "i18next";
import {
  Box,
  Button,
  TextField,
  useTheme,
  useMediaQuery,
  Stack,
  FormControlLabel,
  Checkbox,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Formik } from "formik";
import * as yup from "yup";

import { tokens } from "../../../../theme";
import { getTokenRefresh } from "../../../../helpers/token/refreshToken";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { CreateVehicleUseCase } from "../vehicle/domain/CreateVehicleUseCase";

const FormVehicle = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [soatStart, setSoatStart] = useState(null);
  const [soatEnd, setSoatEnd] = useState(null);
  const [valueSetare, setSetare] = useState(false);
  const [setareStart, setSetareStart] = useState(null);
  const [setareEnd, setSetareEnd] = useState(null);
  const isNonMobile = useMediaQuery("(min-width:700px)");

  const handleSetare = (e) => setSetare(e.target.checked);

  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleCloseSnk = () => setStateSnk(false);
  const [premium, setPremium] = useState(false);
  const handleChangeBooking = (event) => setPremium(event.target.value);

  const handleFormSubmit = async (values) => {
    try {
      const token = getTokenRefresh();
      if (token) {
        const vehicle = {
          brand: values.brand.toUpperCase(),
          model: values.model.toUpperCase(),
          bodywork: values.bodywork.toUpperCase(),
          plate: values.plate.toUpperCase(),
          year: values.year,
          color: values.color.toUpperCase(),
          status: 1,
          owner: values.owner.toUpperCase(),
          cylinder: values.cylinder,
          weight: values.weight,
          premium,
          engine: values.engine,
          series: values.series,
          ownerDni: values.ownerDni,
          ownerAddress: values.ownerAddress,
        };

        const technical_review = {
          description: values.descriptionTR.toUpperCase(),
          start: "",
          end: dateEnd.$d.toISOString(),
        };

        const soat = {
          description: values.descriptionSOAT.toUpperCase(),
          start: "",
          end: soatEnd.$d.toISOString(),
        };

        const setare = {
          permission: valueSetare,
          start: setareStart,
          end: setareEnd,
        };

        const resp = await CreateVehicleUseCase(
          token,
          vehicle,
          technical_review,
          soat,
          setare
        );

        switch (resp.message) {
          case "success":
            snackBarDialog("Vehiculo Registrado Exitosamente");
            values.brand = "";
            values.model = "";
            values.bodywork = "";
            values.plate = "";
            values.year = "";
            values.color = "";
            values.descriptionTR = "";
            values.descriptionSOAT = "";
            values.engine = "";
            values.series = "";
            values.ownerDni = "";
            setDateStart(null);
            setDateEnd(null);
            setSoatStart(null);
            setSoatEnd(null);
            setSetare(false);
            setSetareStart(null);
            setSetareEnd(null);
            break;
          case "PLATE DUPLICATED":
            snackBarDialog("ESTA PLACA YA ESTA REGISTRADA", "error");
            break;
          case "FAILED_REGISTER_VEHICLE":
            snackBarDialog("FALLO EL REGISTRO DEL VEHICULO", "error");
            break;
          default:
            snackBarDialog("ERROR DEL SERVIDOR", "error");
            break;
        }
      }
    } catch (error) {
      snackBarDialog("Fallo el registro del Vehiculo", "error");
    }
  };

  const checkoutSchema = yup.object().shape({
    brand: yup.string().required("required"),
    model: yup.string().required("required"),
    bodywork: yup.string().required("required"),
    plate: yup.string().required("required"),
    year: yup.string().required("required"),
    color: yup.string().required("required"),
    descriptionTR: yup.string().required("required"),
    descriptionSOAT: yup.string().required("required"),
    engine: yup.string().required("requerido"),
    series: yup.string().required("requerido"),
    ownerDni: yup.string().required("requerido"),
    ownerAddress: yup.string().required("requerido"),
  });

  const initialValues = {
    brand: "",
    model: "",
    bodywork: "SEDAN",
    plate: "",
    year: "",
    color: "",
    descriptionTR: "",
    descriptionSOAT: "",
    owner: "",
    ownerDni: "",
    ownerAddress: "",
    cylinder: "",
    weight: "",
    engine: "",
    series: "",
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box backgroundColor={colors.primary[400]} p={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  value={values.brand}
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={t("brand")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  color="success"
                  name="brand"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.brand && !!errors.brand}
                  helperText={touched.brand && errors.brand}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  value={values.model}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("model")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="model"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.model && !!errors.model}
                  helperText={touched.model && errors.model}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <InputLabel>CARROCERIA</InputLabel>
                  <Select
                    id="select"
                    value={values.bodywork}
                    name="bodywork"
                    label="CARROCERIA"
                    onChange={handleChange}
                  >
                    <MenuItem value={"SEDAN"}>SEDAN</MenuItem>
                    <MenuItem value={"SUV"}>SUV</MenuItem>
                    <MenuItem value={"MINIVAN"}>MINIVAN</MenuItem>
                    <MenuItem value={"MINIBUS"}>MINIBUS</MenuItem>
                    <MenuItem value={"BUS"}>BUS</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  value={values.plate}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("plate")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="plate"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.plate && !!errors.plate}
                  helperText={touched.plate && errors.plate}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  value={values.year}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="number"
                  label={t("year")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="year"
                  error={!!touched.year && !!errors.year}
                  helperText={touched.year && errors.year}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  value={values.color}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("color")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="color"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.color && !!errors.color}
                  helperText={touched.color && errors.color}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  value={values.descriptionTR}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("descriptionRT")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="descriptionTR"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.descriptionTR && !!errors.descriptionTR}
                  helperText={touched.descriptionTR && errors.descriptionTR}
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <MobileDatePicker
                      label={t("end")}
                      inputFormat="DD/MM/YYYY"
                      name="dateEnd"
                      value={dateEnd}
                      onChange={(newValue) => {
                        setDateEnd(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  value={values.descriptionSOAT}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("descriptionSOAT")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="descriptionSOAT"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.descriptionSOAT && !!errors.descriptionSOAT}
                  helperText={touched.descriptionSOAT && errors.descriptionSOAT}
                  sx={{ gridColumn: "span 1" }}
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <MobileDatePicker
                      value={soatEnd}
                      label={t("end")}
                      inputFormat="DD/MM/YYYY"
                      name="dateEnd"
                      onChange={(newValue) => {
                        setSoatEnd(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  value={values.owner}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label="Propietario"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="owner"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.descriptionTR && !!errors.descriptionTR}
                  helperText={touched.descriptionTR && errors.descriptionTR}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  value={values.ownerDni}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label="DNI Propietario"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="ownerDni"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.ownerDni && !!errors.ownerDni}
                  helperText={touched.ownerDni && errors.ownerDni}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  value={values.ownerAddress}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label="Direccion del Propietario"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="ownerAddress"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.ownerAddress && !!errors.ownerAddress}
                  helperText={touched.ownerAddress && errors.ownerAddress}
                />
              </Grid>

              <Grid item xs={12} sm={2} md={2}>
                <TextField
                  value={values.cylinder}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label="Cilindrada"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="cylinder"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.descriptionTR && !!errors.descriptionTR}
                  helperText={touched.descriptionTR && errors.descriptionTR}
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <TextField
                  value={values.weight}
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label="Peso"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="weight"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.descriptionTR && !!errors.descriptionTR}
                  helperText={touched.descriptionTR && errors.descriptionTR}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  value={values.engine}
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="MOTOR"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  color="success"
                  name="engine"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.engine && !!errors.engine}
                  helperText={touched.engine && errors.engine}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  value={values.series}
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="SERIE"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  color="success"
                  name="series"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!touched.series && !!errors.series}
                  helperText={touched.series && errors.series}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2} textAlign="center">
                <FormControl>
                  <FormLabel id="row-radio-buttons">PREMIUM:</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="row-radio-buttons"
                    value={premium}
                    onChange={handleChangeBooking}
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio color="success" />}
                      label="NO"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio color="success" />}
                      label="SI"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControlLabel
                  label={t("setare")}
                  control={<Checkbox color="success" onChange={handleSetare} />}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                {valueSetare && (
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={1}>
                          <MobileDatePicker
                            label={t("start")}
                            inputFormat="DD/MM/YYYY"
                            name="dateStart"
                            value={setareStart}
                            onChange={(newValue) => {
                              setSetareStart(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={1}>
                          <MobileDatePicker
                            label={t("end")}
                            inputFormat="DD/MM/YYYY"
                            name="dateEnd"
                            value={setareEnd}
                            onChange={(newValue) => {
                              setSetareEnd(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} sm={12} textAlign={"end"}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ height: 45, width: 120 }}
                >
                  {t("add")}
                </Button>
              </Grid>
            </Grid>

            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={2}>
                <MobileDatePicker
                  label={t("start")}
                  inputFormat="DD/MM/YYYY"
                  name="dateStart"
                  value={dateStart}
                  onChange={(newValue) => {
                    setDateStart(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider> */}

            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={2}>
                <MobileDatePicker
                  value={soatStart}
                  label={t("start")}
                  inputFormat="DD/MM/YYYY"
                  name="dateStart"
                  onChange={(newValue) => {
                    setSoatStart(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider> */}
          </Box>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={4000}
            handleClose={handleCloseSnk}
            severity={severity}
            messageSnk={messageSnk}
          />
        </form>
      )}
    </Formik>
  );
};

export default FormVehicle;
