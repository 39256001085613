import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
  Typography,
} from "@mui/material";
import { useTheme as themeStyles } from "@mui/material/styles";
import { Formik, validateYupSchema } from "formik";
import * as yup from "yup";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import { tokens } from "../../../../../theme";
import { useTranslation } from "react-i18next";
import Sidebar from "../../../../../components/sidebar/Sidebar";
import Topbar from "../../../../../components/topbar/TopBar";
import Header from "../../../../../components/header/Header";
import { use } from "i18next";
import {
  createRateList,
  createRates,
  enabledRate,
  getRates,
  updateRate,
} from "../../../../../api/users/enterprise/enterpriseApi";
import { useLocation, useParams } from "react-router-dom";

const EnterpriseRatesAdd = () => {
  const { id, name } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const location = useLocation()

  const rateModel = {
    rateTypeId: 1,
    pricePerhHour: 0,
    pricePerMinute: 0,
    pricePerKm: 0,
    priceRushTime: 0,
    priceNight: 0,
    priceMinimal: 0,
    priceFestivedDate: 0,
    priceDistanceMinimal: 0,
  };

  const rateSchema = yup.object().shape({});

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [rateList, setRateList] = useState();
  const rateType = {
    1: "GLOBAL",
    2: "SEDAN",
    3: "SUV",
    4: "MINIVAN",
    5: "MINIVAN_15",
    6: "MINIVAN_19",
  };

  useEffect(() => {
    ratesData();
  }, []);

  const ratesData = async () => {
    try {
      const response = await getRates(id);
      setRateList(response?.data);
      if (response.data.rateEnterprise.length == 0) {
        setEnabled(false);
      } else if (response.data.rateEnterprise[0].enabled == 0) {
        setEnabled(false);
      } else {
        setEnabled(true);
      }
      console.log(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const saveDefaultToEnterprise = async (values) => {
    try {
      await createRateList(id, values);
      await ratesData();
    } catch (error) {
      console.log(error);
    }
  };

  const [enabled, setEnabled] = useState(false);
  const enabledRateEnteprise = async () => {
    try {
      console.log("enviando", !enabled);
      await enabledRate(!enabled, id);
      setEnabled(!enabled);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSubmit = async (values) => {
    try {
      const response = await updateRate(values);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={`TARIFAS: ${name}`} subtitle="" />
            </Box>

            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="120px"
              gap="0px"
            >
              <Box gridColumn="span 12" gridRow="span 2">
                {rateList?.rateEnterprise?.length == 0 ? (
                  <Grid item xs={4} sm={4} margin={1} textAlign={"end"}>
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={isLoading}
                      sx={{ height: 40 }}
                      onClick={(e) => {
                        e.preventDefault();
                        saveDefaultToEnterprise(rateList.rateDefault);
                      }}
                    >
                      GUARDAR TODO
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={4} sm={4} margin={1} textAlign={"end"}>
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={isLoading}
                      sx={{ height: 40 }}
                      onClick={(e) => {
                        e.preventDefault();
                        enabledRateEnteprise();
                      }}
                    >
                      {enabled ? "VOLVER GLOBAL" : "VOLVER A SU CONFIGURACION"}
                    </Button>
                  </Grid>
                )}

                {rateList ? (
                  rateList.rateEnterprise.length > 0 && enabled ? (
                    rateList.rateEnterprise.map((rate, index) => (
                      <Formik
                        key={index}
                        onSubmit={handleFormSubmit}
                        initialValues={rate}
                        validationSchema={rateSchema}
                        enableReinitialize
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Box backgroundColor={colors.primary[400]} p={1}>
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                  <Typography
                                    variant="h4"
                                    component="h2"
                                    textAlign={"center"}
                                    fontWeight={"bold"}
                                  >
                                    {rateType[values.rate_type_id]}
                                  </Typography>
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_per_hour}
                                    name="price_per_hour"
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label="PRECIO POR HORA"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_per_minute}
                                    fullWidth
                                    variant="outlined"
                                    type="number"
                                    label="PRECIO POR MINUTO"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_per_minute"
                                    color="success"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_per_km}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label="PRECIO POR KM"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_per_km"
                                  />
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_night}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label="POR NOCHE"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_night"
                                  />
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_rush_time}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label="POR HORA PUNTA"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_rush_time"
                                  />
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_minimal}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label="PRECIO MINIMO"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_minimal"
                                  />
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_festive_date}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label="PRECIO DIA FESTIVO"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_festive_date"
                                  />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_distance_minimal}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label="DISTANCIA MINIMA"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_distance_minimal"
                                    error={!!touched.phone && !!errors.phone}
                                    helperText={touched.phone && errors.phone}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    fullWidth
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    disabled={isLoading}
                                    sx={{ height: 45 }}
                                  >
                                    ACTUALIZAR DATOS
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                            <SnackBar
                              stateSnk={stateSnk}
                              vertical={vertical}
                              horizontal={horizontal}
                              duration={4000}
                              handleClose={handleSnkClose}
                              severity={severity}
                              messageSnk={messageSnk}
                            />
                          </form>
                        )}
                      </Formik>
                    ))
                  ) : (
                    rateList?.rateDefault.map((rate, index) => (
                      <Formik
                        key={index}
                        onSubmit={handleFormSubmit}
                        initialValues={rate}
                        validationSchema={rateSchema}
                        enableReinitialize
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Box backgroundColor={colors.primary[400]} p={1}>
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                  <Typography
                                    variant="h4"
                                    component="h2"
                                    textAlign={"center"}
                                    fontWeight={"bold"}
                                  >
                                    {rateType[values.rate_type_id]}
                                  </Typography>
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_per_hour}
                                    name="price_per_hour"
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label="PRECIO POR HORA"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={true}
                                  />
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_per_minute}
                                    fullWidth
                                    variant="outlined"
                                    type="number"
                                    label="PRECIO POR MINUTO"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_per_minute"
                                    color="success"
                                    disabled={true}
                                  />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_per_km}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label="PRECIO POR KM"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_per_km"
                                    disabled={true}
                                  />
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_night}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label="POR NOCHE"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_night"
                                    disabled={true}
                                  />
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_rush_time}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label="POR HORA PUNTA"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_rush_time"
                                    disabled={true}
                                  />
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_minimal}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label="PRECIO MINIMO"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_minimal"
                                    disabled={true}
                                  />
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_festive_date}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label="PRECIO DIA FESTIVO"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_festive_date"
                                    disabled={true}
                                  />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                  <TextField
                                    value={values.price_distance_minimal}
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="number"
                                    label="DISTANCIA MINIMA"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="price_distance_minimal"
                                    error={!!touched.phone && !!errors.phone}
                                    helperText={touched.phone && errors.phone}
                                    disabled={true}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <SnackBar
                              stateSnk={stateSnk}
                              vertical={vertical}
                              horizontal={horizontal}
                              duration={4000}
                              handleClose={handleSnkClose}
                              severity={severity}
                              messageSnk={messageSnk}
                            />
                          </form>
                        )}
                      </Formik>
                    ))
                  )
                ) : (
                  <></>
                )}

                {/* <Grid item xs={4} sm={4} textAlign={"end"} margin={1}>
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={isLoading}
                    sx={{ height: 40 }}
                    onClick={(e) => {
                      e.preventDefault();
                      saveDefaultToEnterprise(rateList.rateDefault);
                    }}
                  >
                    CREAR TARIFAS
                  </Button>
                </Grid> */}

                {/* 
                  CREAR ADDRES RATES PARA 
                    LAT, LNG, NAME, RADIUS, UUID
                  -AGREGAR RUTAS A ESTA EMPRESA
                  -EDITAR RUTAS
                  -LISTA DE RUTAS
                  -TRAER EL GLOBAL
                  -TRAER MIS RUTAS,
                  -REVISAR SI ESTAN ACTIVOS*
                  -REVISAR TODAS LAS RUTAS POR PAIS
                */}
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default EnterpriseRatesAdd;
