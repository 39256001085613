import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#000000";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: "#000000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  index: {
    width: "5%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  fullname: {
    width: "50%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
  },
  dni: {
    width: "18%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  country: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  age: {
    width: "7%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const TableRow = ({ items }) => {
  const rows = items.map((item, index) => (
    <View style={styles.row} key={index}>
      <Text style={styles.index}>{index + 1}</Text>
      <Text style={styles.fullname}>{item.name}</Text>
      <Text style={styles.dni}>{item.document}</Text>
      <Text style={styles.country}>{item.country}</Text>
      <Text style={styles.age}>{item.age}</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default TableRow;
